import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonRippleEffect,
  IonLabel,
  IonImg,
} from '@ionic/react';
import { t } from '@lingui/macro';
import React, { useContext, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router';
import Home from '../home';
import Marketplacewithtab from '../marketplacewithtab';
import Notificationswithtab from '../notificationswithtab';
import SolutionList from '../solutionlist';
import './style.scss';
import NotiIcon from '../../assets/images/icons/notifications.svg';
import NotiSelectedIcon from '../../assets/images/icons/notifications-selected.svg';
import HomeIcon from '../../assets/images/icons/home.svg';
import HomeSelectedIcon from '../../assets/images/icons/home-selected.svg';
import MarketIcon from '../../assets/images/icons/market.svg';
import MarketSelectedIcon from '../../assets/images/icons/market-selected.svg';
import FoodSecurityIcon from '../../assets/images/icons/food_security.svg';
import FoodSecuritySelectedIcon from '../../assets/images/icons/food_security-selected.svg';
import { AppContext } from '../../context/app.context';

const Tabs: React.FC = () => {
  const { isNewNotification }: any = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('home');
  const onTabClick = (e: any) => {
    setSelectedTab(e.detail.tab);
    if (e.detail.tab == 'market') {
      history.push(`/tabs/${e.detail.tab}/demand`);
    } else {
      history.push(`/tabs/${e.detail.tab}`);
    }
  };

  useEffect(() => {
    if (location.pathname == '/tabs/home') {
      setSelectedTab('home');
    } else if (location.pathname.includes('/tabs/market')) {
      setSelectedTab('market');
    } else if (location.pathname == '/tabs/foodsecurity') {
      setSelectedTab('foodsecurity');
    } else if (location.pathname == '/tabs/notifications') {
      setSelectedTab('notifications');
    }
  }, [location]);
  return (
    <IonTabs onIonTabsWillChange={onTabClick}>
      <IonRouterOutlet>
        <Route path='/tabs/:tab(home)'>
          <Home />
        </Route>
        <Route path='/tabs/:tab(market)/:type'>
          <Marketplacewithtab />
        </Route>
        <Route path='/tabs/:tab(market)/:type'>
          <Marketplacewithtab />
        </Route>
        <Route path='/tabs/:tab(foodsecurity)'>
          <SolutionList />
        </Route>
        <Route path='/tabs/:tab(notifications)'>
          <Notificationswithtab />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot='bottom' className={`bottom-nav`}>
        <IonTabButton
          className='ion-tab__btn'
          tab='home'
          selected={selectedTab == 'home'}
        >
          <IonImg
            src={selectedTab == 'home' ? HomeSelectedIcon : HomeIcon}
            style={{ width: 20, height: 24 }}
            className='mb-5'
          />
          <IonRippleEffect type='unbounded'></IonRippleEffect>
          <IonLabel>{t({ id: 'home', message: 'Home' })}</IonLabel>
        </IonTabButton>
        <IonTabButton
          className='ion-tab__btn'
          tab='market'
          selected={selectedTab == 'market'}
        >
          <IonImg
            src={selectedTab == 'market' ? MarketSelectedIcon : MarketIcon}
            style={{ width: 20, height: 24 }}
            className='mb-5'
          />
          <IonRippleEffect type='unbounded'></IonRippleEffect>
          <IonLabel>
            {t({ id: 'info_exchange', message: 'Info Exchange' })}
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          className='ion-tab__btn'
          tab='foodsecurity'
          selected={selectedTab == 'foodsecurity'}
        >
          <IonImg
            src={
              selectedTab == 'foodsecurity'
                ? FoodSecuritySelectedIcon
                : FoodSecurityIcon
            }
            style={{ width: 20, height: 24 }}
            className='mb-5'
          />
          <IonRippleEffect type='unbounded'></IonRippleEffect>
          <IonLabel>
            {t({
              id: 'our_solutions',
              message: 'Our Solutions',
            })}
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          className='ion-tab__btn'
          tab='notifications'
          selected={selectedTab == 'notifications'}
        >
          <div style={{ display: 'flex' }} className='mb-5'>
            <IonImg
              src={selectedTab == 'notifications' ? NotiSelectedIcon : NotiIcon}
              style={{ width: 20, height: 24 }}
            />
            {isNewNotification && <div className='badge'></div>}
          </div>
          <IonRippleEffect type='unbounded'></IonRippleEffect>
          <IonLabel>
            {t({ id: 'notifications', message: 'Notifications' })}
          </IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Tabs;
