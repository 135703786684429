/* eslint-disable react/no-children-prop */
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import './style.scss';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
interface Props {
  title?: string | null;
  description?: string;
  titleTag?: string | null;
  descPosition?: string;
  titleCustomClass?: string | null;
  descCustomClass?: string;
  descTag?: string | null;
}
const TitleDescription: React.FC<Props> = (props: Props) => {
  const {
    title,
    titleTag,
    titleCustomClass,
    description,
    descPosition,
    descCustomClass,
    // descTag,
  } = props;
  const TitleTag = titleTag ? (titleTag as keyof JSX.IntrinsicElements) : 'div';
  // const DescTag = descTag ? (descTag as keyof JSX.IntrinsicElements) : 'p';

  return (
    <>
      {descPosition ? (
        descPosition == 'top' ? (
          <>
            {description && (
              <ReactMarkdown
                className={`description ${descCustomClass}`}
                children={description}
                remarkPlugins={[remarkGfm, remarkBreaks]}
              />
            )}
            {title && <TitleTag className={titleCustomClass}>{title}</TitleTag>}
          </>
        ) : descPosition == 'left' ? (
          <IonGrid>
            <IonRow className='item-col'>
              {description && (
                <IonCol sizeLg={title ? '9' : '12'} sizeMd='12' sizeXs='12'>
                  <ReactMarkdown
                    className={`description ${descCustomClass}`}
                    children={description}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                  />
                </IonCol>
              )}

              {title && (
                <IonCol
                  sizeLg={description ? '3' : '12'}
                  sizeMd='12'
                  sizeXs='12'
                >
                  <TitleTag className={titleCustomClass}>{title}</TitleTag>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        ) : descPosition == 'right' ? (
          <IonGrid>
            <IonRow className='item-col'>
              {title && (
                <IonCol
                  sizeLg={description ? '3' : '12'}
                  sizeMd='12'
                  sizeXs='12'
                >
                  <TitleTag className={titleCustomClass}>{title}</TitleTag>
                </IonCol>
              )}
              {description && (
                <IonCol sizeLg={title ? '9' : '12'} sizeMd='12' sizeXs='12'>
                  <ReactMarkdown
                    className={`description ${descCustomClass}`}
                    children={description}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                  />
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        ) : (
          (descPosition == 'bottom' || descPosition == 'center') && (
            <>
              {title && (
                <TitleTag className={titleCustomClass}>{title}</TitleTag>
              )}
              {description && (
                <ReactMarkdown
                  className={`description ${descCustomClass}`}
                  children={description}
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                />
              )}
            </>
          )
        )
      ) : (
        <>
          {title && <TitleTag className={titleCustomClass}>{title}</TitleTag>}
          {description && (
            <ReactMarkdown
              className={`description ${descCustomClass}`}
              children={description}
              remarkPlugins={[remarkGfm, remarkBreaks]}
            />
          )}
        </>
      )}
    </>
  );
};

export default TitleDescription;
