/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./style.scss";
import { IonLabel, IonRadio } from "@ionic/react";
import { t } from '@lingui/macro';

interface Props {
  label?: string;
  onClick?: any;
  textKey?: any;
  customClass?: string;
  //onchange?:(value:any, textbox:string)=>void;
}

const Radio: React.FC<Props> = ({ label, onClick, textKey, customClass }) => {

  const labelText: any = label;
  //const [selected] = useState();
  
  return (
    <div className={`radio-wrapper ${customClass || ''}`}>
          <IonRadio 
            slot="start" 
            value={labelText}
            onClick={onClick}
            className="radio-btn"
            // onIonFocus={e => {
            //   setSelected(e.detail.value)
            //   onchange && onchange(e.detail.value, labelText)}}
          />
          <IonLabel className="ion-label">
            {t({ id: textKey, message: labelText })}
          </IonLabel>
    </div>
  );
};

export default Radio;