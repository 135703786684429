import Messages from '../config/messages';
import httpService from './http.service';

export async function getLocationList(pincode: string) {
  try {
    const resp: any = await httpService.execute({
      url: `locations/pincode?pincode=${pincode}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getLocationByPincode(pincode: string) {
  try {
    const resp: any = await httpService.execute({
      url: `locations/pincode/${pincode}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getStates() {
  try {
    const resp: any = await httpService.execute({
      url: `locations/states`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getDists() {
  try {
    const resp: any = await httpService.execute({
      url: `locations/district`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
