import React from 'react';
import { Redirect } from 'react-router';
import { AppContext } from '../../context/app.context';

export function PrivateRoute(Component: any) {
  return class AuthenticatedComponent extends React.Component {
    render() {
      return (
        <AppContext.Consumer>
          {(consumer: any) => {
            return (
              <div>
                {consumer.isLoggedIn == 'true' ? (
                  consumer.isInActive == 'true' ? (
                    <Redirect to={'/account-inactive'} />
                  ) : (
                    <Component {...this.props} />
                  )
                ) : (
                  <Redirect to={'/unauthenticate'} />
                )}
              </div>
            );
          }}
        </AppContext.Consumer>
      );
    }
  };
}

export default PrivateRoute;
