/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useEffect, useState } from 'react';
import { IonImg } from '@ionic/react';
import CommodityPlaceholder from '../../assets/images/commodity_placeholder.png';
import CheckBox from '../checkbox';

interface Props {
  imgPath?: string;
  label?: string;
  textKey?: any;
  isChecked: boolean;
  onCheckChanged: (isChecked: boolean, value: string) => void;
}

const CommodityCard: React.FC<Props> = ({
  imgPath,
  label,
  isChecked,
  onCheckChanged,
}) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setMediaPath(imgPath);
  }, [imgPath]);

  return (
    <div className='commodity-card-wrapper'>
      <div className='commodity-image-wrapper'>
        <IonImg
          src={mediaPath || CommodityPlaceholder}
          className='commodity__image'
        />
        <CheckBox
          key={label}
          customClass='commodity-checkbox'
          isChecked={checked}
          hideLabel
          label={label}
          onchange={onCheckChanged}
        />
      </div>
      <div className='commodity-card-name'>{label}</div>
      {/* <div className='commodity-card-name'>{label}</div> */}
    </div>
  );
};

export default CommodityCard;
