import { IonPage, IonPopover } from '@ionic/react';
import { useContext, useState } from 'react';
import Breadcrumbs from '../../../components/breadcrumbs';

import './style.scss';
import { AppContext } from '../../../context/app.context';
import Layout from '../../../components/layouts';
import { isWeb } from '../../../utils/common.helper';
import PrivateRoute from '../../../components/privateroute';
import Messages from '../../../config/messages';
import ChooseProduct from '../../../components/chooseproduct';
import { userUsingSolutions } from '../../../services/user.service';
import { useHistory } from 'react-router';
import ButtonComponent from '../../../components/button-component';
import { t } from '@lingui/macro';

const Solutions: React.FC = () => {
  const { setLoading, toastMessage }: any = useContext(AppContext);
  const history = useHistory();
  const [popoverStateShare, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const handleProductSubmit = async (productList: any) => {
    setLoading(true);
    console.log(productList);

    const ids = productList.map((item: any) => item.id);
    const resp = await userUsingSolutions({
      ids,
    });
    setLoading(false);
    if (!resp.error) {
      setShowPopover({ showPopover: true, event: undefined });
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  return (
    <IonPage className='page-your-solution-detail'>
      <Layout>
        <div
          className={`page-commodity-detail-container page-container ${
            isWeb() ? 'pt-0' : ''
          }`}
        >
          {isWeb() && (
            <div className='page-menu-list p-32 mb-24'>
              <div className='page-menu-list-inner'>
                <Breadcrumbs
                  subMenuText={'Solutions'}
                  menuText={'My Profile'}
                  subMenuTextKey={'solutions'}
                  menuTextKey={'my_profile'}
                  menuRoute={'/my-profile'}
                />
              </div>
            </div>
          )}
          <div className='registrtation-commodity-container mb-32'>
            <ChooseProduct fromProfile onSubmit={handleProductSubmit} />
          </div>
        </div>
        <IonPopover
          event={popoverStateShare.event}
          isOpen={popoverStateShare.showPopover}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
            // history.goBack();
          }}
          className='img-popover modal-style'
          backdropDismiss={false}
        >
          <div
            className='img-popover-inner success-reg-popover'
            style={{ backgroundColor: 'white' }}
          >
            <h4 className='shareURL_head'>
              {t({
                id: 'thank_you_you_have_updated_your_solution_used',
                message: Messages.SOLUTION_UPDATE,
              })}
            </h4>
            {/* <a href={INIT_PAGE} className='homepage-link'>Visit homepage</a> */}
            <ButtonComponent
              name={'Ok'}
              customClass=' mt-16 homepage-link'
              textKey='ok'
              onClick={() => {
                setShowPopover({ showPopover: false, event: undefined });
                history.goBack();
              }}
            />
          </div>
        </IonPopover>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(Solutions);
