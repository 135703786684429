import { APP_DOMAIN } from '../../../config/appconfig';

const TNCEN: React.FC = () => {
  //   const tableCotent = [
  //     {
  //       title: 'ACCEPTANCE OF THE TERMS AND CONDITIONS OF ACCESS AND USAGE',
  //       page: '5',
  //     },
  //     {
  //       title: 'THIRD-PARTY SERVICES',
  //       page: '6',
  //     },
  //     {
  //       title: 'ACCESS AND USE',
  //       page: '6',
  //     },
  //     {
  //       title: 'MEMBERSHIP/REGISTRATION AND ELIGIBILITY',
  //       page: '7',
  //     },
  //     { title: 'TERMS OF USE OF THE PLATFORM', page: '9' },
  //     { title: 'RESTRICTION ON USE OF SERVICES', page: '10' },
  //     { title: 'CONTENT', page: '11' },
  //     { title: 'LIMITED LICENSE TO THE APP', page: '12' },
  //     { title: 'BREACH OF CONTENT STANDARDS', page: '13' },
  //     { title: 'BILLING AND PAYMENT', page: '14' },
  //     { title: 'INDEMNITY AND LIMITATION OF LIABILITY', page: '14' },
  //     { title: 'OFFENCES', page: '16' },
  //     { title: 'ADDITIONAL TERMS FOR THE MOBILE APP', page: '17' },
  //     { title: 'AUTHORISED ACCESS', page: '17' },
  //     { title: 'CONFIDENTIALITY', page: '19' },
  //     { title: 'PRIVACY AND PROTECTION OF PERSONAL DATA', page: '19' },
  //     { title: 'INTELLECTUAL PROPERTY', page: '20' },
  //     { title: 'DISCLAIMER OF WARRANTIES', page: '21' },
  //     { title: 'USER SUBMISSION AND USER CONTENT BY THIRD PARTIES', page: '23' },
  //     { title: 'WAIVER AND SEVERABILITY', page: '24' },
  //     { title: 'USE OF MOBILE DEVICES', page: '24' },
  //     { title: 'PROHIBITED ACTIONS', page: '25' },
  //     { title: 'GOVERNING LAWS', page: '25' },
  //     { title: 'JURISDICTION', page: '25' },
  //     { title: 'MODIFICATION OF TERMS AND THE PLATFORM', page: '26' },
  //     { title: 'USE AT YOUR OWN RISK', page: '26' },
  //     { title: 'OPINIONS AND FEEDBACK', page: '26' },
  //   ];
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        TERMS AND CONDITIONS OF ACCESS AND USAGE FOR COVESTRO FOOD SECURITY
        PLATFORM
      </h3>
      {/* {tableCotent.map((item: any, index: number) => {
        return (
          <div key={index} className='table-row '>
            <div className='first-col'>{index + 1}.</div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <div style={{ flex: 0.1, textAlign: 'right' }}>{item.page}</div>
          </div>
        );
      })}
      <h4 style={{ textAlign: 'center', marginTop: 40 }}>
        TERMS AND CONDITIONS OF ACCESS AND USAGE FOR COVESTRO FOOD SECURITY
        PLATFORM
      </h4> */}
      <h4 style={{ textAlign: 'center' }}>LAST UPDATED [JULY 28, 2022]</h4>
      <p>
        Thank you for choosing to be part of our community at Covestro (INDIA)
        PRIVATE LIMITED, earlier known as Bayer Material Science Private
        Limited, a private Limited company incorporated under the Companies Act,
        1956 and having its registered office at Unit No. SB- 801, 8th Floor,
        Empire Tower, Cloud City Campus, Airoli, Thane - Belapur Rd Navi Mumbai
        Thane MH 400708 IN (“Covestro”, “company”, “we”, “us”, or “our”, or ‘the
        Platform’). These Terms detailed below govern the contractual
        relationship between the ‘User’ (hereafter also referred to as ‘You’ or
        the ‘Customer’), and Covestro. It is hereby clarified that the term
        &quot;Users&quot; or “You” shall mean any person duly registered and
        using the Platform i.e., consumers, farmers and/or farmers
        societies/groups.
      </p>
      <p>
        COVESTRO has created this website/app [Food Security Platform], and/or
        any related services, sales, marketing or events (we refer to them
        collectively in this policy as the &quot;Platform&quot; or
        “website/app”) to provide Users, the Products and Services which
        includes, guidance on financial schemes or policies, food &amp;
        agricultural produce and products and to provide the Users access to
        various Third-Party Vendors. While the recommendations consider several
        factors, including, but not limited to the information provided by the
        Users and are specific to each User only, it is however reiterated and
        stated that we do not warrant the quality or authenticity of any
        financial schemes or policies, food &amp; agricultural produce and
        products, accessible on the Platform, and the Platform is merely a
        bridge that serves as online market place.
      </p>
      <p>
        Your use of this Platform implies that You have read, understood and
        agreed to abide by following, disclaimer, terms &amp; conditions
        (&quot;Terms &amp; Conditions&quot;). These Terms &amp; Conditions if
        read together with our Privacy Policy govern Your usage, access of the
        Platform. If You disagree with any part of these Terms &amp; Conditions
        please do not use or access the Platform.
      </p>
      <ol>
        <li>
          <h3>ACCEPTANCE OF THE TERMS AND CONDITIONS OF ACCESS AND USAGE</h3>
          <ol>
            <li>
              <p>
                By subscribing to or using any of our services you agree that
                you have read, understood and are bound by the Terms, regardless
                of how you subscribe to or use the services. If you do not want
                to be bound by the Terms, you must not subscribe to or use our
                services. These Terms and various other policies are binding as
                per the provisions of the Information Technology (Intermediaries
                guidelines) Rules, 2011 formulated under the Information
                Technology Act of 2000.
              </p>
            </li>
            <li>
              <p>
                These Terms are important to you and COVESTRO as they are used
                to protect your rights as a valued customer and our rights as a
                business.
              </p>
            </li>
            <li>
              <p>
                These Terms are applicable and replace all other conditions,
                except in the case of express. written, prior dispensation by
                COVESTRO.
              </p>
            </li>
            <li>
              <p>
                When You use any of the services provided by Covestro through
                the Platform, including, but not limited to providing product
                reviews, seller reviews, receiving contact details of the
                potential sellers, etc., You will be subject to the rules,
                guidelines, policies, terms, and conditions applicable to such
                service, and they shall be deemed to be incorporated into this
                Terms of Use and shall be considered as part and parcel of this
                Terms of Use.
              </p>
            </li>
            <li>
              <p>
                We withhold our rights to modify this Terms of Use, at any time
                without any prior written notice to you. It is your
                responsibility to review these Terms of Use periodically for
                updates / changes. Your continued use of the Platform following
                the posting of changes will mean that you accept and agree to
                the revisions. We maintain that you can review the most current
                version of the Terms of Service at:{' '}
                <a
                  href={`${APP_DOMAIN}legal/conditions-of-use`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/conditions-of-use`}
                </a>
              </p>
            </li>
            <li>
              <p>
                As long as you comply with these Terms of Use, we grant you a
                personal, non-exclusive, non-transferable, limited privilege to
                enter and use the Platform.
              </p>
            </li>
            <li>
              <p>
                We have updated our policy to be compliant with the European
                Union’s General Data Protection Regulation (GDPR). As per the
                new policy changes, you have more control over your own data,
                making it more safe and secure for you
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>THIRD-PARTY SERVICES</h3>
          <ol>
            <li>
              <p>
                The Platform may give you access to links to third-party
                products, or services (“Third Party Services”). COVESTRO does
                not control Third Party Services in any manner and, accordingly,
                does not assume any liability associated with such Third-Party
                Services. You need to take appropriate steps to determine
                whether accessing a Third-Party Service is appropriate,
                including protecting your personal information and privacy in
                using any such Third-Party Services and complying with relevant
                agreements.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ACCESS AND USE</h3>
          <ol>
            <li>
              <p>
                The Platform is a marketplace, wherein Users are provided access
                to a variety of food &amp; agricultural produce and connected
                services &amp; solutions, including, but not limited to
                vegetables, fruits, grains, pulses, spices, plantation crops,
                financial schemes, policies, plans, etc.(collectively referred
                to as “Products and Services”).
              </p>
            </li>
            <li>
              <p>
                Any content you submit through the Platform is governed by the
                Company’s Privacy Policy available at{' '}
                <a
                  href={`${APP_DOMAIN}legal/privacy-statement`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/privacy-statement`}
                </a>
                . To the extent there is an inconsistency between this Agreement
                and the Company’s Privacy Policy, these Terms shall govern.
              </p>
            </li>
            <li>
              <p>
                In connection with the Discussion Forum, if you were to submit a
                question or response, you are solely responsible for your own
                communications, the consequences of posting those
                communications, and your reliance on any communications found in
                the public areas. The Company and its licensors are not
                responsible for the consequences of any communications in the
                public areas. In cases where you feel threatened or you believe
                someone else is in danger, you should contact your local law
                enforcement agency immediately. You are responsible for all of
                your activity in connection with the Platform and you shall
                abide by all local, state, national, and international laws and
                regulations and any applicable regulatory codes.
              </p>
            </li>
            <li>
              <p>
                Access to the Products and Services is permitted on a temporary
                basis, and we reserve the right to withdraw or amend Products
                and Services(or any features within the Products and Services)
                that we provide on the Platform by notifying the same on the
                Platform from time to time without prior notice to you. We will
                not be liable, if for any reason the Platform or the Products
                and Services (or any features within the Products and Services)
                are unavailable at any time or for any period. From time to
                time, we may, at our sole discretion, restrict access to some
                parts of the Platform, or the entire Platform, to Users who have
                registered with us.
              </p>
            </li>
            <li>
              <p>
                You are responsible for making all arrangements necessary for
                you to have access to our Platform and thereby the Products and
                Services. You are also responsible to ensure that no other
                person shall access the Platform and thereby the Products and
                Services through your account are aware that you shall be solely
                responsible for such access without any liability whatsoever to
                us.
              </p>
            </li>
            <li>
              <p>
                You are also solely responsible and liable to COVESTRO for all
                activities that take place or occur under your account. You
                agree that your ability to log into your account is dependent
                upon external factors such as internet service providers and
                internet network connectivity and we shall not be liable to you
                for any damages arising from your inability to log into your
                account.
              </p>
            </li>
            <li>
              <p>
                COVESTRO reserves the right to refuse access to use the Products
                and Services offered at the Platform to new Users or to
                terminate access granted to existing Users at any time without
                according any reasons for doing so. Use of the account is not
                available to any User who is suspended or prohibited by COVESTRO
                from using the Platform or Services for any reason whatsoever.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>MEMBERSHIP/REGISTRATION AND ELIGIBILITY</h3>
          <ol>
            <li>
              <p>
                In order to access the Products and Services, you will need to
                register on the Platform and create a “Member” account. Your
                account gives you access to the Products and Services and
                functionality that we may establish and maintain from time to
                time and in our sole discretion on the Platform.
              </p>
            </li>
            <li>
              <p>
                By completing the registration process, you warrant that: (a)
                all the data provided by you is accurate and complete; (b) you
                shall maintain the accuracy of such information, and any changes
                thereto by regular updating of any such information; (c) you
                affirm that you are over 16 years of age and are fully able and
                competent to enter into the terms, conditions, obligations,
                affirmations, representations, and warranties set forth in this
                Agreement, and to abide by and comply with this Agreement; (d)
                if you are under 18 (eighteen) years of age, your parent or
                guardian must review and accept the terms of this Agreement, and
                by using the Platform, you confirm that your parent or guardian
                has so reviewed and accepted this Agreement. We reserve the
                right to limit the availability to users under the age of 18 of
                certain features in the Platform, in our sole discretion. (e)
                you are eligible in accordance with applicable laws to enter
                into a binding contract and are not a person barred from
                receiving the Products and Services under applicable laws; and
                (f) you may use the Platform or such other Services provided
                through the Platform only in compliance with this Agreement and
                all applicable local, state, national, and international laws,
                rules and regulations. We shall not be liable for any injury,
                damage or other consequence, health related or otherwise arising
                out of any inaccuracy in the information provided by you on the
                Platform. Your profile may be deleted by us by informing you 24
                (twenty-four) hours in advance without warning, or without any
                notice whatsoever, if we believe that you have violated any of
                the conditions as mentioned under this Agreement or the Privacy
                Policy.
              </p>
            </li>
            <li>
              <p>
                You must provide your full legal name, phone number, valid email
                address and any other information requested from you, from time
                to time, in order to complete the registration process and be
                entitled to use the Products and Services. The information
                provided by you may be shared by us with any third party for
                providing the Products and Services, record keeping purposes,
                internal procedures or for any other purposes and by using this
                Platform you expressly consent to such sharing of the
                information provided by you.
              </p>
            </li>
            <li>
              <p>
                Upon completing the registration process, you will be provided
                with a user identification code, password or any other piece of
                information, as part of our security procedures. You must treat
                such information as confidential, and you must not disclose it
                to any third party. You also agree to ensure that you exit/log
                out from your account at the end of each session. We have the
                right to disable any user identification code or password,
                whether chosen by you or allocated by us, at any time, if in our
                opinion you have failed to comply with any of the provisions of
                this Agreement. You will immediately notify COVESTRO of any
                unauthorized use of your password or user identification code,
                by sending details of such unauthorized use to:{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                On completion of the registration process on the Platform, you
                will be a member which:
              </p>
              <ol>
                <li>
                  <p>
                    allows you to access and post products as an invitation for
                    sale or purchase on the Platform;
                  </p>
                </li>
                <li>
                  <p>
                    allows us to contact you, if necessary, in order to inform
                    you of changes to the terms of use of the Platform or
                    describing new services that we enable on the Platform;
                  </p>
                </li>
                <li>
                  <p>
                    allows you to access and collect information on the Products
                    and Services on the Platform, and thereafter contact the
                    originator of such information, subject to the terms and
                    conditions set out herein;
                  </p>
                </li>
                <li>
                  <p>
                    does not allow you to use email addresses displayed on the
                    Platform for any purpose other than that specifically
                    authorized by us.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                By providing COVESTRO your email address/ phone number you
                consent to:
              </p>
              <ol>
                <li>
                  <p>
                    Our using your email address or mobile number to send you
                    Service-related notices, including any notices required by
                    law, in lieu of communication by postal mail. You may use
                    your settings to opt out of service-related communications
                    vide email/mobile number or entirely.
                  </p>
                </li>
                <li>
                  <p>
                    Our using the phone numbers provided by you, to contact you
                    from time to time, in order to provide you updates and
                    advice relating to your progress on the Platform and the
                    usage of our Services, and relating to any new promotions
                    and sales opportunities on the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    Our using of your email address, home address, phone number
                    and other ancillary information to fulfil the orders placed
                    by you on the Platform; and
                  </p>
                </li>
                <li>
                  <p>
                    Our using your email address or phone number to send you
                    other messages, including changes to features of Products
                    and Services and special offers. If you do not want to
                    receive such email messages, you may opt out by sending us
                    an email at:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>
                    . Opting out may prevent you from receiving email messages
                    or phone messages regarding updates, improvements, or
                    offers.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>TERMS OF USE OF THE PLATFORM</h3>
          <ol>
            <li>
              <p>
                You may access and use the Platform to access information on
                invitation for sale, purchase and utilize the Products and
                Services listed thereon by third parties from time to time.
              </p>
            </li>
            <li>
              <p>
                COVESTRO’s responsibility in terms of the Platform shall extend
                solely and exclusively to:
              </p>
              <ol>
                <li>
                  <p>The provision of the platform on the Platform;</p>
                </li>
                <li>
                  <p>
                    Serving as a marketplace to ensure accessibility and
                    availability of the Products and Services to the Users who
                    have registered on the Platform.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                It is hereby clarified that the Products and Services listed on
                the Platform are Products and Services owned and sold by
                third-party vendors. The third-party vendor’s Products and
                Services do not belong to COVESTRO in any manner whatsoever.
                Thus, COVESTRO makes no warranty for, and shall not be
                responsible in any manner whatsoever for the quality, quantity
                or authenticity of the Products and Services of third- party
                vendors.
              </p>
            </li>
            <li>
              <p>
                There shall be no refunds or cancellations initiated or
                entertained, once an order or registration is placed and
                processed on the Platform, save as set out herein.
              </p>
            </li>
            <li>
              <p>
                Subject to these terms and conditions of use, COVESTRO grants
                you a non-transferable, non-exclusive, non-sublicensable limited
                right and license for you to access and use the Platform solely
                for your sole benefit.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>RESTRICTION ON USE OF SERVICES</h3>
          <ol>
            <li>
              <p>
                You are not permitted to and shall not do any of the following
                acts, the breach of which shall entitle COVESTRO to terminate
                your use of the Products and Services, without prejudice to any
                other rights or remedies we may have against you:
              </p>
              <ol>
                <li>
                  <p>
                    Distribute, sell, supply, modify, adapt, amend, incorporate,
                    merge or otherwise alter the Platform and all content
                    provided to you as part of the Products and Services;
                  </p>
                </li>
                <li>
                  <p>
                    Attempt to decompile, reverse engineer or otherwise
                    disassemble any Service or content provided to you as part
                    of the Platform or the Website or the App;
                  </p>
                </li>
                <li>
                  <p>
                    Attempt to copy any software provided to you as part of the
                    Platform or the Website or the App and in particular its
                    source code, or attempt to decrypt any part of such software
                    that is provided to you;
                  </p>
                </li>
                <li>
                  <p>
                    Create any derivative work or version of any software
                    provided by us in relation to or to facilitate your use of
                    the Platform or any content provided as part of the Products
                    and Services;
                  </p>
                </li>
                <li>
                  <p>
                    Use any of COVESTRO’s domain name as a pseudonymous return
                    email address;
                  </p>
                </li>
                <li>
                  <p>
                    Access or attempt to access any content that you are not
                    authorized to access by any means;
                  </p>
                </li>
                <li>
                  <p>
                    Remove, from the Products and Services or any content
                    provided as part of the Products and Services, any logo,
                    copyright or proprietary notices, legends, symbols, labels,
                    watermarks, signatures or any other like marks affixed to or
                    embedded in the Products and Services;
                  </p>
                </li>
                <li>
                  <p>
                    Use the Products and Services or any part of it to create a
                    false identity, to impersonate any person or organization,
                    or attempt to disguise the origin of any content;
                  </p>
                </li>
                <li>
                  <p>
                    Access or use the Platform in any manner that could damage,
                    disable, overburden, or impair any of the Platform’s servers
                    or the networks connected to any of the servers on which the
                    Platform is hosted;
                  </p>
                </li>
                <li>
                  <p>
                    Access the Platform through any other means other than
                    through the interface that is provided by us;
                  </p>
                </li>
                <li>
                  <p>
                    Alter or modify any part of the Products and Services; and
                  </p>
                </li>
                <li>
                  <p>
                    Disrupt or interfere with the security of, or otherwise
                    cause harm to the Platform, materials, system resources, or
                    gain unauthorized access to the user accounts, passwords,
                    servers or networks connected to or accessible through the
                    Platform or any affiliated linked sites.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>CONTENT</h3>
          <ol>
            <li>
              <p>
                Whenever you make use of the Products and Services (or any
                feature or part of the Products and Services) that allows you to
                upload material on the Platform, or to make contact with other
                users of our Platform or vendors on the Platform, you must
                comply with applicable data protection rules/laws and the
                content standards set out below. The content standards apply to
                each part of a contribution as well as to its whole. Your
                contribution must be accurate where it states facts, be
                genuinely held (where it states opinions), comply with the law
                applicable in any country from which it is posted; and be
                relevant to your posting or use of the Products and Services.
              </p>
            </li>
            <li>
              <p>
                A search using the Products and Services may produce results and
                links to sites and content that you may find objectionable,
                inappropriate, or offensive and we accept no liability of
                responsibility for any such content. It is also possible that
                your use of the Products and Services will return to you
                information regarding Products and Services, merchants and links
                to websites of third parties selling the product information
                requested by you. COVESTRO hereby disclaims any and all
                responsibility and liability associated with the same.
              </p>
            </li>
            <li>
              <p>
                You acknowledge and agree that COVESTRO is not liable for any
                loss or damage which may be incurred by you as a result of the
                availability of such external sites or resources, or as a result
                of any reliance placed by you on the completeness, accuracy or
                existence of any advertising, Products and Services or other
                materials on, or available from, such web sites or resources.
              </p>
            </li>
            <li>
              <p>
                You shall be responsible for monitoring your content, documents
                and other data and shall be liable to us for ensuring that the
                content transferred to or handled by or within Services does not
                infringe any third-party rights. You warrant that you possess
                such necessary licenses as may be required in order to process
                your content or use the Products and Services.
              </p>
            </li>
            <li>
              <p>
                You understand that the technical processing and transmission of
                the Products and Services, including your Content, may be
                transferred unencrypted and involve: (a) transmissions over
                various networks; and (b) changes to conform and adapt to
                technical requirements of connecting networks or devices.
              </p>
            </li>
            <li>
              <p>
                You hereby acknowledge and understand that as a part of selling
                Products and Services on the Platform, various vendors may post
                images, descriptions and other content. We as COVESTRO are
                merely a platform to facilitate in providing marketplace
                services and will have no liability in relation to such content
                posted by the said third party vendors, including without
                limitation, in terms of the authenticity or reliability of such
                content, in any manner whatsoever.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>LIMITED LICENSE TO THE APP</h3>
          <ol>
            <li>
              <p>
                We grant you a personal, worldwide, revocable, non-transferable
                and non-exclusive license to access and use the Platform for
                personal and non-commercial purposes in accordance with the
                terms of this Agreement.
              </p>
            </li>
            <li>
              <p>
                All rights, title, and interest in and to the Platform not
                expressly granted in this Agreement are reserved by the
                COVESTRO. If you wish to use the COVESTRO’s software, title,
                trade name, trademark, service mark, logo, domain name and/or
                any other identification with notable brand features or other
                content owned by the COVESTRO, you must obtain written
                permission from the COVESTRO. Permission requests may be sent to{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                To avoid any doubt, the COVESTRO owns all the text, images,
                photos, audio, video, location data, and all other forms of data
                or communication that the COVESTRO creates and makes available
                in connection with the Platform, including, but not limited to
                visual interfaces, interactive features, graphics, design,
                compilation of User Content, and the compilation of aggregate
                user review ratings and all other elements and components of the
                Platform, excluding User Content (as defined above). Except as
                expressly and unambiguously provided herein, we do not grant you
                any express or implied rights, and all rights in and to the
                Platform and the COVESTRO’s Content are retained by us.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>BREACH OF CONTENT STANDARDS</h3>
          <ol>
            <li>
              <p>
                When we consider that a breach of the content standards (as
                stated above) has occurred, we may at our discretion take such
                action as we deem appropriate. Failure to comply constitutes a
                material breach of the terms of use on which you are permitted
                to use the Service, and we may take any action legally available
                including all or any of the following actions:
              </p>
              <ol>
                <li>
                  <p>
                    Immediate, temporary or permanent withdrawal of your right
                    to use the Products and Services and simultaneous
                    termination/suspension of your account on the Platform;
                  </p>
                </li>
                <li>
                  <p>
                    Immediate, temporary or permanent removal of any
                    contribution already posted on the Platform using the
                    Products and Services, upon your failure to cure the breach
                    that is brought to your notice;
                  </p>
                </li>
                <li>
                  <p>Issue of a warning to you;</p>
                </li>
                <li>
                  <p>Further legal action against you;</p>
                </li>
                <li>
                  <p>
                    Legal proceedings against you for reimbursement of all costs
                    on an indemnity basis (including, but not limited to,
                    reasonable administrative and legal costs) resulting from
                    the breach; and
                  </p>
                </li>
                <li>
                  <p>
                    Disclosure of such information to law enforcement
                    authorities as we reasonably feel is necessary or as
                    required by law.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                We hereby disclaim and exclude our liability for all action we
                may take in response to breaches of these rules. The actions
                described above are not limited, and we may take any other
                action we reasonably deem appropriate.
              </p>
            </li>
            <li>
              <p>
                Upon such termination/suspension of your account, you will no
                longer be able to access your account or any content or data you
                have stored on the servers. All licenses granted to you by
                COVESTRO to the Platform or the Products and Services will
                automatically terminate.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>BILLING AND PAYMENT</h3>
          <ol>
            <li>
              <p>
                Certain aspects of the Platform may be provided for a fee or
                other charge. If you elect to use paid aspects of the Platform,
                you agree to the terms of sale, pricing, payment and billing
                policies applicable to such fees and charges. COVESTRO may add
                new services for additional fees and charges, or amend fees and
                charges for existing services, at any time in its sole
                discretion.
              </p>
            </li>
            <li>
              <p>
                It is your responsibility to promptly provide COVESTRO with any
                contact or billing information changes or updates (including
                phone number, email address, credit card numbers, etc.).
                COVESTRO does not validate all credit card information required
                by the Customer&#39;s payment provider to secure payment.
              </p>
            </li>
            <li>
              <p>
                In respect of purchases made on the Platform, you hereby
                acknowledge and agree that no refund requests will be
                entertained in any manner whatsoever and no refunds will be
                initiated therefor.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>INDEMNITY AND LIMITATION OF LIABILITY</h3>
          <ol>
            <li>
              <p>
                You agree to defend, indemnify and hold harmless COVESTRO, its
                officers, directors, employees and agents, from and against any
                and all claims, damages, obligations, losses, liabilities, costs
                or debt, and expenses (including, but not limited to
                attorney&#39;s fees) arising from: (i) your use of and access to
                Products and Services and the Platform; (ii) your violation of
                any term of this Terms; (iii) your violation of any third party
                right, including without limitation, any copyright, property, or
                privacy right; or (iv) any claim that your User Content caused
                damage to a third party. This defense and indemnification
                obligation will survive this Agreement and your use of Products
                and Services and Platform.
              </p>
            </li>
            <li>
              <p>
                The material displayed on our Website or App is provided without
                any guarantees, conditions or warranties as to its accuracy. To
                the extent permitted by law, we, our Subsidiaries and Affiliates
                and third parties connected to us hereby expressly exclude:
              </p>
              <ol>
                <li>
                  <p>
                    conditions, warranties and other terms, which might
                    otherwise be implied by statute, common law or the law of
                    equity;
                  </p>
                </li>
                <li>
                  <p>
                    Any liability for any direct, indirect or consequential loss
                    or damage incurred by any user in connection with the
                    Products and Services, our Platform or in connection with
                    the use, inability to use, or results of the use of the
                    Products and Services or the Platform, any websites linked
                    to it and any materials posted on it, including, but not
                    limited to:
                  </p>
                  <ol>
                    <li>
                      <p>loss of income or revenue;</p>
                    </li>
                    <li>
                      <p>loss of business;</p>
                    </li>
                    <li>
                      <p>loss of profits or contracts;</p>
                    </li>
                    <li>
                      <p>loss of anticipated savings;</p>
                    </li>
                    <li>
                      <p>loss of data;</p>
                    </li>
                    <li>
                      <p>loss of goodwill;</p>
                    </li>
                    <li>
                      <p>wasted management or office time; and</p>
                    </li>
                    <li>
                      <p>
                        for any other loss or damage of any kind, however
                        arising and whether caused by tort (including
                        negligence), breach of contract or otherwise, even if
                        foreseeable or advised of the possibility of the same.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Neither Party shall be liable in contract, tort (including,
                without limitation, negligence) or otherwise arising out of or
                in connection with this Terms for;
                <br />
                a) any economic losses (including, without limitation, loss of
                revenues, profits, contracts, data, business, anticipated
                savings or cost of substitute services);
                <br /> b) any loss of goodwill or reputation;
                <br /> c) any special, indirect or consequential losses; whether
                or not such losses were within the contemplation of the Parties
                at the date of this Terms.
              </p>
            </li>
            <li>
              <p>
                You agree that COVESTRO and its wholly owned subsidiaries shall
                not be responsible for or have any liability in relation to
                unauthorized access to or alteration of the Customer Data or
                data from Your exercise.
              </p>
            </li>
            <li>
              <p>
                COVESTRO shall have no liability for any error, defect, failure
                or delay in the Products and Services. Without prejudice to the
                generality of the foregoing, COVESTRO shall have no liability
                for any failure or delay in the Products and Services: any
                special, indirect or consequential losses; whether or not such
                losses were within the contemplation of the Parties at the date
                of this Terms.
                <br />
                a) caused by outages to any public Internet backbones, networks
                or servers; any special, indirect or consequential losses;
                whether or not such losses were within the contemplation of the
                Parties at the date of this Terms.
                <br />
                b) caused by any failures of Your equipment, systems or local
                access services or
                <br />
                c) due to previously scheduled maintenance.
              </p>
            </li>
            <li>
              <p>
                COVESTRO’s (and its wholly owned subsidiaries’) total cumulative
                liability (whether in contract, tort (including negligence) or
                otherwise) to you under or in connection with these Terms shall
                not exceed the 50 percent of the membership fees paid by you.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>OFFENCES</h3>
          <ol>
            <li>
              <p>
                You must not misuse the Platform by knowingly introducing
                viruses, trojans, worms, logic bombs, time bombs, cancel bots,
                spyware or other material, computer programming routines, codes,
                files or such other programs which are malicious or
                technologically harmful, or limit the interests of rights of
                other users or limit the functionality of any computer software,
                hardware or telecommunications. You must not attempt to gain
                unauthorized access to the Platform, the server on which the
                Platform is stored or any server, computer or database connected
                to our site. You must not attack the Platform via a
                denial-of-service attack or a distributed denial-of service
                attack.
              </p>
            </li>
            <li>
              <p>
                By breaching this provision, you would commit a criminal offence
                under the Information Technology Act, 2000 (and any amendments).
                We will report any such breach to the relevant law enforcement
                authorities and we will co-operate with those authorities by
                disclosing your identity to them. In the event of such a breach,
                your right to use the Platform will cease immediately.
              </p>
            </li>
            <li>
              <p>
                We will not be liable for any loss or damage caused by a
                distributed denial-of-service attack, viruses or other
                technologically harmful material that may infect your computer
                equipment, computer programs, data or other proprietary material
                due to your use of the Platform or to your downloading of any
                material posted on it, or on any Platform linked to it.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ADDITIONAL TERMS FOR THE MOBILE APP</h3>
          <ol>
            <li>
              <p>
                You are granted a limited, non-sub-licensable right to access
                the Mobile App, Desktop App, the Products and Services and Data
                for the purpose of enabling you or other users to access the
                Website or the App and/or the Products and Services via third
                party software or website. Any use of the Mobile App, Desktop
                App, is bound by the terms of this agreement plus the following
                specific terms:
              </p>
              <ol>
                <li>
                  <p>
                    You agree that you are solely responsible for (and that we
                    have no responsibility or liability to you or to any third
                    party for) any services and/or Products and Services you
                    provide through any third-party software;
                  </p>
                </li>
                <li>
                  <p>
                    You will not use the Mobile App or the Desktop App to create
                    software that sends unsolicited communications (whether
                    commercial or otherwise) to any third party;
                  </p>
                </li>
                <li>
                  <p>
                    We reserve the right at any time to modify or discontinue,
                    temporarily or permanently, your access to the Mobile App
                    and the Desktop App by providing a notice of 48
                    (forty-eight) hours;
                  </p>
                </li>
                <li>
                  <p>
                    You expressly understand and agree that we shall not be
                    liable for any direct, indirect, incidental, special,
                    consequential or exemplary damages, including, but not
                    limited to, damages for loss of profits, goodwill, use, data
                    or other intangible losses (even if we have been advised of
                    the possibility of such damages), resulting from your use of
                    the Mobile App and Desktop App;
                  </p>
                </li>
                <li>
                  <p>
                    We reserve the right at any time to modify or discontinue,
                    temporarily or permanently the Desktop App and Mobile App by
                    providing a notice of 48 (forty- eight) hours with or
                    without notice;
                  </p>
                </li>
                <li>
                  <p>
                    Abuse or excessively frequent requests to the Products and
                    Services via the Desktop App and Mobile App may result in
                    the temporary or permanent suspension of your account’s
                    access. We, in our sole discretion, will determine abuse or
                    excessive usage;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>AUTHORISED ACCESS</h3>
          <ol>
            <li>
              <p>
                We care for your convenience and time, towards which we
                constantly try and ensure that the Platform is always up and
                available, however, technical glitches and other non-human
                errors are bound to occur.
              </p>
            </li>
            <li>
              <p>
                Towards maintaining your seamless experience, we reserve the
                right to shut-down the Platform/Server/Links/Web-page, wholly or
                partly thereof, for such duration of time towards general
                maintenance, but nonetheless will attempt to keep the down-time
                minimal to ensure your satisfaction.
              </p>
            </li>
            <li>
              <p>
                It is affirmed and deemed to be understood by you that we shall
                not be liable for any reason whatsoever, if the Platform, wholly
                or any part thereof, is unavailable for such duration of time,
                owing towards maintenance.
              </p>
            </li>
            <li>
              <p>
                Towards the usage of our services, as part of our security
                procedure, we require you to register an account with us by
                entering your email or mobile number and choosing a secure
                password and/or by entering the One Time Password (OTP) sent to
                your mobile number or email address. In case of user passwords,
                we highly recommend that you choose a strong password that is
                hard for others to anticipate and you log out from your account
                at the end of every session. For the purpose of convenience, a
                password reset procedure is available in case you forget your
                password. It is hereby agreed by you that you shall treat your
                account log-in information as confidential, by not disclosing it
                to any third party and use them in person. If you think there
                may have been any breach of security then it is your
                responsibility to notify us immediately and where log-in is not
                blocked, to change your password immediately.
              </p>
            </li>
            <li>
              <p>
                It is a condition of your use of the Platform that all the
                information you provide on the Platform is correct, current and
                complete. We reserve the right to disable any user account in
                our sole discretion, at any time for such reason, including if,
                in our opinion and sole discretion, you have failed to comply
                with any provision of these Terms of Service.
              </p>
            </li>
            <li>
              <p>
                You agree that the Platform is an operation without any Human
                Intervention, and thereby acknowledge and understand that the
                Platform or any content provided on the Platform is not free
                from error, and hereby consent that any inconvenience or loss
                occurred thereof, unless caused by Human Intervention, will not
                be treated as a Breach or Deficiency of Service.
              </p>
            </li>
          </ol>
        </li>
        <p>
          It is undertaken by the COVESTRO that all Technical Glitches, that do
          not amount to an Impossible Event or catastrophic failure, or caused
          on grounds of Natural Calamity or Fire, Government Order, riots or
          such other factors beyond the control of the COVESTRO, shall be
          rectified within 90 to 120 Hours. However, COVESTRO cannot be held
          liable for not providing access to the website due to some technical
          glitches, natural calamity, Government Order, riots or due to any
          other event.
        </p>
        <li>
          <h3>CONFIDENTIALITY</h3>
          <ol>
            <li>
              <p>
                For the purposes of this Terms, “Confidential Information”
                includes any proprietary data and any other information
                disclosed by one party to the other in writing and marked
                “confidential” or disclosed orally and, within five business
                days, reduced to writing and marked “confidential”. However,
                Confidential Information will not include any information that
                is or becomes known to the general public, which is already in
                the receiving party’s possession prior to disclosure by a party
                or which is independently developed by the receiving party
                without the use of Confidential Information or any allowed usage
                by COVESTRO of information as described in this Terms.
              </p>
            </li>
            <li>
              <p>
                Neither party will use or disclose the other party’s
                Confidential Information without the other’s prior written
                consent except for the purpose of performing its obligations
                under this Terms or if required by law, regulation or court
                order; in which case, the party being compelled to disclose
                Confidential Information will give the other party as much
                notice as is reasonably practicable prior to disclosing the
                Confidential Information.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>PRIVACY AND PROTECTION OF PERSONAL DATA</h3>
          <ol>
            <li>
              <p>
                By registering on the Site, you agree to provide us with sincere
                and true information.
              </p>
            </li>
            <li>
              <p>
                Communicating false information is contrary to the present
                general Terms and Conditions, and shall make you liable for
                consequential legal action.
              </p>
            </li>
            <li>
              <p>
                You can at any time make a request to us to find out what
                personal information we hold concerning you. You may at any
                time, and by request, modify this information.
              </p>
            </li>
            <li>
              <p>
                Cookies record certain information stored on your hard drive.
                This information enables us to generate statistics based on data
                from visitors to our site and suggest Products and Services that
                might interest you according to the items that you selected when
                you previously visited the site. Cookies do not contain any
                confidential information about you.
              </p>
            </li>
            <li>
              <p>
                The Platform may require you to provide data in order to access
                certain services. The Information may pertain to such matters,
                including, but not limited to the details relevant to your
                order. Whilst Privacy of such information is the cornerstone of
                our terms, however, you acknowledge, agree and consent that we
                may retain and use, subject to the terms of our privacy policy{' '}
                <a href={`${APP_DOMAIN}legal/privacy-statement`}>
                  {APP_DOMAIN}legal/privacy-statement
                </a>
                , information or data collected in Your use of the Products and
                Services for our Business, Research and Analysis.
              </p>
            </li>
            <li>
              <p>
                We will not share Your Data or any Third Party&#39;s Customer
                Data with any third parties, except in accordance with these
                Terms unless we (i) conclude that it is required by law or has a
                good faith belief that access, preservation or disclosure of
                Data is reasonably necessary to protect our rights, property or
                safety, or that of our users or the public; or (ii) provide Data
                in certain limited circumstances to third parties to carry out
                tasks on our behalf (e.g., billing or data storage) with strict
                restrictions that prevent the data from being used or shared
                except as directed by us.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>INTELLECTUAL PROPERTY</h3>
          <ol>
            <li>
              <p>
                All the content on our Platform (illustrations, texts, names,
                brand names, images and videos) is the property of the COVESTRO.
              </p>
            </li>
            <li>
              <p>
                Any partial or total reproduction of this content, by any means
                and on any support, is subject to prior and express
                authorization by COVESTRO.
              </p>
            </li>
            <li>
              <p>
                All information, content and files are protected by the law
                concerning intellectual ownership and copyright.
              </p>
            </li>
            <li>
              <p>
                We shall not give you the right to copy, display or distribute
                content that you do not have the intellectual rights to.
              </p>
            </li>
            <li>
              <p>
                All fraudulent use of this content shall violate the rights held
                by a third party constitutes an offence of forgery, which is
                severely punished under the Intellectual Property Laws.
              </p>
            </li>
            <li>
              <p>
                We will not, under any circumstances, be held responsible if a
                user violates rights held by a third party through his
                activities on the site.
              </p>
            </li>
            <li>
              <p>
                Users are prohibited from using, transposing, copying,
                distributing or modifying all or part of the data, images and
                any other form of content obtained from this Platform for any
                purpose other than personal use, as specified in copyright laws,
                or that which is explicitly permitted by other laws, without the
                consent of the Company or the holders of rights to said data,
                images and content.
              </p>
            </li>
            <li>
              <p>
                COVESTRO shall not be liable for any infringement of
                intellectual property rights arising out of materials posted on
                or transmitted through the site, or items advertised on the
                site, by end-users, or any other third party.
              </p>
              <ol>
                <li>
                  <p>
                    If you are an owner of intellectual property rights or an
                    agent who is fully authorized to act on behalf of the owner
                    of intellectual property rights and believe that any Content
                    or other content infringes upon your intellectual property
                    right or intellectual property right of the owner on whose
                    behalf you are authorized to act, you may submit an email to
                    COVESTRO with a request to delete the relevant content in
                    good faith to the email id: email to{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>
                    .
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>DISCLAIMER OF WARRANTIES</h3>
          <ol>
            <li>
              <p>
                COVESTRO may make improvements and/or changes in Products and
                Services or Software at any time, with or without notice. The
                Service, the Software and the reports relating to exercises are
                provided “AS IS” and COVESTRO gives no warranties with respect
                to any of them. Without limiting the generality of the
                foregoing, COVESTRO does not represent or warrant that:
                <br />
                a) the use of Products and Services or the Software will meet
                your requirements or be uninterrupted, secure or error-free;
                <br />
                b) the defects will be corrected;
                <br />
                c) the Service, the Software or any other software on the Server
                are free of viruses or other harmful components;
                <br />
                d) the use of Products and Services and any information
                available through Products and Services(including the reports
                relating to exercises) will be correct, accurate, timely or
                otherwise reliable.
              </p>
            </li>
            <li>
              <p>
                We have implemented commercially reasonable technical and
                organizational measures designed to secure your personal
                information and User Content from accidental loss and from
                unauthorized access, use, alteration or disclosure. However, we
                cannot guarantee that unauthorized third parties will never be
                able to defeat those measures or use your personal information
                and User Content for improper purposes. You acknowledge that you
                provide your personal information at your own risk.
              </p>
            </li>
            <li>
              <p>
                Your correspondence or business dealings with, or participation
                in promotions of, advertisers found on or through Products and
                Services or of vendors on the Platform, including payment of
                related goods or services, and any other terms, conditions,
                warranties or representations associated with such dealings, are
                solely between You and such advertiser or vendor. You agree
                that, save as set out herein, COVESTRO shall not be responsible
                or liable for any loss or damage of any sort incurred as the
                result of any such dealings or as the result of the presence of
                such advertisers or the vendors on the Products and Services/the
                Marketplace.
              </p>
            </li>
            <li>
              <p>
                The Products and Services may provide, or third parties may
                provide, links to other web sites or resources. Given that we
                have no control over such sites and resources, you acknowledge
                and agree that we are not responsible for the availability of
                such external sites or resources, and do not endorse and are not
                responsible or liable for any content, advertising, Products and
                Services or other materials on or available from such sites or
                resources. You further acknowledge and agree that we shall not
                be responsible or liable, directly or indirectly, for any damage
                or loss caused or alleged to be caused by or in connection with
                use of or reliance on any such content, goods or services
                available on or through any such site or resource.
              </p>
            </li>
            <li>
              <p>
                You agree that your use of the Platform and the Products and
                Services shall be at your sole risk. You expressly understand
                and agree that, to the maximum extent permitted by applicable
                law, the Platform and the Products and Services are provided on
                an “as is” and “as available” basis for your use, without
                warranties of any kind, express or implied, including without
                limitation the warranties of merchantability, fitness for a
                particular purpose, title, non-infringement, and those arising
                from course of dealing or usage of trade.
              </p>
            </li>
            <li>
              <p>
                We have the right to disclose your identity to any third party
                who validly claims and provides evidence that any material
                posted or uploaded by you to the Platform constitutes a
                violation of their intellectual property rights, or of their
                right to privacy.
              </p>
            </li>
            <li>
              <p>
                We are not responsible, or liable to any third party, for the
                content or accuracy of any materials posted by you or any other
                User of the Products and Services.
              </p>
            </li>
            <li>
              <p>
                We have the right to immediately terminate your access or usage
                rights and remove non- compliant information or material, in
                case of non-compliance with these terms and condition, this
                Agreement or the Privacy Policy.
              </p>
            </li>
            <li>
              <p>
                We have the right to investigate and prosecute violations of
                these terms and conditions to the fullest extent of the law and
                may involve and cooperate with law enforcement authorities in
                prosecuting Users who violate these terms and conditions.
              </p>
            </li>
            <li>
              <p>
                While we have no obligation to monitor your access to or your
                use of the Products and Services (or any feature or part of the
                Products and Services), we have the right to do so for the
                purpose of operating the Platform and providing the Products and
                Services, to ensure your compliance with these terms and
                conditions, or to comply with applicable law or the order or
                requirement of a court, administrative agency or other
                governmental body.
              </p>
            </li>
            <li>
              <p>
                Subject to the receipt of a complaint from a User, we have the
                right (but shall have no obligation) to pre-screen, review,
                flag, filter, modify, refuse or remove any or all content from
                any Service. For some of the Products and Services, COVESTRO may
                (but shall be under no obligation to) provide tools to filter
                out explicit sexual content or any content which it deems to be
                unsuitable at its sole discretion.
              </p>
            </li>
            <li>
              <p>
                We reserve the right to access, read, preserve, and disclose any
                information as we reasonably believe is necessary to: (a)
                satisfy any applicable law, regulation, legal process or
                governmental request; (b) enforce these terms and conditions,
                including investigation of potential violations hereof; (c)
                detect, prevent, or otherwise address fraud, security or
                technical issues; (d) respond to user support requests; or (e)
                protect the rights, property or safety of COVESTRO, its Users
                and the public. COVESTRO shall not be responsible or liable for
                the exercise or non-exercise of its rights under these terms and
                conditions in this regard.
              </p>
            </li>
            <li>
              <p>
                We reserve the right to introduce or change the prices of all
                Services upon 30 (thirty) days’ notice from us. Such notice may
                be provided at any time by posting the changes to the terms and
                conditions of use or the Products and Services on the Platform
                itself.
              </p>
            </li>
            <li>
              <p>
                COVESTRO shall not be liable to you or to any third party for
                any modification, price change, suspension or discontinuance of
                the Products and Services.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>USER SUBMISSION AND USER CONTENT BY THIRD PARTIES</h3>
          <ol>
            <li>
              <p>
                You understand that when using the Platform, you will be exposed
                to Content from a variety of sources and that COVESTRO is not
                responsible for the accuracy, usefulness, safety, or
                intellectual property rights of or relating to such Content, and
                you agree and assume all liability for your use.
              </p>
            </li>
            <li>
              <p>
                While COVESTRO strives to exercise best efforts to minimize
                concerns, you further understand and acknowledge that you may be
                exposed to Content that is inaccurate, offensive, indecent, or
                objectionable, defamatory, or libelous and you agree to waive,
                and hereby do waive, any legal or equitable rights or remedies
                you have or may have against COVESTRO with respect thereto.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>WAIVER AND SEVERABILITY</h3>
          <ol>
            <li>
              <p>
                Any failure of COVESTRO to exercise or enforce any right or
                provision of the Terms of Service shall not constitute a waiver
                of such right or provision.
              </p>
            </li>
            <li>
              <p>
                The Terms of Service constitutes the entire agreement between
                you and us, and governs your use of the service, superseding any
                prior agreements (including, but not limited to, any prior
                versions of the Terms of Service). If any provision of these
                Terms of Service is held by a court of competent jurisdiction to
                be invalid, illegal or unenforceable for any reason, such
                provision shall be eliminated or limited to the minimum extent
                such that the remaining provisions of the Terms of Service will
                continue in full force and effect.
              </p>
            </li>
            <li>
              <p>
                You expressly acknowledge and agree that COVESTRO shall not be
                liable for your interactions with any organizations and/or
                individuals on the Platform or through the Service. This
                includes, but is not limited to, payment and delivery of goods
                and services, and any other terms, conditions, warranties, or
                representations associated with any interaction you may have
                with other organizations and/or individuals. These dealings are
                solely between you and such organizations and/or individuals.
              </p>
            </li>
            <li>
              <p>
                You agree and acknowledge that COVESTRO shall not be responsible
                or liable for any loss or damage of any sort incurred as the
                result of any such dealings or interactions. If there is a
                dispute between participants on the Platform, or between users
                and any third party, you understand and agree that COVESTRO is
                under no obligation to become involved in such dispute.
              </p>
            </li>
            <li>
              <p>
                In the event that you have a dispute with one or more other
                users, you hereby release COVESTRO, its officers, employees,
                agents, and successors from any and all claims, demands, and
                damages (actual and consequential) of every kind or nature,
                known or unknown, suspected and unsuspected, disclosed and
                undisclosed, arising out of or in any way related to such
                disputes and/or our Service.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>USE OF MOBILE DEVICES</h3>
          <ol>
            <li>
              <p>
                Please note that your carrier’s normal rates and fees, such as
                text messaging and data charges, will still apply if you are
                using the App on a mobile device.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>PROHIBITED ACTIONS</h3>
          <ol>
            <li>
              <p>
                Users of this Platform are prohibited from engaging in the
                following actions, including those prohibited by other
                provisions of these terms and conditions:
              </p>
              <ul>
                - Actions that will or may infringe on the copyrights, privacy,
                property and other rights or interests of other users of this
                Platform, third parties, the Company or its partner companies.
              </ul>
              <ul>
                - Actions that will or may cause disadvantage, financial loss or
                other damages to other users of this Platform, third parties,
                the Company, or its partner companies.
              </ul>
              <ul>
                - Actions that will or may be offensive to public order and
                morals, or violate laws, regulations, and ordinances.
              </ul>
              <ul>
                - False declarations when using this Platform for membership
                registration or other purposes.
              </ul>
              <ul>
                - Illegitimate use of email addresses, passwords, or other
                personal information.
              </ul>
              <ul>
                - Using this Platform to solicit business or achieve other
                commercial ends for profit, or for preparations thereto.
              </ul>
              <ul>
                - Other actions that may interrupt the operation of this
                Platform or interfere with the provision of services.
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>GOVERNING LAWS</h3>
          <ol>
            <li>
              <p>
                The formulation, legal force, application and interpretation of
                these terms and conditions of use shall be governed by the laws
                of India.
              </p>
            </li>
            <li>
              <p>
                COVESTRO accepts no liability whatsoever, direct or indirect,
                for noncompliance with the laws of any country other than that
                of India, the mere fact that Platform can be accessed or used or
                any facility can be availed of in a country other than India
                will not imply that we accede to the laws of such country.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>JURISDICTION</h3>
          <ol>
            <li>
              <p>
                Disputes between a user and the Company or partner company over
                the use of this Platform shall be exclusively heard in the
                Courts of Mumbai, Maharashtra.
              </p>
            </li>
            <li>
              <p>
                Notwithstanding the foregoing, you agree that COVESTRO shall
                have the right to seek injunctive remedies (or an equivalent
                type of urgent legal relief) in any court of competent
                jurisdiction.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>MODIFICATION OF TERMS AND THE PLATFORM</h3>
          <ol>
            <li>
              <p>
                The Platform is a continuous work in progress, and as such is
                subject to modification by way of additions or deletions or
                substitutions or otherwise.
              </p>
            </li>
            <li>
              <p>
                We reserve the right to update the Website, App and these Terms
                from time to time, at our discretion and without any notice.
              </p>
            </li>
            <li>
              <p>
                It shall be the sole responsibility and duty of the customer to
                be aware of the latest Terms by reading the same, each time when
                the Platform or Services are accessed.
              </p>
            </li>
            <li>
              <p>
                Upon continued use of the Platform subsequent to the publishing
                of the updated Terms of Service, it shall be deemed that the
                user has read and agreed to the modified terms.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>USE AT YOUR OWN RISK</h3>
          <ol>
            <li>
              <p>
                Our goal is to provide marketplace services by developing
                original, research-based solutions to help, induce and ensure
                food security. However, the Platform cannot and does not
                guarantee the quality of any third-party vendors. Your use of
                the Platform and any information, predictions, or suggestions
                provided in the Platform are at your sole satisfaction. We make
                no representation or warranty of any kind as to the accuracy of
                quality, quantity, data, information, estimates, and predictions
                that we may provide to you through the Platform and you agree
                and understand that the Platform is intended to serve as an
                electronic market place, and are subject to the risk of the
                buyer.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>OPINIONS AND FEEDBACK</h3>
          <ol>
            <li>
              <p>
                Any opinions given by yourself on the Platform to the COVESTRO
                is subject to screening and moderation.
              </p>
            </li>
            <li>
              <p>
                If it is found that the comments infringe on the law or are
                inappropriate (abusive publicity, defamation, insults, out of
                context commentary), we reserve the right to refuse or modify
                it, and take appropriate action.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TNCEN;
