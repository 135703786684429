/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import './style.scss';
import {
  IonCol,
  IonGrid,
  IonImg,
  IonPopover,
  IonRow,
  useIonViewWillLeave,
} from '@ionic/react';
import CLPlaceholder from '../../assets/images/cl-placeholder.png';
import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';
import ProfileImage from '../profileimg';
import { useHistory } from 'react-router';
import { AppContext } from '../../context/app.context';
import {
  getFormattedUrl,
  getLanguageText,
  getUserInitial,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import RespondBox from '../respondbox';
import NoPreviewPlacholder from '../../assets/images/no_preview.jpg';

interface Props {
  postType: string;
  postUserId?: number;
  postId?: number;
  customClass?: string;
  imgPath?: string;
  commodity?: any;
  category?: any;
  productVarietyBreed?: string;
  productQuantity?: string;
  unit?: string;
  expectedPrice?: string;
  showLogistic?: boolean;
  logisticRequired?: boolean;
  productType: string;
  farmerName: string;
  farmerLocation: string;
  farmerDist: string;
  farmerState: string;
  farmerPinCode: string;
  certification: string;
  farmerProfilePic: string;
  showContactBtn?: boolean;
  onRespondSubmit?: (
    commentText: string,
    postId: number,
    toUserId: number
  ) => Promise<any>;
}

const CommodityListCard: React.FC<Props> = ({
  postType,
  postUserId,
  postId,
  customClass,
  imgPath,
  commodity,
  category,
  productVarietyBreed,
  productQuantity,
  unit,
  expectedPrice,
  showLogistic,
  logisticRequired,
  productType,
  farmerName,
  farmerLocation,
  farmerDist,
  farmerState,
  farmerPinCode,
  certification,
  farmerProfilePic,
  showContactBtn,
  onRespondSubmit,
}) => {
  const history = useHistory();
  const { lang }: any = useContext(AppContext);
  const [popoverStateRespond, setShowPopoverRepond] = useState({
    showPopover: false,
    event: undefined,
  });
  const [respondText, setRespondText]: any = useState(
    t({
      id: 'post_contact',
      message: 'Hi, I am interested in your post',
    })
  );
  const [isResponding, setIsResponding] = useState(false);

  useIonViewWillLeave(() => {
    setShowPopoverRepond({ showPopover: false, event: undefined });
  }, []);

  return (
    <div className={`cl-card-wrapper p-16 mb-32 ${customClass || ''}`}>
      <IonGrid className='p-0'>
        <IonRow className='ion-row'>
          <IonCol
            size-xs='12'
            size-sm='7'
            className='cl-card-details-wrapper ion-col p-0'
          >
            <IonImg
              src={
                imgPath
                  ? getFormattedUrl(imgPath)
                  : postType == 'buy'
                  ? NoPreviewPlacholder
                  : CLPlaceholder
              }
              className='cl-card-image'
            />
            <div className='cl-card-details ml-20 mr-64'>
              <h3 className='product-name font-aktiv-grotest-medium mb-5'>
                {getLanguageText(
                  commodity.title,
                  'title',
                  commodity.locale,
                  lang
                )}
              </h3>
              <div className='card-product-detail'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'category',
                    message: 'Category',
                  })}{' '}
                  :
                </p>
                <p className='product-detail-disc'>
                  {getLanguageText(
                    category.name,
                    'name',
                    category.locale,
                    lang
                  )}
                </p>
              </div>
              <div className='card-product-detail'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'variety_breed',
                    message: 'Variety / Breed',
                  })}{' '}
                  :
                </p>
                <p className='product-detail-disc'>{productVarietyBreed}</p>
              </div>
              <div className='card-product-detail'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'quantity_unit',
                    message: 'Quantity & Unit',
                  })}{' '}
                  :
                </p>
                <p className='product-detail-disc'>
                  <span>{productQuantity}</span> <span>{unit}</span>
                </p>
              </div>
              {/* <div className='card-product-detail'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'price_expected_ex_farm',
                    message: 'Price Expected Ex-Farm',
                  })}{' '}
                  :
                </p>
                <p className='product-detail-disc'>
                  <span>₹ {expectedPrice}/- </span> <span>{unit}</span>
                </p>
              </div> */}
              {showLogistic && (
                <div className='card-product-detail'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'logistic_required',
                      message: 'Logistic required',
                    })}{' '}
                    :
                  </p>
                  <p className='product-detail-disc'>
                    {!logisticRequired ? (
                      <span>
                        {t({
                          id: 'no',
                          message: 'No',
                        })}
                      </span>
                    ) : (
                      <span>
                        {t({
                          id: 'yes',
                          message: 'Yes',
                        })}
                      </span>
                    )}
                  </p>
                </div>
              )}
              <div className='card-product-detail'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'type',
                    message: 'Type',
                  })}{' '}
                  :
                </p>
                <p className='product-detail-disc'>
                  {toFirstUpperCaseWord(
                    t({
                      id: productType,
                      message: productType,
                    })
                  )}
                </p>
              </div>
            </div>
          </IonCol>
          <IonCol size-xs='12' size-sm='4' className='ion-col p-0'>
            <div className='cl-card-profile-details-wrapper mb-32'>
              <ProfileImage
                userInitial={getUserInitial(farmerName)}
                customClass='cl-card-profile-img mr-24'
                imgPath={
                  farmerProfilePic
                    ? getFormattedUrl(farmerProfilePic)
                    : undefined
                }
              />
              <p className='farmer-details'>
                <h4 className='farmer-name font-aktiv-grotest-bold'>
                  {toFirstUpperCaseWord(farmerName)}
                </h4>
                <span className='farmer-detail-text'>
                  {toFirstUpperCaseWord(farmerLocation)},{' '}
                </span>
                <span className='farmer-detail-text'>
                  {toFirstUpperCaseWord(farmerDist)},{' '}
                </span>
                <span className='farmer-detail-text'>
                  {toFirstUpperCaseWord(farmerState)},{' '}
                </span>
                <span className='farmer-detail-text'>{farmerPinCode}, </span>
                <div>
                  {certification && certification != '' && (
                    <span className='farmer-detail-text'>
                      {certification},{' '}
                    </span>
                  )}
                </div>
              </p>
            </div>

            <ButtonComponent
              name={'View More'}
              customClass='cl-card-view-more mb-16'
              textKey={'view_more'}
              outline
              onClick={() => {
                history.push(`/commoditydetail/${postId}`);
              }}
            />
            {showContactBtn && (
              <ButtonComponent
                name={postType == 'buy' ? 'Contact' : 'Contact'}
                customClass='cl-card-contact-supplier'
                textKey={postType == 'buy' ? 'contact' : 'contact'}
                onClick={() => {
                  setShowPopoverRepond({ showPopover: true, event: undefined });
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonPopover
        event={popoverStateRespond.event}
        isOpen={popoverStateRespond.showPopover}
        onDidDismiss={() => {
          setShowPopoverRepond({ showPopover: false, event: undefined });
          setRespondText(
            t({
              id: 'post_contact',
              message: 'Hi, I am interested in your post',
            })
          );
        }}
        className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <RespondBox
          isSubmitting={isResponding}
          respondText={respondText}
          onRepondChange={(text) => {
            setRespondText(text);
          }}
          onRespondCancel={() => {
            setShowPopoverRepond({ showPopover: false, event: undefined });
            setRespondText(
              t({
                id: 'post_contact',
                message: 'Hi, I am interested in your post',
              })
            );
          }}
          onRespondSubmit={async () => {
            if (onRespondSubmit && postUserId && postId) {
              setIsResponding(true);
              const resp = await onRespondSubmit(
                respondText,
                postId,
                postUserId
              );
              if (resp) {
                setShowPopoverRepond({ showPopover: false, event: undefined });
                setRespondText(
                  t({
                    id: 'post_contact',
                    message: 'Hi, I am interested in your post',
                  })
                );
              }
              setIsResponding(false);
            }
          }}
        />
      </IonPopover>
    </div>
  );
};

export default CommodityListCard;
