import './style.scss';
import React, { useContext, useState } from 'react';
import { IonAvatar, IonImg } from '@ionic/react';
// import SearchIcon from '../../assets/images/icons/Search.png';
import LanguageSelect from '../languageselect';
import ButtonComponent from '../button-component';
// import ContactIcon from '../../assets/images/icons/phone.png';
import DownArrow from '../../assets/images/icons/arrow-small-down.svg';
import { t } from '@lingui/macro';
import { AppContext } from '../../context/app.context';
import { useHistory } from 'react-router';
import {
  getColorByInitial,
  getFormattedUrl,
  getUserInitial,
  isWeb,
} from '../../utils/common.helper';
import ProfilePlaceholder from '../../assets/images/icons/profile-placeholder.svg';
import { logout } from '../../services/auth.service';
import { INIT_PAGE } from '../../config/appconfig';
import FaqIcon from '../../assets/images/icons/faq.svg';
import NotiIcon from '../../assets/images/icons/notifications.svg';

const TopNavigation: React.FC = () => {
  // const [isVisible, setIsVisible] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const {
    isLoggedIn,
    userDetails,
    setIsLoggedIn,
    setUserDetails,
    isNewNotification,
    setIsInActive,
  }: any = useContext(AppContext);

  const history = useHistory();
  function onNavClick(route: string) {
    history.push(route);
  }
  return (
    <div className='page-navigation-meta-container'>
      <div className='navigation-meta__list-level1'>
        {/* {isLoggedIn == 'true' && (
          <>
            {isVisible && (
              <Trans
                id='search'
                render={() => (
                  <IonSearchbar
                    onIonClear={() => {
                      setIsVisible(false);
                    }}
                    placeholder={t({ id: 'search', message: 'Search' })}
                    debounce={1000}
                    onIonChange={(e: any) => {
                      console.log(e.target.value);
                    }}
                  ></IonSearchbar>
                )}
              >
                Search
              </Trans>
            )}
            {!isVisible && (
              <IonImg
                src={SearchIcon}
                className='search-icon mr-20'
                onClick={() => {
                  setIsVisible(true);
                }}
              />
            )}
          </>
        )}
        <IonImg src={ContactIcon} className='phone-icon mr-10' /> */}
        {isLoggedIn == 'true' && (
          <div
            style={{ display: 'flex', marginRight: 20, marginTop: 2 }}
            className='cursor'
            onClick={() => {
              onNavClick('/notifications');
            }}
          >
            {isNewNotification && <div className='badge'></div>}

            <IonImg src={NotiIcon} style={{ width: 20, height: 24 }} />
          </div>
        )}

        {
          <div className='faqicon-con'>
            <IonImg
              src={FaqIcon}
              className='phone-icon mr-10'
              onClick={() => {
                onNavClick('/faq');
              }}
            />
          </div>
        }
        <LanguageSelect customClass='lang-color' />
        {isLoggedIn == 'false' && (
          <>
            <ButtonComponent
              name={'Register'}
              customClass='header-register mr-10'
              textKey={'register'}
              onClick={() => {
                history.push('/signup');
              }}
            />
            <ButtonComponent
              name={'Login'}
              outline
              customClass='header-login'
              textKey={'login'}
              onClick={() => {
                history.push('/login');
              }}
            />
          </>
        )}
        {isLoggedIn == 'true' && (
          <div className='user-nav'>
            <div
              className='user-nav__name'
              onClick={() => {
                setShowProfileMenu(!showProfileMenu);
              }}
            >
              {/* <IonImg
                src={
                  userDetails.profile_pic_obj
                    ? getFormattedUrl(userDetails.profile_pic_obj.uri)
                    : undefined
                }
                className='user-icon'
              /> */}
              <IonAvatar className='user-icon'>
                <>
                  {userDetails.profile_pic_obj ? (
                    <IonImg
                      src={
                        userDetails.profile_pic_obj
                          ? getFormattedUrl(userDetails.profile_pic_obj.uri)
                          : ProfilePlaceholder
                      }
                      className='user-icon'
                    />
                  ) : (
                    <div
                      className='user-icon user-initial-header'
                      style={{
                        color: getColorByInitial(
                          getUserInitial(userDetails.firstname)
                        ),
                      }}
                    >
                      <div
                        className='font-aktiv-grotest-regular'
                        style={{
                          color: getColorByInitial(
                            getUserInitial(userDetails.firstname)
                          ),
                        }}
                      >
                        {getUserInitial(userDetails.firstname)}
                      </div>
                    </div>
                  )}
                </>
              </IonAvatar>

              <span>{`${userDetails.firstname} ${
                userDetails.lastname || ''
              }`}</span>
              <IonImg src={DownArrow} className='arrow-down' />
            </div>
            {showProfileMenu && (
              <ul className='user-nav__dropdown'>
                <li>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowProfileMenu(false);
                      history.push('/my-profile');
                    }}
                  >
                    {t({ id: 'my_profile', message: 'My Profile' })}
                  </div>
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowProfileMenu(false);
                    history.push('/mycorner');
                  }}
                >
                  <div>{t({ id: 'my_corner', message: 'My Corner' })}</div>
                </li>
                <li>
                  <div
                    onClick={async (e) => {
                      e.stopPropagation();
                      await logout();
                      setIsLoggedIn('false');
                      setUserDetails({});
                      setIsInActive('false');
                      setShowProfileMenu(false);
                      history.replace(isWeb() ? INIT_PAGE : '/login');
                      window.location.reload();
                    }}
                  >
                    {t({ id: 'logout', message: 'Logout' })}
                  </div>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavigation;
