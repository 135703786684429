import './style.scss';
import { IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { useHistory } from 'react-router';
import { getFormattedUrlCMS, getRouteForCMS } from '../../utils/common.helper';

const SolutionList: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent('fs-sol');
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  function onNavClick(route: string) {
    history.push(getRouteForCMS(route));
  }

  return (
    <IonPage className='page-solution-list'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <div className='section content-sec pt-0'>
            <div className='page-banner-with-text'>
              {entry.solution_banner_image &&
                entry.solution_banner_image.bannerimg.length > 0 && (
                  <ContentComp
                    data={{
                      images: [entry.solution_banner_image],
                      unique_key: entry.solution_banner_image.unique_key,
                    }}
                  />
                )}

              {entry.solution_title_and_desc && (
                <div className='container'>
                  <div className='banner-content'>
                    <div className='banner-content__inner'>
                      <ContentComp
                        data={{
                          title:
                            entry.solution_title_and_desc[0].short_text.title,
                          titleTag:
                            entry.solution_title_and_desc[0].short_text
                              .tag_name,
                          titleCustomClass: 'content-heading',
                          description:
                            entry.solution_title_and_desc[0].long_text
                              ?.description,
                          descPosition:
                            entry.solution_title_and_desc[0].long_text
                              ?.position,
                          unique_key:
                            entry.solution_title_and_desc[0].unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='container'>
              <div className=''>
                {entry.solution_1 && entry.solution_1.length ? (
                  <>
                    {entry.solution_1.map((item: any) => {
                      return (
                        <div
                          key={item.id}
                          className={`mt-30 card-list ${
                            item.redirect_to ? 'cursor' : ''
                          } ${
                            item.display_type && item.display_type == 'card'
                              ? 'card-bg p-20'
                              : ''
                          }`}
                          onClick={() => {
                            if (item.redirect_to) {
                              onNavClick(item.redirect_to.model);
                            }
                          }}
                        >
                          <ContentComp
                            data={{
                              imagePath: getFormattedUrlCMS(
                                `${item.images[0]?.hash}${item.images[0]?.ext}`
                              ),
                              title: item.title.title,
                              titleTag: item.title.tag_name,
                              description: item.description?.description,
                              descPosition: item.description?.position,
                              unique_key: item.unique_key,
                              displayType: item.display_type,
                              titleOnTop: item.title_on_top,
                            }}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </Layout>
    </IonPage>
  );
};

export default SolutionList;
