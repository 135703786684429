import { IonPage } from '@ionic/react';

import './style.scss';
import Layout from '../../components/layouts';

import { PrivateRoute } from '../../components/privateroute';

import Buylist from '../../components/buylist';

const Buy: React.FC = () => {
  return (
    <IonPage className='page-buy'>
      <Layout>
        <div className='page-container pt-0'>
          <Buylist />
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(Buy);
