import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const selector = `[name=${keys[0]}]`;
        const errorElement = document.querySelector(selector) as any;

        onFocus(errorElement);
      }
    }
  }, [errors, isSubmitting, isValidating]);

  const onFocus = async (ele: any) => {
    if (ele) {
      const temp = await ele.getInputElement();
      temp.focus();
    }
  };
  return null;
};

export default FocusError;
