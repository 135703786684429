/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Route, useHistory, withRouter } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  useIonToast,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/styles/main.scss';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useContext, useEffect } from 'react';

import Registration from './pages/register';
import Intro from './pages/intro';
import SignUp from './pages/signup';
import LogIn from './pages/login';
// import PostTimeLine from './pages/posttimeline';
import {
  isConnectedToInternet,
  networkCheckInit,
} from './services/network.service';

import { AppContext } from './context/app.context';
import Loader from './components/loader';
import Buy from './pages/buy';
import CommodityDetail from './pages/commoditydetailpage';
import Splash from './pages/splash';
import { isWeb, notificationRedirect } from './utils/common.helper';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen';
import Tabs from './pages/tabs';
import Unauthenticate from './pages/unauthenticate';
import FoodSecurity from './pages/foodsecurity';
import MenuBar from './components/menubar';
import Marketplace from './pages/marketplace';
import Home from './pages/home';
import Sell from './pages/sell';
import AboutCovestro from './pages/aboutcovestro';
import AboutIB from './pages/aboutib';
import MyProfile from './pages/my-profile';
import page404 from './pages/404';
import pagefaq from './pages/faq';
import FullImage from './pages/full-image';
import pageComponents from './pages/components';
import maintenance from './pages/maintenance';
// import 'moment/min/locales';
import SubmittedSuccessfully from './pages/submitted-successfully';
import Addbuypost from './pages/addbuypost';
import Addsellpost from './pages/addsellpost';
import Mycorner from './pages/mycorner';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links';
import { ACCESS_TOKEN, APP_DOMAIN } from './config/appconfig';
import SolutionList from './pages/solutionlist';
import SuccessStoryList from './pages/successstorylist';
import TestimonialList from './pages/testimoniallist';
import NewsList from './pages/newslist';
import AccreditationList from './pages/accreditationlist';
import SubsidyOrLoanList from './pages/subsidyorloanlist';
import Solution from './pages/solution';
import Partners from './pages/partners';
import PartnersDetail from './pages/partnersdetail';
import SuccessStoryDetail from './pages/successstorydetail';
import Editpost from './pages/editpost';
import Notificationlist from './pages/notificationlist';
import MediaLanding from './pages/medialanding';
import PartnerCategory from './pages/partnercategory';
import VideoList from './pages/videolist';
import localnotificationService from './services/localnotification.service';
import Notificationdetails from './pages/notificationdetails';
import { firebaseInit, googleInit } from './services/firebase.service';
import observerService from './services/observer.service';
import { getNotificationListById } from './services/user.service';
import storageService from './services/storage.service';
import TNC from './pages/tnc';
import PrivacyPolicy from './pages/privacypolicy';
import commodities from './pages/my-profile/commodities';
import solutions from './pages/my-profile/solutions';
import AccountInActive from './pages/accountinactive';
import Imprint from './pages/imprint';

// import { hi } from 'make-plural/plurals';
setupIonicReact();
let dynamicLinkObservable: any = null;
const App: React.FC = () => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  // const [refresh, setRefresh] = useState(false);
  const { isLoggedIn, setIsNewNotification }: any = useContext(AppContext);
  let observer: any = null;
  let interval: any = null;
  useEffect(() => {
    SplashScreen.hide();
    networkCheckInit(present, dismiss);
    // check network on start
    isConnectedToInternet();
    // getLangFile();
    firebaseInit();
    googleInit();
    getNotificationOnLoad();
    localnotificationService.clickListener((notification: any) => {
      console.log('local click', notification);
      const parsedData = notification.data;
      notificationRedirect(parsedData);
    });

    if (!dynamicLinkObservable) {
      dynamicLinkObservable = FirebaseDynamicLinks.onDynamicLink();
      dynamicLinkObservable.subscribe((data: any) => {
        console.log('open from link', data);
        onDynamicLink(data.deepLink);
      });
    }

    if (!observer) {
      observer = observerService.observable.subscribe(async (value: any) => {
        if (value) {
          if (value.type == 'notification' && value.data.isNewNotification) {
            setIsNewNotification(true);
            await storageService.set('setIsNewNotification', 'true');
          }
        }
      });
    }

    if (!interval) {
      interval = setInterval(() => {
        getNotificationOnLoad();
      }, 300000);
    }
    return () => {
      dynamicLinkObservable = null;
      if (observer) {
        observer.unsubscribe();
        observer = null;
      }
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, []);

  const onDynamicLink = async (link: string) => {
    if (!link) return;
    const route = link.split(APP_DOMAIN).pop();
    history.push(`/${route}`);
    // window.location.href = `/${route}`;
  };

  const getNotificationOnLoad = async () => {
    const token = await storageService.get(ACCESS_TOKEN);
    if (!token) return;
    const resp = await getNotificationListById(0);
    if (!resp.error) {
      if (resp.length > 0) {
        const lastNotiId = await storageService.get('notificationLastId');
        // console.log(lastNotiId, resp[0].id);

        if (lastNotiId) {
          if (lastNotiId != resp[0].id) {
            await storageService.set('notificationLastId', resp[0].id);
            await storageService.set('setIsNewNotification', 'true');
            setIsNewNotification(true);
          }
        } else {
          await storageService.set('notificationLastId', resp[0].id);
          await storageService.set('setIsNewNotification', 'true');
          setIsNewNotification(true);
        }
      }
    }
  };
  return (
    <AppContext.Consumer>
      {(consumer: any) => {
        return (
          <I18nProvider forceRenderOnLocaleChange={true} i18n={i18n}>
            <IonApp>
              <IonReactRouter>
                {isWeb() ? <MenuBar /> : isLoggedIn == 'true' && <MenuBar />}
                <IonRouterOutlet id='main'>
                  <Route path='/splash' exact component={Splash} />
                  <Route path='/tabs' component={Tabs} />
                  <Route path='/register' exact component={Registration} />
                  <Route path='/intro' exact component={Intro} />
                  <Route path='/login' exact component={LogIn} />
                  <Route path='/signup' exact component={SignUp} />
                  <Route path='/buy' exact component={Buy} />
                  <Route path='/sell' exact component={Sell} />

                  <Route
                    path='/addbuyrequirement'
                    exact
                    component={Addbuypost}
                  />
                  <Route
                    path='/addsellrequirement'
                    exact
                    component={Addsellpost}
                  />
                  <Route path='/marketplace' exact component={Marketplace} />
                  <Route path='/home' exact component={Home} />

                  <Route
                    path='/aboutcovestro'
                    exact
                    component={AboutCovestro}
                  />
                  <Route path='/aboutib' exact component={AboutIB} />
                  <Route path='/my-profile' exact component={MyProfile} />
                  <Route
                    path='/my-profile/my-commodity'
                    exact
                    component={commodities}
                  />
                  <Route
                    path='/my-profile/my-solution'
                    exact
                    component={solutions}
                  />
                  <Route path='/404' exact component={page404} />
                  <Route path='/faq' exact component={pagefaq} />
                  <Route
                    path='/notifications'
                    exact
                    component={Notificationlist}
                  />
                  <Route
                    path='/notifications/details/:id'
                    exact
                    component={Notificationdetails}
                  />
                  <Route path='/full-image' exact component={FullImage} />
                  <Route path='/components' exact component={pageComponents} />
                  <Route path='/maintenance' exact component={maintenance} />

                  {/* <Route path='/posttimeline' exact component={PostTimeLine} /> */}

                  <Route
                    path='/commoditydetail/:id'
                    exact
                    component={CommodityDetail}
                  />
                  <Route path='/editpost/:id' exact component={Editpost} />
                  <Route
                    path='/unauthenticate'
                    exact
                    component={Unauthenticate}
                  />
                  <Route
                    path='/submittedsuccessfully/:id'
                    exact
                    component={SubmittedSuccessfully}
                  />
                  <Route
                    path='/submittedsuccessfully/:edit/:id'
                    exact
                    component={SubmittedSuccessfully}
                  />
                  <Route path='/foodsecurity' exact component={FoodSecurity} />
                  <Route path='/mycorner' exact component={Mycorner} />
                  <Route path='/solutions' exact component={SolutionList} />
                  <Route path='/solution/:id' exact component={Solution} />
                  <Route
                    path='/successstories'
                    exact
                    component={SuccessStoryList}
                  />
                  <Route
                    path='/testimonials'
                    exact
                    component={TestimonialList}
                  />
                  <Route path='/news' exact component={NewsList} />
                  <Route
                    path='/accreditations'
                    exact
                    component={AccreditationList}
                  />
                  <Route
                    path='/subsidyorloans'
                    exact
                    component={SubsidyOrLoanList}
                  />
                  <Route
                    path='/successstorydetail/:id'
                    exact
                    component={SuccessStoryDetail}
                  />
                  <Route
                    path='/partnersdetail/:id'
                    exact
                    component={PartnersDetail}
                  />
                  <Route path='/partners' exact component={Partners} />
                  <Route
                    path='/partnerscategory/:id'
                    exact
                    component={PartnerCategory}
                  />
                  <Route path='/medialanding' exact component={MediaLanding} />
                  <Route path='/videolist' exact component={VideoList} />

                  <Route
                    path='/legal/conditions-of-use'
                    exact
                    component={TNC}
                  />
                  <Route
                    path='/legal/privacy-statement'
                    exact
                    component={PrivacyPolicy}
                  />
                  <Route
                    path='/account-inactive'
                    exact
                    component={AccountInActive}
                  />
                  <Route path='/legal/imprint' exact component={Imprint} />
                  {/* {consumer.isLoggedIn == 'true' ? (
                    <Route exact path='/'>
                      <Redirect to={'/buy'} />
                    </Route>
                  ) : (
                    <Route exact path='/' component={Intro}></Route>
                  )} */}
                  {isWeb() ? (
                    <Route exact path='/' component={Home}></Route>
                  ) : (
                    <Route exact path='/' component={Splash}></Route>
                  )}
                </IonRouterOutlet>
              </IonReactRouter>
              {consumer.loading && (
                <div className='loader-position'>
                  <Loader />
                </div>
              )}
            </IonApp>
          </I18nProvider>
        );
      }}
    </AppContext.Consumer>
  );
};
export default withRouter(App);
