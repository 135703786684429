/* eslint-disable no-unused-vars */
import './style.scss';
import React from 'react';
interface Props {
  totalStep: number;
  activeStep: number;
}
const StepBar: React.FC<Props> = (props) => {
  const { totalStep, activeStep } = props;

  const renderSteps = () => {
    const item = [];
    for (let index = 0; index < totalStep; index++) {
      const activeClass =
        index < activeStep ? 'done' : index == activeStep ? 'editing' : '';
      item.push(
        <div key={index} className={`step ${activeClass} `}>
          <div className='step-circle'></div>
          <div className='step-bar-left'></div>
          <div className='step-bar-right'></div>
        </div>
      );
    }
    return item;
  };
  return (
    <div className='stepper-horizontal' id='stepper1'>
      {renderSteps()}
    </div>
  );
};

export default StepBar;
