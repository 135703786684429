import {
  IonImg,
  IonPage,
  IonPopover,
  IonSlides, 
  IonSlide,
  IonRow,
  IonCol
} from '@ionic/react';
import { useState } from 'react';
import './style.scss';
import Layout from '../../components/layouts';
import ButtonComponent from '../../components/button-component';

import imgUserDefault from '../../assets/images/blank-profile-picture.png';
import IconCamera from '../../assets/images/icons/icon_camera.svg';
import SlideImg01 from '../../assets/images/slide_img01.jpg';
import sampleImg from '../../assets/images/about-ib.png';

const slideOpts = {
  initialSlide: 1,
  speed: 400
};

const Home: React.FC = () => {

  const [imagePopoverState, setimagePopoverState] = useState({
    showPopover: false,
    event: undefined,
  });
  
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const [popoverStateShare, setShowPopoverShare] = useState({
    showPopover: false,
    event: undefined,
  });

  const [popoverStateSupport, setShowPopoverSupport] = useState({
    showPopover: false,
    event: undefined,
  });

  return (
    <IonPage>
      <Layout>
      <div className="user-dashboard-container">
      <div className="dashboard-main-container mr-24">

<div className='mt-20 mb-20'>
      <IonImg onClick={() => {
            setimagePopoverState({
              showPopover: true,
              event: undefined,
            });
          }}  src={SlideImg01}></IonImg>
      </div>

     


      <div
          className='add-camera-wrapper  mr-10'
          onClick={() => {
            setShowPopover({
              showPopover: true,
              event: undefined,
            });
          }}
        >
          <IonImg
            src={IconCamera}
            style={{ width: 20, height: 20 }}
            className='mr-10'
          />
          <div>
            Image popup
          </div>
        </div>

        <div
          className='add-camera-wrapper  mr-10'
          onClick={() => {
            setShowPopoverShare({
              showPopover: true,
              event: undefined,
            });
          }}
        >
          <IonImg
            src={IconCamera}
            style={{ width: 20, height: 20 }}
            className='mr-10'
          />
          <div>
            Share Popup
          </div>
        </div>


        <div
          className='add-camera-wrapper  mr-10 mb-30'
          onClick={() => {
            setShowPopoverSupport({
              showPopover: true,
              event: undefined,
            });
          }}
        >
          <IonImg
            src={IconCamera}
            style={{ width: 20, height: 20 }}
            className='mr-10'
          />
          <div>
            Support Popup
          </div>
        </div>


        
          <IonSlides pager={true} options={slideOpts} className='slide-bg slide-bg--pagination mb-30'>
            <IonSlide className='slide-bg__item'>
            <IonImg src={SlideImg01} className='slide-bg__img' />
            <div className='slide-bg__copy'>
            <div className='slide-bg__copy__inner'>
              <h2 className='slide-bg__copy__heading'>Solar Greenhouse Dryer (SGHD)</h2>
              <p className='slide-bg__copy__description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat, scelerisque lobortis amet donec eu magnis.</p>
            </div>
            </div>
            </IonSlide>
            <IonSlide className='slide-bg__item'>
            <IonImg src={SlideImg01} className='slide-bg__img' />
            <div className='slide-bg__copy'>
            <div className='slide-bg__copy__inner'>
            <h2 className='slide-bg__copy__heading'>Solar Greenhouse Dryer (SGHD)</h2>
              <p className='slide-bg__copy__description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat, scelerisque lobortis amet donec eu magnis.</p>
            </div>
            </div>
            </IonSlide>
            <IonSlide className='slide-bg__item'>
            <IonImg src={SlideImg01} className='slide-bg__img' />
            <div className='slide-bg__copy'>
            <div className='slide-bg__copy__inner'>
            <h2 className='slide-bg__copy__heading'>Solar Greenhouse Dryer (SGHD)</h2>
              <p className='slide-bg__copy__description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat, scelerisque lobortis amet donec eu magnis.</p>
            </div>
            </div>
            </IonSlide>            
          </IonSlides>


          <div className='image-text mb-30'>
              <IonRow className='image-text__row'>
                <IonCol size-xs='12' size-sm='6' className='image-text__img'>
                <IonImg src={SlideImg01} />
                </IonCol>
                <IonCol size-xs='12' size-sm='6' className='image-text__copy'>
                  <h3 className='font-aktiv-grotest-medium mb-5 image-text__copy__heading'>Heading</h3>
                  <div className='image-text__copy__description'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet turpis gravida, mollis diam quis, molestie nulla. Curabitur cursus sem quis sem ultrices.</p>
                  </div>
                  <div className='image-text__copy__btn'><ButtonComponent
                      name={'Button'}
                      textKey={'button'}
                      outline
                      customClass=''
                    /></div>
                </IonCol>
                </IonRow>
                </div>
          
          <div className='image-text mb-30'>
              <IonRow className='image-text__row'>
                <IonCol size-xs='12' size-sm='6' className='image-text__img image-text__img--right'>
                <IonImg src={SlideImg01} />
                </IonCol>
                <IonCol size-xs='12' size-sm='6' className='image-text__copy'>
                  <h3 className='font-aktiv-grotest-medium mb-5 image-text__copy__heading'>Heading</h3>
                  <div className='image-text__copy__description'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet turpis gravida, mollis diam quis, molestie nulla. Curabitur cursus sem quis sem ultrices.</p>
                  </div>
                  <div className='image-text__copy__btn'><ButtonComponent
                      name={'Button'}
                      textKey={'button'}
                      outline
                      customClass=''
                    /></div>
                </IonCol>
                </IonRow>
                </div>
        


        <IonPopover
          event={imagePopoverState.event}
          isOpen={imagePopoverState.showPopover}
          onDidDismiss={() => {
            setimagePopoverState({ showPopover: false, event: undefined });
          }}
          className='img-popover modal-style modal-style--lg'
        // backdropDismiss={false}
        >
          <div
            className='img-popover-inner'
            style={{ backgroundColor: 'white' }}
          >
            <IonImg className='modal-style__img' src={SlideImg01}></IonImg>
            
          </div>
        </IonPopover>

        <IonPopover
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
          className='img-popover modal-style'
        // backdropDismiss={false}
        >
          <div
            className='img-popover-inner'
            style={{ backgroundColor: 'white' }}
          >
            <IonImg className='modal-style__img' src={imgUserDefault}></IonImg>
            <ButtonComponent
              name={'Submit'}
              textKey={'Submit'}
              customClass='mt-20 popup-btn'
            />
          </div>
        </IonPopover>

        <IonPopover
          event={popoverStateShare.event}
          isOpen={popoverStateShare.showPopover}
          onDidDismiss={() => {
            setShowPopoverShare({ showPopover: false, event: undefined });
          }}
          className='img-popover modal-style'
        // backdropDismiss={false}
        >
          <div
            className='img-popover-inner'
            style={{ backgroundColor: 'white' }}
          >
            <h3 className='shareURL_head'>Share URL</h3>
            <div className='shareURL_sec'>
              <div className='shareURL_link'>http://localhost:3000/components</div>
              <ButtonComponent
                name={'Copy URL'}
                textKey={'copyurl'}
                customClass='popup-btn'
              />
            </div>
          </div>
        </IonPopover>

        <IonPopover
          event={popoverStateSupport.event}
          isOpen={popoverStateSupport.showPopover}
          onDidDismiss={() => {
            setShowPopoverSupport({ showPopover: false, event: undefined });
          }}
          className='img-popover modal-style'
        // backdropDismiss={false}
        >
          <div
            className='img-popover-inner'
            style={{ backgroundColor: 'white' }}
          >
            <h3 className='popupSupport_head'>Support</h3>
            <div className='popupSupport_description'>
              <p><strong>Hi, There </strong>I&#8216;m interested in the drop post you updated in Covestro Food Services. </p>
            </div>
            <div className='popupSupport_btns'>
              <ButtonComponent
                name={'Send'}
                textKey={'send'}
                customClass='popup-btn'
              />
              <ButtonComponent
                name={'Cancel'}
                textKey={'send'}
                customClass='popup-btn popup-btn-cancel'
              />
            </div>

          </div>
        </IonPopover>

      </div>
      <div className="dashboard-notification-news-container">

      <div className="notification-wrapper mb-24 p-8"><div className="notification-news-title font-aktiv-grotest-medium pb-10">Notifications</div></div>
      </div>
      </div>
      
      <div className='pt-30 pb-30 sm-pt-15 sm-pb-15'>
        <div className='container'>
          <div className='row'>
            <div className='col col-12 col-md-4 pt-15 pb-15'>
              <h2 className='font-aktiv-grotest-medium mb-15 text-cyan'>Technology Providers</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className='col col-12 col-md-8'>
              <div className='row'>
              <div className='col col-6 col-md-4 pt-15 pb-15'><IonImg className='' src={sampleImg}></IonImg></div>
              <div className='col col-6 col-md-4 pt-15 pb-15'><IonImg className='' src={sampleImg}></IonImg></div>
              <div className='col col-6 col-md-4 pt-15 pb-15'><IonImg className='' src={sampleImg}></IonImg></div>
              <div className='col col-6 col-md-4 pt-15 pb-15'><IonImg className='' src={sampleImg}></IonImg></div>
              <div className='col col-6 col-md-4 pt-15 pb-15'><IonImg className='' src={sampleImg}></IonImg></div>
              <div className='col col-6 col-md-4 pt-15 pb-15'><IonImg className='' src={sampleImg}></IonImg></div>
              </div>
            </div>
          </div>
        </div>
      </div>   

      </Layout>
    </IonPage>
  );
};

export default Home;
