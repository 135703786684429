import { IonPage } from '@ionic/react';
import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Buylist from '../../components/buylist';
import Layout from '../../components/layouts';
import PrivateRoute from '../../components/privateroute';
import Selllist from '../../components/selllist';
import './style.scss';

const MarketPlaceWithTab: React.FC = () => {
  const { type }: any = useParams();
  const [selectedTab, setSelectedTab] = useState(type == 'demand' ? 1 : 2);
  function onTabClick(index: number) {
    setSelectedTab(index);
  }

  useEffect(() => {
    setSelectedTab(type == 'demand' ? 1 : 2);
  }, [type]);
  return (
    <IonPage>
      <Layout>
        <div className='page-container'>
          <ul className='userTab'>
            <li className={selectedTab == 1 ? 'active' : ''}>
              <div
                className='font-aktiv-grotest-medium userTab__link'
                onClick={() => {
                  onTabClick(1);
                }}
              >
                {t({
                  id: 'buy',
                  message: 'Demand',
                })}
              </div>
            </li>
            <li className={selectedTab == 2 ? 'active' : ''}>
              <div
                className='font-aktiv-grotest-medium userTab__link'
                onClick={() => {
                  onTabClick(2);
                }}
              >
                {t({
                  id: 'sell',
                  message: 'Supply',
                })}
              </div>
            </li>
          </ul>
          <div style={{ display: selectedTab == 1 ? 'block' : 'none' }}>
            <Buylist />
          </div>
          <div style={{ display: selectedTab == 2 ? 'block' : 'none' }}>
            <Selllist />
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(MarketPlaceWithTab);
