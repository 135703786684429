import { APP_DOMAIN } from '../../../config/appconfig';

const PrivacyKN: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        ಕೊವೆಸ್ಟ್ರೋ ಫುಡ್ ಸೆಕ್ಯುರಿಟಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಾಗಿ ಗೌಪ್ಯತೆಯ ನಿಯಮಗಳು
      </h3>
      <h4 style={{ textAlign: 'center' }}>
        ಕೊನೆಯದಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ [సెప్టెంబర్ 27, 2022]
      </h4>
      <h3>ಪರಿಚಯ</h3>
      <p>
        ಕೋವೆಸ್ಟ್ರೋ (ಇಂಡಿಯಾ) ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್‌ನ ನಮ್ಮ ಸಮುದಾಯಭಾಗವಾಗಲು ಆಯ್ಕೆ
        ಮಾಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು, ಮೊದಲು ಬೇಯರ್ ಮೆಟೇರಿಯಲ್ ಸೈನ್ಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್
        ಎಂದು ಕರೆಯಲಾಗುತ್ತಿತ್ತು. ಇದು ಕಂಪನಿಗಳ ಮಸೂದೆ, 1956ರ ಅಡಿಯಲ್ಲಿ ಸಂಘಟಿತವಾದ ಖಾಸಗಿ
        ಲಿಮಿಟೆಡ್ ಕಂಪನಿ ಮತ್ತು ಯುನಿಟ್ ನಂ. SB-801,8ನೇ ಮಹಡಿ, ಎಂಪೈರ್ ಟವರ್, ಕ್ಲೌಡ್
        ಸಿಟಿ ಕ್ಯಾಂಪಸ್,ಐರೋಲಿ ಥಾಣೆ MH 400708 IN (“ಕೋವೆಸ್ಟ್ರೋ, “ಕಂಪನಿ”,“ನಾವು”,
        “ನಮಗೆ”, ಅಥವಾ “ನಮ್ಮ”,ಅಥವಾ ‘ಪ್ಲಾಟ್‌ಫಾರ್ಮ್’). ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿ ಮತ್ತು
        ಗೌಪ್ಯತೆಯ ನಿಮ್ಮ ಹಕ್ಕನ್ನು ರಕ್ಷಿಸಲು ನಾವು ಬದ್ಧರಾಗಿದ್ದೇವೆ. ನಮ್ಮ ಕಾರ್ಯನೀತಿ
        ಅಥವಾ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಗೆ ಸಂಬಂಧ ಪಟ್ಟಂತೆ ನಮ್ಮ ಅಭ್ಯಾಸಗಳ ಕುರಿತು ನೀವು
        ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಅಥವಾ ಕಳವಳಗಳನ್ನು ಹೊಂದಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು
        ಸಂಪರ್ಕಿಸಿ.
      </p>
      <p>
        ಈ ಗೌಪ್ಯತಾ ನೀತಿಯು ನಮ್ಮ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳ ಜೊತೆಗೆ ನಿಮ್ಮ ಬಳಕೆಮತ್ತು
        ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಪ್ರವೇಶವನ್ನು ನಿಯಂತ್ರಿಸುತ್ತದೆ. ಬಳಕೆಯ ನಿಯಮಗಳನ್ನು{' '}
        <a href={`${APP_DOMAIN}legal/conditions-of-use`}>
          {`${APP_DOMAIN}legal/conditions-of-use`}
        </a>{' '}
        ನಲ್ಲಿ ಪ್ರವೇಶಿಸಬಹುದು.
      </p>
      <p>
        ನಮ್ಮ ಸೇವೆಗಳನ್ನು ಪಡೆಯುವ ಮೊದಲು ಪ್ರತಿಯೊಬ್ಬ ಬಳಕೆದಾರರು ಗೌಪ್ಯತೆಯ ನಿಯಮಗಳನ್ನು
        ವಿವರವಾಗಿ ಓದಲು ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬೇಕೆಂದು ವಿನಂತಿಸಲಾಗಿದೆ. ಮತ್ತು ಈ ಗೌಪ್ಯತೆ
        ಕಾರ್ಯ ನೀತಿಯಲ್ಲಿ ನೀವು ಒಪ್ಪದ ಯಾವುದೇ ನಿಯಮಗಳಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಮ್ಮ ವೆಬ್‌ಸೈಟ್
        ಮತ್ತು ನಮ್ಮ ಸೇವೆಗಳ ಬಳಕೆ ಅನ್ನು ನಿಲ್ಲಿಸಿ.
      </p>
      <p>
        ಈ ಗೌಪ್ಯತಾ ನೀತಿಯು ನಮ್ಮ ವೆಬ್‌ಸೈಟ್/ಅಪ್ಲಿಕೇಶನ್[ಆಹಾರ ಭದ್ರತೆ ಪ್ಲಾಟ್‌ಫಾರ್ಮ]
        ಮತ್ತು/ಅಥವಾ ಯಾವುದೇ ಸಂಬಂಧಿತ ಸೇವೆಗಳು, ಮಾರಾಟಗಳು, ಮಾರ್ಕೆಟಿಂಗ್ ಅಥವಾ ಈವೆಂಟ್‌ಗಳ
        ಮೂಲಕ ಸಂಗ್ರಹಿಸಲಾದ ಎಲ್ಲಾ ಮಾಹಿತಿಗಳಿಗೆ ಅನ್ವಯಿಸುತ್ತದೆ(ನಾವುಅವುಗಳನ್ನು ಈ ಗೌಪ್ಯತೆ
        ನೀತಿಯಲ್ಲಿ <b>&quot;ಪ್ಲಾಟ್‌ಫಾರ್ಮ್&quot;</b> ಎಂದು ಒಟ್ಟಾಗಿ ಉಲ್ಲೇಖಿಸುತ್ತೇವೆ)
      </p>
      <p>
        ಈ ಗೌಪ್ಯತೆ ಕಾರ್ಯ ನೀತಿಯು ನಮ್ಮ ಗೌಪ್ಯತೆ ಅಭ್ಯಾಸಗಳನ್ನು ವಿವರಿಸಲು ಉದ್ದೇಶಿಸಲಾಗಿದೆ
        ಮತ್ತು ಈ ಕೆಳಗಿನ ಕ್ಷೇತ್ರಗಳನ್ನು ಒಳಗೊಂಡಿದೆ:
      </p>
      <ol>
        <li>
          <h3>ಯಾವ ಮಾಹಿತಿಯನ್ನು ನಾವು ಸಂಗ್ರಹಿಸುತ್ತೇವೆ?</h3>
          <ol>
            <li>
              <b>
                <p>
                  <i>ನೀವು ನಮಗೆ ಬಹಿರಂಗಪಡಿಸುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿ </i>
                </p>
              </b>
              <i>
                <p>
                  <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಹೆಸರು, ವಿಳಾಸ, ಸಂಪರ್ಕ ಮಾಹಿತಿಯಂತಹ ನೀವು ನಮಗೆ
                  ಒದಗಿಸುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಾವು ಸಂಗ್ರಹಿಸುತ್ತೇವೆ, ಸೇರಿದಂತೆ
                  ಆದರೆ ಬಿಲ್ಲಿಂಗ್ ಮೊತ್ತಕ್ಕೆ ಸೀಮಿತವಾಗಿಲ್ಲ.
                </p>
              </i>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೋಂದಾವಣಿ ಮಾಡುವಾಗ, ನಮ್ಮ ಅಥವಾ ನಮ್ಮ ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳ ಕುರಿತು ಮಾಹಿತಿಯನ್ನು ಪಡೆಯುವಲ್ಲಿ ಆಸಕ್ತಿಯನ್ನು
                ವ್ಯಕ್ತಪಡಿಸುವಾಗ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸುವಾಗ ಅಥವಾ
                ಕೆಳಗೆ ನೀಡಿರುವ ಮಾಹಿತಿಯಲ್ಲಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕ ಮಾಡುವಾಗ ನೀವು ಸ್ವಯಂ
                ಪ್ರೇರಣೆಯಿಂದ ನಮಗೆ ನೀಡುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಾವು
                ಸಂಗ್ರಹಿಸುತ್ತೇವೆ.
              </p>
              <p>
                ನೀವು ನಮಗೆ ಒದಗಿಸಿದ ಎಲ್ಲಾ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯು ನೈಜ, ಸಂಪೂರ್ಣ ಮತ್ತು
                ನಿಖರವಾಗಿರಬೇಕು ಮತ್ತು ಅಂತಹ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಗೆ ನೀವು ಯಾವುದೇ
                ಬದಲಾವಣೆಗಳನ್ನು ನಮಗೆ ಸೂಚಿಸಬೇಕು.
              </p>
              <p>
                ನಾವು ಸಂಗ್ರಹಿಸುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯು ನಮ್ಮೊಂದಿಗೆ ಮತ್ತು
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ ಜೊತೆಗಿನ ನಿಮ್ಮ ಸಂವಾದಗಳ ಸಂದರ್ಭ, ನೀವು ಮಾಡುವ ಆಯ್ಕೆಗಳು
                ಮತ್ತು ನೀವು ಪಡೆದುಕೊಳ್ಳುವ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಮೇಲೆ
                ಅವಲಂಬಿಸಿರುತ್ತದೆ.
              </p>
              <p>
                ನಾವು ಸಂಗ್ರಹಿಸುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯುಈ ಕೆಳಗಿನವುಗಳನ್ನು ಒಳಗೊಂಡಿರಬಹುದು:
              </p>
              <p>
                <b>ಹೆಸರು ಮತ್ತು ಸಂಪರ್ಕ ಡೇಟಾ.</b> ನಾವು ನಿಮ್ಮ ಮೊದಲ ಮತ್ತು ಕೊನೆಯ
                ಹೆಸರು, ಇ-ಮೇಲ್ ವಿಳಾಸ, ಅಂಚೆ ವಿಳಾಸ, ಫೋನ್ ಸಂಖ್ಯೆಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು
                ಪ್ರವೇಶಿಸಲು ನೀವು ಒದಗಿಸುವ ಇತರ ರೀತಿಯ ಸಂಪರ್ಕ ಡೇಟಾವನ್ನು
                ಸಂಗ್ರಹಿಸುತ್ತೇವೆ.
              </p>
              <p>
                <b>ರುಜುವಾತುಗಳು.</b> ನಾವು ಪಾಸ್‌ವರ್ಡ್‌ಗಳು, ಪಾಸ್‌ವರ್ಡ್ ಸುಳಿವುಗಳು
                ಮತ್ತು ದೃಢೀಕರಣ ಮತ್ತು ಖಾತೆ ಪ್ರವೇಶಕ್ಕಾಗಿ ಬಳಸಲಾಗುವ ಇದೇ ರೀತಿಯ ಭದ್ರತಾ
                ಮಾಹಿತಿಯನ್ನು <b>ಸಂಗ್ರಹಿಸುವುದಿಲ್ಲ.</b>
              </p>
              <p>
                <b>ಪಾವತಿ ಡೇಟಾ.</b> ನಿಮ್ಮ ಪಾವತಿಯನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು,
                ಖರೀದಿಗಳನ್ನು ಪ್ರಕ್ರಿಯೆ ಗೊಳಿಸಲು ಅಗತ್ಯವಿರುವ ಡೇಟಾವನ್ನು ಮಾತ್ರ ನಾವು
                ಸಂಗ್ರಹ ಮಾಡುತ್ತೇವೆ. ಪಾವತಿ ಗೇಟ್‌ವೇ ಮೂಲಕ ಎಲ್ಲಾ ಪಾವತಿ ಡೇಟಾವನ್ನು
                ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ, ಅದರ ಮೇಲೆ ನಾವು ಸೀಮಿತ ನಿಯಂತ್ರಣವನ್ನು ಹೊಂದಿದ್ದೇವೆ
                ಮತ್ತು ನೀವು ಅದರ ಗೌಪ್ಯತೆ ನೀತಿಗಳನ್ನು ಪರಿಶೀಲಿಸಬೇಕು ಮತ್ತು ನಿಮ್ಮ
                ಪ್ರಶ್ನೆಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಲು ನೇರವಾಗಿ ಪಾವತಿ ಪ್ರೊಸೆಸರ್ ಅನ್ನು
                ಸಂಪರ್ಕಿಸಬೇಕು.
              </p>
              <p>
                <b>
                  ಪರೀಕ್ಷಾ ಡೇಟಾ, ಪರೀಕ್ಷಾ ವಿಶ್ಲೇಷಣೆ ವರದಿಗಳು, ಸಮೀಕ್ಷೆಗಳು, AI ಭವಿಷ್ಯ
                  ಸೂಚಕ ಮಾದರಿಗಳು.
                </b>{' '}
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಕೆಯಿಂದ ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾ ವರದಿಗಳು, ವಿಶ್ಲೇಷಣೆ,
                ಸಮೀಕ್ಷೆಗಳು, ಬೆಳೆದ ಬೆಳೆಗಳು, ಬೆಲೆಯನ್ನು ಪರಿಶೀಲಿಸಲು ಬೆಳೆಗಳು, ಆಸಕ್ತಿ
                ಹೊಂದಿರುವ ಉತ್ಪನ್ನಗಳು, ಲೇಖನಗಳು ಮತ್ತು ಪೋಸ್ಟ್‌ಗಳನ್ನು ಇಷ್ಟಪಡುವ
                ಮುನ್ಸೂಚಕ ಮಾದರಿಗಳು ಮತ್ತು ಫಲಿತಾಂಶಗಳು ಮತ್ತುಇತರ ಉತ್ಪನ್ನ ಮಾಹಿತಿ
                ಇತ್ಯಾದಿಗಳನ್ನು ನಾವು ಸಂಗ್ರಹ ಮಾಡುತ್ತೇವೆ.
              </p>
              <p>
                <b>ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಲಾಗಿನ್ ಡೇಟಾ.</b> ನಿಮ್ಮ Facebook, Twitter ಅಥವಾ
                ಯಾವುದೇ ಇತರ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಖಾತೆಯಂತಹ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಖಾತೆಯ
                ವಿವರಗಳನ್ನು ಬಳಸಿಕೊಂಡು ನೋಂದಾಯಿಸುವ ಆಯ್ಕೆಯನ್ನು ನಾವು ನಿಮಗೆ
                ಒದಗಿಸುತ್ತೇವೆ. ಈ ರೀತಿಯಲ್ಲಿ ನೀವು ನೋಂದಾಯಿಸಲು ಆಯ್ಕೆ ಮಾಡಿದರೆ, ಈ
                ಕೆಳಗಿನ ಮಾಹಿತಿಯನ್ನು ನಾವು ಸಂಗ್ರಹಿಸುತ್ತೇವೆ:
              </p>
              <p>
                ನಿಮ್ಮ ಹೆಸರು, ನಿಮ್ಮ ಸಾಮಾಜಿಕ ನೆಟ್‌ವರ್ಕ್, ಬಳಕೆದಾರ ಹೆಸರು, ಸ್ಥಳ,
                ಲಿಂಗ, ಜನ್ಮ,ದಿನಾಂಕ, ಇಮೇಲ್ ವಿಳಾಸ, ಪ್ರೊಫೈಲ್ ಚಿತ್ರ ಮತ್ತು
                ಸಂಪರ್ಕಗಳಿಗಾಗಿ ಸಾರ್ವಜನಿಕ ಡೇಟಾ ಒಳಗೊಂಡಂತೆ Facebook, Google+
                Instagram, Pinterest, Twitter, LinkedInನಂತಹ ಸಾಮಾಜಿಕ
                ನೆಟ್‌ವರ್ಕಿಂಗ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಿಂದ ಬಳಕೆದಾರ ಮಾಹಿತಿ, ಅಂತಹ ಸಾಮಾಜಿಕ
                ನೆಟ್‌ವರ್ಕ್‌ಗಳಿಗೆ ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನೀವು ಸಂಪರ್ಕಿಸಿದರೆ. ಈ ಮಾಹಿತಿ
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವುದಕ್ಕೆ ಮತ್ತು /ಸೇರ್ಪಡೆಯಾಗಲು ನೀವು
                ಆಹ್ವಾನಿಸುವ ಯಾರೊಬ್ಬರ ಸಂಪರ್ಕ ಮಾಹಿತಿಯನ್ನು ಸಹ ಒಳಗೊಂಡಿರಬಹುದು
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಲಾಗಿದೆ</i>
                </p>
              </b>
              <p>
                <i>
                  <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಕೆಲವು ಮಾಹಿತಿ – ಉದಾಹರಣೆಗೆ IP ವಿಳಾಸ ಮತ್ತು/ಅಥವಾ
                  ಬ್ರೌಸರ್ ಮತ್ತು ಸಾಧನ ಗುಣಲಕ್ಷಣಗಳು – ನೀವು ಭದ್ರತಾ ಉದ್ದೇಶಗಳಿಗಾಗಿ
                  ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಭೇಟಿ ನೀಡಿದಾಗ ಸ್ವಯಂಚಾಲಿತವಾಗಿ
                  ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಅದನ್ನು ನಿರ್ಬಂಧಿಸಲಾಗುವುದಿಲ್ಲ.
                </i>
              </p>
              <p>
                ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಭೇಟಿ ನೀಡಿದಾಗ, ಬಳಸು ಅಥವಾ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಿದಾಗ
                ನಾವು ಕೆಲವು ಮಾಹಿತಿಗಳನ್ನು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸಂಗ್ರಹಿಸುತ್ತೇವೆ. ನಿಮ್ಮ
                ನಿರ್ದಿಷ್ಟ ಗುರುತನ್ನು ಈ ಮಾಹಿತಿಯು (ನಿಮ್ಮ ಹೆಸರು ಅಥವಾ ಸಂಪರ್ಕ
                ಮಾಹಿತಿಯಂತಹ) ಬಹಿರಂಗಪಡಿಸುವುದಿಲ್ಲ ಆದರೆ ನಿಮ್ಮ IP ವಿಳಾಸ, ಬ್ರೌಸರ್
                ಮತ್ತು ಸಾಧನದ ಗುಣಲಕ್ಷಣಗಳು,ಆಪರೇಟಿಂಗ್ ಸಿಸ್ಟಮ್, ಭಾಷೆಯ ಆದ್ಯತೆಗಳು,
                ಉಲ್ಲೇಖಿಸುವ URL ಗಳು, ಸಾಧನದ ಹೆಸರು, ದೇಶ, ಸ್ಥಳದಂತಹ ಸಾಧನ ಮತ್ತು ಬಳಕೆಯ
                ಮಾಹಿತಿಯನ್ನು ಒಳಗೊಂಡಿರಬಹುದು., ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮತ್ತು ಇತರ
                ತಾಂತ್ರಿಕ ಮಾಹಿತಿಯನ್ನು ನೀವು ಹೇಗೆ ಮತ್ತು ಯಾವಾಗ ಬಳಸುತ್ತೀರಿ ಎಂಬ ಬಗೆಗಿನ
                ಮಾಹಿತಿಯಂತಹ ಸಾಧನ ಮತ್ತು ಬಳಕೆಯ ಮಾಹಿತಿಯನ್ನು ಒಳಗೊಂಡಿರಬಹುದು. ನಮ್ಮ
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಸುರಕ್ಷತೆ ಮತ್ತು ಕಾರ್ಯಾಚರಣೆಯನ್ನು ನಿರ್ವಹಿಸಲು ಮತ್ತು
                ನಮ್ಮ ಆಂತರಿಕ ವಿಶ್ಲೇಷಣೆ ಮತ್ತು ವರದಿ ಮಾಡುವ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಈ ಮಾಹಿತಿಯು
                ಪ್ರಾಥಮಿಕವಾಗಿ ಅಗತ್ಯವಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>
                    ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳ ಮೂಲಕ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ
                  </i>
                </p>
              </b>
              <p>
                <i>
                  <b>ಸಂಕ್ಷಿಪ್ತ:</b> ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವಾಗ ನಿಮ್ಮ
                  ಜಿಯೋ-ಲೊಕೇಶನ್, ಮೊಬೈಲ್ ಸಾಧನ, ಪುಶ್ ಅಧಿಸೂಚನೆಗಳ ಬಗ್ಗೆ ನಾವು
                  ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಬಹುದು.
                </i>
              </p>
              <p>
                ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ನೀವು ಬಳಸಿದರೆ, ನಾವು ಈ ಕೆಳಗಿನ ಮಾಹಿತಿಯನ್ನು
                ಸಹ ಸಂಗ್ರಹಿಸಬಹುದು:
              </p>
              <p>
                - <i>ಜಿಯೋ-ಲೋಕೇಷನ್ ಮಾಹಿತಿ.</i> ಸ್ಥಳ-ಆಧಾರಿತ ಸೇವೆಗಳನ್ನು ಒದಗಿಸಲು,
                ನಿರಂತರವಾಗಿ ಅಥವಾ ನಮ್ಮ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ನೀವು ಬಳಕೆ
                ಮಾಡುತ್ತಿರುವಾಗ ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಾಧನದಿಂದ ಸ್ಥಳ-ಆಧಾರಿತ ಮಾಹಿತಿಯನ್ನು
                ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ನಾವು ಪ್ರವೇಶ ಅಥವಾ ಅನುಮತಿಯನ್ನು
                ವಿನಂತಿಸಬಹುದು. ನೀವು ನಿಮ್ಮ ಪ್ರವೇಶ ಅಥವಾ ಅನುಮತಿಗಳನ್ನು ಬದಲಾಯಿಸಲು
                ಬಯಸಿದರೆ, ನಿಮ್ಮ ಸಾಧನದ ಸೆಟ್ಟಿಂಗ್‌ಗಳಲ್ಲಿ ನೀವು ಹಾಗೆ ಮಾಡಬಹುದು.
              </p>
              <p>
                - <i>ಮೊಬೈಲ್ ಸಾಧನ ಪ್ರವೇಶ.</i> ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಾಧನದ [ಬ್ಲೂಟೂತ್,
                ಕ್ಯಾಲೆಂಡರ್, ಕ್ಯಾಮೆರಾ, ಸಂಪರ್ಕಗಳು, ಮೈಕ್ರೊಫೋನ್, ಜ್ಞಾಪನೆಗಳು,
                ಸಂವೇದಕಗಳು, SMS ಸಂದೇಶಗಳು, ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಖಾತೆಗಳು, ಸಂಗ್ರಹಣೆ,] IP
                ವಿಳಾಸ ಸೇರಿದಂತೆ ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಾಧನದಿಂದ ಕೆಲವು ವೈಶಿಷ್ಟ್ಯಗಳಿಗೆ ಪ್ರವೇಶ
                ಅಥವಾ ಅನುಮತಿಯನ್ನು ನಾವು ವಿನಂತಿಸಬಹುದು; ಸಮಯ ವಲಯ; ನಿಮ್ಮ ಮೊಬೈಲ್ ಸೇವಾ
                ಪೂರೈಕೆದಾರರ ಕುರಿತು ಮಾಹಿತಿ,ಇತ್ಯಾದಿ ಮತ್ತು ಇತರ ವೈಶಿಷ್ಟ್ಯಗಳು. ನೀವು
                ನಿಮ್ಮ ಪ್ರವೇಶ ಅಥವಾ ಅನುಮತಿಗಳನ್ನು ಬದಲಾಯಿಸಲು ಬಯಸಿದರೆ, ನಿಮ್ಮ ಸಾಧನದ
                ಸೆಟ್ಟಿಂಗ್‌ಗಳಲ್ಲಿ ನೀವು ಹಾಗೆ ಮಾಡಬಹುದು.
              </p>
              <p>
                - <i>ಮೊಬೈಲ್ ಸಾಧನ ಡೇಟಾ.</i> ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನಾವು ಸಾಧನ ಮಾಹಿತಿಯನ್ನು
                ಸಂಗ್ರಹಿಸಬಹುದು (ಉದಾಹರಣೆಗೆ ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಾಧನ ID, ಮಾದರಿ ಮತ್ತು
                ತಯಾರಕರು), ಯಂತ್ರಾಂಶ ಮಾದರಿ; ಆಪರೇಟಿಂಗ್ ಸಿಸ್ಟಮ್ ಮತ್ತು ಅದರ ಆವೃತ್ತಿಯ
                ಬಗ್ಗೆ ಮಾಹಿತಿ; ವಿಶಿಷ್ಟ ಸಾಧನ ಗುರುತಿಸುವಿಕೆಗಳು (ಉದಾ. IDFA); ಮೊಬೈಲ್
                ನೆಟ್ವರ್ಕ್ ಮಾಹಿತಿ; ಸಾಧನ ಸಂಗ್ರಹಣೆ ಮಾಹಿತಿ, ಆಪರೇಟಿಂಗ್ ಸಿಸ್ಟಮ್,
                ಆವೃತ್ತಿ ಮಾಹಿತಿ, IP ವಿಳಾಸ, ಇತ್ಯಾದಿ.
              </p>
              <p>
                - <i>ಪುಶ್ ಅಧಿಸೂಚನೆಗಳು.</i> ನಿಮ್ಮ ಖಾತೆ ಅಥವಾ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗೆ
                ಸಂಬಂಧಪಟ್ಟಂತೆ ನಾವು ಪುಶ್ ಅಧಿಸೂಚನೆಗಳನ್ನು ಕಳುಹಿಸಲು ವಿನಂತಿಸಬಹುದು.
                ನೀವು ಈ ರೀತಿಯ ಸಂವಹನಗಳನ್ನು ಸ್ವೀಕರಿಸುವುದರಿಂದ ಹೊರಗುಳಿಯಲು ಬಯಸಿದರೆ,
                ನಿಮ್ಮ ಸಾಧನದ ಸೆಟ್ಟಿಂಗ್‌ಗಳಲ್ಲಿ ನೀವು ಅವುಗಳನ್ನು ಆಫ್ ಮಾಡಬಹುದು.
              </p>
              <p>
                - <b>ಇತರ ಪೈಕಿ ಒಳಗೊಂಡಂತೆ, ಅಪ್ಲಿಕೇಶನ್ ಬಳಕೆಯ ಡೇಟಾ:</b> ನಾವು ಬಳಕೆಯ
                ಆವರ್ತನದ ಕುರಿತು ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಬಹುದು; ನೀವು ಭೇಟಿ ನೀಡಬಹುದಾದ
                ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನ ಪ್ರದೇಶಗಳು ಮತ್ತು ವೈಶಿಷ್ಟ್ಯಗಳು ; ನಿಮ್ಮ ಬಳಕೆಯ
                ಮಾದರಿಗಳು ಸಾಮಾನ್ಯವಾಗಿ; ನಿರ್ದಿಷ್ಟ ವೈಶಿಷ್ಟ್ಯಗಳೊಂದಿಗೆ
                ತೊಡಗಿಸಿಕೊಳ್ಳುವಿಕೆ.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>ಇತರ ಮೂಲಗಳಿಂದ ಮಾಹಿತಿ ಸಂಗ್ರಹಿಸಲಾಗಿದೆ</i>
                </p>
              </b>
              <p>
                <i>
                  <b>ಸಂಕ್ಷಿಪ್ತ:</b> ನಾವು ಸಾರ್ವಜನಿಕ ಡೇಟಾಬೇಸ್‌ಗಳು, ಮಾರ್ಕೆಟಿಂಗ್
                  ಪಾಲುದಾರರು, ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳು ಮತ್ತು ಇತರ ಹೊರಗಿನ
                  ಮೂಲಗಳಿಂದ ಸೀಮಿತ ಡೇಟಾವನ್ನು ಸಂಗ್ರಹಿಸಬಹುದು.
                </i>
              </p>
              <p>
                ನಾವು ಸಾರ್ವಜನಿಕ ಡೇಟಾಬೇಸ್‌ಗಳು, ಮಾರ್ಕೆಟಿಂಗ್ ಪಾಲುದಾರರು, ಸಾಮಾಜಿಕ
                ಮಾಧ್ಯಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳು ಮತ್ತು ಇತರ ಹೊರಗಿನ ಮೂಲಗಳಿಂದ ಸೀಮಿತ
                ಡೇಟಾವನ್ನು ಸಂಗ್ರಹಿಸಬಹುದು. (ಫೇಸ್‌ಬುಕ್ ಸೇರಿದಂತೆ ಆದರೆ ಸೀಮಿತವಾಗಿಲ್ಲ
                ), ಮತ್ತು ಇತರ ಮೂರನೇ ವ್ಯಕ್ತಿಗಳಂತಹ ಇತರೆ ಮೂಲಗಳಿಂದ ನಾವು ನಿಮ್ಮ ಕುರಿತು
                ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಬಹುದು. ಈ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಲು, ನಾವು ನಿಮ್ಮ
                ಮೊಬೈಲ್ ಸಾಧನ ಅಥವಾ ಕಂಪ್ಯೂಟರ್‌ಗೆ ಕುಕೀಗಳನ್ನು ಕಳುಹಿಸಬಹುದು ಅಥವಾ ಇತರ
                ಟ್ರ್ಯಾಕಿಂಗ್ ತಂತ್ರಜ್ಞಾನವನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಬಹುದು. ನಿಮ್ಮ ಕುಕೀ
                ನೀತಿಯಲ್ಲಿ ಇನ್ನಷ್ಟು ನೋಡಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಾವು ಹೇಗೆ ಬಳಸುತ್ತೇವೆ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಕಾನೂನುಬದ್ಧ ವಾಣಿಜ್ಯ ಆಸಕ್ತಿಗಳು ಮತ್ತು ವ್ಯಾಪಕ
              ಸಾಮಾಜಿಕ ಪ್ರಯೋಜನಗಳು, ನಿಮ್ಮ ಜೊತೆಗಿನ ನಮ್ಮ ಒಪ್ಪಂದದ ನೆರವೇರಿಕೆ, ನಮ್ಮ
              ಕಾನೂನು ಬಾಧ್ಯತೆಗಳ ಅನುಸರಣೆ ಮತ್ತು/ಅಥವಾ ನಿಮ್ಮ ಒಪ್ಪಿಗೆಯ ಆಧಾರದ ಮೇಲೆ
              ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಾವು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತೇವೆ.
            </i>
          </p>
          <p>
            ನಾವು ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೂಲಕ ಸಂಗ್ರಹಿಸಿದ ಅನಾಮಧೇಯ ಮಾಹಿತಿಯನ್ನು ಬಗೆಬಗೆಯ
            ವಾಣಿಜ್ಯ ಆಸಕ್ತಿಗಳಿಗಾಗಿ ಮತ್ತು ಕೆಳಗೆ ವಿವರಿಸಿದಂತೆ ವ್ಯಾಪಕ ಸಾಮಾಜಿಕ
            ಪ್ರಯೋಜನಗಳಿಗಾಗಿ ಬಳಸುತ್ತೇವೆ. ನಿಮ್ಮ ಸಮ್ಮತಿಯೊಂದಿಗೆ
            (&quot;ಒಪ್ಪಂದದ&quot;)ನಿಮ್ಮ ಜೊತೆಗೆ ಒಪ್ಪಂದವನ್ನು ಪ್ರವೇಶ ಮಾಡಲು ಅಥವಾ
            ನಿರ್ವಹಿಸಲು ನಮ್ಮ ಕಾನೂನುಬ್ಧ ವಾಣಿಜ್ಯ ಆಸಕ್ತಿಗಳು ಮತ್ತು ವ್ಯಾಪಕವಾದ ಸಾಮಾಜಿಕ
            ಪ್ರಯೋಜನಗಳನ್ನು (&quot;ವಾಣಿಜ್ಯ ಮತ್ತು ಸಾಮಾಜಿಕ ಉದ್ದೇಶಗಳು&quot;),
            ಅವಲಂಬಿಸಿ ಈ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಾವು
            ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತೇವೆ (&quot;ಸಮ್ಮತಿ&quot;), ಮತ್ತು/ಅಥವಾ ನಮ್ಮ ಕಾನೂನು
            ಬಾಧ್ಯತೆಗಳ ಅನುಸರಣೆಗಾಗಿ (&quot;ಕಾನೂನು ಕಾರಣಗಳು&quot;). ಕೆಳಗಿನಂತೆ ಪಟ್ಟಿ
            ಮಾಡಲಾಗಿರುವ ಪ್ರತಿಯೊಂದು ಉದ್ದೇಶದ ಮುಂದೆ ನಾವು ಅವಲಂಬಿಸಿರುವ ನಿರ್ದಿಷ್ಟ
            ಸಂಸ್ಕರಣಾ ಆಧಾರಗಳನ್ನು ನಾವು ಸೂಚಿಸುತ್ತೇವೆ.
          </p>
          <p>
            ನಾವು ಸಂಗ್ರಹಿಸಿದ ಅಥವಾ ಸ್ವೀಕರಿಸುವ ಮಾಹಿತಿಯನ್ನು ಈ ಕೆಳಗಿನವುಗಳನ್ನು
            ಒಳಗೊಂಡಂತೆ ಆದರೆ ಸೀಮಿತವಾಗಿರದೇ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಬಳಸುತ್ತೇವೆ:
          </p>
          <p>
            - <b>ಖಾತೆ ರಚನೆ ಮತ್ತು ಲಾಗಿನ್ ಪ್ರಕ್ರಿಯೆ ಅನ್ನು ಸುಲಭವಾಗಿಸಲು </b>[ನಿಮ್ಮ
            ಸಮ್ಮತಿಯೊಂದಿಗೆ]. ನಿಮ್ಮ Google or Facebook ಖಾತೆಯಂತಹ ಮೂರನೇ ವ್ಯಕ್ತಿಯ
            ಖಾತೆಗೆ ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನಮ್ಮೊಂದಿಗೆ ಲಿಂಕ್ ಮಾಡಲು ನೀವು ಆಯ್ಕೆ ಮಾಡಿದರೆ,
            ಖಾತೆ ರಚನೆ ಮತ್ತು ಲಾಗಿನ್ ಪ್ರಕ್ರಿಯೆ ಅನ್ನು ಸುಲಭಗೊಳಿಸಲು ಆ ಮೂರನೇ
            ವ್ಯಕ್ತಿಗಳಿಂದ ಸಂಗ್ರಹಿಸಲು ನೀವು ನಮಗೆ ಅನುಮಿತಿಸಿದ ಮಾಹಿತಿಯನ್ನು ನಾವು
            ಬಳಸುತ್ತೇವೆ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗೆ &quot;ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಲಾಗಿನ್ ಡೇಟಾ&quot;
            ಶೀರ್ಷಿಕೆಯ ಕೆಳಗಿನ ವಿಭಾಗವನ್ನು ನೋಡಿ.
          </p>
          <p>
            - <b>ನಿಮಗೆ ಮಾರ್ಕೆಂಟಿಂಗ್ ಮತ್ತು ಪ್ರಚಾರದ ಸಂವಹನಗಳನ್ನು</b> ಕಳುಹಿಸಲು
            [ವಾಣಿಜ್ಯ ಮತ್ತು ಸಾಮಾಜಿಕ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಮತ್ತು/ಅಥವಾ ನಿಮ್ಮ
            ಸಮ್ಮತಿಯೊಂದಿಗೆ]. ನಿಮ್ಮ ಮಾರ್ಕೆಟಿಂಗ್ ಆದ್ಯತೆಗಳಿಗೆ ಅನುಗುಣವಾಗಿ ನಾವು
            ಮತ್ತು/ಅಥವಾ ನಮ್ಮ ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಮಾರ್ಕೆಟಿಂಗ್ ಪಾಲುದಾರರು ನೀವು ನಮಗೆ
            ಕಳುಹಿಸುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಮ್ಮ ಮಾರ್ಕೆಟಿಂಗ್ ಉದ್ದೇಶಗಳಿಗಾಗಿ
            ಬಳಸಬಹುದು. ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನೀವು ನಮ್ಮ ಮಾರ್ಕೆಟಿಂಗ್ ಇಮೇಲ್‌ಗಳಿಂದ
            ಹೊರಗುಳಿಯಬಹುದು (ಕೆಳಗಿನ &quot;ನಿಮ್ಮ ಗೌಪ್ಯತಾ ಹಕ್ಕುಗಳನ್ನು ನೋಡಿ&quot;).
          </p>
          <p>
            - ವಾಣಿಜ್ಯ ಮತ್ತು ಸಾಮಾಜಿಕ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಿಮಗೆ{' '}
            <b>ಆಡಳಿತಾತ್ಮಕ ಮಾಹಿತಿಯನ್ನು ಕಳುಹಿಸಲು</b> ಕಾನೂನು ಕಾರಣಗಳು ಮತ್ತು ಅಥವಾ
            ಪ್ರಾಯಶಃ ಒಪ್ಪಂದವಾಗಿದೆ. ಉತ್ಪನ್ನ, ಸೇವೆ ಮತ್ತು ಹೊಸ ವೈಶಿಷ್ಟ್ಯದ ಮಾಹಿತಿ
            ಮತ್ತು/ಅಥವಾ ನಮ್ಮ ನಿಯಮಗಳು, ಷರತ್ತುಗಳು ಮತ್ತು ನೀತಿಗಳಲ್ಲಿನ ಬದಲಾವಣೆಗಳ
            ಕುರಿತು ಮಾಹಿತಿಯನ್ನು ಕಳುಹಿಸಲು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಾವು
            ಬಳಸಬಹುದು, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್/ಅಪ್ಲಿಕೇಶನ್ ಮುಖಾಂತರ ನಿಮಗೆ ಒದಗಿಸುವ ಸೇವೆಗಳನ್ನು
            ಸುಧಾರಿಸಬಹುದು;
          </p>
          <p>
            -{' '}
            <b>ಪ್ಲಾಟ್‌‌ಫಾರ್ಮ್ ಅನ್ನು ವಿಶ್ಲೇಷಿಸಲು ಕಾರ್ಯನಿರ್ವಹಿಸಲು, ನಿರ್ವಹಿಸಲು</b>{' '}
            ಮತ್ತು ಸುಧಾರಿಸಲು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಹೊಸ ವೈಶಿಷ್ಟ್ಯಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು
            ಸೇರಿಸಲು, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಕಾರ್ಯಗಳನ್ನು ಬೆಂಬಲಿಸಲು;
          </p>
          <p>
            -{' '}
            <b>
              ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಕೊಡುಗೆಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ನಿಮಗೆ
              ಉತ್ಪನ್ನ ಮತ್ತು ಸೇವೆಯ ಕೊಡುಗೆಗಳು ಮತ್ತು ಶಿಫಾರಸುಗಳನ್ನು ಕಸ್ಟಮೈಸ್
              ಮಾಡುವುದಕ್ಕೆ,
            </b>{' '}
            ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಪಟ್ಟಂತೆ ನಿಮಗೆ ಸೂಚನೆ ನೀಡಲು, ನಿಮ್ಮ ಖಾತೆಯಲ್ಲಿನ
            ಸಮಸ್ಯೆಗಳನ್ನು ನಿವಾರಿಸಲು, ವಿವಾದವನ್ನು ಪರಿಹರಿಸಲು, ನಿಮ್ಮ ಖಾತೆಯಲ್ಲಿನ
            ಸಮಸ್ಯೆಗಳನ್ನು ನಿವಾರಿಸಲು, ವಿವಾದವನ್ನು ಪರಿಹರಿಸಲು, ಶುಲ್ಕಗಳು ಅಥವಾ ಹಣವನ್ನು
            ಸಂಗ್ರಹಿಸಲು, ನಿಮ್ಮ ಗುರುತನ್ನು ದೃಢೀಕರಿಸಲು ನೀವು ಪ್ಲಾಟ್ ಫಾರ್ಮ್/ಅಪ್ಲಿಕೇಷನ್
            ಬಳಸುವುದಕ್ಕೆ ಅರ್ಹರಾಗಿದ್ದೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಆದೇಶ;
          </p>
          <p>
            - <b>ನೀವು ವಿನಂತಿಸುವ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಒದಗಿಸಲು</b> ಮತ್ತು
            ತಲುಪಿಸಲು ವಹಿವಾಟುಗಳನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಮತ್ತು ದೃಢೀಕರಣಗಳು ಮತ್ತು
            ಜ್ಞಾಪನೆಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ನಿಮಗೆ ಸಂಬಂಧಿಸಿದ ಮಾಹಿತಿಯನ್ನು ಕಳುಹಿಸಲು;
          </p>
          <p>
            - <b>ಬಿಲ್ಲಿಂಗ್ (ಇನ್ವಾಯ್ಸಿಂಗ್), ಖಾತೆ ನಿರ್ವಹಣೆ</b> ಮತ್ತು ಇತರೆ
            ಆಡಳಿತಾತ್ಮಕ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಅನ್ವಯಿಸಿದರೆ;
          </p>
          <p>
            -{' '}
            <b>
              ನಿಮ್ಮ ಕಾಮೆಂಟ್‌ಗಳು, ಪ್ರಶ್ನೆಗಳು ಮತ್ತು ವಿನಂತಿಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಲು
            </b>{' '}
            ಮತ್ತು ಗ್ರಾಹಕ ಸೇವೆಯನ್ನು ಒದಗಿಸಲು;
          </p>
          <p>
            - <b>ನಿಮಗೆ ತಾಂತ್ರಿಕ ಸೂಚನೆಗಳು, ನವೀಕರಣಗಳು,</b> ಭದ್ರತಾ ಎಚ್ಚರಿಕೆಗಳು
            ಮತ್ತು ಬೆಂಬಲ ಮತ್ತು ಆಡಳಿತಾತ್ಮಕ ಸಂದೇಶಗಳನ್ನು ಕಳುಹಿಸಲು;
          </p>
          <p>
            - <b>ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಪ್ರವೃತ್ತಿಗಳು, </b>ಬಳಕೆ ಮತ್ತು
            ಚಟುವಟಿಕೆಗಳನ್ನು <b>ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಮತ್ತು ವಿಶ್ಲೇಷಿಸಲು;</b>
          </p>
          <p>
            - ಕೊವೆಸ್ಟ್ರೋ <b>ಪ್ರಚಾರದ ಉದ್ದೇಶಗಳಿಗಾಗಿ</b> ನೀವು ಹಂಚಿಕೊಳ್ಳಲು ಒಪ್ಪುವ
            ಮಾಹಿತಿಗೆ ಸಂಬಂಧಪಟ್ಟಂತೆ ಮಾತ್ರ;
          </p>
          <p>
            - <b>ನಿಮ್ಮ ಗುರುತನ್ನು ಪರಿಶೀಲಿಸಲು;</b>
          </p>
          <p>
            - ನಮ್ಮ ವ್ಯವಹಾರ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಮತ್ತು/ಅಥವಾ ನಿಮ್ಮ ಒಪ್ಪಿಗೆಯೊಂದಿಗೆ{' '}
            <b>ನಿಮಗೆ ಉದ್ದೇಶಿತ ಜಾಹೀರಾತನ್ನು ತಲುಪಿಸಿ.</b> ವಿಷಯ ಮತ್ತು ಜಾಹೀರಾತನ್ನು
            ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಮತ್ತು ಪ್ರದರ್ಶಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆಸಕ್ತಿಗಳು ಮತ್ತು/ಅಥವಾ
            ಸ್ಥಳಕ್ಕೆ ಅನುಗುಣವಾಗಿ ಮಾಡುವ ಮೂರನೇ ವ್ಯಕ್ತಿಗಳ ಜೊತೆಗೆ ಕೆಲಸ ಮಾಡಲು ಮತ್ತು
            ಅದರ ಪರಿಣಾಮಕಾರಿತ್ವವನ್ನು ಅಳೆಯಲು ನಾವು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಬಳಸಬಹುದು.
          </p>
          <p>
            - ನಮ್ಮ ವ್ಯವಹಾರ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಮತ್ತು/ಅಥವಾ ನಿಮ್ಮ ಒಪ್ಪಿಗೆಯೊಂದಿಗೆ{' '}
            <b>ಬಹುಮಾನ ಡ್ರಾಗಳು ಮತ್ತು ಸ್ಪರ್ಧೆಗಳ್ನು</b>ನಿರ್ವಹಿಸಿ. ನೀವು
            ಸ್ಪರ್ಧೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸಲು ಆಯ್ಕೆ ಮಾಡಿದಾಗ ಬಹುಮಾನ ಡ್ರಾಗಳು ಮತ್ತು
            ಸ್ಪರ್ಧೆಗಳನ್ನು ನಿರ್ವಹಿಸಲು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಾವು ಬಳಸಬಹುದು.;
          </p>
          <p>
            - ನಮ್ಮ ವ್ಯವಹಾರ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಮತ್ತು/ ಅಥವಾ ನಿಮ್ಮ ಒಪ್ಪಿಗೆಯೊಂದಿಗೆ{' '}
            <b>ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ವಿನಂತಿಸಿ.</b> ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ವಿನಂತಿಸಲು ಮತ್ತು
            ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ನಿಮ್ಮ ಬಳಕೆಯ ಬಗ್ಗೆ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು ನಾವು
            ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಬಳಸಬಹುದು;
          </p>
          <p>
            - ವ್ಯವಹಾರ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಮತ್ತು/ಅಥವಾ ಕಾನೂನು ಕಾರಣಗಳಿಗಾಗಿ{' '}
            <b>ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ರಕ್ಷಿಸಲು.</b> ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು
            ಸುರಕ್ಷಿತವಾಗಿ ಮತ್ತು ಸುರಕ್ಷಿತವಾಗಿರಿಸಲು ನಮ್ಮ ಪ್ರಯತ್ನಗಳ ಭಾಗವಾಗಿ ನಿಮ್ಮ
            ಮಾಹಿತಿಯನ್ನು ನಾವು ಬಳಸಬಹುದು (ಉದಾಹರಣೆಗೆ, ವಂಚನೆ ಮೇಲ್ವಿಚಾರಣೆ ಮತ್ತು
            ತಡೆಗಟ್ಟುವಿಕೆಗಾಗಿ);
          </p>
          <p>
            - ವ್ಯವಹಾರ ಉದ್ದೇಶಗಳು, ಕಾನೂನು ಕಾರಣಗಳು ಮತ್ತು/ಅಥವಾ ಪ್ರಾಯಶಃ ಒಪ್ಪಂದಕ್ಕಾಗಿ{' '}
            <b>ನಮ್ಮ ನಿಯಮಗಳು, ಷರತ್ತುಗಳು ಮತ್ತು ನೀತಿಗಳನ್ನು </b>ಜಾರಿಗೊಳಿಸುವುದಕ್ಕೆ;
          </p>
          <p>
            -{' '}
            <b>
              ಕಾನೂನು ವಿನಂತಿಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಲು ಮತ್ತು ಕಾನೂನು ಕಾರಣಗಳಿಗಾಗಿ
              ಹಾನಿಯನ್ನು ತಡೆಯಲು.
            </b>{' '}
            ನಾವು ಸಬ್‌ಪೋನಾ ಅಥವಾ ಇತರ ಕಾನೂನು ವಿನಂತಿ ಅನ್ನು ಸ್ವೀಕರಿಸಿದರೆ, ಹೇಗೆ
            ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಬೇಕು ಎಂಬುದನ್ನು ನಿರ್ಧರಿಸಲು ನಾವು ಹೊಂದಿರುವ ಡೇಟಾವನ್ನು
            ನಾವು ಪರಿಶೀಲಿಸಬೇಕಾಗಬಹುದು;
          </p>
          <p>
            - <b>ಇತರ ವಾಣಿಜ್ಯ ಮತ್ತು ಸಾಮಾಜಿಕ ಉದ್ದೇಶಗಳಿಗಾಗಿ.</b> ಡೇಟಾ ವಿಶ್ಲೇಷಣೆ,
            ಬಳಕೆಯ ಪ್ರವೃತ್ತಿಗಳನ್ನು ಗುರುತಿಸುವುದು, ನಮ್ಮ ಪ್ರಚಾರದ ಕಾರ್ಯಾಚರಣೆಗಳ
            ಪರಿಣಾಮಕಾರಿತ್ವವನ್ನು ನಿರ್ಧರಿಸುವುದು ಮತ್ತು ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮತ್ತು
            ನಿಮ್ಮ ಅನುಭವವನ್ನು ಮೌಲ್ಯ ಮಾಪನ ಮಾಡಲು ಮತ್ತು ಸುಧಾರಿಸಲು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು
            ನಾವು ಇತರ ವ್ಯಾಪಾರ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಬಳಸಬಹುದು; ಮತ್ತು
          </p>
          <p>
            - <b>ಕನ್ಸೋರ್ಟಿಯಂ ಪಾಲುದಾರರಿಗೆ.</b> ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೋಂದಾಯಿಸಲ್ಪಟ್ಟ
            ನಮ್ಮ ಒಕ್ಕೂಟದ ಪಾಲುದಾರರು ಮತ್ತು ಬಳಕೆದಾರರು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೀವು
            ಆಸಕ್ತಿ ತೋರಿದ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಬಳಸಬಹುದು.
          </p>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಯಾರೊಂದಿಗಾದರೂ ಹಂಚಿಕೊಳ್ಳಲಾಗುತ್ತದೆಯೇ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಕಾನೂನುಗಳನ್ನು ಅನುಸರಿಸಲು, ನಿಮ್ಮ ಹಕ್ಕುಗಳನ್ನು
              ರಕ್ಷಿಸಲು ಅಥವಾ ವ್ಯವಹಾರದ ಜವಾಬ್ದಾರಿಗಳನ್ನು ಪೂರೈಸಲು ನಿಮ್ಮ
              ಒಪ್ಪಿಗೆಯೊಂದಿಗೆ ಮಾತ್ರ ನಾವು ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇವೆ.
            </i>
          </p>
          <p>
            ಈ ಕೆಳಗಿನ ಸಂದರ್ಭಗಳಲ್ಲಿ ಮಾತ್ರ ನಾವು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇವೆ
            ಮತ್ತು ಬಹಿರಂಗಪಡಿಸುತ್ತೇವೆ:
          </p>
          <p>
            - <b>ಕಾನೂನುಗಳ ಅನುಸರಣೆ.</b> ಅನ್ವಯವಾಗುವ ಕಾನೂನು, ಸರ್ಕಾರಿ ವಿನಂತಿಗಳು,
            ನ್ಯಾಯಾಂಗ ಪ್ರಕ್ರಿಯೆ, ನ್ಯಾಯಾಲಯದ ಆದೇಶ ಅಥವಾ ಕಾನೂನು ಪ್ರಕ್ರಿಯೆಗೆ
            ಅನುಗುಣವಾಗಿ ನಾವು ಕಾನೂನುಬದ್ಧವಾಗಿ ಅಗತ್ಯವಿರುವಲ್ಲಿ ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಾವು
            ಬಹಿರಂಗಪಡಿಸಬಹುದು. ಉದಾಹರಣೆಗೆ ನ್ಯಾಯಾಲಯದ ಆದೇಶ ಅಥವಾ ಸಬ್‌ಪೋನಾಗೆ
            ಪ್ರತಿಕ್ರಿಯೆಯಾಗಿ (ಪ್ರತಿಕ್ರಿಯೆ ಸೇರಿದಂತೆ ರಾಷ್ಟ್ರೀಯ ಭದ್ರತೆ ಅಥವಾ ಕಾನೂನು
            ಜಾರಿ ಅವಶ್ಯಕತೆಗಳನ್ನು ಪೂರೈಸಲು ಸಾರ್ವಜನಿಕ ಅಧಿಕಾರಿಗಳಿಗೆ).
          </p>
          <p>
            - <b>ಪ್ರಮುಖ ಆಸಕ್ತಿಗಳು ಮತ್ತು ಕಾನೂನು ಹಕ್ಕುಗಳು.</b> ನಮ್ಮ ನೀತಿಗಳ ಸಂಭಾವ್ಯ
            ಉಲ್ಲಂಘನೆಗಳು, ಶಂಕಿತ ವಂಚನೆ, ಯಾವುದೇ ವ್ಯಕ್ತಿಯ ಸುರಕ್ಷತೆಗೆ ಸಂಭವನೀಯ
            ಬೆದರಿಕೆಗಳು ಮತ್ತು ಕಾನೂನುಬಾಹಿರ ಚಟುವಟಿಕೆಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಸಂದರ್ಭಗಳು
            ಅಥವಾ ದಾವೆಯಲ್ಲಿ ಸಾಕ್ಷ್ಯವಾಗಿ ತನಿಖೆ ಮಾಡುವುದು, ತಡೆಯುವುದು ಅಥವಾ ಕ್ರಮ
            ತೆಗೆದುಕೊಳ್ಳುವುದು ಅಗತ್ಯವೆಂದು ನಾವು ಭಾವಿಸುವ ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು
            ಬಹಿರಂಗಪಡಿಸಬಹುದು. ಅಥವಾ ನಾವು ಭಾಗಿಯಾಗಿರುವ ದಾವೆಯಲ್ಲಿ ಸಾಕ್ಷಿಯಾಗಿ.
          </p>
          <p>
            -{' '}
            <b>
              ಮಾರಾಟಗಾರರು, ಸಲಹೆಗಾರರು ಮತ್ತು ಇತರ ಮೂರನೇ-ವ್ಯಕ್ತಿಯ ಸೇವಾ ಪೂರೈಕೆದಾರರು.
            </b>{' '}
            ನಿಮ್ಮ ಡೇಟಾವನ್ನು ನಾವು ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಮಾರಾಟಗಾರರು, ಸೇವಾ ಪೂರೈಕೆದಾರರು,
            ಗುತ್ತಿಗೆದಾರರು ಅಥವಾ ನಮಗಾಗಿ ಅಥವಾ ನಮ್ಮ ಪರವಾಗಿ ಸೇವೆಗಳನ್ನು ನಿರ್ವಹಿಸುವ
            ಏಜೆಂಟ್‌ಗಳೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಬಹುದು ಮತ್ತು ಆ ಕೆಲಸವನ್ನು ಮಾಡಲು ಅಂತಹ ಮಾಹಿತಿಗೆ
            ಪ್ರವೇಶದ ಅಗತ್ಯತೆ ಇರುತ್ತದೆ. ಉದಾಹರಣೆಗಳು ಒಳಗೊಂಡಿವೆ: ಪಾವತಿ ಪ್ರಕ್ರಿಯೆ,
            ಡೇಟಾ ವಿಶ್ಲೇಷಣೆ, ಇ-ಮೇಲ್ ವಿತರಣೆ, ಹೋಸ್ಟಿಂಗ್ ಸೇವೆಗಳು, ಗ್ರಾಹಕ ಸೇವೆ ಮತ್ತು
            ಮಾರ್ಕೆಟಿಂಗ್ ಪ್ರಯತ್ನಗಳು. ಆಯ್ದ ಮೂರನೇ ವ್ಯಕ್ತಿಗಳಿಗೆ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ
            ಟ್ರ್ಯಾಕಿಂಗ್ ತಂತ್ರಜ್ಞಾನವನ್ನು ಬಳಸಲು ನಾವು ಅನುಮತಿಸಬಹುದು. ಇದು ನೀವು
            ಕಾಲಾನಂತರದಲ್ಲಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನೊಂದಿಗೆ ಸಂವಹನವನ್ನು ಹೇಗೆ ನಡೆಸುತ್ತೀರಿ
            ಎಂಬುದರ ಬಗ್ಗೆ ಡೇಟಾವನ್ನು ಸಂಗ್ರಹಿಸಲು ಅವರಿಗೆ ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ. ಈ
            ಮಾಹಿತಿಯನ್ನು ಇತರ ವಿಷಯಗಳ ಜೊತೆಗೆ, ಡೇಟಾವನ್ನು ವಿಶ್ಲೇಷಿಸಲು ಮತ್ತು ಟ್ಯ್ರಾಕ್
            ಮಾಡಲು, ನಿರ್ದಿಷ್ಟ ವಿಷಯದ ಜನಪ್ರಿಯತೆ ಅನ್ನು ನಿರ್ಧರಿಸಲು ಮತ್ತು ಆನ್‌ಲೈನ್
            ಚಟುವಟಿಕೆಯನ್ನು ಉತ್ತಮವಾಗಿ ಅರ್ಥ ಮಾಡಿಕೊಳ್ಳಲು ಬಳಸಬಹುದು. ಈ ನೀತಿಯಲ್ಲಿ
            ವಿವರಿಸದ ಹೊರೆತು, ಮೂರನೇ ಪಕ್ಷಗಳಿಗೆ ಪ್ರಚಾರದ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಾವು ನಿಮ್ಮ
            ಯಾವುದೇ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುವುದಿಲ್ಲ. ಮಾರಾಟ ಮಾಡುವುದಿಲ್ಲ. ಬಾಡಿಗೆ ಅಥವಾ
            ವ್ಯವಹಾರ ಮಾಡುವುದಿಲ್ಲ.
          </p>
          <p>
            - <b>ವ್ಯವಹಾರ ವರ್ಗಾವಣೆಗಳು.</b> ಯಾವುದೇ ವಿಲೀನ, ಕಂಪನಿಯ ಸ್ವತ್ತುಗಳ ಮಾರಾಟ,
            ಹಣಕಾಸು, ಅಥವಾ ನಮ್ಮ ವ್ಯವಹಾರದ ಎಲ್ಲಾ ಅಥವಾ ಒಂದು ಭಾಗವನ್ನು ಮತ್ತೊಂದು ಕಂಪನಿಗೆ
            ಸ್ವಾಧೀನಪಡಿಸಿಕೊಳ್ಳುವ ಸಂಬಂಧದಲ್ಲಿ ಅಥವಾ ಮಾತುಕತೆಯ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ
            ಮಾಹಿತಿಯನ್ನು ನಾವು ಹಂಚಿಕೊಳ್ಳಬಹುದು ಅಥವಾ ವರ್ಗಾಯಿಸಬಹುದು.
          </p>
          <p>
            - <b>ಮೂರನೆಯ-ವ್ಯಕ್ತಿ ಜಾಹೀರಾತುದಾರರು.</b> ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ನೀವು ಭೇಟಿ
            ನೀಡಿದಾಗ ಜಾಹೀರಾತುಗಳನ್ನು ನೀಡಲು ನಾವು ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಜಾಹೀರಾತು
            ಕಂಪನಿಗಳನ್ನು ಬಳಸಬಹುದು. ನಿಮಗೆ ಆಸಕ್ತಿಯಿರುವ ಸರಕುಗಳು ಮತ್ತು ಸೇವೆಗಳ ಕುರಿತು
            ಜಾಹೀರಾತುಗಳನ್ನು ಒದಗಿಸಲು ವೆಬ್ ಕುಕೀಗಳು ಮತ್ತು ಇತರ ಟ್ರ್ಯಾಕಿಂಗ್
            ತಂತ್ರಜ್ಞಾನಗಳಲ್ಲಿ ಒಳಗೊಂಡಿರುವ ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ (ಗಳು) ಮತ್ತು ಇತರ
            ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳಿಗೆ ನಿಮ್ಮ ಭೇಟಿಗಳ ಮಾಹಿತಿಯನ್ನು ಈ ಕಂಪನಿಗಳು ಬಳಸಬಹುದು.
            [ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ ನಮ್ಮ ಕುಕಿ ನೀತಿ [ಹೈಪರ್‌ಲಿಂಕ್] ನೋಡಿ]
          </p>
          <p>
            - <b>ಅಂಗಸಂಸ್ಥೆಗಳು.</b> ನಾವು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಮ್ಮ ಅಂಗಸಂಸ್ಥೆಗಳೊಂದಿಗೆ
            ಹಂಚಿಕೊಳ್ಳಬಹುದು, ಈ ಸಂದರ್ಭದಲ್ಲಿ ಆ ಅಂಗಸಂಸ್ಥೆಗಳು ಈ ಗೌಪ್ಯತೆ ನೀತಿ ಅನ್ನು
            ಗೌರವಿಸುವ ಅಗತ್ಯವಿದೆ. ಅಂಗಸಂಸ್ಥೆಗಳು ನಮ್ಮ ಪೋಷಕ ಕಂಪನಿ ಮತ್ತು ಯಾವುದೇ
            ಅಂಗಸಂಸ್ಥೆಗಳು, ಜಂಟಿ ಉದ್ಯಮ ಪಾಲುದಾರರು ಅಥವಾ ನಾವು ನಿಯಂತ್ರಿಸುವ ಅಥವಾ
            ನಮ್ಮೊಂದಿಗೆ ಸಾಮಾನ್ಯ ನಿಯಂತ್ರಣದಲ್ಲಿರುವ ಇತರ ಕಂಪನಿಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.
          </p>
          <p>
            - <b>ವ್ಯವಹಾರದ ಪಾಲುದಾರರು.</b> ನಿಮಗೆ ಕೆಲವು ಉತ್ಪನ್ನಗಳು, ಸೇವೆಗಳು ಅಥವಾ
            ಪ್ರಚಾರಗಳನ್ನು ನೀಡಲು ನಾವು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಮ್ಮ ವ್ಯವಹಾರ
            ಪಾಲುದಾರರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಬಹುದು.
          </p>
          <p>
            - <b>ನಿಮ್ಮ ಒಪ್ಪಿಗೆಯೊಂದಿಗೆ.</b> ನಾವು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಿಮ್ಮ
            ಸಮ್ಮತಿಯೊಂದಿಗೆ ಬೇರೆ ಯಾವುದೇ ಉದ್ದೇಶಕ್ಕಾಗಿ ಬಹಿರಂಗಪಡಿಸಬಹುದು.
          </p>
          <p>
            - <b>ಇತರೆ ಬಳಕೆದಾರರು.</b> ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಂಡಾಗ
            (ಉದಾಹರಣೆಗೆ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಕಾಮೆಂಟ್‌ಗಳು, ಕೊಡುಗೆಗಳು, ಅಥವಾ ಇತರ
            ವಿಷಯವನ್ನು ಪೋಸ್ಟ್ ಮಾಡುವ ಮೂಲಕ) ಅಥವಾ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಸಾರ್ವಜನಿಕ
            ಪ್ರದೇಶಗಳೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಿದಾಗ ಅಂತಹ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಎಲ್ಲಾ
            ಬಳಕೆದಾರರು ವೀಕ್ಷಿಸಬಹುದು ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಹೊರಗೆ ಶಾಶ್ವತವಾಗಿ
            ಸಾರ್ವಜನಿಕವಾಗಿ ವಿತರಿಸಬಹುದು. [ನೀವು ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಇತರ
            ಬಳಕೆದಾರರೊಂದಿಗೆ ಸಂವಹನ ನಡೆಸಿದರೆ ಮತ್ತು ಸಾಮಾಜಿಕ ನೆಟ್‌ವರ್ಕ್ ಮೂಲಕ
            (ಫೇಸ್‌ಬುಕ್‌ನಂತಹ) ನೋಂದಾಯಿಸಿದರೆ, ಸಾಮಾಜಿಕ ನೆಟ್‌ವರ್ಕ್‌ನಲ್ಲಿರುವ ನಿಮ್ಮ
            ಸಂಪರ್ಕಗಳು ನಿಮ್ಮ ಹೆಸರು, ಪ್ರೊಫೈಲ್ ಫೋಟೋ ಮತ್ತು ನಿಮ್ಮ ಚಟುವಟಿಕೆಯ
            ವಿವರಣೆಗಳನ್ನು ನೋಡುತ್ತೇವೆ.] ಹಾಗೆಯೇ, ಇತರ ಬಳಕೆದಾರರು ನಿಮ್ಮ ಚಟುವಟಿಕೆಯ
            ವಿವರಣೆಯನ್ನು ವೀಕ್ಷಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ. ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನಿಮ್ಮ
            ಜೊತೆಗೆ ಸಂವಹನ ನಡೆಸಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ವೀಕ್ಷಿಸಬಹುದು.
          </p>
        </li>
        <li>
          <h3>
            ನಾವು ಕುಕೀಗಳು ಮತ್ತು ಇತರ ಟ್ರ್ಯಾಕಿಂಗ್ ತಂತ್ರಜ್ಞಾನಗಳನ್ನು ಬಳಸುತ್ತೇವೆಯೇ?
          </h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಲು ಮತ್ತು ಹೇರಳವಾಗಿಸಲು
              ನಾವು ಕುಕೀಗಳು ಮತ್ತು ಇತರ ಟ್ರ್ಯಾಕಿಂಗ್ ತಂತ್ರಜ್ಞಾನಗಳನ್ನು ಬಳಸಬಹುದು.
            </i>
          </p>
          <p>
            ಯಾವುದೇ ಕಂಪ್ಲೈಂಟ್ ಸಂಸ್ಥೆಯಾಗಿ, ನಿಮ್ಮ ಅನುಭವ ಮತ್ತು ನಮ್ಮ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
            ಸೇವೆಗಳನ್ನು ಹೆಚ್ಚಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುವ ಕುಕೀಗು ಮತ್ತು ಅಂತಹುದೇ
            ತಂತ್ರಜ್ಞಾನಗಳ ಮೂಲಕ ನಾವು ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುತ್ತೇವೆ. ನಾವು ಬಳಸುವ
            ಕುಕೀಗಳು ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ ಮತ್ತು
            ಸಂದರ್ಶಕರಿಗೆ ಯಾವ ಮಾಹಿತಿ ಮತ್ತು ಜಾಹೀರಾತು ಹೆಚ್ಚು ಉಪಯುಕ್ತವಾಗಿದೆ ಎಂಬುದನ್ನು
            ಅರ್ಥ ಮಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.
          </p>
          <p>
            ಹೆಚ್ಚಿನ ವೆಬ್ ಬ್ರೌಸರ್‌ಗಳು ಪೂರ್ವನಿಯೋಜಿತವಾಗಿ ಕುಕೀಗಳನ್ನು ಸ್ವೀಕರಿಸಲು
            ಹೊಂದಿಸಲಾಗಿದೆ. ನೀವು ಕುಕೀಗಳನ್ನು ತೆಗೆದು ಹಾಕಲು ಅಥವಾ ಕುಕೀಗಳನ್ನು
            ತಿರಸ್ಕರಿಸಲು ಆಯ್ಕೆ ಮಾಡಿದರೆ, ಇದು ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಕೆಲವು
            ವೈಶಿಷ್ಟ್ಯಗಳು ಅಥವಾ ಸೇವೆಗಳ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರಬಹುದು. ನೀವು ಕುಕೀಗಳನ್ನು
            ತೆಗೆದುಹಾಕಲು ಅಥವಾ ಕುಕೀಗಳನ್ನು ತಿರಸ್ಕರಿಸಲು ಆಯ್ಕೆ ಮಾಡಿದರೆ, ಇದು ನಮ್ಮ
            ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಕೆಲವು ವೈಶಿಷ್ಟ್ಯಗಳು ಅಥವಾ ಸೇವೆಗಳ ಮೇಲೆ ಪರಿಣಾಮ ಬೀರಬಹುದು.
          </p>
          <p>ಬಳಕೆದಾರ ಕೇಂದ್ರಿತ ತಂತ್ರಜ್ಞಾನ (ಸಮ್ಮತಿ ಸಾಧನ)</p>
          <p>
            ಸಮ್ಮತಿಯ ಅಗತ್ಯವಿರುವ ಕುಕೀಗಳಿಗೆ ಮತ್ತು ಕುಕೀ ಆಧಾರಿತ ಅಪ್ಲಿಕೇಶನ್‌ಗಳಿಗೆ
            ಪರಿಣಾಮಕಾರಿ ಬಳಕೆದಾರ ಸಮ್ಮತಿ ಅನ್ನು ಪಡೆಯಲು, ನಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಯೂಸರ್
            ಸೆಂಟ್ರಿಕ್ಸ್ GmbH, Sendlinger Str ಅಭಿವೃದ್ಧಿಪಡಿಸಿದ ತಂತ್ರಜ್ಞಾನದೊಂದಿಗೆ
            ಕುಕೀ ಸಮ್ಮತಿ ಸಾಧನವನ್ನು ಬಳಸುತ್ತದೆ. 7, 80331 ಮ್ಯೂನಿಟ್ (ಇನ್ನು ಮುಂದೆ
            &quot;ಬಳಕೆದಾರ ಕೇಂದ್ರಗಳು&quot; ಎಂದು ಉಲ್ಲೇಖಿಸಲಾಗುತ್ತದೆ).
          </p>
          <p>
            ಅನುಗುಣವಾದ JavaScript ಕೋಡ್ ಅನ್ನು ಸಂಯೋಜಿಸುವ ಮೂಲಕ, ಬಳಕೆದಾರರು ಪುಟಕ್ಕೆ
            ಭೇಟಿ ನೀಡಿದಾಗ ಬ್ಯಾನರ್ ಅನ್ನು ತೋರಿಸಲಾಗುತ್ತದೆ. ಇದರಲ್ಲಿ ಕೆಲವು ಕುಕೀಗಳು
            ಮತ್ತು/ಅಥವಾ ಕುಕೀ-ಆಧಾರಿತ ಅಪ್ಲಿಕೇಶನ್‌ಗಳಿಗೆ ಬಾಕ್ಸ್‌ಗಳನ್ನು ಪರಿಶೀಲಿಸುವ
            ಮೂಲಕ ಸಮ್ಮತಿ ಅನ್ನು ನೀಡಬಹುದು. ಅನುಗುಣವಾದ ಬಾಕ್ಸ್‌ಗಳನ್ನು ಪರಿಶೀಲಿಸುವ ಮೂಲಕ
            ಆಯಾ ಬಳಕೆದಾರರು ಒಪ್ಪಿಗೆ ನೀಡುವವರೆಗೆ ಸಮ್ಮತಿಯ ಅಗತ್ಯವಿರುವ ಎಲ್ಲಾ ಕುಕೀಗಳ
            ಸೆಟ್ಟಿಂಗ್ ಅನ್ನು ಉಪಕರಣವು ನಿರ್ಬಂಧಿಸುತ್ತದೆ. ಸಮ್ಮತಿ ಅನ್ನು ನೀಡಿದಾಗ ಅಂತಹ
            ಕುಕೀಗಳನ್ನು ಬಳಕೆದಾರರ ಆಯಾ ಅಂತಿಮ ಸಾಧನದಲ್ಲಿ ಮಾತ್ರ ಹೊಂದಿಸಲಾಗಿದೆ ಎಂದು ಇದು
            ಖಚಿತಪಡಿಸುತ್ತದೆ.
          </p>
          <p>
            ವೆಬ್‌ಸೈಟ್ ಕೆಳಗಿನ ಬಲಭಾಗದಲ್ಲಿರುವ UserCentrics ಬಟನ್- ಅನ್ನು ಒತ್ತುವ ಮೂಲಕ
            ಬಳಕೆದಾರರು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಬದಲಾಯಿಸಬಹುದು.
          </p>
          <p>
            ನಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಅನ್ನು ಪ್ರವೇಶಿಸಿದಾಗ, ನಿರ್ದಿಷ್ಟ ಬಳಕೆದಾರರ ಮಾಹಿತಿಯನ್ನು
            (IP ವಿಳಾಸವನ್ನು ಒಳಗೊಂಡಂತೆ) ಕುಕೀ ಸಮ್ಮತಿ ಉಪಕಪರಣದಿಂದ ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ,
            ಬಳಕೆದಾರ ಕೇಂದ್ರಗಳ ಸರ್ವರ್‌ಗಳಿಗೆ ರವಾನಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಅಲ್ಲಿ
            ಉಳಿಸಲಾಗುತ್ತದೆ. ಇದರಿಂದ ಕುಕೀ ಸಮ್ಮತಿ ಉಪಕರಣವು ವೈಯಕ್ತಿಕ ಬಳಕೆದಾರರಿಗೆ ಪುಟ್ಟ
            ವೀಕ್ಷಣೆಗಳನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ನಿಯೋಜಿಸಬಹುದು ಮತ್ತು ಪ್ರತ್ಯೇಕವಾಗಿ
            ದಾಖಲಿಸಬಹುದು, ಲಾಗ್ ಮಾಡಬಹುದು ಮತ್ತು ಬಳಕೆದಾರರು ಮಾಡಿದ ಸಮ್ಮತಿಯ
            ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಸೆಷನ್‌ನ ಅವಧಿಯವರೆಗೆ ಉಳಿಸಿ.
          </p>
          <p>
            ಯೂಸರ್‌ಸೆಂಟ್ರಿಕ್ಸ್ ಗೌಪ್ಯತಾ ನೀತಿ{' '}
            <a
              href='https://usercentrics.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              https://usercentrics.com/privacy-policy/
            </a>{' '}
            ನಲ್ಲಿ ಬಳಕೆದಾರರಿಂದ ಡೇಟಾ ಬಳಕೆಯ ಬಗ್ಗೆ ಹೆಚ್ಚುವರಿ ಮಾಹಿತಿಯನ್ನು ನೀವು
            ಕಾಣಬಹುದಾಗಿದೆ.
          </p>
          <p>
            ನಿಮಗೆ ಇಮೇಲ್ ಕಳುಹಿಸುವ ಮೂಲಕ ಅಥವಾ ಕೆಳಗಿನ “ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ” ಮೂಲಕ
            ವಿನಂತಿ ಅನ್ನು ಸಲ್ಲಿಸುವ ಮೂಲಕ ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದರೆ ನಮಗೆ
            ತಿಳಿಸಿ.
          </p>
        </li>
        <li>
          <h3>ನಾವು ಸ್ಥಳ ಸೇವೆಗಳನ್ನು ಬಳಸುತ್ತೇವೆಯೇ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಹೌದು, ಉತ್ತಮ ಸೇವೆ ಅನ್ನು ಒದಗಿಸುವ ಉದ್ದೇಶಕ್ಕಾಗಿ ನಾವು
              ಸ್ಥಳ ಸೇವೆಗಳನ್ನು, ಅಂತನಿರ್ಮಿತ ಪ್ರೋಗ್ರಾಂ ಅಥವಾ Google ನಕ್ಷೆಗಳಂತಹ ಬಾಹ್ಯ
              ಪೂರೈಕೆದಾರರನ್ನು ಬಳಸುತ್ತೇವೆ.
            </i>
          </p>
          <p>
            ಈ ನೀತಿಯ ಉದ್ದೇಶಕ್ಕಾಗಿ ಸಂಗ್ರಹಿಸಿದ ಮಾಹಿತಿಯನ್ನು ಮಾತ್ರ ಬಳಸಲಾಗುತ್ತದೆ ಮತ್ತು
            ಅಂತಹ ಸೇವಾ ಪೂರೈಕೆದಾರರ ಬಳಕೆಯ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು ನಮ್ಮ ವ್ಯಾಪ್ತಿ
            ಮತ್ತು ನಿಯಂತ್ರಣವನ್ನು ಮೀರಿವೆ.
          </p>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಅಂತರಾಷ್ಟ್ರೀಯವಾಗಿ ವರ್ಗಾಯಿಸಲಾಗಿದೆಯೇ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ನಿಮ್ಮದೇ ಆದ ದೇಶಗಳನ್ನು ಹೊರತುಪಡಿಸಿ ನಾವು ನಿಮ್ಮ
              ಮಾಹಿತಿಯನ್ನು ವರ್ಗಾಯಿಸಬಹುದು, ಸಂಗ್ರಹಿಸಬಹುದು ಮತ್ತು
              ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಬಹುದು.
            </i>
          </p>
          <p>
            ನಮಗಾಗಿ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಡೇಟಾದೊಂದಿಗೆ ನಿರ್ದಿಷ್ಟ ಕಾರ್ಯಾಚರಣೆಗಳನ್ನು
            ನಿರ್ವಹಿಸುವ ಪ್ರೊಸೆಸರ್‌ಗಳನ್ನು ನಾವು ತೊಡಗಿಸಿಕೊಳ್ಳುತ್ತೇವೆ. ಪ್ರೊಸೆಸರ್ ಗಳು
            ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಚಲಾಯಿಸಲು, ನಿಮ್ಮೊಂದಿಗೆ ನಮ್ಮ ಸಂವಹನವನ್ನು ಬೆಂಬಲಿಸಲು
            ಅಥವಾ ಇತರ ಪ್ಲಾಟ್ ಫಾರ್ಮ್ -ಸಂಬಂಧಿತ ಚಟುವಟಿಕೆಗಳನ್ನು ನಿರ್ವಹಿಸಲು ನಮಗೆ ಸಹಾಯ
            ಮಾಡುವ ಕಂಪನಿಗಳಾಗಿವೆ. ವೆಬ್‌ಸೈಟ್ ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್ ಕಾರ್ಯಗಳು ಮತ್ತು
            ಸಂಬಂಧಿತ ಚಟುವಟಿಕೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ಗುರಿಗಳನ್ನು ಸಾಧಿಸಲು ಅವರು ನಮ್ಮ ಪರವಾಗಿ
            ಕೆಲವು ವೈಯಕ್ತಿಕ ಡೇಟಾವನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಬಹುದು. ಪ್ರೊಸೆಸರ್‌ಗಳು ನಮ್ಮ
            ಸೂಚನೆಗಳಿಗೆ ತಕ್ಕಂತೆ ಮಾತ್ರ ಕಾರ್ಯ ನಿರ್ವಹಿಸುತ್ತವೆ ಮತ್ತು ನಾವು
            ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಸೂಚಿಸುವ ವೈಯಕ್ತಿಕ ಡೇಟಾವನ್ನು ಮಾತ್ರ
            ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತವೆ. ನಮ್ಮ ಪ್ರೊಸೆಸರ್‌ಗಳು ಯಾವುದೇ ಕಾರ್ಯಗಳು ಅಥವಾ
            ಲೋಪಗಳಿಗೆ ನಾವು ಸಂಪೂರ್ಣ ಜವಾಬ್ದಾರಾಗಿರುತ್ತೇವೆ ಮತ್ತು ಅನ್ವಯವಾಗುವ
            ಕಾನೂನುನಿಂದ ಅಗತ್ಯವಿರುವ ಮಟ್ಟಿಗೆ ಅವರೊಂದಿಗೆ ಔಪಚಾರಿಕ ಡೇಟಾ ಸಂಸ್ಕರಣಾ
            ಒಪ್ಪಂದಗಳನ್ನು ಕಾರ್ಯಗತಗೊಳಿಸಲು ಕೈಗೊಳ್ಳುತ್ತೇವೆ.
          </p>
          <p>
            ನಮ್ಮ ಮುಖ್ಯ ಪ್ರೊಸೆಸರ್ Amazon Web Services Ince (AWS) ಆಗಿದೆ. Amazon
            Web Services, Inc. ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್‌ನಲ್ಲಿದೆ ಮತ್ತು ಅವರ ಅಂಗಸಂಸ್ಥೆ
            ಕಂಪನಿಗಳು ಪ್ರಪಂಚದಾದ್ಯಂತ ನೆಲೆಗೊಂಡಿವೆ. AWS ಕೊಡುಗೆಗಳೊಂದಿಗೆ ನಮ್ಮ ಸಂವಹನಗಳ
            ವ್ಯಾಪ್ತಿ ಅನ್ನು ಅವಲಂಬಿಸಿ, ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್
            ಸೇರಿದಂತೆ ಅನೇಕ ದೇಶಗಳಲ್ಲಿ ಸಂಗ್ರಹಿಸಬಹುದು ಅಥವಾ ಪ್ರವೇಶಿಸಬಹುದು. AWS
            ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಇತರ ನ್ಯಾಯವ್ಯಾಪ್ತಿಗಳಿಗೆ ವರ್ಗಾಯಿಸಿದಾಗಲೆಲ್ಲಾ, ಅದರ
            ಗೌಪ್ಯತೆ ಸೂಚನೆಗೆ ({' '}
            <a
              href='https://aws.amazon.com/privacy/'
              target='_blank'
              rel='noreferrer'
            >
              https://aws.amazon.com/privacy/
            </a>{' '}
            ) ಮತ್ತು ಅನ್ವಯವಾಗುವ ಡೇಟಾ ಸಂರಕ್ಷಣಾ ಕಾನೂನುಗಳು ಅನುಮತಿಸುವಂತೆ ಮಾಹಿತಿಯನ್ನು
            ವರ್ಗಾಯಿಸುತ್ತದೆ ಎಂದು ಖಚಿತಪಿಡಿಸುತ್ತದೆ.
          </p>
          <p>
            ಹೆಚ್ಚುವರಿಯಾಗಿ, Covestro ಸರ್ವರ್‌ಗಳನ್ನು ಬಾಡಿಗೆಗೆ ಪಡೆಯುವ ದೇಶಗಳಲ್ಲಿ ಅಥವಾ
            Covestro ಅಥವಾ ಅದರ ಅಂಗಸಂಸ್ಥೆಗಳು, ಅಥವಾ ಏಜೆಂಟ್‌ಗಳು ಸೌಲಭ್ಯಗಳನ್ನು
            ನಿರ್ವಹಿಸುವ ಯಾವುದೇ ಇತರ ದೇಶದಲ್ಲಿ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವ ಮೂಲಕ
            ಸಂಗ್ರಹಿಸಿದ ವೈಯಕ್ತಿಕ ಡೇಟಾವನ್ನು ಸಂಗ್ರಹಿಸಬಹುದು ಮತ್ತು
            ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಬಹುದು ಎಂದು ನೀವು ಒಪ್ಪುತ್ತೀರಿ, ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಬಳಸುವ
            ಮೂಲಕ, ನೀವು ಯಾವುದಕ್ಕೆ ಸಮ್ಮತಿಸುತ್ತೀರಿ ನಿಮ್ಮ ದೇಶದ ಹೊರಗೆ ವೈಯಕ್ತಿಕ ಡೇಟಾ
            ವರ್ಗಾವಣೆ.
          </p>
        </li>
        <li>
          <h3>ಮೂರನೇ-ವ್ಯಕ್ತಿಯ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನಮ್ಮ ನಿಲುವು ಏನು?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನೊಂದಿಗೆ ಸಂಯೋಜಿತವಾಗಿಲ್ಲದ ಮೂರನೇ
              -ವ್ಯಕ್ತಿಗಳ ಲಿಂಕ್‌ಗಳು ಮತ್ತು ಜಾಹೀರಾತುಗಳನ್ನು ಒಳಗೊಂಡಿರಬಹುದು ಮತ್ತು ಇತರ
              ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳು ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್, ಆನ್‌ಲೈನ್ ಸೇವೆಗಳು ಅಥವಾ ಮೊಬೈಲ್
              ಅಪ್ಲಿಕೇಶನ್‌ಗಳಿಗೆ ಲಿಂಕ್ ಮಾಡಬಹುದು.
            </i>
          </p>
          <p>
            ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ನಮ್ಮೊಂದಿಗೆ ಸಂಯೋಜಿತವಾಗಿಲ್ಲದ ಮೂರನೇ-ವ್ಯಕ್ತಿಗಳ ಲಿಂಕ್‌ಗಳು
            ಮತ್ತು ಜಾಹೀರಾತುಗಳನ್ನು ಒಳಗೊಂಡಿರಬಹುದು ಮತ್ತು ಇತರ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗಳು ಮತ್ತು
            ಅಪ್ಲಿಕೇಶನ್, ಆನ್‌ಲೈನ್ ಸೇವೆಗಳು ಅಥವಾ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಳಿಗೆ ಲಿಂಕ್
            ಮಾಡಬಹುದು. ಅಂತಹ ಜಾಹೀರಾತುಗಳಿಗೆ ಪ್ರವೇಶದ ಮೂಲಕ ನೀವು ಯಾವುದೇ ಮೂರನೇ
            ವ್ಯಕ್ತಿಗಳಿಗೆ ಒದಗಿಸುವ ಡೇಟಾದ ಸುರಕ್ಷತೆ ಮತ್ತು ಗೌಪ್ಯತೆ ಅನ್ನು ನಾವು
            ಖಾತರಿಪಡಿಸುವುದಿಲ್ಲ.
          </p>
          <p>
            ಮೂರನೇ ವ್ಯಕ್ತಿಗಳು ಸಂಗ್ರಹಿಸಿದ ಯಾವುದೇ ಡೇಟಾವನ್ನು ಈ ಗೌಪ್ಯತೆ ನೀತಿಯಿಂದ
            ಒಳಗೊಳ್ಳುವುದಿಲ್ಲ.
          </p>
          <p>
            ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಅಥವಾ ಲಿಂಕ್ ಮಾಡಬಹುದಾದ ಇತರ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ಸೇವೆಗಳು
            ಅಥವಾ ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಯಾವುದೇ ಮೂರನೇ ವ್ಯಕ್ತಿಗಳ ವಿಷಯ ಅಥವಾ
            ಗೌಪ್ಯತೆ ಮತ್ತು ಭದ್ರತಾ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ನೀತಿಗಳಿಗೆ ನಾವು
            ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ. ಅಂತಹ ಮೂರನೇ ವ್ಯಕ್ತಿಗಳ ನೀತಿಗಳನ್ನು ನೀವು
            ಪರಿಶೀಲಿಸಬೇಕು ಮತ್ತು ನಿಮ್ಮ ಪ್ರಶ್ನೆಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯಿಸಲು ಅವರನ್ನು ನೇರವಾಗಿ
            ಸಂಪರ್ಕಿಸಬೇಕು.
          </p>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಾವು ಎಷ್ಟು ದಿನ ಇಟ್ಟುಕೊಳ್ಳುತ್ತೇವೆ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಈ ಗೌಪ್ಯತಾ ನೀತಿಯಲ್ಲಿ ವಿವರಿಸಿರುವ ಉದ್ದೇಶಗಳನ್ನು
              ಪೂರೈಸಲು ಕಾನೂನುನಿಂದ ಅಗತ್ಯವಿಲ್ಲದ ಹೊರೆತು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನಾವು
              ಎಲ್ಲಿಯವರೆಗೆ ಇರಿಸುತ್ತೇವೆ.
            </i>
          </p>
          <p>
            ದೀರ್ಘಾವಧಿಯ ಧಾರಣ ಅವಧಿಯು ಕಾನೂನಿನಿಂದ (ತೆರಿಗೆ, ಲೆಕ್ಕಪತ್ರ ನಿರ್ವಹಣೆ ಅಥವಾ
            ಇತರ ಕಾನೂನು ಅವಶ್ಯಕತೆಗಳಂತಹ) ಅನುಮತಿಸದ ಹೊರತು, ಈ ಗೌಪ್ಯತಾ ನೀತಿಯಲ್ಲಿ
            ಸೂಚಿಸಲಾದ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಾವು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಎಲ್ಲಿಯವರೆಗೆ
            ಇರಿಸುತ್ತೇವೆ. ಈ ನೀತಿಯಲ್ಲಿನ ಯಾವುದೇ ಉದ್ದೇಶವು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು
            ನೀವು ನಮ್ಮೊಂದಿಗೆ ಹೊಂದಿರುವ ಅವಧಿಗಿಂತ ಹೆಚ್ಚಿನ ಅವಧಿಯವರೆಗೆ (ಅಥವಾ) ನಿಮ್ಮ
            ಖಾತೆಯನ್ನು ಮುಕ್ತಾಯಗೊಳಿಸಿದ 1 ವರ್ಷದ ನಂತರ, ಯಾವುದೇ ನಂತರದವರೆಗೆ
            ಇರಿಸಿಕೊಳ್ಳುವ ಅಗತ್ಯವಿರುವುದಿಲ್ಲ.
          </p>
          <p>
            ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ನಮಗೆ ನಡೆಯುತ್ತಿರುವ
            ಕಾನೂನುಬದ್ಧ ವ್ಯವಹಾರ ಅಗತ್ಯವಿಲ್ಲದೇ ಇದ್ದಾಗ,ನಾವು ಅದನ್ನು ಅಳಿಸುತ್ತೇವೆ ಅಥವಾ
            ಅನಾಮಧೇಯಗೊಳಿಸುತ್ತೇವೆ ಅಥವಾ ಇದು ಸಾಧ್ಯವಾಗದೇ ಇದ್ದರೆ (ಉದಾಹರಣೆಗೆ, ನಿಮ್ಮ
            ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಬ್ಯಾಕಪ್ ಆರ್ಕೈವ್‌ಗಳಲ್ಲಿ ಸಂಗ್ರಹಿಸಲಾಗಿದೆ), ನಂತರ
            ನಾವು ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಸುರಕ್ಷಿತವಾಗಿ ಸಂಗ್ರಹಿಸುತ್ತೇವೆ ಮತ್ತು
            ಅಳಿಸುವಿಕೆ ಸಾಧ್ಯವಾಗುವವರೆಗೆ ಯಾವುದೇ ಹೆಚ್ಚಿನ ಪ್ರಕ್ರಿಯೆಯಿಂದ ಅದನ್ನು
            ಪ್ರತ್ಯೇಕಿಸುತ್ತದೆ.
          </p>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಹೇಗೆ ಸುರಕ್ಷಿತವಾಗಿ ಇಡಲಾಗುತ್ತದೆ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಸಾಂಸ್ಥಿಕ ಮತ್ತು ತಾಂತ್ರಿಕ ಭದ್ರತಾ ಕ್ರಮಗಳ ವ್ಯವಸ್ಥೆಯ
              ಮುಖಾಂತರ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ರಕ್ಷಿಸುವ ಗುರಿಯನ್ನು ನಾವು
              ಹೊಂದಿದ್ದೇವೆ.
            </i>
          </p>
          <p>
            ನಾವು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುವ ಅಥವಾ ಸ್ವೀಕರಿಸುವ ಯಾವುದೇ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು
            ಸುರಕ್ಷತೆಯನ್ನು ರಕ್ಷಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾದ ಸೂಕ್ತವಾದ ತಾಂತ್ರಿಕ ಮತ್ತು
            ಸಾಂಸ್ಥಿಕ ಭದ್ರತಾ ಕ್ರಮಗಳನ್ನು ನಾವು ಜಾರಿಗೊಳಿಸಿದ್ದೇವೆ. ಯಾವುದೇ
            ಉಲ್ಲಂಘನೆಯನ್ನು ನಿರ್ಧರಿಸಲು ಮತ್ತು ಗುರುತಿಸಲು ಸರಿಯಾದ ಕಾಳಜಿ ಮತ್ತು
            ರಕ್ಷಣೆಯನ್ನು ಕೈಗೊಳ್ಳಲಾಗುತ್ತದೆ, ಇದಕ್ಕಾಗಿ ಸೂಕ್ತವಾದ ಸರಿಪಡಿಸುವ ಕ್ರಮವನ್ನು
            ಪ್ರಾರಂಭಿಸಲಾಗುತ್ತದೆ. ಆದಾಗ್ಯೂ, ಅಗತ್ಯವಿರುವ ರಕ್ಷಣೋಪಾಯಗಳನ್ನು
            ಅಳವಡಿಸಲಾಗದಿದ್ದರೂ, ತಾಂತ್ರಿಕ ಇಂಟರ್ಫೇಸ್‌ನಿಂದಾಗಿ ಸಂಭಾವ್ಯ ಅಪಾಯಗಳು ಇರಬಹುದು
            ಎಂದು ನಾವು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇವೆ.
          </p>
          <p>
            ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ರಕ್ಷಿಸಲು ನಾವು ನಮ್ಮ ಕೈಲಾದಷ್ಟು
            ಪ್ರಯತ್ನಿಸುತ್ತೇವೆ. ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಮತ್ತು ಅದರಿಂದ ವೈಯಕ್ತಿಕ
            ಮಾಹಿತಿಯನ್ನು ರವಾನಿಸುವುದು ನಿಮ್ಮ ಸ್ವಂತಃ ಅಪಾಯದಲ್ಲಿದೆ. ನೀವು ಸುರಕ್ಷಿತ
            ಪರಿಸರದಲ್ಲಿ ಮಾತ್ರ ಸೇವೆಗಳನ್ನು ಪ್ರವೇಶಿಸಬೇಕು.
          </p>
        </li>
        <li>
          <h3>ನಾವು ಅಪ್ರಾಪ್ತ ವಯಸ್ಕರಿಂದ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುತ್ತೇವೆಯೇ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> 16 ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ ವಯಸ್ಸಿನ ಮಕ್ಕಳಿಂದ ನಾವು ಉದ್ದೇಶ
              ಪೂರ್ವಕವಾಗಿ ಡೇಟಾವನ್ನು ಸಂಗ್ರಹಿಸುವುದಿಲ್ಲ ಅಥವಾ ಮಾರುಕಟ್ಟೆ ಮಾಡುವುದಿಲ್ಲ,
            </i>
          </p>
          <p>
            <b>ಸಾಮಾನ್ಯ ವಯಸ್ಸಿನ ಮೀತಿ.</b> ಮಕ್ಕಳ ಗೌಪ್ಯತೆಯನ್ನು ಕಾಪಾಡಲು ನಾವು
            ಬದ್ಧರಾಗಿದ್ದೇವೆ. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮಕ್ಕಳಿಗಾಗಿ ಉದ್ದೇಶಿಸಿಲ್ಲ ಮತ್ತು 16
            ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ ವಯಸ್ಸಿನ ಮಕ್ಕಳ ಕುರಿತು ಉದ್ದೇಶಪೂರ್ವಕವಾಗಿ ನಾವು
            ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುವುದಿಲ್ಲ. 16 ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ ವಯಸ್ಸಿನವರು ಎಂದು
            ಕೊವೆಸ್ಟ್ರೋ ತಿಳಿದಿರುವ ಯಾವುದೇ ವ್ಯಕ್ತಿಯಿಂದ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ವೈಯಕ್ತಿಕ
            ಡೇಟಾವನ್ನು ಸಂಗ್ರಹಿಸುವುದಿಲ್ಲ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು 16 ವರ್ಷದೊಳಗಿನ
            ಯಾರಾದರೂ ಬಳಸುತ್ತಿದ್ದರೆ ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಅಂತಹ ಮಾಹಿತಿಯನ್ನು ಅಳಿಸಲು ಮತ್ತು (ಅಥವಾ) ಅವನ/ಅವಳ
            ಖಾತೆಯನ್ನು ಅಳಿಸಲು ನಾವು ಅಗತ್ಯ ಕ್ರಮಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತೇವೆ.
          </p>
          <p>
            <b>ಯುರೋಪಿಯನ್ ಯೂನಿಯನ್ ನಿವಾಸಿಗಳಿಗೆ ವಯಸ್ಸಿನ ಮಿತಿ.</b> ಕಾನೂನು ಅವಶ್ಯಕತೆಗಳ
            ಕಾರಣದಿಂದಾಗಿ ನೀವು ವೆಬ್‌ಸೈಟ್ ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಬಳಸಲು ಕನಿಷ್ಛ 16
            ವರ್ಷ ವಯಸ್ಸಿನವರಾಗಿರಬೇಕು. ಯಾವುದೇ ಯುರೋಪಿಯನ್ ಯೂನಿಯನ್ ನಿವಾಸಿಗಳಿಗಾಗಿ ಈ
            ವೆಬ್‌ಸೈಟ್ ಉದ್ದೇಶಿತವಾಗಿಲ್ಲ. ನೀವು ಅಥವಾ ನಿಮಗೆ ತಿಳಿದಿರುವ ಯಾವುದೇ
            ಬಳಕೆದಾರರು ಯುರೋಪಿಯನ್ ಯೂನಿಯನ್ ನಿವಾಸಿಗಳಾಗಿದ್ದರೆ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್
            ಅನ್ನು ಬಳಸುತ್ತಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಅಂತಹ ಮಾಹಿತಿಯನ್ನು ಅಳಿಸಲು ಮತ್ತು (ಅಥವಾ) ಅವನ/ಅವಳ
            ಖಾತೆಯನ್ನು ಅಳಿಸಲು ನಾವು ಕ್ರಮಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತೇವೆ.
          </p>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಗೌಪ್ಯತೆಯ ಹಕ್ಕುಗಳು ಯಾವವು?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಯುರೋಪಿಯನ್ ಎಕನಾಮಿಕ್ ಏರಿಯಾದಂತಹ ಕೆಲವು ಪ್ರದೇಶಗಳಲ್ಲಿ,
              ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಗೆ ಹೆಚ್ಚಿನ ಪ್ರವೇಶ ಮತ್ತು ನಿಯಂತ್ರಣವನ್ನು
              ಅನುಮತಿಸುವ ಹಕ್ಕುಗಳನ್ನು ನೀವು ಹೊಂದಿದ್ದೀರಿ. ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನೀವು ನಿಮ್ಮ
              ಖಾತೆಯನ್ನು ಪರಿಶೀಲಿಸಬಹುದು, ಬದಲಾಯಿಸಬಹುದು ಅಥವಾ ಕೊನೆಗೊಳಿಸಬಹುದು.
            </i>
          </p>
          <p>
            ಕೆಲವು ಪ್ರದೇಶಗಳಲ್ಲಿ (ಯುರೋಪಿಯನ್ ಆರ್ಥಿಕ ಪ್ರದೇಶದಂತೆ), ಅನ್ವಯವಾಗುವ ಡೇಟಾ
            ರಕ್ಷಣೆ ಕಾನೂನುಗಳ ಅಡಿಯಲ್ಲಿ ನೀವು ಕೆಲವು ಹಕ್ಕುಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. ಇವುಗಳು
            ಹಕ್ಕನ್ನು ಒಳಗೊಂಡಿರಬಹುದು(i) ಪ್ರವೇಶವನ್ನು ವಿನಂತಿಸಲು ಮತ್ತು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ
            ಮಾಹಿತಿಯ ನಕಲನ್ನು ಪಡೆಯಲು, (ii)ಸರಿಪಡಿಸುವಿಕೆ ಅಥವಾ ಅಳಿಸುವಿಕೆಯನ್ನು
            ವಿನಂತಿಸಲು; (iii)ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯ ಪ್ರಕ್ರಿಯೆಯನ್ನು
            ನಿರ್ಬಂಧಿಸಲು;ಮತ್ತು (iv)ಅನ್ವಯಿಸಿದರೆ, ಡೇಟಾ ಪೋರ್ಟಬಿಲಿಟಿಗೆ. ಕೆಲವು
            ಸಂದರ್ಭಗಳಲ್ಲಿ, ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯ ಪ್ರಕ್ರಿಯೆಗೆ ಆಕ್ಷೇಪಿಸುವ ಹಕ್ಕನ್ನು
            ನೀವು ಸಹ ಹೊಂದಿರಬಹುದು. ಅಂತಹ ವಿನಂತಿಯನ್ನು ಮಾಡಲು, ದಯವಿಟ್ಟು ಕೆಳಗೆ ನೀಡಲಾದ
            ಸಂಪರ್ಕ ವಿವರಗಳನ್ನು ಬಳಸಿ [HYPERLINK]. ಆದಾಗ್ಯೂ, ಅನ್ವಯವಾಗುವ ಡೇಟಾ ರಕ್ಷಣೆ
            ಕಾನೂನುಗಳಿಗೆ ಅನುಸಾರವಾಗಿ ಯಾವುದೇ ವಿನಂತಿಯನ್ನು ಪರಿಗಣಿಸುವ ಮತ್ತು
            ಕಾರ್ಯನಿರ್ವಹಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ.
          </p>
          <p>
            ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ನಾವು ನಿಮ್ಮ ಸಮ್ಮತಿಯನ್ನು
            ಅವಲಂಬಿಸಿದ್ದರೆ, ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಸಮ್ಮತಿಯನ್ನು ಹಿಂಪಡೆಯುವ ಹಕ್ಕನ್ನು
            ನೀವು ಹೊಂದಿರುತ್ತೀರಿ. ಆದಾಗ್ಯೂ, ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ಮೊದಲು ಮಾಹಿತಿಯ
            ಪ್ರಕ್ರಿಯೆಯ ಕಾನೂನುಬದ್ಧತೆಯ ಮೇಲೆ ಇದು ಪರಿಣಾಮ ಬೀರುವುದಿಲ್ಲ ಎಂಬುದನ್ನು
            ದಯವಿಟ್ಟು ಗಮನಿಸಿ.
          </p>
          <p>
            ನೀವು ಯುರೋಪಿಯನ್ ಎಕನಾಮಿಕ್ ಏರಿಯಾದಲ್ಲಿ ವಾಸಿಸುತ್ತಿದ್ದರೆ ಮತ್ತು ನಿಮ್ಮ
            ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಾವು ಕಾನೂನುಬಾಹಿರವಾಗಿ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತಿದ್ದೇವೆ
            ಎಂದು ನೀವು ಭಾವಿಸಿದರೆ, ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು ನಿಮ್ಮನ್ನು ವಿನಂತಿಸಲಾಗಿದೆ.
          </p>
          <ol>
            <li>
              <p>
                <i>
                  <b>ಖಾತೆ ಮಾಹಿತಿ</b>
                </i>
              </p>
              <p>
                ನೀವು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಖಾತೆಯಲ್ಲಿನ ಮಾಹಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಬಹುದು
                ಅಥವಾ ಬದಲಾಯಿಸಬಹುದು ಅಥವಾ ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಈ ಮೂಲಕ ಕೊನೆಗೊಳಿಸಬಹುದು:
              </p>
              <p>
                - ನಿಮ್ಮ ಖಾತೆ ಸೆಟ್ಟಿಂಗ್‌ಗಳಿಗೆ ಲಾಗ್ ಇನ್ ಮಾಡುವುದು ಮತ್ತು ನಿಮ್ಮ
                ಖಾತೆಯನ್ನು ನವೀಕರಿಸುವುದು; ಅಥವಾ
              </p>
              <p>
                - ಕೆಳಗಿನ ಸಂಪರ್ಕ ಮಾಹಿತಿಯನ್ನು ಬಸಿಕೊಂಡು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಲಾಗುತ್ತಿದೆ
              </p>
              <p>
                ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಅಂತ್ಯಗೊಳಿಸಲು ನಿಮ್ಮ ವಿನಂತಿಯ ಮೇರೆಗೆ, ನಾವು ನಿಮ್ಮ
                ಖಾತೆ ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ನಮ್ಮ ಸಕ್ರಿಯ ಡೇಟಾಬೇಸ್‌ಗಳಿಂದ ನೀತಿಗೆ
                ಅನುಗುಣವಾಗಿ ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುತ್ತೇವೆ ಅಥವಾ ಅಳಿಸುತ್ತೇವೆ. ಆದಾಗ್ಯೂ,
                ವಂಚನೆಯನ್ನು ತಡೆಯಲು, ಸಮಸ್ಯೆಗಳನ್ನು ನಿವಾರಿಸಲು, ಯಾವುದೇ ತನಿಖೆಗಳಿಗೆ
                ಸಹಾಯ ಮಾಡಲು, ನಮ್ಮ ಬಳಕೆಯ ನಿಯಮಗಳನ್ನು ಜಾರಿಗೊಳಿಸಲು ಮತ್ತು /ಅಥವಾ ಕಾನೂನು
                ಅವಶ್ಯಕತೆಗಳನ್ನು ಅನುಸರಿಸಲು ಕೆಲವು ಮಾಹಿತಿಯನ್ನು ನಮ್ಮ ಫೈಲ್‌ಗಳಲ್ಲಿ
                ಉಳಿಸಿಕೊಳ್ಳಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                <i>
                  <b>ಆಡಳಿತ ಕಾನೂನು ಮತ್ತು ವಿವಾದ ಪರಿಹಾರ</b>
                </i>
              </p>
              <p>
                ಈ ನೀತಿಯ ಅಡಿಯಲ್ಲಿ ಯಾವುದೇ ವಿವಾದಗಳ ಸಂದರ್ಭದಲ್ಲಿ ಭಾರತದ ಕಾನೂನುಗಳಿಗೆ
                ಒಳಪಟ್ಟಿರುತ್ತದೆ, ಮತ್ತು ಮುಂಬೈ, ಮಹಾರಾಷ್ಟ್ರದ ನ್ಯಾಯಾಲಯಗಳು ಇದಕ್ಕಾಗಿ
                ವಿಶೇಷ ನ್ಯಾಯವ್ಯಾಪ್ತಿ ಅನ್ನು ಹೊಂದಿರುತ್ತವೆ.
              </p>
              <p>
                - ಮೇಲೆ ನಿರ್ದಿಷ್ಟಪಡಿಸಿದಂತೆ ಈ ನೀತಿಯ ವ್ಯಾಖ್ಯಾನ, ಅನುಷ್ಠಾನ ಅಥವಾ
                ಉದ್ದೇಶಿತ ಮುಕ್ತಾಯಕ್ಕೆ ಸಂಬಂಧಪಟ್ಟಂತೆ ಯಾವುದೇ ವಿವಾದ ಅಥವಾ ವ್ಯತ್ಯಾಸ
                ಉದ್ಭವಿಸದೇ ಇದ್ದರೆ, ಅಂತಹ ವಿವಾದವನ್ನು ಸೌಹಾರ್ದ ಸಮಾಲೋಚನೆಗಳ ಮೂಲಕ
                ಪರಿಹರಿಸಲು ಪಕ್ಷಗಳು ಮೊದಲ ನಿದರ್ಶನದಲ್ಲಿ ಪ್ರಯತ್ನಿಸುತ್ತವೆ.
              </p>
              <p>
                - ಚರ್ಚೆಯ ಆರಂಭದ ನಂತರ 30 (ಮೂವತ್ತು) ದಿನಗಳ ಒಳಗೆ ಸೌಹಾರ್ದ ಸಮಾಲೋಚನೆಗಳ
                ಮೂಲಕ ಅಂತಹ ವಿವಾದವನ್ನು ಪರಿಹರಿಸದಿದ್ದರೆ ಅಥವಾ ಪಕ್ಷಗಳು ಲಿಖಿತವಾಗಿ
                ಒಪ್ಪಿಕೊಳ್ಳುವ ದೀರ್ಘಾವಧಿಯವರೆಗೆ, ಬಳಿಕ ಯಾವುದೇ ಪಕ್ಷವು ಈ ಒಒಪ್ಪಂದದ
                ನಿಯಮಗಳಿಗೆ ಅನುಸಾರವಾಗಿ ಮಧ್ಯಸ್ಥಿಕೆಯ ಮೂಲಕ ವಿವಾದವನ್ನು ಪರಿಹರಿಸಬಹುದು.
              </p>
              <p>
                - ಎಲ್ಲಾ ಅಂತಹ ವಿವಾದಗಳನ್ನು ಮಧ್ಯಸ್ಥಿಕೆ ಮತ್ತು ಸಮನ್ವಯ ಕಾಯಿದೆ, 1996ರ
                ಅನುಸಾರವಾಗಿ ಮಧ್ಯಸ್ಥಿಕೆಯಿಂದ ಉಲ್ಲೇಖಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಅಂತಿಮವಾಗಿ
                ಪರಿಹರಿಸಲಾಗುತ್ತದೆ, ಕಾಲಕಾಲಕ್ಕೆ ಮತ್ತು ಇಂಡಿಯನ್ ಕೌನ್ಸಿಲ್ ಆಫ್
                ಆರ್ಬಿಟ್ರೇಶನ್ (“ICA”),ನಿಯಮಗಳ ಅಡಿಯಲ್ಲಿ ತಿದ್ದುಪಡಿ ಅಥವಾ ಮರು-ಸಂಗ್ರಹಣೆ
                ಈ ಷರತ್ತಿಗೆ ಉಲ್ಲೇಖದ ಮೂಲಕ ನಿಯಮಗಳನ್ನು ಸಂಯೋಜಿಸಲಾಗಿದೆ ಎಂದು
                ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ. ನ್ಯಾಯಮಂಡಳಿ ಮೂರು ಮಧ್ಯಸ್ಥಗಾರರನ್ನು
                ಒಳಗೊಂಡಿರುತ್ತದೆ. ಪ್ರತಿ ವಿವಾದಾತ್ಮಕ ಪಕ್ಷವು ಒಬ್ಬ ಮಧ್ಯಸ್ಥಗಾರನನ್ನು
                ನೇಮಿಸುತ್ತದೆ ಮತ್ತು ಹೀಗೆ ನೇಮಕಗೊಂಡ ಇಬ್ಬರು ಮಧ್ಯಸ್ಥಗಾರರು ಒಟ್ಟಾಗಿ
                ಮೂರನೇ ಮಧ್ಯಸ್ಥರನ್ನು ನೇಮಿಸಬೇಕು. ಮಧ್ಯಸ್ಥಿಕೆ ಭಾಷೆ ಮುಂಬೈ ಆಗಿರಬೇಕು.
              </p>
              <p>- ಮಧ್ಯಸ್ಥಿಕೆ ಸ್ಥಳವು ಭಾರತದ ಮುಂಬೈ ಆಗಿರುತ್ತದೆ.</p>
              <p>
                - ಮಧ್ಯಸ್ಥಿಕೆ ತೀರ್ಪು ಅಂತಿಮವಾಗಿರುತ್ತದೆ ಮತ್ತು ಪಕ್ಷಗಳ ಮೇಲೆ
                ಬದ್ಧವಾಗಿರುತ್ತದೆ ಮತ್ತು ಪಕ್ಷಗಳು ಅದಕ್ಕೆ ಬದ್ಧವಾಗಿರಲು ಮತ್ತು ಅದರಂತೆ
                ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತವೆ
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನಾವು ಈ ನೀತಿಗೆ ನವೀಕರಣಗಳನ್ನು ಅಥವಾ ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡುತ್ತೇವೆಯೇ?</h3>
          <p>
            <i>
              <b>ಸಂಕ್ಷಿಪ್ತ:</b> ಹೌದು, ನಾವು ಸಂಬಂಧಿತ ಕಾನೂನುಗಳಿಗೆ ಅನುಗುಣವಾಗಿರಲು
              ಅಗತ್ಯವಾಗಿರುವಂತೆ ಈ ನೀತಿಯನ್ನು ನವೀಕರಿಸುತ್ತೇವೆ.
            </i>
          </p>
          <p>
            ಕಾಲ ಕಾಲಕ್ಕೆ ನಾವು ಈ ಗೌಪ್ಯತಾ ನೀತಿಯನ್ನು ನವೀಕರಿಸಬಹುದು. ನವೀಕರಿಸಿದ
            “ಪರಿಷ್ಕೃತ” ದಿನಾಂಕದ ಮೂಲಕ ನವೀಕರಿಸಿದ ಆವೃತ್ತಿಯನ್ನು ಸೂಚಿಸಲಾಗುತ್ತದೆ ಮತ್ತು
            ನವೀಕರಿಸಿದ ಆವೃತ್ತಿಯು ಪ್ರವೇಶಿಸಿದ ತಕ್ಷಣ ಪರಿಣಾಮಕಾರಿಯಾಗಿರುತ್ತದೆ.
          </p>
          <p>
            ಈ ಗೌಪ್ಯತೆ ಪಾಲಿಸಿಗೆ ನಾವು ವಸ್ತು ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡಿದರೆ, ಪ್ರಮುಖವಾಗಿ
            ಪೋಸ್ಟ್ ಮಾಡುವ ಮೂಲಕ ಅಂತಹ ಬದಲಾವಣೆಗಳ ಸೂಚನೆಯನ್ನು ಅಥವಾ ನಿಮಗೆ ನೇರವಾಗಿ
            ಅಧಿಸೂಚನೆ ಕಳುಹಿಸುವ ಮೂಲಕ ನಾವು ನಿಮಗೆ ಸೂಚಿಸಬಹುದು. ನಾವು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು
            ಹೇಗೆ ರಕ್ಷಿಸುತ್ತಿದ್ದೇವೆ ಎಂಬುದನ್ನು ತಿಳಿಸಲು ಈ ಗೌಪತ್ಯೆ ಪಾಲಿಸಿಯನ್ನು
            ಆಗಾಗ್ಗೆ ಪರಿಶೀಲಿಸುವಂತೆ ನಾವು ನಿಮ್ಮನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತೇವೆ.
          </p>
        </li>
        <li>
          <h3>ಈ ಪಾಲಿಸಿಯ ಬಗ್ಗೆ ನೀವು ನಮ್ಮನ್ನು ಹೇಗೆ ಸಂಪರ್ಕ ಮಾಡಬಹುದು?</h3>
          <p>
            ಈ ಪಾಲಿಸಿಯ ಬಗ್ಗೆ ನೀವು ಪ್ರಶ್ನೆಗಳನ್ನು ಅಥವಾ ಕಾಮೆಂಟ್‌ಗಳನ್ನು ಹೊಂದಿದ್ದರೆ,{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            ನಲ್ಲಿ ನಮಗೆ ಇ-ಮೇಲ್ ಮಾಡಿ
          </p>
          <p>
            ನಮ್ಮ ಅಥವಾ ನಮ್ಮ ಪಾಲಿಸಿಗಳ ಬಗ್ಗೆ ನೀವು ಯಾವುದೇ ಹೆಚ್ಚಿನ ಪ್ರಶ್ನೆಗಳನ್ನು ಅಥವಾ
            ಕಾಮೆಂಟ್‌ಗಳನ್ನು ಹೊಂದಿದ್ದರೆ,{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            ನಲ್ಲಿ ನಮಗೆ ಇ-ಮೇಲ್ ಮಾಡಿ
          </p>
        </li>
      </ol>
    </div>
  );
};
export default PrivacyKN;
