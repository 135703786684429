import './style.scss';
import { IonImg } from '@ionic/react';
import React from 'react';
import RightArrow from '../../assets/images/icons/arrow-small-right.svg';

interface props {
  message?: string;
}
const NewsAndAnnouncement: React.FC<props> = ({ message }) => {
  return (
    <div className='news-flex pt-8 pb-8'>
      <div className='news mr-24 ml-3'>{message}</div>
      <IonImg className='news-right-arrow mr-3' src={RightArrow}></IonImg>
    </div>
  );
};
export default NewsAndAnnouncement;
