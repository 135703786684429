import './style.scss';
import { IonImg, IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
// import PrivateRoute from "../../components/privateroute";
// import AboutUsRect from '../../assets/images/banner-aboutcovestro.jpg';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { getFormattedUrlCMS } from '../../utils/common.helper';
// import AboutCIPL from '../../assets/images/about-cipl.png';
// import AboutFS from '../../assets/images/about-fs.png';
// import AboutFSPlatform from '../../assets/images/about-fs-platform.png';
import Placeholder from '../../assets/images/cd-placeholder.png';

const AboutCovestro: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);

  const [isContentLoaded, setIsContentLoaded] = useState(false);

  const [entry, setEntry]: any = useState(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent('abt-cov-IB');
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  return (
    <IonPage className='page-aboutus'>
      <Layout>
        {loading || !entry ? (
          <div></div>
        ) : (
          <div className='section content-sec pt-0'>
            <div className='page-banner-with-text'>
              {entry.bannerimage && entry.bannerimage.length > 0 && (
                <ContentComp
                  data={{
                    images: entry.bannerimage,
                    unique_key: entry.bannerimage[0].unique_key,
                  }}
                />
              )}

              {entry.title_about_covestro && (
                <div className='container'>
                  <div className='banner-content'>
                    <div className='banner-content__inner'>
                      <ContentComp
                        data={{
                          title: entry.title_about_covestro.short_text.title,
                          titleTag:
                            entry.title_about_covestro.short_text.tag_name,
                          titleCustomClass: 'content-heading',
                          description:
                            entry.title_about_covestro.long_text?.description,
                          descPosition:
                            entry.title_about_covestro.long_text?.position,
                          unique_key: entry.title_about_covestro.unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* {entry.covestro_title && entry.covestro_title.length > 0 && (
              <div className='container mt-30'>
                <div className='row'>
                  {entry.covestro_title.map((item: any) => {
                    const col = entry.covestro_title[0].column
                      ? entry.covestro_title[0].column.trim() == '2'
                        ? '3'
                        : entry.covestro_title[0].column
                      : '1';
                    return (
                      <div
                        className={`col col-12 col-sm-6 col-md-${
                          12 / parseInt(col)
                        } text-center `}
                        key={item.id}
                      >
                        <ContentComp
                          data={{
                            title: item.short_title.title,
                            titleTag: item.short_title.tag_name,
                            titleCustomClass:
                              'large font-size-60 mb-26 sm-mb-10',
                            description: item.long_text.description,
                            descPosition: item.long_text.position,
                            unique_key: item.unique_key,
                            descCustomClass: 'font-normal',
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )} */}

            {entry.about_vision_with_description &&
              entry.about_vision_with_description.length > 0 && (
                <div className='section-about-grid mt-50 md-mt-20 pb-20'>
                  <div className='container container--medium'>
                    <div className='row'>
                      {/* <table className='table table-vision'> */}
                      {entry.about_vision_with_description.map((item: any) => {
                        const col =
                          entry.about_vision_with_description[0].column || '1';

                        return (
                          <div
                            className={`col col-12 col-sm-6 col-md-${
                              12 / parseInt(col)
                            } text-center `}
                            key={item.id}
                          >
                            <ContentComp
                              key={item.id}
                              data={{
                                title: item.short_text.title,
                                titleTag: item.short_text.tag_name,
                                titleCustomClass:
                                  'table__head font-aktiv-grotest-medium nowrap text-left mb-0 pt-0',
                                description: item.long_text.description,
                                descPosition: item.long_text.position,
                                unique_key: item.unique_key,
                                descCustomClass:
                                  'table__content text-left mb-0',
                              }}
                            />
                          </div>
                        );
                      })}
                      {/* </table> */}
                    </div>
                  </div>
                </div>
              )}

            {entry.ib_image && (
              <div className={`container container--medium page-banner`}>
                <IonImg
                  src={
                    getFormattedUrlCMS(
                      `${entry.ib_image[0].hash}${entry.ib_image[0].ext}`
                    ) || Placeholder
                  }
                />
              </div>
            )}
            {entry.title_about_ib && (
              <div className='container container--medium mt-50 md-mt-20'>
                <ContentComp
                  data={{
                    title: entry.title_about_ib.short_text.title,
                    titleTag: entry.title_about_ib.short_text.tag_name,
                    titleCustomClass: 'content-heading',
                    // descCustomClass: 'about-cov-content',
                    description: entry.title_about_ib.long_text?.description,
                    descPosition: entry.title_about_ib.long_text?.position,
                    unique_key: entry.title_about_ib.unique_key,
                  }}
                />
              </div>
            )}

            <div className=' section-about-grid mt-20'>
              <div className='container container--medium'>
                {entry.title_with_solution_img &&
                  entry.title_with_solution_img.length > 0 && (
                    <div className='row'>
                      {entry.title_with_solution_img.map((item: any) => {
                        const col =
                          entry.title_with_solution_img[0].column || '1';
                        return (
                          <div
                            className={`col col-12 col-sm-6 col-md-${
                              12 / parseInt(col)
                            } mt-24 mb-24 md-mt-15 md-mb-15`}
                            key={item.id}
                          >
                            <ContentComp
                              data={{
                                imagePath: getFormattedUrlCMS(
                                  `${item.images[0].hash}${item.images[0].ext}`
                                ),
                                title: item.title.title,
                                titleTag: item.title.tag_name,
                                titleCustomClass: 'mb-5',
                                description: item.description?.description,
                                descPosition: item.description?.position,
                                unique_key: item.unique_key,
                                displayType: item.display_type,
                                titleOnTop: item.title_on_top,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>
            {entry.SDG_goal_image_with_title && (
              <div className='container container--medium mt-50 md-mt-20'>
                <div className='row'>
                  <div className='col col-md-12'>
                    <ContentComp
                      data={{
                        imagePath: getFormattedUrlCMS(
                          `${entry.SDG_goal_image_with_title.images[0].hash}${entry.SDG_goal_image_with_title.images[0].ext}`
                        ),
                        imageClass: 'og-image',
                        title: entry.SDG_goal_image_with_title.title.title,
                        titleTag:
                          entry.SDG_goal_image_with_title.title.tag_name,
                        description:
                          entry.SDG_goal_image_with_title.description
                            ?.description,
                        descPosition:
                          entry.SDG_goal_image_with_title.description?.position,
                        unique_key: entry.SDG_goal_image_with_title.unique_key,
                        displayType:
                          entry.SDG_goal_image_with_title.display_type,
                        titleOnTop:
                          entry.SDG_goal_image_with_title.title_on_top,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Layout>
    </IonPage>
  );
};

export default AboutCovestro;
