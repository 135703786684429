import './style.scss';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { t } from '@lingui/macro';
import { Capacitor } from '@capacitor/core';
import { INIT_PAGE } from '../../config/appconfig';
import { AppContext } from '../../context/app.context';
import { getLanguageText } from '../../utils/common.helper';

const NavButtons: React.FC = () => {
  const history = useHistory();
  const { productList, lang }: any = useContext(AppContext);
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 1025 ? true : false,
  });
  const [solutionList, setSolutionList] = useState(
    productList || [
      {
        id: 1,
        title: 'Solar Conduction Dryers (SCD)',
        shortname: 'SCD',
      },
      {
        id: 2,
        title: 'Solar-powered Greenhouse Dryers (GHD)',
        shortname: 'GHD',
      },
      {
        id: 3,
        title: 'Solar Cold Storage (SCS)',
        shortname: 'SCS',
      },
      {
        id: 4,
        title: 'Solar Greenhouse Cultivation',
        shortname: 'GHC',
      },
    ]
  );

  useEffect(() => {
    setSolutionList(productList);
  }, [productList]);

  const [selectedMenu, setSelectedMenu] = useState('');
  useEffect(() => {
    window.matchMedia('(min-width: 1025px)').addListener(setMQuery);
  });

  // useEffect(() => {
  //   let mediaQuery = window.matchMedia("(min-width: 768px)");
  //   mediaQuery.addListener(setMQuery);

  //   return () => mediaQuery.removeListener(setMQuery);
  // }, []);

  // MediaQueryListEvent { isTrusted: true, media: "(min-width: 768px)", matches: true ...}
  function onNavClick(route: string) {
    history.push(route);
  }
  return (
    <div className=''>
      {mQuery && !mQuery.matches ? (
        <IonMenuButton auto-hide='false' />
      ) : (
        <div className='navbar-container'>
          <div className='menu-btn-wrapper'>
            <IonButton
              onClick={() => {
                onNavClick(INIT_PAGE);
                setSelectedMenu('');
              }}
              className='nav-btn'
            >
              <IonLabel>{t({ id: 'home', message: 'Home' })}</IonLabel>
            </IonButton>
          </div>
          <div className='menu-btn-wrapper'>
            <IonButton
              onClick={() => {
                onNavClick('/aboutcovestro');
                if (selectedMenu == 'about') {
                  setSelectedMenu('');
                } else {
                  setSelectedMenu('about');
                }
              }}
              className='nav-btn'
            >
              <IonLabel>{t({ id: 'about_us', message: 'About Us' })}</IonLabel>
            </IonButton>
            {/* <IonList className='submenu'>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/aboutcovestro');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({ id: 'about_covestro', message: 'About Covestro' })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/aboutib');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'about_inclusive_business_ib',
                      message: 'About Inclusive Business (IB)',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
            </IonList> */}
          </div>

          {Capacitor.getPlatform() == 'web' && (
            <>
              <div className='menu-btn-wrapper'>
                <IonButton
                  onClick={() => {
                    onNavClick('/solutions');
                    if (selectedMenu == 'food_security_solution') {
                      setSelectedMenu('');
                    } else {
                      setSelectedMenu('food_security_solution');
                    }
                  }}
                  className='nav-btn'
                >
                  <IonLabel>
                    {t({
                      id: 'our_solutions',
                      message: 'Our Solutions',
                    })}
                  </IonLabel>
                </IonButton>
                <IonList className='submenu'>
                  {solutionList.map((item: any) => {
                    return (
                      <IonItem
                        key={item.id}
                        className='submenu-list'
                        onClick={() => {
                          onNavClick(`/solution/fs-sol-${item.shortname}`);
                          setSelectedMenu('');
                        }}
                      >
                        <IonButton className='nav-btn'>
                          <IonLabel className='submenu-label'>
                            {getLanguageText(
                              item.title,
                              'title',
                              item.locale || null,
                              lang
                            )}
                          </IonLabel>
                        </IonButton>
                      </IonItem>
                    );
                  })}
                </IonList>
              </div>

              <div className='menu-btn-wrapper'>
                <IonButton
                  onClick={() => {
                    if (selectedMenu == 'market_place') {
                      setSelectedMenu('');
                    } else {
                      setSelectedMenu('market_place');
                    }
                  }}
                  className='nav-btn'
                >
                  <IonLabel>
                    {t({
                      id: 'market_place',
                      message: 'Information Exchange',
                    })}
                  </IonLabel>
                </IonButton>
                <IonList className='submenu'>
                  <IonItem
                    className='submenu-list'
                    onClick={() => {
                      onNavClick('/buy');
                      setSelectedMenu('');
                    }}
                  >
                    <IonButton className='nav-btn'>
                      <IonLabel className='submenu-label'>
                        {t({ id: 'buy', message: 'Demand' })}
                      </IonLabel>
                    </IonButton>
                  </IonItem>
                  <IonItem
                    className='submenu-list'
                    onClick={() => {
                      onNavClick('/sell');
                      setSelectedMenu('');
                    }}
                  >
                    <IonButton className='nav-btn'>
                      <IonLabel className='submenu-label'>
                        {t({ id: 'sell', message: 'Supply' })}
                      </IonLabel>
                    </IonButton>
                  </IonItem>
                </IonList>
              </div>
            </>
          )}

          <div className='menu-btn-wrapper'>
            <IonButton
              onClick={() => {
                onNavClick('/subsidyorloans');
                setSelectedMenu('');
              }}
              className='nav-btn'
            >
              <IonLabel>
                {t({
                  id: 'subsidy_loan',
                  message: 'Subsidy/loan',
                })}
              </IonLabel>
            </IonButton>
            {/* <IonList className='submenu'>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick('/');
                    setSelectedMenu('');
                  }}
                  routerLink={'/aboutus'}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({ id: 'subsidy_schemes', message: 'Subsidy Schemes' })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick('/');
                    setSelectedMenu('');
                  }}
                  routerLink={'/intro'}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'loan_or_financial_assistance',
                      message: 'Loan/Financial Assistance ',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
            </IonList> */}
          </div>

          <div className='menu-btn-wrapper'>
            <IonButton
              onClick={() => {
                onNavClick('/partners');
                if (selectedMenu == 'our_partners') {
                  setSelectedMenu('');
                } else {
                  setSelectedMenu('our_partners');
                }
              }}
              className='nav-btn'
            >
              <IonLabel>
                {t({
                  id: 'our_partners',
                  message: 'Our Partners',
                })}
              </IonLabel>
            </IonButton>
            <IonList className='submenu'>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick(
                      `/partnerscategory/financial_institutions_landing`
                    );
                    setSelectedMenu('');
                  }}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'financial_institution',
                      message: 'Financial Institutions',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick(
                      `/partnerscategory/government_departments_landing`
                    );
                    setSelectedMenu('');
                  }}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'government_departments_and_institutions',
                      message: 'Government departments and institutions',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick(`/partnerscategory/techology_provider_landing`);
                    setSelectedMenu('');
                  }}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'technology_providers',
                      message: 'Technology Providers',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick(`/partnerscategory/market_providers_landing`);
                    setSelectedMenu('');
                  }}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'market_linkage_providers',
                      message: 'Market Linkage Providers',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem className='submenu-list'>
                <IonButton
                  onClick={() => {
                    onNavClick(`/partnerscategory/ngo_landing`);
                    setSelectedMenu('');
                  }}
                  className='nav-btn'
                >
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'development_agencies_or_ngos',
                      message: 'Development Agencies/ NGOs',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
            </IonList>
          </div>

          <div className='menu-btn-wrapper'>
            <IonButton
              onClick={() => {
                onNavClick('/medialanding');
                if (selectedMenu == 'media') {
                  setSelectedMenu('');
                } else {
                  setSelectedMenu('media');
                }
              }}
              className='nav-btn'
            >
              <IonLabel>
                {t({
                  id: 'media',
                  message: 'Media',
                })}
              </IonLabel>
            </IonButton>
            <IonList className='submenu'>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/news');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({ id: 'news', message: 'News' })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/videolist');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({ id: 'videos', message: 'Videos' })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/successstories');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({ id: 'success_stories', message: 'Success Stories' })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/testimonials');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'testimonials_or_reviews',
                      message: 'Testimonials/ Reviews',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
              <IonItem
                className='submenu-list'
                onClick={() => {
                  onNavClick('/accreditations');
                  setSelectedMenu('');
                }}
              >
                <IonButton className='nav-btn'>
                  <IonLabel className='submenu-label'>
                    {t({
                      id: 'accreditations',
                      message: 'Accreditations',
                    })}
                  </IonLabel>
                </IonButton>
              </IonItem>
            </IonList>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavButtons;
