/* eslint-disable react/no-children-prop */
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
interface Props {
  description: string;
  descPosition: string;
  descCustomClass?: string;
  descTag?: string | null;
}
const Description: React.FC<Props> = (props: Props) => {
  const { description, descCustomClass } = props;

  return (
    <ReactMarkdown
      className={descCustomClass}
      children={description}
      remarkPlugins={[remarkGfm, remarkBreaks]}
    />
  );
};

export default Description;
