import './style.scss';
import { IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import Layout from '../../components/layouts';
import { t } from '@lingui/macro';
import { logout } from '../../services/auth.service';
import { isWeb } from '../../utils/common.helper';
import { INIT_PAGE } from '../../config/appconfig';
import { useContext } from 'react';
import { AppContext } from '../../context/app.context';
import { sendActivateAccountRequest } from '../../services/user.service';
import Messages from '../../config/messages';
const AccountInActive: React.FC = () => {
  const {
    setIsLoggedIn,
    setUserDetails,
    setIsInActive,
    toastMessage,
    setLoading,
    loading,
  }: any = useContext(AppContext);
  const history = useHistory();

  const activateAccount = async () => {
    if (loading) return;
    setLoading(true);
    const resp = await sendActivateAccountRequest();
    setLoading(false);

    if (!resp.error) {
      toastMessage(Messages.ACTIVATE_SUCCESS_MSG);
    } else {
      toastMessage(resp.message || Messages.SERVER_ERROR, 'danger');
    }
  };
  return (
    <IonPage>
      <Layout>
        <div className='inactive__sec page-container'>
          <div className='inactive__box'>
            <h4 className='intro-covestro-text font-aktiv-grotest-medium mb-40'>
              {t({
                id: 'account_inactive',
                message:
                  'Dear User, Due to inactivity your account is deactivated, kindly submit the request to admin to reactivate the account following below link',
              })}
            </h4>
            <div>
              <span
                className={`activate-link `}
                onClick={() => {
                  activateAccount();
                }}
              >
                {t({
                  id: 'activate_the_account',
                  message: 'Activate the Account',
                })}
              </span>
              <span
                className={`activate-link ml-10`}
                onClick={async (e) => {
                  e.stopPropagation();
                  await logout();
                  setIsLoggedIn('false');
                  setIsInActive('false');
                  setUserDetails({});
                  history.replace(isWeb() ? INIT_PAGE : '/login');
                  window.location.reload();
                }}
              >
                {t({ id: 'logout', message: 'Logout' })}
              </span>
            </div>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default AccountInActive;
