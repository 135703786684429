import { APP_DOMAIN } from '../../../config/appconfig';

const PrivacyHI: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        COVESTRO खाद्य सुरक्षा प्लेटफ़ॉर्म की गोपनीयता की शर्तें
      </h3>
      <h4 style={{ textAlign: 'center' }}>
        पिछली बार अपडेट करने की तिथि [27 सितंबर 2022]
      </h4>
      <h3>प्रस्तावना</h3>
      <p>
        Covestro (इंडिया) प्राइवेट लिमिटेड में हमारे समुदाय का हिस्सा बनने के
        लिए धन्यवाद, जिसे पहले Bayer MaterialScience प्राइवेट लिमिटेड के नाम से
        जाना जाता था। यह कंपनी अधिनियम, 1956 के तहत निगमित एक प्राइवेट लिमिटेड
        कंपनी है और इसका पंजीकृत कार्यालय यूनिट नंबर SB-801 8वीं मंजिल, एम्पायर
        टॉवर, क्लाउड सिटी कैंपस, ऐरोली, ठाणे - बेलापुर रोड नवी मुंबई ठाणे
        (महाराष्ट्र) 400708 में है (“Covestro”, “कंपनी”, “हम”, “हमें”, या
        “हमारा”, या ‘प्लेटफ़ॉर्म’)। हम आपकी व्यक्तिगत जानकारी और आपकी गोपनीयता
        के अधिकार की रक्षा करने के लिए प्रतिबद्ध हैं। अगर आपके पास हमारी
        गोपनीयता नीति या कार्यप्रणाली के संबंध में प्रश्न या शिकायतें हैं, तो
        कृपया हमसे संपर्क करें।
      </p>
      <p>
        हमारे नियमों और शर्तों के साथ पढ़ी जाने वाली यह गोपनीयता नीति प्लेटफॉर्म
        के आपके उपयोग और पहुंच को नियंत्रित करती है। उपयोग की शर्तों को{' '}
        <a href={`${APP_DOMAIN}legal/conditions-of-use`}>
          {`${APP_DOMAIN}legal/conditions-of-use`}
        </a>{' '}
        पर देखा जा सकता है
      </p>
      <p>
        सभी उपयोगकर्ताओं से अनुरोध किया जाता है कि वे हमारी सेवा का लाभ उठाने से
        पहले गोपनीयता शर्तों को विस्तार से पढ़ने के लिए समय निकालें और अगर इस
        गोपनीयता नीति में कोई ऐसी शर्त है, जिससे आप सहमत नहीं हैं, तो कृपया
        हमारी वेबसाइट और सेवाओं का इस्तेमाल करना बंद कर दें।
      </p>
      <p>
        यह गोपनीयता नीति हमारी वेबसाइट/ऐप [खाद्य सुरक्षा प्लेटफ़ॉर्म] और/या
        संबंधित सेवाओं, बिक्री, मार्केटिंग या इवेंट (हम उन्हें इस गोपनीयता नीति
        में सामूहिक तौर पर <b>&quot;प्लेटफ़ॉर्म&quot;</b> के रूप में संदर्भित
        करते हैं) के माध्यम से एकत्र की गई सभी जानकारी पर लागू होती है।
      </p>
      <p>
        इस गोपनीयता नीति का उद्देश्य हमारी गोपनीयता प्रथाओं की व्याख्या करना है
        और इसमें नीचे दी गई चीज़ें शामिल हैं:
      </p>
      <ol>
        <li>
          <h3>हम कैसी जानकारी एकत्रित करते हैं?</h3>
          <ol>
            <li>
              <b>
                <p>
                  <i>आपकी ओर से हमें दी जाने वाली जानकारी </i>
                </p>
              </b>
              <i>
                <p>
                  <b>संक्षिप्त जानकारी:</b> हम आपकी ओर से हमें दी जाने वाली
                  व्यक्तिगत जानकारी एकत्र करते हैं, जैसे नाम, पता और संपर्क
                  जानकारी, जिसमें बिलिंग राशि शामिल है, लेकिन इन तक ही सीमित
                  नहीं है।
                </p>
              </i>
              <p>
                आपकी ओर से हमें स्वेच्छा से दी जाने वाली व्यक्तिगत जानकारी हम तब
                एकत्रित जब आप प्लेटफ़ॉर्म पर पंजीकरण करते हैं, हमारे या हमारे
                उत्पादों और सेवाओं के बारे में जानकारी हासिल करने में रुचि
                व्यक्त करते हैं, प्लेटफ़ॉर्म पर गतिविधियों में भाग लेते हैं या
                नीचे दी गई जानकारी का उपयोग करके हमसे संपर्क करते हैं।
              </p>
              <p>
                आपकी ओर से हमें दी जाने वाली सभी व्यक्तिगत जानकारी सत्य, पूर्ण
                और सटीक होनी चाहिए और आपको ऐसी व्यक्तिगत जानकारी में किसी भी
                बदलाव के बारे में हमें सूचित करना चाहिए।
              </p>
              <p>
                हम जो व्यक्तिगत जानकारी एकत्र करते हैं, वह हमारे और प्लेटफॉर्म
                के साथ आपकी बातचीत के संदर्भ, आपकी ओर से चुने गए विकल्पों और
                आपकी ओर से इस्तेमाल किए जाने वाले उत्पादों और सेवाओं पर निर्भर
                करती है।
              </p>
              <p>
                हमारी ओर से एकत्र की जाने वाली व्यक्तिगत जानकारी में नीचे दी गई
                जानकारी शामिल हो सकती है:
              </p>
              <p>
                <b>नाम और संपर्क डेटा।</b> हम आपका पहला और अंतिम नाम, ई-मेल
                एड्रेस, डाक का पता, फोन नंबर और इसी तरह के अन्य संपर्क डेटा
                एकत्र करते हैं, जिसे आप सेवाओं का इस्तेमाल के लिए प्रदान करेंगे।
              </p>
              <p>
                <b>क्रेडेंशियल।</b> हम प्रमाणीकरण और अकाउंट एक्सेस के लिए उपयोग
                किए जाने वाले पासवर्ड, पासवर्ड संकेत और इसी तरह की सुरक्षा
                जानकारी एकत्रित <b>नहीं</b> करते हैं।
              </p>
              <p>
                <b>भुगतान डेटा।</b> हम सिर्फ़ वही डेटा एकत्र करते हैं जो आपके
                भुगतान, खरीदारी को संसाधित करने के लिए ज़रूरी है। सभी भुगतान डेटा
                भुगतान गेटवे के ज़रिए स्टोर किए जाते हैं, जिस पर हमारा सीमित
                नियंत्रण होता है और आपको इसकी गोपनीयता नीति की समीक्षा करनी
                चाहिए। साथ ही, अपने प्रश्नों के उत्तर देने के लिए सीधे भुगतान
                प्रोसेसर से संपर्क करना चाहिए।
              </p>
              <p>
                <b>
                  टेस्ट डेटा, टेस्ट एनालिसिस रिपोर्ट, सर्वे, AI प्रीडिक्टिव
                  मॉडल।
                </b>{' '}
                हम प्लेटफ़ॉर्म के उपयोग से हम आपकी सभी डेटा रिपोर्ट, विश्लेषण,
                सर्वेक्षण, उत्पादित फसलें, रुचि के उत्पाद, पसंद किए गए लेख और
                पोस्ट, प्रीडिक्टिव मॉडल और परिणाम और अन्य प्रकाशित जानकारी आदि
                एकत्र करते हैं।
              </p>
              <p>
                <b>सोशल मीडिया लॉगिन डेटा।</b> हम आपको अपने Facebook, Twitter या
                किसी अन्य सोशल मीडिया अकाउंट जैसे सोशल मीडिया अकाउंट की जानकारी
                का इस्तेमाल करके पंजीकरण करने का विकल्प प्रदान करते हैं। अगर आप
                इस तरह से पंजीकरण करना चुनते हैं, तो हम नीचे दी गई जानकारी एकत्र
                करेंगे:
              </p>
              <p>
                अगर आप अपने अकाउंट को ऐसे सोशल नेटवर्क से कनेक्ट करते हैं, तो
                आपको Facebook, Google+ Instagram, Pinterest, Twitter, LinkedIn
                जैसे सोशल नेटवर्किंग प्लेटफ़ॉर्म से उपयोगकर्ता की जानकारी
                प्राप्त होती है, जिसमें आपका नाम, सोशल नेटवर्क के उपयोगकर्ता का
                नाम, स्थान, लिंग, जन्म तिथि, ई-मेल पता, प्रोफ़ाइल चित्र और
                संपर्कों के लिए सार्वजनिक डेटा शामिल है। इस जानकारी में किसी ऐसे
                व्यक्ति की संपर्क जानकारी भी शामिल हो सकती है, जिसे आप
                प्लेटफॉर्म का उपयोग करने और/या इसमें शामिल होने के लिए आमंत्रित
                करते हैं
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>अपने-आप एकत्रित की गई जानकारी</i>
                </p>
              </b>
              <p>
                <i>
                  <b>संक्षिप्त जानकारी:</b> जब आप हमारे प्लेटफ़ॉर्म पर जाते हैं,
                  तो कुछ जानकारी, जैसे IP एड्रेस और/या ब्राउज़र और डिवाइस
                  विशेषताएं, सुरक्षा उद्देश्यों के लिए अपने-आप एकत्र की जाती हैं
                  और इसे प्रतिबंधित नहीं किया जा सकता है।
                </i>
              </p>
              <p>
                जब आप प्लेटफ़ॉर्म पर जाते हैं, उपयोग करते हैं या ब्राउज़ करते
                हैं तो हम स्वचालित रूप से कुछ जानकारी एकत्र करते हैं। यह जानकारी
                आपकी विशिष्ट पहचान (जैसे आपका नाम या संपर्क जानकारी) प्रकट नहीं
                करती है, लेकिन इसमें डिवाइस और उपयोग की जानकारी शामिल हो सकती
                है, जैसे कि आपका IP एड्रेस, ब्राउज़र और डिवाइस की विशेषताएं,
                ऑपरेटिंग सिस्टम, भाषा प्राथमिकताएं, URL, डिवाइस का नाम, देश,
                स्थान का संदर्भ देना, आप हमारे प्लेटफॉर्म का उपयोग कैसे और कब
                करते हैं, इसके बारे में जानकारी और अन्य तकनीकी जानकारी। यह
                जानकारी मुख्य रूप से हमारे प्लेटफ़ॉर्म की सुरक्षा और संचालन को
                बनाए रखने के साथ-साथ हमारे आंतरिक विश्लेषण और रिपोर्टिंग के लिए
                ज़रूरी है।
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>हमारे प्लेटफ़ॉर्म के ज़रिए एकत्र की गई जानकारी</i>
                </p>
              </b>
              <p>
                <i>
                  <b>संक्षिप्त जानकारी:</b> प्लेटफ़ॉर्म का उपयोग करते समय, हम
                  आपके भू-स्थान, मोबाइल डिवाइस, पुश नोटिफिकेशन के बारे में
                  जानकारी एकत्रित कर सकते हैं।
                </i>
              </p>
              <p>
                अगर आप हमारे प्लेटफॉर्म का उपयोग करते हैं, तो हम नीचे दी गई
                जानकारी भी एकत्रित कर सकते हैं:
              </p>
              <p>
                - <i>भू-स्थान की जानकारी। </i> स्थान-आधारित सेवाएं मुहैया कराने
                के लिए, हम या तो लगातार या जब आप हमारे मोबाइल एप्लिकेशन का उपयोग
                करते हैं, तो हम आपके मोबाइल डिवाइस से एक्सेस या अनुमति का अनुरोध
                कर सकते हैं और स्थान की जानकारी ट्रैक कर सकते हैं। अगर आप हमारी
                पहुंच या अनुमतियों को बदलना चाहते हैं, तो आप अपने डिवाइस की
                सेटिंग में जाकर ऐसा कर सकते हैं।
              </p>
              <p>
                - <i>मोबाइल डिवाइस का एक्सेस।</i> हम आपके मोबाइल डिवाइस से कुछ
                सुविधाओं को एक्सेस करने या अनुमति का अनुरोध कर सकते हैं, जिसमें
                आपके मोबाइल डिवाइस का [ब्लूटूथ, कैलेंडर, कैमरा, संपर्क,
                माइक्रोफोन, रिमाइंडर, सेंसर, SMS मैसेज, सोशल मीडिया अकाउंट,
                स्टोरेज] IP एड्रेस शामिल है; टाइम ज़ोन; मोबाइल की सेवा देने वाली
                कंपनी और अन्य सुविधाओं के बारे में जानकारी। अगर आप हमारी पहुंच
                या अनुमतियों को बदलना चाहते हैं, तो आप अपने डिवाइस की सेटिंग में
                जाकर ऐसा कर सकते हैं।
              </p>
              <p>
                - <i>मोबाइल डिवाइस का डेटा।</i> हम अपने-आप डिवाइस की जानकारी
                एकत्रित कर सकते हैं (जैसे आपकी मोबाइल डिवाइस ID, मॉडल और
                निर्माता), हार्डवेयर मॉडल; ऑपरेटिंग सिस्टम और उसके संस्करण के
                बारे में जानकारी; विशिष्ट उपकरण पहचानकर्ता (जैसे IDFA); मोबाइल
                नेटवर्क की जानकारी; डिवाइस स्टोरेज की जानकारी, ऑपरेटिंग सिस्टम,
                वर्शन की जानकारी, IP एड्रेस आदि।
              </p>
              <p>
                - <i>पुश नोटिफ़िकेशन। </i> हम आपको आपके खाते या मोबाइल एप्लिकेशन
                के संबंध में पुश नोटिफ़िकेशन भेजने का अनुरोध कर सकते हैं। अगर आप
                इस प्रकार के संचार पाने से ऑप्ट आउट करना चाहते हैं, तो आप अपनी
                डिवाइस सेटिंग में जाकर ऐसा कर सकते हैं।
              </p>
              <p>
                - <b>ऐप उपयोग डेटा, जिसमें अन्य शामिल हैं:</b> हम उपयोग की
                आवृत्ति के बारे में भी जानकारी एकत्र कर सकते हैं; हमारे ऐप के उन
                फ़ीचर और एरिया जिन पर आप जाते हैं; आपके सामान्य उपयोग पैटर्न;
                विशिष्ट सुविधाओं के साथ जुड़ाव।
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>अन्य स्रोत से एकत्रित जानकारी</i>
                </p>
              </b>
              <p>
                <i>
                  <b>संक्षिप्त जानकारी:</b> हम सार्वजनिक डेटाबेस, मार्केटिंग
                  पार्टनर्स, सोशल मीडिया प्लेटफॉर्म और अन्य बाहरी स्रोतों से
                  सीमित डेटा एकत्रित कर सकते हैं।
                </i>
              </p>
              <p>
                हम आपके बारे में अन्य स्रोतों से जानकारी पा सकते हैं, जैसे कि
                सार्वजनिक डेटाबेस, जाइंट मार्केटिंग पार्टनर, सोशल मीडिया
                प्लेटफॉर्म (इसमें फेसबुक शामिल है, लेकिन इन्हीं तक सीमित नहीं
                है) और अन्य तृतीय पक्ष। इस जानकारी को एकत्रित करने के लिए, हम
                आपके मोबाइल डिवाइस या कंप्यूटर पर कुकीज़ भी भेज सकते हैं या
                ट्रैकिंग से जुड़े अन्य तकनीकों को शामिल कर सकते हैं। हमारी कुकी
                से जुड़ी नीति में और जानकारी देखें।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>हम आपकी जानकारी का उपयोग कैसे करते हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम वैध वाणिज्यिक हितों और व्यापक सामाजिक
              हितों, आपके साथ हमारे अनुबंध की पूर्ति, हमारे कानूनी दायित्वों के
              अनुपालन और/या आपकी सहमति के आधार पर आपकी जानकारी को संसाधित करते
              हैं।
            </i>
          </p>
          <p>
            हम अलग-अलग व्यावसायिक हितों और नीचे वर्णित व्यापक सामाजिक लाभों के
            लिए अपने प्लेटफॉर्म के माध्यम से एकत्रित की गई अनाम जानकारी का
            इस्तेमाल करते हैं। हम आपकी सहमति (&quot;सहमति&quot;) और/या हमारे
            कानूनी दायित्वों (&quot;कानूनी कारण&quot;) का पालन करने के लिए आपके
            साथ एक अनुबंध (&quot;अनुबंध&quot;) में करने या उसे पूरा करने के लिए
            हमारे वैध वाणिज्यिक हितों और व्यापक सामाजिक हितों (&quot;वाणिज्यिक
            और सामाजिक उद्देश्यों&quot;) के आधार पर इन उद्देश्यों के लिए आपकी
            व्यक्तिगत जानकारी को संसाधित करते हैं। हम नीचे हर उद्देश्य के आगे उन
            विशिष्ट प्रसंस्करण आधारों को इंगित करते हैं, जिन पर हम भरोसा करते
            हैं।
          </p>
          <p>
            हम एकत्रित या प्राप्त की जाने वाली जानकारी का उपयोग उन उद्देश्यों के
            लिए करते हैं जिनमें नीचे दी गई चीज़ें शामिल हैं, लेकिन इन्हीं तक
            सीमित नहीं हैं:
          </p>
          <p>
            - <b>अकाउंट बनाने और लॉगिन प्रक्रिया को सुविधाजनक बनाने के लिए </b>
            [आपकी सहमति से]। अगर आप अपने अकाउंट को हमारे साथ किसी तीसरे पक्ष के
            खाते जैसे कि आपके Google या Facebook अकाउंट से लिंक करना चुनते हैं,
            तो हम अकाउंट बनाने और लॉगऑन प्रक्रिया को सुविधाजनक बनाने के लिए उस
            जानकारी का उपयोग करते हैं, जिसे आपने हमें उन तृतीय पक्षों से एकत्र
            करने की अनुमति दी थी। ज़्यादा जानकारी के लिए नीचे &quot;सोशल मीडिया
            लॉगिन डेटा&quot; शीर्षक वाला सेक्शन देखें।
          </p>
          <p>
            - <b>आपको विपणन और प्रचार संचार भेजने के</b> लिए [वाणिज्यिक और
            सामाजिक उद्देश्यों के लिए और/या आपकी सहमति से]। अगर यह आपकी
            मार्केटिंग प्राथमिकताओं के अनुसार है, तो हम और/या हमारे तीसरे पक्ष
            के मार्केटिंग पार्टनर आपकी ओर से हमें भेजी गई व्यक्तिगत जानकारी का
            उपयोग हमारे मार्केटिंग उद्देश्यों के लिए कर सकते हैं। आप किसी भी समय
            हमारे मार्केटिंग ईमेल से ऑप्ट-आउट कर सकते हैं (नीचे &quot;गोपनीयता
            अधिकार&quot; देखें)।
          </p>
          <p>
            - <b>वाणिज्यिक और सामाजिक उद्देश्यों</b> के लिए कानूनी कारणों और/या
            संभवतः संविदात्मक के लिए आपको प्रशासनिक जानकारी भेजने के लिए।
            प्लेटफ़ॉर्म/ऐप के माध्यम से आपको दी जाने वाली सेवाओं में सुधार करते
            समय हम आपको उत्पाद, सेवा और नई सुविधाओं की जानकारी और/या हमारे
            नियमों, शर्तों और नीतियों में बदलाव के बारे में जानकारी भेजने के लिए
            आपकी व्यक्तिगत जानकारी का उपयोग कर सकते हैं;
          </p>
          <p>
            -{' '}
            <b>
              प्लेटफ़ॉर्म के मौजूदा कार्यों का समर्थन करने के लिए प्लेटफ़ॉर्म
            </b>{' '}
            में नई सुविधाओं और सेवाओं को जोड़ने के लिए प्लेटफ़ॉर्म का विश्लेषण,
            संचालन, रखरखाव और सुधार करना;
          </p>
          <p>
            -{' '}
            <b>
              उत्पाद और सेवा के ऑफर और सुझावों को आपके लिए कस्टमाइज़ करने के लिए,
            </b>{' '}
            जिसमें तृतीय पक्ष के उत्पाद और ऑफ़र शामिल हैं, आपको अपने खाते के
            बारे में सूचित, अपने खाते के साथ समस्याओं का निवारण, किसी विवाद को
            हल, शुल्क या देय राशि एकत्र करने के लिए, अपनी पहचान की पुष्टि करने
            के लिए यह सुनिश्चित करने के लिए कि आप प्लेटफॉर्म/एप्लिकेशन का उपयोग
            करने के हकदार हैं;
          </p>
          <p>
            -{' '}
            <b>
              आपकी ओर से अनुरोधित उत्पादों और सेवाओं को मुहैया कराने और वितरित
              करने के लिए,{' '}
            </b>{' '}
            लेन-देन प्रोसेस करें और आपको पुष्टिकरण और रीमाइंडर सहित संबंधित
            जानकारी भेजें;
          </p>
          <p>
            - <b>जहां लागू हो, वहां बिलिंग (बिलिंग), खाता प्रबंधन </b> और
            प्रशासनिक से जुड़े अन्य उद्देश्यों के लिए।
          </p>
          <p>
            - <b>आपकी टिप्पणियों, प्रश्नों और अनुरोधों </b> का जवाब देने और
            ग्राहक सेवा मुहैया कराने के लिए;
          </p>
          <p>
            - <b>आपको तकनीकी नोटिस, अपडेट,</b> सुरक्षा अलर्ट और समर्थन और
            प्रशासनिक मैसेज भेजने के लिए;
          </p>
          <p>
            - <b>हमारे प्लेटफॉर्म के संबंध में रुझानों,</b> उपयोग और गतिविधियों
            की निगरानी और विश्लेषण करने के लिए;
          </p>
          <p>
            - सिर्फ़ जानकारी के संबंध में आप Covestro के{' '}
            <b>विज्ञापन उद्देश्यों</b> के लिए साझा करने के लिए सहमत हुए हैं;
          </p>
          <p>
            - <b>अपनी पहचान सत्यापित करने के लिए;</b>
          </p>
          <p>
            - <b>बिजनेस से जुड़े उद्देश्यों</b> के लिए और/या आपकी सहमति से आपको
            लक्षित विज्ञापन प्रदान करें। हम सामग्री और विज्ञापनों को विकसित करने
            और प्रदर्शित करने के लिए आपकी जानकारी का उपयोग कर सकते हैं, उन्हें
            आपकी रुचियों और/या स्थान के अनुरूप बना सकते हैं, और उनकी प्रभावशीलता
            को मापने के लिए तीसरे पक्षों के साथ काम कर सकते हैं।
          </p>
          <p>
            - <b>हमारे बिजनेस से जुड़े उद्देश्यों के लिए और/या</b> आपकी सहमति से
            पुरस्कार ड्रा और प्रतियोगिताओं का संचालन करें। जब आप प्रतियोगिताओं
            में भाग लेने का चुनाव करते हैं, तो हम आपकी जानकारी का उपयोग पुरस्कार
            ड्रा और प्रतियोगिताओं का संचालन करने के लिए कर सकते हैं।
          </p>
          <p>
            - हमारे बिजनेस से जुड़े उद्देश्यों के लिए और/या आपकी सहमति से{' '}
            <b>प्रतिक्रिया के लिए अनुरोध</b> करें। हम फीडबैक का अनुरोध करने और
            हमारे प्लेटफॉर्म के आपके उपयोग के बारे में आपसे संपर्क करने के लिए
            आपकी जानकारी का इस्तेमाल कर सकते हैं;
          </p>
          <p>
            - बिजनेस से जुड़े उद्देश्यों और/या कानूनी कारणों से हमारे{' '}
            <b>प्लेटफ़ॉर्म की सुरक्षा</b> करना। हम अपने प्लेटफॉर्म को सुरक्षित
            रखने के अपने प्रयासों के हिस्से के रूप में आपकी जानकारी का उपयोग कर
            सकते हैं (उदाहरण के लिए, धोखाधड़ी की निगरानी और रोकथाम के लिए);
          </p>
          <p>
            - <b>बिजनेस से जुड़े उद्देश्यों, कानूनी कारणों</b> और/या संभवतः
            संविदात्मक के लिए हमारे नियमों, शर्तों और नीतियों को लागू करने के
            लिए;
          </p>
          <p>
            - <b>कानूनी अनुरोधों का जवाब देने और कानूनी कारणों</b> से नुकसान को
            रोकने के लिए। अगर हमें एक सम्मन या अन्य कानूनी अनुरोध प्राप्त होता
            है, तो हमें प्रतिक्रिया देने का तरीका तय करने के लिए हमारे पास मौजूद
            डेटा की समीक्षा करने की आवश्यकता हो सकती है।
          </p>
          <p>
            - <b>अन्य वाणिज्यिक और सामाजिक उद्देश्यों के लिए।</b> हम बिज़नेस से
            जुड़े अन्य उद्देश्यों के लिए आपकी जानकारी का उपयोग कर सकते हैं, जैसे
            कि डेटा विश्लेषण, उपयोग प्रवृत्तियों की पहचान करना, हमारे प्रचार
            अभियानों की प्रभावशीलता का निर्धारण करना और हमारे प्लेटफॉर्म और आपके
            अनुभव का मूल्यांकन और सुधार करना; और
          </p>
          <p>
            - <b>संघ भागीदारों के लिए।</b> हमारे संघ के भागीदार और पंजीकृत
            प्लेटफ़ॉर्म उपयोगकर्ता आपकी जानकारी का उपयोग उन उत्पादों और सेवाओं
            के लिए कर सकते हैं, जिनमें आपने प्लेटफ़ॉर्म पर रुचि दिखाई है।
          </p>
        </li>
        <li>
          <h3>क्या आपकी जानकारी किसी के साथ शेयर की जाएगी?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम सिर्फ़ आपकी सहमति से, कानूनों का पालन,
              आपके अधिकारों की रक्षा करने या व्यावसायिक दायित्वों को पूरा करने
              के लिए जानकारी शेयर करते हैं।
            </i>
          </p>
          <p>
            हम सिर्फ़ निम्नलिखित स्थितियों में आपकी जानकारी शेयर करते हैं और उनका
            खुलासा करते हैं:
          </p>
          <p>
            - <b>कानूनों का अनुपालन।</b> हम आपकी जानकारी का खुलासा तब कर सकते
            हैं, जब हमें लागू कानून, सरकारी अनुरोधों, न्यायिक कार्यवाही, अदालती
            आदेश या कानूनी प्रक्रिया, जैसे अदालती आदेश या सम्मन के जवाब में ऐसा
            करने के लिए कानूनी रूप से ज़रूरी हो। (राष्ट्रीय सुरक्षा या कानून
            प्रवर्तन आवश्यकताओं को पूरा करने के लिए सार्वजनिक अधिकारियों के जवाब
            सहित)।
          </p>
          <p>
            - <b>महत्वपूर्ण हित और कानूनी अधिकार।</b> हम आपकी जानकारी का खुलासा
            तब कर सकते हैं, जब हमें लगता है कि हमारी नीतियों के संभावित
            उल्लंघनों, संदिग्ध धोखाधड़ी, किसी भी व्यक्ति की सुरक्षा के लिए
            संभावित खतरों और अवैध गतिविधियों या कानूनी प्रक्रिया में साक्ष्य के
            रूप में जांच करना, रोकना या कार्रवाई करना आवश्यक है। जिसमें हम शामिल
            हैं।
          </p>
          <p>
            - <b>विक्रेता, परामर्शदाता और अन्य तृतीय-पक्ष सेवा प्रदाता.</b> हम
            आपकी जानकारी को तीसरे पक्ष के विक्रेताओं, सेवा देने वाली कंपनी,
            ठेकेदारों या एजेंटों के साथ शेयर कर सकते हैं जो हमारे लिए या हमारी
            ओर से सेवाएं प्रदान करते हैं और जिन्हें उस काम को करने के लिए ऐसी
            जानकारी का इस्तेमाल करने की आवश्यकता होती है। उदाहरणों में नीचे दी
            गई चीज़ें शामिल हैं: भुगतान प्रसंस्करण, डेटा विश्लेषण, ई-मेल डिलीवरी,
            होस्टिंग से जुड़ी सेवाएं, ग्राहक सेवा और विपणन प्रयास। हम चयनित तृतीय
            पक्षों को प्लेटफ़ॉर्म पर ट्रैकिंग से जुड़ी तकनीक का उपयोग करने की
            अनुमति दे सकते हैं, जिससे उन्हें इस बारे में डेटा एकत्र कर सकेंगे कि
            आप समय के साथ प्लेटफ़ॉर्म के साथ कैसे इंटरैक्ट करते हैं। अन्य बातों
            के अलावा, इस जानकारी का उपयोग डेटा का विश्लेषण और ट्रैक करने, कुछ
            सामग्री की लोकप्रियता निर्धारित करने और ऑनलाइन गतिविधि को बेहतर ढंग
            से समझने के लिए किया जा सकता है। जब इस नीति में बताया नहीं जाता, तब
            तक हम आपकी किसी भी जानकारी को तीसरे पक्ष के साथ उनके प्रचार
            उद्देश्यों के लिए शेयर, बिक्री या व्यापार नहीं करते हैं या किराए पर
            नहीं देते।
          </p>
          <p>
            - <b>बिज़नेस ट्रांसफर।</b> हम किसी विलय, कंपनी की संपत्तियों की
            बिक्री, वित्तपोषण, या किसी अन्य कंपनी की ओर से हमारे बिज़नेस के सभी
            या हिस्से के अधिग्रहण के संबंध में या बातचीत के दौरान आपकी जानकारी
            को शेयर या ट्रांसफर कर सकते हैं।
          </p>
          <p>
            - <b>तीसरे पक्ष के विज्ञापनदाता।</b> जब आप प्लेटफ़ॉर्म पर जाते हैं,
            तो हम विज्ञापनों को दिखाने के लिए तृतीय पक्ष के विज्ञापन कंपनियों का
            उपयोग कर सकते हैं। ये कंपनियां हमारे प्लेटफ़ॉर्म (प्लेटफ़ॉर्मों) और
            अन्य प्लेटफ़ॉर्म पर आपकी विज़िट के बारे में जानकारी का उपयोग कर सकती
            हैं जो वेब कुकीज़ और अन्य ट्रैकिंग तकनीकों में निहित हैं, जो आपको
            रुचि की वस्तुओं और सेवाओं के बारे में विज्ञापन प्रदान करती हैं।
          </p>
          <p>
            - <b>सहयोगी।</b> हम आपकी जानकारी को अपने सहयोगियों के साथ शेयर कर
            सकते हैं, इस स्थिति में हमें इस गोपनीयता नीति का पालन करने की ज़रूरत
            होगी। एफ़िलिएट में हमारी मूल कंपनी और कोई भी सहायक, संयुक्त उद्यम
            भागीदार या हमारी ओर से नियंत्रित या हमारी साझा नियंत्रण वाली अन्य
            कंपनियां शामिल हैं।
          </p>
          <p>
            - <b>बिज़नेस पार्टनर।</b> आपको कुछ उत्पादों, सेवाओं या प्रचारों की
            पेशकश करने के लिए हम आपकी जानकारी को अपने व्यापार भागीदारों के साथ
            शेयर कर सकते हैं।
          </p>
          <p>
            - <b>आपकी सहमति से।</b> हम आपकी सहमति से किसी अन्य मकसद के लिए आपकी
            व्यक्तिगत जानकारी का खुलासा कर सकते हैं।
          </p>
          <p>
            - <b>अन्य उपयोगकर्ता।</b> जब आप व्यक्तिगत जानकारी साझा करते हैं
            (उदाहरण के लिए, प्लेटफ़ॉर्म पर टिप्पणी, योगदान या अन्य सामग्री पोस्ट
            करके) या प्लेटफ़ॉर्म के सार्वजनिक क्षेत्रों के साथ इंटरैक्ट करते
            हैं, तो ऐसी व्यक्तिगत जानकारी सभी उपयोगकर्ताओं द्वारा देखी जा सकती
            है और प्लेटफ़ॉर्म के बाहर स्थायी रूप से सार्वजनिक रूप से वितरित की
            जा सकती है। [अगर आप हमारे प्लेटफॉर्म के अन्य उपयोगकर्ताओं के साथ
            बातचीत करते हैं और सोशल नेटवर्क (जैसे Facebook) के माध्यम से रजिस्टर
            करते हैं, तो तो आपके सामाजिक नेटवर्क के उपयोगकर्ता आपका नाम,
            प्रोफ़ाइल फ़ोटो और आपके काम का विवरण देखेंगे।] इसी तरह, अन्य
            उपयोगकर्ता आपकी गतिविधि की जानकारी देख सकेंगे, हमारे प्लेटफॉर्म पर
            आपसे बातचीत कर सकेंगे। साथ ही, आपकी प्रोफ़ाइल देख सकेंगे।
          </p>
        </li>
        <li>
          <h3>क्या हम कुकीज़ और अन्य ट्रैकिंग तकनीकों का इस्तेमाल करते हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम आपकी जानकारी एकत्र करने और स्टोर करने
              के लिए कुकीज़ और ट्रैकिंग से जुड़ी अन्य तकनीकों का उपयोग कर सकते
              हैं।
            </i>
          </p>
          <p>
            किसी भी आज्ञाकारी संगठन की तरह, हम आपके अनुभव और हमारे उत्पादों और
            सेवाओं को बेहतर बनाने में हमारी मदद करने के लिए कुकीज़ और इसी तरह की
            तकनीकों के माध्यम से जानकारी एकत्र करते हैं। हम जिन कुकीज़ का
            इस्तेमाल करते हैं, वे हमारे प्लेटफ़ॉर्म को कार्य करने में सक्षम
            बनाती हैं और हमें यह समझने में मदद करती हैं कि कौन सी जानकारी और
            विज्ञापन विजिटर के लिए सबसे उपयोगी हैं।
          </p>
          <p>
            ज़्यादातर वेब ब्राउज़र डिफ़ॉल्ट तौर पर कुकीज़ स्वीकार करने के लिए सेट
            होते हैं। अगर आप चाहें, तो आप आमतौर पर अपने ब्राउज़र से कुकीज़ हटाने
            और कुकीज़ को अस्वीकार करने का विकल्प चुन सकते हैं। अगर आप कुकीज़ को
            हटाना या कुकीज़ को अस्वीकार करना चुनते हैं, तो यह हमारे प्लेटफ़ॉर्म
            की कुछ फ़ीचर या सेवाओं पर असर डाल सकता है।
          </p>
          <p>UserCentrics तकनीक (सहमति टूल)</p>
          <p>
            कुकीज़ के लिए प्रभावी उपयोगकर्ता सहमति पाने के लिए सहमति की ज़रूरत
            होती है और कुकी-आधारित एप्लिकेशन के लिए, हमारी वेबसाइट Usercentrics
            GmbH, Sendlinger Str 7, 80331 म्यूनिख द्वारा डेवलप तकनीक के साथ कुकी
            सहमति टूल का इस्तेमाल करती है। (बाद में &quot;यूजरसेंट्रिक्स&quot;
            के रूप में संदर्भित)।
          </p>
          <p>
            संबंधित JavaScript कोड को इंटीग्रेट करके, उपयोगकर्ताओं को पेज पर
            जाने पर एक बैनर दिखाया जाता है, जिसमें कुछ कुकीज़ और/या कुकी-आधारित
            एप्लिकेशन के लिए बॉक्स चेक करके सहमति दी जा सकती है। जब तक कि
            संबंधित उपयोगकर्ता संबंधित बॉक्स को चेक करके सहमति नहीं देता, तब तक
            टूल उन सभी कुकीज की सेटिंग को ब्लॉक कर देता है, जिनके लिए सहमति की
            ज़रूरत होती है। यह पक्का करता है कि सहमति दिए जाने पर ही ऐसी कुकीज़
            उपयोगकर्ता के संबंधित एंड डिवाइस पर सेट की जाती हैं।
          </p>
          <p>
            वेबसाइट के नीचे दाईं ओर मौजूद UserCentrics बटन को दबाकर किसी भी समय
            उपयोगकर्ता सेटिंग को बदला जा सकता है।
          </p>
          <p>
            जब आप हमारी वेबसाइट पर जाते हैं, तो हमारे कुकी सहमति टूल से कुछ
            उपयोगकर्ता की जानकारी (आपके IP एड्रेस सहित) एकत्र की जाती है, जिसे
            Usercentrics के सर्वर पर ट्रांसमिट किया जाता है और वहां स्टोर किया
            जाता है, ताकि कुकी सहमति टूल अलग-अलग उपयोगकर्ताओं को स्पष्ट रूप से
            पेज व्यू असाइनट कर सके और व्यक्तिगत रूप से रिकॉर्ड, लॉग कर सके और
            उपयोगकर्ता की ओर से की गई सहमति सेटिंग को उस सत्र की अवधि के लिए
            सहेज सकें।
          </p>
          <p>
            आप Usercentrics की ओर से डेटा के उपयोग के बारे में अतिरिक्त जानकारी
            Usercentrics गोपनीयता नीति में पा सकते हैं:{' '}
            <a
              href='https://usercentrics.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              https://usercentrics.com/privacy-policy/
            </a>
          </p>
          <p>
            अगर आपके कोई सवाल पूछना चाहते हैं, तो कृपया हमें एक ईमेल भेजकर या
            नीचे &quot;हमसे संपर्क करें&quot; अनुभाग के माध्यम से एक अनुरोध
            सबमिट करके बताएं।
          </p>
        </li>
        <li>
          <h3>क्या हम स्थान से जुड़ी सेवाओं का इस्तेमाल करते हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हां, हम आपको बेहतर सेवा मुहैया कराने के
              लिए, इन-बिल्ट प्रोग्राम या Google Maps जैसे बाहरी प्रदाताओं के
              स्थान से जुड़ी सेवाओं का उपयोग करते हैं।
            </i>
          </p>
          <p>
            एकत्र की गई जानकारी का इस्तेमाल सिर्फ़ इस नीति के उद्देश्यों के लिए
            किया जाएगा। साथ ही, ऐसी सेवा देने वाली कंपनी के उपयोग से जुड़े नियम
            और शर्तें हमारे दायरे और नियंत्रण से बाहर हैं।
          </p>
        </li>
        <li>
          <h3>क्या आपकी जानकारी अंतरराष्ट्रीय स्तर पर ट्रांसफर की जाती है?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम आपकी जानकारी को आपके देशों के अलावा
              अन्य देशों में ट्रांसफर, स्टोर और प्रोसेस कर सकते हैं।
            </i>
          </p>
          <p>
            हम ऐसे प्रोसेसर को शामिल करते हैं, जो हमारे लिए आपके व्यक्तिगत डेटा
            के साथ विशेष संचालन करते हैं। प्रोसेसर ऐसी कंपनियां हैं जिसकी मदद से
            हम प्लेटफ़ॉर्म चला सकते हैं, आपके साथ संचार कर सकते हैं या
            प्लेटफ़ॉर्म से संबंधित अन्य गतिविधियां कर सकते हैं। वे हमारी ओर से
            कुछ व्यक्तिगत डेटा को संसाधित कर सकते हैं, ताकि वेबसाइट और ऐप फंक्शन
            और संबंधित गतिविधियों से जुड़े लक्ष्यों को पूरा कर सकें। प्रोसेसर
            सिर्फ़ हमारे निर्देशों के मुताबिक कार्य करते हैं और सिर्फ़ उतनी ही
            मात्रा में व्यक्तिगत डेटा को संसाधित करते हैं, जितना हम उन्हें
            संसाधित करने का निर्देश देते हैं। हम अपने प्रोसेसर के किसी भी कार्य
            या चूक के लिए पूरी तरह से जिम्मेदार हैं. साथ ही, लागू कानून द्वारा
            आवश्यक सीमा तक उनके साथ औपचारिक डेटा प्रोसेसिंग समझौते लागू करने का
            वचन देते हैं।
          </p>
          <p>
            हमारा मुख्य प्रोसेसर Amazon Web Services Ince (AWS) है। Amazon Web
            Services, Inc. संयुक्त राज्य अमेरिका में स्थित है और उनकी संबद्ध
            कंपनियां दुनिया भर में स्थित हैं। AWS की पेशकशों के साथ हमारी बातचीत
            के आधार पर, व्यक्तिगत जानकारी को संयुक्त राज्य सहित कई देशों में
            स्टोर या एक्सेस किया जा सकता है। जब भी AWS व्यक्तिगत जानकारी को अन्य
            न्यायालयों में स्थानांतरित करता है, तो यह पक्का करता है कि जानकारी
            को उसके गोपनीयता नोटिस{' '}
            <a
              href='https://aws.amazon.com/privacy/'
              target='_blank'
              rel='noreferrer'
            >
              (https://aws.amazon.com/privacy/)
            </a>{' '}
            के अनुसार और लागू डेटा सुरक्षा कानूनों द्वारा अनुमति के अनुसार
            स्थानांतरित किया जाता है।
          </p>
          <p>
            इसके अलावा, आप इस बात से सहमत हैं कि एकत्रित व्यक्तिगत डेटा को उन
            देशों में संग्रहीत और संसाधित किया जा सकता है, जहां Covestro किराए
            पर सर्वर देता है या किसी अन्य देश में जहां Covestro या उसके सहयोगी,
            सहायक या एजेंट सुविधाएं बनाए रखते हैं और प्लेटफॉर्म का उपयोग करके,
            आप अपने देश के बाहर व्यक्तिगत डेटा के ऐसे किसी भी हस्तांतरण के लिए
            सहमति देते हैं।
          </p>
        </li>
        <li>
          <h3>तृतीय पक्ष प्लेटफ़ॉर्म पर हमारी स्थिति क्या है?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम विज्ञापन देने वाले तीसरे पक्ष के
              प्रदाताओं के साथ आपकी ओर से साझा की जाने वाली ऐसी जानकारी की
              सुरक्षा के लिए ज़िम्मेदार नहीं हैं, जो हमारे प्लेटफ़ॉर्म से
              संबंधित नहीं है।
            </i>
          </p>
          <p>
            प्लेटफ़ॉर्म में तीसरे पक्ष के ऐसे लिंक और विज्ञापन हो सकते हैं जो
            हमसे संबंधित नहीं हैं। यह अन्य प्लेटफ़ॉर्म या ऐप, ऑनलाइन सेवाओं या
            मोबाइल एप्लिकेशन से लिंक हो सकते हैं। हम ऐसे विज्ञापनों तक पहुंच के
            माध्यम से तीसरे पक्ष को आपकी ओर से दिए जाने वाले डेटा की सुरक्षा और
            गोपनीयता की गारंटी नहीं दे सकते।
          </p>
          <p>
            तृतीय पक्ष की ओर से एकत्रित डेटा इस गोपनीयता नीति के अंतर्गत नहीं
            आता है।
          </p>
          <p>
            हम सामग्री या गोपनीयता और सुरक्षा प्रथाओं और तीसरे पक्ष की नीतियों
            के लिए ज़िम्मेदार नहीं होंगे, जिसमें अन्य प्लेटफ़ॉर्म, सेवाएं या
            एप्लिकेशन शामिल हैं जो प्लेटफ़ॉर्म से या उससे जुड़े हो सकते हैं।
            आपको ऐसे तृतीय पक्षों की नीतियों की समीक्षा करनी चाहिए और अपने
            प्रश्नों का उत्तर देने के लिए उनसे सीधे संपर्क करना चाहिए।
          </p>
        </li>
        <li>
          <h3>हम आपकी जानकारी कब तक रखते हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम इस गोपनीयता नीति में निर्दिष्ट
              समयावधि के लिए आपकी व्यक्तिगत जानकारी को बनाए रखते हैं, जब तक
              अन्यथा कानून द्वारा आवश्यक न हो।
            </i>
          </p>
          <p>
            हम आपकी व्यक्तिगत जानकारी को सिर्फ़ तब तक बनाए रखेंगे, जब तक कि इस
            गोपनीयता नीति में वर्णित उद्देश्यों के लिए आवश्यक हो, लंबी अवधारण
            अवधि की आवश्यकता न हो या कानून द्वारा अनुमति दी गई हो (जैसे कर, लेखा
            या अन्य कानूनी आवश्यकताएं)। इस नीति के मुताबिक किसी भी उद्देश्य के
            लिए, हमें आपकी व्यक्तिगत जानकारी को हमारे पास आपके खाते की अवधि (या)
            आपके खाते के रद्द होने के 1 वर्ष बाद, जो भी बाद में हो, से अधिक समय
            तक बनाए रखने की ज़रूरत नहीं होगी।
          </p>
          <p>
            जब हमारे पास आपकी व्यक्तिगत जानकारी को संसाधित करने के लिए वैध
            व्यवसाय की ज़रूरत नहीं है, तो हम इसे या तो हटा देंगे या अनाम कर देंगे
            या यदि यह संभव नहीं है (उदाहरण के लिए, क्योंकि आपकी व्यक्तिगत
            जानकारी बैकअप संग्रह में स्टोर की गई है), तो हम सुरक्षित रूप से आपकी
            व्यक्तिगत जानकारी स्टोर करेंगे। साथ ही, हटाना संभव होने तक आगे की
            प्रक्रिया से अलग करेंगे।
          </p>
        </li>
        <li>
          <h3>हम आपकी जानकारी कब तक सुरक्षित रखते हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम संगठनात्मक और तकनीकी सुरक्षा उपायों
              की एक प्रणाली के माध्यम से आपकी व्यक्तिगत जानकारी की सुरक्षा के
              लिए प्रतिबद्ध हैं।
            </i>
          </p>
          <p>
            हमने संसाधित या प्राप्त होने वाली किसी भी व्यक्तिगत जानकारी की
            सुरक्षा के लिए उपयुक्त तकनीकी और संगठनात्मक सुरक्षा उपायों को लागू
            किया है। किसी भी उल्लंघन का पता लगाने और उसकी पहचान करने के लिए उचित
            देखभाल और सुरक्षा उपाय किए जाते हैं जिसके लिए उचित सुधारात्मक
            कार्रवाई शुरू की जाएगी। हालांकि, हम आपको सूचित करते हैं कि भले ही
            आवश्यक सुरक्षा उपायों को लागू किया गया हो, तकनीकी इंटरफ़ेस के कारण
            संभावित जोखिम हो सकते हैं।
          </p>
          <p>
            यद्यपि हम आपकी व्यक्तिगत जानकारी की सुरक्षा करने के लिए अपनी पूरी
            कोशिश करेंगे, लेकिन हमारे प्लेटफ़ॉर्म से आपकी व्यक्तिगत जानकारी का
            प्रसारण आपके अपने जोखिम पर है। आपको सिर्फ़ सुरक्षित वातावरण में ही इन
            सेवाओं का इस्तेमाल करना चाहिए।
          </p>
        </li>
        <li>
          <h3>क्या हम नाबालिगों से जानकारी एकत्र करते हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हम जानबूझकर 16 साल से कम उम्र के बच्चों
              से डेटा एकत्र नहीं करते हैं या उन्हें डेटा नहीं बेचते हैं।
            </i>
          </p>
          <p>
            <b>सामान्य आयु सीमा।</b> हम बच्चों की गोपनीयता की सुरक्षा करने के
            लिए प्रतिबद्ध हैं। यह प्लेटफॉर्म बच्चों के लिए नहीं है और हम 16 साल
            से कम उम्र के बच्चों से जानबूझकर जानकारी एकत्र नहीं करते हैं।
            प्लेटफ़ॉर्म किसी ऐसे व्यक्ति से व्यक्तिगत जानकारी एकत्र नहीं करता है
            जिसके बारे में Covestro जानता है कि उनकी आयु 16 वर्ष से कम है। अगर
            आप प्लेटफ़ॉर्म का इस्तेमाल करने वाले 16 वर्ष से कम उम्र के किसी
            व्यक्ति के बारे में जानते हैं, तो कृपया हमसे{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            पर संपर्क करें और हम ऐसी जानकारी को हटाने और (या) उसके खाते को हटाने
            के लिए ज़रूरी कदम उठाएंगे।
          </p>
          <p>
            <b>यूरोपीय संघ के निवासियों के लिए आयु सीमा।</b> कानूनी शर्तों के
            कारण, वेबसाइट और ऐप का इस्तेमाल करने के लिए आपकी आयु कम से कम 16
            वर्ष होनी चाहिए। यह वेबसाइट किसी भी यूरोपीय संघ के निवासियों के लिए
            नहीं है। अगर आप या आपके परिचित उपयोगकर्ता यूरोपीय संघ के निवासी हैं
            और इस प्लेटफ़ॉर्म का इस्तेमाल कर रहे हैं, तो कृपया हमसे{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            पर संपर्क करें और हम ऐसी जानकारी को हटाने और (या) उसके खाते को हटाने
            के लिए ज़रूरी कदम उठाएंगे।
          </p>
        </li>
        <li>
          <h3>आपके गोपनीयता अधिकार क्या हैं?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> कुछ क्षेत्रों में (जैसे कि यूरोपीय
              आर्थिक क्षेत्र), आपकी व्यक्तिगत जानकारी पर आपकी अधिक पहुंच और
              नियंत्रण हो सकता है। आप किसी भी समय अपने खाते की समीक्षा, संशोधन
              या रद्द कर सकते हैं।
            </i>
          </p>
          <p>
            कुछ क्षेत्रों में (जैसे कि यूरोपीय आर्थिक क्षेत्र), आपके पास लागू
            डेटा सुरक्षा कानूनों के तहत कुछ अधिकार हैं। इनमें यह अधिकार शामिल हो
            सकते हैं (i) एक्सेस का अनुरोध करना और आपकी व्यक्तिगत जानकारी की एक
            प्रति प्राप्त करना, (ii) सुधार या मिटाने का अनुरोध करना; (iii) आपकी
            व्यक्तिगत जानकारी के प्रसंस्करण को प्रतिबंधित करना; और (iv) डेटा
            पोर्टेबिलिटी जहां लागू हो। कुछ परिस्थितियों में, आपको अपनी व्यक्तिगत
            जानकारी के प्रसंस्करण पर आपत्ति जताने का अधिकार भी हो सकता है। ऐसा
            अनुरोध करने के लिए, कृपया नीचे दी गई संपर्क जानकारी [हाइपरलिंक] का
            इस्तेमाल करें। हालांकि, हम लागू डेटा सुरक्षा कानूनों के मुताबिक किसी
            भी अनुरोध पर विचार करने और कार्रवाई करने का अधिकार सुरक्षित रखते
            हैं।
          </p>
          <p>
            अगर हम आपकी व्यक्तिगत जानकारी को संसाधित करने के लिए आपकी सहमति पर
            भरोसा करते हैं, तो आपको किसी भी समय अपनी सहमति वापस लेने का अधिकार
            है। हालांकि, कृपया ध्यान दें कि यह जानकारी के प्रसंस्करण की वैधता को
            तब तक प्रभावित नहीं करेगा जब तक कि इसे हटा नहीं दिया जाता।
          </p>
          <p>
            अगर आप यूरोपीय आर्थिक क्षेत्र में रहते हैं और मानते हैं कि हम आपके
            व्यक्तिगत डेटा को अवैध रूप से संसाधित कर रहे हैं, तो आपको हमसे
            संपर्क करने के लिए कहा जाता है।
          </p>
          <ol>
            <li>
              <p>
                <i>
                  <b>अकाउंट की जानकारी</b>
                </i>
              </p>
              <p>
                आप किसी भी समय अपने खाते की जानकारी की समीक्षा कर सकते हैं या
                उसमें संशोधन कर सकते हैं या ऐसा करके अपना खाता हटा सकते हैं:
              </p>
              <p>
                - अपनी खाता सेटिंग में लॉग इन करना और अपना खाता अपडेट करना; या
              </p>
              <p>- नीचे दी गई संपर्क जानकारी का उपयोग करके हमसे संपर्क करना</p>
              <p>
                आपके खाते को हटाने के आपके अनुरोध पर, हम नीति के मुताबिक अपने
                सक्रिय डेटाबेस से आपके खाते और जानकारी को निष्क्रिय कर देंगे या
                हटा देंगे। हालांकि, धोखाधड़ी को रोकने, समस्याओं का निवारण करने,
                किसी भी जांच में सहायता करने, हमारी उपयोग की शर्तों को लागू करने
                और/या कानूनी शर्तों का अनुपालन करने के लिए कुछ जानकारी हमारी
                फाइलों में रखी जा सकती है।
              </p>
            </li>
            <li>
              <p>
                <i>
                  <b>शासकीय कानून और विवाद समाधान</b>
                </i>
              </p>
              <p>
                इस नीति के तहत किसी भी विवाद के मामले में, भारत के कानून लागू
                होंगे और मुंबई, महाराष्ट्र की अदालतों का उस पर विशेष अधिकार
                क्षेत्र होगा।
              </p>
              <p>
                - ऊपर बताए अनुसार इस नीति की व्याख्या, प्रवर्तन, या इच्छित
                समाप्ति से संबंधित किसी भी विवाद या असहमति की स्थिति में,
                पार्टियां पहले मैत्रीपूर्ण परामर्श के माध्यम से ऐसे किसी भी
                विवाद को हल करने का प्रयास करेंगी।
              </p>
              <p>
                - अगर इस तरह के विवाद को चर्चा शुरू होने के 30 (तीस) दिनों के
                भीतर या पार्टियों की ओर से लिखित रूप में सहमत होने वाली लंबी
                अवधि के भीतर सौहार्दपूर्ण बातचीत के माध्यम से हल नहीं किया जाता
                है, तो कोई भी पक्ष इस समझौते की शर्तों के मुताबिक, विवाद को
                मध्यस्थता के लिए भेज सकता है।
              </p>
              <p>
                - ऐसे सभी विवादों को मध्यस्थता और सुलह अधिनियम, 1996 के अनुसार
                समय-समय पर संशोधित या संशोधित और भारतीय मध्यस्थता परिषद (“ICA”)
                के नियमों के तहत मध्यस्थता द्वारा संदर्भित किया जाएगा और अंत में
                हल किया जाएगा। नियमों को इस अनुच्छेद में संदर्भ के मुताबिक शामिल
                किया गया माना जाता है। न्यायालय में तीन न्यायाधीश होंगे। हरेक
                विवादित पक्ष एक मध्यस्थ नियुक्त करेंगे और इस तरह नियुक्त दो
                मध्यस्थ संयुक्त रूप से एक तीसरा मध्यस्थ नियुक्त करेंगे।
                मध्यस्थता की भाषा अंग्रेजी होगी।
              </p>
              <p>- मध्यस्थता का स्थान मुंबई, भारत होगा।</p>
              <p>
                - मध्यस्थता का निर्णय अंतिम है और पार्टियों पर बाध्यकारी है और
                पार्टियां इससे बाध्य होने और तदनुसार कार्य करने के लिए सहमत हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>क्या हम इस नीति में अपडेट या बदलाव करेंगे?</h3>
          <p>
            <i>
              <b>संक्षिप्त जानकारी:</b> हां, हम प्रासंगिक कानूनों का अनुपालन
              करने के लिए इस नीति को आवश्यकतानुसार अपडेट करेंगे।
            </i>
          </p>
          <p>
            हम समय-समय पर इस गोपनीयता नीति को अपडेट कर सकते हैं। अपडेट किए गए
            वर्शन को अपडेट की गई &quot;संशोधित&quot; तिथि के साथ चिह्नित किया
            जाएगा और उपलब्ध होने पर अपडेट किया गया वर्शन प्रभावी होगा।
          </p>
          <p>
            अगर हम इस गोपनीयता नीति में ज़रूरी बदलाव करते हैं, तो हम आपको या तो
            ऐसे बदलावों की सूचना प्रमुखता से पोस्ट करके या आपको एक सीधे सूचना
            भेजकर सूचित कर सकते हैं। हम आपको इस गोपनीयता नीति की बार-बार समीक्षा
            करने के लिए प्रोत्साहित करते हैं, ताकि यह समझ सकें कि हम आपकी
            जानकारी की सुरक्षा कैसे करते हैं।
          </p>
        </li>
        <li>
          <h3>आप इस नीति के बारे में हमसे कैसे संपर्क कर सकते हैं?</h3>
          <p>
            अगर इस नीति के बारे में आपका कोई प्रश्न या टिप्पणी है, तो हमें{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            पर ई-मेल करें
          </p>
          <p>
            अगर हमारे या हमारी नीतियों के बारे में आपका कोई और प्रश्न या टिप्पणी
            है, तो कृपया{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            पर ईमेल करें।
          </p>
        </li>
      </ol>
    </div>
  );
};
export default PrivacyHI;
