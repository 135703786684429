import { Capacitor } from '@capacitor/core';
import { useIonToast } from '@ionic/react';
import { t } from '@lingui/macro';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ACCESS_TOKEN, SELECTED_LANG } from '../config/appconfig';
import { getLangDescriptor } from '../services/lang-descriptor.service';
import { getProductList } from '../services/product.service';
import storageService from '../services/storage.service';
import { getUserDetails } from '../services/user.service';
import { getKeyFromString } from '../utils/common.helper';

export const AppContext = React.createContext({});
type Props = {
  children: any;
};
export const AppProvider = (props: Props) => {
  const [loading, isLoading] = useState(false);
  const [lang, setLang] = useState('en');
  const [isLoggedIn, setIsLoggedIn] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [productList, setproductList]: any = useState([]);
  const [isNewNotification, setIsNewNotification]: any = useState(false);
  const [isInActive, setIsInActive] = useState('false');

  const [present] = useIonToast();

  useEffect(() => {
    Promise.all([getSelectedLang(), checkIsLoggedIn(), getProducts()]);
  }, []);
  const setLoading = (loading: boolean) => {
    isLoading(loading);
  };

  const getSelectedLang = async () => {
    const lang = await storageService.get(SELECTED_LANG);
    setLang(lang || 'en');
    // moment.locale(lang || 'en');
    await getLangDescriptor(lang || 'en');
  };

  const setSelectedLang = async (langCode: string) => {
    await storageService.set(SELECTED_LANG, langCode);
    setLang(langCode);
    // moment.locale(langCode);
    await getLangDescriptor(langCode);
  };

  const checkIsLoggedIn = async () => {
    const isNewNoti = await storageService.get('setIsNewNotification');
    if (isNewNoti) {
      setIsNewNotification(isNewNoti == 'true');
    }
    const token = await storageService.get(ACCESS_TOKEN);
    if (token) {
      const resp = await getUserDetails();
      if (!resp.error) {
        if (!resp.is_active) {
          setUserDetails({});
          setIsLoggedIn('false');
        } else {
          setUserDetails(resp);
          setIsInActive(resp.is_in_active ? 'true' : 'false');
          setIsLoggedIn(resp.profile_complete ? 'true' : 'false');
        }
      }
    } else {
      setIsLoggedIn('false');
    }
  };

  const getProducts = async () => {
    const resp: any = await getProductList();
    if (!resp.error) {
      setproductList(resp);
    }
  };

  const toastMessage = (message: string, variant = 'success') => {
    message = t({
      id: getKeyFromString(message),
      message,
    });
    present({
      message: message,
      color: variant,
      duration: 2000,
      cssClass: Capacitor.getPlatform() != 'web' ? 'toast-message' : '',
    });
  };

  return (
    <>
      {isLoggedIn != '' && (
        <AppContext.Provider
          value={{
            loading,
            lang,
            isLoggedIn,
            userDetails,
            productList,
            isNewNotification,
            isInActive,
            setUserDetails,
            setLoading,
            getSelectedLang,
            setSelectedLang,
            setIsLoggedIn,
            toastMessage,
            setIsNewNotification,
            setIsInActive,
          }}
        >
          {props.children}
        </AppContext.Provider>
      )}
    </>
  );
};
