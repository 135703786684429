import { IonCard, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Breadcrumbs from '../../components/breadcrumbs';
import Layout from '../../components/layouts';
import PrivateRoute from '../../components/privateroute';
import Messages from '../../config/messages';
import { AppContext } from '../../context/app.context';
import { getNotificationById } from '../../services/user.service';
import { getLanguageText, isWeb } from '../../utils/common.helper';
import './style.scss';

const NotificationDetails: React.FC = () => {
  const [title, setTitle] = useState('');
  const [subText, setSubText] = useState('');
  const [locale, setLocale] = useState(null);

  const { loading, setLoading, toastMessage, lang }: any =
    useContext(AppContext);
  const { id }: any = useParams();
  useEffect(() => {
    getNotificationDetails();
  }, [id]);

  const getNotificationDetails = async () => {
    setLoading(true);
    const resp = await getNotificationById(id);
    setLoading(false);
    if (!resp.error) {
      setTitle(resp.title);
      setSubText(resp.message);
      setLocale(resp.locale);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };
  return (
    <IonPage className='page-notification-detail'>
      <Layout>
        <div
          className={`page-commodity-detail-container page-container ${
            isWeb() ? 'pt-0' : ''
          }`}
        >
          {isWeb() && (
            <div className='page-menu-list p-32 mb-24'>
              <div className='page-menu-list-inner'>
                <Breadcrumbs
                  subMenuText={'Notification Detail'}
                  menuText={'Notifications'}
                  subMenuTextKey={'notifications_detail'}
                  menuTextKey={'notifications'}
                  menuRoute={isWeb() ? '/notifications' : '/tabs/notifications'}
                />
              </div>
            </div>
          )}
          <IonCard className='post-card p-16 m-0 mb-24'>
            {loading ? null : (
              <>
                <h3>
                  {locale
                    ? getLanguageText(title, 'title', locale, lang)
                    : title}
                </h3>
                <p className='font-normal notification-details mt-10'>
                  {locale
                    ? getLanguageText(subText, 'message', locale, lang)
                    : subText}
                </p>
              </>
            )}
          </IonCard>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(NotificationDetails);
