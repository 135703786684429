import { FCM_TOKEN } from '../config/appconfig';
import Messages from '../config/messages';
import httpService, { HttpMethod } from './http.service';
import storageService from './storage.service';
import { deleteDeviceData } from './userdevice.service';

export async function verifyOTP(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.PATCH,
      url: 'auth/otp-verify',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function addSocialLogin(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'auth/add-social-account',
      data: params,
    });
    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function doSocialLogin(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'auth/login/social',
      data: params,
    });
    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function logout() {
  try {
    const fcm_token = await storageService.get(FCM_TOKEN);
    if (fcm_token) {
      await deleteDeviceData(fcm_token);
    }
    await storageService.clearAll();
    return true;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
