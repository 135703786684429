import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonRow,
} from '@ionic/react';
import { t, Trans } from '@lingui/macro';
import './style.scss';
import ButtonComponent from '../../components/button-component';
import { useContext, useEffect, useState } from 'react';
import CommodityListCard from '../../components/commoditylistcard';
import Breadcrumbs from '../../components/breadcrumbs';
import { useHistory } from 'react-router';
import { PrivateRoute } from '../../components/privateroute';
import { getCommodityListByFilter } from '../../services/commodity.service';
import { getDataForDropDown, getLanguageText } from '../../utils/common.helper';
import Messages from '../../config/messages';
import { AppContext } from '../../context/app.context';
import { getPosts, submitRespond } from '../../services/post.service';
import AutocompleteInput from '../autocompleteinput';
import { getDists, getStates } from '../../services/location.service';
import FilterChip from '../filterchip';
import DropDownFilter from '../dropdownfilter';
import EmptyData from '../emptydata';
import ValidationMessage from '../validationMessage';

let clearFilter = false;
const BuyList: React.FC = () => {
  const history = useHistory();
  const { setLoading, toastMessage, lang, loading, userDetails }: any =
    useContext(AppContext);
  const [selectType] = useState([
    {
      name: t({ id: 'please_select', message: 'Please Select' }),
      value: '',
    },
    {
      name: t({ id: 'fresh', message: 'Fresh' }),
      value: 'fresh',
    },
    {
      name: t({ id: 'dehydrated', message: 'Dehydrated' }),
      value: 'dehydrated',
    },
  ]);
  const [commodityList, setCommodityList] = useState([
    {
      id: 0,
      name: t({ id: 'all', message: 'All' }),
      value: 'all',
    },
  ]);
  const [allCommodityList, setAllCommodityList] = useState([
    {
      id: 0,
      name: t({ id: 'all', message: 'All' }),
      value: 'all',
    },
  ]);
  const [states, setStates] = useState([]);
  const [stateValue, setStateValue]: any = useState([]);

  const [districts, setDistricts] = useState([]);
  const [distValue, setDistValue]: any = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [pageMeta, setPageMeta]: any = useState({
    currentPage: 1,
  });

  const [filter, setFilter]: any = useState({
    post_type: 'buy',
  });

  const [posts, setPosts] = useState([]);
  const [isPaginationLoad, setIsPaginationLoad] = useState(false);
  const [filters, setFilters]: any = useState([]);

  const [selectedComm, setSelectedComm] = useState({
    id: 0,
    name: t({ id: 'all', message: 'All' }),
    value: 'all',
  });
  const [selectedType, setSelectedType] = useState({
    name: 'Please select',
    value: '',
  });

  const [showTypeError, setShowTypeError] = useState(false);
  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchCommodityList(),
      fetchStateList(),
      fetchDistList(),
      fetchPost(),
    ]).finally(() => {
      setLoading(false);
    });
    return () => {
      clearFilter = false;
    };
  }, []);

  const fetchCommodityList = async () => {
    let resp: any = await getCommodityListByFilter('all');
    if (!resp.error) {
      resp = resp.map((item: any) => {
        item.title = getLanguageText(item.title, 'title', item.locale, lang);
        return item;
      });
      setAllCommodityList(
        allCommodityList.concat(getDataForDropDown(resp, 'title'))
      );
      setCommodityList(commodityList.concat(getDataForDropDown(resp, 'title')));
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchStateList = async () => {
    const resp: any = await getStates();
    if (!resp.error) {
      setStates(resp.map((item: any) => item.state));
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchDistList = async () => {
    const resp: any = await getDists();
    if (!resp.error) {
      setDistricts(resp.map((item: any) => item.district));
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const fetchPost = async (
    showLoading = false,
    filterKey?: string,
    filterValue?: any,
    newFilter?: any
  ) => {
    if (showLoading && loading) return;
    if (showLoading) {
      setLoading(true);
    }
    clearFilter = false;
    const filterObj = newFilter || filter;
    if (newFilter) {
      setFilter(newFilter);
    }
    if (filterKey) {
      if (filterValue == 0 || filterValue == '') {
        delete filterObj[filterKey];
      } else {
        filterObj[filterKey] = filterValue;
      }
      setFilter(filterObj);
    }
    const resp = await getPosts(`page=1&filter=${JSON.stringify(filterObj)}`);
    if (showLoading) {
      setLoading(false);
    }
    if (!resp.error) {
      setPosts(resp.items);
      setPageMeta(resp.meta);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchPostPagination = async (event: any) => {
    if (pageMeta.currentPage >= pageMeta.totalPages || isPaginationLoad) return;
    setIsPaginationLoad(true);
    const resp = await getPosts(
      `page=${pageMeta.currentPage + 1}&filter=${JSON.stringify(filter)}`
    );
    setIsPaginationLoad(false);
    if (!resp.error) {
      if (event) {
        event.target.complete();
      }
      if (event) {
        event.target.disabled =
          pageMeta.currentPage + 1 >= resp.meta.totalPages;
      }
      setPosts(posts.concat(resp.items));
      setPageMeta(resp.meta);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onRespondSubmit = async (req: any) => {
    const resp = await submitRespond(req);
    if (!resp.error) {
      toastMessage(Messages.RESPOND_ADDED_MSG);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onCommodityTypeFilter = (filterType: string) => {
    const filterData = allCommodityList.filter((item: any) => {
      return item.data
        ? item.data.type.toLowerCase() == filterType.toLowerCase()
        : item;
    });
    setCommodityList(filterData);
  };
  return (
    <div className='page-buy-container'>
      <div className='page-menu-list p-18 mb-24'>
        <div className='page-menu-list-inner'>
          <Breadcrumbs menuText={'Demand'} menuTextKey='buy' menuRoute='/buy' />
          <ButtonComponent
            name={'Add your requirement'}
            customClass='buy__add-your-req'
            textKey={'add_your_requirement'}
            onClick={() => {
              history.push('/addbuyrequirement');
            }}
          />
        </div>
      </div>
      <div className='buy__comomodity-finder-wrapper p-26 mb-48'>
        <h2 className='comomodity-finder-title font-aktiv-grotest-medium mb-24'>
          {t({ id: 'explore', message: 'Explore' })}
        </h2>
        <div className='buy__comomodity-wrapper'>
          <IonGrid className='p-0'>
            <IonRow className={`ion-row ${showFilter ? 'show-dropdown' : ''}`}>
              <IonCol size-xs='12' size-sm='3' className='ion-col select-dd'>
                <Trans
                  id='type'
                  render={() => (
                    <DropDownFilter
                      name='type'
                      labelName='Type'
                      labelKey='type'
                      optionList={selectType}
                      onChange={(item: any) => {
                        if (clearFilter) return;
                        let temp = filters;
                        const filterObj = temp.find((obj: any) => {
                          return obj.type == 'type';
                        });
                        if (filterObj) {
                          if (item.value == '') {
                            temp = temp.filter((obj: any) => {
                              return obj.type != 'type';
                            });
                            setShowTypeError(true);
                          } else {
                            filterObj.name = item.value;
                            setShowTypeError(false);
                          }
                          setFilters(temp);
                        } else {
                          if (item.value != '') {
                            setFilters([
                              ...filters,
                              { name: item.value, type: 'type' },
                            ]);
                            setShowTypeError(false);
                          }
                        }
                        onCommodityTypeFilter(item.value);
                        fetchPost(true, 'type', item.value);
                      }}
                      selectedItem={selectedType}
                    />
                  )}
                >
                  Type
                </Trans>
                {showTypeError && (
                  <ValidationMessage
                    message={Messages.TYPE_OF_COMMODITY_MSG}
                    customClass='filter-err-msg'
                  />
                )}
              </IonCol>
              <IonCol size-xs='12' size-sm='3' className='ion-col select-dd'>
                <Trans
                  id='select_commodity'
                  render={() => (
                    <DropDownFilter
                      name='selectCommodity'
                      labelName='Select Commodity'
                      labelKey='select_commodity'
                      optionList={commodityList}
                      onChange={(item: any) => {
                        if (clearFilter) return;
                        const typeObj = filters.find(
                          (item: any) => item.type == 'type'
                        );
                        if (!typeObj) {
                          setSelectedComm({
                            id: 0,
                            name: t({ id: 'all', message: 'All' }),
                            value: 'all',
                          });
                          setShowTypeError(true);
                          return;
                        }
                        let temp = filters;
                        const filterObj = temp.find((obj: any) => {
                          return obj.type == 'commodity_id';
                        });
                        if (filterObj) {
                          if (item.id == 0) {
                            temp = temp.filter((obj: any) => {
                              return obj.type != 'commodity_id';
                            });
                          } else {
                            filterObj.name = item.name;
                          }
                          setFilters(temp);
                        } else {
                          if (item.id != 0) {
                            setFilters([
                              ...filters,
                              { name: item.name, type: 'commodity_id' },
                            ]);
                          }
                        }

                        fetchPost(true, 'commodity_id', item.id);
                      }}
                      selectedItem={selectedComm}
                    />
                  )}
                >
                  Select Commodity
                </Trans>
              </IonCol>

              <IonCol size-xs='12' size-sm='3' className='ion-col state-dd'>
                <Trans
                  id='state'
                  render={({ translation }) => (
                    <AutocompleteInput
                      suggestions={states}
                      onItemSelected={(value: string) => {
                        if (clearFilter) return;
                        const temp = filters;
                        const filterObj = temp.find((obj: any) => {
                          return obj.type == 'state';
                        });
                        if (filterObj) {
                          filterObj.name = value;
                          setFilters(temp);
                        } else {
                          setFilters([
                            ...filters,
                            { name: value, type: 'state' },
                          ]);
                        }
                        setStateValue(value);
                        fetchPost(true, 'state', value);
                      }}
                      name='state'
                      labelName='State'
                      labelKey='state'
                      placeholder={'' + translation}
                      value={stateValue}
                      onSuggestionChange={(value: any) => {
                        if (clearFilter) return;
                        setStateValue(value);
                        if (value == '') {
                          let temp = filters;
                          temp = temp.filter((obj: any) => {
                            return obj.type != 'state';
                          });
                          setFilters(temp);
                          fetchPost(true, 'state', '');
                        }
                      }}
                      debounce={500}
                    />
                  )}
                >
                  State
                </Trans>
              </IonCol>
              <IonCol size-xs='12' size-sm='3' className='ion-col district-dd'>
                <Trans
                  id='district'
                  render={({ translation }) => (
                    <AutocompleteInput
                      suggestions={districts}
                      onItemSelected={(value: string) => {
                        if (clearFilter) return;
                        const temp = filters;
                        const filterObj = temp.find((obj: any) => {
                          return obj.type == 'district';
                        });
                        if (filterObj) {
                          filterObj.name = value;
                          setFilters(temp);
                        } else {
                          setFilters([
                            ...filters,
                            { name: value, type: 'district' },
                          ]);
                        }
                        setDistValue(value);
                        fetchPost(true, 'district', value);
                      }}
                      name='district'
                      labelName='District'
                      labelKey='district'
                      placeholder={'' + translation}
                      value={distValue}
                      onSuggestionChange={(value: any) => {
                        if (clearFilter) return;
                        let temp = filters;
                        temp = temp.filter((obj: any) => {
                          return obj.type != 'district';
                        });
                        setFilters(temp);
                        setDistValue(value);
                        if (value == '') {
                          fetchPost(true, 'district', '');
                        }
                      }}
                      debounce={500}
                    />
                  )}
                >
                  District
                </Trans>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className='filter-wrapper p-8'>
          {filters.length > 0 && (
            <>
              {filters.map((filterItem: any, index: any) => {
                return (
                  <FilterChip
                    key={index}
                    label={filterItem.name}
                    type={filterItem.type}
                    onRemove={(value: string, type: string) => {
                      console.log(value, type);
                      setFilters(
                        filters.filter((item: any) => {
                          return item.type != type;
                        })
                      );
                      if (type == 'commodity_id') {
                        setSelectedComm({
                          id: 0,
                          name: t({ id: 'all', message: 'All' }),
                          value: 'all',
                        });
                      } else if (type == 'type') {
                        setFilters(
                          filters.filter((item: any) => {
                            return (
                              item.type != 'commodity_id' && item.type != 'type'
                            );
                          })
                        );
                        setSelectedType({
                          name: 'Please select',
                          value: '',
                        });
                        setSelectedComm({
                          id: 0,
                          name: t({ id: 'all', message: 'All' }),
                          value: 'all',
                        });
                        setCommodityList(allCommodityList);
                        setTimeout(() => {
                          setShowTypeError(false);
                        }, 800);
                        delete filter.commodity_id;
                        fetchPost(true, 'type', '');
                      } else if (type == 'state') {
                        setStateValue('');
                        // fetchPost(true, 'state', '');
                      } else if (type == 'district') {
                        setDistValue('');
                        // fetchPost(true, 'district', '');
                      }
                    }}
                  />
                );
              })}
              <div
                className='clear-btn font-aktiv-grotest-regular'
                onClick={() => {
                  clearFilter = true;
                  setCommodityList(allCommodityList);
                  setFilters([]);
                  setSelectedComm({
                    id: 0,
                    name: t({ id: 'all', message: 'All' }),
                    value: 'all',
                  });
                  setSelectedType({
                    name: 'Please select',
                    value: '',
                  });
                  setStateValue('');
                  setDistValue('');
                  fetchPost(true, undefined, undefined, {
                    post_type: 'buy',
                  });
                }}
              >
                {t({ id: 'clear_all', message: ' Clear All' })}
              </div>
            </>
          )}
        </div>
        <ButtonComponent
          name={showFilter ? 'Hide Filter' : 'Show Filter'}
          customClass='buy__comomodity-filter mt-16'
          textKey={showFilter ? 'hide_filter' : 'show_filter'}
          outline
          onClick={toggleFilter}
        />
        {/* {showFilter && (
          <ButtonComponent
            name={'Clear Filter'}
            customClass='buy__comomodity-clear-filter mt-16'
            textKey={'clear_filter'}
            outline
            onClick={hideoptions}
          />
        )} */}
      </div>
      {loading && posts.length == 0 ? (
        <></>
      ) : posts.length > 0 ? (
        <>
          <IonList>
            {posts.map((item: any) => {
              let certification = '';
              if (item.certification_id) {
                const certArray = item.certification_id.map((certItem: any) => {
                  return getLanguageText(
                    certItem.certificate_name,
                    'name',
                    certItem.locale,
                    lang
                  );
                });

                if (certArray && certArray.length > 0) {
                  certification = certArray.join(', ');
                }
              }
              let postImage = null;
              if (
                item.commodity_images_obj &&
                item.commodity_images_obj.length > 0
              ) {
                postImage = item.commodity_images_obj[0].uri;
              } else if (item.commodity_id.image_pic_obj) {
                postImage = item.commodity_id.image_pic_obj.uri;
              }
              return (
                <div key={item.id} className='buy__cl-card-list-wrapper'>
                  <CommodityListCard
                    postType={item.post_type}
                    postUserId={item.user_id.id}
                    postId={item.id}
                    commodity={item.commodity_id}
                    category={item.category_id}
                    productVarietyBreed={item.variety_breed}
                    productQuantity={item.quantity}
                    unit={getLanguageText(
                      item.weightunit_id.unit_name,
                      'name',
                      item.weightunit_id.locale,
                      lang
                    )}
                    expectedPrice={item.price}
                    productType={item.type}
                    farmerName={
                      item.user_id.firstname && item.user_id.is_active
                        ? `${item.user_id.firstname} ${
                            item.user_id.lastname || ''
                          }`
                        : 'Anonymous'
                    }
                    farmerLocation={item.location_id.locality_name}
                    farmerDist={item.location_id.district}
                    farmerState={item.location_id.state}
                    farmerPinCode={item.location_id.pincode}
                    certification={certification}
                    farmerProfilePic={
                      userDetails.id == item.user_id.id
                        ? userDetails.profile_pic_obj
                          ? userDetails.profile_pic_obj.uri
                          : undefined
                        : item.user_id.profile_pic_obj
                        ? item.user_id.profile_pic_obj.uri
                        : undefined
                    }
                    imgPath={postImage}
                    showContactBtn={
                      item.user_id.is_active &&
                      userDetails.id != item.user_id.id
                    }
                    onRespondSubmit={async (
                      commentText: string,
                      postId: number,
                      toUserId: number
                    ) => {
                      return await onRespondSubmit({
                        text: commentText,
                        postId: postId,
                        toUserId,
                        type: 'private',
                      });
                    }}
                  />
                </div>
              );
            })}
          </IonList>
          <IonInfiniteScroll
            threshold='100px'
            onIonInfinite={(event) => {
              fetchPostPagination(event);
            }}
            disabled={pageMeta.currentPage >= pageMeta.totalPages}
          >
            <IonInfiniteScrollContent
              loadingSpinner='circles'
              loadingText='Loading more data...'
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      ) : (
        <div>
          <EmptyData />
        </div>
      )}
    </div>
  );
};

export default PrivateRoute(BuyList);
