// Must import the package once to make sure the web support initializes
import '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';
import {
  FileTransfer,
  FileTransferObject,
} from '@awesome-cordova-plugins/file-transfer';
import { ACCESS_TOKEN, api } from '../config/appconfig';
import { getFileDirectory, getFileNameFromURL } from '../utils/common.helper';
import httpService, { HttpMethod } from './http.service';
import storageSevice from './storage.service';
import { Directory } from '@capacitor/filesystem';
import { Http } from '@capacitor-community/http';
const BASE_URL = api.apiUrl;

export const downloadFile = async (
  url: string,
  filePath: string,
  ext = 'zip'
) => {
  try {
    if (Capacitor.getPlatform() == 'ios') {
      const fileTransfer: FileTransferObject = FileTransfer.create();
      const path: string = getFileDirectory() + `${filePath}.${ext}`;
      const res = await fileTransfer.download(BASE_URL + url, path);
      return {
        data: res.nativeURL,
        message: 'Download success',
      };
    } else {
      const res = await Http.downloadFile({
        url: `${BASE_URL}${url}`,
        filePath: `${filePath}.${ext}`,
        fileDirectory: Directory.Data,
      });
      return {
        data: res.path,
        message: 'Download success',
      };
    }
  } catch (error) {
    console.log('download error', error);
  }
  return {
    error: true,
    message: 'Download Failed',
  };
};

export const uploadFile = async (
  filePath: string,
  mimeType: string,
  fileName?: string
) => {
  try {
    const token = await storageSevice.get(ACCESS_TOKEN);
    const name: string =
      fileName && fileName != '' ? fileName : getFileNameFromURL(filePath);
    const options: any = {
      fileName: name,
      mimeType: mimeType,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      chunkedMode: false,
    };
    const fileTransfer: FileTransferObject = FileTransfer.create();
    const res = await fileTransfer.upload(
      filePath,
      encodeURI(BASE_URL + 'file/upload'),
      options
    );
    console.log(res);

    return res.response || { error: true, message: 'File upload failed' };
  } catch (error) {
    console.log('error', error);

    return {
      error: true,
      message: 'File upload failed',
    };
  }
};

export const uploadFileWeb = async (file: any): Promise<any> => {
  if (!file) {
    return null;
  }
  const formData = new FormData();
  if (typeof file == 'string') {
    file = DataURIToBlob(file);
  }
  formData.set('file', file);
  const response = await httpService.execute({
    url: `/file/upload`,
    method: HttpMethod.POST,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

// export async function dataUrlToFile(
//   dataUrl: string,
//   fileName: string
// ): Promise<File> {
//   const res: Response = await fetch(dataUrl);
//   const blob: Blob = await res.blob();
//   return new File([blob], fileName, { type: 'image/png' });
// }
function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export const getSignedVideoUrl = async (filePath: string) => {
  // if (!filePath.startsWith('videos')) {
  //   return {
  //     body: getFormattedUrl(filePath),
  //   };
  // }
  try {
    const resp = await httpService.execute({
      method: HttpMethod.GET,
      url: `file/download/${filePath}`,
    });
    return resp;
  } catch (error) {
    return {
      error: true,
      message: '',
    };
  }
};
