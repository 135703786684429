/* eslint-disable no-unused-vars */
import './style.scss';
import { IonAvatar, IonImg, IonModal, IonPopover } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ProfilePlaceholder from '../../assets/images/icons/profile-placeholder.svg';
import DeleteProfilePic from '../../assets/images/icons/trash.svg';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Capacitor } from '@capacitor/core';
import { chooseFromGallery, imageCapture } from '../../utils/media.helper';
import ButtonComponent from '../button-component';
import IonModalBack from '../../assets/images/icons/arrow-small-right.png';
import { t } from '@lingui/macro';
import { MAX_FILE_SIZE } from '../../config/appconfig';
import Messages from '../../config/messages';
import { AppContext } from '../../context/app.context';
import { getColorByInitial } from '../../utils/common.helper';

interface Props {
  userInitial?: string;
  imgPath?: string;
  customClass?: string;
  isUpload?: boolean;
  onCrop?: (base64: string) => void;
  onRemove?: () => Promise<boolean>;
}
let isOptionVisible = false;
let isCropVisible = false;

const ProfileImage: React.FC<Props> = ({
  userInitial,
  imgPath,
  customClass,
  isUpload,
  onCrop,
  onRemove,
}) => {
  const { setLoading }: any = useContext(AppContext);

  const fileInput = useRef(null);
  const [imagePath, setImagePath]: any = useState(imgPath);
  const [showError, setShowError]: any = useState(false);
  const [showDelete, setShowDelete]: any = useState(isUpload && imgPath);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [croppedImage, setCroppedImage]: any = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  useEffect(() => {
    setImagePath(imgPath);
  }, [imgPath]);

  useEffect(() => {
    if (showModal || popoverState.showPopover) {
      document.addEventListener('ionBackButton', handleBack);
    }

    return () => {
      document.removeEventListener('ionBackButton', handleBack);
    };
  }, [showModal, popoverState]);

  const handleBack = (ev: any) => {
    ev.detail.register(102, () => {
      if (isOptionVisible) {
        setShowModal(false);
        isOptionVisible = false;
        document.removeEventListener('ionBackButton', handleBack);
      } else if (isCropVisible) {
        setShowPopover({ showPopover: false, event: undefined });
        setImagePath(imgPath);
        isCropVisible = false;
        document.removeEventListener('ionBackButton', handleBack);
      }
    });
  };

  const handleDeletePic = async (e: any) => {
    e.stopPropagation();
    onRemove && (await onRemove());
    setImagePath(null);
    setCroppedImage(null);
    setShowDelete(false);
  };
  return (
    <div className={`profile_image mb-8 ${customClass || ''}`}>
      <input
        ref={fileInput}
        hidden
        type='file'
        accept={'image/jpeg, image/jpg, image/png'}
        onChange={(e) => {
          setLoading(true);
          if (e.target.files) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              console.log(e.target.files);
              setLoading(false);
              if (e.target.files && e.target.files[0].size <= MAX_FILE_SIZE) {
                setImagePath(reader.result);
                setShowPopover({ showPopover: true, event: undefined });
                isCropVisible = true;
                setShowError(false);
              } else {
                setShowError(true);
              }
            });
            reader.readAsDataURL(e.target.files[0]);
          }
        }}
      />

      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
          isCropVisible = false;
        }}
        className='profile-img-popover'
        backdropDismiss={false}
      >
        {imagePath && (
          <>
            <Cropper
              src={imagePath}
              style={{ height: 400, width: '100%' }}
              // Cropper.js options
              initialAspectRatio={1 / 1}
              guides={false}
              ref={cropperRef}
              cropBoxResizable={false}
              dragMode='none'
              viewMode={1}
              background={false}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'black',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: '33%',
                  justifyContent: 'flex-start',
                }}
              >
                <ButtonComponent
                  name='Crop & Save'
                  outline
                  textKey={'crop'}
                  customClass='crop-btn ml-10'
                  onClick={async () => {
                    const imageElement: any = cropperRef?.current;
                    const cropper: any = imageElement?.cropper;
                    const temp = cropper
                      .getCroppedCanvas({ maxWidth: 1024, maxHeight: 1024 })
                      .toDataURL('image/jpeg', 0.8);
                    setCroppedImage(temp);
                    setImagePath(null);
                    setShowPopover({ showPopover: false, event: undefined });
                    isCropVisible = false;
                    onCrop && onCrop(temp);
                    setShowDelete(true);
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: '33%',
                  justifyContent: 'center',
                }}
              >
                <ButtonComponent
                  name='Rotate'
                  outline
                  textKey={'rotate'}
                  customClass='rotate-btn'
                  onClick={async () => {
                    const imageElement: any = cropperRef?.current;
                    const cropper: any = imageElement?.cropper;
                    cropper.rotate(90);
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: '33%',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonComponent
                  name='Cancel'
                  outline
                  textKey={'cancel'}
                  customClass='cancel-btn mr-10'
                  onClick={async () => {
                    setShowPopover({ showPopover: false, event: undefined });
                    setImagePath(imgPath);
                    isCropVisible = false;
                    document.removeEventListener('ionBackButton', handleBack);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </IonPopover>

      <IonAvatar
        className='ion-avatar'
        onClick={async () => {
          if (!isUpload) return;
          if (Capacitor.getPlatform() == 'web') {
            // @ts-ignore
            fileInput?.current?.click();
          } else {
            setShowModal(true);
            isOptionVisible = true;
          }
          // setBackgroundOption(BackgroundOptionType.Gradient);
        }}
      >
        <>
          {croppedImage || imagePath || !userInitial ? (
            <IonImg
              src={croppedImage || imagePath || ProfilePlaceholder}
              className='ion-avatar__image'
            />
          ) : (
            <div
              className='ion-avatar__image user-initial'
              style={{ borderColor: getColorByInitial(userInitial) }}
            >
              <div style={{ color: getColorByInitial(userInitial) }}>
                {userInitial}
              </div>
            </div>
          )}
          {showDelete && (
            <IonImg
              src={DeleteProfilePic}
              className='delete-profile'
              onClick={handleDeletePic}
            />
          )}
        </>
      </IonAvatar>

      {showError && <div className='error'>{Messages.IMAGE_MAX_SIZE_MSG}</div>}
      <IonModal
        isOpen={showModal}
        initialBreakpoint={0.25}
        breakpoints={[0, 0.25, 0.5, 1]}
        className='ion-modal'
        onDidDismiss={() => {
          setShowModal(false);
          isOptionVisible = false;
        }}
      >
        <div
          className='ion-modal-back-wrapper p-16'
          onClick={() => {
            setShowModal(false);
            isOptionVisible = false;
          }}
        >
          <IonImg
            className='ion-modal-back-img mr-10'
            src={IonModalBack}
          ></IonImg>
          <span className='ion-modal-back-txt mt-4'>
            {t({ id: 'back', message: 'Back' })}
          </span>
        </div>
        <div className='ion-modal-btn-wrapper p-16'>
          <ButtonComponent
            name='Take a picture'
            textKey={'take_a_picture'}
            customClass='ion-modal-btn mb-10'
            onClick={async (e: any) => {
              setShowModal(false);
              isOptionVisible = false;
              setLoading(true);
              const imageData = await imageCapture();
              setLoading(false);
              if (imageData) {
                setImagePath(imageData);
                setShowPopover({ showPopover: true, event: e });
                isCropVisible = true;
              }
            }}
          />
          <ButtonComponent
            name='Choose from gallery'
            textKey={'choose_from_gallery'}
            customClass='ion-modal-btn mb-10'
            onClick={async (e: any) => {
              setShowModal(false);
              isOptionVisible = false;
              setLoading(true);
              const imageData = await chooseFromGallery();
              setLoading(false);
              if (imageData) {
                setImagePath(imageData);
                setShowPopover({ showPopover: true, event: e });
                isCropVisible = true;
              }
            }}
          />
        </div>
      </IonModal>
    </div>
  );
};

export default ProfileImage;
