/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useEffect, useState } from 'react';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { t } from '@lingui/macro';

interface Props {
  name?: string;
  labelName?: string;
  labelKey?: string;
  customClass?: string;
  customClassTop?: string;
  optionList: any;
  onChange(selectedItem: any): void;
  error?: boolean;
  required?: boolean;
  selectedItem?: any;
  multiple?: boolean;
}
// const select = document.querySelector('ion-select');

// select.interfaceOptions = {
//   cssClass: 'custom-popover'
// }
const DropDownFilter: React.FC<Props> = ({
  name,
  labelName,
  labelKey,
  customClass,
  customClassTop,
  optionList,
  onChange,
  error,
  selectedItem,
  required,
  multiple,
}) => {
  labelKey = labelKey || '';
  labelName = labelName || '';
  const [value, setValue]: any = useState(selectedItem || null);

  useEffect(() => {
    setValue(selectedItem);
  }, [selectedItem]);
  const options = {
    cssClass: 'my-custom-class',
    size: 'cover',
  };

  return (
    <div className={`ion-field-wrapper ${customClassTop || ''}`}>
      <IonLabel
        className={`ion-label font-aktiv-grotest-regular ${
          error ? 'ion-field-label-error' : ''
        }`}
      >
        {t({ id: labelKey, message: labelName })}
        {required && <span className='mandatory'>*</span>}
      </IonLabel>
      <IonItem
        className={`font-aktiv-grotest-medium ion-field-main-container ion-field-drop-down ${
          error ? 'ion-field-error' : ''
        } ${customClass || ''}`}
      >
        <IonSelect
          name={name}
          value={value}
          //   selectedText={value}
          className='dropdown-select'
          interface='popover'
          interfaceOptions={options}
          placeholder={
            optionList && optionList.length ? optionList[0].name : ''
          }
          onIonChange={(e) => {
            if (
              value &&
              e.detail.value.value &&
              value.value != e.detail.value.value
            ) {
              setValue(e.detail.value);
              onChange(e.detail.value);
            }
          }}
          multiple={multiple}
        >
          {optionList.map((item: any, index: any) => {
            return (
              <IonSelectOption
                className='dropdown-select-option'
                value={item}
                key={index}
              >
                {item.name}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </div>
  );
};

export default DropDownFilter;
