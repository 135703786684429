import './style.scss';
import { IonPage, IonImg } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { useHistory, useParams } from 'react-router';
import { getFormattedUrlCMS, getRouteForCMS } from '../../utils/common.helper';
import brandBanner from '../../assets/images/cd-placeholder.png';

const PartnersDetail: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const { id }: any = useParams();

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setEntry(null);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent(id);
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  function onNavClick(route: string) {
    history.push(getRouteForCMS(route));
  }

  return (
    <IonPage className='page-partnerdetail'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <>
            <div className='banner-brand bg-purple mb-40'>
              <div className='container container--medium'>
                <div className='banner-brand__row'>
                  <div className='banner-brand__copy'>
                    <h1>{entry.partner_name.title}</h1>
                    <p>
                      <a
                        href={entry.website_url}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ color: 'white' }}
                      >
                        {entry.website_url}
                      </a>
                    </p>
                  </div>
                  <div className='banner-brand__img'>
                    <IonImg
                      src={
                        entry.logo && entry.logo.image
                          ? getFormattedUrlCMS(
                              `${entry.logo.image.hash}${entry.logo.image.ext}`
                            )
                          : brandBanner
                      }
                    ></IonImg>
                  </div>
                </div>
              </div>
            </div>
            <div className='section content-sec pt-0'>
              {entry.about_partner && (
                <div className='container container--medium mt-20'>
                  <ContentComp
                    data={{
                      description: entry.about_partner.description,
                      descPosition: entry.about_partner.position,
                      unique_key: entry.about_partner.unique_key,
                    }}
                  />
                </div>
              )}
              {entry.about_work && (
                <div>
                  <div className='container container--medium'>
                    <div className=''>
                      <ContentComp
                        data={{
                          title: entry.about_work.title,
                          titleTag: entry.about_work.tag_name,
                          titleCustomClass: 'mb-20',
                          unique_key: entry.about_work.unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className=' section-about-grid mt-20'>
                <div className='container container--medium'>
                  {entry.work_list && entry.work_list.length > 0 && (
                    <div className='row'>
                      {entry.work_list.map((item: any) => {
                        const col = entry.work_list[0].column
                          ? entry.work_list[0].column.trim() == '2'
                            ? '3'
                            : entry.work_list[0].column
                          : '1';

                        return (
                          <div
                            className={`col col-12 col-sm-6 col-md-${
                              12 / parseInt(col)
                            }  ${item.redirect_to ? 'cursor' : ''} `}
                            key={item.id}
                            onClick={() => {
                              if (item.redirect_to) {
                                onNavClick(item.redirect_to.model);
                              }
                            }}
                          >
                            <ContentComp
                              data={{
                                imagePath: item.images
                                  ? getFormattedUrlCMS(
                                      `${item.images[0].hash}${item.images[0].ext}`
                                    )
                                  : null,
                                title: item.title.title,
                                titleTag: item.title.tag_name,
                                description: item.description?.description,
                                descPosition: item.description?.position,
                                unique_key: item.unique_key,
                                displayType: item.display_type,
                                titleOnTop: item.title_on_top,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </IonPage>
  );
};

export default PartnersDetail;
