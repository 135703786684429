import {
  //IonButton,
  IonContent,
  //IonInput,
  //IonLabel,
  IonPage,
} from '@ionic/react';
// import { Trans } from '@lingui/macro';
// import { Formik, Form } from 'formik';
import React from 'react';
//import RegisterForm from '../../components/registerform';
// import ValidationMessage from '../../components/validationMessage';
// import { RegisterSchema } from '../../schemas/auth.schema';

const Registration: React.FC = () => {
  // const [error] = React.useState(null);
  // const initialValues = {
  //   email: '',
  //   password: '',
  //   stakeholderCategory: '',
  //   profilePic: '',
  // };

  // const handleLogin = (values: any) => {
  //   console.log(values);
  // };
  return (
    <IonPage>
      <IonContent>
        {/* <Formik
          enableReinitialize={false}
          initialValues={initialValues}
          validationSchema={RegisterSchema}
          onSubmit={handleLogin}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className='m-3'>
                <IonLabel>
                  <Trans id='email'>Email</Trans>
                </IonLabel>
                <Trans
                  id='enter_email'
                  render={({ translation }) => (
                    <IonInput
                      type='email'
                      placeholder={'' + translation}
                      value={values.email}
                      onIonChange={(e: any) => {
                        setFieldValue('email', e.target.value);
                      }}
                    ></IonInput>
                  )}
                >
                  Enter Email
                </Trans>

                <ValidationMessage name={'email'} />
              </div>
              <br />
              <div className='m-3'>
                <IonLabel>Password</IonLabel>
                <Trans
                  id='enter_password'
                  render={({ translation }) => (
                    <IonInput
                      type='password'
                      placeholder={'' + translation}
                      value={values.password}
                      onIonChange={(e: any) => {
                        setFieldValue('password', e.target.value);
                      }}
                    ></IonInput>
                  )}
                >
                  Enter Password
                </Trans>

                <ValidationMessage name={'password'} />
              </div>
              <br />

              <div className='m-3'>
                <IonLabel>Stakeholders Category</IonLabel>
                <select
                  onChange={(e) =>
                    setFieldValue('stakeholderCategory', e.target.value)
                  } // Using setValue
                >
                  <option value=''>Select</option>
                  <option value='farmer'>Farmer</option>
                  <option value='non-farmer'>Non-Farmer</option>
                </select>

                <ValidationMessage name={'stakeholderCategory'} />
              </div>
              <br />
              <input
                type='file'
                accept={'image/jpeg, image/jpg, image/png'}
                name='fileUpload'
                id={`fileUpload-pic`}
                onChange={(e) => {
                  if (e.target.files) {
                    setFieldValue('profilePic', e.target.files[0]);
                  }
                }}
              />
              <ValidationMessage name={'profilePic'} />
              <br />
              {error && <div className='text-center text-danger'>{error}</div>}
              <div className='w-100 text-center'>
                <IonButton type='submit'>
                  <IonLabel>Save</IonLabel>
                </IonButton>
              </div>
            </Form>
          )}
        </Formik> */}
        {/* <RegisterForm /> */}
        {/* <ChooseCommodity /> */}
      </IonContent>
    </IonPage>
  );
};

export default Registration;
