/* eslint-disable no-unused-vars */
import { IonTextarea } from '@ionic/react';
import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';

interface Props {
  placeholderName?: string;
  placeholderKey?: string;
  isSubmitting: boolean;
  respondText: string;
  onRepondChange: (text: string) => void;
  onRespondSubmit: () => void;
  onRespondCancel: () => void;
}
const RespondBox: React.FC<Props> = (props: Props) => {
  const {
    placeholderName,
    placeholderKey,
    isSubmitting,
    respondText,
    onRepondChange,
    onRespondSubmit,
    onRespondCancel,
  } = props;
  return (
    <div className='img-popover-inner' style={{ backgroundColor: 'white' }}>
      {/* <h3 className='popupSupport_head'>Support</h3> */}
      <div className='popupSupport_description'>
        {/* <p>
        <strong>Hi, There </strong>I&#8216;m interested in the drop post
        you updated in Covestro Food Services.{' '}
      </p> */}
        <IonTextarea
          className='respond-text'
          placeholder={t({
            id: placeholderKey || 'type_your_reponse',
            message: placeholderName || `Type your response`,
          })}
          rows={3}
          cols={1}
          autoGrow
          value={respondText}
          onIonChange={(e: any) => {
            onRepondChange(e.detail.value);
          }}
        ></IonTextarea>
      </div>
      <div className='popupSupport_btns'>
        <ButtonComponent
          name={'Send'}
          textKey={'send'}
          customClass='popup-btn'
          disabled={isSubmitting}
          onClick={() => {
            onRespondSubmit();
          }}
        />
        <ButtonComponent
          name={'Cancel'}
          textKey={'cancel'}
          customClass='popup-btn popup-btn-cancel'
          onClick={() => {
            onRespondCancel();
          }}
        />
      </div>
    </div>
  );
};

export default RespondBox;
