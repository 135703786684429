/* eslint-disable no-unused-vars */
import './style.scss';
import { IonImg, IonModal, IonPopover } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import HomeIcon from '../../assets/images/icons/home-icon.png';
import DragAndDrop from '../../assets/images/icons/drag-and-drop.png';
import { MAX_FILE_SIZE } from '../../config/appconfig';
import Messages from '../../config/messages';
import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';
import { chooseFromGallery, imageCapture } from '../../utils/media.helper';
import IonModalBack from '../../assets/images/icons/arrow-small-right.png';
import { isWeb } from '../../utils/common.helper';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { AppContext } from '../../context/app.context';
interface Props {
  filePath?: string;
  onFileSelect: (file: any) => void;
  onError: (message: string) => void;
}
let isOptionVisible = false;
let isCropVisible = false;

// const UploadComponent = (props: { setFieldValue: any; }) => {
const UploadComponent: React.FC<Props> = (prop) => {
  const { setLoading }: any = useContext(AppContext);
  const cropperRef = useRef<HTMLImageElement>(null);
  const { onFileSelect, onError, filePath } = prop;
  const [isUploaded, setIsUploaded]: any = React.useState(filePath);
  const [showModal, setShowModal] = useState(false);
  const [src, setSrc] = React.useState(filePath || '');
  const [imagePath, setImagePath]: any = useState(null);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setLoading(true);
      if (acceptedFiles[0].size <= MAX_FILE_SIZE) {
        setImagePath(URL.createObjectURL(acceptedFiles[0]));
        setShowPopover({ showPopover: true, event: undefined });
        isCropVisible = true;
      } else {
        onError(Messages.IMAGE_MAX_SIZE_MSG);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (showModal || popoverState.showPopover) {
      document.addEventListener('ionBackButton', handleBack);
    }

    return () => {
      document.removeEventListener('ionBackButton', handleBack);
    };
  }, [showModal, popoverState]);

  const handleBack = (ev: any) => {
    ev.detail.register(103, () => {
      if (isOptionVisible) {
        setShowModal(false);
        isOptionVisible = false;
        document.removeEventListener('ionBackButton', handleBack);
      } else if (isCropVisible) {
        setShowPopover({ showPopover: false, event: undefined });
        setImagePath(null);
        isCropVisible = false;
        document.removeEventListener('ionBackButton', handleBack);
      }
    });
  };

  return (
    <div className='drag-drop-container'>
      {isWeb() ? (
        <div
          {...getRootProps({ className: 'dropzone select-photos mr-24 mb-16' })}
        >
          <input {...getInputProps()} />
          {isUploaded ? (
            <div>
              <IonImg src={src} className='drag-drop-uploaded-img' />
            </div>
          ) : (
            <div className='drag-drop-wrapper'>
              <IonImg className='drag-drop-icon mb-16' src={DragAndDrop} />
              <p className='drag-n-drop'>Drag and drop or click here.</p>
            </div>
          )}
        </div>
      ) : (
        <div
          className='dropzone select-photos mr-24 mb-16'
          onClick={() => {
            setShowModal(true);
            isOptionVisible = true;
          }}
        >
          {isUploaded ? (
            <div>
              <IonImg src={src} className='drag-drop-uploaded-img' />
            </div>
          ) : (
            <div className='drag-drop-wrapper'>
              <IonImg className='drag-drop-icon mb-16' src={DragAndDrop} />
              <p className='drag-n-drop'>Click here</p>
            </div>
          )}
        </div>
      )}
      <IonModal
        isOpen={showModal}
        initialBreakpoint={0.25}
        breakpoints={[0, 0.25, 0.5, 1]}
        className='ion-modal'
        onDidDismiss={() => {
          setShowModal(false);
          isOptionVisible = false;
        }}
      >
        <div
          className='ion-modal-back-wrapper p-16'
          onClick={() => {
            setShowModal(false);
            isOptionVisible = false;
          }}
        >
          <IonImg
            className='ion-modal-back-img mr-10'
            src={IonModalBack}
          ></IonImg>
          <span className='ion-modal-back-txt mt-4'>
            {t({
              id: 'back',
              message: 'Back',
            })}
          </span>
        </div>
        <div className='ion-modal-btn-wrapper p-16'>
          <ButtonComponent
            name='Take a picture'
            textKey={'take_a_picture'}
            customClass='ion-modal-btn mb-10'
            onClick={async (e: any) => {
              setShowModal(false);
              isOptionVisible = false;
              setLoading(true);
              const imageData = await imageCapture();
              setLoading(false);
              if (imageData) {
                setImagePath(imageData);
                setShowPopover({ showPopover: true, event: e });
                isCropVisible = true;
              }
            }}
          />
          <ButtonComponent
            name='Choose from gallery'
            textKey={'choose_from_gallery'}
            customClass='ion-modal-btn mb-10'
            onClick={async (e: any) => {
              setShowModal(false);
              isOptionVisible = false;
              setLoading(true);
              const imageData = await chooseFromGallery();
              setLoading(false);
              if (imageData) {
                setImagePath(imageData);
                setShowPopover({ showPopover: true, event: e });
                isCropVisible = true;
              }
            }}
          />
        </div>
      </IonModal>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
          isCropVisible = false;
        }}
        className='profile-img-popover'
        backdropDismiss={false}
      >
        {imagePath && (
          <>
            <Cropper
              src={imagePath}
              style={{ height: 500, width: '100%' }}
              // Cropper.js options
              // initialAspectRatio={16 / 9}
              guides={false}
              ref={cropperRef}
              cropBoxResizable={true}
              dragMode='none'
              viewMode={1}
              background={false}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'black',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: '50%',
                  justifyContent: 'flex-start',
                }}
              >
                <ButtonComponent
                  name='Crop & Save'
                  outline
                  textKey={'crop'}
                  customClass='crop-btn ml-10'
                  onClick={async () => {
                    const imageElement: any = cropperRef?.current;
                    const cropper: any = imageElement?.cropper;
                    const temp = cropper
                      .getCroppedCanvas({ maxWidth: 1024, maxHeight: 1024 })
                      .toDataURL('image/jpeg', 0.8);
                    setSrc(temp);
                    setIsUploaded(true);
                    setImagePath(null);
                    setShowPopover({ showPopover: false, event: undefined });
                    isCropVisible = false;
                    onFileSelect(temp);
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: '50%',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonComponent
                  name='Cancel'
                  outline
                  textKey={'cancel'}
                  customClass='cancel-btn mr-10'
                  onClick={async () => {
                    setShowPopover({ showPopover: false, event: undefined });
                    setImagePath(null);
                    isCropVisible = false;
                    document.removeEventListener('ionBackButton', handleBack);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </IonPopover>
    </div>
  );
};

export default UploadComponent;
