import { APP_DOMAIN } from '../../../config/appconfig';

const TNCTA: React.FC = () => {
  //   const tableCotent = [
  //     {
  //       title: 'ACCEPTANCE OF THE TERMS AND CONDITIONS OF ACCESS AND USAGE',
  //       page: '5',
  //     },
  //     {
  //       title: 'THIRD-PARTY SERVICES',
  //       page: '6',
  //     },
  //     {
  //       title: 'ACCESS AND USE',
  //       page: '6',
  //     },
  //     {
  //       title: 'MEMBERSHIP/REGISTRATION AND ELIGIBILITY',
  //       page: '7',
  //     },
  //     { title: 'TERMS OF USE OF THE PLATFORM', page: '9' },
  //     { title: 'RESTRICTION ON USE OF SERVICES', page: '10' },
  //     { title: 'CONTENT', page: '11' },
  //     { title: 'LIMITED LICENSE TO THE APP', page: '12' },
  //     { title: 'BREACH OF CONTENT STANDARDS', page: '13' },
  //     { title: 'BILLING AND PAYMENT', page: '14' },
  //     { title: 'INDEMNITY AND LIMITATION OF LIABILITY', page: '14' },
  //     { title: 'OFFENCES', page: '16' },
  //     { title: 'ADDITIONAL TERMS FOR THE MOBILE APP', page: '17' },
  //     { title: 'AUTHORISED ACCESS', page: '17' },
  //     { title: 'CONFIDENTIALITY', page: '19' },
  //     { title: 'PRIVACY AND PROTECTION OF PERSONAL DATA', page: '19' },
  //     { title: 'INTELLECTUAL PROPERTY', page: '20' },
  //     { title: 'DISCLAIMER OF WARRANTIES', page: '21' },
  //     { title: 'USER SUBMISSION AND USER CONTENT BY THIRD PARTIES', page: '23' },
  //     { title: 'WAIVER AND SEVERABILITY', page: '24' },
  //     { title: 'USE OF MOBILE DEVICES', page: '24' },
  //     { title: 'PROHIBITED ACTIONS', page: '25' },
  //     { title: 'GOVERNING LAWS', page: '25' },
  //     { title: 'JURISDICTION', page: '25' },
  //     { title: 'MODIFICATION OF TERMS AND THE PLATFORM', page: '26' },
  //     { title: 'USE AT YOUR OWN RISK', page: '26' },
  //     { title: 'OPINIONS AND FEEDBACK', page: '26' },
  //   ];
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        COVESTRO உணவு பாதுகாப்பு பிளாட்ஃபார்முக்கான அணுகல் மற்றும்
        உபயோகத்திற்கான விதிமுறைகளும் நிபந்தனைகளும்
      </h3>
      {/* {tableCotent.map((item: any, index: number) => {
        return (
          <div key={index} className='table-row '>
            <div className='first-col'>{index + 1}.</div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <div style={{ flex: 0.1, textAlign: 'right' }}>{item.page}</div>
          </div>
        );
      })}
      <h4 style={{ textAlign: 'center', marginTop: 40 }}>
        TERMS AND CONDITIONS OF ACCESS AND USAGE FOR COVESTRO FOOD SECURITY
        PLATFORM
      </h4> */}
      <h4 style={{ textAlign: 'center' }}>
        கடைசியாகப் புதுப்பிக்கப்பட்டது [ஜூலை 28, 2022]
      </h4>
      <p>
        Covestro (INDIA) PRIVATE LIMITED இல் எங்கள் சமூகத்தின் ஒரு பகுதியாக
        இருக்கத் தேர்ந்தெடுத்ததற்கு நன்றி, முன்னதாக பேயர் மெட்டீரியல் சயின்ஸ்
        பிரைவேட் லிமிடெட் என அறியப்பட்ட இந்நிறுவனம், நிறுவனங்கள் சட்டம், 1956
        இன் கீழ் இணைக்கப்பட்ட ஒரு பிரைவேட் லிமிடெட் நிறுவனமாகும், இதன் பதிவு
        செய்யப்பட்ட அலுவலகம் பினவரும் முகவரியில் உள்ளது: யூனிட் எண் SB-801, 8வது
        தளம், எம்பயர் டவர், கிளவுட் சிட்டி கேம்பஸ், ஐரோலி, தானே - பேலாப்பூர்
        ரோடு நவி மும்பை தானே MH 400708 IN .(&quot;Covestro&quot;,
        &quot;நிறுவனம்&quot;, &quot;நாங்கள்&quot;, &quot;எங்களது&quot;, அல்லது
        &quot;எங்கள்&quot;, அல்லது &quot;பிளாட்ஃபார்ம்&quot;)
      </p>
      <p>
        கீழே விவரிக்கப்பட்டுள்ள இந்த விதிமுறைகள் &quot;பயனர்&quot; (இனிமேல்
        &quot;நீங்கள்&quot; அல்லது &quot;வாடிக்கையாளர்&quot; என்றும்
        குறிப்பிடப்படும்) மற்றும் Covestroக்கு இடையேயான ஒப்பந்த உறவை
        நிர்வகிக்கிறது. &quot;பயனர்கள்&quot; அல்லது &quot;நீங்கள்&quot; என்பது,
        முறையாகப் பதிவுசெய்யப்பட்ட மற்றும் பிளாட்ஃபார்மைப் பயன்படுத்தும்
        எந்தவொரு நபரையும் அதாவது நுகர்வோர், விவசாயிகள் மற்றும்/அல்லது விவசாயிகள்
        சங்கங்கள்/குழுக்கள் என்று இதன் மூலம் தெளிவுபடுத்தப்படுகிறது.
      </p>
      <p>
        நிதித் திட்டங்கள் அல்லது கொள்கைகள், உணவு & விவசாய விளைபொருட்களையும்
        தயாரிப்புகளையும் பற்றிய வழிகாட்டுதலை உள்ளடக்கிய தயாரிப்புகளையும்
        சேவைகளையும் பயனர்களுக்கு வழங்க, அத்துடன் பல்வேறு மூன்றாம் தரப்பு
        விற்பனையாளர்களுக்கான அணுகலை பயனர்களுக்கு வழங்க, COVESTRO இந்த
        இணையதளம்/செயலி [உணவு பாதுகாப்பு பிளாட்ஃபார்ம்] மற்றும்/அல்லது தொடர்புடைய
        சேவைகள், விற்பனை, சந்தைப்படுத்தல் அல்லது நிகழ்வுகளை (இந்தக் கொள்கையில்
        &quot;பிளாட்ஃபார்ம்&quot; அல்லது &quot;இணையதளம்/செயலி&quot; எனக்
        குறிப்பிடுகிறோம்)
      </p>
      <p>
        உருவாக்கியுள்ளது. பயனர்கள் வழங்கிய தகவல்கள் மற்றும் ஒவ்வொரு பயனருக்கும்
        குறிப்பிடத்தக்கதாக உள்ள தகவல்களை உள்ளடக்கிய, ஆனால் இவை மட்டுமல்லாமல்
        பல்வேறு காரணிகளைப் பரிந்துரைகள் கருத்தில் கொண்டிருந்தாலும்,
        பிளாட்ஃபாரமில் அணுகக்கூடிய எந்தவொரு நிதித் திட்டங்கள் அல்லது கொள்கைகள்,
        உணவு & விவசாய விளைபொருட்கள் மற்றும் தயாரிப்புகளின் தரம் அல்லது
        நம்பகத்தன்மைக்கு நாங்கள் உத்தரவாதம் அளிக்க மாட்டோம் என்று மீண்டும்
        வலியுறுத்தித் தெரிவிக்கிறோம், மேலும் இந்த பிளாட்ஃபாரம் என்பது ஆன்லைன்
        சந்தையாகச் செயல்படும் ஒரு பாலம் மட்டுமே.
      </p>
      <ol>
        <li>
          <h3>
            அணுகல் மற்றும் உபயோகத்தின் விதிமுறைகளையும் நிபந்தனைகளையும்
            ஏற்றுக்கொள்ளுதல்
          </h3>
          <ol>
            <li>
              <p>
                எங்கள் சேவைகளில் ஏதேனும் ஒன்றில் குழுசேர்வதன் மூலம் அல்லது
                பயன்படுத்துவதன் மூலம், நீங்கள் எவ்வாறு குழுசேர்கிறீர்கள் அல்லது
                சேவைகளைப் பயன்படுத்துகிறீர்கள் என்பதைப் பொருட்படுத்தாமல்,
                நீங்கள் விதிமுறைகளைப் படித்து, புரிந்துகொண்டு,
                அதற்குக்கட்டுப்பட்டிருப்பதை ஒப்புக்கொள்கிறீர்கள். நீங்கள்
                விதிமுறைகளுக்கு கட்டுப்பட விரும்பவில்லை என்றால், நீங்கள்
                குழுசேரவோ அல்லது எங்கள் சேவைகளைப் பயன்படுத்தவோ கூடாது. 2000 இன்
                தகவல் தொழில்நுட்பச் சட்டத்தின் கீழ் உருவாக்கப்பட்ட தகவல்
                தொழில்நுட்ப (இடைத்தரகர்கள் வழிகாட்டுதல்கள்) விதிகள், 2011 இன்
                விதிகளின்படி இந்த விதிமுறைகள் மற்றும் பல்வேறு கொள்கைகள்
                பிணைக்கப்பட்டுள்ளன.
              </p>
            </li>
            <li>
              <p>
                இந்த விதிமுறைகள் உங்களுக்கும் COVESTRO க்கும் முக்கியமானவை,
                ஏனெனில் அவை மதிப்புமிக்க வாடிக்கையாளராக உங்கள் உரிமைகளையும்
                வணிகமாக எங்கள் உரிமைகளையும் பாதுகாக்கப் பயன்படுகிறது.
              </p>
            </li>
            <li>
              <p>
                COVESTRO மூலம் வெளிப்படுத்தப்பட்ட, எழுதப்பட்ட, முன் விநியோகம்
                தவிர, இந்த விதிமுறைகள் மற்ற எல்லா நிபந்தனைகளுக்கும் பொருந்தும்,
                மேலும் மாற்றியமைக்கும்.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்பு மதிப்புரைகள், விற்பனையாளர் மதிப்புரைகள், சாத்தியமான
                விற்பனையாளர்களின் தொடர்பு விவரங்களைப் பெறுதல் போன்றவை உட்பட,
                ஆனால் அவை மட்டும் அல்லாமல், பிளாட்ஃபார்ம் மூலம் Covestro
                வழங்கும் சேவைகளில் ஏதேனும் ஒன்றை நீங்கள் பயன்படுத்தும் போது,
                நீங்கள் விதிகள், வழிகாட்டுதல்கள், கொள்கைகளுக்கு
                உட்பட்டிருப்பீர்கள், அத்தகைய சேவைக்கு பொருந்தக்கூடிய விதிமுறைகள்
                மற்றும் நிபந்தனைகள் மற்றும் அவை இந்த பயன்பாட்டு விதிமுறைகளுடன்
                இணைக்கப்பட்டதாகக் கருதப்படும் மற்றும் இந்த பயன்பாட்டு
                விதிமுறைகளின் ஒரு பகுதியாகவும் பார்சலாகவும் கருதப்படும்.
              </p>
            </li>
            <li>
              <p>
                இந்த பயன்பாட்டு விதிமுறைகளை எந்த நேரத்திலும் உங்களுக்கு எந்த
                முன் எழுத்துப்பூர்வ அறிவிப்பும் இல்லாமல் மாற்றுவதற்கான எங்கள்
                உரிமைகளை நாங்கள் கொண்டிருக்கிறோம். . புதுப்பிப்புகள் /
                மாற்றங்களுக்கு இந்த பயன்பாட்டு விதிமுறைகளை அவ்வப்போது
                மதிப்பாய்வு செய்வது உங்கள் பொறுப்பு. மாற்றங்களை இடுகையிட்டதைத்
                தொடர்ந்து பிளாட்ஃபார்மை நீங்கள் தொடர்ந்து பயன்படுத்தினால்,
                நீங்கள் திருத்தங்களை ஒப்புக்கொண்டு ஏற்றுக்கொள்கிறீர்கள் என்று
                அர்த்தம். சேவை விதிமுறைகளின் தற்போதைய பதிப்பை நீங்கள் இங்கு
                மதிப்பாய்வு செய்யலாம்:{' '}
                <a
                  href={`${APP_DOMAIN}legal/conditions-of-use`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/conditions-of-use`}
                </a>
              </p>
            </li>
            <li>
              <p>
                இந்த பயன்பாட்டு விதிமுறைகளுக்கு நீங்கள் இணங்கும் வரை,
                பிளாட்ஃபார்மில் நுழைவதற்கும் பயன்படுத்துவதற்கும் தனிப்பட்ட,
                பிரத்தியேகமற்ற, மாற்ற முடியாத, வரையறுக்கப்பட்ட சலுகையை நாங்கள்
                உங்களுக்கு வழங்குகிறோம்.
              </p>
            </li>
            <li>
              <p>
                ஐரோப்பிய ஒன்றியத்தின் பொதுத் தரவுப் பாதுகாப்பு ஒழுங்குமுறைக்கு
                (GDPR) இணங்கும் வகையில் எங்கள் கொள்கையைப் புதுப்பித்துள்ளோம்.
                புதிய கொள்கை மாற்றங்களின்படி, உங்களின் சொந்தத் தரவின் மீது
                உங்களுக்கு அதிகக் கட்டுப்பாடு உள்ளது, இது உங்களுக்கு மிகவும்
                பாதுகாப்பானதாக இருக்கும்
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>மூன்றாம் தரப்பு சேவைகள்</h3>
          <ol>
            <li>
              <p>
                மூன்றாம் தரப்பு தயாரிப்புகள் அல்லது சேவைகளுக்கான (“மூன்றாம்
                தரப்பு சேவைகள்”) இணைப்புகளுக்கான அணுகலை பிளாட்ஃபாரம் வழங்கலாம்.
                COVESTRO மூன்றாம் தரப்பு சேவைகளை எந்த வகையிலும்
                கட்டுப்படுத்தாது, அதன்படி, அத்தகைய மூன்றாம் தரப்பு சேவைகளுடன்
                தொடர்புடைய எந்தப் பொறுப்பையும் ஏற்காது. மூன்றாம் தரப்பு சேவையை
                அணுகுவது பொருத்தமானதா என்பதைத் தீர்மானிக்க நீங்கள் தகுந்த
                நடவடிக்கைகளை எடுக்க வேண்டும், இதில் அத்தகைய மூன்றாம் தரப்பு
                சேவைகளைப் பயன்படுத்துவதில் உங்கள் தனிப்பட்ட தகவல் மற்றும்
                தனியுரிமையைப் பாதுகாப்பது, மேலும் தொடர்புடைய ஒப்பந்தங்களுக்கு
                இணங்குவது உள்ளிட்டவை அடங்கும்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>அணுகல் மற்றும் பயன்பாடு</h3>
          <ol>
            <li>
              <p>
                பிளாட்ஃபார்ம் என்பது ஒரு சந்தையாகும், இதில் காய்கறிகள், பழங்கள்,
                தானியங்கள், பருப்பு வகைகள், மசாலா பொருட்கள், தோட்டப் பயிர்கள்,
                நிதித் திட்டங்கள், கொள்கைகள், திட்டங்கள் போன்றவை (ஒட்டுமொத்தமாக
                &quot;தயாரிப்புகள் மற்றும் சேவைகள்&quot; என குறிப்பிடப்படுகிறது)
                உள்ளிட்ட பல்வேறு உணவு & விவசாய விளைபொருட்கள் மற்றும்
                இணைக்கப்பட்ட சேவைகள் மற்றும் தீர்வுகளுக்கான அணுகல் பயனர்களுக்கு
                வழங்கப்படுகிறது.
              </p>
            </li>
            <li>
              <p>
                தளத்தின் மூலம் நீங்கள் சமர்ப்பிக்கும் எந்தவொரு உள்ளடக்கமும்{' '}
                <a
                  href={`${APP_DOMAIN}legal/privacy-statement`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/privacy-statement`}
                </a>{' '}
                இல் கிடைக்கும் நிறுவனத்தின் தனியுரிமைக் கொள்கையால்
                நிர்வகிக்கப்படுகிறது. இந்த ஒப்பந்தத்திற்கும் நிறுவனத்தின்
                தனியுரிமைக் கொள்கைக்கும் இடையில் முரண்பாடு இருக்கும் அளவிற்கு,
                இந்த விதிமுறைகள் நிர்வகிக்கப்படும்.
              </p>
            </li>
            <li>
              <p>
                கலந்துரையாடல் மன்றம் தொடர்பாக, நீங்கள் ஒரு கேள்வி அல்லது பதிலைச்
                சமர்ப்பித்தால், உங்கள் சொந்த தகவல்தொடர்புகள், அந்தத்
                தகவல்தொடர்புகளை இடுகையிடுவதால் ஏற்படும் விளைவுகள் மற்றும் பொதுப்
                பகுதிகளில் காணப்படும் எந்தத் தகவல்தொடர்புகளையும் நம்பியிருப்பது
                ஆகியவற்றுக்கு நீங்கள் மட்டுமே பொறுப்பாவீர்கள். நிறுவனம் மற்றும்
                அதன் உரிமதாரர்கள் பொது இடங்களில் எந்தவொரு தகவல்தொடர்புகளின்
                விளைவுகளுக்கும் பொறுப்பல்ல. நீங்கள் அச்சுறுத்தப்படுவதாக
                உணர்ந்தால் அல்லது வேறு யாராவது ஆபத்தில் இருப்பதாக நீங்கள்
                நம்பினால், உங்கள் உள்ளூர் சட்ட அமலாக்க நிறுவனத்தை உடனடியாகத்
                தொடர்புகொள்ளவும். பிளாட்ஃபார்ம் தொடர்பான உங்களின் அனைத்துச்
                செயல்பாடுகளுக்கும் நீங்கள் பொறுப்பாவீர்கள், மேலும் நீங்கள்
                அனைத்து உள்ளூர், மாநில, தேசிய மற்றும் சர்வதேச சட்டங்கள் மற்றும்
                விதிமுறைகள் மற்றும் பொருந்தக்கூடிய ஒழுங்குமுறைக்
                குறியீடுகளுக்குக் கட்டுப்படுவீர்கள்.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகளுக்கான அணுகல் தற்காலிக அடிப்படையில்
                அனுமதிக்கப்படுகிறது, மேலும் பிளாட்ஃபார்மில் நாங்கள் வழங்கும்
                தயாரிப்புகள் மற்றும் சேவைகளை (அல்லது தயாரிப்புகள் மற்றும்
                சேவைகளில் உள்ள ஏதேனும் அம்சங்கள்) திரும்பப் பெறவோ அல்லது
                திருத்தவோ எங்களுக்கு உரிமை உள்ளது. உங்களுக்கு முன்னறிவிப்பு
                இல்லாமல் நேரம். எந்தவொரு காரணத்திற்காகவும் பிளாட்ஃபாரம் அல்லது
                தயாரிப்புகள் மற்றும் சேவைகள் (அல்லது தயாரிப்புகள் மற்றும்
                சேவைகளில் உள்ள ஏதேனும் அம்சங்கள்) எந்த நேரத்திலும் அல்லது எந்த
                நேரத்திலும் கிடைக்காவிட்டால் நாங்கள் பொறுப்பாக மாட்டோம்.
                அவ்வப்போது, எங்களுடைய சொந்த விருப்பத்தின் பேரில், எங்களுடன்
                பதிவுசெய்த பயனர்களுக்கு, பிளாட்ஃபார்ம், அல்லது முழு
                பிளாட்ஃபார்மின், சில பகுதிகளுக்கான அணுகலைக் கட்டுப்படுத்தலாம்.
              </p>
            </li>
            <li>
              <p>
                எங்கள் பிளாட்ஃபார்மையும் அதன் மூலம் தயாரிப்புகள் மற்றும்
                சேவைகளையும் நீங்கள் அணுகுவதற்குத் தேவையான அனைத்து ஏற்பாடுகளையும்
                செய்வதற்கு நீங்கள் பொறுப்பாவீர்கள். வேறு யாரும் பிளாட்ஃபார்மை
                அணுகக்கூடாது என்பதையும், அதன் மூலம் உங்கள் கணக்கு மூலம்
                தயாரிப்புகள் மற்றும் சேவைகள் எங்களுக்கு எந்தப் பொறுப்பும்
                இல்லாமல் அத்தகைய அணுகலுக்கு நீங்கள் மட்டுமே பொறுப்பாவீர்கள்
                என்பதை உறுதிப்படுத்துவதும் உங்கள் பொறுப்பாகும்.
              </p>
            </li>
            <li>
              <p>
                உங்கள் கணக்கின் கீழ் நடக்கும் அல்லது நிகழும் அனைத்து
                நடவடிக்கைகளுக்கும் நீங்கள் முழுப் பொறுப்பு மற்றும் COVESTRO க்கு
                பொறுப்பாவீர்கள். உங்கள் கணக்கில் உள்நுழைவதற்கான உங்கள் திறன்
                இணைய சேவை வழங்குநர்கள் மற்றும் இணைய நெட்வொர்க் இணைப்பு போன்ற
                வெளிப்புற காரணிகளைப் பொறுத்தது என்பதை ஒப்புக்கொள்கிறீர்கள்,
                மேலும் உங்கள் கணக்கில் உள்நுழைய இயலாமையால் ஏற்படும் சேதங்களுக்கு
                நாங்கள் பொறுப்பேற்க மாட்டோம்.
              </p>
            </li>
            <li>
              <p>
                புதிய பயனர்களுக்கு பிளாட்ஃபார்மில் வழங்கப்படும் தயாரிப்புகள்
                மற்றும் சேவைகளைப் பயன்படுத்துவதற்கான அணுகலை மறுக்கும் உரிமையை
                COVESTRO கொண்டுள்ளது அல்லது அவ்வாறு செய்வதற்கான எந்தக் காரணமும்
                இல்லாமல் எந்த நேரத்திலும் இருக்கும் பயனர்களுக்கு வழங்கப்பட்ட
                அணுகலை நிறுத்தவும். எந்தவொரு காரணத்திற்காகவும் பிளாட்ஃபார்ம்
                அல்லது சேவைகளைப் பயன்படுத்துவதிலிருந்து COVESTRO ஆல் இடைநீக்கம்
                செய்யப்பட்ட அல்லது தடைசெய்யப்பட்ட எந்தவொரு பயனருக்கும் கணக்கைப்
                பயன்படுத்த முடியாது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>உறுப்பினர்/பதிவு மற்றும் தகுதி</h3>
          <ol>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகளை அணுகுவதற்கு, நீங்கள் தளத்தில்
                பதிவுசெய்து &quot;உறுப்பினர்&quot; கணக்கை உருவாக்க வேண்டும்.
                உங்கள் கணக்கு தயாரிப்புகள் மற்றும் சேவைகள் மற்றும்
                செயல்பாடுகளுக்கான அணுகலை உங்களுக்கு வழங்குகிறது, அவை நாங்கள்
                அவ்வப்போது நிறுவி பராமரிக்கலாம் மற்றும் பிளாட்ஃபார்மில் எங்கள்
                சொந்த விருப்பப்படி.
              </p>
            </li>
            <li>
              <p>
                பதிவு செயல்முறையை முடிப்பதன் மூலம், நீங்கள் உத்தரவாதம்
                அளிக்கிறீர்கள்: (அ) நீங்கள் வழங்கிய அனைத்து தரவுகளும்
                துல்லியமானது மற்றும் முழுமையானது; (b) நீங்கள் அத்தகைய தகவலின்
                துல்லியத்தை பராமரிக்க வேண்டும், மேலும் அத்தகைய தகவலை தொடர்ந்து
                புதுப்பிப்பதன் மூலம் அதில் ஏதேனும் மாற்றங்கள் செய்ய வேண்டும்;
                (c) நீங்கள் 16 வயதுக்கு மேற்பட்டவர் என்றும், இந்த ஒப்பந்தத்தில்
                குறிப்பிடப்பட்டுள்ள விதிமுறைகள், நிபந்தனைகள், கடமைகள்,
                உறுதிமொழிகள், பிரதிநிதித்துவங்கள் மற்றும் உத்திரவாதங்களுக்குள்
                நுழைவதற்கும், இந்த உடன்படிக்கைக்கு இணங்குவதற்கும் முழுமையாகவும்
                தகுதியுடனும் இருக்கிறீர்கள் என்பதை உறுதிப்படுத்துகிறீர்கள். ;
                (ஈ) நீங்கள் 18 (பதினெட்டு) வயதிற்குட்பட்டவராக இருந்தால், உங்கள்
                பெற்றோர் அல்லது பாதுகாவலர் இந்த ஒப்பந்தத்தின் விதிமுறைகளை
                மதிப்பாய்வு செய்து ஏற்க வேண்டும், மேலும் பிளாட்ஃபாரமைப்
                பயன்படுத்துவதன் மூலம், உங்கள் பெற்றோர் அல்லது பாதுகாவலர் இந்த
                ஒப்பந்தத்தை மதிப்பாய்வு செய்து ஏற்றுக்கொண்டார் என்பதை
                உறுதிப்படுத்துகிறீர்கள். பிளாட்ஃபார்மில் உள்ள சில அம்சங்கள், 18
                வயதுக்குட்பட்ட பயனர்களுக்கு மட்டுமே கிடைப்பதைக் கட்டுப்படுத்தும்
                உரிமையை நாங்கள் வைத்திருக்கிறோம். (e) நீங்கள் ஒரு பிணைப்பு
                ஒப்பந்தத்தில் நுழைவதற்கு பொருந்தக்கூடிய சட்டங்களின்படி
                தகுதியுடையவர் மற்றும் பொருந்தக்கூடிய சட்டங்களின் கீழ்
                தயாரிப்புகள் மற்றும் சேவைகளைப் பெறுவதற்குத் தடைசெய்யப்பட்ட
                நபரல்ல; மற்றும் (f) இந்த ஒப்பந்தம் மற்றும் பொருந்தக்கூடிய
                அனைத்து உள்ளூர், மாநில, தேசிய மற்றும் சர்வதேச சட்டங்கள், விதிகள்
                மற்றும் ஒழுங்குமுறைகளுக்கு இணங்க மட்டுமே பிளாட்ஃபார்ம் அல்லது
                தளத்தின் மூலம் வழங்கப்பட்ட பிற சேவைகளை நீங்கள் பயன்படுத்தலாம்.
                எந்தவொரு காயம், சேதம் அல்லது பிற விளைவுகள், உடல்நலம் தொடர்பான
                அல்லது பிளாட்ஃபார்மில் நீங்கள் வழங்கிய தகவல்களில் ஏதேனும் தவறான
                தன்மையால் ஏற்படும் எந்தப் பாதிப்புக்கும் நாங்கள் பொறுப்பாக
                மாட்டோம். இந்த ஒப்பந்தம் அல்லது தனியுரிமைக் கொள்கையின் கீழ்
                குறிப்பிடப்பட்டுள்ள நிபந்தனைகளில் ஏதேனும் ஒன்றை நீங்கள்
                மீறியுள்ளீர்கள் என்று நாங்கள் நம்பினால், எச்சரிக்கையின்றி அல்லது
                எந்த அறிவிப்பும் இன்றி 24 (இருபத்தி நான்கு) மணிநேரங்களுக்கு
                முன்னதாக உங்களுக்குத் தெரிவிப்பதன் மூலம் உங்கள் சுயவிவரம்
                எங்களால் நீக்கப்படலாம்.
              </p>
            </li>
            <li>
              <p>
                பதிவுச் செயல்முறையை முடிக்கவும், தயாரிப்புகள் மற்றும் சேவைகளைப்
                பயன்படுத்த உரிமை பெறவும், உங்கள் முழு சட்டப்பூர்வ பெயர்,
                தொலைபேசி எண், செல்லுபடியாகும் மின்னஞ்சல் முகவரி மற்றும்
                உங்களிடமிருந்து கோரப்படும் பிற தகவல்களை அவ்வப்போது வழங்க
                வேண்டும். தயாரிப்புகள் மற்றும் சேவைகள், பதிவுகளை வைத்திருக்கும்
                நோக்கங்கள், உள் நடைமுறைகள் அல்லது வேறு எந்த நோக்கங்களுக்காகவும்
                நீங்கள் வழங்கிய தகவல் எங்களால் எந்த மூன்றாம் தரப்பினருடனும்
                பகிரப்படலாம் மற்றும் இந்த தளத்தைப் பயன்படுத்துவதன் மூலம் நீங்கள்
                வழங்கிய தகவல்களைப் பகிர்வதற்கு நீங்கள் வெளிப்படையாக
                ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
            <li>
              <p>
                பதிவுசெய்தல் செயல்முறையை முடித்தவுடன், எங்கள் பாதுகாப்பு
                நடைமுறைகளின் ஒரு பகுதியாக, பயனர் அடையாளக் குறியீடு, கடவுச்சொல்
                அல்லது வேறு ஏதேனும் தகவல் உங்களுக்கு வழங்கப்படும். அத்தகைய தகவலை
                நீங்கள் ரகசியமாக கருத வேண்டும், மேலும் எந்த மூன்றாம்
                தரப்பினருக்கும் அதை வெளியிடக்கூடாது. ஒவ்வொரு அமர்வின் முடிவிலும்
                உங்கள் கணக்கிலிருந்து வெளியேற/வெளியேறுவதை உறுதிசெய்யவும்
                ஒப்புக்கொள்கிறீர்கள். நீங்கள் தேர்ந்தெடுத்த அல்லது எங்களால்
                ஒதுக்கப்பட்ட எந்தவொரு பயனர் அடையாளக் குறியீடு அல்லது
                கடவுச்சொல்லையும், எந்த நேரத்திலும், எங்கள் கருத்தில் நீங்கள்
                இந்த ஒப்பந்தத்தின் எந்த விதிகளுக்கும் இணங்கத் தவறினால், அதை
                முடக்க எங்களுக்கு உரிமை உள்ளது. உங்கள் கடவுச்சொல் அல்லது பயனர்
                அடையாளக் குறியீட்டின் அங்கீகரிக்கப்படாத பயன்பாடு குறித்த
                விவரங்களை உடனடியாக COVESTRO க்கு அறிவிப்பீர்கள்:{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>
              </p>
            </li>
            <li>
              <p>
                பிளாட்பாரமில் பதிவு செயல்முறை முடிந்ததும், நீங்கள் உறுப்பினராக
                இருப்பீர்கள்:
              </p>
              <ol>
                <li>
                  <p>
                    தயாரிப்புகளை விற்பனை அல்லது வாங்குவதற்கான அழைப்பாக
                    பிளாட்ஃபார்மில்; அணுகவும் இடுகையிடவும் உங்களை அனுமதிக்கிறது.
                  </p>
                </li>
                <li>
                  <p>
                    தேவைப்பட்டால், பிளாட்ஃபார்மின் பயன்பாட்டு விதிமுறைகளில்
                    ஏற்படும் மாற்றங்களை உங்களுக்குத் தெரிவிக்க அல்லது நாங்கள்
                    செயல்படுத்தும் புதிய சேவைகளை விவரிக்க உங்களைத் தொடர்புகொள்ள
                    எங்களை அனுமதிக்கிறது . பிளாட்ஃபார்ம்:
                  </p>
                </li>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகள் பற்றிய தகவல்களை
                    பிளாட்ஃபார்மில்அணுகவும் சேகரிக்கவும் உங்களை அனுமதிக்கிறது,
                    அதன்பின், குறிப்பிடப்பட்டுள்ள விதிமுறைகள் மற்றும்
                    நிபந்தனைகளுக்கு உட்பட்டு, அத்தகைய தகவலைத் தோற்றுவித்தவரைத்
                    தொடர்புகொள்ளவும். இங்கே;
                  </p>
                </li>
                <li>
                  <p>
                    இந்த பிளாட்ஃபார்மில் காட்டப்படும் மின்னஞ்சல் முகவரிகளை
                    எங்களால் அங்கீகரிக்கப்பட்டதைத் தவிர வேறு எந்த
                    நோக்கத்திற்காகவும் பயன்படுத்த அனுமதிக்காது.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                COVESTRO ஐ வழங்குவதன் மூலம் உங்கள் மின்னஞ்சல் முகவரி / தொலைபேசி
                எண்ணை நீங்கள் ஒப்புக்கொள்கிறீர்கள்:
              </p>
              <ol>
                <li>
                  <p>
                    அஞ்சல் மூலம் தொடர்புகொள்வதற்குப் பதிலாக, சட்டப்படி
                    தேவைப்படும் அறிவிப்புகள் உட்பட, சேவை தொடர்பான அறிவிப்புகளை
                    உங்களுக்கு அனுப்ப உங்கள் மின்னஞ்சல் முகவரி அல்லது மொபைல்
                    எண்ணைப் பயன்படுத்துகிறோம். மின்னஞ்சல்/மொபைல் எண் அல்லது
                    முழுவதுமாக சேவை தொடர்பான தகவல்தொடர்புகளில் இருந்து விலக
                    உங்கள் அமைப்புகளைப் பயன்படுத்தலாம்.
                  </p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்மில் உங்கள் முன்னேற்றம் மற்றும் எங்கள் சேவைகளின்
                    பயன்பாடு மற்றும் புதிய விளம்பரங்கள் மற்றும் விற்பனை
                    வாய்ப்புகள் தொடர்பான புதுப்பிப்புகள் மற்றும் ஆலோசனைகளை
                    உங்களுக்கு வழங்குவதற்காக, அவ்வப்போது உங்களைத்
                    தொடர்புகொள்வதற்காக, நீங்கள் வழங்கிய தொலைபேசி எண்களைப்
                    பயன்படுத்துகிறோம். நடைமேடை.
                  </p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்மில் நீங்கள் செய்யும் ஆர்டர்களை நிறைவேற்ற உங்கள்
                    மின்னஞ்சல் முகவரி, வீட்டு முகவரி, தொலைபேசி எண் மற்றும் பிற
                    துணைத் தகவல்களைப் பயன்படுத்துகிறோம்; மற்றும்
                  </p>
                </li>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகளின் அம்சங்கள் மற்றும் சிறப்புச்
                    சலுகைகள் உள்ளிட்ட பிற செய்திகளை உங்களுக்கு அனுப்ப உங்கள்
                    மின்னஞ்சல் முகவரி அல்லது தொலைபேசி எண்ணைப் பயன்படுத்துகிறோம்.
                    நீங்கள் அத்தகைய மின்னஞ்சல் செய்திகளைப் பெற விரும்பவில்லை
                    என்றால், எங்களுக்கு ஒரு மின்னஞ்சலை அனுப்புவதன் மூலம் நீங்கள்
                    விலகலாம்:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>
                    . விலகுதல், புதுப்பிப்புகள், மேம்பாடுகள் அல்லது சலுகைகள்
                    தொடர்பான மின்னஞ்சல் செய்திகள் அல்லது தொலைபேசி செய்திகளைப்
                    பெறுவதைத் தடுக்கலாம்.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>தளத்தின் பயன்பாட்டு விதிமுறைகள்</h3>
          <ol>
            <li>
              <p>
                மூன்றாம் தரப்பினரால் பட்டியலிடப்பட்டுள்ள தயாரிப்புகள் மற்றும்
                சேவைகளை அவ்வப்போது விற்பனை, வாங்குதல் மற்றும் பயன்படுத்துவதற்கான
                அழைப்பிதழ் பற்றிய தகவலை அணுகுவதற்கு நீங்கள் தளத்தை அணுகலாம்
                மற்றும் பயன்படுத்தலாம்.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்ம் அடிப்படையில் COVESTRO வின் பொறுப்பு இதற்கு மட்டுமே
                நீட்டிக்கப்படும்:
              </p>
              <ol>
                <li>
                  <p>பிளாட்ஃபார்மில் தளத்தின் ஏற்பாடு;</p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்மில் பதிவுசெய்துள்ள பயனர்களுக்கு தயாரிப்புகள்
                    மற்றும் சேவைகளின் அணுகல் மற்றும் கிடைக்கும் தன்மையை
                    உறுதிசெய்யும் சந்தையாகச் சேவை செய்கிறது.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                தளத்தில் பட்டியலிடப்பட்டுள்ள தயாரிப்புகள் மற்றும் சேவைகள்
                மூன்றாம் தரப்பு விற்பனையாளர்களுக்கு சொந்தமான மற்றும்
                விற்கப்படும் தயாரிப்புகள் மற்றும் சேவைகள் என்பது இதன் மூலம்
                தெளிவுபடுத்தப்படுகிறது. மூன்றாம் தரப்பு விற்பனையாளரின்
                தயாரிப்புகள் மற்றும் சேவைகள் எந்த வகையிலும் COVESTRO க்கு
                சொந்தமானவை அல்ல. எனவே, மூன்றாம் தரப்பு விற்பனையாளர்களின்
                தயாரிப்புகள் மற்றும் சேவைகளின் தரம், அளவு அல்லது நம்பகத்தன்மை
                ஆகியவற்றிற்கு COVESTRO எந்த உத்தரவாதமும் அளிக்காது மற்றும் எந்த
                வகையிலும் பொறுப்பேற்காது.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்மில் ஒரு ஆர்டர் அல்லது பதிவு செய்யப்பட்டு
                செயலாக்கப்பட்டதும், இங்கு குறிப்பிடப்பட்டுள்ளதைத் தவிர, பணத்தைத்
                திரும்பப்பெறுதல் அல்லது ரத்துசெய்வது தொடங்கப்படவோ அல்லது
                மகிழ்விக்கப்படவோ கூடாது.
              </p>
            </li>
            <li>
              <p>
                இந்த விதிமுறைகள் மற்றும் பயன்பாட்டு நிபந்தனைகளுக்கு உட்பட்டு,
                COVESTRO உங்களுக்கு மாற்ற முடியாத, பிரத்தியேகமற்ற, துணை உரிமம்
                பெறாத வரையறுக்கப்பட்ட உரிமையையும் உரிமத்தையும் வழங்குகிறது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>சேவைகளைப் பயன்படுத்துவதற்கான கட்டுப்பாடு</h3>
          <ol>
            <li>
              <p>
                பின்வரும் செயல்களில் எதையும் செய்ய நீங்கள்
                அனுமதிக்கப்படமாட்டீர்கள் மற்றும் செய்ய மாட்டீர்கள், அதை
                மீறினால், உங்களுக்கு எதிராக நாங்கள் வைத்திருக்கும் பிற உரிமைகள்
                அல்லது பரிகாரங்களுக்கு பாரபட்சமின்றி, தயாரிப்புகள் மற்றும்
                சேவைகளின் உங்கள் பயன்பாட்டை நிறுத்த COVESTRO க்கு உரிமை உண்டு:
              </p>
              <ol>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகளின் ஒரு பகுதியாக உங்களுக்கு
                    வழங்கப்பட்ட தளம் மற்றும் அனைத்து உள்ளடக்கத்தையும்
                    விநியோகித்தல், விற்பனை செய்தல், வழங்குதல், மாற்றியமைத்தல்,
                    மாற்றியமைத்தல், திருத்துதல், இணைத்தல், ஒன்றிணைத்தல் அல்லது
                    மாற்றுதல்;
                  </p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்ம் அல்லது இணையதளம் அல்லது ஆப்ஸின் ஒரு பகுதியாக
                    உங்களுக்கு வழங்கப்பட்ட சேவை அல்லது உள்ளடக்கத்தை சிதைக்க,
                    தலைகீழ் பொறியாளர் அல்லது வேறுவிதமாக பிரிக்க முயற்சி;
                  </p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்ம் அல்லது இணையதளம் அல்லது ஆப்ஸ் மற்றும் குறிப்பாக
                    அதன் மூலக் குறியீட்டின் ஒரு பகுதியாக உங்களுக்கு வழங்கப்பட்ட
                    எந்த மென்பொருளையும் நகலெடுக்க முயற்சிக்கவும் அல்லது
                    உங்களுக்கு வழங்கப்பட்ட மென்பொருளின் எந்தப் பகுதியையும்
                    மறைகுறியாக்க முயற்சிக்கவும்;
                  </p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்ம் அல்லது தயாரிப்புகள் மற்றும் சேவைகளின் ஒரு
                    பகுதியாக வழங்கப்பட்ட எந்த உள்ளடக்கத்தையும் நீங்கள்
                    பயன்படுத்துவதற்கு வசதியாக அல்லது எங்களால் வழங்கப்பட்ட
                    எந்தவொரு மென்பொருளின் டெரிவேட்டிவ் வேலை அல்லது பதிப்பை
                    உருவாக்கவும்;
                  </p>
                </li>
                <li>
                  <p>
                    COVESTRO இன் டொமைன் பெயரைப் புனைப்பெயரில் திருப்பி அனுப்பும்
                    மின்னஞ்சல் முகவரியாகப் பயன்படுத்தவும்;
                  </p>
                </li>
                <li>
                  <p>
                    நீங்கள் எந்த வகையிலும் அணுகுவதற்கு அங்கீகரிக்கப்படாத
                    உள்ளடக்கத்தை அணுகவும் அல்லது அணுக முயற்சிக்கவும்;
                  </p>
                </li>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகளின் ஒரு பகுதியாக வழங்கப்பட்ட
                    தயாரிப்புகள் மற்றும் சேவைகள் அல்லது உள்ளடக்கம், ஏதேனும்
                    லோகோ, பதிப்புரிமை அல்லது தனியுரிம அறிவிப்புகள்,
                    புராணக்கதைகள், சின்னங்கள், லேபிள்கள், வாட்டர்மார்க்ஸ்,
                    கையொப்பங்கள் அல்லது தயாரிப்புகள் மற்றும் சேவைகளில்
                    ஒட்டப்பட்ட அல்லது உட்பொதிக்கப்பட்ட குறிகள் போன்றவற்றை
                    அகற்றவும். ;
                  </p>
                </li>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகள் அல்லது அதன் ஏதேனும் ஒரு
                    பகுதியைப் பயன்படுத்தி தவறான அடையாளத்தை உருவாக்கவும்,
                    எந்தவொரு நபரை அல்லது நிறுவனத்தைப் போல ஆள்மாறாட்டம் செய்யவும்
                    அல்லது எந்தவொரு உள்ளடக்கத்தின் தோற்றத்தையும் மறைக்க
                    முயற்சிக்கவும்;
                  </p>
                </li>
                <li>
                  <p>
                    பிளாட்ஃபார்மை அணுகவும் அல்லது பயன்படுத்தவும் பிளாட்ஃபார்மின்
                    சர்வர்கள் அல்லதுபிளாட்ஃபார்ம்ஹோஸ்ட் செய்யப்பட்டுள்ள எந்த
                    சர்வருடனும் இணைக்கப்பட்ட நெட்வொர்க்குகள்;
                  </p>
                </li>
                <li>
                  <p>
                    எங்களால் வழங்கப்பட்ட இடைமுகத்தைத் தவிர வேறு எந்த
                    வழியிலும்பிளாட்பாரமை அணுகவும்;
                  </p>
                </li>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகளின் எந்தப் பகுதியையும் மாற்றவும்
                    அல்லது மாற்றவும்; மற்றும்
                  </p>
                </li>
                <li>
                  <p>
                    பாதுகாப்புக்கு இடையூறு விளைவித்தல் அல்லது குறுக்கீடு செய்தல்
                    அல்லது பிளாட்ஃபார்ம்க்கு தீங்கு விளைவித்தல், பொருட்கள்,
                    கணினி ஆதாரங்கள் அல்லது பயனர் கணக்குகள், கடவுச்சொற்கள்,
                    சேவையகங்களுக்கான அங்கீகரிக்கப்படாத அணுகலைப் பெறுதல் அல்லது
                    பிளாட்ஃபார்ம் அல்லது ஏதேனும் இணைக்கப்பட்ட இணைக்கப்பட்ட
                    தளங்கள் மூலம் இணைக்கப்பட்ட அல்லது அணுகக்கூடிய
                    நெட்வொர்க்குகள்.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>உள்ளடக்கம்</h3>
          <ol>
            <li>
              <p>
                பிளாட்ஃபார்மில் பொருட்களைப் பதிவேற்ற அல்லது எங்கள் தளத்தின் பிற
                பயனர்கள் அல்லது பிளாட்ஃபார்மில் உள்ள விற்பனையாளர்களுடன் தொடர்பு
                கொள்ள உங்களை அனுமதிக்கும் தயாரிப்புகள் மற்றும் சேவைகளை (அல்லது
                தயாரிப்புகள் மற்றும் சேவைகளின் ஏதேனும் அம்சம் அல்லது பகுதி)
                நீங்கள் பயன்படுத்தும் போதெல்லாம், நீங்கள் கண்டிப்பாக இணங்க
                வேண்டும் பொருந்தக்கூடிய தரவு பாதுகாப்பு விதிகள்/சட்டங்கள்
                மற்றும் கீழே குறிப்பிடப்பட்டுள்ள உள்ளடக்க தரநிலைகளுடன். உள்ளடக்க
                தரநிலைகள் பங்களிப்பின் ஒவ்வொரு பகுதிக்கும் அதன் முழுமைக்கும்
                பொருந்தும். உங்கள் பங்களிப்பானது உண்மைகளைக் கூறும் இடத்தில்
                துல்லியமாக இருக்க வேண்டும், உண்மையாகக் கடைப்பிடிக்கப்பட வேண்டும்
                (அது கருத்துக்களைக் கூறுகிறது), அது எந்த நாட்டிலிருந்து
                வெளியிடப்படுகிறதோ அந்த நாட்டில் பொருந்தக்கூடிய சட்டத்திற்கு
                இணங்க வேண்டும்; உங்கள் இடுகையிடுதல் அல்லது தயாரிப்புகள் மற்றும்
                சேவைகளின் பயன்பாட்டிற்கு பொருத்தமானதாக இருக்க வேண்டும்.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகளைப் பயன்படுத்தி ஒரு தேடலானது, நீங்கள்
                ஆட்சேபனைக்குரிய, பொருத்தமற்ற அல்லது புண்படுத்தக்கூடியதாகக்
                காணக்கூடிய தளங்கள் மற்றும் உள்ளடக்கத்திற்கான முடிவுகள் மற்றும்
                இணைப்புகளை உருவாக்கலாம் மற்றும் அத்தகைய உள்ளடக்கத்திற்கு நாங்கள்
                பொறுப்பேற்க முடியாது. நீங்கள் தயாரிப்புகள் மற்றும் சேவைகளைப்
                பயன்படுத்தினால், தயாரிப்புகள் மற்றும் சேவைகள், வணிகர்கள் மற்றும்
                நீங்கள் கோரும் தயாரிப்புத் தகவலை விற்கும் மூன்றாம் தரப்பினரின்
                இணையதளங்களுக்கான இணைப்புகள் பற்றிய தகவலை உங்களுக்குத் திருப்பித்
                தரலாம். COVESTRO இதன் மூலம் அதனுடன் தொடர்புடைய அனைத்து பொறுப்பு
                மற்றும் பொறுப்புகளை மறுக்கிறது.
              </p>
            </li>
            <li>
              <p>
                அத்தகைய வெளிப்புற தளங்கள் அல்லது ஆதாரங்கள் கிடைப்பதன் விளைவாக
                அல்லது முழுமை, துல்லியம் அல்லது இருப்பு ஆகியவற்றின் மீது நீங்கள்
                வைத்திருக்கும் நம்பிக்கையின் விளைவாக உங்களுக்கு ஏற்படக்கூடிய
                இழப்பு அல்லது சேதத்திற்கு COVESTRO பொறுப்பேற்காது என்பதை நீங்கள்
                ஒப்புக்கொள்கிறீர்கள், ஒப்புக்கொள்கிறீர்கள். எந்தவொரு விளம்பரம்,
                தயாரிப்புகள் மற்றும் சேவைகள் அல்லது பிற பொருட்கள் அல்லது அத்தகைய
                இணைய தளங்கள் அல்லது ஆதாரங்களில் இருந்து கிடைக்கும்.
              </p>
            </li>
            <li>
              <p>
                உங்கள் உள்ளடக்கம், ஆவணங்கள் மற்றும் பிற தரவைக் கண்காணிப்பதற்கு
                நீங்கள் பொறுப்பாவீர்கள், மேலும் சேவைகளுக்குள் அல்லது
                சேவைகளுக்குள் மாற்றப்பட்ட அல்லது கையாளப்படும் உள்ளடக்கம் எந்த
                மூன்றாம் தரப்பு உரிமைகளையும் மீறவில்லை என்பதை
                உறுதிப்படுத்துவதற்கு நாங்கள் பொறுப்பாவீர்கள். உங்கள்
                உள்ளடக்கத்தைச் செயலாக்குவதற்கு அல்லது தயாரிப்புகள் மற்றும்
                சேவைகளைப் பயன்படுத்துவதற்குத் தேவையான உரிமங்களை நீங்கள்
                வைத்திருக்கிறீர்கள் என்று உத்தரவாதம் அளிக்கிறீர்கள்.
              </p>
            </li>
            <li>
              <p>
                உங்கள் உள்ளடக்கம் உட்பட, தயாரிப்புகள் மற்றும் சேவைகளின்
                தொழில்நுட்ப செயலாக்கம் மற்றும் பரிமாற்றம் மறைகுறியாக்கப்படாமல்
                மாற்றப்படலாம் மற்றும் பின்வருவனவற்றை உள்ளடக்கியது: (அ) பல்வேறு
                நெட்வொர்க்குகளில் பரிமாற்றங்கள்; மற்றும் (ஆ) நெட்வொர்க்குகள்
                அல்லது சாதனங்களை இணைக்கும் தொழில்நுட்ப தேவைகளுக்கு இணங்க மற்றும்
                மாற்றியமைக்க மாற்றங்கள்.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்மில் தயாரிப்புகள் மற்றும் சேவைகளை விற்பனை செய்வதன்
                ஒரு பகுதியாக, பல்வேறு விற்பனையாளர்கள் படங்கள், விளக்கங்கள்
                மற்றும் பிற உள்ளடக்கங்களை இடுகையிடலாம் என்பதை நீங்கள் இதன் மூலம்
                ஒப்புக்கொண்டு புரிந்துகொள்கிறீர்கள். COVESTRO என்ற முறையில்
                நாங்கள் சந்தைச் சேவைகளை வழங்குவதற்கான ஒரு தளம் மட்டுமே, மேலும்
                கூறப்பட்ட மூன்றாம் தரப்பு விற்பனையாளர்களால் வெளியிடப்பட்ட
                உள்ளடக்கம் தொடர்பாக எந்தப் பொறுப்பும் இல்லை, அத்தகைய
                உள்ளடக்கத்தின் நம்பகத்தன்மை அல்லது நம்பகத்தன்மையின்
                அடிப்படையில், எந்த வகையிலும் .
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>பயன்பாட்டிற்கான வரையறுக்கப்பட்ட உரிமம்</h3>
          <ol>
            <li>
              <p>
                இந்த ஒப்பந்தத்தின் விதிமுறைகளுக்கு இணங்க, தனிப்பட்ட மற்றும் வணிக
                நோக்கங்களுக்காக பிளாட்ஃபார்மை அணுகவும் பயன்படுத்தவும் தனிப்பட்ட,
                உலகளாவிய, திரும்பப்பெறக்கூடிய, மாற்ற முடியாத மற்றும்
                பிரத்தியேகமற்ற உரிமத்தை நாங்கள் வழங்குகிறோம்.
              </p>
            </li>
            <li>
              <p>
                இந்த ஒப்பந்தத்தில் வெளிப்படையாக வழங்கப்படாத பிளாட்ஃபார்ம்
                மற்றும் அதற்கான அனைத்து உரிமைகள், தலைப்பு மற்றும் ஆர்வம் ஆகியவை
                COVESTRO ஆல் ஒதுக்கப்பட்டுள்ளன. நீங்கள் COVESTRO இன் மென்பொருள்,
                தலைப்பு, வர்த்தகப் பெயர், வர்த்தக முத்திரை, சேவை முத்திரை, லோகோ,
                டொமைன் பெயர் மற்றும்/அல்லது குறிப்பிடத்தக்க பிராண்டு அம்சங்கள்
                அல்லது COVESTRO க்கு சொந்தமான பிற உள்ளடக்கத்துடன் கூடிய வேறு
                ஏதேனும் அடையாளத்தைப் பயன்படுத்த விரும்பினால், நீங்கள் COVESTRO
                விடம் எழுத்துப்பூர்வ அனுமதியைப் பெற வேண்டும். அனுமதி கோரிக்கைகளை{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                என்ற முகவரிக்கு அனுப்பலாம்.
              </p>
            </li>
            <li>
              <p>
                8.3 எந்தவொரு சந்தேகத்தையும் தவிர்க்க, COVESTRO அனைத்து உரை,
                படங்கள், புகைப்படங்கள், ஆடியோ, வீடியோ, இருப்பிடத் தரவு மற்றும்
                COVESTRO உருவாக்கும் மற்றும் பிளாட்ஃபார்முடன் தொடர்புடைய அனைத்து
                வகையான தரவு அல்லது தகவல்தொடர்புகளையும் கொண்டுள்ளது. காட்சி
                இடைமுகங்கள், ஊடாடும் அம்சங்கள், கிராபிக்ஸ், வடிவமைப்பு, பயனர்
                உள்ளடக்கத்தின் தொகுத்தல் மற்றும் மொத்த பயனர் மதிப்பாய்வு
                மதிப்பீடுகள் மற்றும் பயனர் உள்ளடக்கத்தைத் தவிர்த்து (மேலே
                வரையறுத்துள்ளபடி) தளத்தின் அனைத்து கூறுகள் மற்றும் கூறுகளின்
                தொகுப்பு. இங்கு வெளிப்படையாகவும் சந்தேகத்திற்கு இடமின்றியும்
                வழங்கப்பட்டுள்ளதைத் தவிர, நாங்கள் உங்களுக்கு வெளிப்படையான அல்லது
                மறைமுகமான உரிமைகள் எதையும் வழங்க மாட்டோம், மேலும் பிளாட்ஃபார்ம்
                மற்றும் COVESTRO இன் உள்ளடக்கத்திற்கான அனைத்து உரிமைகளும்
                எங்களால் தக்கவைக்கப்படுகின்றன.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>உள்ளடக்க தரநிலைகளை மீறுதல்</h3>
          <ol>
            <li>
              <p>
                உள்ளடக்கத் தரங்களின் மீறல் (மேலே கூறியது போல்) ஏற்பட்டுள்ளதைக்
                கருத்தில் கொள்ளும்போது, எங்களின் விருப்பப்படி பொருத்தமானதாகக்
                கருதும் நடவடிக்கையை நாங்கள் எடுக்கலாம். இணங்கத் தவறினால்,
                நீங்கள் சேவையைப் பயன்படுத்த அனுமதிக்கப்பட்டுள்ள பயன்பாட்டு
                விதிமுறைகளை மீறுவதாகும், மேலும் பின்வரும் அனைத்து அல்லது ஏதேனும்
                செயல்கள் உட்பட சட்டப்பூர்வமாக கிடைக்கக்கூடிய எந்த
                நடவடிக்கையையும் நாங்கள் எடுக்கலாம்:
              </p>
              <ol>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகளைப் பயன்படுத்துவதற்கான உங்கள்
                    உரிமையை உடனடியாக, தற்காலிகமாக அல்லது நிரந்தரமாக திரும்பப்
                    பெறுதல் மற்றும் பிளாட்ஃபார்மில் உங்கள் கணக்கை ஒரே நேரத்தில்
                    நிறுத்துதல்/நிறுத்துதல்;
                  </p>
                </li>
                <li>
                  <p>
                    உங்கள் கவனத்திற்குக் கொண்டுவரப்பட்ட மீறலைக் குணப்படுத்தத்
                    தவறினால், தயாரிப்புகள் மற்றும் சேவைகளைப் பயன்படுத்தி ஏற்கனவே
                    பிளாட்ஃபார்மில் இடுகையிடப்பட்ட எந்தவொரு பங்களிப்பையும்
                    உடனடியாக, தற்காலிகமாக அல்லது நிரந்தரமாக அகற்றுதல்;
                  </p>
                </li>
                <li>
                  <p>உங்களுக்கு ஒரு எச்சரிக்கை வெளியீடு;</p>
                </li>
                <li>
                  <p>உங்களுக்கு எதிராக மேலும் சட்ட நடவடிக்கை; </p>
                </li>
                <li>
                  <p>
                    மீறலின் விளைவாக ஏற்படும் அனைத்து செலவுகளையும் இழப்பீட்டு
                    அடிப்படையில் (நியாயமான நிர்வாக மற்றும் சட்ட செலவுகள் உட்பட,
                    ஆனால் அவை மட்டும் அல்ல) திருப்பிச் செலுத்துவதற்கு உங்களுக்கு
                    எதிரான சட்ட நடவடிக்கைகள்; மற்றும்
                  </p>
                </li>
                <li>
                  <p>
                    சட்ட அமலாக்க அதிகாரிகளுக்கு அத்தகைய தகவலை வெளிப்படுத்துவது
                    அவசியமானது அல்லது சட்டத்தின்படி தேவைப்படுகிறது.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                இந்த விதிகளின் மீறல்களுக்கு பதிலளிக்கும் வகையில் நாங்கள்
                எடுக்கக்கூடிய அனைத்து நடவடிக்கைகளுக்கும் எங்கள் பொறுப்பை நாங்கள்
                மறுக்கிறோம் மற்றும் விலக்குகிறோம். மேலே விவரிக்கப்பட்ட செயல்கள்
                மட்டுப்படுத்தப்பட்டவை அல்ல, மேலும் நாங்கள் நியாயமான முறையில்
                பொருத்தமானதாகக் கருதும் வேறு எந்தச் செயலையும் செய்யலாம்.
              </p>
            </li>
            <li>
              <p>
                உங்கள் கணக்கு நிறுத்தப்படும்/நிறுத்தப்பட்டால், உங்கள் கணக்கையோ
                அல்லது சேவையகங்களில் நீங்கள் சேமித்துள்ள எந்த உள்ளடக்கத்தையோ
                அல்லது தரவையோ இனி உங்களால் அணுக முடியாது. பிளாட்ஃபார்ம் அல்லது
                தயாரிப்புகள் மற்றும் சேவைகளுக்கு COVESTRO மூலம் உங்களுக்கு
                வழங்கப்பட்ட அனைத்து உரிமங்களும் தானாகவே நிறுத்தப்படும்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>பில்லிங் மற்றும் பணம் செலுத்துதல்</h3>
          <ol>
            <li>
              <p>
                பிளாட்பார்மின் சில அம்சங்கள் கட்டணம் அல்லது பிற கட்டணத்திற்கு
                வழங்கப்படலாம். பிளாட்ஃபார்மின் கட்டண அம்சங்களைப் பயன்படுத்த
                நீங்கள் தேர்வுசெய்தால், அத்தகைய கட்டணங்கள் மற்றும்
                கட்டணங்களுக்குப் பொருந்தக்கூடிய விற்பனை, விலை, கட்டணம் மற்றும்
                பில்லிங் கொள்கைகளை நீங்கள் ஒப்புக்கொள்கிறீர்கள். COVESTRO
                கூடுதல் கட்டணங்கள் மற்றும் கட்டணங்களுக்காக புதிய சேவைகளைச்
                சேர்க்கலாம் அல்லது ஏற்கனவே உள்ள சேவைகளுக்கான கட்டணங்கள் மற்றும்
                கட்டணங்களை எந்த நேரத்திலும் அதன் சொந்த விருப்பப்படி திருத்தலாம்.
              </p>
            </li>
            <li>
              <p>
                தொடர்பு அல்லது பில்லிங் தகவல் மாற்றங்கள் அல்லது புதுப்பிப்புகள்
                (தொலைபேசி எண், மின்னஞ்சல் முகவரி, கிரெடிட் கார்டு எண்கள்
                போன்றவை) உடனுக்குடன் COVESTRO க்கு வழங்குவது உங்கள் பொறுப்பு.
                COVESTRO வாடிக்கையாளரின் கட்டண வழங்குநருக்குத் தேவைப்படும்
                அனைத்து கிரெடிட் கார்டு தகவலையும் பாதுகாப்பான கட்டணத்தைச்
                சரிபார்க்காது.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்மில் செய்யப்பட்ட வாங்குதல்களைப் பொறுத்தமட்டில், எந்த
                விதத்திலும் பணத்தைத் திரும்பப்பெறும் கோரிக்கைகள்
                ஏற்றுக்கொள்ளப்படாது மற்றும் அதற்கான பணத்தைத் திரும்பப்பெறுதல்
                தொடங்கப்படாது என்பதை இதன்மூலம் ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>இழப்பீடு மற்றும் பொறுப்பு வரம்பு</h3>
          <ol>
            <li>
              <p>
                எந்தவொரு மற்றும் அனைத்து உரிமைகோரல்கள், சேதங்கள், கடமைகள்,
                இழப்புகள், பொறுப்புகள், செலவுகள் அல்லது கடன்கள் மற்றும் செலவுகள்
                (வழக்கறிஞரின் கட்டணங்கள் உட்பட, ஆனால் அவை மட்டும் அல்ல)
                பாதிப்பில்லாத COVESTRO, அதன் அதிகாரிகள், இயக்குநர்கள், ஊழியர்கள்
                மற்றும் முகவர்களை பாதுகாக்கவும், இழப்பீடு வழங்கவும் மற்றும்
                வைத்திருக்கவும் ஒப்புக்கொள்கிறீர்கள், இதிலிருந்து எழுகிறது: (i)
                தயாரிப்புகள் மற்றும் சேவைகள் மற்றும் பிளாட்ஃபார்மை நீங்கள்
                பயன்படுத்துதல் மற்றும் அணுகுதல்; (ii) இந்த விதிமுறைகளின்
                எந்தவொரு விதிமுறையையும் நீங்கள் மீறுவது; (iii) எந்தவொரு மூன்றாம்
                தரப்பு உரிமையையும் மீறுவது, வரம்பு இல்லாமல், ஏதேனும்
                பதிப்புரிமை, சொத்து அல்லது தனியுரிமை உரிமை உட்பட; அல்லது (iv)
                உங்கள் பயனர் உள்ளடக்கம் மூன்றாம் தரப்பினருக்கு சேதத்தை
                ஏற்படுத்தியதாக ஏதேனும் உரிமைகோரல். இந்த ஒப்பந்தம் மற்றும்
                தயாரிப்புகள் மற்றும் சேவைகள் மற்றும் பிளாட்ஃபார்ம் ஆகியவற்றைப்
                பயன்படுத்துவதன் மூலம் இந்த பாதுகாப்பு மற்றும் இழப்பீட்டுக் கடமை
                நீடித்திருக்கும்.
              </p>
            </li>
            <li>
              <p>
                எங்கள் இணையதளம் அல்லது பயன்பாட்டில் காட்டப்படும் பொருள் அதன்
                துல்லியம் குறித்து எந்தவிதமான உத்தரவாதங்கள், நிபந்தனைகள் அல்லது
                உத்தரவாதங்கள் இல்லாமல் வழங்கப்படுகிறது. சட்டத்தால்
                அனுமதிக்கப்பட்ட அளவிற்கு, நாங்கள், எங்கள் துணை நிறுவனங்கள்
                மற்றும் துணை நிறுவனங்கள் மற்றும் எங்களுடன் இணைக்கப்பட்டுள்ள
                மூன்றாம் தரப்பினர் இதன் மூலம் வெளிப்படையாக விலக்குகிறோம்:
              </p>
              <ol>
                <li>
                  <p>
                    நிபந்தனைகள், உத்தரவாதங்கள் மற்றும் பிற விதிமுறைகள், மற்றபடி
                    சட்டம், பொதுச் சட்டம் அல்லது சமபங்குச் சட்டம் ஆகியவற்றால்
                    குறிக்கப்படலாம்;
                  </p>
                </li>
                <li>
                  <p>
                    தயாரிப்புகள் மற்றும் சேவைகள் தொடர்பாக எந்தவொரு பயனருக்கும்
                    நேரிடையான, மறைமுகமான அல்லது விளைவான இழப்பு அல்லது
                    சேதத்திற்கு ஏதேனும் பொறுப்பு, எங்களின்பிளாட்ஃபார்ம் அல்லது
                    பயன்பாடு தொடர்பாக, பயன்படுத்த இயலாமை, அல்லது தயாரிப்புகள்
                    மற்றும் சேவைகளின் பயன்பாட்டின் முடிவுகள் அல்லது
                    பிளாட்ஃபார்ம்அதனுடன் இணைக்கப்பட்டுள்ள இணையதளங்கள் மற்றும்
                    அதில் இடுகையிடப்பட்ட பொருட்கள் உட்பட, ஆனால் அவை மட்டும்
                    அல்ல:,
                  </p>
                  <ol>
                    <li>
                      <p>வருவாய் அல்லது வருவாய் இழப்பு;</p>
                    </li>
                    <li>
                      <p>வணிக இழப்பு;</p>
                    </li>
                    <li>
                      <p>இலாபங்கள் அல்லது ஒப்பந்தங்களின் இழப்பு;</p>
                    </li>
                    <li>
                      <p>எதிர்பார்த்த சேமிப்பு இழப்பு;</p>
                    </li>
                    <li>
                      <p>தரவு இழப்பு;</p>
                    </li>
                    <li>
                      <p>நல்லெண்ண இழப்பு;</p>
                    </li>
                    <li>
                      <p>வீணான மேலாண்மை அல்லது அலுவலக நேரம்; மற்றும்</p>
                    </li>
                    <li>
                      <p>
                        வேறு ஏதேனும் இழப்பு அல்லது சேதம் ஏற்பட்டாலும்,
                        சித்திரவதை (அலட்சியம் உட்பட), ஒப்பந்த மீறல் அல்லது
                        வேறுவிதமாக, எதிர்பார்க்கக்கூடியதாக இருந்தாலும் அல்லது
                        அறிவுறுத்தப்பட்டாலும்.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>
                எந்தவொரு தரப்பினரும் ஒப்பந்தம், சித்திரவதை (வரம்பு இல்லாமல்,
                அலட்சியம் உட்பட) அல்லது வேறுவிதமாக இந்த விதிமுறைகளின் காரணமாகவோ
                அல்லது அது தொடர்பாகவோ பொறுப்பேற்க மாட்டார்கள்;
                <br />
                அ) ஏதேனும் பொருளாதார இழப்புகள் (வரம்பில்லாமல், வருவாய் இழப்பு,
                இலாபங்கள், ஒப்பந்தங்கள், தரவு, வணிகம், எதிர்பார்க்கப்படும்
                சேமிப்பு அல்லது மாற்று சேவைகளின் செலவு உட்பட);
                <br />
                b) நல்லெண்ணம் அல்லது நற்பெயருக்கு ஏதேனும் இழப்பு;
                <br /> c) ஏதேனும் சிறப்பு, மறைமுக அல்லது விளைவான இழப்புகள்;
                அத்தகைய இழப்புகள் இந்த விதிமுறைகளின் தேதியில் கட்சிகளின்
                சிந்தனைக்குள் இருந்ததா இல்லையா.
              </p>
            </li>
            <li>
              <p>
                உங்கள் பயிற்சியிலிருந்து வாடிக்கையாளர் தரவு அல்லது தரவை
                அங்கீகரிக்கப்படாத அணுகல் அல்லது மாற்றுதல் தொடர்பாக COVESTRO
                மற்றும் அதன் முழுச் சொந்தமான துணை நிறுவனங்கள் பொறுப்பேற்காது
                அல்லது எந்தப் பொறுப்பையும் கொண்டிருக்காது என்பதை
                ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகளில் ஏதேனும் பிழை, குறைபாடு, தோல்வி
                அல்லது தாமதத்திற்கு COVESTRO எந்தப் பொறுப்பையும் கொண்டிருக்காது.
                மேற்கூறியவற்றின் பொதுவான தன்மைக்கு பாரபட்சம் இல்லாமல்,
                தயாரிப்புகள் மற்றும் சேவைகளில் ஏதேனும் தோல்வி அல்லது
                தாமதத்திற்கு COVESTRO எந்தப் பொறுப்பையும் கொண்டிருக்காது:
                ஏதேனும் சிறப்பு, மறைமுக அல்லது விளைவான இழப்புகள்; அத்தகைய
                இழப்புகள் இந்த விதிமுறைகளின் தேதியில் கட்சிகளின் சிந்தனைக்குள்
                இருந்ததா இல்லையா.
                <br />
                அ) பொது இணைய முதுகெலும்புகள், நெட்வொர்க்குகள் அல்லது சர்வர்கள்
                செயலிழப்பதால் ஏற்படும்; ஏதேனும் சிறப்பு, மறைமுக அல்லது விளைவான
                இழப்புகள்; அத்தகைய இழப்புகள் இந்த விதிமுறைகளின் தேதியில்
                கட்சிகளின் சிந்தனைக்குள் இருந்ததா இல்லையா.
                <br />
                b) உங்கள் உபகரணங்கள், அமைப்புகள் அல்லது உள்ளூர் அணுகல் சேவைகளின்
                ஏதேனும் தோல்விகள் அல்லது
                <br />
                c) முன்னர் திட்டமிடப்பட்ட பராமரிப்பு காரணமாக.
              </p>
            </li>
            <li>
              <p>
                COVESTRO வின் (மற்றும் அதன் முழுச் சொந்தமான துணை நிறுவனங்களின்)
                மொத்த ஒட்டுமொத்தப் பொறுப்பு (ஒப்பந்தம், துரோகம் (அலட்சியம்
                உட்பட) அல்லது வேறுவிதமாக) இந்த விதிமுறைகளின் கீழ் அல்லது அது
                தொடர்பாக நீங்கள் செலுத்திய உறுப்பினர் கட்டணத்தில் 50 சதவீதத்தை
                தாண்டக்கூடாது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>குற்றங்கள்</h3>
          <ol>
            <li>
              <p>
                வைரஸ்கள், ட்ரோஜான்கள், புழுக்கள், லாஜிக் குண்டுகள், நேர
                வெடிகுண்டுகள், கேன்சல் போட்கள், ஸ்பைவேர் அல்லது பிற பொருள்கள்,
                கணினி நிரலாக்க நடைமுறைகள், குறியீடுகள், கோப்புகள் அல்லது
                தீங்கிழைக்கும் அல்லது தொழில்நுட்ப ரீதியாக தீங்கு விளைவிக்கும்
                பிற நிரல்களை நீங்கள் தெரிந்தே அறிமுகப்படுத்தி பிளாட்ஃபார்மை
                தவறாகப் பயன்படுத்தக்கூடாது. பிற பயனர்களின் உரிமைகளின் நலன்கள்
                அல்லது கணினி மென்பொருள், வன்பொருள் அல்லது தொலைத்தொடர்புகளின்
                செயல்பாட்டைக் கட்டுப்படுத்துதல். பிளாட்ஃபார்ம், பிளாட்ஃபார்ம்
                சேமிக்கப்பட்டுள்ள சர்வர் அல்லது எங்கள் பிளாட்ஃபார்முடன்
                இணைக்கப்பட்டுள்ள சர்வர், கம்ப்யூட்டர் அல்லது டேட்டாபேஸ்
                ஆகியவற்றுக்கு அங்கீகரிக்கப்படாத அணுகலைப் பெற நீங்கள்
                முயற்சிக்கக் கூடாது. சேவை மறுப்பு தாக்குதல் அல்லது
                விநியோகிக்கப்பட்ட சேவை மறுப்புத் தாக்குதல் மூலம் நீங்கள்
                பிளாட்ஃபார்மை தாக்கக்கூடாது.
              </p>
            </li>
            <li>
              <p>
                இந்த விதியை மீறுவதன் மூலம், தகவல் தொழில்நுட்பச் சட்டம், 2000
                (மற்றும் ஏதேனும் திருத்தங்கள்) கீழ் நீங்கள் கிரிமினல்
                குற்றத்தைச் செய்வீர்கள். அத்தகைய மீறல் ஏதேனும் இருந்தால்
                சம்பந்தப்பட்ட சட்ட அமலாக்க அதிகாரிகளுக்கு நாங்கள் புகாரளிப்போம்,
                மேலும் உங்கள் அடையாளத்தை அவர்களிடம் வெளிப்படுத்துவதன் மூலம்
                நாங்கள் அவர்களுக்கு ஒத்துழைப்போம். அத்தகைய மீறல் ஏற்பட்டால்,
                பிளாட்ஃபார்மைப் பயன்படுத்துவதற்கான உங்கள் உரிமை உடனடியாக
                நிறுத்தப்படும்.
              </p>
            </li>
            <li>
              <p>
                விநியோகிக்கப்பட்ட சேவை மறுப்பு தாக்குதல், வைரஸ்கள் அல்லது பிற
                தொழில்நுட்ப ரீதியாக தீங்கு விளைவிக்கும் பொருட்களால் ஏற்படும்
                இழப்பு அல்லது சேதத்திற்கு நாங்கள் பொறுப்பேற்க மாட்டோம் அதில்
                இடுகையிடப்பட்ட எந்தவொரு பொருளையும் அல்லது அதனுடன் இணைக்கப்பட்ட
                எந்த பிளாட்ஃபார்மிலும் நீங்கள் பதிவிறக்கம் செய்ய வேண்டும்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>மொபைல் பயன்பாட்டிற்கான கூடுதல் விதிமுறைகள்</h3>
          <ol>
            <li>
              <p>
                நீங்கள் அல்லது பிற பயனர்கள் இணையதளம் அல்லது ஆப்ஸ் மற்றும்/அல்லது
                தயாரிப்புகள் மற்றும் சேவைகளை அணுகுவதற்கான நோக்கத்திற்காக மொபைல்
                ஆப், டெஸ்க்டாப் ஆப், தயாரிப்புகள் மற்றும் சேவைகள் மற்றும்
                டேட்டாவை அணுக உங்களுக்கு வரையறுக்கப்பட்ட, துணை உரிமம் இல்லாத
                உரிமை வழங்கப்பட்டுள்ளது. மூன்றாம் தரப்பு மென்பொருள் அல்லது
                இணையதளம் வழியாக. மொபைல் ஆப், டெஸ்க்டாப் ஆப்ஸின் எந்தவொரு
                பயன்பாடும், இந்த ஒப்பந்தத்தின் விதிமுறைகள் மற்றும் பின்வரும்
                குறிப்பிட்ட விதிமுறைகளுக்குக் கட்டுப்படும்:
              </p>
              <ol>
                <li>
                  <p>
                    எந்தவொரு மூன்றாம் தரப்பு மென்பொருளின் மூலம் நீங்கள் வழங்கும்
                    எந்தவொரு சேவைகள் மற்றும்/அல்லது தயாரிப்புகள் மற்றும்
                    சேவைகளுக்கு நீங்கள் மட்டுமே பொறுப்பு என்பதை
                    ஒப்புக்கொள்கிறீர்கள் (உங்களுக்கு அல்லது எந்த மூன்றாம்
                    தரப்பினருக்கும் எங்களுக்கு எந்தப் பொறுப்பும் அல்லது
                    பொறுப்பும் இல்லை);
                  </p>
                </li>
                <li>
                  <p>
                    எந்தவொரு மூன்றாம் தரப்பினருக்கும் கோரப்படாத தகவல்தொடர்புகளை
                    (வணிகமாகவோ அல்லது வேறு விதமாகவோ) அனுப்பும் மென்பொருளை
                    உருவாக்க மொபைல் ஆப் அல்லது டெஸ்க்டாப் ஆப்ஸை நீங்கள்
                    பயன்படுத்த மாட்டீர்கள்;
                  </p>
                </li>
                <li>
                  <p>
                    48 (நாற்பத்தெட்டு) மணிநேர அறிவிப்பை வழங்குவதன் மூலம், மொபைல்
                    பயன்பாடு மற்றும் டெஸ்க்டாப் பயன்பாட்டிற்கான உங்கள் அணுகலைத்
                    தற்காலிகமாகவோ அல்லது நிரந்தரமாகவோ மாற்றவோ அல்லது நிறுத்தவோ
                    எந்த நேரத்திலும் எங்களுக்கு உரிமை உள்ளது;
                  </p>
                </li>
                <li>
                  <p>
                    நேரடி, மறைமுக, தற்செயலான, சிறப்பு, பின்விளைவு அல்லது
                    முன்மாதிரியான சேதங்களுக்கு நாங்கள் பொறுப்பேற்க மாட்டோம்
                    என்பதை நீங்கள் தெளிவாகப் புரிந்துகொண்டு
                    ஒப்புக்கொள்கிறீர்கள், இதில் லாப இழப்பு, நல்லெண்ணம்,
                    பயன்பாடு, தரவு அல்லது மற்ற அருவமான இழப்புகள் (அத்தகைய
                    சேதங்கள் ஏற்படுவதற்கான சாத்தியக்கூறுகள் குறித்து எங்களுக்கு
                    அறிவுறுத்தப்பட்டிருந்தாலும் கூட) நீங்கள் மொபைல் ஆப் மற்றும்
                    டெஸ்க்டாப் ஆப்ஸைப் பயன்படுத்துவதன் விளைவாக;
                  </p>
                </li>
                <li>
                  <p>
                    48 (நாற்பத்தெட்டு) மணிநேர அறிவிப்பை வழங்குவதன் மூலம்,
                    டெஸ்க்டாப் பயன்பாடு மற்றும் மொபைல் செயலியை தற்காலிகமாகவோ
                    அல்லது நிரந்தரமாகவோ மாற்றவோ அல்லது நிறுத்தவோ எந்த
                    நேரத்திலும் எங்களுக்கு உரிமை உள்ளது.
                  </p>
                </li>
                <li>
                  <p>
                    டெஸ்க்டாப் ஆப்ஸ் மற்றும் மொபைல் ஆப்ஸ் மூலம் தயாரிப்புகள்
                    மற்றும் சேவைகளுக்கான துஷ்பிரயோகம் அல்லது அதிகப்படியான
                    அடிக்கடி கோரிக்கைகள் உங்கள் கணக்கின் அணுகலை தற்காலிகமாக
                    அல்லது நிரந்தரமாக நிறுத்தி வைக்கலாம். துஷ்பிரயோகம் அல்லது
                    அதிகப்படியான பயன்பாட்டை நாங்கள் எங்கள் சொந்த விருப்பப்படி
                    தீர்மானிப்போம்;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>அங்கீகரிக்கப்பட்ட அணுகல்</h3>
          <ol>
            <li>
              <p>
                உங்கள் வசதிக்காகவும் நேரத்தையும் நாங்கள் கவனித்துக்கொள்கிறோம்,
                அதை நோக்கி நாங்கள் தொடர்ந்து முயற்சி செய்து, பிளாட்ஃபார்ம்
                எப்போதும் கிடைக்கக்கூடியதாக இருப்பதை உறுதிசெய்கிறோம்,
                இருப்பினும், தொழில்நுட்பக் கோளாறுகள் மற்றும் பிற மனிதனல்லாத
                பிழைகள் கண்டிப்பாக ஏற்படும்.
              </p>
            </li>
            <li>
              <p>
                உங்கள் தடையற்ற அனுபவத்தைத் தக்கவைத்துக்கொள்வதற்காக, பொதுப்
                பராமரிப்பிற்காக பிளாட்ஃபார்ம்/சேவையகம்/இணைப்புகள்/இணையப்
                பக்கத்தை முழுமையாகவோ அல்லது பகுதியாகவோ நிறுத்துவதற்கான உரிமையை
                நாங்கள் பெற்றுள்ளோம், இருப்பினும் செயலிழக்கும் நேரத்தை
                குறைந்தபட்சமாக வைத்திருக்க முயற்சிப்போம். உங்கள் திருப்தியை
                உறுதிப்படுத்த.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்ம், முழுமையாக அல்லது அதன் எந்தப் பகுதியும்,
                பராமரிப்பு காரணமாக, அத்தகைய காலத்திற்குக் கிடைக்காமல் போனால்,
                எக்காரணம் கொண்டும் நாங்கள் பொறுப்பேற்க மாட்டோம் என்பது
                உறுதிப்படுத்தப்பட்டு, உங்களால் புரிந்து கொள்ளப்பட்டதாகக்
                கருதப்படுகிறது.
              </p>
            </li>
            <li>
              <p>
                எங்கள் சேவைகளைப் பயன்படுத்துவதற்கு, எங்கள் பாதுகாப்பு
                நடைமுறையின் ஒரு பகுதியாக, உங்கள் மின்னஞ்சல் அல்லது மொபைல் எண்ணை
                உள்ளிட்டு பாதுகாப்பான கடவுச்சொல்லைத் தேர்வுசெய்து மற்றும்/அல்லது
                உங்களுக்கு அனுப்பப்பட்ட ஒருமுறை கடவுச்சொல்லை (OTP) உள்ளிட்டு
                எங்களுடன் ஒரு கணக்கைப் பதிவு செய்ய வேண்டும். மொபைல் எண் அல்லது
                மின்னஞ்சல் முகவரி. பயனர் கடவுச்சொற்களைப் பொறுத்தவரை, மற்றவர்கள்
                எதிர்பார்க்க முடியாத வலுவான கடவுச்சொல்லைத் தேர்வுசெய்யுமாறு
                பரிந்துரைக்கிறோம், மேலும் ஒவ்வொரு அமர்வின் முடிவிலும் உங்கள்
                கணக்கிலிருந்து வெளியேறவும். வசதிக்காக, உங்கள் கடவுச்சொல்லை
                மறந்துவிட்டால் கடவுச்சொல் மீட்டமைப்பு செயல்முறை உள்ளது. உங்கள்
                கணக்கு உள்நுழைவுத் தகவலை எந்த மூன்றாம் தரப்பினருக்கும்
                வெளியிடாமல், அவற்றை நேரில் பயன்படுத்துவதன் மூலம், அதை
                ரகசியமானதாகக் கருதுவீர்கள் என்பதை நீங்கள் ஒப்புக்கொள்கிறீர்கள்.
                ஏதேனும் பாதுகாப்பு மீறல் ஏற்பட்டிருக்கலாம் என நீங்கள்
                நினைத்தால், உடனடியாக எங்களுக்குத் தெரிவிப்பதும், உள்நுழைவைத்
                தடுக்காத இடங்களில் உங்கள் கடவுச்சொல்லை உடனடியாக மாற்றுவதும்
                உங்கள் பொறுப்பாகும்.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்மில் நீங்கள் வழங்கும் அனைத்து தகவல்களும் சரியானவை,
                தற்போதைய மற்றும் முழுமையானவை என்பது உங்கள் பிளாட்ஃபார்மைப்
                பயன்படுத்துவதற்கான நிபந்தனையாகும். எங்கள் கருத்து மற்றும்
                தனிப்பட்ட விருப்பத்தின்படி, இந்த சேவை விதிமுறைகளின் எந்த
                விதிமுறைகளுக்கும் நீங்கள் இணங்கத் தவறினால் உட்பட, எந்த
                நேரத்திலும், அத்தகைய காரணத்திற்காக, எங்கள் சொந்த
                விருப்பத்தின்படி எந்தவொரு பயனர் கணக்கையும் முடக்குவதற்கான
                உரிமையை நாங்கள் வைத்திருக்கிறோம்.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்ம் மனித தலையீடு இல்லாத செயல் என்பதை
                ஒப்புக்கொள்கிறீர்கள், இதன் மூலம் பிளாட்ஃபார்ம் அல்லது
                பிளாட்ஃபார்மில் வழங்கப்பட்ட எந்த உள்ளடக்கமும் பிழையிலிருந்து
                விடுபடவில்லை என்பதை ஒப்புக்கொண்டு புரிந்துகொள்கிறீர்கள். ,
                சேவையின் மீறல் அல்லது குறைபாடு எனக் கருதப்படாது.
              </p>
            </li>
          </ol>
        </li>
        <p>
          ஒரு சாத்தியமற்ற நிகழ்வு அல்லது பேரழிவு தோல்வி, அல்லது இயற்கை பேரிடர்
          அல்லது தீ, அரசாங்க உத்தரவு, கலவரங்கள் அல்லது கோவெஸ்ட்ரோவின்
          கட்டுப்பாட்டிற்கு அப்பாற்பட்ட பிற காரணிகளின் அடிப்படையில் ஏற்படாத
          அனைத்து தொழில்நுட்பக் குறைபாடுகளும் COVESTRO ஆல் மேற்கொள்ளப்படுகிறது.
          90 முதல் 120 மணி நேரத்திற்குள் சரி செய்யப்பட்டது. இருப்பினும், சில
          தொழில்நுட்பக் கோளாறுகள், இயற்கைப் பேரிடர், அரசு உத்தரவு, கலவரங்கள்
          அல்லது வேறு ஏதேனும் நிகழ்வுகள் காரணமாக இணையதளத்திற்கான அணுகலை
          வழங்காததற்கு COVESTRO பொறுப்பேற்க முடியாது.
        </p>
        <li>
          <h3>இரகசியத்தன்மை</h3>
          <ol>
            <li>
              <p>
                இந்த விதிமுறைகளின் நோக்கங்களுக்காக, &quot;ரகசியத் தகவல்&quot;
                என்பது எந்தவொரு தனியுரிமத் தரவு மற்றும் ஒரு தரப்பினரால்
                மற்றவர்களுக்கு எழுத்துப்பூர்வமாக வெளிப்படுத்தப்பட்ட மற்றும்
                &quot;ரகசியம்&quot; அல்லது வாய்வழியாக வெளிப்படுத்தப்பட்ட மற்றும்
                ஐந்து வணிக நாட்களுக்குள், எழுதுவதற்குக் குறைக்கப்பட்டு
                &quot;ரகசியம்&quot; எனக் குறிக்கப்படும். &quot;. எவ்வாறாயினும்,
                பொது மக்களுக்குத் தெரிந்த அல்லது ஒரு தரப்பினரால்
                வெளிப்படுத்தப்படுவதற்கு முன்னர் பெறப்பட்ட தரப்பினரின் வசம் உள்ள
                அல்லது இரகசியத் தகவல் அல்லது எதையும் பயன்படுத்தாமல் பெறுநரால்
                சுயாதீனமாக உருவாக்கப்பட்ட எந்தவொரு தகவலையும் இரகசியத் தகவல்
                உள்ளடக்காது. இந்த விதிமுறைகளில் விவரிக்கப்பட்டுள்ளபடி COVESTRO
                தகவல் பயன்படுத்த அனுமதிக்கப்படுகிறது.
              </p>
            </li>
            <li>
              <p>
                எந்தவொரு தரப்பினரும் மற்றவரின் முன் எழுத்துப்பூர்வ அனுமதியின்றி
                மற்ற தரப்பினரின் ரகசியத் தகவலை இந்த விதிமுறைகளின் கீழ் அல்லது
                சட்டம், ஒழுங்குமுறை அல்லது நீதிமன்ற உத்தரவின்படி தேவைப்படின்
                அதன் கடமைகளை நிறைவேற்றும் நோக்கத்திற்காகப் பயன்படுத்தவோ அல்லது
                வெளியிடவோ மாட்டார்கள்; இந்த வழக்கில், இரகசியத் தகவலை
                வெளியிடுவதற்கு கட்டாயப்படுத்தப்படும் தரப்பினர், இரகசியத் தகவலை
                வெளியிடுவதற்கு முன், நியாயமான முறையில் நடைமுறைப்படுத்தக்கூடிய
                அளவுக்கு மற்ற தரப்பினருக்கு அறிவிப்பை வழங்குவார்கள்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>தனிப்பட்ட தரவின் தனியுரிமை மற்றும் பாதுகாப்பு</h3>
          <ol>
            <li>
              <p>
                தளத்தில் பதிவு செய்வதன் மூலம், உண்மையான மற்றும் உண்மையான
                தகவல்களை எங்களுக்கு வழங்க ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
            <li>
              <p>
                தவறான தகவலைத் தெரிவிப்பது தற்போதைய பொதுவான விதிமுறைகள் மற்றும்
                நிபந்தனைகளுக்கு முரணானது, மேலும் சட்ட நடவடிக்கைகளுக்கு உங்களைப்
                பொறுப்பாக்குவீர்கள்.
              </p>
            </li>
            <li>
              <p>
                உங்களைப் பற்றி நாங்கள் வைத்திருக்கும் தனிப்பட்ட தகவல்களைக்
                கண்டறிய நீங்கள் எந்த நேரத்திலும் எங்களிடம் கோரிக்கை வைக்கலாம்.
                நீங்கள் எந்த நேரத்திலும், கோரிக்கையின் பேரிலும் இந்தத் தகவலை
                மாற்றலாம்.
              </p>
            </li>
            <li>
              <p>
                குக்கீகள் உங்கள் வன்வட்டில் சேமிக்கப்பட்ட சில தகவல்களை பதிவு
                செய்கின்றன. இந்தத் தகவல், எங்கள் தளத்தைப்
                பார்வையிடுபவர்களிடமிருந்து தரவின் அடிப்படையில் புள்ளிவிவரங்களை
                உருவாக்கவும், நீங்கள் தளத்திற்கு முன்பு சென்றபோது நீங்கள்
                தேர்ந்தெடுத்த உருப்படிகளின்படி உங்களுக்கு விருப்பமான
                தயாரிப்புகள் மற்றும் சேவைகளைப் பரிந்துரைக்கவும் எங்களுக்கு
                உதவுகிறது. குக்கீகளில் உங்களைப் பற்றிய எந்த ரகசியத் தகவலும்
                இல்லை.
              </p>
            </li>
            <li>
              <p>
                குறிப்பிட்ட சேவைகளை அணுகுவதற்கு நீங்கள் தரவை வழங்க பிளாட்ஃபார்ம்
                தேவைப்படலாம். உங்கள் ஆர்டருக்குத் தொடர்புடைய விவரங்கள் உட்பட,
                ஆனால் அவை மட்டுப்படுத்தப்படாமல், அத்தகைய விஷயங்களுக்குத்
                தொடர்புடையதாக இருக்கலாம். அத்தகைய தகவலின் தனியுரிமை எங்கள்
                விதிமுறைகளின் மூலக்கல்லாகும், இருப்பினும், எங்கள் தனியுரிமைக்
                கொள்கையின் விதிமுறைகளுக்கு (இதில் அமைந்துள்ளது (இணைப்பு
                வழங்கப்பட வேண்டும்{' '}
                <a href={`${APP_DOMAIN}legal/privacy-statement`}>
                  {APP_DOMAIN}legal/privacy-statement
                </a>
                ) உட்பட்டு நாங்கள் தக்கவைத்து பயன்படுத்தலாம் என்பதை நீங்கள்
                ஒப்புக்கொள்கிறீர்கள், ஏற்கிறீர்கள் மற்றும் சம்மதிக்கிறீர்கள்,
                எங்கள் வணிகம், ஆராய்ச்சி மற்றும் பகுப்பாய்வுக்கான தயாரிப்புகள்
                மற்றும் சேவைகளின் உங்கள் பயன்பாட்டில் சேகரிக்கப்பட்ட தகவல்
                அல்லது தரவு.
              </p>
            </li>
            <li>
              <p>
                இந்த விதிமுறைகளுக்கு இணங்க உங்கள் தரவு அல்லது மூன்றாம்
                தரப்பினரின் வாடிக்கையாளர் தரவை நாங்கள் எந்த மூன்றாம்
                தரப்பினருடனும் பகிர்ந்து கொள்ள மாட்டோம், (i) இது சட்டத்தால் தேவை
                என்று நாங்கள் முடிவு செய்தால் அல்லது தரவை அணுகுவது, பாதுகாத்தல்
                அல்லது வெளிப்படுத்துவது என்பதில் நல்ல நம்பிக்கை இருந்தால் வரை.
                எங்கள் உரிமைகள், சொத்து அல்லது பாதுகாப்பு அல்லது எங்கள் பயனர்கள்
                அல்லது பொதுமக்களின் உரிமைகளைப் பாதுகாக்க நியாயமான தேவை; அல்லது
                (ii) எங்கள் சார்பாக (எ.கா., பில்லிங் அல்லது தரவு சேமிப்பு)
                பணிகளைச் செய்ய மூன்றாம் தரப்பினருக்கு சில வரையறுக்கப்பட்ட
                சூழ்நிலைகளில் தரவை வழங்குதல், எங்களால் இயக்கப்பட்டவை தவிர தரவு
                பயன்படுத்தப்படுவதை அல்லது பகிரப்படுவதைத் தடுக்கும் கடுமையான
                கட்டுப்பாடுகளுடன்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>அறிவுசார் சொத்து</h3>
          <ol>
            <li>
              <p>
                எங்கள் பிளாட்ஃபார்மில் உள்ள அனைத்து உள்ளடக்கமும்
                (விளக்கப்படங்கள், உரைகள், பெயர்கள், பிராண்ட் பெயர்கள், படங்கள்
                மற்றும் வீடியோக்கள்) COVESTRO இன் சொத்து.
              </p>
            </li>
            <li>
              <p>
                இந்த உள்ளடக்கத்தின் எந்தவொரு பகுதி அல்லது மொத்த மறுஉருவாக்கம்,
                எந்த வகையிலும் எந்த ஆதரவிலும், COVESTRO இன் முன் மற்றும்
                வெளிப்படையான அங்கீகாரத்திற்கு உட்பட்டது.
              </p>
            </li>
            <li>
              <p>
                அறிவுசார் உரிமை மற்றும் பதிப்புரிமை தொடர்பான சட்டத்தால் அனைத்து
                தகவல்களும், உள்ளடக்கமும் மற்றும் கோப்புகளும்
                பாதுகாக்கப்படுகின்றன.
              </p>
            </li>
            <li>
              <p>
                உங்களுக்கு அறிவுசார் உரிமைகள் இல்லாத உள்ளடக்கத்தை நகலெடுக்கவோ,
                காட்சிப்படுத்தவோ அல்லது விநியோகிப்பதற்கான உரிமையை நாங்கள்
                உங்களுக்கு வழங்க மாட்டோம்.
              </p>
            </li>
            <li>
              <p>
                இந்த உள்ளடக்கத்தின் அனைத்து மோசடியான பயன்பாடும் மூன்றாம்
                தரப்பினரின் உரிமைகளை மீறும், இது போலியான குற்றமாகும், இது
                அறிவுசார் சொத்து சட்டங்களின் கீழ் கடுமையாக தண்டிக்கப்படும்.
              </p>
            </li>
            <li>
              <p>
                தளத்தில் தனது செயல்பாடுகள் மூலம் மூன்றாம் தரப்பினரின் உரிமைகளை
                பயனர் மீறினால், எந்த சூழ்நிலையிலும் நாங்கள் பொறுப்பேற்க
                மாட்டோம்.
              </p>
            </li>
            <li>
              <p>
                இந்த தளத்திலிருந்து பெறப்பட்ட தரவு, படங்கள் மற்றும்
                பதிப்புரிமைச் சட்டங்களில் குறிப்பிடப்பட்டுள்ள அல்லது
                வெளிப்படையாகப் பயன்படுத்தப்படும் தனிப்பட்ட பயன்பாட்டினைத் தவிர
                வேறு எந்த நோக்கத்திற்காகவும் பெறப்பட்ட உள்ளடக்கத்தின் அனைத்து
                அல்லது பகுதியையும் பயன்படுத்தவோ, மாற்றவோ, நகலெடுக்கவோ,
                விநியோகிக்கவோ அல்லது மாற்றவோ பயனர்கள் தடைசெய்யப்பட்டுள்ளனர்.
                நிறுவனம் அல்லது கூறப்பட்ட தரவு, படங்கள் மற்றும்
                உள்ளடக்கத்திற்கான உரிமைகளை வைத்திருப்பவர்களின் அனுமதியின்றி, பிற
                சட்டங்களால் அனுமதிக்கப்படுகிறது.
              </p>
            </li>
            <li>
              <p>
                COVESTRO பிளாட்ஃபார்மில் இடுகையிடப்பட்ட அல்லது அனுப்பப்பட்ட
                பொருட்கள் அல்லது தளத்தில் விளம்பரப்படுத்தப்பட்ட பொருட்கள், இறுதி
                பயனர்கள் அல்லது வேறு எந்த மூன்றாம் தரப்பினராலும் எழும் அறிவுசார்
                சொத்துரிமை மீறலுக்கு பொறுப்பாகாது.
              </p>
              <ol>
                <li>
                  <p>
                    நீங்கள் அறிவுசார் சொத்துரிமைகளின் உரிமையாளராகவோ அல்லது
                    அறிவுசார் சொத்துரிமைகளின் உரிமையாளரின் சார்பாகச் செயல்பட
                    முழு அங்கீகாரம் பெற்ற முகவராகவோ இருந்தால், ஏதேனும்
                    உள்ளடக்கம் அல்லது பிற உள்ளடக்கம் உங்கள் அறிவுசார் சொத்துரிமை
                    அல்லது அறிவுசார் சொத்துரிமையை மீறுவதாக நம்பினால் யாருடைய
                    சார்பாக நீங்கள் செயல்பட அங்கீகரிக்கப்பட்டுள்ளீர்களோ, அந்த
                    மின்னஞ்சல் ஐடிக்கு நல்ல நம்பிக்கையுடன் தொடர்புடைய
                    உள்ளடக்கத்தை நீக்குவதற்கான கோரிக்கையுடன் COVESTRO க்கு
                    மின்னஞ்சலைச் சமர்ப்பிக்கலாம்:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>
                    .
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>உத்தரவாதங்களின் பொறுப்புத்திறப்பு</h3>
          <ol>
            <li>
              <p>
                COVESTRO எந்த நேரத்திலும், அறிவிப்புடன் அல்லது இல்லாமல்
                தயாரிப்புகள் மற்றும் சேவைகள் அல்லது மென்பொருளில் மேம்பாடுகள்
                மற்றும்/அல்லது மாற்றங்களைச் செய்யலாம். சேவை, மென்பொருள் மற்றும்
                பயிற்சிகள் தொடர்பான அறிக்கைகள் &quot;உள்ளபடியே&quot;
                வழங்கப்படுகின்றன, மேலும் COVESTRO அவை எதற்கும் உத்தரவாதம்
                அளிக்காது. மேற்கூறியவற்றின் பொதுவான தன்மையைக் கட்டுப்படுத்தாமல்,
                COVESTRO இதைப் பிரதிநிதித்துவப்படுத்தாது அல்லது உத்தரவாதம்
                அளிக்காது:
                <br />
                a) தயாரிப்புகள் மற்றும் சேவைகள் அல்லது மென்பொருளின் பயன்பாடு
                உங்கள் தேவைகளை பூர்த்தி செய்யும் அல்லது தடையின்றி, பாதுகாப்பான
                அல்லது பிழையின்றி இருக்கும்;
                <br />
                b) குறைபாடுகள் சரி செய்யப்படும்;
                <br />
                c) சேவையகத்தில் உள்ள சேவை, மென்பொருள் அல்லது வேறு ஏதேனும்
                மென்பொருள் வைரஸ்கள் அல்லது பிற தீங்கு விளைவிக்கும் கூறுகள்
                இல்லாதவை;
                <br />
                d) தயாரிப்புகள் மற்றும் சேவைகளின் பயன்பாடு மற்றும் தயாரிப்புகள்
                மற்றும் சேவைகள் மூலம் கிடைக்கும் எந்த தகவலும் (பயிற்சிகள்
                தொடர்பான அறிக்கைகள் உட்பட) சரியானதாக, துல்லியமாக, சரியான
                நேரத்தில் அல்லது நம்பகமானதாக இருக்கும்.
              </p>
            </li>
            <li>
              <p>
                உங்கள் தனிப்பட்ட தகவல் மற்றும் பயனர் உள்ளடக்கத்தை தற்செயலான
                இழப்பு மற்றும் அங்கீகரிக்கப்படாத அணுகல், பயன்பாடு, மாற்றம்
                அல்லது வெளிப்படுத்தல் ஆகியவற்றிலிருந்து பாதுகாக்க
                வடிவமைக்கப்பட்ட வணிக ரீதியாக நியாயமான தொழில்நுட்ப மற்றும் நிறுவன
                நடவடிக்கைகளை நாங்கள் செயல்படுத்தியுள்ளோம். இருப்பினும்,
                அங்கீகரிக்கப்படாத மூன்றாம் தரப்பினரால் ஒருபோதும் அந்த
                நடவடிக்கைகளைத் தோற்கடிக்க முடியாது அல்லது உங்கள் தனிப்பட்ட தகவல்
                மற்றும் பயனர் உள்ளடக்கத்தை முறையற்ற நோக்கங்களுக்காகப் பயன்படுத்த
                முடியாது என்று நாங்கள் உத்தரவாதம் அளிக்க முடியாது. உங்கள்
                தனிப்பட்ட தகவலை உங்கள் சொந்த ஆபத்தில் வழங்குகிறீர்கள் என்பதை
                ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகள் அல்லது பிளாட்ஃபார்மில் உள்ள
                விற்பனையாளர்களின் விளம்பரதாரர்கள், தொடர்புடைய பொருட்கள் அல்லது
                சேவைகள் மற்றும் அத்தகைய பரிவர்த்தனைகளுடன் தொடர்புடைய வேறு
                ஏதேனும் விதிமுறைகள், நிபந்தனைகள், உத்தரவாதங்கள் அல்லது
                பிரதிநிதித்துவங்கள் உட்பட, உங்கள் கடிதப் பரிமாற்றங்கள் அல்லது
                விளம்பரங்களில் பங்கேற்பது , உங்களுக்கும் அத்தகைய விளம்பரதாரர்
                அல்லது விற்பனையாளருக்கும் இடையே மட்டுமே. இங்கு
                குறிப்பிடப்பட்டுள்ளதைத் தவிர, அத்தகைய பரிவர்த்தனைகளின் விளைவாக
                அல்லது அத்தகைய விளம்பரதாரர்கள் அல்லது தயாரிப்புகள் மற்றும்
                விற்பனையாளர்களின் இருப்பின் விளைவாக ஏற்படும் எந்தவொரு இழப்பு
                அல்லது சேதத்திற்கும் COVESTRO பொறுப்பாகாது அல்லது பொறுப்பாகாது
                என்பதை ஒப்புக்கொள்கிறீர்கள். சேவைகள் / சந்தை.
              </p>
            </li>
            <li>
              <p>
                பிற இணையதளங்கள் அல்லது ஆதாரங்களுக்கான இணைப்புகளை தயாரிப்புகள்
                மற்றும் சேவைகள் வழங்கலாம் அல்லது மூன்றாம் தரப்பினர் வழங்கலாம்.
                அத்தகைய தளங்கள் மற்றும் ஆதாரங்களின் மீது எங்களுக்கு எந்தக்
                கட்டுப்பாடும் இல்லை என்பதால், அத்தகைய வெளிப்புற தளங்கள் அல்லது
                ஆதாரங்கள் கிடைப்பதற்கு நாங்கள் பொறுப்பல்ல என்பதை நீங்கள்
                ஒப்புக்கொள்கிறீர்கள், ஒப்புக்கொள்கிறீர்கள், மேலும் எந்தவொரு
                உள்ளடக்கம், விளம்பரம், தயாரிப்புகள் மற்றும் சேவைகளுக்குப்
                பொறுப்பேற்கவோ அல்லது பொறுப்பாகவோ இல்லை. அல்லது அத்தகைய தளங்கள்
                அல்லது ஆதாரங்களில் உள்ள அல்லது கிடைக்கும் பிற பொருட்கள். மேலும்,
                அத்தகைய உள்ளடக்கம், பொருட்கள் அல்லது சேவைகள் அல்லது அதன் மூலம்
                கிடைக்கப்பெறும் எந்த ஒரு உள்ளடக்கம், பொருட்கள் அல்லது சேவைகளைப்
                பயன்படுத்துதல் அல்லது நம்பியிருப்பதால் ஏற்படும் அல்லது
                ஏற்பட்டதாகக் கூறப்படும் ஏதேனும் சேதம் அல்லது இழப்புகளுக்கு
                நேரடியாகவோ அல்லது மறைமுகமாகவோ நாங்கள் பொறுப்பாகவோ அல்லது
                பொறுப்பாகவோ மாட்டோம் என்பதை நீங்கள் மேலும் ஒப்புக்கொள்கிறீர்கள்.
                அத்தகைய தளம் அல்லது ஆதாரம்.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்ம் மற்றும் தயாரிப்புகள் மற்றும் சேவைகளின் உங்கள்
                பயன்பாடு உங்கள் ஆபத்தில் இருக்கும் என்பதை ஒப்புக்கொள்கிறீர்கள்.
                பொருந்தக்கூடிய சட்டத்தால் அனுமதிக்கப்பட்ட அதிகபட்ச அளவிற்கு,
                பிளாட்ஃபார்ம் மற்றும் தயாரிப்புகள் மற்றும் சேவைகள் உங்கள்
                பயன்பாட்டிற்கான &quot;உள்ளது&quot; மற்றும்
                &quot;கிடைக்கும்&quot; அடிப்படையில், எந்தவிதமான, வெளிப்படையான
                அல்லது மறைமுகமான உத்தரவாதங்கள் இல்லாமல் வழங்கப்படுகின்றன என்பதை
                நீங்கள் தெளிவாகப் புரிந்துகொண்டு ஒப்புக்கொள்கிறீர்கள். ,
                வணிகத்திறன், ஒரு குறிப்பிட்ட நோக்கத்திற்கான தகுதி, தலைப்பு,
                மீறல் இல்லாதது மற்றும் வர்த்தகத்தை கையாளும் அல்லது
                பயன்படுத்துவதன் மூலம் எழும் உத்தரவாதங்கள் உட்பட.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்மில் உங்களால் இடுகையிடப்பட்ட அல்லது பதிவேற்றப்படும்
                எந்தவொரு பொருளும் அவர்களின் அறிவுசார் சொத்துரிமை அல்லது
                தனியுரிமைக்கான உரிமையை மீறுவதாக உள்ளதாக சரியான முறையில் உரிமை
                கோரும் மற்றும் ஆதாரங்களை வழங்கும் எந்தவொரு மூன்றாம்
                தரப்பினருக்கும் உங்கள் அடையாளத்தை வெளிப்படுத்த எங்களுக்கு உரிமை
                உள்ளது.
              </p>
            </li>
            <li>
              <p>
                நீங்கள் அல்லது தயாரிப்புகள் மற்றும் சேவைகளின் பிற பயனர்களால்
                இடுகையிடப்பட்ட எந்தவொரு பொருளின் உள்ளடக்கம் அல்லது
                துல்லியத்திற்கு நாங்கள் எந்த மூன்றாம் தரப்பினருக்கும் பொறுப்பல்ல
                அல்லது பொறுப்பல்ல.
              </p>
            </li>
            <li>
              <p>
                இந்த விதிமுறைகள் மற்றும் நிபந்தனைகள், இந்த ஒப்பந்தம் அல்லது
                தனியுரிமைக் கொள்கை ஆகியவற்றுக்கு இணங்காத பட்சத்தில், உங்களின்
                அணுகல் அல்லது பயன்பாட்டு உரிமைகளை உடனடியாக நிறுத்துவதற்கும்,
                இணக்கமற்ற தகவல் அல்லது உள்ளடக்கத்தை அகற்றுவதற்கும் எங்களுக்கு
                உரிமை உள்ளது.
              </p>
            </li>
            <li>
              <p>
                இந்த விதிமுறைகள் மற்றும் நிபந்தனைகளின் மீறல்களை சட்டத்தின் முழு
                அளவில் விசாரித்து, வழக்குத் தொடர எங்களுக்கு உரிமை உள்ளது.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகளுக்கான (அல்லது தயாரிப்புகள் மற்றும்
                சேவைகளின் ஏதேனும் அம்சம் அல்லது பகுதி) உங்கள் அணுகலை அல்லது
                உங்கள் பயன்பாட்டைக் கண்காணிக்க எங்களுக்கு எந்தக் கடமையும் இல்லை
                என்றாலும், தளத்தை இயக்குவதற்கும் தயாரிப்புகளை வழங்குவதற்கும்
                அவ்வாறு செய்ய எங்களுக்கு உரிமை உள்ளது. சேவைகள், இந்த விதிமுறைகள்
                மற்றும் நிபந்தனைகளுடன் நீங்கள் இணங்குவதை உறுதிசெய்ய அல்லது
                பொருந்தக்கூடிய சட்டம் அல்லது நீதிமன்றம், நிர்வாக நிறுவனம் அல்லது
                பிற அரசாங்க அமைப்பின் உத்தரவு அல்லது தேவைகளுக்கு இணங்க.
              </p>
            </li>
            <li>
              <p>
                பயனரிடமிருந்து ஒரு புகாரைப் பெறுவதற்கு உட்பட்டு, எந்தவொரு
                சேவையிலிருந்தும் ஏதேனும் அல்லது அனைத்து உள்ளடக்கத்தையும்
                முன்கூட்டியே திரையிட, மதிப்பாய்வு செய்ய, கொடியிட, வடிகட்ட,
                மாற்ற, மறுக்க அல்லது அகற்ற எங்களுக்கு உரிமை உள்ளது (ஆனால் எந்தக்
                கடமையும் இல்லை). சில தயாரிப்புகள் மற்றும் சேவைகளுக்கு, COVESTRO
                வெளிப்படையான பாலியல் உள்ளடக்கம் அல்லது அதன் சொந்த விருப்பப்படி
                பொருத்தமற்றதாகக் கருதும் எந்தவொரு உள்ளடக்கத்தையும்
                வடிகட்டுவதற்கான கருவிகளை வழங்கலாம் (ஆனால் எந்தக் கடமையும்
                இல்லை).
              </p>
            </li>
            <li>
              <p>
                எந்தவொரு தகவலையும் அணுகவும், படிக்கவும், பாதுகாக்கவும் மற்றும்
                வெளிப்படுத்தவும் எங்களுக்கு உரிமை உள்ளது: (அ) பொருந்தக்கூடிய
                ஏதேனும் சட்டம், ஒழுங்குமுறை, சட்ட செயல்முறை அல்லது அரசாங்க
                கோரிக்கையை திருப்திப்படுத்துதல்; (ஆ) இந்த விதிமுறைகள் மற்றும்
                நிபந்தனைகளைச் செயல்படுத்துதல், இதில் சாத்தியமான மீறல்கள் பற்றிய
                விசாரணை உட்பட; (c) மோசடி, பாதுகாப்பு அல்லது தொழில்நுட்ப
                சிக்கல்களைக் கண்டறிதல், தடுக்க அல்லது வேறுவிதமாக நிவர்த்தி
                செய்தல்; (ஈ) பயனர் ஆதரவு கோரிக்கைகளுக்கு பதிலளிக்கவும்; அல்லது
                (இ) COVESTRO, அதன் பயனர்கள் மற்றும் பொதுமக்களின் உரிமைகள்,
                சொத்து அல்லது பாதுகாப்பைப் பாதுகாத்தல். இது சம்பந்தமாக இந்த
                விதிமுறைகள் மற்றும் நிபந்தனைகளின் கீழ் அதன் உரிமைகளைப்
                பயன்படுத்துவதற்கு அல்லது பயன்படுத்தாததற்கு COVESTRO பொறுப்பாகவோ
                அல்லது பொறுப்பாகவோ இருக்காது.
              </p>
            </li>
            <li>
              <p>
                எங்களிடமிருந்து 30 (முப்பது) நாட்களுக்கு முன் அறிவிப்பு மூலம்
                அனைத்து சேவைகளின் விலைகளையும் அறிமுகப்படுத்த அல்லது
                மாற்றுவதற்கான உரிமையை நாங்கள் வைத்திருக்கிறோம். பயன்பாட்டு
                விதிமுறைகள் மற்றும் நிபந்தனைகள் அல்லது தயாரிப்புகள் மற்றும்
                சேவைகளின் மாற்றங்களை பிளாட்ஃபார்மிலேயே இடுகையிடுவதன் மூலம்
                அத்தகைய அறிவிப்பு எந்த நேரத்திலும் வழங்கப்படலாம்.
              </p>
            </li>
            <li>
              <p>
                தயாரிப்புகள் மற்றும் சேவைகளின் ஏதேனும் மாற்றம், விலை மாற்றம்,
                இடைநிறுத்தம் அல்லது நிறுத்தம் ஆகியவற்றிற்கு COVESTRO உங்களுக்கோ
                அல்லது மூன்றாம் தரப்பினருக்கோ பொறுப்பாகாது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>
            மூன்றாம் தரப்பினரால் பயனர் சமர்ப்பிப்பு மற்றும் பயனர் உள்ளடக்கம்
          </h3>
          <ol>
            <li>
              <p>
                பிளாட்ஃபார்மைப் பயன்படுத்தும் போது, பல்வேறு ஆதாரங்களில் இருந்து
                உள்ளடக்கத்தை நீங்கள் வெளிப்படுத்துவீர்கள் என்பதையும், அத்தகைய
                உள்ளடக்கத்தின் துல்லியம், பயன், பாதுகாப்பு அல்லது அறிவுசார்
                சொத்துரிமை அல்லது அது தொடர்பான அறிவுசார் சொத்துரிமைகளுக்கு
                COVESTRO பொறுப்பேற்காது என்பதையும் நீங்கள்
                புரிந்துகொள்கிறீர்கள், மேலும் நீங்கள் அனைத்தையும்
                ஒப்புக்கொள்கிறீர்கள். உங்கள் பயன்பாட்டிற்கான பொறுப்பு.
              </p>
            </li>
            <li>
              <p>
                கவலைகளைக் குறைப்பதற்கு COVESTRO சிறந்த முயற்சிகளை மேற்கொள்ளும்
                அதே வேளையில், தவறான, புண்படுத்தும், அநாகரீகமான அல்லது
                ஆட்சேபனைக்குரிய, அவதூறான அல்லது அவதூறான உள்ளடக்கத்திற்கு நீங்கள்
                வெளிப்படலாம் என்பதை நீங்கள் புரிந்துகொண்டு ஒப்புக்கொள்கிறீர்கள்,
                மேலும் நீங்கள் தள்ளுபடி செய்ய ஒப்புக்கொள்கிறீர்கள் COVESTRO
                விற்கு எதிராக நீங்கள் வைத்திருக்கும் சட்ட அல்லது சமமான உரிமைகள்
                அல்லது தீர்வுகள்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>விட்டுக்கொடுப்பு மற்றும் பிரித்தல்</h3>
          <ol>
            <li>
              <p>
                COVESTRO வின் எந்தவொரு உரிமையையும் அல்லது சேவை விதிமுறைகளின்
                விதிமுறைகளையும் செயல்படுத்த அல்லது செயல்படுத்துவதில் எந்தத்
                தோல்வியும் அத்தகைய உரிமை அல்லது விதியை தள்ளுபடி செய்வதாக
                இருக்காது.
              </p>
            </li>
            <li>
              <p>
                சேவை விதிமுறைகள் உங்களுக்கும் எங்களுக்கும் இடையேயான முழு
                ஒப்பந்தத்தையும் உருவாக்குகிறது, மேலும் எந்தவொரு முந்தைய
                ஒப்பந்தங்களையும் (சேவை விதிமுறைகளின் முந்தைய பதிப்புகள் உட்பட,
                ஆனால் அவை மட்டும் அல்ல) முறியடித்து, சேவையின் உங்கள் பயன்பாட்டை
                நிர்வகிக்கிறது. எந்தவொரு காரணத்திற்காகவும் இந்த சேவை
                விதிமுறைகளின் எந்தவொரு விதிமுறைகளும் செல்லுபடியற்றதாகவோ,
                சட்டவிரோதமாகவோ அல்லது செயல்படுத்த முடியாததாகவோ தகுதியுள்ள
                நீதிமன்றத்தால் நடத்தப்பட்டால், அத்தகைய விதிமுறைகள் நீக்கப்படும்
                அல்லது குறைந்தபட்ச அளவிற்கு வரையறுக்கப்படும், அதாவது மீதமுள்ள
                சேவை விதிமுறைகள் தொடரும். முழு சக்தியிலும் விளைவுகளிலும்.
              </p>
            </li>
            <li>
              <p>
                பிளாட்ஃபார்ம் அல்லது சேவையின் மூலம் எந்தவொரு நிறுவனங்கள்
                மற்றும்/அல்லது தனிநபர்களுடனான உங்கள் தொடர்புகளுக்கு COVESTRO
                பொறுப்பேற்காது என்பதை நீங்கள் வெளிப்படையாக ஒப்புக்கொண்டு
                ஒப்புக்கொள்கிறீர்கள். பொருட்கள் மற்றும் சேவைகளை செலுத்துதல்
                மற்றும் வழங்குதல் மற்றும் பிற நிறுவனங்கள் மற்றும்/அல்லது
                தனிநபர்களுடன் நீங்கள் மேற்கொள்ளும் எந்தவொரு தொடர்புடன்
                தொடர்புடைய பிற விதிமுறைகள், நிபந்தனைகள், உத்தரவாதங்கள் அல்லது
                பிரதிநிதித்துவங்கள் ஆகியவை இதில் அடங்கும், ஆனால் அவை மட்டும்
                அல்ல. இந்த பரிவர்த்தனைகள் உங்களுக்கும் அத்தகைய நிறுவனங்கள்
                மற்றும்/அல்லது தனிநபர்களுக்கும் இடையே மட்டுமே.
              </p>
            </li>
            <li>
              <p>
                அத்தகைய பரிவர்த்தனைகள் அல்லது தொடர்புகளின் விளைவாக ஏற்படும்
                எந்தவொரு இழப்பு அல்லது சேதத்திற்கும் COVESTRO பொறுப்பு அல்லது
                பொறுப்பாகாது என்பதை நீங்கள் ஒப்புக்கொள்கிறீர்கள் மற்றும்
                ஒப்புக்கொள்கிறீர்கள். பிளாட்ஃபார்மில் பங்கேற்பாளர்களுக்கிடையில்
                அல்லது பயனர்கள் மற்றும் மூன்றாம் தரப்பினருக்கு இடையே ஏதேனும்
                தகராறு இருந்தால், அத்தகைய சர்ச்சையில் ஈடுபடுவதற்கு COVESTRO
                எந்தக் கடமையும் இல்லை என்பதை நீங்கள் புரிந்துகொண்டு
                ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
            <li>
              <p>
                ஒன்று அல்லது அதற்கு மேற்பட்ட பயனர்களுடன் உங்களுக்கு தகராறு
                ஏற்பட்டால், நீங்கள் COVESTRO, அதன் அதிகாரிகள், ஊழியர்கள்,
                முகவர்கள் மற்றும் வாரிசுகளை எந்தவொரு மற்றும் அனைத்து
                உரிமைகோரல்கள், கோரிக்கைகள் மற்றும் சேதங்கள் (உண்மையான மற்றும்
                விளைவு) எல்லா வகையான அல்லது இயல்புகளிலிருந்தும்
                விடுவிக்கிறீர்கள். அறியப்பட்ட அல்லது அறியப்படாத,
                சந்தேகத்திற்குரிய மற்றும் சந்தேகத்திற்கு இடமில்லாத,
                வெளிப்படுத்தப்பட்ட மற்றும் வெளிப்படுத்தப்படாத, அத்தகைய தகராறுகள்
                மற்றும்/அல்லது எங்கள் சேவையுடன் தொடர்புடைய அல்லது எந்த வகையிலும்
                எழுகிறது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>மொபைல் சாதனங்களின் பயன்பாடு</h3>
          <ol>
            <li>
              <p>
                நீங்கள் மொபைல் சாதனத்தில் ஆப்ஸைப் பயன்படுத்தினால், உங்கள்
                கேரியரின் வழக்கமான கட்டணங்கள் மற்றும் குறுஞ்செய்தி அனுப்புதல்
                மற்றும் தரவுக் கட்டணங்கள் போன்ற கட்டணங்கள் தொடர்ந்து பொருந்தும்
                என்பதை நினைவில் கொள்ளவும்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>தடைசெய்யப்பட்ட நடவடிக்கைகள்</h3>
          <ol>
            <li>
              <p>
                இந்த தளத்தின் பயனர்கள் பின்வரும் செயல்களில் ஈடுபடுவது
                தடைசெய்யப்பட்டுள்ளது, இந்த விதிமுறைகள் மற்றும் நிபந்தனைகளின் பிற
                விதிகளால் தடைசெய்யப்பட்டவை உட்பட:
              </p>
              <ul>
                - இந்த தளத்தின் பிற பயனர்கள், மூன்றாம் தரப்பினர், நிறுவனம்
                அல்லது அதன் கூட்டாளர் நிறுவனங்களின் பதிப்புரிமை, தனியுரிமை,
                சொத்து மற்றும் பிற உரிமைகள் அல்லது நலன்களை மீறும் அல்லது
                மீறக்கூடிய செயல்கள்.
              </ul>
              <ul>
                - இந்த தளத்தின் பிற பயனர்கள், மூன்றாம் தரப்பினர், நிறுவனம்
                அல்லது அதன் கூட்டாளர் நிறுவனங்களுக்கு பாதகம், நிதி இழப்பு அல்லது
                பிற சேதங்களை ஏற்படுத்தும் அல்லது ஏற்படுத்தக்கூடிய செயல்கள்.
              </ul>
              <ul>
                - பொது ஒழுங்கு மற்றும் ஒழுக்கத்தை புண்படுத்தும் அல்லது
                புண்படுத்தும் அல்லது சட்டங்கள், ஒழுங்குமுறைகள் மற்றும்
                ஒழுங்குமுறைகளை மீறும் செயல்கள்.
              </ul>
              <ul>
                - உறுப்பினர் பதிவு அல்லது பிற நோக்கங்களுக்காக இந்த தளத்தைப்
                பயன்படுத்தும் போது தவறான அறிவிப்புகள்.
              </ul>
              <ul>
                - மின்னஞ்சல் முகவரிகள், கடவுச்சொற்கள் அல்லது பிற தனிப்பட்ட
                தகவல்களை சட்டவிரோதமாகப் பயன்படுத்துதல்.
              </ul>
              <ul>
                - வணிகத்தைக் கோருவதற்கு அல்லது பிற வணிக நோக்கங்களை லாபத்திற்காக
                அல்லது அதற்கான தயாரிப்புகளுக்காக இந்த தளத்தைப் பயன்படுத்துதல்.
              </ul>
              <ul>
                - இந்த பிளாட்ஃபார்மின் செயல்பாட்டில் குறுக்கிடக்கூடிய அல்லது
                சேவைகளை வழங்குவதில் குறுக்கிடக்கூடிய பிற செயல்கள்.
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>ஆளும் சட்டங்கள்</h3>
          <ol>
            <li>
              <p>
                இந்த விதிமுறைகள் மற்றும் பயன்பாட்டு நிபந்தனைகளின் உருவாக்கம்,
                சட்ட பலம், பயன்பாடு மற்றும் விளக்கம் ஆகியவை இந்திய சட்டங்களால்
                நிர்வகிக்கப்படும்.
              </p>
            </li>
            <li>
              <p>
                COVESTRO இந்தியாவைத் தவிர வேறு எந்த நாட்டின் சட்டங்களுக்கும்
                இணங்காததற்கு நேரடியாகவோ அல்லது மறைமுகமாகவோ எந்தப் பொறுப்பையும்
                ஏற்காது, தளத்தை அணுகலாம் அல்லது பயன்படுத்தலாம் அல்லது எந்த
                வசதியையும் பெறலாம். இந்தியாவைத் தவிர வேறு ஒரு நாட்டில், அத்தகைய
                நாட்டின் சட்டங்களை நாங்கள் ஏற்றுக்கொள்கிறோம் என்பதைக்
                குறிக்காது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>அதிகார வரம்பு</h3>
          <ol>
            <li>
              <p>
                இந்த பிளாட்ஃபார்மை பயன்படுத்துவதில் பயனருக்கும் நிறுவனம் அல்லது
                கூட்டாளர் நிறுவனத்திற்கும் இடையே உள்ள சர்ச்சைகள் மகாராஷ்டிராவின்
                மும்பை நீதிமன்றங்களில் பிரத்தியேகமாக விசாரிக்கப்படும்.
              </p>
            </li>
            <li>
              <p>
                மேற்கூறியவற்றைப் பொருட்படுத்தாமல், தகுதிவாய்ந்த அதிகார
                வரம்பிற்குட்பட்ட எந்தவொரு நீதிமன்றத்திலும் COVESTRO தடை நிவாரணம்
                (அல்லது அதற்கு சமமான அவசர சட்ட நிவாரணம்) பெற உரிமை உண்டு என்பதை
                ஒப்புக்கொள்கிறீர்கள்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>விதிமுறைகள் மற்றும் மேடையில் மாற்றம்</h3>
          <ol>
            <li>
              <p>
                பிளாட்ஃபார்ம் ஒரு தொடர்ச்சியான வேலையாக உள்ளது, மேலும் இது
                சேர்த்தல் அல்லது நீக்குதல் அல்லது மாற்றீடுகள் அல்லது வேறு
                வழிகளில் மாற்றத்திற்கு உட்பட்டது.
              </p>
            </li>
            <li>
              <p>
                இணையதளம், ஆப்ஸ் மற்றும் இந்த விதிமுறைகளை அவ்வப்போது எங்கள்
                விருப்பப்படி எந்த அறிவிப்பும் இன்றி புதுப்பிக்கும் உரிமை
                எங்களுக்கு உள்ளது.
              </p>
            </li>
            <li>
              <p>
                ஒவ்வொரு முறையும் பிளாட்ஃபார்ம் அல்லது சேவைகளை அணுகும் போது,
                சமீபத்திய விதிமுறைகளைப் படிப்பதன் மூலம் அவற்றைப் பற்றி அறிந்து
                கொள்வது வாடிக்கையாளரின் முழுப் பொறுப்பும் கடமையும் ஆகும்.
              </p>
            </li>
            <li>
              <p>
                புதுப்பிக்கப்பட்ட சேவை விதிமுறைகளை வெளியிட்ட பிறகு பிளாட்ஃபார்மை
                தொடர்ந்து பயன்படுத்தினால், பயனர் மாற்றியமைக்கப்பட்ட
                விதிமுறைகளைப் படித்து ஒப்புக்கொண்டதாகக் கருதப்படும்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>உங்கள் சொந்த ஆபத்தில் பயன்படுத்தவும்</h3>
          <ol>
            <li>
              <p>
                உணவுப் பாதுகாப்பிற்கு உதவவும், தூண்டவும் மற்றும்
                உறுதிப்படுத்தவும் அசல், ஆராய்ச்சி அடிப்படையிலான தீர்வுகளை
                உருவாக்குவதன் மூலம் சந்தை சேவைகளை வழங்குவதே எங்கள் குறிக்கோள்.
                இருப்பினும், எந்த மூன்றாம் தரப்பு விற்பனையாளர்களின் தரத்திற்கும்
                தளம் உத்தரவாதம் அளிக்க முடியாது மற்றும் உத்தரவாதம் அளிக்காது.
                உங்கள் பிளாட்ஃபார்மைப் பயன்படுத்துதல் மற்றும் பிளாட்ஃபார்மில்
                வழங்கப்பட்ட எந்தத் தகவல், கணிப்புகள் அல்லது பரிந்துரைகளும்
                உங்கள் முழு திருப்தியில் உள்ளன. பிளாட்ஃபார்ம் மூலம் நாங்கள்
                உங்களுக்கு வழங்கக்கூடிய தரம், அளவு, தரவு, தகவல், மதிப்பீடுகள்
                மற்றும் கணிப்புகளின் துல்லியம் குறித்து நாங்கள் எந்த விதமான
                பிரதிநிதித்துவம் அல்லது உத்தரவாதம் அளிக்கவில்லை. சந்தை இடம்,
                மற்றும் வாங்குபவரின் ஆபத்துக்கு உட்பட்டது.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>கருத்துகள் மற்றும் கருத்து</h3>
          <ol>
            <li>
              <p>
                COVESTRO க்கு பிளாட்ஃபார்மில் நீங்கள் கொடுக்கும் எந்தக்
                கருத்தும் ஸ்கிரீனிங் மற்றும் நிதானத்திற்கு உட்பட்டது.
              </p>
            </li>
            <li>
              <p>
                கருத்துகள் சட்டத்தை மீறுவதாகவோ அல்லது பொருத்தமற்றதாகவோ இருந்தால்
                (தவறான விளம்பரம், அவதூறு, அவமதிப்பு, சூழலுக்கு அப்பாற்பட்ட
                வர்ணனை), அதை மறுப்பதற்கு அல்லது திருத்துவதற்கும், தகுந்த
                நடவடிக்கை எடுப்பதற்கும் எங்களுக்கு உரிமை உண்டு.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TNCTA;
