/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { IonCheckbox, IonLabel } from '@ionic/react';
import { t } from '@lingui/macro';

interface Props {
  label?: string;
  onClick?: any;
  isChecked?: boolean;
  textKey?: any;
  customClass?: string;
  onchange?: (ischecked: boolean, value: string) => void;
  hideLabel?: boolean;
}

const CheckBox: React.FC<Props> = ({
  label,
  onClick,
  isChecked,
  textKey,
  customClass,
  onchange,
  hideLabel,
}) => {
  const labelText: any = label;
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className={`checkbox-wrapper ${customClass || ''}`}>
      <IonCheckbox
        slot='start'
        className='checkbox'
        onClick={onClick}
        checked={checked}
        onIonChange={(e) => {
          setChecked(e.detail.checked);
          onchange && onchange(e.detail.checked, labelText);
        }}
      ></IonCheckbox>
      {!hideLabel && (
        <IonLabel className='ion-label'>
          {t({ id: textKey, message: labelText })}
        </IonLabel>
      )}
    </div>
  );
};

export default CheckBox;
