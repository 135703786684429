import { IonPage } from '@ionic/react';

import Layout from '../../components/layouts';
import HomeIcon from '../../assets/images/icons/home-icon.png';
import { IonImg } from '@ionic/react';
import PrivateRoute from '../../components/privateroute';
import './style.scss';
import pageBanner from '../../assets/images/banner-aboutcovestro.jpg';
import pageBannerVideo from '../../assets/images/banner-aboutib.jpg';
import iconPlay from '../../assets/images/icon-play.png';
const FullImage: React.FC = () => {
  
  return (
    <IonPage className='page-notifications'>
      <Layout>

      <div className="page-menu-list p-32 mb-24"><div className="page-menu-list-inner"><div className="breadcrumb__menu-list-wrapper "><IonImg
        className='breadcrumb__menu-list-home-icon mr-16'
        src={HomeIcon} ></IonImg><div className="breadcrumb__menu pt-3 font-aktiv-grotest-medium ml-16 mr-16">Full Image - Video</div></div></div></div>

      <div className="buy__cl-card-list-wrapper small-container">
        <div className="cd-card-wrapper p-16 ">
        <div className='page-banner__sec'>
          <IonImg className='page-banner' src={pageBanner}></IonImg>
          <div className='page-banner__copy pt-20'>
            <h1 className='mb-10'>Lorem Ipsum</h1>
            <p className='mb-10'>Maecenas non tortor sed metus euismod venenatis quis non libero. Cras facilisis risus non ipsum fermentum, vitae condimentum dolor laoreet. Nam malesuada ligula sed ullamcorper pharetra. Quisque eu dictum odio. Phasellus ut mi ut eros dictum sagittis. Quisque sit amet malesuada neque.</p>
          </div>
          </div>
          </div>
        </div>
        <div className='pt-40'></div>
      <div className="buy__cl-card-list-wrapper small-container">
        <div className="cd-card-wrapper p-16 ">
        <div className='page-banner__sec'>
<div className='page-banner__img'>
          <IonImg className='page-banner' src={pageBannerVideo}></IonImg>
          <IonImg className='page-banner__icon' src={iconPlay}></IonImg>
          </div>
          <div className='page-banner__copy pt-20'>
            <h1 className='mb-10'>Lorem Ipsum</h1>
            <p className='mb-10'>Maecenas non tortor sed metus euismod venenatis quis non libero. Cras facilisis risus non ipsum fermentum, vitae condimentum dolor laoreet. Nam malesuada ligula sed ullamcorper pharetra. Quisque eu dictum odio. Phasellus ut mi ut eros dictum sagittis. Quisque sit amet malesuada neque.</p>
          </div>
          </div>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(FullImage);
