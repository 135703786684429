import { APP_DOMAIN } from '../../../config/appconfig';

const TNCMA: React.FC = () => {
  //   const tableCotent = [
  //     {
  //       title: 'ACCEPTANCE OF THE TERMS AND CONDITIONS OF ACCESS AND USAGE',
  //       page: '5',
  //     },
  //     {
  //       title: 'THIRD-PARTY SERVICES',
  //       page: '6',
  //     },
  //     {
  //       title: 'ACCESS AND USE',
  //       page: '6',
  //     },
  //     {
  //       title: 'MEMBERSHIP/REGISTRATION AND ELIGIBILITY',
  //       page: '7',
  //     },
  //     { title: 'TERMS OF USE OF THE PLATFORM', page: '9' },
  //     { title: 'RESTRICTION ON USE OF SERVICES', page: '10' },
  //     { title: 'CONTENT', page: '11' },
  //     { title: 'LIMITED LICENSE TO THE APP', page: '12' },
  //     { title: 'BREACH OF CONTENT STANDARDS', page: '13' },
  //     { title: 'BILLING AND PAYMENT', page: '14' },
  //     { title: 'INDEMNITY AND LIMITATION OF LIABILITY', page: '14' },
  //     { title: 'OFFENCES', page: '16' },
  //     { title: 'ADDITIONAL TERMS FOR THE MOBILE APP', page: '17' },
  //     { title: 'AUTHORISED ACCESS', page: '17' },
  //     { title: 'CONFIDENTIALITY', page: '19' },
  //     { title: 'PRIVACY AND PROTECTION OF PERSONAL DATA', page: '19' },
  //     { title: 'INTELLECTUAL PROPERTY', page: '20' },
  //     { title: 'DISCLAIMER OF WARRANTIES', page: '21' },
  //     { title: 'USER SUBMISSION AND USER CONTENT BY THIRD PARTIES', page: '23' },
  //     { title: 'WAIVER AND SEVERABILITY', page: '24' },
  //     { title: 'USE OF MOBILE DEVICES', page: '24' },
  //     { title: 'PROHIBITED ACTIONS', page: '25' },
  //     { title: 'GOVERNING LAWS', page: '25' },
  //     { title: 'JURISDICTION', page: '25' },
  //     { title: 'MODIFICATION OF TERMS AND THE PLATFORM', page: '26' },
  //     { title: 'USE AT YOUR OWN RISK', page: '26' },
  //     { title: 'OPINIONS AND FEEDBACK', page: '26' },
  //   ];
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        COVESTRO फूड सिक्युरिटी प्लॅटफॉर्मवरील प्रवेश आणि वापर संदर्भातील अटी व
        शर्ती
      </h3>
      {/* {tableCotent.map((item: any, index: number) => {
        return (
          <div key={index} className='table-row '>
            <div className='first-col'>{index + 1}.</div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <div style={{ flex: 0.1, textAlign: 'right' }}>{item.page}</div>
          </div>
        );
      })}
      <h4 style={{ textAlign: 'center', marginTop: 40 }}>
        TERMS AND CONDITIONS OF ACCESS AND USAGE FOR COVESTRO FOOD SECURITY
        PLATFORM
      </h4> */}
      <h4 style={{ textAlign: 'center' }}>अंतिम अद्यतन: [ 28 जुलै 2022]</h4>
      <p>
        पूर्वी Bayer Material Science Private Limited म्हणून ओळखल्या जाणाऱ्या
        Covestro (INDIA) PRIVATE LIMITEDच्या समुदायाचा भाग होण्याचा निर्णय
        घेतल्याबद्दल धन्यवाद. कंपनी कायदा 1956 अंतर्गत समाविष्ट केली गेलेली ही
        एक खाजगी लिमिटेड कंपनी असून आमचे नोंदणीकृत कार्यालय युनिट क्रमांक
        SB-801, आठवा मजला, एम्पायर टॉवर, क्लाउड सिटी कॅम्पस, ऐरोली, ठाणे -
        बेलापूर रोड नवी मुंबई ठाणे MH 400708 IN येथे आहे. (“COVESTRO”, “कंपनी”,
        “आम्ही”, “आपण”, किंवा “आमच्या”, किंवा ‘प्लॅटफॉर्म’). खाली तपशीलवार
        दिलेल्या या अटी &quot;वापरकर्ता&quot; (यापुढे &quot;तुम्ही&quot; किंवा
        &quot;ग्राहक&quot; म्हणूनही संबोधले जातील) आणि COVESTRO यांच्यातील करार
        संबंध नियंत्रित करतात. याद्वारे स्पष्ट केले आहे की &quot;वापरकर्ते&quot;
        किंवा &quot;तुम्ही&quot; या शब्दाचा अर्थ प्लॅटफॉर्मची रीतसर नोंदणी
        केलेली आणि वापरणारी कोणतीही व्यक्ती असेल, म्हणजे ग्राहक, शेतकरी
        आणि/किंवा शेतकरी सोसायट्या/गट.
      </p>
      <p>
        वापरकर्त्यांना, उत्पादने आणि सेवा पुरविणे ज्यामध्ये आर्थिक योजना किंवा
        धोरणे, अन्न आणि कृषी उत्पादने आणि उत्पादने यांचा समावेश आहे आणि
        वापरकर्त्यांना विविध तृतीय-पक्ष विक्रेत्यांकडे प्रवेश देणे शक्य व्हावे
        यासाठी, COVESTRO ने ही वेबसाइट/अॅप [फूड सिक्युरिटी प्लॅटफॉर्म],
        आणि/किंवा कोणत्याही संबंधित सेवा, विक्री, विपणन किंवा इव्हेंट (आम्ही
        त्यांचा या धोरणात एकत्रितपणे &quot;प्लॅटफॉर्म&quot; किंवा
        &quot;वेबसाइट/अॅप&quot; म्हणून उल्लेख करतो) तयार केली आहे. शिफारशींमध्ये
        अनेक घटकांचा विचार केला जातो, ज्यामध्ये वापरकर्त्यांनी दिलेल्या माहितीचा
        समावेश आहे, परंतु त्यापुरते मर्यादित नाही तसेच हे प्रत्येक
        वापरकर्त्यासाठी स्पेसिफिक आहे, तथापि, हे यापुर्वी सांगितलेले आहे आणि
        सांगितले जाते की आम्ही प्लॅटफॉर्मवर उपलब्ध असलेल्या कोणत्याही आर्थिक
        योजना किंवा धोरणे, अन्न आणि कृषी उत्पादनांच्या गुणवत्तेची किंवा सत्यतेची
        हमी देत नाही, आणि प्लॅटफॉर्म हा केवळ एक पूल आहे जो ऑनलाइन मार्केटप्लेस
        म्हणून काम करतो.
      </p>
      <p>
        या प्लॅटफॉर्मचा तुमचा वापर असे सूचित करतो की तुम्ही अस्वीकरण, अटी आणि
        शर्ती (&quot;अटी आणि शर्ती&quot;) वाचून समजून घेतल्या आहेत आणि त्यांचे
        पालन करण्यास सहमत आहात. या अटी आणि शर्ती आमच्या गोपनीयता धोरणासह एकत्र
        वाचल्यास तुमचा प्लॅटफॉर्मचा वापर, प्रवेश नियंत्रित केला जातो. जर तुम्ही
        या अटी आणि शर्तींच्या कोणत्याही भागाशी असहमत असाल तर कृपया प्लॅटफॉर्म
        वापरू नका किंवा त्यामध्ये प्रवेश करू नका.
      </p>
      <ol>
        <li>
          <h3>प्रवेश आणि वापर संदर्भातील अटी व शर्तींची स्वीकृती</h3>
          <ol>
            <li>
              <p>
                तुम्ही आमच्या कोणत्याही सेवेची सदस्यता कशी घेता किंवा त्यांचा
                वापरता कसा करता हे विचारात न घेता आमच्या कोणत्याही सेवेचे
                सदस्यत्व घेऊन किंवा त्या वापरून तुम्ही अटी वाचल्या आहेत, तसेच
                त्या तुम्हाला समजल्या असून त्यांना तुम्ही बांधील आहात यास सहमती
                दर्शवता. तुम्ही अटींना बांधील राहू इच्छित नसल्यास, तुम्ही आमच्या
                सेवांची सदस्यता घेऊ नये किंवा त्यांचा वापर करू नये. या अटी आणि
                इतर विविध धोरणे 2000 च्या माहिती तंत्रज्ञान कायद्यांतर्गत तयार
                केलेल्या माहिती तंत्रज्ञान (मध्यस्थ मार्गदर्शक तत्त्वे) नियम,
                2011 च्या तरतुदींनुसार बंधनकारक आहेत.
              </p>
            </li>
            <li>
              <p>
                या अटी तुमच्यासाठी आणि COVESTRO साठी महत्त्वाच्या आहेत कारण
                त्यांचा वापर एक मौल्यवान ग्राहक म्हणून तुमचे हक्क आणि व्यवसाय
                म्हणून आमच्या अधिकारांचे संरक्षण करण्यासाठी केला जातो.
              </p>
            </li>
            <li>
              <p>
                या अटी लागू करण्यायोग्य आहेत आणि COVESTRO द्वारे लिखित, पूर्वी
                वितरित केलेल्या स्पष्ट बाबी वगळता इतर सर्व अटी बदलतात.
              </p>
            </li>
            <li>
              <p>
                जेव्हा तुम्ही प्लॅटफॉर्मच्या माध्यमातून Covestro ने पुरविलेल्या
                कोणत्याही सेवा वापरता, ज्यामध्ये उत्पादन पुनरावलोकने, विक्रेता
                पुनरावलोकने, संभाव्य विक्रेत्यांचे संपर्क तपशील मिळविणे पुरविणे
                समाविष्ट आहे, परंतु त्यापुरते मर्यादित नाही, तेव्हा तुम्ही नियम,
                मार्गदर्शक तत्त्वे, धोरणांच्या अधीन असाल. अशा सेवेला लागू
                असलेल्या अटी आणि शर्ती, आणि ते या वापराच्या अटींमध्ये समाविष्ट
                करण्यात आल्या आहेत असे मानले जाईल आणि असा होत असलेला वापराचा,
                अटींचा भाग म्हणून विचार केला जाईल.
              </p>
            </li>
            <li>
              <p>
                आम्ही तुम्हाला कोणत्याही पूर्व लेखी सूचना न देता, कोणत्याही वेळी
                या वापर अटींमध्ये बदल करण्याचे अधिकार आमच्याकडे राखून ठेवतो.
                अद्यतने/बदलांसाठी या वापर अटींचे वेळोवेळी पुनरावलोकन करणे ही
                तुमची जबाबदारी आहे. बदल पोस्ट केल्यानंतर तुम्ही प्लॅटफॉर्मचा
                वापर करत राहिल्याचा अर्थ असा होईल की तुम्ही हे बदल स्वीकारले
                असून त्यास सहमती दर्शवली आहे. तुम्हाला सेवा अटींच्या नवीनतम
                आवृत्तीचे पुनरावलोकन करता यावे यासाठी आम्ही त्या येथे सुयोग्य
                स्थितीमध्ये ठेवलेल्या आहेत:{' '}
                <a
                  href={`${APP_DOMAIN}legal/conditions-of-use`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/conditions-of-use`}
                </a>
              </p>
            </li>
            <li>
              <p>
                जोपर्यंत तुम्ही या वापराच्या अटींचे पालन करता, तोपर्यंत आम्ही
                तुम्हाला प्लॅटफॉर्ममध्ये प्रवेश करण्यासाठी आणि त्याचा वापर
                करण्यासाठी वैयक्तिक, वैशिष्ट्यपूर्ण नसलेला, अ-हस्तांतरणीय,
                मर्यादित विशेषाधिकार देतो.
              </p>
            </li>
            <li>
              <p>
                आम्ही आमचे धोरण युरोपियन युनियनच्या जनरल डेटा प्रोटेक्शन
                रेग्युलेशन (GDPR) चे पालन करण्यासाठी अद्ययावत केलेले आहे. नवीन
                धोरणातील बदलांनुसार, तुमच्या वैयक्तिक डेटावर तुमचे अधिक नियंत्रण
                आहे, ज्यामुळे तो तुमच्यासाठी अधिक सुरक्षित आणि बिनधोक होईल.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>तृतीय-पक्ष सेवा</h3>
          <ol>
            <li>
              <p>
                प्लॅटफॉर्मवरून तुम्हाला तृतीय-पक्ष उत्पादने किंवा सेवा (“तृतीय
                पक्ष सेवा”) च्या लिंक्सचा ॲक्सेस मिळू देऊ शकतो. COVESTRO
                कोणत्याही प्रकारे तृतीय पक्ष सेवा नियंत्रित करत नाही आणि
                त्यानुसार, अशा तृतीय-पक्ष सेवांशी संबंधित कोणतेही दायित्व गृहीत
                धरत नाही. तृतीय-पक्ष सेवेत प्रवेश करणे योग्य आहे की नाही हे
                निर्धारित करण्यासाठी तुम्हाला योग्य पावले उचलण्याची आवश्यकता
                आहे, ज्यामध्ये अशा कोणत्याही तृतीय-पक्ष सेवा वापरताना आपली
                वैयक्तिक माहिती आणि गोपनीयतेचे संरक्षण करणे आणि संबंधित करारांचे
                पालन करणे समाविष्ट आहे.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>प्रवेश आणि वापर</h3>
          <ol>
            <li>
              <p>
                प्लॅटफॉर्म ही एक मार्केटप्लेस आहे, ज्यामध्ये वापरकर्त्यांना
                विविध प्रकारचे खाद्यपदार्थ आणि कृषी उत्पादने आणि संबंधित सेवा
                आणि समाधानांचा वापर करता येतो. यामध्ये भाजीपाला, फळे, धान्य,
                डाळी, मसाले, लागवडीची पिके, आर्थिक योजना, धोरणे, आराखडे इ.
                (एकत्रितपणे &quot;उत्पादने आणि सेवा&quot; म्हणून संदर्भित) यांचा
                समावेश आहे, परंतु इतकेच मर्यादित नाही.
              </p>
            </li>
            <li>
              <p>
                तुम्ही प्लॅटफॉर्मवरून सबमिट केलेली कोणतीही सामग्री, कंपनीच्या{' '}
                <a
                  href={`${APP_DOMAIN}legal/privacy-statement`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/privacy-statement`}
                </a>{' '}
                येथे उपलब्ध असलेल्या गोपनीयता धोरणानुसार नियंत्रित केली जाते. हा
                करार आणि कंपनीच्या गोपनीयता धोरणामधील विसंगतीच्या प्रमाणात, या
                अटींचे नियमन करण्यात येईल.
              </p>
            </li>
            <li>
              <p>
                चर्चा मंचाच्या संबंधात, जर तुम्ही प्रश्न किंवा प्रतिसाद सबमिट
                करत असाल, तर तुम्ही तुमच्या स्व-संप्रेषणांसाठी, ती संप्रेषणे
                पोस्ट करण्याचे परिणाम आणि सार्वजनिक भागात आढळणाऱ्या कोणत्याही
                संप्रेषणांवरील तुमच्या विश्वासासाठी पूर्णपणे जबाबदार आहात.
                सार्वजनिक क्षेत्रातील कोणत्याही संप्रेषणाच्या परिणामांसाठी,
                कंपनी आणि तिचे परवानाधारक जबाबदार नाहीत. ज्या प्रकरणांमध्ये
                तुम्हाला धोका वाटत असेल किंवा तुम्हाला वाटत असेल की कोणीतरी
                धोक्यात आहे, तुम्ही ताबडतोब तुमच्या स्थानिक कायदा अंमलबजावणी
                एजन्सीशी संपर्क साधावा. प्लॅटफॉर्मशी संबंधित तुमच्या सर्व
                क्रियाकलापांसाठी तुम्ही जबाबदार आहात आणि तुम्ही सर्व स्थानिक,
                राज्य, राष्ट्रीय आणि आंतरराष्ट्रीय कायदे आणि नियम आणि लागू
                होणाऱ्या कोणत्याही नियामक कोडचे पालन कराल.
              </p>
            </li>
            <li>
              <p>
                उत्पादने आणि सेवांमध्ये प्रवेशास तात्पुरत्या आधारावर परवानगी आहे
                आणि आम्ही प्लॅटफॉर्मवर नमूद केलेली उत्पादने आणि सेवा (किंवा
                उत्पादने आणि सेवांमधील कोणतीही वैशिष्ट्ये) मागे घेण्याचा किंवा
                तुम्हाला पूर्वसूचना न देता वेळोवेळी त्यामध्ये सुधारणा करण्याचा
                अधिकार आम्ही राखून ठेवतो. कोणत्याही कारणास्तव प्लॅटफॉर्म किंवा
                उत्पादने आणि सेवा (किंवा उत्पादने आणि सेवांमधील कोणतीही
                वैशिष्ट्ये) कोणत्याही वेळी किंवा कोणत्याही कालावधीसाठी अनुपलब्ध
                असल्यास आम्ही जबाबदार राहणार नाही. वेळोवेळी, आम्ही आमच्या
                विवेकबुद्धीनुसार, आमच्याकडे नोंदणी केलेल्या वापरकर्त्यांसाठी
                प्लॅटफॉर्मच्या काही भागांमध्ये किंवा संपूर्ण प्लॅटफॉर्मवर प्रवेश
                प्रतिबंधित करू शकतो.
              </p>
            </li>
            <li>
              <p>
                तुम्हाला आमच्या प्लॅटफॉर्मवर आणि त्यावरून उत्पादने आणि
                सेवांमध्ये प्रवेश मिळावा यासाठी आवश्यक असलेल्या सर्व व्यवस्था
                करण्यासाठी तुम्ही जबाबदार आहात. इतर कोणीही प्लॅटफॉर्म आणि
                त्यावरून तुमचे खाते ॲक्सेस करून उत्पादने आणि सेवांचा वापर करणार
                नाही याची खात्री करण्यासाठी तुम्ही देखील जबाबदार आहात. तसेच,
                आमच्यावर कोणतेही दायित्व न ठेवता अशा प्रवेशासाठी तुम्ही पूर्णपणे
                जबाबदार असाल याची तुम्हाला जाणीव आहे.
              </p>
            </li>
            <li>
              <p>
                तुमच्या खात्यांतर्गत होणाऱ्या किंवा घडणाऱ्या सर्व
                क्रियाकलापांसाठी तुम्ही पूर्णपणे जबाबदार आणि COVESTRO ला
                उत्तरदायी आहात. तुमच्या खात्यात लॉग इन करण्याची तुमची क्षमता
                इंटरनेट सेवा प्रदाते आणि इंटरनेट नेटवर्क कनेक्टिव्हिटी यासारख्या
                बाह्य घटकांवर अवलंबून आहे आणि तुमच्या खात्यात लॉग इन करण्याच्या
                अक्षमतेमुळे उद्भवलेल्या कोणत्याही नुकसानासाठी आम्ही तुम्हाला
                जबाबदार राहणार नाही यास तुम्ही सहमती दर्शवता.
              </p>
            </li>
            <li>
              <p>
                नवीन वापरकर्त्यांना प्लॅटफॉर्मवर ऑफर केलेली उत्पादने आणि सेवा
                वापरण्यासाठी प्रवेश नाकारण्याचा किंवा विद्यमान वापरकर्त्यांना
                दिलेला प्रवेश कोणत्याही वेळी कोणत्याही कारणाशिवाय संपुष्टात
                आणण्याचा अधिकार COVESTRO राखून ठेवते. कोणत्याही कारणास्तव
                प्लॅटफॉर्म किंवा सेवा वापरण्यापासून COVESTRO ने निलंबित किंवा
                प्रतिबंधित केलेल्या कोणत्याही वापरकर्त्यासाठी खात्याचा वापर
                उपलब्ध नाही.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>सदस्यत्व/नोंदणी आणि पात्रता</h3>
          <ol>
            <li>
              <p>
                उत्पादने आणि सेवांमध्ये प्रवेश करण्यासाठी तुम्हाला प्लॅटफॉर्मवर
                नोंदणी करावी लागेल आणि &quot;सदस्य&quot; खाते तयार करावे लागेल.
                तुमचे खाते तुम्हाला उत्पादने आणि सेवा आणि कार्यक्षमतेमध्ये
                प्रवेश देते जे आम्ही वेळोवेळी आणि प्लॅटफॉर्मवर आमच्या
                विवेकबुद्धीनुसार स्थापित करू शकतो आणि देखरेख करू शकतो.
              </p>
            </li>
            <li>
              <p>
                नोंदणी प्रक्रिया पूर्ण करून, तुम्ही हमी देता की: (a) तुम्ही नमूद
                केलेला सर्व डेटा अचूक आणि पूर्ण आहे; (b) तुम्ही अशा माहितीची
                अचूकता आणि अशा कोणत्याही माहितीचे नियमित अद्ययावतीकरण करून त्यात
                कोणतेही बदल राखाल; (c) तुम्ही प्रतिज्ञा करता की तुमचे वय 16
                वर्षांपेक्षा जास्त आहे आणि तुम्ही या करारामध्ये नमूद केलेल्या
                अटी, शर्ती, दायित्वे, पुष्टीकरणे, प्रतिनिधित्व आणि हमींमध्ये
                प्रवेश करण्यास पूर्णपणे सक्षम आहात आणि या कराराचे पालन करण्यास
                पूर्णपणे सक्षम आहात. ; (d) तुमचे वय 18 (अठरा) वर्षांपेक्षा कमी
                असल्यास, तुमच्या पालकांनी किंवा कायदेशीर सांभाळकर्त्यांनी या
                कराराच्या अटींचे पुनरावलोकन करून त्या स्वीकारणे आवश्यक आहे आणि
                प्लॅटफॉर्म वापरून, तुम्ही पुष्टी करता की तुमच्या पालकांनी किंवा
                कायदेशीर सांभाळकर्त्यांनी या कराराचे पुनरावलोकन करून ते
                स्वीकारले आहे. आम्ही आमच्या विवेकबुद्धीनुसार प्लॅटफॉर्ममधील
                विशिष्ट वैशिष्ट्यांची 18 वर्षाखालील वापरकर्त्यांसाठी उपलब्धता
                मर्यादित करण्याचा अधिकार राखून ठेवतो. (e) तुम्ही बंधनकारक करार
                करण्यासाठी लागू कायद्यांनुसार पात्र आहात आणि लागू कायद्यांतर्गत
                उत्पादने आणि सेवा प्राप्त करण्यापासून प्रतिबंधित असलेली व्यक्ती
                नाही; आणि (f) तुम्ही प्लॅटफॉर्म किंवा प्लॅटफॉर्मद्वारे प्रदान
                केलेल्या अशा इतर सेवांचा वापर केवळ या कराराचे आणि सर्व लागू
                स्थानिक, राज्य, राष्ट्रीय आणि आंतरराष्ट्रीय कायदे, नियम आणि
                नियननांचे पालन करुनच करू शकता. तुम्ही प्लॅटफॉर्मवर नमूद केलेल्या
                माहितीमधील कोणत्याही चुकीमुळे कोणत्याही प्रकारची दुखापत, नुकसान
                किंवा इतर परिणाम, आरोग्याशी संबंधित किंवा अन्यथा उद्भवल्यास
                आम्ही जबाबदार राहणार नाही. या करार किंवा गोपनीयता धोरणांतर्गत
                नमूद केल्यानुसार तुम्ही कोणत्याही अटींचे उल्लंघन केले आहे असे
                आम्हाला वाटत असल्यास, चेतावणीशिवाय किंवा कोणतीही सूचना न देता
                तुम्हाला 24 (चोवीस) तास अगोदर सूचित करून आम्ही तुमचे प्रोफाइल
                हटवू शकतो.
              </p>
            </li>
            <li>
              <p>
                नोंदणी प्रक्रिया पूर्ण करण्यासाठी आणि उत्पादने आणि सेवा
                वापरण्याचा अधिकार मिळण्यासाठी तुम्ही तुमचे संपूर्ण कायदेशीर नाव,
                फोन नंबर, वैध ईमेल पत्ता आणि तुमच्याकडून वेळोवेळी विनंती केलेली
                कोणतीही माहिती देणे आवश्यक आहे. तुम्ही दिलेली माहिती आम्ही
                उत्पादने आणि सेवा पुरविण्यासाठी, रेकॉर्ड ठेवण्याचे हेतू, अंतर्गत
                प्रक्रिया किंवा इतर कोणत्याही हेतूंसाठी कोणत्याही तृतीय पक्षाला
                सामायिक करू शकतो आणि या प्लॅटफॉर्मचा वापर करून तुम्ही दिलेल्या
                माहितीच्या अशा सामायिकरणास तुम्ही स्पष्टपणे संमती देता.
              </p>
            </li>
            <li>
              <p>
                नोंदणी प्रक्रिया पूर्ण केल्यावर, तुम्हाला आमच्या सुरक्षा
                प्रक्रियेचा एक भाग म्हणून वापरकर्ता ओळख कोड, पासवर्ड किंवा इतर
                कोणतीही माहिती देण्यात येईल. तुम्ही अशी माहिती गोपनीय मानली
                पाहिजे आणि तुम्ही ती कोणत्याही तृतीय पक्षाला उघड करू नये.
                प्रत्येक सत्राच्या शेवटी तुम्ही तुमच्या खात्यातून बाहेर पडले
                आहात/लॉग आउट केले आहे याची खात्री करण्यासही तुम्ही सहमत आहात.
                आमच्या मते, तुम्ही या कराराच्या कोणत्याही तरतुदींचे पालन करण्यात
                अयशस्वी झाल्यास, तुम्ही निवडलेला किंवा आम्ही नेमून दिलेला
                कोणताही वापरकर्ता ओळख कोड किंवा पासवर्ड अक्षम करण्याचा आम्हाला
                अधिकार आहे. तुम्ही तुमच्या पासवर्डच्या किंवा वापरकर्ता ओळख
                कोडच्या कोणत्याही अनधिकृत वापराबद्दल COVESTRO ला अशा अनधिकृत
                वापराचे तपशील येथे पाठवून ताबडतोब सूचित कराल:{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्मवर नोंदणी प्रक्रिया पूर्ण झाल्यावर, तुम्ही सदस्य व्हाल
                ज्यामुळे तुम्हाला:
              </p>
              <ol>
                <li>
                  <p>
                    तुम्हाला प्लॅटफॉर्मवर विक्री किंवा खरेदीसाठी आमंत्रण म्हणून
                    उत्पादनांमध्ये प्रवेश आणि पोस्ट करण्याची परवानगी मिळते;
                  </p>
                </li>
                <li>
                  <p>
                    आम्हाला प्लॅटफॉर्मच्या वापराच्या अटींमधील बदल किंवा आम्ही
                    प्लॅटफॉर्मवर सक्षम केलेल्या नवीन सेवांचे वर्णन करण्यासाठी,
                    आवश्यक असल्यास, तुमच्याशी संपर्क साधण्याची परवानगी मिळते;
                  </p>
                </li>
                <li>
                  <p>
                    येथे दिलेल्या अटी व शर्तींच्या अधीन राहून तुम्हाला
                    प्लॅटफॉर्मवरील उत्पादने आणि सेवांवरील माहिती ॲक्सेस करण्याची
                    आणि संकलित करण्याची आणि त्यानंतर अशा माहितीच्या प्रवर्तकाशी
                    संपर्क साधण्याची परवानगी मिळते;
                  </p>
                </li>
                <li>
                  <p>
                    तुम्हाला प्लॅटफॉर्मवर प्रदर्शित केलेले ईमेल पत्ते आम्ही
                    विशेषतः अधिकृत केलेल्या व्यतिरिक्त इतर कोणत्याही हेतूसाठी
                    वापरण्याची परवानगी नाही.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                COVESTRO ला तुमचा ईमेल पत्ता/फोन नंबर देऊन तुम्ही खालील
                गोष्टींना संमती देता:
              </p>
              <ol>
                <li>
                  <p>
                    पोस्टल मेलवरून संप्रेषणाच्या बदल्यात, कायद्याने आवश्यक
                    असलेल्या कोणत्याही सूचनांसह, तुम्हाला सेवा-संबंधित सूचना
                    पाठवण्यासाठी आम्ही तुमचा ईमेल पत्ता किंवा मोबाइल नंबर
                    वापरतो. तुम्ही तुमच्या सेटिंग्जचा वापर ईमेल/मोबाइल नंबरवरून
                    किंवा संपूर्णपणे सेवा-संबंधित संप्रेषणांची निवड रद्द
                    करण्यासाठी करू शकता.
                  </p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्मवरील तुमची प्रगती आणि आमच्या सेवांच्या वापरासंबंधी
                    आणि कोणत्याही नवीन जाहिराती आणि विक्रीशी संबंधित अद्यतने आणि
                    सल्ला देण्यासाठी, तुम्ही नमूद केलेल्या फोन नंबर्सवरून आम्ही
                    वेळोवेळी तुमच्याशी प्लॅटफॉर्मवर संपर्क साधतो.
                  </p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्मवर तुम्ही दिलेल्या ऑर्डरची पूर्तता करण्यासाठी
                    तुमचा ईमेल पत्ता, घराचा पत्ता, फोन नंबर आणि इतर साहाय्यकारी
                    माहितीचा आमचा वापर; आणि
                  </p>
                </li>
                <li>
                  <p>
                    आम्ही तुमचा ईमेल पत्ता किंवा फोन नंबर वापरून तुम्हाला इतर
                    संदेश पाठवतो, ज्यात उत्पादने आणि सेवांच्या वैशिष्ट्यांमधील
                    बदल आणि विशेष ऑफर समाविष्ट आहेत. तुम्हाला असे ईमेल संदेश
                    मिळवायचे नसल्यास, तुम्ही आम्हाला येथे ईमेल पाठवून निवड रद्द
                    करू शकता:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    निवड रद्द केल्याने तुम्हाला अद्यतने, सुधारणा किंवा ऑफर
                    संबंधित ईमेल संदेश किंवा फोन संदेश मिळण्यापासून प्रतिबंधित
                    केले जाऊ शकते.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>प्लॅटफॉर्मच्या वापर अटी</h3>
          <ol>
            <li>
              <p>
                तुम्ही वेळोवेळी तृतीय पक्षांनी विक्री, खरेदी आणि त्यावर सूचीबद्ध
                उत्पादने आणि सेवांच्या आमंत्रणावरील माहिती पाहण्यासाठी
                प्लॅटफॉर्ममध्ये प्रवेश करून तिचा वापर करू शकता.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्मच्या संदर्भात COVESTRO ची जबाबदारी केवळ आणि विशेषत:
                खालील कारणांस्तव विस्तारित करण्यात येईल:
              </p>
              <ol>
                <li>
                  <p>प्लॅटफॉर्मवरील प्लॅटफॉर्मची तरतूद;</p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्मवर नोंदणी केलेल्या वापरकर्त्यांना उत्पादने आणि
                    सेवांची सुलभता आणि उपलब्धता सुनिश्चित करण्यासाठी
                    मार्केटप्लेस म्हणून सेवा देणे.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                याद्वारे हे स्पष्ट केले आहे की प्लॅटफॉर्मवर सूचीबद्ध उत्पादने
                आणि सेवा ही तृतीय-पक्ष विक्रेत्यांच्या मालकीची आणि विक्री केलेली
                उत्पादने आणि सेवा आहेत. तृतीय-पक्ष विक्रेत्याची उत्पादने आणि
                सेवा कोणत्याही प्रकारे COVESTRO शी संबंधित नाहीत. अशा प्रकारे,
                तृतीय-पक्ष विक्रेत्यांची उत्पादने आणि सेवांच्या गुणवत्तेसाठी,
                प्रमाणासाठी किंवा सत्यतेसाठी COVESTRO कोणतीही हमी देत नाही आणि
                कोणत्याही प्रकारे जबाबदार असणार नाही.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्मवर ऑर्डर किंवा नोंदणी केल्यानंतर आणि त्यावर प्रक्रिया
                केल्यानंतर, येथे नमूद केल्याप्रमाणे कोणतेही परतावे किंवा
                रद्दीकरण केले जाणार नाही किंवा दखल घेतली जाणार नाही.
              </p>
            </li>
            <li>
              <p>
                वापराच्या या अटी व शर्तींच्या अधीन राहून, COVESTRO तुम्हाला केवळ
                तुमच्या फायद्यासाठी प्लॅटफॉर्ममध्ये प्रवेश करण्याचा आणि
                वापरण्याचा एक अहस्तांतरणीय, नॉन-एक्सक्लुझिव्ह, नॉन-सबसिडायजेबल
                करण्यायोग्य मर्यादित अधिकार आणि परवाना देत आहे.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>सेवांच्या वापरावरील निर्बंध</h3>
          <ol>
            <li>
              <p>
                आमच्याकडे तुमच्या विरुद्ध असलेल्या इतर कोणत्याही अधिकार किंवा
                उपायांचा पूर्वग्रह न ठेवता तुम्हाला खालीलपैकी कोणतीही कृती
                करण्याची परवानगी नाही आणि तुम्ही ती करू शकणार नाही, ज्यायोगे
                होणारे उल्लंघन COVESTRO ला तुमची उत्पादने आणि सेवांचा वापर
                संपुष्टात आणण्याचा अधिकार देईल:
              </p>
              <ol>
                <li>
                  <p>
                    उत्पादने आणि सेवांचा भाग म्हणून तुम्हाला पुरवलेली सर्व
                    सामग्री आणि प्लॅटफॉर्मचे वितरण, विक्री, पुरवठा, सुधारणे,
                    रुपांतर करणे, सुधारणा करणे, समाविष्ट करणे, विलीन करणे किंवा
                    अन्यथा बदल करणे;
                  </p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्म किंवा वेबसाइट किंवा अॅपचा भाग म्हणून तुम्हाला
                    पुरविलेली कोणतीही सेवा किंवा सामग्री डीकंपाइल, रिव्हर्स
                    इंजिनियर किंवा अन्यथा वेगळे करण्याचा प्रयत्न;
                  </p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्म किंवा वेबसाइट किंवा अॅपचा भाग म्हणून
                    तुम्हालापुरविलेले कोणतेही सॉफ्टवेअर कॉपी करण्याचा प्रयत्न
                    करणे आणि विशेषतः त्याचा स्रोत कोड किंवा तुम्हाला पुरविलेल्या
                    अशा सॉफ्टवेअरचा कोणताही भाग डिक्रिप्ट करण्याचा प्रयत्न करणे;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पादने आणि सेवांचा भाग म्हणून प्रदान केलेल्या प्लॅटफॉर्मचा
                    किंवा कोणत्याही सामग्रीचा तुमचा वापर सुलभ करण्यासाठी किंवा
                    आम्ही पुरविलेल्या कोणत्याही सॉफ्टवेअरचे कोणतेही व्युत्पन्न
                    कार्य किंवा आवृत्ती तयार करणे;
                  </p>
                </li>
                <li>
                  <p>
                    टोपणनावी रिटर्न ईमेल पत्ता म्हणून COVESTRO चे कोणतेही डोमेन
                    नाव वापरणे;
                  </p>
                </li>
                <li>
                  <p>
                    तुम्ही कोणत्याही प्रकारे प्रवेश करण्यासाठी अधिकृत नसलेल्या
                    कोणत्याही सामग्रीमध्ये प्रवेश करणे किंवा प्रवेश करण्याचा
                    प्रयत्न करणे;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पादने आणि सेवा किंवा उत्पादने आणि सेवांचा भाग म्हणून
                    पुरविलेली कोणतीही सामग्री, कोणताही लोगो, कॉपीराइट किंवा
                    मालकीच्या सूचना, दंतकथा, चिन्हे, लेबले, वॉटरमार्क, स्वाक्षरी
                    किंवा इतर कोणत्याही चिन्हांवर चिकटवलेले किंवा उत्पादने आणि
                    सेवांवर चिकटवलेले किंवा एम्बेड केलेले इतर कोणतेही चिन्ह
                    काढून टाकणे;
                  </p>
                </li>
                <li>
                  <p>
                    खोटी ओळख निर्माण करण्यासाठी, कोणत्याही व्यक्तीची किंवा
                    संस्थेची तोतयागिरी करण्यासाठी किंवा कोणत्याही सामग्रीचे मूळ
                    लपविण्याचा प्रयत्न करण्यासाठी उत्पादने आणि सेवा किंवा त्याचा
                    कोणताही भाग वापरणे;
                  </p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्मच्या कोणत्याही सर्व्हरला किंवा प्लॅटफॉर्म होस्ट
                    केलेल्या कोणत्याही सर्व्हरशी कनेक्ट केलेल्या नेटवर्कला
                    नुकसान, अक्षम, ओव्हरबर्डन किंवा खराब करू शकतील अशा कोणत्याही
                    प्रकारे प्लॅटफॉर्ममध्ये प्रवेश करणे किंवा तो वापरणे;
                  </p>
                </li>
                <li>
                  <p>
                    आम्ही पुरविलेल्या इंटरफेसशिवाय इतर कोणत्याही माध्यमाने
                    प्लॅटफॉर्मवर प्रवेश करणे;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पादने आणि सेवांचा कोणताही भाग बदलणे किंवा सुधारणे; आणि
                  </p>
                </li>
                <li>
                  <p>
                    प्लॅटफॉर्म, सामग्री, सिस्टम संसाधने यांच्या सुरक्षिततेमध्ये
                    व्यत्यय आणणे किंवा हस्तक्षेप करणे किंवा अन्यथा प्लॅटफॉर्म,
                    सामग्री, सिस्टम संसाधनांना हानी पोहोचवणे किंवा प्लॅटफॉर्म
                    किंवा कोणत्याही संलग्न लिंक केलेल्या साइट्सशी कनेक्ट केलेले
                    किंवा प्रवेश करण्यायोग्य वापरकर्ता खाती, पासवर्ड, सर्व्हर
                    किंवा नेटवर्कमध्ये अनधिकृत प्रवेश मिळवणे.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>सामग्री</h3>
          <ol>
            <li>
              <p>
                जेव्हा तुम्ही उत्पादने आणि सेवांचा (किंवा कोणतेही वैशिष्ट्य
                किंवा उत्पादने आणि सेवांचा भाग) वापर करता ज्या तुम्हाला
                प्लॅटफॉर्मवर सामग्री अपलोड करण्यास किंवा आमच्या प्लॅटफॉर्मच्या
                इतर वापरकर्त्यांशी किंवा प्लॅटफॉर्मवरील विक्रेत्यांशी संपर्क
                साधण्याची परवानगी देतात, तेव्हा तुम्ही लागू डेटा संरक्षण
                नियम/कायदे आणि खाली सेट केलेल्या सामग्री मानकांचे पालन करणे
                आवश्यक आहे. सामग्री मानके योगदानाच्या प्रत्येक भागावर तसेच
                संपूर्ण भागावर लागू होतात. तुमचे योगदान अचूक असले पाहिजे जेथे
                त्यावरून तथ्ये मांडली जातात, ते सत्य घटनेला धरून असावे (जेथे
                त्यावरून मते व्यक्त केली जातात), त्यामध्ये पोस्ट केलेल्या
                कोणत्याही देशामध्ये लागू असलेल्या कायद्याचे पालन होणे आवश्यक
                आहे; तसेच ते तुमच्या पोस्टिंगशी किंवा उत्पादने आणि सेवांच्या
                वापराशी संबंधित असावे.
              </p>
            </li>
            <li>
              <p>
                उत्पादने आणि सेवा वापरून शोध परिणाम आणि साइट आणि सामग्रीच्या
                लिंक्स तयार करू शकतात जे तुम्हाला आक्षेपार्ह, अनुचित किंवा
                अपमानकारक वाटू शकतात आणि आम्ही अशा कोणत्याही सामग्रीसाठी कोणतीही
                जबाबदारी स्वीकारत नाही. हे देखील शक्य आहे की तुमच्या उत्पादने
                आणि सेवांच्या वापरामुळे तुम्हाला उत्पादने आणि सेवा, व्यापारी आणि
                तुम्ही विनंती केलेल्या उत्पादन माहितीची विक्री करणाऱ्या तृतीय
                पक्षांच्या वेबसाइट्सच्या लिंकशी संबंधित माहिती मिळेल. COVESTRO
                याद्वारे संबंधित कोणतीही आणि सर्व जबाबदारी आणि दायित्व नाकारते.
              </p>
            </li>
            <li>
              <p>
                अशा बाह्य साइट्स किंवा संसाधनांच्या उपलब्धतेमुळे किंवा
                पूर्णतेवर, अचूकतेवर किंवा तुम्ही ठेवलेल्या कोणत्याही अवलंबनाच्या
                किंवा कोणत्याही जाहिरातींचे अस्तित्व, उत्पादने आणि सेवा किंवा
                इतर सामग्री अशा वेबसाइट्स किंवा संसाधनांबाबत तुमच्याकडून
                होणाऱ्या कोणत्याही हानी किंवा नुकसानासाठी COVESTRO जबाबदार नाही
                यास तुम्ही स्वीकृती देऊन त्यास सहमती दर्शवता.
              </p>
            </li>
            <li>
              <p>
                तुमची सामग्री, दस्तऐवज आणि इतर डेटाचे निरीक्षण करण्यासाठी तुम्ही
                जबाबदार असाल आणि सेवेद्वारे किंवा त्यामध्ये हस्तांतरित केलेली
                किंवा हाताळलेली सामग्री कोणत्याही तृतीय-पक्ष्याच्या अधिकारांचे
                उल्लंघन करत नाही हे सुनिश्चित करण्यासाठी तुम्ही जबाबदार असाल.
                तुमच्या सामग्रीवर प्रक्रिया करण्यासाठी किंवा उत्पादने आणि सेवा
                वापरण्यासाठी आवश्यक असलेले असे आवश्यक परवाने तुमच्याकडे आहेत,
                याची तुम्ही हमी देता.
              </p>
            </li>
            <li>
              <p>
                तुम्हाला हे समजले आहे की तुमच्या सामग्रीवरून उत्पादने आणि
                सेवांची तांत्रिक प्रक्रिया आणि प्रेषण, एनक्रिप्टेड हस्तांतरित
                केले जाऊ शकते आणि त्यात खालील गोष्टींचा समावेश आहे: (a) विविध
                नेटवर्कवर प्रसारित करणे; आणि (b) कनेक्टिंग नेटवर्क किंवा
                डिव्हाइसेसच्या तांत्रिक आवश्यकतांचे पालन करण्यासाठी आणि
                त्यांच्याशी जुळवून घेण्यासाठी बदल करणे.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्मवर उत्पादने आणि सेवा विकण्याचा एक भाग म्हणून, विविध
                विक्रेते प्रतिमा, वर्णन आणि इतर सामग्री पोस्ट करू शकतात.
                COVESTRO या नात्याने आम्ही केवळ मार्केटप्लेस सेवा पुरविण्यासाठी
                एक प्लॅटफॉर्म आहोत आणि त्याच्या तृतीय पक्ष विक्रेत्यांनी पोस्ट
                केलेल्या सामग्रीशी संबंधित कोणतेही उत्तरदायित्व नाही यासह, अशा
                सामग्रीची सत्यता किंवा विश्वासार्हतेच्या बाबतीत, कोणत्याही
                प्रकारे, कोणत्याही मर्यादेशिवाय आम्ही जबाबदार नाही हे तुम्ही
                यावरून कबूल करून समजून घेता.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>अॅपसाठी मर्यादित परवाना</h3>
          <ol>
            <li>
              <p>
                आम्ही तुम्हाला या कराराच्या अटींनुसार वैयक्तिक आणि
                गैर-व्यावसायिक हेतूंसाठी प्लॅटफॉर्ममध्ये प्रवेश करण्यासाठी आणि
                वापरण्यासाठी वैयक्तिक, जगभरातील, रद्द करण्यायोग्य, अ-हस्तांतरणीय
                आणि नॉन-एक्सक्लुझिव्ह परवाना देतो.
              </p>
            </li>
            <li>
              <p>
                या करारामध्ये स्पष्टपणे मंजूर न केलेले सर्व हक्क, शीर्षक आणि
                प्लॅटफॉर्ममधील स्वारस्य COVESTRO कडे राखीव आहेत. तुम्हाला
                COVESTRO चे सॉफ्टवेअर, शीर्षक, व्यापार नाव, ट्रेडमार्क, सेवा
                चिन्ह, लोगो, डोमेन नाव आणि/किंवा उल्लेखनीय ब्रँड वैशिष्ट्ये
                किंवा COVESTRO च्या मालकीच्या इतर सामग्रीसह इतर कोणतीही ओळख
                वापरायची असल्यास, तुम्हाला COVESTRO कडून लेखी परवानगी घेणे
                आवश्यक आहे. परवानगी विनंत्या{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                वर पाठवल्या जाऊ शकतात .
              </p>
            </li>
            <li>
              <p>
                कोणतीही शंका टाळण्यासाठी, COVESTRO कडे सर्व मजकूर, प्रतिमा,
                फोटो, ऑडिओ, व्हिडिओ, स्थान डेटा आणि इतर सर्व प्रकारचा डेटा किंवा
                संप्रेषण आहे जे COVESTRO प्लॅटफॉर्मच्या संबंधात तयार करते आणि
                उपलब्ध करते यासह, परंतु यापूरते मर्यादित नाही व्हिज्युअल
                इंटरफेस, परस्परसंवादी वैशिष्ट्ये, ग्राफिक्स, डिझाइन, वापरकर्ता
                सामग्रीचे संकलन आणि एकूण वापरकर्ता पुनरावलोकन रेटिंग आणि
                प्लॅटफॉर्मचे इतर सर्व घटक आणि घटकांचे संकलन, वापरकर्ता सामग्री
                (वर परिभाषित केल्याप्रमाणे) वगळून. येथे स्पष्टपणे आणि
                निःसंदिग्धपणे नमूद केल्याशिवाय, आम्ही तुम्हाला कोणतेही स्पष्ट
                किंवा निहित अधिकार देत नाही आणि प्लॅटफॉर्म आणि COVESTRO च्या
                सामग्रीमधील सर्व अधिकार आम्ही राखून ठेवलेले आहेत.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>सामग्री मानकांचे उल्लंघन</h3>
          <ol>
            <li>
              <p>
                जेव्हा सामग्री मानकांचे उल्लंघन झाले आहे (वर सांगितल्याप्रमाणे)
                असे आमच्या लक्षात येते तेव्हा, आम्ही आमच्या विवेकबुद्धीनुसार
                आम्हाला योग्य वाटेल अशी कारवाई करू शकतो. वापर अटींचे पालन करता न
                येणे हे त्यांचे भौतिक उल्लंघन आहे ज्यावर तुम्हाला सेवा
                वापरण्याची परवानगी आहे आणि आम्ही खालील सर्व किंवा कोणत्याही
                कृतींसह कायदेशीररित्या उपलब्ध असलेली कोणतीही कारवाई करू शकतो:
              </p>
              <ol>
                <li>
                  <p>
                    उत्पादने आणि सेवा वापरण्याचा तुमचा अधिकार तात्काळ, तात्पुरता
                    किंवा कायमचा काढून घेणे आणि प्लॅटफॉर्मवरील तुमचे खाते
                    एकाचवेळी संपुष्टात आणणे/स्थगित करणे;
                  </p>
                </li>
                <li>
                  <p>
                    तुमच्या लक्षात आणून दिलेले उल्लंघन तुम्हाला दुरुस्त करता न
                    आल्यास, उत्पादने आणि सेवांचा वापर करून प्लॅटफॉर्मवर आधीच
                    पोस्ट केलेले कोणतेही योगदान तात्काळ, तात्पुरते किंवा कायमचे
                    काढून टाकणे;
                  </p>
                </li>
                <li>
                  <p>तुम्हाला चेतावणी जारी करणे;</p>
                </li>
                <li>
                  <p>तुमच्या विरुद्ध पुढील कायदेशीर कारवाई करणे; </p>
                </li>
                <li>
                  <p>
                    नुकसानभरपाईच्या आधारावर (वाजवी प्रशासकीय आणि कायदेशीर
                    खर्चासह, परंतु इतकेच मर्यादित नाही) सर्व खर्चांची परतफेड
                    करण्यासाठी उल्लंघनाच्या परिणामी तुमच्या विरुद्ध कायदेशीर
                    कार्यवाही करणे; आणि
                  </p>
                </li>
                <li>
                  <p>
                    आम्हाला वाजवी वाटते किंवा कायद्याने आवश्यक आहे अशा कायद्याची
                    अंमलबजावणी करणाऱ्या अधिकाऱ्यांना अशा माहितीचा खुलासा करणे.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                आम्ही याद्वारे या नियमांच्या उल्लंघनास प्रतिसाद म्हणून करू
                शकणाऱ्या सर्व कारवायांसाठी अस्वीकरण दर्शवून दायित्व वगळतो. वर
                वर्णन केलेल्या कृती मर्यादित नाहीत आणि आम्हाला योग्य वाटेल अशी
                कोणतीही अन्य कारवाई आम्ही करू शकतो.
              </p>
            </li>
            <li>
              <p>
                तुमचे खाते अशा प्रकारे संपुष्टात आणल्यानंतर/स्थगित केल्यावर,
                तुम्ही यापुढे तुमचे खाते किंवा तुम्ही सर्व्हरवर संग्रहित केलेली
                कोणतीही सामग्री किंवा डेटा ॲक्सेस करू शकणार नाही. COVESTRO ने
                प्लॅटफॉर्म किंवा उत्पादने आणि सेवांसाठी तुम्हाला दिलेले सर्व
                परवाने स्वयंचलितपणे संपुष्टात येतील.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>बिलिंग आणि पेमेंट</h3>
          <ol>
            <li>
              <p>
                प्लॅटफॉर्मची काही वैशिष्ट्ये, फी किंवा इतर शुल्कासाठी पुरविली
                जाऊ शकतात. तुम्ही प्लॅटफॉर्मचे सशुल्क वैशिष्ट्य वापरण्याचे
                निवडल्यास, तुम्ही अशा फी आणि शुल्कांना लागू असलेल्या विक्री,
                किंमत, पेमेंट आणि बिलिंग धोरणांच्या अटींना सहमती दर्शवता.
                अतिरिक्त शुल्क आणि शुल्कांसाठी COVESTRO कोणत्याही वेळी
                स्व-विवेकबुद्धीनुसार नवीन सेवा समाविष्ट करू शकते किंवा विद्यमान
                सेवांसाठी शुल्क आणि शुल्कामध्ये सुधारणा करू शकते.
              </p>
            </li>
            <li>
              <p>
                कोणत्याही संपर्क किंवा बिलिंग माहितीमधील बदल किंवा अद्ययावत (फोन
                नंबर, ईमेल पत्ता, क्रेडिट कार्ड नंबर इ.सह) त्वरितपणे COVESTRO ला
                कळविणे ही तुमची जबाबदारी आहे. पेमेंट सुरक्षित करण्यासाठी
                ग्राहकाच्या पेमेंट प्रदात्याकडून आवश्यक असलेली सर्व क्रेडिट
                कार्ड माहिती COVESTRO सत्यापित करत नाही.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्मवर केलेल्या खरेदीच्या संदर्भात, परताव्याच्या कोणत्याही
                विनंत्या कोणत्याही प्रकारे स्वीकारल्या जाणार नाहीत आणि त्यासाठी
                कोणताही परतावा केला जाणार नाही यास तुम्ही स्वीकृती देऊन सहमती
                दर्शवता.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>नुकसानभरपाई आणि दायित्वाची मर्यादा</h3>
          <ol>
            <li>
              <p>
                कोणतेही तसेच सर्व दावे, नुकसान, दायित्वे, नुकसान, दायित्वे, खर्च
                किंवा कर्ज, आणि खर्च (यासह, परंतु इतकेच मर्यादित नाही वकिलाची
                फी) यापासून उद्भवणारे: (i) उत्पादने आणि सेवा आणि प्लॅटफॉर्मचा
                तुमचा वापर आणि प्रवेश; (ii) या अटींमधील कोणत्याही अटींचे तुमचे
                उल्लंघन; (iii) तुमचे कोणत्याही तृतीय पक्ष अधिकाराचे उल्लंघन,
                कोणत्याही मर्यादेशिवाय, कोणताही कॉपीराइट, मालमत्ता किंवा
                गोपनीयता अधिकार; किंवा (iv) तुमच्या वापरकर्ता सामग्रीमुळे तृतीय
                पक्षाचे नुकसान झाल्याचा कोणताही दावा यापासून COVESTRO, त्याचे
                अधिकारी, संचालक, कर्मचारी आणि एजंट यांचे नुकसानभरपाई देणे किंवा
                तारण राहण्यापासून तुम्ही रक्षण करणे, व उपद्रव होणार नाही यास
                तुम्ही सहमत आहात. हे संरक्षण आणि नुकसानभरपाईचे दायित्व यावर, हा
                करार आणि तुमचा उत्पादने आणि सेवा आणि प्लॅटफॉर्मचा वापर टिकून
                राहील.
              </p>
            </li>
            <li>
              <p>
                आमच्या वेबसाइट किंवा अॅपवर प्रदर्शित केलेली सामग्री त्याच्या
                अचूकतेबद्दल कोणत्याही हमी, अटी किंवा वॉरंटीशिवाय नमूद केली जाते.
                कायद्याने परवानगी दिलेल्या मर्यादेपर्यंत, आम्ही, आमच्या सहाय्यक
                कंपन्या तसेच सहयोगी आणि आमच्याशी जोडलेले तृतीय पक्ष खालील बाबी
                स्पष्टपणे वगळतो:
              </p>
              <ol>
                <li>
                  <p>
                    अटी, हमी आणि इतर अटी, ज्या अन्यथा कायदा, सामान्य कायदा किंवा
                    इक्विटी कायद्याने निहित असू शकतात;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पादने आणि सेवा, आमचा प्लॅटफॉर्म किंवा उत्पादने आणि
                    सेवांच्या वापराच्या संबंधात, वापरण्यास असमर्थता किंवा
                    परिणामांच्या संबंधात कोणत्याही वापरकर्त्याने केलेल्या
                    कोणत्याही प्रत्यक्ष, अप्रत्यक्ष किंवा परिणामी हानी किंवा
                    नुकसानासाठी कोणतेही दायित्व किंवा प्लॅटफॉर्म, त्याच्याशी
                    लिंक केलेली कोणतीही वेबसाइट आणि त्यावर पोस्ट केलेली कोणतीही
                    सामग्री यासह, परंतु इतकेच मर्यादित नाही:
                  </p>
                  <ol>
                    <li>
                      <p>उत्पन्न किंवा महसुलाचे नुकसान;</p>
                    </li>
                    <li>
                      <p>व्यवसायाचे नुकसान;</p>
                    </li>
                    <li>
                      <p>नफा किंवा कराराचे नुकसान;</p>
                    </li>
                    <li>
                      <p>अपेक्षित बचतीचे नुकसान;</p>
                    </li>
                    <li>
                      <p>डेटाचे नुकसान;</p>
                    </li>
                    <li>
                      <p>ख्यातिमूल्य (गुडविल) कमी होणे;</p>
                    </li>
                    <li>
                      <p>व्यवस्थापन किंवा कार्यालयीन वेळ वाया जाणे; आणि</p>
                    </li>
                    <li>
                      <p>
                        इतर कोणत्याही हानीसाठी किंवा कोणत्याही प्रकारच्या
                        नुकसानासाठी, तरीही उद्भवलेल्या आणि टोर्टमुळे
                        (निष्काळजीपणासह), कराराचा भंग किंवा अन्यथा, अगदी अगोदर
                        किंवा त्याच्या शक्यतेचा सल्ला दिला असला तरीही.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>
                कोणताही पक्ष करार, नुकसान (मर्यादेशिवाय, निष्काळजीपणासह) किंवा
                अन्यथा या अटींमधून किंवा त्यांच्या संबंधात उद्भवलेल्या बाबतीत
                जबाबदार असणार नाही;
                <br />
                a) कोणतेही आर्थिक नुकसान (मर्यादेशिवाय, महसूल, नफा, करार, डेटा,
                व्यवसाय, अपेक्षित बचत किंवा पर्यायी सेवांच्या खर्चासह नुकसान;
                <br /> b) ख्यातिमूल्य किंवा प्रतिष्ठेची कोणतीही हानी;
                <br /> c) कोणतेही विशेष, अप्रत्यक्ष किंवा परिणामी नुकसान; या
                अटींच्या तारखेला असे नुकसान पक्षांच्या विचारात होते की नाही.
              </p>
            </li>
            <li>
              <p>
                COVESTRO आणि त्याच्या पूर्ण मालकीच्या उपकंपन्या तुमच्या
                कामकाजातील ग्राहक डेटा किंवा डेटामध्ये अनधिकृत प्रवेश किंवा बदल
                करण्याच्या संबंधात जबाबदार असणार नाहीत किंवा कोणत्याही प्रकारे
                उत्तरदायी असणार नाहीत यास तुम्ही सहमती दर्शवता.
              </p>
            </li>
            <li>
              <p>
                उत्पादने आणि सेवांमधील कोणत्याही त्रुटी, दोष, अपयश किंवा विलंब
                यासाठी COVESTRO ची जबाबदारी असणार नाही. पूर्वी कधितरी घडले
                म्हणून आताही तसेच घडेल अशा पूर्वग्रहाशिवाय, उत्पादने आणि
                सेवांमधील कोणत्याही अपयश किंवा विलंबासाठी COVESTRO ची जबाबदारी
                असणार नाही: कोणतेही विशेष, अप्रत्यक्ष किंवा परिणामी नुकसान; या
                अटींच्या तारखेला असे नुकसान पक्षांच्या विचारात होते की नाही.
                <br />
                a) कोणत्याही सार्वजनिक इंटरनेट बॅकबोन्स, नेटवर्क किंवा
                सर्व्हरच्या आउटेजमुळे; कोणतेही विशेष, अप्रत्यक्ष किंवा परिणामी
                नुकसान; या अटींच्या तारखेला असे नुकसान पक्षांच्या विचारात होते
                की नाही.
                <br />
                b) तुमची उपकरणे, प्रणाली किंवा स्थानिक प्रवेश सेवांच्या
                कोणत्याही बिघाडामुळे किंवा
                <br />
                c) पूर्वीच्या नियोजित देखभालीमुळे.
              </p>
            </li>
            <li>
              <p>
                COVESTRO चे (आणि त्याच्या पूर्ण मालकीच्या सहाय्यक कंपन्यांचे)
                एकूण संचयी दायित्व (ते करारात असेल, टोर्ट (निष्काळजीपणासह) किंवा
                अन्यथा) या अटींखाली किंवा त्यासंदर्भात तुम्ही भरलेल्या सदस्यत्व
                शुल्काच्या 50 टक्क्यांपेक्षा जास्त नसावे.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>गुन्हे</h3>
          <ol>
            <li>
              <p>
                तुम्ही व्हायरस, ट्रोजन, वर्म्स, लॉजिक बॉम्ब, टाइम बॉम्ब, कॅन्सल
                बॉट्स, स्पायवेअर किंवा इतर साहित्य, कम्प्युटर प्रोग्रॅमिंग
                रुटीन, कोड, फाइल्स किंवा दुर्भावनापूर्ण किंवा तांत्रिकदृष्ट्या
                हानीकारक असलेल्या प्रोग्रॅम्सचा गैरवापर करू नये. इतर
                वापरकर्त्यांच्या हक्कांचे हित किंवा कोणत्याही कम्प्युटर
                सॉफ्टवेअर, हार्डवेअर किंवा टेलिक्म्युनिकेशन्सच्या कार्यक्षमतेवर
                मर्यादा घालणे अशासाठी प्लॅटफॉर्मचा जाणूनबुजून गैरवापर करू नये.
                सर्व्हरवर प्लॅटफॉर्म संग्रहित आहे किंवा आमच्या साइटशी कनेक्ट
                केलेला कोणताही सर्व्हर, कम्प्युटर किंवा डेटाबेसमध्ये तुम्ही
                प्लॅटफॉर्मवर अनधिकृत प्रवेश मिळवण्याचा प्रयत्न करू नये. तुम्ही
                प्लॅटफॉर्मवर डिनायल-ऑफ-सर्व्हिस अॅटॅक किंवा
                डिस्ट्रिब्यूटेड-डिनायल-ऑफ-सर्व्हिस अॅटॅक असे हल्ले करू नये.
              </p>
            </li>
            <li>
              <p>
                या तरतुदीचे उल्लंघन करून, तुम्ही माहिती तंत्रज्ञान कायदा, 2000
                (आणि कोणत्याही सुधारणा) अंतर्गत फौजदारी गुन्हा कराल. आम्ही अशा
                कोणत्याही उल्लंघनाची तक्रार संबंधित कायद्याची अंमलबजावणी
                करणाऱ्या अधिकाऱ्यांना करू आणि आम्ही त्या अधिकाऱ्यांना तुमची ओळख
                उघड करून त्यांना सहकार्य करू. असे उल्लंघन झाल्यास, प्लॅटफॉर्म
                वापरण्याचा तुमचा अधिकार ताबडतोब बंद होईल.
              </p>
            </li>
            <li>
              <p>
                तुमच्या प्लॅटफॉर्मच्या वापरामुळे तुमचा कम्प्युटर, कम्प्युटर
                उपकरणे, कम्प्युटर प्रोग्रॅम, डेटा किंवा इतर मालकी सामग्री
                संक्रमित होऊ शकणाऱ्या डिस्ट्रिब्यूटेड-डिनायल-ऑफ-सर्व्हिस अॅटॅक,
                व्हायरस किंवा इतर तांत्रिकदृष्ट्या हानीकारक सामग्रीमुळे झालेल्या
                कोणत्याही हानी किंवा नुकसानासाठी त्यावर पोस्ट केलेल्या कोणतीही
                सामग्री डाउनलोड करण्यासाठी किंवा तीच्याशी लिंक केलेल्या
                कोणत्याही प्लॅटफॉर्मसाठी आम्ही जबाबदार राहणार नाही.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>मोबाइल अॅपसाठी अतिरिक्त अटी</h3>
          <ol>
            <li>
              <p>
                तुम्हाला किंवा इतर वापरकर्त्यांना वेबसाइट किंवा अॅप आणि/किंवा
                उत्पादने आणि सेवांमध्ये प्रवेश करण्यास कार्यक्षम करण्याच्या
                उद्देशाने तृतीय पक्ष सॉफ्टवेअर किंवा वेबसाइटच्या माध्यमातून
                तुम्हाला मोबाइल अॅप, डेस्कटॉप अॅप, उत्पादने, सेवा आणि डेटामध्ये
                प्रवेश करण्याचा मर्यादित, उप-परवाना नसलेला अधिकार देण्यात आला
                आहे. मोबाइल अॅप, डेस्कटॉप अॅपचा कोणताही वापर नॉन-सब-लायसेंसिबल
                मर्यादित अधिकार या कराराच्या अटींसह खालील विशिष्ट अटींनी बांधील
                आहे:
              </p>
              <ol>
                <li>
                  <p>
                    कोणत्याही तृतीय-पक्ष सॉफ्टवेअरवरून पुरवता त्या कोणत्याही
                    सेवा आणि/किंवा उत्पादने आणि सेवांसाठी तुम्ही पूर्णपणे
                    जबाबदार आहात (आणि आमच्यासाठी तुम्ही किंवा कोणत्याही तृतीय
                    पक्षाची कोणतीही जबाबदारी किंवा दायित्व नाही) यास तुम्ही
                    सहमती दर्शवता.
                  </p>
                </li>
                <li>
                  <p>
                    तुम्ही कोणत्याही तृतीय पक्षाला अवांछित संप्रेषणे (व्यावसायिक
                    किंवा अन्यथा) पाठवणारे सॉफ्टवेअर तयार करण्यासाठी मोबाइल अॅप
                    किंवा डेस्कटॉप अॅप वापरणार नाही;
                  </p>
                </li>
                <li>
                  <p>
                    आम्ही कधीही 48 (अठ्ठेचाळीस) तासांची सूचना देऊन मोबाईल अॅप
                    आणि डेस्कटॉप अॅपवरील तुमचा प्रवेश तात्पुरते किंवा
                    कायमस्वरूपी बदलण्याचा किंवा बंद करण्याचा अधिकार राखून ठेवतो;
                  </p>
                </li>
                <li>
                  <p>
                    तुम्ही स्पष्टपणे समजता आणि सहमत आहात की आम्ही कोणत्याही
                    प्रत्यक्ष, अप्रत्यक्ष, आनुषंगिक, विशेष, परिणामी किंवा
                    अनुकरणीय हानीसाठी जबाबदार राहणार नाही, ज्यामध्ये
                    ख्यातीमूल्य, वापर, डेटा किंवा इतर अमूर्त नुकसान यासह, परंतु
                    इतकेच मर्यादित नाही. तुमच्या मोबाइल अॅप आणि डेस्कटॉप अॅपच्या
                    वापरामुळे होणारे नफा नुकसान (जरी आम्हाला अशा नुकसानीच्या
                    संभाव्यतेबद्दल सूचित केले गेले असले तरीही);
                  </p>
                </li>
                <li>
                  <p>
                    आम्ही कोणत्याही वेळी 48 (अठ्ठेचाळीस) तासांची सूचना देऊन
                    किंवा त्याशिवाय नोटीस देऊन, तात्पुरते किंवा कायमचे डेस्कटॉप
                    अॅप आणि मोबाइल अॅप सुधारित किंवा बंद करण्याचा अधिकार राखून
                    ठेवतो;
                  </p>
                </li>
                <li>
                  <p>
                    डेस्कटॉप अॅप आणि मोबाइल अॅपवरून उत्पादने आणि सेवांचा गैरवापर
                    किंवा जास्त वारंवार विनंत्या केल्यामुळे तुमच्या खात्याचा
                    प्रवेश तात्पुरता किंवा कायमचा स्थगित होऊ शकतो. आम्ही, आमच्या
                    विवेकबुद्धीनुसार, दुरुपयोग किंवा अतिरिक्त वापर निश्चित करू;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>अधिकृत प्रवेश</h3>
          <ol>
            <li>
              <p>
                आम्ही तुमच्या सोयीची आणि वेळेची काळजी घेतो, ज्यासाठी आम्ही सतत
                प्रयत्न करतो आणि खात्री करतो की प्लॅटफॉर्म नेहमी चालू आहे आणि
                उपलब्ध आहे, तथापि, तांत्रिक त्रुटी आणि इतर गैर-मानवी त्रुटी
                उद्भवणे स्वाभाविक आहे.
              </p>
            </li>
            <li>
              <p>
                तुमचा वापरानुभव निरंतर राखण्यासाठी, आम्ही
                प्लॅटफॉर्म/सर्व्हर/लिंक/वेब-पेज पूर्णपणे किंवा अंशतः बंद
                करण्याचा अधिकार राखून ठेवतो, अशा कालावधीसाठी सामान्य
                देखरेखीसाठी, परंतु तरीही तुमच्या किमान अपेक्षांची पूर्तता
                करण्यासाठी डाउन-टाइम ठेवण्याचा प्रयत्न करू.
              </p>
            </li>
            <li>
              <p>
                जर प्लॅटफॉर्म, संपूर्णपणे किंवा त्याचा कोणताही भाग, देखभालीच्या
                कारणास्तव अशा कालावधीसाठी अनुपलब्ध असेल तर, आम्ही कोणत्याही
                कारणास्तव जबाबदार राहणार नाही, याची तुम्ही पुष्टी करता व मान्य
                करता की तुम्ही हे समजून घेतले आहे.
              </p>
            </li>
            <li>
              <p>
                आमच्या सेवांच्या वापरासाठी, आमच्या सुरक्षा प्रक्रियेचा एक भाग
                म्हणून, आम्हाला तुमचा ईमेल किंवा मोबाइल नंबर प्रविष्ट करून आणि
                सुरक्षित पासवर्ड निवडून आणि/किंवा तुमच्या मोबाइल नंबर किंवा
                ईमेलवर पाठवलेला वन टाइम पासवर्ड (OTP) प्रविष्ट करून आमच्याकडे
                खाते नोंदणी करणे आवश्यक आहे. वापरकर्ता पासवर्डच्या संदर्भात
                तुम्ही इतरांना अपेक्षित नसलेला एखादा क्लिष्ट पासवर्ड निवडण्याची
                आणि प्रत्येक सत्राच्या शेवटी तुमच्या खात्यातून लॉग आउट करण्याची
                आम्ही शिफारस करतो. तुम्ही तुमचा पासवर्ड विसरल्यास सोयीसाठी,
                पासवर्ड रीसेट करण्याची प्रक्रिया उपलब्ध आहे. तुम्ही तुमची खाते
                लॉग-इन माहिती कोणत्याही तृतीय पक्षाला न सांगता वैयक्तिकरित्या
                वापरून ती गोपनीय मानाल यास तुम्ही सहमती दर्शवता. जर तुम्हाला
                वाटत असेल की सुरक्षेचा भंग झाला असेल तर आम्हाला ताबडतोब सूचित
                करणे आणि जेथे लॉग-इन अवरोधित केलेले नाही तेथे तुमचा पासवर्ड
                त्वरित बदलणे ही तुमची जबाबदारी आहे.
              </p>
            </li>
            <li>
              <p>
                तुमच्या प्लॅटफॉर्मच्या वापरासाठी ही एक अट आहे की तुम्ही
                प्लॅटफॉर्मवर दिलेली सर्व माहिती योग्य, सध्याची आणि परिपूर्ण आहे.
                अशा कारणास्तव, आमच्या मते आणि संपूर्ण विवेकबुद्धीनुसार, तुम्ही
                या सेवा अटींच्या कोणत्याही तरतुदीचे पालन करण्यात अयशस्वी झाल्यास
                आम्ही आमच्या विवेकबुद्धीनुसार कोणतेही वापरकर्ता खाते अक्षम
                करण्याचा अधिकार राखून ठेवतो.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्म हे कोणत्याही मानवी हस्तक्षेपाशिवाय चालवलेले ऑपरेशन
                आहे हे तुम्हाला मान्य असून तुम्ही त्याद्वारे हे मान्य करता आणि
                समजता की प्लॅटफॉर्म किंवा प्लॅटफॉर्मवर देलेली कोणतीही सामग्री
                त्रुटीमुक्त नाही, तसेच याद्वारे संमती देता की कोणतीही गैरसोय
                किंवा नुकसान मानवी हस्तक्षेपामुळे झाल्याशिवाय ते सेवेचे उल्लंघन
                किंवा त्यामधील कमतरता म्हणून मानले जाणार नाही.
              </p>
            </li>
          </ol>
        </li>
        <p>
          COVESTRO द्वारे हे हमीपात्र आहे की सर्व तांत्रिक त्रुटी, ज्यात अशक्य
          घटना किंवा आपत्तीजनक बिघाड, किंवा नैसर्गिक आपत्ती किंवा आग, सरकारी
          आदेश, दंगल किंवा COVESTRO च्या नियंत्रणाबाहेरील असे इतर घटक घटक 90 ते
          120 तासांच्या आत दुरुस्त केले जातील. तथापि, काही तांत्रिक त्रुटी,
          नैसर्गिक आपत्ती, सरकारी आदेश, दंगल किंवा इतर कोणत्याही घटनेमुळे
          वेबसाइटवर प्रवेश न दिल्याबद्दल COVESTRO ला जबाबदार धरले जाऊ शकत नाही.
        </p>
        <li>
          <h3>गोपनीयता</h3>
          <ol>
            <li>
              <p>
                या अटींच्या उद्देशांसाठी, “गोपनीय माहिती”मध्ये कोणत्याही मालकीचा
                डेटा आणि एका पक्षाने दुसऱ्या पक्षाकडे लिखित स्वरूपात उघड केलेली
                आणि “गोपनीय” म्हणून चिन्हांकित केलेली किंवा तोंडी उघड केलेली
                कोणतीही माहिती समाविष्ट असून ती पाच व्यावसायिक दिवसांमध्ये लिखित
                स्वरूपात करून “गोपनीय” म्हणून चिन्हांकित केली आहे. तथापि, गोपनीय
                माहितीमध्ये अशी कोणतीही माहिती समाविष्ट केली जाणार नाही जी
                सामान्य जनतेला माहिती आहे किंवा ज्ञात झाली आहे, जी पक्षाद्वारे
                उघड करण्यापूर्वी प्राप्तकर्त्याच्या ताब्यात आहे किंवा जी गोपनीय
                माहितीचा वापर न करता किंवा प्राप्तकर्त्या पक्षाने स्वतंत्रपणे
                विकसित केली आहे. या अटींमध्ये वर्णन केल्यानुसार माहितीच्या
                COVESTRO द्वारे वापरण्यास परवानगी आहे.
              </p>
            </li>
            <li>
              <p>
                कोणताही पक्ष या अटींनुसार आपली जबाबदारी पार पाडण्याच्या
                उद्देशाशिवाय किंवा कायदा, नियमन किंवा न्यायालयाच्या आदेशानुसार
                आवश्यक असल्यास इतर पक्षाच्या पूर्व लेखी संमतीशिवाय इतर पक्षाची
                गोपनीय माहिती वापरणार नाही किंवा उघड करणार नाही; अशा परिस्थितीत,
                ज्या पक्षाला गोपनीय माहिती उघड करण्यास भाग पाडले जात आहे, तो इतर
                पक्षाला गोपनीय माहिती उघड करण्यापूर्वी वाजवी व्यवहार्य असेल
                तितकी सूचना देईल.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>गोपनीयता आणि वैयक्तिक डेटाचे संरक्षण</h3>
          <ol>
            <li>
              <p>
                साइटवर नोंदणी करून, तुम्ही आम्हाला प्रामाणिक आणि खरी माहिती
                देण्यास सहमत होता.
              </p>
            </li>
            <li>
              <p>
                खोटी माहिती संप्रेषण करणे सध्याच्या सामान्य अटी आणि नियमांच्या
                विरुद्ध आहे आणि परिणामी कायदेशीर कारवाईसाठी तुम्हाला जबाबदार
                धरले जाईल.
              </p>
            </li>
            <li>
              <p>
                आम्ही तुमच्याबद्दल कोणती वैयक्तिक माहिती ठेवतो हे शोधण्यासाठी
                तुम्ही आम्हाला कधीही विनंती करू शकता. तुम्ही कधीही, आणि
                विनंतीनुसार, ही माहिती सुधारू शकता.
              </p>
            </li>
            <li>
              <p>
                तुमच्या हार्ड ड्राइव्हमध्ये संग्रहित केलेली काही माहिती
                कुकीजमध्ये रेकॉर्ड केली जाते. या माहितीमुळे आम्हाला आमच्या
                साइटवर आलेल्या अभ्यागतांच्या डेटावर आधारित आकडेवारी जनरेट करणे
                शक्य होते आणि तुम्ही साइटला आधी भेट दिली होती तेव्हा तुम्ही
                निवडलेल्या आयटम्सनुसार तुम्हाला स्वारस्य वाटेल अशी उत्पादने आणि
                सेवा सुचवू शकतात. कुकीजमध्ये तुमच्याबद्दल कोणतीही गोपनीय माहिती
                नसते.
              </p>
            </li>
            <li>
              <p>
                काही सेवांचा वापर करण्यासाठी प्लॅटफॉर्मला तुम्हाला डेटा
                पुरविण्याची आवश्यकता असू शकते. माहिती अशा बाबींशी संबंधित असू
                शकते, यासह, परंतु तुमच्या ऑर्डरशी संबंधित तपशीलांपुरते मर्यादित
                नाही. जरी अशा माहितीची गोपनीयता हा आमच्या अटींचा आधारस्तंभ आहे,
                तथापि, तुम्ही कबूल करता, सहमत होता आणि संमती देता की आम्ही
                आमच्या गोपनीयता धोरणाच्या अटींच्या अधीन राहून ( येथे असलेली
                (देण्यात येणारी लिंक{' '}
                <a href={`${APP_DOMAIN}legal/privacy-statement`}>
                  {APP_DOMAIN}legal/privacy-statement
                </a>{' '}
                , आमच्या व्यवसाय, संशोधन आणि विश्लेषणासाठी तुम्ही उत्पादने आणि
                सेवांच्या वापरामध्ये गोळा केलेली माहिती किंवा डेटा यांचा वापर
                करू शकतो.
              </p>
            </li>
            <li>
              <p>
                आम्ही तुमचा डेटा किंवा कोणत्याही तृतीय पक्षाचा ग्राहक डेटा
                कोणत्याही तृतीय पक्षांसोबत सामायिक करणार नाही, जोपर्यंत या
                अटींनुसार आम्ही (i) निष्कर्ष काढत नाही की तो कायद्याने आवश्यक
                आहे किंवा डेटाचा प्रवेश, जतन किंवा प्रकटीकरण असा सद्भावनापूर्ण
                विश्वास आहे. आमच्या अधिकारांचे, मालमत्तेचे किंवा सुरक्षिततेचे
                किंवा आमच्या वापरकर्त्यांचे किंवा जनतेचे संरक्षण करण्यासाठी
                वाजवीपणे आवश्यक आहे; किंवा (ii) आमच्या वतीने (उदा. बिलिंग किंवा
                डेटा स्टोरेज) कार्ये पार पाडण्यासाठी तृतीय पक्षांना काही
                मर्यादित परिस्थितीत डेटा पुरावा असे आम्ही निर्देशित केल्याशिवाय,
                डेटा वापरण्यास किंवा सामायिक करण्यापासून प्रतिबंधित करतात.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>बौद्धिक संपदा</h3>
          <ol>
            <li>
              <p>
                आमच्या प्लॅटफॉर्मवरील सर्व सामग्री (चित्रे, मजकूर, नावे, ब्रँड
                नावे, प्रतिमा आणि व्हिडिओ) ही COVESTRO ची मालमत्ता आहे.
              </p>
            </li>
            <li>
              <p>
                या सामग्रीचे कोणतेही आंशिक किंवा संपूर्ण पुनर्निर्मिती,
                कोणत्याही प्रकारे आणि कोणत्याही समर्थनाद्वारे, COVESTRO द्वारे
                पूर्व आणि स्पष्ट अधिकृततेच्या अधीन आहे.
              </p>
            </li>
            <li>
              <p>
                सर्व माहिती, सामग्री आणि फाइल्स बौद्धिक मालकी आणि कॉपीराइट
                संबंधित कायद्याद्वारे संरक्षित आहेत.
              </p>
            </li>
            <li>
              <p>
                आम्ही तुम्हाला बौद्धिक अधिकार नसलेली सामग्री कॉपी, प्रदर्शित
                किंवा वितरीत करण्याचा अधिकार देणार नाही.
              </p>
            </li>
            <li>
              <p>
                या सामग्रीचा सर्व फसव्या वापरामुळे तृतीय पक्षाच्या अधिकारांचे
                उल्लंघन होईल, हा खोटारडेपणाचा गुन्हा आहे, ज्याला बौद्धिक संपदा
                कायद्यांतर्गत कठोर शिक्षा दिली जाते.
              </p>
            </li>
            <li>
              <p>
                वापरकर्त्याने साइटवरील त्याच्या क्रियाकलापांनी तृतीय पक्षाच्या
                अधिकारांचे उल्लंघन केल्यास आम्ही कोणत्याही परिस्थितीत जबाबदार
                राहणार नाही.
              </p>
            </li>
            <li>
              <p>
                कंपनी किंवा उक्त डेटा, प्रतिमा आणि सामग्रीचे अधिकार धारकांच्या
                संमतीशिवाय वापरकर्त्यांना कॉपीराइट कायद्यांमध्ये निर्दिष्ट
                केल्याप्रमाणे, किंवा ज्याला इतर कायद्यांद्वारे स्पष्टपणे परवानगी
                असल्याशिवाय वैयक्तिक वापराव्यतिरिक्त इतर कोणत्याही हेतूसाठी या
                प्लॅटफॉर्मवरून मिळविलेला डेटा, प्रतिमा आणि इतर कोणत्याही
                प्रकारच्या सामग्रीचा सर्व किंवा काही भाग वापरणे, हस्तांतरित
                करणे, कॉपी करणे, वितरित करणे प्रतिबंधित आहे.
              </p>
            </li>
            <li>
              <p>
                साइटवर पोस्ट केलेल्या किंवा प्रसारित केलेली सामग्री, किंवा
                साइटवर जाहिरात केल्लेले आयटम्स, अंतिम वापरकर्त्यांने किंवा इतर
                कोणत्याही तृतीय पक्षाने उद्भवलेल्या बौद्धिक संपदा अधिकारांच्या
                कोणत्याही उल्लंघनासाठी COVESTRO जबाबदार राहणार नाही.
              </p>
              <ol>
                <li>
                  <p>
                    जर तुम्ही बौद्धिक संपदा अधिकारांचे मालक असाल किंवा बौद्धिक
                    संपदा हक्कांच्या मालकाच्या वतीने कार्य करण्यासाठी पूर्णपणे
                    अधिकृत असे एजंट असाल आणि कोणतीही सामग्री किंवा इतर सामग्री
                    तुमच्या बौद्धिक संपत्ती अधिकाराचे किंवा बौद्धिक संपत्ती
                    अधिकाराचे उल्लंघन करत असल्याची तुम्हाला खात्री असल्यास, ज्या
                    मालकाच्या वतीने तुम्ही कार्य करण्यास अधिकृत आहात,
                    त्यासंदर्भात तुम्ही COVESTRO ला पुढील ईमेल आयडीवर संबंधित
                    सामग्री हटवण्याच्या विनंतीचा ईमेल सबमिट करू शकता:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>वॉरंटीजचे अस्वीकरण</h3>
          <ol>
            <li>
              <p>
                COVESTRO कोणत्याही वेळी, सूचना देऊन किंवा त्याशिवाय उत्पादने आणि
                सेवा किंवा सॉफ्टवेअरमध्ये सुधारणा आणि/किंवा बदल करू शकते. सेवा,
                सॉफ्टवेअर आणि वापराशी संबंधित अहवाल &quot;जसे आहे तसे&quot;
                प्रदान केले जातात आणि COVESTRO त्यांपैकी कोणत्याही संदर्भात
                कोणतीही हमी देत नाही. इतकेच मर्यादित नाही तर, पूर्वी कधितरी घडले
                म्हणून आताही तसेच घडेल अशा पूर्वग्रहाशिवाय, COVESTRO हे
                प्रतिनिधित्व करत नाही किंवा हमी देत नाही की:
                <br />
                a) उत्पादने आणि सेवा किंवा सॉफ्टवेअरचा वापर तुमच्या आवश्यकता
                पूर्ण करेल किंवा अखंड, सुरक्षित किंवा त्रुटीमुक्त असेल;
                <br />
                b) दोष दुरुस्त केले जातील;
                <br />
                c) सर्व्हरवरील सेवा, सॉफ्टवेअर किंवा इतर कोणतेही सॉफ्टवेअर
                व्हायरस किंवा इतर हानिकारक घटकांपासून मुक्त आहेत;
                <br />
                d) उत्पादने आणि सेवांचा वापर आणि उत्पादने आणि सेवांद्वारे उपलब्ध
                असलेली कोणतीही माहिती (वापराशी संबंधित अहवालांसह) योग्य, अचूक,
                वेळेवर किंवा अन्यथा विश्वसनीय असेल.
              </p>
            </li>
            <li>
              <p>
                आम्ही तुमची वैयक्तिक माहिती आणि वापरकर्ता सामग्री अपघाती नुकसान
                आणि अनधिकृत प्रवेश, वापर, बदल किंवा प्रकटीकरण यापासून सुरक्षित
                करण्यासाठी डिझाइन केलेले व्यावसायिकदृष्ट्या वाजवी तांत्रिक आणि
                संस्थात्मक उपाय लागू केले आहेत. तथापि, आम्ही हमी देऊ शकत नाही की
                अनधिकृत तृतीय पक्ष कधीही त्या उपायांना पराभूत करू शकणार नाहीत
                किंवा तुमची वैयक्तिक माहिती आणि वापरकर्ता सामग्री अयोग्य
                हेतूंसाठी वापरू शकणार नाहीत. तुम्ही तुमची वैयक्तिक माहिती
                तुमच्या जोखमीवर देत असल्याचे तुम्ही कबूल करता.
              </p>
            </li>
            <li>
              <p>
                संबंधित वस्तू किंवा सेवांच्या देयकासह, उत्पादने आणि सेवांवर
                किंवा प्लॅटफॉर्मवरील विक्रेत्यांकडून किंवा त्याद्वारे आढळलेल्या
                जाहिरातदारांसोबतचा तुमचा पत्रव्यवहार किंवा व्यावसायिक व्यवहार
                किंवा जाहिरातींमध्ये सहभाग, आणि अशा संबंधित इतर कोणत्याही अटी,
                शर्ती, वॉरंटी किंवा प्रतिनिधित्व व्यवहार, केवळ तुमच्या आणि अशा
                जाहिरातदार किंवा विक्रेता यांच्यात आहेत. येथे नमूद
                केल्याप्रमाणे, अशा कोणत्याही व्यवहाराच्या परिणामी किंवा अशा
                कोणत्याही व्यवहाराचा परिणाम म्हणून किंवा अशा जाहिरातदारांच्या
                किंवा उत्पादने आणि सेवा/मार्केटप्लेसवरील विक्रेत्यांच्या
                उपस्थितीचा परिणाम म्हणून झालेल्या कोणत्याही नुकसान किंवा
                हानीसाठी COVESTRO जबाबदार किंवा उत्तरदायी असणार नाही यास तुम्ही
                सहमती दर्शवता.
              </p>
            </li>
            <li>
              <p>
                उत्पादने आणि सेवा, किंवा तृतीय पक्ष इतर वेबसाइट्स किंवा
                संसाधनांच्या लिंक देऊ शकतात. अशा साइट्स आणि संसाधनांवर आमचे
                कोणतेही नियंत्रण नाही हे लक्षात घेऊन, आम्ही अशा बाह्य साइट्स
                किंवा संसाधनांच्या उपलब्धतेसाठी जबाबदार नाही आणि कोणत्याही
                सामग्री, जाहिराती, उत्पादने आणि सेवा किंवा अशा साइट किंवा
                संसाधनांवर किंवा उपलब्ध इतर साहित्यास आम्ही जबाबदार किंवा
                उत्तरदायी नाही यास तुम्ही स्वीकृती देऊन त्यास सहमती दर्शवता.
                तसेच, अशा कोणत्याही सामग्री, वस्तू किंवा सेवांवर किंवा
                त्याद्वारे उपलब्ध असलेल्या अशा कोणत्याही सामग्री, वस्तू किंवा
                सेवांच्या वापरामुळे किंवा त्यावर अवलंबून राहिल्यामुळे किंवा अशी
                कोणतीही साइट किंवा संसाधन, त्याद्वारे झालेल्या कोणत्याही नुकसान
                किंवा नुकसानीसाठी आम्ही प्रत्यक्ष किंवा अप्रत्यक्षपणे जबाबदार
                किंवा उत्तरदायी असणार नाही यास तुम्ही स्वीकृती देऊन त्यास सहमती
                दर्शवता.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्म आणि उत्पादने आणि सेवांचा तुमचा वापर संपूर्ण तुमच्या
                जोखमीवर असेल यास तुम्ही सहमती दर्शवता. व्यापारीतेची हमी, विशिष्ट
                हेतूसाठी फिटनेस, शीर्षक, गैर-उल्लंघन, आणि व्यवहार किंवा
                व्यापाराच्या वापरामुळे उद्भवलेल्या कोणत्याही प्रकारच्या हमीशिवाय
                स्पष्ट किंवा निहित, मर्यादेशिवाय लागू कायद्याने परवानगी दिलेल्या
                कमाल मर्यादेपर्यंत, प्लॅटफॉर्म, उत्पादने आणि सेवा तुमच्या
                वापरासाठी &quot;जसे आहे तसे&quot; आणि &quot;जसे उपलब्ध&quot;
                आधारावर पुरविल्या जातात याची तुम्हाला पूर्णपणे कल्पना असून
                तुम्ही त्याच्याशी सहमत आहात.
              </p>
            </li>
            <li>
              <p>
                तुम्ही प्लॅटफॉर्मवर पोस्ट केलेली किंवा अपलोड केलेली कोणतीही
                सामग्री त्यांच्या बौद्धिक संपदा अधिकारांचे किंवा त्यांच्या
                गोपनीयतेच्या अधिकाराचे उल्लंघन करते असा दावा करणाऱ्या आणि पुरावा
                देणाऱ्या कोणत्याही तृतीय पक्षाला तुमची ओळख उघड करण्याचा आम्हाला
                अधिकार आहे.
              </p>
            </li>
            <li>
              <p>
                तुम्ही किंवा उत्पादने आणि सेवांच्या इतर कोणत्याही वापरकर्त्याने
                पोस्ट केलेल्या कोणत्याही सामग्रीच्या किंवा सामग्रीच्या
                अचूकतेसाठी आम्ही कोणत्याही तृतीय पक्षास जबाबदार किंवा उत्तरदायी
                नाही.
              </p>
            </li>
            <li>
              <p>
                या करारनामा किंवा गोपनीयता धोरणाचे पालन न केल्यास, तुमचा प्रवेश
                किंवा वापर अधिकार ताबडतोब संपुष्टात आणण्याचा आणि गैर-अनुपालक
                माहिती किंवा सामग्री काढून टाकण्याचा आम्हाला अधिकार आहे.
              </p>
            </li>
            <li>
              <p>
                आम्हाला या अटी आणि शर्तींच्या उल्लंघनाची चौकशी करण्याचा आणि
                कायद्याच्या पूर्ण मर्यादेपर्यंत खटला चालवण्याचा अधिकार आहे आणि
                या अटी आणि शर्तींचे उल्लंघन करणाऱ्या वापरकर्त्यांवर कारवाई
                करण्यात कायद्याची अंमलबजावणी करणाऱ्या अधिकाऱ्यांचा सहभाग आणि
                सहकार्य करू शकतो.
              </p>
            </li>
            <li>
              <p>
                उत्पादने आणि सेवांच्या (किंवा कोणतेही वैशिष्ट्य किंवा उत्पादने
                आणि सेवांचा भाग) तुमच्या प्रवेशावर किंवा तुमच्या वापरावर लक्ष
                ठेवण्याचे आमचे कोणतेही बंधन नसताना, आम्हाला प्लॅटफॉर्म ऑपरेट
                करण्याच्या आणि उत्पादने पुरवण्याच्या द्देशाने आणि सेवा, या अटी
                आणि शर्तींचे पालन सुनिश्चित करण्यासाठी किंवा लागू कायद्याचे
                किंवा न्यायालय, प्रशासकीय एजन्सी किंवा इतर सरकारी संस्थेच्या
                आदेशाचे किंवा आवश्यकतांचे पालन करण्यासाठी तसे करण्याचा अधिकार
                आहे.
              </p>
            </li>
            <li>
              <p>
                वापरकर्त्याकडून आलेल्या तक्रारीच्या अधीन राहून, आम्हाला
                कोणत्याही सेवेतील कोणतीही किंवा सर्व सामग्री पूर्व-स्क्रीन,
                पुनरावलोकन, ध्वजांकन, फिल्टर, सुधारित, नकार किंवा काढून
                टाकण्याचा अधिकार (परंतु कोणतेही बंधन नाही) आहे. काही उत्पादने
                आणि सेवांसाठी, COVESTRO स्वतःच्या विवेकबुद्धीनुसार (परंतु त्याचे
                कोणतेही बंधन नाही) सुस्पष्ट लैंगिक सामग्री किंवा कोणत्याही
                सामग्रीला अयोग्य वाटणारी कोणतीही सामग्री फिल्टर करण्यासाठी साधने
                पुरवू शकते.
              </p>
            </li>
            <li>
              <p>
                आम्ही कोणत्याही माहितीमध्ये प्रवेश करण्याचा, ती वाचण्याचा, जतन
                करण्याचा आणि उघड करण्याचा अधिकार राखून ठेवतो कारण आम्हाला
                वाजवीपणे वाटते की हे पुढील कारणांसाठी आवश्यक आहे: (a) कोणताही
                लागू कायदा, नियमन, कायदेशीर प्रक्रिया किंवा सरकारी विनंती पूर्ण
                करणे; (b) संभाव्य उल्लंघनाच्या तपासासह या अटी व शर्तींची
                अंमलबजावणी करणे; (c) फसवणूक, सुरक्षा किंवा तांत्रिक समस्या
                शोधणे, प्रतिबंध करणे किंवा अन्यथा संबोधित करणे; (d) वापरकर्ता
                समर्थन विनंत्यांना प्रतिसाद देणे; किंवा (e) COVESTRO, त्याचे
                वापरकर्ते आणि जनतेच्या हक्कांचे, मालमत्तेचे किंवा सुरक्षिततेचे
                संरक्षण करणे. या संदर्भात या अटी व शर्तींच्या अंतर्गत त्याच्या
                अधिकारांचा वापर किंवा वापर न केल्याबद्दल COVESTRO जबाबदार किंवा
                उत्तरदायी असणार नाही.
              </p>
            </li>
            <li>
              <p>
                आमच्याकडून 30 (तीस) दिवसांच्या सूचनेवर आम्ही सर्व सेवांच्या
                किमती सादर करण्याचा किंवा बदलण्याचा अधिकार राखून ठेवतो. अशी
                सूचना कधीही प्लॅटफॉर्मवरच वापराच्या अटी आणि शर्ती किंवा उत्पादने
                आणि सेवांमध्ये बदल पोस्ट करून देता येऊ शकते.
              </p>
            </li>
            <li>
              <p>
                उत्पादने आणि सेवांच्या कोणत्याही फेरफार, किंमतीतील बदल, निलंबन
                किंवा खंडित करण्यासाठी COVESTRO तुम्हाला किंवा कोणत्याही तृतीय
                पक्षास जबाबदार राहणार नाही.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>वापरकर्ता सबमिशन आणि तृतीय पक्षांकडील वापरकर्ता सामग्री</h3>
          <ol>
            <li>
              <p>
                प्लॅटफॉर्म वापरताना, तुम्हाला विविध स्त्रोतांकडून सामग्री उघड
                केली जाईल आणि COVESTRO अशा सामग्रीच्या अचूकतेसाठी,
                उपयुक्ततेसाठी, सुरक्षिततेसाठी किंवा बौद्धिक संपदा अधिकारांसाठी
                जबाबदार नाही याची तुम्हाला कल्पना आहे किंवा अशा सामग्रीशी
                संबंधित, आणि तुम्ही सहमती दर्शवून तुमच्या वापरासाठी सर्व दायित्व
                गृहीत धरता.
              </p>
            </li>
            <li>
              <p>
                COVESTRO ची काळजी कमी करण्यासाठी सर्वोतोपरी काम करण्याचा प्रयत्न
                करत असताना, आपण पुढे समजून घेता आणि कबूल करता की तुम्ही
                चुकीच्या, आक्षेपार्ह, असभ्य, किंवा आक्षेपार्ह, बदनामीकारक किंवा
                अपमानास्पद सामग्रीच्या संपर्कात येऊ शकता आणि तुम्ही माफी देण्यास
                सहमत आहात आणि याद्वारे त्या संदर्भात COVESTRO विरुद्ध तुमच्याकडे
                असलेले कोणतेही कायदेशीर किंवा न्याय्य हक्क किंवा उपाय सोडून
                देण्यास तयार आहात.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>माफी आणि पृथक्करण</h3>
          <ol>
            <li>
              <p>
                सेवा अटींच्या कोणत्याही अधिकाराचा किंवा तरतुदीचा वापर किंवा
                अंमलबजावणी करण्यात COVESTRO ला आलेल्याकोणत्याही अपयशाचा अशा
                अधिकाराचा किंवा तरतुदीचा माफीमध्ये समावेश होणार नाही.
              </p>
            </li>
            <li>
              <p>
                सेवा अटींच्या माध्यमातून तुमच्या आणि आमच्यामधील संपूर्ण करार
                बद्ध केला जातो आणि त्यायोगे कोणतेही पूर्वीचे करार (सेवा अटींच्या
                कोणत्याही आधीच्या आवृत्त्यांसह, परंतु इतकेच मर्यादित नाही) सोडून
                तुमच्या सेवेच्या वापरावर नियंत्रण ठेवले जाते. या सेवा अटींची
                कोणतीही तरतूद सक्षम अधिकारक्षेत्राच्या न्यायालयाकडून अवैध,
                बेकायदेशीर किंवा कोणत्याही कारणास्तव लागू करण्यायोग्य नसल्यास,
                अशी तरतूद काढून टाकली जाईल किंवा किमान मर्यादेपर्यंत सीमित केली
                जाईल जेणेकरून सेवा अटींच्या उर्वरित तरतुदी संपूर्ण कार्यक्षमतेवर
                आणि प्रभावीपणे चालू राहतील.
              </p>
            </li>
            <li>
              <p>
                प्लॅटफॉर्मवर किंवा सेवेद्वारे कोणत्याही संस्था आणि/किंवा
                व्यक्तींशी तुमच्या परस्परसंवादासाठी COVESTRO जबाबदार राहणार नाही
                यास तुम्ही स्वीकृती देऊन सहमती दर्शवता. यामध्ये वस्तू आणि
                सेवांचे पेमेंट आणि वितरण आणि इतर कोणत्याही अटी, शर्ती, हमी किंवा
                इतर संस्था आणि/किंवा व्यक्तींशी असलेल्या कोणत्याही
                परस्परसंवादाशी संबंधित प्रतिनिधित्व समाविष्ट आहे, परंतु
                त्यापुरते मर्यादित नाही. हे व्यवहार केवळ तुम्ही आणि अशा संस्था
                आणि/किंवा व्यक्ती यांच्यामधील आहेत.
              </p>
            </li>
            <li>
              <p>
                अशा कोणत्याही व्यवहार किंवा परस्परसंवादाच्या परिणामी झालेल्या
                कोणत्याही नुकसानीसाठी किंवा नुकसानीसाठी COVESTRO जबाबदार किंवा
                उत्तरदायी असणार नाही यास तुम्ही स्वीकृती देऊन सहमती दर्शवता.
                प्लॅटफॉर्मवरील सहभागींमध्ये किंवा वापरकर्ते आणि कोणत्याही तृतीय
                पक्षामध्ये विवाद असल्यास, अशा विवादात सहभागी होण्यासाठी COVESTRO
                वर कोणतेही बंधन नाही, हे तुम्ही समजून घेऊन त्यास सहमती दर्शवता.
              </p>
            </li>
            <li>
              <p>
                जर ज्ञात किंवा अज्ञात, संशयित आणि अप्रसिद्ध, उघड आणि छूपे, अशा
                विवाद आणि/किंवा आमच्या सेवेशी संबंधित किंवा कोणत्याही प्रकारे
                तुमचा एक किंवा अधिक वापरकर्त्यांशी वाद झाला असेल, तर तुम्ही
                याद्वारे COVESTRO, त्याचे अधिकारी, कर्मचारी, एजंट आणि
                उत्तराधिकारी यांना कोणत्याही आणि सर्व प्रकारचे दावे, मागण्या आणि
                नुकसान (वास्तविक आणि परिणामी) पासून मुक्त करता.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>मोबाइल उपकरणांचा वापर</h3>
          <ol>
            <li>
              <p>
                कृपया लक्षात ठेवा की तुमच्या वाहकाचे सामान्य दर आणि शुल्क, जसे
                की टेक्स्ट मेसेजिंग आणि डेटा शुल्क, तुम्ही मोबाइल डिव्हाइसवर अॅप
                वापरत असल्यास लागू होईल.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>प्रतिबंधित क्रिया</h3>
          <ol>
            <li>
              <p>
                या प्लॅटफॉर्मच्या वापरकर्त्यांना या अटी व शर्तींच्या इतर
                तरतुदींद्वारे प्रतिबंधित केलेल्या क्रियांसह खालील क्रियांमध्ये
                सहभागी होण्यास मनाई आहे:
              </p>
              <ul>
                - या प्लॅटफॉर्मवर, तृतीय पक्ष, कंपनी किंवा तिच्या भागीदार
                कंपन्यांच्या कॉपीराइट, गोपनीयता, मालमत्ता आणि इतर अधिकार किंवा
                हितसंबंधांचे उल्लंघन करणारी किंवा त्यांचे उल्लंघन करणारी कृती.
              </ul>
              <ul>
                - या प्लॅटफॉर्मचे इतर वापरकर्ते, तृतीय पक्ष, कंपनी किंवा तिच्या
                भागीदार कंपन्यांची गैरसोय, आर्थिक नुकसान किंवा इतर नुकसान करतील
                किंवा होऊ शकतील अशा कृती.
              </ul>
              <ul>
                - सार्वजनिक सुव्यवस्था आणि नैतिकतेसाठी आक्षेपार्ह किंवा कायदे,
                नियम आणि अध्यादेशांचे उल्लंघन करणारी कृती.
              </ul>
              <ul>
                - सदस्य नोंदणी किंवा इतर उद्देशांसाठी या प्लॅटफॉर्मचा वापर
                करताना देण्यात येणाऱ्या खोट्या घोषणा.
              </ul>
              <ul>
                - ईमेल पत्ते, पासवर्ड किंवा इतर वैयक्तिक माहितीचा बेकायदेशीर
                वापर.
              </ul>
              <ul>
                - या प्लॅटफॉर्मचा वापर व्यवसायासाठी किंवा इतर व्यावसायिक
                उद्दिष्टे साध्य करण्यासाठी किंवा त्याच्या तयारीसाठी.
              </ul>
              <ul>
                - या प्लॅटफॉर्मच्या ऑपरेशनमध्ये व्यत्यय आणणाऱ्या किंवा सेवांच्या
                तरतूदीमध्ये व्यत्यय आणणाऱ्या इतर क्रिया.
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>नियमन करणारे कायदे</h3>
          <ol>
            <li>
              <p>
                या वापराच्या अटी आणि नियमांचे सूत्रीकरण, कायदेशीर शक्ती,
                अंमलबजावणी आणि व्याख्या भारताच्या कायद्यांद्वारे नियंत्रित केली
                जाईल.
              </p>
            </li>
            <li>
              <p>
                भारताव्यतिरिक्त इतर कोणत्याही देशाच्या कायद्यांचे पालन न
                केल्याबद्दल COVESTRO कोणतेही उत्तरदायित्व स्वीकारत नाही,
                प्रत्यक्ष किंवा अप्रत्यक्षपणे, केवळ प्लॅटफॉर्मवर प्रवेश केला जाऊ
                शकतो किंवा तो भारताव्यतिरिक्त इतर देशात वापर केला जाऊ शकतो, याचा
                अर्थ आम्ही अशा देशाचे कायदे स्वीकारले आहेत असा होत नाही.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>न्यायाधिकार</h3>
          <ol>
            <li>
              <p>
                या प्लॅटफॉर्मच्या वापरावरून वापरकर्ता आणि कंपनी किंवा भागीदार
                कंपनी यांच्यातील विवादांची केवळ मुंबई, महाराष्ट्रातील
                न्यायालयांमध्ये सुनावणी केली जाईल.
              </p>
            </li>
            <li>
              <p>
                वरील गोष्टी असूनही, तुम्ही सहमत आहात की COVESTRO ला कोणत्याही
                सक्षम अधिकारक्षेत्राच्या न्यायालयात निषेधात्मक उपाय (किंवा
                समतुल्य प्रकारचे तातडीचे कायदेशीर सवलत) मिळविण्याचा अधिकार असेल.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>अटी आणि प्लॅटफॉर्ममधील बदल</h3>
          <ol>
            <li>
              <p>
                प्लॅटफॉर्म हे एक सतत चालू असलेले काम आहे, आणि त्यामध्ये समाविष्ट
                करणे किंवा हटवणे किंवा बदलणे किंवा अन्यथा बदल केले जाणे शक्य
                आहे.
              </p>
            </li>
            <li>
              <p>
                आम्ही आमच्या विवेकबुद्धीनुसार आणि कोणत्याही सूचना न देता
                वेळोवेळी वेबसाइट, अॅप आणि या अटी अद्ययावत करण्याचा अधिकार राखून
                ठेवतो.
              </p>
            </li>
            <li>
              <p>
                प्रत्येक वेळी प्लॅटफॉर्म किंवा सेवांमध्ये प्रवेश केल्यावर त्या
                वाचून नवीनतम अटींबद्दल जागरूक राहणे ही ग्राहकाची एकमेव जबाबदारी
                आणि कर्तव्य असेल.
              </p>
            </li>
            <li>
              <p>
                अद्यतनित सेवा अटींच्या प्रकाशनानंतर प्लॅटफॉर्मचा सतत वापर
                केल्यावर, वापरकर्त्याने सुधारित अटी वाचल्या आहेत आणि त्यांना
                सहमती दिली आहे असे मानले जाईल.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>तुमच्या स्वतःच्या जोखमीवर वापरा</h3>
          <ol>
            <li>
              <p>
                अस्सल, संशोधन-आधारित उपाययोजना विकसित करून अन्न सुरक्षेबाबत मदत
                करणे, त्यासाठी प्रेरित करणे आणि यासाठी मार्केटप्लेस सेवा पुरवणे
                हे आमचे ध्येय आहे. तथापि, प्लॅटफॉर्म कोणत्याही तृतीय-पक्ष
                विक्रेत्यांच्या गुणवत्तेची हमी देऊ शकत नाही आणि देत नाही.
                प्लॅटफॉर्मचा तुमचा वापर आणि प्लॅटफॉर्ममध्ये दिलेली कोणतीही
                माहिती, अंदाज किंवा सूचना तुमच्या समाधानासाठी आहेत. आम्ही
                तुम्हाला प्लॅटफॉर्मवरून पुरवू शकणाऱ्या गुणवत्ता, प्रमाण, डेटा,
                माहिती, अनुमान आणि अंदाज यांच्या अचूकतेबद्दल आम्ही कोणतेही
                प्रतिनिधित्व करत नाही किंवा हमी देत नाही आणि प्लॅटफॉर्म
                इलेक्ट्रॉनिक मार्केट प्लेस म्हणून काम करण्यासाठी आहे, आणि
                खरेदीदाराच्या जोखमीच्या अधीन आहे, हे तुम्ही समजून घेऊन त्यास
                सहमती दर्शवता.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>मते आणि अभिप्राय</h3>
          <ol>
            <li>
              <p>
                COVESTRO ला प्लॅटफॉर्मवर स्वतः दिलेली कोणतीही मते ही स्क्रीनिंग
                आणि नियंत्रणाच्या अधीन आहेत.
              </p>
            </li>
            <li>
              <p>
                टिप्पण्या कायद्याचे उल्लंघन करत असल्याचे किंवा अनुचित (अपमानजनक
                प्रसिद्धी, बदनामी, अपमान, संदर्भाबाहेर टिप्पणी) असल्याचे
                आढळल्यास, त्यास नकार देण्याचा किंवा ते सुधारित करण्याचा आणि
                योग्य कारवाई करण्याचा अधिकार आम्ही राखून ठेवतो.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TNCMA;
