import { IonPage } from '@ionic/react';

import './style.scss';
import Layout from '../../components/layouts';

import { PrivateRoute } from '../../components/privateroute';
import Selllist from '../../components/selllist';

const Sell: React.FC = () => {
  return (
    <IonPage className='page-sell'>
      <Layout>
        <div className='page-container pt-0'>
          <Selllist />
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(Sell);
