import Messages from '../config/messages';
import httpService, { HttpMethod } from './http.service';

export async function addInterest(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'interest',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getInterestByShortanme(shortname: string) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `interest/isInterested/${shortname}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
