/* eslint-disable react/jsx-no-target-blank */
// import { IonFooter, IonToolbar, IonTitle } from "@ionic/react";
import './style.scss';
import { IonFooter, IonToolbar, IonIcon } from '@ionic/react';
import React from 'react';
import { APP_VERSION } from '../../config/appconfig';
import iconFacebok from '../../assets/images/icons/facebook-f-brands.svg';
import iconTwitter from '../../assets/images/icons/twitter-brands.svg';
import iconYoutube from '../../assets/images/icons/youtube-brands.svg';
import iconLinkedin from '../../assets/images/icons/linkedin-in-brands.svg';
import iconWechat from '../../assets/images/icons/weixin-brands.svg';
import { t } from '@lingui/macro';

const Footer: React.FC = () => {
  return (
    <IonFooter>
      <IonToolbar>
        <footer className='footer'>
          <div className='footer__main'>
            <div className='footer__container'>
              <div className='footer__info'>
                <h6>
                  {t({
                    id: 'about_us',
                    message: 'About Us',
                  })}
                </h6>
                <p>
                  {t({
                    id: 'footer_sub_text',
                    message:
                      'Covestro is a world-leading supplier of high-tech polymer materials: innovative, sustainable and diverse.',
                  })}
                </p>
                <p>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.covestro.com'
                  >
                    www.covestro.com
                  </a>
                </p>
              </div>
              <ul className='socialicons d-flex'>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.facebook.com/covestroindia?mibextid=ZbWKwL'
                  >
                    <IonIcon className='' src={iconFacebok} />
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://twitter.com/covestrogroup'
                  >
                    <IonIcon className='' src={iconTwitter} />
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.youtube.com/channel/UCt0O5lTN3Nw4cGd43DgeIlQ'
                  >
                    <IonIcon className='' src={iconYoutube} />
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.linkedin.com/company/covestro'
                  >
                    <IonIcon className='' src={iconLinkedin} />
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://covestro.com/-/media/project/development/shared/images/logo/wechat-service-account_tag-solutioncenter.png?la=en'
                  >
                    <IonIcon className='' src={iconWechat} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='footer__bar'>
            <div className='footer__container d-flex'>
              <ul className='footer__bar__left d-flex'>
                <li>
                  <a href={`/legal/conditions-of-use`}>
                    {t({
                      id: 'general_conditions',
                      message: 'General conditions of use',
                    })}
                  </a>
                </li>
                <li>
                  <a href='/legal/imprint'>
                    {t({
                      id: 'imprint',
                      message: 'Imprint',
                    })}
                  </a>
                </li>
                <li>
                  <a href={`/legal/privacy-statement`}>
                    {t({
                      id: 'privacy_statement',
                      message: 'Privacy statement',
                    })}
                  </a>
                </li>
              </ul>
              <ul className='footer__bar__right d-flex'>
                <li>
                  {t({
                    id: 'version',
                    message: 'Version',
                  })}{' '}
                  {`${APP_VERSION} `} &nbsp;|&nbsp;{' '}
                  {t({
                    id: 'copyright',
                    message: 'Copyright © Covestro IN',
                  })}
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
