import {
  IonCard,
  IonCardContent,
  IonImg,
  IonPopover,
  useIonViewWillLeave,
} from '@ionic/react';
import { useContext, useState } from 'react';
import { AppContext } from '../../context/app.context';
import {
  getFormattedUrl,
  getLanguageText,
  getRouteForCMS,
  openExternalUrl,
} from '../../utils/common.helper';
import VideoPlayer from '../videoplayer';
import './style.scss';
import IconPlay from '../../assets/images/icon-play.png';
import { useHistory } from 'react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
interface Props {
  data: any;
  customClass?: string;
}
const ContentSlider: React.FC<Props> = (props: Props) => {
  const { data, customClass } = props;
  const { lang }: any = useContext(AppContext);
  // const slideOpts = {
  //   initialSlide: 0,
  //   speed: 400,
  //   autoplay: {
  //     delay: 3000,
  //     disableOnInteraction: false,
  //   },
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'bullets',
  //     clickable: true,
  //   },
  // };
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [videoObj, setVideoObj]: any = useState(null);
  const history = useHistory();
  useIonViewWillLeave(() => {
    setShowPopover({ showPopover: false, event: undefined });
  }, []);

  const onSlideClick = (route: string) => {
    if (route.startsWith('https' || 'http')) {
      openExternalUrl(route);
    } else {
      history.push(getRouteForCMS(route));
    }
  };

  return (
    <div className={`post-card-wrapper ${customClass || ''}`}>
      <IonCard className='post-card p-16 m-0 mb-24'>
        <IonCardContent
          className={`card-content-wrapper p-0 mb-24 card-content`}
        >
          <Carousel
            key={'Content-slider'}
            autoPlay
            showArrows={false}
            stopOnHover={false}
            showStatus={false}
            infiniteLoop
            showThumbs={false}
            className='content-slide-bg content-slide-bg--pagination'
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
          >
            {data.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className='content-slide-bg__item '
                  onClick={() => {
                    if (item.slug && item.content_type == 'banner') {
                      onSlideClick(item.slug);
                    }
                  }}
                >
                  {item.content_type == 'banner' ? (
                    <IonImg src={getFormattedUrl(item.content_image.uri)} />
                  ) : (
                    <div
                      className='cursor content-slide-bg__play-btn'
                      onClick={() => {
                        setShowPopover({ showPopover: true, event: undefined });
                        setVideoObj({
                          videoUrl: item.video.uri,
                          thumbUrl: getFormattedUrl(item.thumbnail.uri),
                        });
                      }}
                    >
                      <IonImg src={getFormattedUrl(item.thumbnail.uri)} />
                      <div>
                        <IonImg
                          style={{
                            top: '45%',
                            left: '45%',
                            width: 40,
                            height: 40,
                            position: 'absolute',
                          }}
                          src={IconPlay}
                        />
                      </div>
                    </div>
                  )}
                  {item.title || item.text ? (
                    <div
                      className='content-slide-bg__copy'
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (item.slug) {
                          onSlideClick(item.slug);
                        }
                      }}
                    >
                      <div className='content-slide-bg__copy__inner'>
                        {item.title && (
                          <div className='content-slide-bg__copy__heading'>
                            {getLanguageText(
                              item.title,
                              'title',
                              item.locale,
                              lang
                            )}
                          </div>
                        )}
                        {item.text && (
                          <div className='content-slide-bg__copy__description'>
                            {getLanguageText(
                              item.text,
                              'text',
                              item.locale,
                              lang
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </Carousel>
        </IonCardContent>
      </IonCard>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
          setVideoObj(null);
        }}
        className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <div className='cursor video-height-wrapper' style={{ width: '100%' }}>
          {videoObj && (
            <VideoPlayer
              url={videoObj.videoUrl}
              thumbnail={videoObj.thumbUrl}
            />
          )}
        </div>
      </IonPopover>
    </div>
  );
};

export default ContentSlider;
