import { IonPage } from '@ionic/react';
import { useContext } from 'react';
import Layout from '../../components/layouts';
import PrivacyEN from '../../components/privacylang/en';
import PrivacyHI from '../../components/privacylang/hi';
import PrivacyKN from '../../components/privacylang/kn';
import PrivacyMA from '../../components/privacylang/ma';
import PrivacyTA from '../../components/privacylang/ta';
import PrivacyTE from '../../components/privacylang/te';
import { AppContext } from '../../context/app.context';
import './style.scss';

const PrivacyPolicy: React.FC = () => {
  const { lang }: any = useContext(AppContext);
  return (
    <IonPage className='page-privacy-policy'>
      <Layout hideUserCentric>
        <div className='content-sec container mt-20'>
          {lang == 'en' ? (
            <PrivacyEN />
          ) : lang == 'hi' ? (
            <PrivacyHI />
          ) : lang == 'mr' ? (
            <PrivacyMA />
          ) : lang == 'kn' ? (
            <PrivacyKN />
          ) : lang == 'te' ? (
            <PrivacyTE />
          ) : lang == 'ta' ? (
            <PrivacyTA />
          ) : (
            <PrivacyEN />
          )}
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivacyPolicy;
