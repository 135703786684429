/* eslint-disable no-unused-vars */
import './style.scss';
import React from 'react';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { t } from '@lingui/macro';
import { isWeb } from '../../utils/common.helper';

interface Props {
  name?: string;
  labelName?: string;
  labelKey?: string;
  customClass?: string;
  value: any;
  placeholder?: string;
  suffix?: string;
  type?: any;
  error?: boolean;
  maxlength?: any;
  debounce?: number;
  disabled?: boolean;
  readonly?: boolean;
  inputMode?: any;
  required?: boolean;
  hideLable?: boolean;
  placeholderName?: string;
  placeholderKey?: string;
  onChange?: (val: string) => void;
  onKeyDown?: (event: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const InputText: React.FC<Props> = ({
  name,
  labelName,
  labelKey,
  customClass,
  value,
  placeholder,
  suffix,
  type,
  maxlength,
  error,
  debounce,
  disabled,
  readonly,
  inputMode,
  required,
  hideLable,
  placeholderKey,
  placeholderName,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
}) => {
  labelKey = labelKey || '';
  labelName = labelName || '';
  const onKeyPress = (event: any) => {
    if (maxlength && event.target.value.length >= maxlength) {
      event.preventDefault();
      return;
    }
    if (type && type == 'number') {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  };
  return (
    <div className='ion-field-wrapper'>
      {!hideLable && (
        <IonLabel
          className={`ion-label font-aktiv-grotest-regular ${
            error ? 'ion-field-label-error' : ''
          } ${customClass || ''}`}
        >
          {t({ id: labelKey, message: labelName })}
          {required && <span className='mandatory'>*</span>}
        </IonLabel>
      )}
      <IonItem
        className={`font-aktiv-grotest-medium ion-field-main-container ${
          error ? 'ion-field-error' : ''
        } ${customClass || ''}`}
      >
        <IonInput
          name={name}
          value={value}
          placeholder={t({
            id: placeholderKey || labelKey,
            message: placeholderName || labelName,
          })}
          type={
            type && type == 'number'
              ? isWeb()
                ? 'text'
                : type
              : type || 'text'
          }
          maxlength={maxlength}
          className={`input-text-select ${error ? 'ion-field-error' : ''}`}
          onIonChange={(e: any) => {
            onChange && onChange(e.detail.value || '');
          }}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          debounce={debounce}
          disabled={disabled}
          inputmode={inputMode || 'text'}
          onFocus={onFocus}
          onBlur={onBlur}
          readonly={readonly}
        ></IonInput>
      </IonItem>
      {suffix && (
        <span className='ion-item__suffix font-aktiv-grotest-bold'>
          {suffix}
        </span>
      )}
    </div>
  );
};

export default InputText;
