import { IonImg, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Logo from '../../assets/images/logo/covestro-logo-wh.png';
import Loader from '../../components/loader';
import { INIT_PAGE } from '../../config/appconfig';
import { AppContext } from '../../context/app.context';

const Splash: React.FC = () => {
  const history = useHistory();
  const { isLoggedIn, isInActive }: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (isLoggedIn != '') {
      setTimeout(() => {
        setLoading(false);
        if (isLoggedIn == 'true') {
          if (isInActive == 'true') {
            history.replace('/account-inactive');
          } else {
            history.replace(INIT_PAGE);
          }
        } else {
          history.replace('/intro');
        }
      }, 3000);
    }
  }, [isLoggedIn, isInActive]);
  return (
    <IonPage>
      <div
        style={{
          flex: 1,
          backgroundColor: 'black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IonImg src={Logo} className='logo-img ' />
        <span className='logo__identifier'>Food Security Platform</span>
        {loading && <Loader />}
      </div>
    </IonPage>
  );
};

export default Splash;
