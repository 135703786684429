/* eslint-disable no-unused-vars */
import './style.scss';
import React from 'react';
import ProfileImage from '../profileimg';
import { t } from '@lingui/macro';
interface Props {
  imgPath?: string;
  customClass?: string;
  onCrop?: (base64: string) => void;
  onRemove?: () => Promise<boolean>;
}
const UploadProfilePic: React.FC<Props> = ({ imgPath, onCrop, onRemove }) => {
  return (
    <div className='upload-profile-container'>
      <div className='upload-photo-title mb-8'>
        {t({
          id: 'upload_profile_photo',
          message: 'Upload Profile Photo',
        })}
      </div>
      <ProfileImage
        isUpload
        onCrop={(base64: string) => {
          onCrop && onCrop(base64);
        }}
        onRemove={async () => {
          if (onRemove) {
            return await onRemove();
          } else {
            return true;
          }
        }}
        imgPath={imgPath}
      />
      <div className='upload-photo-disc'>
        Supported File: JPEG, PNG
        <br />
        Max Size: 2MB
      </div>
    </div>
  );
};

export default UploadProfilePic;
