import './style.scss';
import { IonImg, IonPage } from '@ionic/react';
import Layout from '../../components/layouts';
import maintenanceImg from '../../assets/images/maintenance.png';
const maintenance: React.FC = () => {
  return (
    <IonPage className='maintenance-main'>
      <Layout>
        <div className='page-maintenance'>
          <IonImg
            className='page-maintenance__icon'
            src={maintenanceImg}
          ></IonImg>
          <div className='page-maintenance__copy'>
            <h1>Hang on! We are under maintenance</h1>
            <p>
              We will be online as soon as possible. please check again in a
              little while. Thank you!
            </p>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default maintenance;
