/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import {
  IonCol,
  IonImg,
  IonPopover,
  IonRow,
  useIonViewWillLeave,
} from '@ionic/react';
import Placeholder from '../../assets/images/cd-placeholder.png';
import Like from '../../assets/images/icons/heart.png';
import LikeBlue from '../../assets/images/icons/heart_blue.png';
import Share from '../../assets/images/icons/Share.svg';
import { t } from '@lingui/macro';
import CheckBox from '../checkbox';
import {
  getDynamicLink,
  getFormattedUrl,
  getLanguageText,
  isWeb,
  socialSharing,
} from '../../utils/common.helper';
import { AppContext } from '../../context/app.context';
import VideoPlayer from '../videoplayer';
import ButtonComponent from '../button-component';
import observerService from '../../services/observer.service';
import { useHistory } from 'react-router';
import { getDynamicLinkForWeb } from '../../services/firebase.service';
import ShareBox from '../sharebox';
interface Props {
  imgPath?: string;
  videoPath?: string;
  customClass?: string;
  isFavourite?: boolean;
  isAttended?: boolean;
  productLabel?: string;
  productDisc?: string;
  isChecked?: boolean;
  showReaction?: boolean;
  showDisc?: boolean;
  product?: any;
  isClickable?: boolean;
  onCheckChanged?: (isChecked: boolean, value: string) => void;
  onAddInterest?: (productId: number) => Promise<any>;
}

const ProductCard: React.FC<Props> = ({
  customClass,
  isFavourite,
  isAttended,
  imgPath,
  videoPath,
  productLabel,
  productDisc,
  isChecked,
  showReaction,
  showDisc,
  product,
  isClickable,
  onCheckChanged,
  onAddInterest,
}) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  const [checked, setChecked] = useState(isChecked || false);
  const { lang }: any = useContext(AppContext);
  const [showDetails, setShowDetails] = useState(true);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const bottomObserver: any = useRef(null);
  const [bottomRef, setBottomRef]: any = useState(null);
  const history = useHistory();
  const [isLoadingDynamicLink, setIsLoadingDynamicLink] = useState(false);
  const [dynamicLink, setDynamicLink] = useState('');
  const [popoverStateShare, setShowPopoverShare] = useState({
    showPopover: false,
    event: undefined,
  });
  useEffect(() => {
    setObserver();
  }, []);

  useEffect(() => {
    setMediaPath(imgPath);
  }, [imgPath]);

  useEffect(() => {
    if (bottomRef && bottomObserver) {
      bottomObserver.current.observe(bottomRef);
    }
    return () => {
      if (bottomRef && bottomObserver) {
        bottomObserver.current.unobserve(bottomRef);
      }
    };
  }, [bottomObserver, bottomRef]);

  const setObserver = () => {
    const bottom = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          if (!entry.isIntersecting && entry.target.dataset.type == 'video') {
            observerService.handleEvents('video-pause');
          }
        });
      },
      { threshold: 0.1 }
    );
    bottomObserver.current = bottom;
  };

  const handleFavClick = async () => {
    if (isFavourite && !isAttended) return;
    if (onAddInterest) {
      const resp = await onAddInterest(product.id);
      if (resp) {
        setShowPopover({ showPopover: true, event: undefined });
      }
    }
  };
  useEffect(() => {
    setChecked(isChecked || false);
  }, [isChecked]);

  useIonViewWillLeave(() => {
    setShowPopoverShare({ showPopover: false, event: undefined });
    setShowPopover({ showPopover: false, event: undefined });
  }, []);

  return (
    <div className={`product-card-wrapper ${customClass || ''}`}>
      <div className='product-card-wrapper'>
        <div className='product-image-wrapper'>
          {showReaction && videoPath ? (
            <div ref={setBottomRef} data-type={'video'} className='cursor'>
              <VideoPlayer
                url={videoPath}
                onVideoEnd={() => {
                  setShowDetails(true);
                }}
                onVideoPlay={(isPlay: boolean) => {
                  setShowDetails(!isPlay);
                }}
                hideControlsOnPause
              />
            </div>
          ) : (
            <IonImg
              src={mediaPath ? getFormattedUrl(mediaPath) : Placeholder}
              className='product__image'
            />
          )}

          {!showReaction && (
            <CheckBox
              label={productLabel}
              customClass='product-checkbox'
              isChecked={checked}
              hideLabel
              onchange={onCheckChanged}
            />
          )}
        </div>
        {showDetails && (
          <div
            className={`product-card-details ${isClickable ? 'cursor' : ''}`}
            onClick={() => {
              if (!isClickable) return;
              history.push(`/solution/fs-sol-${product.shortname}`);
            }}
          >
            <div className='product-card-name font-aktiv-grotest-medium'>
              {product
                ? getLanguageText(product.title, 'title', product.locale, lang)
                : productLabel}
            </div>
            {showDisc && (
              <div className='product-card-disc'>
                {product
                  ? getLanguageText(
                      product.description,
                      'description',
                      product.locale,
                      lang
                    )
                  : productDisc}
              </div>
            )}
          </div>
        )}
      </div>
      {showReaction && (
        <IonRow className='card-reaction-wrapper p-12 mb-16 mt-10'>
          <IonCol className='card-reaction-details p-0 card-reaction-like'>
            <div className='flex cursor' onClick={handleFavClick}>
              <IonImg
                src={isFavourite && !isAttended ? LikeBlue : Like}
                className='card-reaction-icon'
              />
              <div className='card-reaction-label font-aktiv-grotest-bold ml-8 mt-2 cursor'>
                {isFavourite && !isAttended
                  ? t({
                      id: 'interest_contact',
                      message: 'Covestro team will connect you shortly!',
                    })
                  : t({
                      id: 'i_am_interested',
                      message: 'I am interested',
                    })}
              </div>
            </div>
          </IonCol>
          <IonCol className='card-reaction-details p-0 card-reaction-share'>
            <div
              className='flex cursor'
              onClick={async () => {
                if (isLoadingDynamicLink) return;
                setIsLoadingDynamicLink(true);
                if (isWeb()) {
                  const dynamicUrl = await getDynamicLinkForWeb(
                    `solution/fs-sol-${product.shortname}`
                  );

                  if (!dynamicUrl.error && dynamicUrl.shortLink) {
                    setDynamicLink(dynamicUrl.shortLink);
                    setShowPopoverShare({
                      showPopover: true,
                      event: undefined,
                    });
                  }
                  setIsLoadingDynamicLink(false);
                } else {
                  const dynamicUrl = await getDynamicLink(
                    `solution/fs-sol-${product.shortname}`
                  );
                  if (dynamicUrl) {
                    socialSharing(
                      'Visit my post from Covestro',
                      undefined,
                      undefined,
                      dynamicUrl
                    );
                  }
                  setIsLoadingDynamicLink(false);
                }
              }}
            >
              <IonImg src={Share} className='card-reaction-icon' />
              <div className='card-reaction-label font-aktiv-grotest-bold ml-8 cursor'>
                {t({
                  id: 'share',
                  message: 'Share',
                })}
              </div>
            </div>
          </IonCol>
        </IonRow>
      )}
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
        }}
        className='success-popover'
        backdropDismiss={false}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            // justifyContent: 'center',
            margin: 10,
            flexDirection: 'column',
          }}
        >
          {t({
            id: 'success_solution_message',
            message:
              'Thank you for showing interest in our solution. Our team will connect with you for further details',
          })}
          <ButtonComponent
            name={'Ok'}
            customClass=' mt-16'
            textKey='ok'
            onClick={() => {
              setShowPopover({ showPopover: false, event: undefined });
            }}
          />
        </div>
      </IonPopover>
      <IonPopover
        event={popoverStateShare.event}
        isOpen={popoverStateShare.showPopover}
        onDidDismiss={() => {
          setShowPopoverShare({ showPopover: false, event: undefined });
          setDynamicLink('');
        }}
        className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <ShareBox
          url={dynamicLink}
          onShareClose={() => {
            setShowPopoverShare({ showPopover: false, event: undefined });
            setDynamicLink('');
          }}
        />
      </IonPopover>
    </div>
  );
};

export default ProductCard;
