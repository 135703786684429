/* eslint-disable security/detect-object-injection */
import { File } from '@awesome-cordova-plugins/file';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links';
import { Capacitor } from '@capacitor/core';
import { api, APP_DOMAIN, INIT_PAGE } from '../config/appconfig';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';

export const getFileDirectory = () => {
  const directory = File.dataDirectory;
  return directory;
};

export const getFormattedUrl = (url: string) => {
  return !url.startsWith('https' || 'http')
    ? `${api.apiUrl}file/download/${url.replace('\\', '/')}`
    : url;
};

export const getFormattedUrlCMS = (url: string) => {
  return !url.startsWith('https' || 'http')
    ? `${api.apiUrl}file/download/strapi-cms/${url}`
    : url;
};

export function getFileNameFromURL(urlString: string) {
  const index = urlString.substring(urlString.lastIndexOf('/') + 1);
  return index;
}

export const isNullOrEmptyText = (v: string) =>
  v == undefined || v == null || v.length == 0;

export const mastText = (str: string) => {
  let maskText = str;
  if (!str.includes('@')) {
    const endDigits = str.slice(-4);
    maskText = endDigits.padStart(str.length, '*');
  } else {
    const strArr = str.split('');
    const finalArr: any = [];
    const len = strArr.indexOf('@');
    strArr.forEach((item, pos) => {
      pos >= 1 && pos <= len - 2
        ? finalArr.push('*')
        : finalArr.push(strArr[pos]);
    });
    maskText = finalArr.join('');
  }
  return maskText;
};

export const getKeyFromString = (str: string) => {
  const regex = /[.,\s]/g;
  if (str) {
    const message = Array.isArray(str) ? str[0] : str;
    return message
      .toLowerCase()
      .split(' ')
      .join('_')
      .replace(regex, '')
      .replace('/', '_');
  }
  return str;
};

export const getDropDownSeletedValue = (data: any, value: string) => {
  const selectedItem = data.find((item: any) => {
    return item.value == value;
  });
  return selectedItem || {};
};

export const getDataForDropDown = (data: any, key: string, id = 'id') => {
  const finalData: any = [];
  for (let index = 0; index < data.length; index++) {
    finalData.push({
      name: data[index][key],
      value: data[index][key],
      id: data[index][id],
      data: data[index],
    });
  }
  return finalData;
};

export const isWeb = () => {
  return Capacitor.getPlatform() == 'web';
};

export const getLanguageText = (
  text: string,
  textKey: string,
  locale: any,
  language: string
) => {
  try {
    if (locale) {
      const parsedData = JSON.parse(locale);

      if (parsedData[language][textKey]) {
        if (parsedData[language][textKey] != '') {
          return parsedData[language][textKey];
        } else {
          return text;
        }
      } else if (parsedData[language]['name']) {
        if (parsedData[language]['name'] != '') {
          return parsedData[language]['name'];
        } else {
          return text;
        }
      } else {
        return text;
      }
    }
    return text;
  } catch (error) {
    return text;
  }
};

export const getLocaleText = (dataString: string, language: string) => {
  try {
    const parsedData = JSON.parse(dataString);
    let str = parsedData['name'];
    if (parsedData['locale']) {
      if (
        parsedData['locale'][language] &&
        parsedData['locale'][language]['name']
      ) {
        if (parsedData['locale'][language]['name'] != '') {
          str = parsedData['locale'][language]['name'];
        }
      }
    }
    return str;
  } catch (error) {
    return dataString;
  }
};

export const toFirstUpperCaseWord = (str: string) => {
  if (!str) return '';
  const arr = str.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(' ');
  return str2;
};

export const formatDateToSingleChar = (date: string) => {
  if (date.includes('a few seconds ago')) {
    return 'a few secs ago';
  } else if (date.includes('a minute ago')) {
    return '1min ago';
  } else if (date.includes('minutes ago')) {
    return date.replace(' minutes ago', 'min ago');
  } else if (date.includes('an hour ago')) {
    return '1h ago';
  } else if (date.includes('hours ago')) {
    return date.replace(' hours ago', 'h ago');
  } else if (date.includes('a day ago')) {
    return '1d ago';
  } else if (date.includes('days ago')) {
    return date.replace(' days ago', 'd ago');
  } else if (date.includes('a month ago')) {
    return '1M ago';
  } else if (date.includes('months ago')) {
    return date.replace(' months ago', 'M ago');
  } else if (date.includes('a year ago')) {
    return '1y ago';
  } else if (date.includes('years ago')) {
    return date.replace(' years ago', 'y ago');
  } else {
    return date;
  }
};

export const getDynamicLink = async (url: string) => {
  try {
    const dynamicUrl = await FirebaseDynamicLinks.createUnguessableDynamicLink({
      domainUriPrefix: 'https://covestrofoodsecurity.page.link',
      link: `${APP_DOMAIN}${url}`,
      androidInfo: {
        androidPackageName: 'com.covestro.foodsecurity',
        androidFallbackLink: `${APP_DOMAIN}${url}`,
      },
    });
    return dynamicUrl;
  } catch (error) {
    return null;
  }
};

export const socialSharing = async (
  message: string | undefined,
  subject: string | undefined,
  file?: string | undefined,
  url?: string | undefined
) => {
  await SocialSharing.share(message, subject, file, url);
};
export const random = () =>
  `${Math.floor(Math.random() * 100000)}-${Date.now()}`;

export const getRouteForCMS = (route: string) => {
  if (route.includes('fs-sol-')) {
    return `/solution/${route}`;
  } else if (route.includes('ss-')) {
    return `/successstorydetail/${route}`;
  } else if (route.includes('abt-cov-IB')) {
    return `/aboutcovestro`;
  } else if (route.includes('fs-sol') || route.includes('solutions')) {
    return `/solutions`;
  } else if (route.includes('ssdetail')) {
    return `/successstorydetail/${route}`;
  } else if (route.includes('ss') || route.includes('successstories')) {
    return `/successstories`;
  } else if (route.includes('testimonial')) {
    return `/testimonials`;
  } else if (route.includes('news')) {
    return `/news`;
  } else if (route.includes('accreditation')) {
    return `/accreditations`;
  } else if (route.includes('subsidy/loan')) {
    return `/subsidyorloans`;
  } else if (
    route.includes('partnersdetail') ||
    route.includes('partnersdetail-') ||
    route.includes('partners-')
  ) {
    return `/partnersdetail/${route}`;
  } else if (route.includes('video')) {
    return `/videolist`;
  }
  return route;
};

export const getUserInitial = (userName: string) => {
  if (!userName) return '';
  return userName.charAt(0).toUpperCase();
};

export const getColorByInitial = (initial: string) => {
  const colorGroup = [
    {
      userInitial: 'abcd',
      colorCode: '#009FE4',
    },
    {
      userInitial: 'efgh',
      colorCode: '#00BB7E',
    },
    {
      userInitial: 'ijkl',
      colorCode: '#FFEE00',
    },
    {
      userInitial: 'mnop',
      colorCode: '#FF7F41',
    },
    {
      userInitial: 'qrstu',
      colorCode: '#E6007E',
    },
    {
      userInitial: 'vwxyz',
      colorCode: '#7d55c7',
    },
  ];
  const userColorObj = colorGroup.find((group: any) =>
    group.userInitial.includes(initial.toLowerCase())
  );
  if (userColorObj) {
    return userColorObj.colorCode;
  }
  return 'black';
};

export const getTagsByModel = (model: string) => {
  if (!model) {
    return null;
  }
  if (model.includes('fs-sol')) {
    return {
      tagText: 'Solution',
      tagBgColor: '#009FE4',
    };
  } else if (model.includes('ss')) {
    return {
      tagText: 'Media',
      tagBgColor: '#E6007E',
    };
  } else if (model.includes('video')) {
    return {
      tagText: 'Media',
      tagBgColor: '#E6007E',
    };
  } else if (model.includes('partners')) {
    return {
      tagText: 'Partner',
      tagBgColor: '#E6007E',
    };
  } else {
    return null;
  }
};

export const openExternalUrl = (url: string) => {
  window.open(url, '_blank');
};

export const formatStringToJSON = (str: string) => {
  let formattedString = str;
  try {
    formattedString = JSON.parse(str);
  } catch (e) {
    return formattedString;
  }
  return formattedString;
};

export const notificationRedirect = (parsedData: any) => {
  if (parsedData) {
    if (parsedData.postId) {
      window.location.href = `/commoditydetail/${parsedData.postId}`;
    } else if (parsedData.id) {
      window.location.href = `/notifications/details/${parsedData.id}`;
    } else if (parsedData.type && parsedData.type == 'COMPLETE PROFILE') {
      window.location.href = `/my-profile`;
    } else {
      window.location.href = INIT_PAGE;
    }
  } else {
    window.location.href = INIT_PAGE;
  }
};

export const stringInject = (str: string, data: any) => {
  if (typeof str === 'string' && data instanceof Array) {
    return str.replace(/({\d})/g, function (i: any) {
      return data[i.replace(/{/, '').replace(/}/, '')];
    });
  } else if (typeof str === 'string' && data instanceof Object) {
    if (Object.keys(data).length === 0) {
      return str;
    }

    for (const key in data) {
      return str.replace(/({([^}]+)})/g, function (i) {
        const key1 = i.replace(/{/, '').replace(/}/, '');
        if (!data[key1]) {
          return i;
        }

        return data[key1];
      });
    }
  } else if (
    (typeof str === 'string' && data instanceof Array === false) ||
    (typeof str === 'string' && data instanceof Object === false)
  ) {
    return str;
  } else {
    return str;
  }
};

export const getQueryStrings = (url: string) => {
  const assoc: any = {};
  const decode = function (s: any) {
    return decodeURIComponent(s.replace(/\+/g, ' '));
  };
  const queryString = url.substring(url.indexOf('?') + 1);
  const keyValues = queryString.split('&');

  for (const i in keyValues) {
    const key = keyValues[i].split('=');
    if (key.length > 1) {
      assoc[decode(key[0])] = decode(key[1]);
    }
  }

  return assoc;
};
