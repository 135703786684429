import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
} from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import EmptyData from '../../components/emptydata';
import Layout from '../../components/layouts';
import PostCard from '../../components/postcard';
import PrivateRoute from '../../components/privateroute';
import Messages from '../../config/messages';
import { AppContext } from '../../context/app.context';
import {
  getLikePostCount,
  getUsersPosts,
  likePost,
  submitPostComment,
} from '../../services/post.service';
import { getLanguageText } from '../../utils/common.helper';
import './style.scss';

const MyCorner: React.FC = () => {
  const {
    isLoggedIn,
    setLoading,
    toastMessage,
    loading,
    userDetails,
    lang,
  }: any = useContext(AppContext);

  const [posts, setPosts] = useState([]);
  const [isPaginationLoad, setIsPaginationLoad] = useState(false);
  const [lastPostRecieved, setLastPostRecieved] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pageMeta, setPageMeta]: any = useState({
    currentPage: 0,
  });
  const [isLikeLoading, setIsLikeLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchPostPagination(true, null)]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchPostPagination = async (isFirstLoad: boolean, event: any) => {
    if (isLoggedIn == 'false') return;
    if ((!isFirstLoad && lastPostRecieved) || isPaginationLoad) return;
    setIsPaginationLoad(true);

    const resp = await getUsersPosts(`page=${pageMeta.currentPage + 1}`);
    setIsPaginationLoad(false);
    if (!resp.error) {
      if (event) {
        event.target.complete();
      }
      if (event) {
        event.target.disabled = resp.length == 0;
      }
      setPosts(
        posts.concat(
          resp.items.filter((item: any) => {
            return item.post_type != 'product';
          })
        )
      );
      setPageMeta(resp.meta);
      setLastPostRecieved(resp.meta.currentPage >= resp.meta.totalPages);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onPostCommentSubmit = async (req: any) => {
    const resp = await submitPostComment(req);
    if (!resp.error) {
      toastMessage(Messages.COMMENT_ADDED_MSG);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onPostLike = async (req: any) => {
    if (isLikeLoading) return;
    setIsLikeLoading(true);
    const resp = await likePost(req);
    if (!resp.error) {
      const postObj: any = posts.find((item: any) => {
        return item.id == req.postId && item.post_type != 'product';
      });
      if (postObj) {
        postObj.isLiked = req.liked;
        if (postObj.likes_count) {
          if (req.liked) {
            postObj.likes_count = postObj.likes_count + 1;
          } else {
            postObj.likes_count = Math.max(0, postObj.likes_count - 1);
          }
        } else {
          if (req.liked) {
            postObj.likes_count = 1;
          } else {
            postObj.likes_count = 0;
          }
        }
        const countRes = await getLikePostCount(req.postId);
        if (!countRes.error) {
          postObj.likes_count = Math.max(0, countRes);
        }
        setRefresh(!refresh);
      }
      setIsLikeLoading(false);
      // toastMessage(Messages.COMMENT_ADDED_MSG);
      return resp;
    } else {
      setIsLikeLoading(false);
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onPostDetele = (postId: any) => {
    setPosts(posts.filter((item: any) => item.id != postId));
  };

  return (
    <IonPage>
      <Layout>
        <div className='my-corner-container page-container'>
          <div className='my-corner-main-container'>
            <>
              {loading && posts.length == 0 ? (
                <></>
              ) : posts.length > 0 ? (
                <>
                  <IonList>
                    {posts.map((item: any) => {
                      let postImage = null;
                      if (
                        item.commodity_images_obj &&
                        item.commodity_images_obj.length > 0
                      ) {
                        postImage = item.commodity_images_obj[0].uri;
                      } else if (item.commodity_id?.image_pic_obj) {
                        postImage = item.commodity_id.image_pic_obj.uri;
                      }
                      return (
                        <div key={item.post_type + item.id}>
                          {item.post_type == 'general' ? (
                            <PostCard
                              postUserId={item.user_id.id}
                              postId={item.id}
                              farmerName={
                                item.user_id.firstname
                                  ? `${item.user_id.firstname} ${
                                      item.user_id.lastname || ''
                                    }`
                                  : 'Anonymous'
                              }
                              farmerType={item.user_id.stakeholder_id}
                              location={item.user_id.location_id.locality_name}
                              postCardContent={item.description}
                              isContentOnly={true}
                              farmerProfilePic={
                                userDetails.id == item.user_id.id
                                  ? userDetails.profile_pic_obj
                                    ? userDetails.profile_pic_obj.uri
                                    : undefined
                                  : item.user_id.profile_pic_obj
                                  ? item.user_id.profile_pic_obj.uri
                                  : undefined
                              }
                              commentList={item.commentList}
                              onSubmit={async (
                                commentText: string,
                                postId: number
                              ) => {
                                return await onPostCommentSubmit({
                                  text: commentText,
                                  postId: postId,
                                  type: 'public',
                                });
                              }}
                              isLiked={item.isLiked}
                              likeCount={item.likes_count || 0}
                              commentsCount={item.comments_count || 0}
                              viewCount={
                                item.viewList ? item.viewList.length : 0
                              }
                              onLike={async (
                                isLiked: boolean,
                                postId: number
                              ) => {
                                return await onPostLike({
                                  postId: postId,
                                  liked: isLiked,
                                });
                              }}
                              postTime={item.created_at}
                              updateComment={(
                                commentList: any,
                                postId: any
                              ) => {
                                const postObj: any = posts.find((item: any) => {
                                  return (
                                    item.id == postId &&
                                    item.post_type != 'product'
                                  );
                                });
                                if (postObj) {
                                  postObj.commentList = commentList;
                                  setRefresh(!refresh);
                                }
                              }}
                              isActive={item.is_active}
                              postStatus={item.status}
                              rejectReason={item.reject_reason}
                              postType={item.post_type}
                              imgPath={
                                item.image_pic_obj
                                  ? item.image_pic_obj.uri
                                  : null
                              }
                              videoPath={
                                item.video_obj ? item.video_obj.uri : null
                              }
                              onPostDelete={(postId: number) => {
                                onPostDetele(postId);
                              }}
                            />
                          ) : (
                            <PostCard
                              postUserId={item.user_id.id}
                              postId={item.id}
                              farmerName={
                                item.user_id.firstname
                                  ? `${item.user_id.firstname} ${
                                      item.user_id.lastname || ''
                                    }`
                                  : 'Anonymous'
                              }
                              farmerType={item.user_id.stakeholder_id}
                              location={item.user_id.location_id.locality_name}
                              productName={item.commodity_id}
                              productCategory={item.category_id}
                              productVarietyBreed={item.variety_breed}
                              productQuantity={item.quantity}
                              productUnit={getLanguageText(
                                item.weightunit_id.unit_name,
                                'name',
                                item.weightunit_id.locale,
                                lang
                              )}
                              expectedPrice={item.price}
                              logisticRequired={item.is_logistic_required}
                              productType={item.type}
                              buying={item.post_type == 'buy'}
                              imgPath={postImage}
                              showLogistic={item.post_type == 'sell'}
                              farmerProfilePic={
                                userDetails.id == item.user_id.id
                                  ? userDetails.profile_pic_obj
                                    ? userDetails.profile_pic_obj.uri
                                    : undefined
                                  : item.user_id.profile_pic_obj
                                  ? item.user_id.profile_pic_obj.uri
                                  : undefined
                              }
                              commentList={item.commentList}
                              onSubmit={async (
                                commentText: string,
                                postId: number
                              ) => {
                                return await onPostCommentSubmit({
                                  text: commentText,
                                  postId: postId,
                                  type: 'public',
                                });
                              }}
                              isLiked={item.isLiked}
                              likeCount={item.likes_count || 0}
                              commentsCount={item.comments_count || 0}
                              viewCount={
                                item.viewList ? item.viewList.length : 0
                              }
                              onLike={async (
                                isLiked: boolean,
                                postId: number
                              ) => {
                                return await onPostLike({
                                  postId: postId,
                                  liked: isLiked,
                                });
                              }}
                              postTime={item.created_at}
                              updateComment={(
                                commentList: any,
                                postId: any
                              ) => {
                                const postObj: any = posts.find((item: any) => {
                                  return (
                                    item.id == postId &&
                                    item.post_type != 'product'
                                  );
                                });
                                if (postObj) {
                                  postObj.commentList = commentList;
                                  setRefresh(!refresh);
                                }
                              }}
                              isActive={item.is_active}
                              postStatus={item.status}
                              rejectReason={item.reject_reason}
                              postType={item.post_type}
                              onPostDelete={(postId: number) => {
                                onPostDetele(postId);
                              }}
                              extended={item.extended}
                              extendedAt={item.expiring_at}
                            />
                          )}
                        </div>
                      );
                    })}
                  </IonList>
                  <IonInfiniteScroll
                    threshold='100px'
                    onIonInfinite={(event) => {
                      fetchPostPagination(false, event);
                    }}
                    disabled={lastPostRecieved}
                  >
                    <IonInfiniteScrollContent
                      loadingSpinner='circles'
                      loadingText='Loading more data...'
                    ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </>
              ) : (
                <div>
                  <EmptyData />
                </div>
              )}
            </>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(MyCorner);
