import Messages from '../config/messages';
import httpService, { HttpMethod } from './http.service';

export async function registration(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'user/registration',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function login(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'user/login/phone-check',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function profileRegistration(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'user/profile-registration',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function stakeholderList() {
  try {
    const resp: any = await httpService.execute({
      url: 'user/stakeholder',
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getUserDetails() {
  try {
    const resp: any = await httpService.execute({
      url: 'user/profile',
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function createUserCommodity(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'user/user-commodity',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function removeUserProfilePic() {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'user/remove-profile-pic',
      data: {},
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function resendOtp(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.PATCH,
      url: 'user/resend-otp',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function userUsingSolutions(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'user/user-using-solutions',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function userUpdate(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.PUT,
      url: 'user',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function contactUs(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'contact',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getNotificationList(limit = 10) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `user-notifications/list?limit=${limit}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getNotificationListById(lastId: number) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `user-notifications?lastId=${lastId}&read=false`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function userLoginCount() {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `user/user-count`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getNotificationById(id: string) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `notification/${id}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getCommodityByUserId() {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `user/current-user-commodity`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function deleteMyAccount() {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: `user/delete-account`,
      data: {},
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function deleteAccountResponse(
  comment: string,
  metaData?: string
) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: `user/delete-account-comment`,
      data: {
        comment,
        metaData,
      },
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function sendActivateAccountRequest() {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: `user/add-inactive-request`,
      data: {},
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
