import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera';

import {
  CaptureVideoOptions,
  MediaCapture,
} from '@awesome-cordova-plugins/media-capture';
import { Capacitor } from '@capacitor/core';
import {
  CreateThumbnailOptions,
  TranscodeOptions,
  VideoEditor,
} from '@awesome-cordova-plugins/video-editor';
import { random } from './common.helper';
import { FilePath } from '@awesome-cordova-plugins/file-path';
import { Chooser } from '@awesome-cordova-plugins/chooser';
export const imageCapture = async () => {
  // try {
  //   const data: any = await MediaCapture.captureImage(options || {});
  //   console.log('data', JSON.stringify(data));
  //   return parseMediaResponse(data);
  // } catch (e) {
  //   console.log('error');
  //   return null;
  // }
  try {
    const options: CameraOptions = {
      quality: 100,
      sourceType: Camera.PictureSourceType.CAMERA,
      destinationType: Camera.DestinationType.DATA_URL,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    const image = await Camera.getPicture(options);
    const base64Image = 'data:image/jpeg;base64,' + image;
    return base64Image;
  } catch (error) {
    return null;
  }
};

export const chooseFromGallery = async (): Promise<any> => {
  try {
    const options: CameraOptions = {
      quality: 100,
      sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: Camera.DestinationType.DATA_URL,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    const image = await Camera.getPicture(options);
    const base64Image = 'data:image/jpeg;base64,' + image;
    return base64Image;
  } catch (error) {
    return null;
  }
};

export const videoRecorder = async (options: CaptureVideoOptions) => {
  const retObj = { path: '', compressed: '', thumbnail: '' };
  try {
    const data: any = await MediaCapture.captureVideo(options);
    console.log('MediaCapture', data);
    const resp: any = parseMediaResponse(data);
    const compressed = await compressVideo(resp.path);
    const thumbnail = await createThumbnail(`file://${compressed}`);
    return { path: resp.path, compressed, thumbnail };
  } catch (error) {
    console.log(error);
    return null;
  }
  return retObj;
};

export const chooseFromGallery2 = async (
  options?: any,
  doCompress?: boolean
): Promise<any> => {
  try {
    const respFile = await Chooser.getFile(options);
    if (doCompress && respFile) {
      const compressed = await compressVideo(respFile?.uri);
      const thumbnail = await createThumbnail(`file://${compressed}`);
      return { path: respFile.uri, compressed, thumbnail };
    }
    return respFile;
  } catch (e) {
    console.log('error');
    return null;
  }
};

export const compressVideo = async (fileUri: string) => {
  try {
    let temp = fileUri;
    if (Capacitor.getPlatform() === 'android') {
      temp = await FilePath.resolveNativePath(fileUri);
    }
    const transcodeOptions: TranscodeOptions = {
      fileUri: temp,
      outputFileName: `${random()}`,
      outputFileType: VideoEditor.OutputFileType.MPEG4,
      saveToLibrary: false,
    };
    const compressed = await VideoEditor.transcodeVideo(transcodeOptions);
    console.log('video transcode success', compressed);
    console.log(compressed);
    return compressed;
  } catch (error) {
    console.log(error);
  }
  return '';
};

export const createThumbnail = async (fileUri: string) => {
  console.log('fileUri', fileUri);
  const thumbnailOptions: CreateThumbnailOptions = {
    height: 1024,
    width: 1024,
    quality: 100,
    fileUri, // looks something like this : http://example.com/filename.mp4
    outputFileName: `${random()}`,
  };
  try {
    const thumbnail = await VideoEditor.createThumbnail(thumbnailOptions);
    return thumbnail;
  } catch (error) {
    console.log(error);
  }
  return '';
};

const parseMediaResponse = (data: any) => {
  let path;
  const len = data.length;
  for (let i = 0; i < len; i += 1) {
    path = {
      path: data[i].fullPath,
      type: data[i].type,
    };
  }
  return path;
};

// const createImage = (url: string) =>
//   new Promise((resolve, reject) => {
//     const image = new Image();
//     image.addEventListener('load', () => resolve(image));
//     image.addEventListener('error', (error) => reject(error));
//     image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
//     image.src = url;
//   });

// function getRadianAngle(degreeValue: any) {
//   return (degreeValue * Math.PI) / 180;
// }

// export async function getCroppedImg(
//   imageSrc: any,
//   pixelCrop: any,
//   rotation = 0
// ) {
//   const image: any = await createImage(imageSrc);
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');

//   const maxSize = Math.max(image.width, image.height);
//   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

//   // set each dimensions to double largest dimension to allow for a safe area for the
//   // image to rotate in without being clipped by canvas context
//   canvas.width = safeArea;
//   canvas.height = safeArea;

//   if (ctx) {
//     ctx.fillStyle = 'white'
// ctx.fillRect(0, 0, canvas.width, canvas.height)
//     // translate canvas context to a central location on image to allow rotating around the center.
//     ctx.translate(safeArea / 2, safeArea / 2);
//     ctx.rotate(getRadianAngle(rotation));
//     ctx.translate(-safeArea / 2, -safeArea / 2);

//     // draw rotated image and store data.
//     ctx.drawImage(
//       image,
//       safeArea / 2 - image.width * 0.5,
//       safeArea / 2 - image.height * 0.5
//     );
//     const data = ctx.getImageData(0, 0, safeArea, safeArea);

//     // set canvas width to final desired crop size - this will clear existing context
//     canvas.width = pixelCrop.width;
//     canvas.height = pixelCrop.height;

//     // paste generated rotate image with correct offsets for x,y crop values.
//     ctx.putImageData(
//       data,
//       Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//       Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//     );
//   }
//   // As Base64 string
//   // return canvas.toDataURL('image/jpeg');

//   // As a blob
//   return new Promise((resolve) => {
//     canvas.toBlob((file) => {
//       console.log(file);
//       if(file)
//       resolve(URL.createObjectURL(file));
//     }, "image/jpeg");
//   });
// }
