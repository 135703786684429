// import Head from 'next/head'
import { Capacitor } from '@capacitor/core';
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { useEffect, useRef } from 'react';
import Footer from '../footer';
import Header from '../header';
import './style.scss';

// import { useRouter } from 'next/router'

type Props = {
  children: any;
  hideUserCentric?: boolean;
};

const Layout = (props: Props) => {
  const { children, hideUserCentric } = props;
  const scrollUp = 'scroll-up';
  const scrollDown = 'scroll-down';
  let lastScroll = 0;

  const pageWrapperRef = useRef<HTMLDivElement>(null);
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      window.location.reload();
      event.detail.complete();
    }, 2000);
  }
  const logScrolling = (event: any) => {
    const pageWrapperNode = pageWrapperRef.current;
    const currentScroll =
      event.detail.currentY > event.detail.startY
        ? event.detail.currentY
        : event.detail.currentY;

    if (currentScroll <= 0) {
      pageWrapperNode?.classList.remove(scrollUp);
      return;
    }

    if (
      currentScroll &&
      currentScroll > 145 &&
      currentScroll > lastScroll &&
      !pageWrapperNode?.classList.contains(scrollDown)
    ) {
      // down
      pageWrapperNode?.classList.remove(scrollUp);
      pageWrapperNode?.classList.add(scrollDown);
    } else if (
      currentScroll &&
      currentScroll < lastScroll &&
      pageWrapperNode?.classList.contains(scrollDown)
    ) {
      // up
      pageWrapperNode?.classList.remove(scrollDown);
      pageWrapperNode?.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  };

  useEffect(() => {
    const scriptId = document.getElementById('usercentrics-cmp');
    if (hideUserCentric) {
      if (scriptId) {
        document.head.removeChild(scriptId);
      }
    } else {
      if (!scriptId) {
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          'https://app.usercentrics.eu/browser-ui/latest/loader.js'
        );
        script.setAttribute('id', 'usercentrics-cmp');
        script.setAttribute('data-settings-id', '91t6XagZf');
        script.setAttribute('async', '');

        document.head.appendChild(script);
      }
    }
  }, [hideUserCentric]);
  return (
    <div>
      <IonContent
        fullscreen
        className='page-content ion-padding'
        scrollEvents={true}
        onIonScroll={(event) => logScrolling(event)}
      >
        <div
          className={`${Capacitor.getPlatform() == 'web' ? 'pageWrapper' : ''}`}
          ref={pageWrapperRef}
        >
          <Header />
          <IonRefresher
            slot='fixed'
            onIonRefresh={doRefresh}
            disabled={Capacitor.getPlatform() == 'web'}
          >
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <div
            className={`${
              Capacitor.getPlatform() != 'web'
                ? 'layout-container-app layout-main'
                : 'layout-main'
            }`}
          >
            {children}
          </div>
          {Capacitor.getPlatform() == 'web' ? <Footer /> : null}
        </div>
      </IonContent>
    </div>
  );
};

export default Layout;
