import Messages from '../config/messages';
import httpService from './http.service';

export async function getCommodityList() {
  try {
    const resp: any = await httpService.execute({
      url: `commodity`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getCommodityListByFilter(filter = 'all') {
  try {
    const resp: any = await httpService.execute({
      url: `commodity/list/${filter}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
