import './style.scss';
import { IonImg, IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
// import AboutUs2Rect from '../../assets/images/banner-aboutib.jpg';
// import About2Image from '../../assets/images/about2-bottom-img.png';
import CDPlaceholder from '../../assets/images/cd-placeholder.png';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import { isNullOrEmptyText } from '../../utils/common.helper';

const AboutIB: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);

  const [isContentLoaded, setIsContentLoaded] = useState(false);

  const [entry, setEntry] = useState({
    title: '',
    Para1: '',
    Para2: '',
    Para3: '',
    Para4: '',
    Pillarstitle: '',
  });

  const [pillars, setPillars] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent('about-ib');
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
      setPillars(entry.Pillars.split(','));
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  return (
    <IonPage className='page-aboutus2'>
      <Layout>
        {loading ? (
          <div></div>
        ) : (
          <>
            <div className='rect-img page-banner'>
              <IonImg src={CDPlaceholder}></IonImg>
            </div>
            <div className='section'>
              <div className='container'>
                <div className='content-sec'>
                  {!isNullOrEmptyText(entry.title) && <h1>{entry.title}</h1>}
                  {!isNullOrEmptyText(entry.Para1) && <p>{entry.Para1}</p>}
                  {!isNullOrEmptyText(entry.Para2) && <p>{entry.Para2}</p>}
                  {!isNullOrEmptyText(entry.Para3) && <p>{entry.Para3}</p>}
                  {!isNullOrEmptyText(entry.Para4) && <p>{entry.Para4}</p>}

                  {!isNullOrEmptyText(entry.Pillarstitle) && (
                    <p className='mb-0'>
                      <strong>{entry.Pillarstitle}</strong>
                    </p>
                  )}

                  {pillars && (
                    <ul>
                      {' '}
                      {pillars.map((pillar) => (
                        <li key={pillar}>{pillar}</li>
                      ))}
                    </ul>
                  )}
                  <div className='bottom-img mt-40 md-mt-20'>
                    <IonImg src={CDPlaceholder}></IonImg>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </IonPage>
  );
};
export default AboutIB;
