import { APP_DOMAIN } from '../../../config/appconfig';

const PrivacyMA: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        कोव्हेस्ट्रो अन्न सुरक्षा प्लॅटफॉर्मसाठी गोपनीयतेच्या अटी
      </h3>
      <h4 style={{ textAlign: 'center' }}>
        [सप्टेंबर 27, 2022] रोजी अंतिम अद्यतनित केले
      </h4>
      <h3>प्रस्तावना</h3>
      <p>
        कोवेस्ट्रो (इंडिया) प्रायव्हेट लिमिटेड, पूर्वी बायर मटेरियल सायन्स
        प्रायव्हेट लिमिटेड या नावाने ओळखल्या जाणाऱ्या आमच्या समुदायाचा भाग
        होण्यासाठी निवड केल्याबद्दल धन्यवाद, कंपनी कायदा 1956 अन्वये समाविष्ट
        केलेली खाजगी लिमिटेड कंपनी आणि युनिट क्रमांक SB-801 8वा मजला, एम्पायर
        टॉवर, क्लाउड सिटी कॅम्पस, ऐरोली, ठाणे – बेलापूर रोड नवी मुंबई ठाणे MH
        400708 IN येथे नोंदणीकृत कार्यालय आहे (“कोव्हेस्ट्रो”, “कंपनी”, “आम्ही”,
        “आम्ही”, किंवा “आमचे”, किंवा ‘प्लॅटफॉर्म’). तुमची वैयक्तिक माहिती आणि
        तुमच्या गोपनीयतेच्या अधिकाराचे संरक्षण करण्यासाठी आम्ही वचनबद्ध आहोत.
        तुमच्या वैयक्तिक माहितीच्या संदर्भात आमच्या धोरणाबद्दल किंवा आमच्या
        पद्धतींबद्दल तुम्हाला काही प्रश्न किंवा समस्या असल्यास, कृपया आमच्याशी
        संपर्क साधा.
      </p>
      <p>
        हे गोपनीयता धोरण आमच्या अटी आणि नियमांसह वाचण्यात आले आहे जे तुमच्या
        प्लॅटफॉर्मचा वापर आणि प्रवेश नियंत्रित करते. वापराच्या अटींसाठी{' '}
        <a href={`${APP_DOMAIN}legal/conditions-of-use`}>
          {`${APP_DOMAIN}legal/conditions-of-use`}
        </a>{' '}
        येथे प्रवेश करता येईल
      </p>
      <p>
        आमच्या सेवांचा लाभ घेण्यापूर्वी प्रत्येक वापरकर्त्याने गोपनीयता अटी
        तपशीलवार वाचण्यासाठी वेळ द्यावा अशी विनंती आहे आणि या गोपनीयता
        धोरणामध्ये काही अटी असतील ज्यांच्याशी तुम्ही सहमत नसाल तर कृपया आमची
        वेबसाइट आणि आमच्या सेवांचा वापर बंद करा.
      </p>
      <p>
        हे गोपनीयता धोरण आमच्या वेबसाइट/अॅप [अन्न सुरक्षा प्लॅटफॉर्म] आणि/किंवा
        कोणत्याही संबंधित सेवा, विक्री, विपणन किंवा इव्हेंट्सद्वारे संकलित
        केलेल्या सर्व माहितीवर लागू होते (आम्ही त्यांना या गोपनीयता धोरणामध्ये{' '}
        <b>&quot;प्लॅटफॉर्म&quot;</b> म्हणून एकत्रितपणे संदर्भित करतो).
      </p>
      <p>
        हे गोपनीयता धोरण आमच्या गोपनीयता पद्धतींचे स्पष्टीकरण देण्यासाठी आहे आणि
        त्यात खालील क्षेत्रांचा समावेश करते:
      </p>
      <ol>
        <li>
          <h3>आम्ही कोणती माहिती संकलित करतो?</h3>
          <ol>
            <li>
              <b>
                <p>
                  <i>तुम्ही आम्हाला सांगितलेली वैयक्तिक माहिती </i>
                </p>
              </b>
              <i>
                <p>
                  <b>संक्षिप्त:</b> तुम्ही आम्हाला दिलेली वैयक्तिक माहिती आम्ही
                  संकलित करतो जसे की नाव, पत्ता, संपर्क माहिती यासह परंतु बिलिंग
                  रकमेपर्यंत मर्यादित नाही.
                </p>
              </i>
              <p>
                प्लॅटफॉर्मवर नोंदणी करताना तुम्ही स्वेच्छेने आम्हाला प्रदान
                केलेली वैयक्तिक माहिती आम्ही संकलित करतो आणि प्लॅटफॉर्मवरील
                क्रियाकलापांमध्ये सहभागी होताना किंवा अन्यथा खाली दिलेल्या
                माहितीमध्ये आमच्याशी संपर्क साधताना आमच्याबद्दल किंवा आमची
                उत्पादने आणि सेवांबद्दल माहिती मिळविण्यात स्वारस्य व्यक्त करतो.
              </p>
              <p>
                तुम्ही आम्हाला प्रदान केलेली सर्व वैयक्तिक माहिती खरी, पूर्ण आणि
                सत्य असली पाहिजे आणि अशा वैयक्तिक माहितीतील कोणतेही बदल तुम्ही
                आम्हाला सांगितले पाहिजेत.
              </p>
              <p>
                आम्ही संकलित केलेली वैयक्तिक माहिती तुमच्या आमच्याशी आणि
                प्लॅटफॉर्मवरील परस्परसंवादाच्या संदर्भावर, तुम्ही करता त्या
                निवडी आणि तुम्ही वापरत असलेली उत्पादने आणि सेवा यावर अवलंबून
                असते.
              </p>
              <p>
                आम्ही संकलित करत असलेल्या वैयक्तिक माहितीमध्ये पुढील गोष्टींचा
                समावेश असू शकतो:
              </p>
              <p>
                <b>नाव आणि संपर्क डेटा.</b> आम्ही तुमचे नाव आणि आडनाव, ई-मेल
                पत्ता, पोस्टाचा पत्ता, फोन क्रमांक आणि इतर तत्सम संपर्क डेटा
                संकलित करतो जो तुम्ही सेवांमध्ये प्रवेश करण्यासाठी द्याल.
              </p>
              <p>
                <b>ओळखपत्रे.</b> आम्ही प्रमाणीकरण आणि खाते प्रवेशासाठी वापरलेले
                पासवर्ड, पासवर्ड इशारे आणि तत्सम सुरक्षा माहिती संकलित{' '}
                <b>करत नाही.</b>
              </p>
              <p>
                <b>पेमेंट डेटा.</b> आम्ही फक्त तुमच्या पेमेंटवर प्रक्रिया
                करण्यासाठी, खरेदीवर प्रक्रिया करण्यासाठी आवश्यक असलेला डेटा
                संकलित करतो. सर्व पेमेंट डेटा पेमेंट गेटवेद्वारे संग्रहित केला
                जातो, ज्यावर आमचे मर्यादित नियंत्रण आहे आणि तुम्ही त्याच्या
                गोपनीयता धोरणांचे पुनरावलोकन केले पाहिजे आणि तुमच्या प्रश्नांची
                उत्तरे मिळण्यासाठी थेट पेमेंट प्रोसेसरशी संपर्क साधावा.
              </p>
              <p>
                <b>
                  चाचणी डेटा, चाचणी विश्लेषण अहवाल, सर्वेक्षण, AI अंदाज सांगणारे
                  मॉडेल.
                </b>{' '}
                आम्ही प्लॅटफॉर्मच्या वापरातून तुमचे सर्व डेटा अहवाल, विश्लेषण,
                सर्वेक्षण, पिकवलेले पीक, किंमती तपासण्यासाठी पिके, स्वारस्य
                असलेली उत्पादने, लेख आणि पोस्ट आवडलेले अंदाज मॉडेल्स आणि परिणाम
                आणि इतर व्युत्पन्न माहिती इत्यादी संकलित करतो.
              </p>
              <p>
                <b>सोशल मीडिया लॉगिन डेटा.</b> आम्ही तुम्हाला सोशल मीडिया खाते
                तपशील वापरून नोंदणी करण्याचा पर्याय देतो, जसे की तुमचे Facebook,
                Twitter किंवा इतर कोणतेही सोशल मीडिया खाते. तुम्ही अशा प्रकारे
                नोंदणी करणे निवडल्यास, आम्ही खालील माहिती संकलित करू:
              </p>
              <p>
                Facebook, Google+ Instagram, Pinterest, Twitter, LinkedIn
                सारख्या सोशल नेटवर्किंग प्लॅटफॉर्मवरील वापरकर्ता माहिती, तुमचे
                नाव, तुमचे सोशल नेटवर्क वापरकर्तानाव, स्थान, लिंग, जन्मतारीख,
                ई-मेल पत्ता, प्रोफाइल चित्र आणि संपर्कांसाठी सार्वजनिक डेटा
                यासह, तुम्ही तुमचे खाते अशा सामाजिक नेटवर्कशी कनेक्ट केल्यास. या
                माहितीमध्ये तुम्ही प्लॅटफॉर्म वापरण्यासाठी आणि/किंवा सामील
                होण्यासाठी आमंत्रित केलेल्या इतर कोणाचीही संपर्क माहिती समाविष्ट
                असू शकते
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>माहिती आपोआप संकलित केली जाते</i>
                </p>
              </b>
              <p>
                <i>
                  <b>संक्षिप्त:</b> जेव्हा तुम्ही आमच्या प्लॅटफॉर्मला भेट देता
                  तेव्हा काही माहिती - जसे की IP पत्ता आणि/किंवा ब्राउझर आणि
                  डिव्हाइस वैशिष्ट्ये सुरक्षिततेच्या उद्देशाने स्वयंचलितपणे
                  संकलित केली जाते आणि ती प्रतिबंधित केली जाऊ शकत नाही.
                </i>
              </p>
              <p>
                तुम्ही प्लॅटफॉर्मला भेट देता, वापरता किंवा नेव्हिगेट करता तेव्हा
                आम्ही काही माहिती स्वयंचलितपणे संकलित करतो. ही माहिती तुमची
                विशिष्ट ओळख (जसे की तुमचे नाव किंवा संपर्क माहिती) प्रकट करत
                नाही परंतु तुमचा IP पत्ता, ब्राउझर आणि डिव्हाइस वैशिष्ट्ये,
                ऑपरेटिंग सिस्टम, भाषा प्राधान्ये, संदर्भित URLs, डिव्हाइसचे नाव,
                देश, स्थान यासारखी डिव्हाइस आणि वापर माहिती तसेच तुम्ही आमचे
                प्लॅटफॉर्म कसे आणि केव्हा वापरता याबद्दल माहिती आणि इतर तांत्रिक
                माहितीचा समावेश असू शकतो. ही माहिती प्रामुख्याने आमच्या
                प्लॅटफॉर्मची सुरक्षा आणि ऑपरेशन राखण्यासाठी आणि आमच्या अंतर्गत
                विश्लेषणे आणि अहवालाच्या हेतूंसाठी आवश्यक आहे.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>आमच्या प्लॅटफॉर्मद्वारे संकलित केलेली माहिती</i>
                </p>
              </b>
              <p>
                <i>
                  <b>संक्षिप्त:</b> तुम्ही प्लॅटफॉर्म वापरता तेव्हा आम्ही तुमचे
                  भौगोलिक-स्थान, मोबाइल डिव्हाइस, पुश सूचनापत्रे यासंबंधी माहिती
                  संकलित करू शकतो.
                </i>
              </p>
              <p>
                तुम्ही आमचा प्लॅटफॉर्म वापरल्यास, आम्ही खालील माहिती सुद्धा
                संकलित करू शकतो:
              </p>
              <p>
                - <i>भौगोलिक-स्थान माहिती.</i> स्थान-आधारित सेवा प्रदान
                करण्यासाठी, आम्ही सतत किंवा तुम्ही आमचे मोबाइल अॅप वापरत असताना,
                तुमच्या मोबाइल डिव्हाइसवरून स्थान-आधारित माहितीमध्ये प्रवेश
                किंवा परवानगी मागू शकतो आणि ट्रॅक करू शकतो. तुम्हाला आमचा प्रवेश
                किंवा परवानग्या बदलायच्या असल्यास, तुम्ही ते तुमच्या
                डिव्हाइसच्या सेटिंग्जमध्ये करू शकता.
              </p>
              <p>
                - <i>मोबाइल डिव्हाइस प्रवेश.</i> आम्ही तुमच्या मोबाइल
                डिव्हाइसवरून तुमच्या मोबाइल डिव्हाइसचा [ब्लूटूथ, कॅलेंडर,
                कॅमेरा, संपर्क, मायक्रोफोन, स्मरणपत्रे, सेन्सर्स, SMS संदेश,
                सोशल मीडिया खाती, स्टोरेज,]IP पत्ता, वेळ क्षेत्र, तुमच्या मोबाइल
                सेवा प्रदात्याबद्दलची माहिती इत्यादी आणि इतर वैशिष्ट्यांसह काही
                वैशिष्ट्यांमध्ये प्रवेश किंवा परवानगीची विनंती करू शकतो.
                तुम्हाला आमचा प्रवेश किंवा परवानग्या बदलायच्या असल्यास, तुम्ही
                ते तुमच्या डिव्हाइसच्या सेटिंग्जमध्ये करू शकता.
              </p>
              <p>
                - <i>मोबाइल डिव्हाइस डेटा.</i> आम्ही डिव्हाइस माहिती (जसे की
                तुमचा मोबाइल डिव्हाइस ID, मॉडेल आणि निर्माता), हार्डवेअर मॉडेल;
                ऑपरेटिंग सिस्टम आणि त्याच्या आवृत्तीबद्दल माहिती; युनिक डिव्हाइस
                आयडेंटिफायर्स (उदा. IDFA); मोबाइल नेटवर्क माहिती; डिव्हाइस
                स्टोरेज माहिती, ऑपरेटिंग सिस्टम, आवृत्ती माहिती, IP पत्ता
                इत्यादी स्वयंचलितपणे संकलित करू शकतो.
              </p>
              <p>
                - <i>पुश सूचनापत्रे.</i> आम्ही तुमच्या खात्याबद्दल किंवा मोबाइल
                अॅप्लिकेशनशी संबंधित पुश सूचनापत्रे पाठवण्याची विनंती करू शकतो.
                तुम्हाला या प्रकारची संप्रेषणे मिळवण्यासाठी निवड रद्द करायची
                असल्यास, तुम्ही ते तुमच्या डिव्हाइसच्या सेटिंग्जमध्ये बंद करू
                शकता.
              </p>
              <p>
                - <b>अॅप वापर डेटा, यासह, इतरांमध्ये:</b> आम्ही वापराच्या
                वारंवारतेबद्दल माहिती; तुम्ही भेट देत असलेल्या आमच्या अॅपची
                क्षेत्रे आणि वैशिष्ट्ये; साधारणपणे तुम्ही वापरता ते पॅटर्न्स;
                विशिष्ट वैशिष्ट्यांसह प्रतिबध्दता याबद्दलची माहिती देखील संकलित
                करू शकतो.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>इतर स्त्रोतांकडून संकलित केलेली माहिती</i>
                </p>
              </b>
              <p>
                <i>
                  <b>संक्षिप्त:</b> आम्ही सार्वजनिक डेटाबेस, विपणन भागीदार, सोशल
                  मीडिया प्लॅटफॉर्म्स आणि इतर बाहेरील स्त्रोतांकडून मर्यादित
                  डेटा संकलित करू शकतो.
                </i>
              </p>
              <p>
                आम्ही सार्वजनिक डेटाबेस, संयुक्त विपणन भागीदार, सोशल मीडिया
                प्लॅटफॉर्म्स (Facebook सह परंतु त्यापुरतेच मर्यादित नाही) तसेच
                इतर तृतीय पक्षांसारख्या इतर स्त्रोतांकडून तुमच्याबद्दल माहिती
                मिळवू शकतो. ही माहिती संकलित करण्यासाठी, आम्ही तुमच्या मोबाइल
                डिव्हाइसवर किंवा कॉम्प्युटरवर कुकीज पाठवू शकतो किंवा इतर
                ट्रॅकिंग तंत्रज्ञान गुंतवू शकतो. आमच्या कुकी धोरणामध्ये अधिक
                माहिती पहा.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>आम्ही तुमची माहिती कशी वापरतो?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> कायदेशीर व्यावसायिक हितसंबंध आणि व्यापक सामाजिक
              फायद्यांवर आधारित, तुमच्याशी केलेल्या आमच्या कराराची पूर्तता,
              आमच्या कायदेशीर दायित्वांचे पालन आणि/किंवा तुमच्या संमतीच्या
              आधारावर आम्ही तुमच्या माहितीवर प्रक्रिया करतो.
            </i>
          </p>
          <p>
            खाली वर्णन केल्याप्रमाणे आम्ही आमच्या प्लॅटफॉर्मद्वारे संकलित केलेली
            निनावी माहिती विविध व्यावसायिक हितसंबंध आणि व्यापक सामाजिक
            फायद्यांसाठी वापरतो. तुमच्या (&quot;संमती&quot;) संमतीने आणि/किंवा
            आमच्या (&quot;कायदेशीर कारणे&quot;) कायदेशीर जबाबदाऱ्यांचे पालन
            करण्यासाठी तुमच्याशी (&quot;करारात्मक&quot;) करार करण्यासाठी किंवा
            पूर्ण करण्यासाठी आम्ही आमच्या कायदेशीर व्यावसायिक हितसंबंधांवर आणि
            व्यापक सामाजिक फायद्यांवर (&quot;व्यावसायिक आणि सामाजिक
            उद्दिष्टे&quot;), अवलंबून राहून या उद्देशांसाठी तुमच्या वैयक्तिक
            माहितीवर प्रक्रिया करतो. खाली यादीमध्ये दिलेल्या प्रत्येक
            उद्देशासाठी आम्ही विशिष्ट प्रक्रिया आधारांवर अवलंबून आहोत असे आम्ही
            सूचित करतो.
          </p>
          <p>
            आम्ही संकलित करतो किंवा प्राप्त करतो ती माहिती खालील उद्देशांसाठी
            वापरतो परंतु त्यापुरते मर्यादित नाही:
          </p>
          <p>
            - <b>खाते तयार करणे आणि लॉगऑन प्रक्रिया सुलभ करण्यासाठी </b>[तुमच्या
            संमतीने]. तुम्ही तुमच्या Google किंवा Facebook खात्यासारख्या तृतीय
            पक्ष खात्याशी तुमचे खाते आमच्याशी लिंक करणे निवडल्यास, खाते तयार
            करणे आणि लॉगऑन प्रक्रिया सुलभ करण्यासाठी तुम्ही आम्हाला संकलित
            करण्याची परवानगी दिलेली माहिती आम्ही त्या तृतीय पक्षांकडून वापरतो.
            अधिक माहितीसाठी &quot;सोशल मीडिया लॉगिन डेटा&quot; शीर्षकाखालील
            विभाग पहा.
          </p>
          <p>
            - <b>तुम्हाला विपणन आणि प्रचारात्मक संप्रेषणे</b> [व्यावसायिक आणि
            सामाजिक उद्देशांसाठी आणि/किंवा तुमच्या संमतीने] पाठवण्यासाठी. हे
            तुमच्या विपणन प्राधान्यांनुसार असल्यास, आम्ही आणि/किंवा आमचे
            तृतीय-पक्ष विपणन भागीदार तुम्ही आम्हाला पाठवलेली वैयक्तिक माहिती
            आमच्या विपणन हेतूंसाठी वापरू शकतो. तुम्ही आमच्या विपणन ई-मेल्समधून
            कधीही निवड रद्द करू शकता (खालील &quot;तुमचे गोपनीयता अधिकार&quot;
            पहा).
          </p>
          <p>
            - व्यावसायिक आणि सामाजिक हेतू कायदेशीर कारणांसाठी आणि/किंवा शक्यतो
            करारानुसार <b>प्रशासकीय माहिती तुम्हाला पाठवण्यासाठी.</b>{' '}
            प्लॅटफॉर्म/अॅपद्वारे तुम्हाला देऊ केलेल्या सेवांमध्ये सुधारणा
            करताना, आम्ही तुमची वैयक्तिक माहिती तुम्हाला उत्पादन, सेवा आणि नवीन
            वैशिष्ट्यांची माहिती आणि आमचे नियम, अटी आणि धोरणांमधील बदलांबद्दल
            माहिती पाठवण्यासाठी वापरू शकतो;
          </p>
          <p>
            -{' '}
            <b>
              प्लॅटफॉर्मचे विश्लेषण करणे, ऑपरेट करणे, देखरेख करणे आणि सुधारणे,
            </b>{' '}
            प्लॅटफॉर्ममध्ये नवीन वैशिष्ट्ये आणि सेवा जोडणे, प्लॅटफॉर्मच्या
            विद्यमान कार्यांना समर्थन देण्यासाठी;
          </p>
          <p>
            - तृतीय-पक्ष उत्पादने आणि ऑफरसह{' '}
            <b>
              तुम्हाला उत्पादन आणि सेवा ऑफर आणि शिफारशी कस्टमाइझ करण्यासाठी,
            </b>{' '}
            तुमच्या खात्याबद्दल तुम्हाला सूचित करण्यासाठी, तुमच्या खात्यातील
            समस्या सोडवण्यासाठी, विवादाचे निराकरण करण्यासाठी, शुल्क किंवा पैसे
            जमा करण्यासाठी, तुम्ही प्लॅटफॉर्म/अॅप वापरण्यास पात्र आहात याची
            खात्री करण्यासाठी तुमच्या ओळखीची पुष्टी करण्यासाठी;
          </p>
          <p>
            -{' '}
            <b>
              तुम्ही विनंती करत असलेली उत्पादने आणि सेवा प्रदान करण्यासाठी आणि
              वितरीत करण्यासाठी,
            </b>{' '}
            व्यवहारांवर प्रक्रिया करण्यासाठी आणि तुम्हाला पुष्टीकरणे आणि
            स्मरणपत्रांसह संबंधित माहिती पाठवण्यासाठी;
          </p>
          <p>
            - लागू असल्यास; <b>बिलिंग (इनव्हॉइसिंग), खाते व्यवस्थापन</b> आणि इतर
            प्रशासकीय हेतूंसाठी,
          </p>
          <p>
            -{' '}
            <b>आपल्या टिप्पण्या, प्रश्न आणि विनंत्यांना प्रतिसाद देण्यासाठी</b>{' '}
            आणि ग्राहक सेवा प्रदान करण्यासाठी;
          </p>
          <p>
            - <b>तुम्हाला तांत्रिक सूचना, अद्यतने,</b> सुरक्षा सूचना आणि समर्थन
            आणि प्रशासकीय संदेश पाठवण्यासाठी;
          </p>
          <p>
            - आमच्या प्लॅटफॉर्मच्या संबंधात{' '}
            <b>
              ट्रेंड, वापर आणि क्रियाकलापांचे परीक्षण आणि विश्लेषण करण्यासाठी;
            </b>
          </p>
          <p>
            - केवळ कोवेस्ट्रो <b>प्रचारात्मक हेतूंसाठी;</b> तुम्ही सामायिक
            करण्यास सहमत असलेल्या माहितीच्या संदर्भात;
          </p>
          <p>
            - <b>तुमची ओळख सत्यापित करण्यासाठी;</b>
          </p>
          <p>
            - आमच्या व्यावसायिक उद्देशांसाठी आणि/किंवा तुमच्या संमतीने{' '}
            <b>तुम्हाला लक्ष्यित जाहिराती वितरीत करा.</b> आम्ही तुमच्या माहितीचा
            वापर सामग्री आणि जाहिराती विकसित आणि प्रदर्शित करण्यासाठी करू शकतो
            आणि तुमच्या आवडी आणि/किंवा स्थानानुसार असे करणाऱ्या तृतीय पक्षांसोबत
            काम करू शकतो आणि त्याची परिणामकारकता मोजू शकतो.
          </p>
          <p>
            - आमच्या व्यावसायिक उद्देशांसाठी आणि/किंवा तुमच्या संमतीने{' '}
            <b>बक्षीस सोडती आणि स्पर्धा व्यवस्थापित करा.</b> तुम्ही
            स्पर्धांमध्ये भाग घेणे निवडता तेव्हा आम्ही तुमची माहिती बक्षीस सोडती
            आणि स्पर्धा व्यवस्थापित करण्यासाठी वापरू शकतो.;
          </p>
          <p>
            - आमच्या व्यावसायिक उद्देशांसाठी आणि तुमच्या संमतीने{' '}
            <b>अभिप्राय मागवा.</b> आम्ही तुमची माहिती अभिप्रायाची विनंती
            करण्यासाठी आणि आमच्या प्लॅटफॉर्मच्या तुमच्या वापराबद्दल तुमच्याशी
            संपर्क साधण्यासाठी वापरू शकतो;
          </p>
          <p>
            - व्यावसायिक उद्देशांसाठी आणि/किंवा कायदेशीर कारणांसाठी आमच्या{' '}
            <b>प्लॅटफॉर्मचे संरक्षण करण्यासाठी.</b> आमचा प्लॅटफॉर्म निर्धोक आणि
            सुरक्षित ठेवण्याच्या आमच्या प्रयत्नांचा भाग म्हणून आम्ही तुमची
            माहिती वापरू शकतो (उदाहरणार्थ, फसवणूक निरीक्षण आणि प्रतिबंधासाठी);
          </p>
          <p>
            - व्यावसायिक हेतू, कायदेशीर कारणे आणि/किंवा शक्यतो करारासाठी{' '}
            <b>आमच्या अटी, नियम आणि धोरणांची अंमलबजावणी करण्यासाठी;</b>
          </p>
          <p>
            - कायदेशीर कारणांसाठी{' '}
            <b>
              कायदेशीर विनंत्यांना प्रतिसाद देण्यासाठी आणि हानी टाळण्यासाठी.
            </b>{' '}
            आम्हाला सबपोना किंवा इतर कायदेशीर विनंती मिळाल्यास, आम्हाला प्रतिसाद
            कसा द्यायचा हे निर्धारित करण्यासाठी आमच्याकडे असलेल्या डेटाची तपासणी
            करणे आवश्यक आहे;
          </p>
          <p>
            - <b>इतर व्यावसायिक आणि सामाजिक उद्देशांसाठी.</b> आम्ही तुमच्या
            माहितीचा वापर इतर व्यवसाय उद्देशांसाठी जसे की डेटा विष्लेषण, वापर
            ट्रेंड ओळखणे, आमच्या प्रचार मोहिमेची परिणामकारकता ठरवण्यासाठी आणि
            आमच्या प्लॅटफॉर्मचे आणि तुमच्या अनुभवाचे मुल्यांकन आणि सुधारणा
            करण्यासाठी करू शकतो; आणि
          </p>
          <p>
            - <b>कंसोर्टियम भागीदारांसाठी.</b> ज्या उत्पादनांसाठी आणि सेवांसाठी
            तुम्ही प्लॅटफॉर्मवर स्वारस्य दाखवले आहे त्यासाठी आमचे कन्सोर्टियम
            भागीदार आणि प्लॅटफॉर्मवर नोंदणीकृत वापरकर्ते तुमची माहिती वापरू
            शकतात.
          </p>
        </li>
        <li>
          <h3>तुमची माहिती कोणाबरोबरही शेअर केली जाईल का?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> आम्ही फक्त तुमच्या संमतीने, कायद्यांचे पालन
              करण्यासाठी, तुमच्या अधिकारांचे संरक्षण करण्यासाठी किंवा व्यावसायिक
              जबाबदाऱ्या पूर्ण करण्यासाठी माहिती शेअर करतो.
            </i>
          </p>
          <p>आम्ही केवळ खालील प्रसंगात तुमची माहिती शेअर किंवा उघड करू शकतो:</p>
          <p>
            - <b>कायद्यांचे पालन.</b> लागू कायदा, सरकारी विनंत्या, न्यायालयीन
            कार्यवाही, न्यायालयीन आदेश किंवा न्यायालयीन आदेश किंवा सबपोनाच्या
            (राष्ट्रीय सुरक्षा किंवा कायद्याची अंमलबजावणी आवश्यकता पूर्ण
            करण्यासाठी सार्वजनिक प्राधिकरणांच्या प्रतिसादासह) प्रतिसादासारख्या
            कायदेशीर प्रक्रियेचे पालन करण्यासाठी आम्हाला कायदेशीररित्या तसे करणे
            आवश्यक आहे तेथे आम्ही तुमची माहिती उघड करू शकतो.
          </p>
          <p>
            - <b>महत्वाची स्वारस्ये आणि कायदेशीर हक्क.</b> आमच्या धोरणांचे
            संभाव्य उल्लंघन, संशयित फसवणूक, कोणत्याही व्यक्तीच्या सुरक्षेसाठी
            संभाव्य धोके आणि बेकायदेशीर क्रियाकलाप किंवा आम्ही ज्या खटल्यात
            गुंतलो आहोत त्यात पुरावा यासंबंधीची चौकशी करणे, प्रतिबंध करणे किंवा
            कारवाई करणे आवश्यक आहे असे आम्हाला वाटते तेथे आम्ही तुमची माहिती उघड
            करू शकतो.
          </p>
          <p>
            - <b>विक्रेते, सल्लागार आणि इतर तृतीय-पक्ष सेवा प्रदाते.</b> आम्ही
            तुमचा डेटा तृतीय पक्ष विक्रेते, सेवा प्रदाते, कंत्राटदार किंवा एजंट
            यांच्याशी सामायिक करू शकतो जे आमच्यासाठी किंवा आमच्या वतीने सेवा
            देतात आणि ते काम करण्यासाठी अशा माहितीमध्ये त्यांना प्रवेश द्यावा
            लागतो. उदाहरणांमध्ये यांचा समावेश आहे: पेमेंट प्रक्रिया, डेटा
            विश्लेषण, ई-मेल वितरण, होस्टिंग सेवा, ग्राहक सेवा आणि विपणन प्रयत्न.
            आम्ही निवडक तृतीय पक्षांना प्लॅटफॉर्मवर ट्रॅकिंग तंत्रज्ञान
            वापरण्याची परवानगी देऊ शकतो, जे त्यांना काही काळानंतर तुम्ही
            प्लॅटफॉर्मशी कसा संवाद साधता याविषयी डेटा संकलित करण्यास सक्षम करेल.
            ही माहिती इतर गोष्टींबरोबरच डेटाचे विश्लेषण आणि ट्रॅक करण्यासाठी,
            विशिष्ट सामग्रीची लोकप्रियता ठरवण्यासाठी आणि ऑनलाइन क्रियाकलाप अधिक
            चांगल्या प्रकारे समजून घेण्यासाठी वापरली जाऊ शकते. या धोरणामध्ये
            वर्णन केल्याशिवाय, आम्ही तुमची कोणतीही माहिती तृतीय पक्षांसोबत
            त्यांच्या प्रचारात्मक हेतूंसाठी शेअर करत नाही, विक्री करत नाही,
            भाड्याने किंवा त्याचा व्यापार करत नाही.
          </p>
          <p>
            - <b>व्यवसाय हस्तांतरण.</b> आम्ही कोणत्याही विलीनीकरणाच्या,
            कंपनीच्या मालमत्तेची विक्री, वित्तपुरवठा किंवा आमच्या व्यवसायाचा
            सर्व किंवा काही भाग दुसऱ्या कंपनीला मिळवून देण्याच्या संबंधात किंवा
            वाटाघाटी दरम्यान तुमची माहिती सामायिक किंवा हस्तांतरित करू शकतो.
          </p>
          <p>
            - <b>तृतीय-पक्ष जाहिरातदार.</b> तुम्ही प्लॅटफॉर्मला भेट देता तेव्हा
            आम्ही जाहिराती देण्यासाठी तृतीय-पक्ष जाहिरात कंपन्यांचा वापर करू
            शकतो. या कंपन्या तुम्हाला स्वारस्य असलेल्या वस्तू आणि सेवांबद्दल
            जाहिराती देण्यासाठी वेब कुकीज आणि इतर ट्रॅकिंग तंत्रज्ञानामध्ये
            समाविष्ट असलेल्या आमच्या प्लॅटफॉर्म आणि इतर प्लॅटफॉर्मवरील तुमच्या
            भेटींची माहिती वापरू शकतात.
          </p>
          <p>
            - <b>संलग्न.</b> आम्ही तुमची माहिती आमच्या सहयोगींसोबत शेअर करू
            शकतो, अशा वेळी त्या संलग्नांनी या गोपनीयता धोरणाचा आदर करण्याची
            आम्हाला आवशक्यता वाटते. संलग्नांमध्ये आमची मूळ कंपनी आणि इतर
            उपकंपन्या, संयुक्त उपक्रम भागीदार किंवा आम्ही नियंत्रित केलेल्या
            किंवा आमच्यासह सामान्य नियंत्रणाखाली असलेल्या इतर कंपन्या यांचा
            समावेश होतो.
          </p>
          <p>
            - <b>व्यवसाय भागीदार.</b> तुम्हाला काही उत्पादने, सेवा किंवा
            जाहिराती देण्यासाठी आम्ही तुमची माहिती आमच्या व्यावसायिक भागीदारांसह
            शेअर करू शकतो.
          </p>
          <p>
            - <b>तुमच्या संमतीने.</b> तुमच्या संमतीने आम्ही तुमची वैयक्तिक
            माहिती इतरही कारणासाठी उघड करू शकतो.
          </p>
          <p>
            - <b>इतर वापरकर्ते.</b> जेव्हा तुम्ही वैयक्तिक माहिती शेअर करता
            (उदाहरणार्थ, टिप्पण्या, योगदान किंवा प्लॅटफॉर्मवर इतर सामग्री पोस्ट
            करून) किंवा अन्यथा प्लॅटफॉर्मच्या सार्वजनिक क्षेत्रांशी संवाद साधता
            तेव्हा अशी वैयक्तिक माहिती सर्व वापरकर्ते पाहू शकतात आणि
            प्लॅटफॉर्मच्या बाहेर सार्वजनिकरित्या वितरीत केली जाऊ शकते. [तुम्ही
            आमच्या प्लॅटफॉर्मच्या इतर वापरकर्त्यांशी संवाद साधल्यास आणि सोशल
            नेटवर्कद्वारे नोंदणी केल्यास (जसे की Facebook), तुमचे संपर्क सोशल
            नेटवर्कवरील तुमचे नाव, प्रोफाइल फोटो आणि तुमच्या क्रियाकलापाचे वर्णन
            पाहतील.] त्याचप्रमाणे, इतर वापरकर्ते तुमच्या क्रियाकलापांचे वर्णन
            पाहू शकतील, आमच्या प्लॅटफॉर्ममध्ये तुमच्याशी संवाद साधू शकतील आणि
            तुमचे प्रोफाइल पाहू शकतील.
          </p>
        </li>
        <li>
          <h3>आम्ही कुकीज आणि इतर ट्रॅकिंग तंत्रज्ञान वापरतो का?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> आम्ही तुमची माहिती संकलित आणि संग्रहित
              करण्यासाठी कुकीज आणि इतर ट्रॅकिंग तंत्रज्ञान वापरू शकतो.
            </i>
          </p>
          <p>
            कोणतीही सुसंगत संस्था म्हणून, आम्ही कुकीज आणि तत्सम
            तंत्रज्ञानाद्वारे देखील माहिती संकलित करतो, जी आम्हाला तुमचा अनुभव
            आणि आमची उत्पादने आणि सेवा वर्धित होण्यास मदत करते. आम्ही वापरत
            असलेल्या कुकीज आमच्या प्लॅटफॉर्मला कार्य करण्यास अनुमती देतात आणि
            अभ्यागतांसाठी कोणती माहिती आणि जाहिरात जास्त उपयुक्त आहे हे समजून
            घेण्यात आम्हाला मदत करतात.
          </p>
          <p>
            बरेच वेब ब्राउझर्स डीफॉल्टनुसार कुकीज स्वीकारण्यासाठी सेट केलेले
            असतात. आपण प्राधान्य दिल्यास, आपण सहसा कुकीज काढण्यासाठी आणि कुकीज
            नाकारण्यासाठी आपले ब्राउझर सेटिंग निवडू शकता. तुम्ही कुकीज काढून
            टाकणे किंवा कुकीज नाकारणे निवडल्यास, आमच्या प्लॅटफॉर्मच्या काही
            वैशिष्ट्यांवर किंवा सेवांवर ह्यामुळे परिणाम होऊ शकतो.
          </p>
          <p>वापरकर्ताकेंद्री तंत्रज्ञान (संमती साधन)</p>
          <p>
            संमती आवश्यक असलेल्या कुकीजसाठी आणि कुकी-आधारित अॅप्लिकेशन्ससाठी
            प्रभावी वापरकर्ता संमती मिळवण्यासाठी, आमची वेबसाइट वापरकर्ताकेंद्री
            GmbH सेंडलिंगर Str. 7, 80331 म्युनिक द्वारे विकसित केलेल्या
            तंत्रज्ञानासह कुकीचे संमती साधन वापरते. (यापुढे
            &quot;वापरकर्ताकेंद्री&quot; म्हणून संदर्भित).
          </p>
          <p>
            संबंधित JavaScript कोड इंटिग्रेट करून, वापरकर्ते जेव्हा पृष्ठाला भेट
            देतात तेव्हा त्यांना एक बॅनर दर्शविला जातो, ज्यामध्ये काही कुकीज
            आणि/किंवा कुकी-आधारित अॅप्लिकेशन्ससाठी बॉक्सेस चेक करून संमती दिली
            जाऊ शकते. संबंधित वापरकर्त्याने संबंधित बॉक्सेस चेक करून संमती
            देईपर्यंत संमती आवश्यक असलेल्या सर्व कुकीजचे सेटिंग हे साधन अवरोधित
            करते. हे याची खात्री देते की जेव्हा संमती दिली जाते तेव्हा अशा कुकीज
            वापरकर्त्याच्या संबंधित एंड डिव्हाइसवर सेट केल्या जातात.
          </p>
          <p>
            वेबसाइटच्या तळाशी उजवीकडे - वापरकर्ताकेंद्री बटण दाबून वापरकर्ता
            कधीही सेटिंग्ज बदलू शकतो.
          </p>
          <p>
            जेव्हा आमच्या वेबसाइटवर प्रवेश केला जातो, तेव्हा विशिष्ट वापरकर्ता
            माहिती (IP पत्त्यासह) कुकी संमती साधनाद्वारे संकलित केली जाते,
            वापरकर्ताकेंद्राच्या सर्व्हरवर प्रसारित केली जाते आणि तेथे जतन केली
            जाते त्यामुळे कुकी संमती साधन वैयक्तिक वापरकर्त्यांना स्पष्टपणे
            पृष्ठ दृश्ये नियुक्त करू शकते आणि सत्राच्या कालावधीसाठी
            वापरकर्त्याने केलेल्या संमती सेटिंग्ज वैयक्तिकरित्या रेकॉर्ड, लॉग
            आणि जतन करू शकते.
          </p>
          <p>
            तुम्ही वापरकर्ताकेंद्रीद्वारे डेटाच्या वापराबद्दल अतिरिक्त माहिती
            वापरकर्ताकेंद्री गोपनीयता धोरणात{' '}
            <a
              href='https://usercentrics.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              https://usercentrics.com/privacy-policy/
            </a>{' '}
            येथे शोधू शकता
          </p>
          <p>
            तुम्हाला काही प्रश्न असल्यास आम्हाला ई-मेल पाठवून किंवा खाली
            दिलेल्या &quot;आमच्याशी संपर्क साधा&quot; द्वारे विनंती सबमिट करून
            कळवा.
          </p>
        </li>
        <li>
          <h3>आम्ही स्थान सेवा वापरतो का?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> होय, आम्ही स्थान सेवा वापरतो, एकतर अंगभूत
              प्रोग्राम किंवा Google नकाशे सारख्या बाह्य प्रदात्याचा वापर उत्तम
              सेवा प्रदान करण्याच्या उद्देशाने करतो.
            </i>
          </p>
          <p>
            संकलित केलेली माहिती केवळ या धोरणाच्या उद्देशांसाठी वापरण्यात येईल
            आणि अशा सेवा प्रदात्याच्या वापराच्या अटी व शर्ती आमच्या व्याप्ती आणि
            नियंत्रणाच्या बाहेर आहेत.
          </p>
        </li>
        <li>
          <h3>तुमची माहिती आंतरराष्ट्रीय स्तरावर हस्तांतरित केली जाते का?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> आम्ही तुमची माहिती तुमच्या स्वतःच्या व्यतिरिक्त
              इतर देशांमध्ये हस्तांतरित करू शकतो, संग्रहित करू शकतो आणि त्यावर
              प्रक्रिया करू शकतो.
            </i>
          </p>
          <p>
            आम्ही प्रोसेसर्स गुंतवून ठेवतो जे आमच्यासाठी तुमच्या वैयक्तिक डेटासह
            विशिष्ट ऑपरेशन करतात. प्रोसेसर अशा कंपन्या आहेत ज्या आम्हाला
            प्लॅटफॉर्म चालविण्यात मदत करतात, तुमच्याशी आमच्या संप्रेषणासाठी
            समर्थन करतात किंवा इतर प्लॅटफॉर्म-संबंधित क्रियाकलाप करतात.
            वेबसाइट्स आणि अॅप फंक्शन्स आणि संबंधित क्रियाकलापांशी संबंधित
            उद्दिष्टे पूर्ण करण्यासाठी ते आमच्या वतीने विशिष्ट वैयक्तिक डेटावर
            प्रक्रिया करू शकतात. प्रोसेसर केवळ आमच्या सूचनांप्रमाणे कार्य करतात
            आणि आम्ही त्यांना प्रक्रिया करण्यासाठी सूचना दिलेल्याच वैयक्तिक
            डेटावर प्रक्रिया करतो. आमच्या प्रोसेसरच्या कोणत्याही कृती किंवा
            वगळण्यासाठी आम्ही पूर्णपणे जबाबदार आहोत आणि लागू कायद्याद्वारे
            आवश्यक मर्यादेपर्यंत त्यांच्याशी औपचारिक डेटा प्रक्रिया करार अंमलात
            आणण्याचे वचन देतो.
          </p>
          <p>
            आमचा मुख्य प्रोसेसर Amazon Web Services Ince (AWS) आहे. Amazon Web
            Services, Inc. युनायटेड स्टेट्स मध्ये आहे आणि त्यांच्या सहयोगी
            कंपन्या पूर्ण जगभर आहेत. AWS ऑफरिंगसह आमच्या परस्परसंवादाच्या
            व्याप्तीवर अवलंबून, वैयक्तिक माहिती युनायटेड स्टेट्ससह विविध
            देशांमधून संग्रहित केली जाऊ शकते किंवा त्यात प्रवेश केला जाऊ शकतो.
            जेव्हा जेव्हा AWS कडून वैयक्तिक माहिती इतर अधिकारक्षेत्रांमध्ये
            हस्तांतरित होते, तेव्हा ती माहिती तिच्या गोपनीयता सूचनेनुसार (
            <a
              href='https://aws.amazon.com/privacy/'
              target='_blank'
              rel='noreferrer'
            >
              https://aws.amazon.com/privacy/
            </a>
            ) आणि लागू डेटा संरक्षण कायद्यांद्वारे परवानगी दिल्यानुसार
            हस्तांतरित होत आहे याची खात्री केली जाते.
          </p>
          <p>
            याव्यतिरिक्त, संकलित केलेला वैयक्तिक डेटा अशा देशांमध्ये संग्रहित
            केला जाऊ शकतो आणि त्यावर प्रक्रिया केली जाऊ शकते, जेथे कोव्हेस्ट्रो
            सर्व्हर भाड्याने देतात किंवा इतर कोणत्याही देशात ज्यामध्ये
            कोवेस्ट्रो किंवा त्याच्या संलग्न कंपन्या, सहाय्यक कंपन्या किंवा एजंट
            सुविधा राखतात आणि प्लॅटफॉर्म वापरून, तुम्ही तुमच्या देशाबाहेर
            वैयक्तिक डेटाच्या अशा कोणत्याही हस्तांतरणास संमती देता यास तुम्ही
            सहमत आहात.
          </p>
        </li>
        <li>
          <h3>थर्ड-पार्टी प्लॅटफॉर्मवर आमची भूमिका काय आहे?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> जाहिरात करणाऱ्या आणि आमच्या प्लॅटफॉर्मशी संलग्न
              नसलेल्या तृतीय-पक्ष प्रदात्यांसोबत तुम्ही शेअर केलेल्या कोणत्याही
              माहितीच्या सुरक्षिततेसाठी आम्ही जबाबदार नाही.
            </i>
          </p>
          <p>
            प्लॅटफॉर्ममध्ये आमच्याशी संलग्न नसलेल्या आणि इतर प्लॅटफॉर्म आणि अॅप,
            ऑनलाइन सेवा किंवा मोबाइल अॅप्लिकेशन्सशी लिंक होऊ शकणाऱ्या तृतीय
            पक्षांच्या लिंक्स आणि जाहिराती असू शकतात. याप्रकारच्या
            जाहिरातींमध्ये प्रवेश करून, तुम्ही कोणत्याही तृतीय पक्षांना प्रदान
            करता त्या डेटाची सुरक्षितता आणि गोपनीयता याची आम्ही हमी देऊ शकत
            नाही.
          </p>
          <p>
            तृतीय पक्षांद्वारे संकलित केलेला कोणताही डेटा या गोपनीयता धोरणात
            समाविष्ट केलेला नाही.
          </p>
          <p>
            आम्ही इतर प्लॅटफॉर्म, सेवा किंवा प्लॅटफॉर्मशी किंवा त्याच्याशी लिंक
            अॅप्लिकेशन्ससह कोणत्याही तृतीय पक्षाची सामग्री किंवा गोपनीयता आणि
            सुरक्षा पद्धती आणि धोरणांसाठी जबाबदार असणार नाही. तुम्ही अशा तृतीय
            पक्षांच्या धोरणांचे पुनरावलोकन करावे आणि तुमच्या प्रश्नांना उत्तर
            देण्यासाठी त्यांच्याशी थेट संपर्क साधावा.
          </p>
        </li>
        <li>
          <h3>आम्ही तुमची माहिती किती काळ ठेवू?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> जोपर्यंत कायद्याने आवश्यक नाही तोपर्यंत, या
              गोपनीयता धोरणामध्ये नमूद केलेल्या उद्देशांची पूर्तता करण्यासाठी
              आम्ही तुमची माहिती आवश्यकता असेपर्यंत ठेवतो.
            </i>
          </p>
          <p>
            जोपर्यंत दीर्घ धारणा कालावधी आवश्यक आहे किंवा कायद्याद्वारे परवानगी
            दिली जात नाही तोपर्यंत, या गोपनीयता धोरणामध्ये नमूद केलेल्या
            उद्देशांसाठी आवश्यक असेल तोपर्यंत आम्ही तुमची वैयक्तिक माहिती ठेवू
            (जसे की कर, लेखा किंवा इतर कायदेशीर आवश्यकता). या धोरणातील कोणत्याही
            उद्देशासाठी आम्हाला तुमची वैयक्तिक माहिती आमच्याकडे खाते असलेल्या
            कालावधीपेक्षा जास्त कालावधीसाठी (किंवा) तुमचे खाते संपुष्टात
            आणल्यानंतर 1 वर्षानंतर, यापैकी जे नंतर असेल त्या कालावधीसाठी
            ठेवण्याची आवश्यकता नाही.
          </p>
          <p>
            जेव्हा आम्हाला तुमच्या वैयक्तिक माहितीवर प्रक्रिया करण्याची
            कोणत्याही कायदेशीर व्यवसायाची आवश्यकता नसते, तेव्हा आम्ही एकतर ती
            हटवू किंवा निनावी करू किंवा हे शक्य नसल्यास (उदाहरणार्थ, तुमची
            वैयक्तिक माहिती बॅकअप संग्रहणांमध्ये संग्रहित केल्यामुळे), त्यानंतर
            आम्ही तुमची वैयक्तिक माहिती सुरक्षितपणे संग्रहित करू आणि हटवता येणार
            नाही तोपर्यंत ती पुढील कोणत्याही प्रक्रियेपासून वेगळी ठेवू.
          </p>
        </li>
        <li>
          <h3>आम्ही तुमची माहिती सुरक्षित कशी ठेवू?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> संस्थात्मक आणि तांत्रिक सुरक्षा उपायांच्या
              सिस्टमद्वारे तुमची वैयक्तिक माहिती संरक्षित करण्याचे आमचे ध्येय
              आहे.
            </i>
          </p>
          <p>
            आम्ही प्रक्रिया किंवा प्राप्त केलेल्या कोणत्याही वैयक्तिक माहितीच्या
            सुरक्षेसाठी डिझाइन केलेले योग्य तांत्रिक आणि संस्थात्मक सुरक्षेचे
            उपाय आम्ही लागू केले आहेत. कोणतेही उल्लंघन निश्चित करण्यासाठी आणि
            ओळखण्यासाठी योग्य काळजी आणि संरक्षण केले जाते, ज्यासाठी योग्य
            सुधारात्मक प्रक्रिया सुरू केली जाईल. तथापि, आम्ही तुम्हाला सूचित
            करतो की, आवश्यक सेफगार्ड्स लागू केले असले तरी, तांत्रिक इंटरफेसमुळे
            संभाव्य धोके असू शकतात.
          </p>
          <p>
            आम्ही तुमच्या वैयक्तिक माहितीचे संरक्षण करण्यासाठी जास्तीत जास्त
            प्रयत्न करू, तरीही आमच्या प्लॅटफॉर्मवर आणि आमच्या प्लॅटफॉर्मवरून
            वैयक्तिक माहितीचे प्रसारण तुमच्या स्वतःच्या जबाबदारीवर आहे. तुम्ही
            केवळ सुरक्षित वातावरणातच सेवांमध्ये प्रवेश केला पाहिजे.
          </p>
        </li>
        <li>
          <h3>आम्ही अल्पवयीन मुलांकडून माहिती गोळा करतो का?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> आम्ही 16 वर्षांखालील मुलांकडून जाणूनबुजून डेटा
              संकलित करत नाही किंवा त्याचे विपणन करत नाही.
            </i>
          </p>
          <p>
            <b>सामान्य वय मर्यादा.</b> मुलांच्या गोपनीयतेचे रक्षण करण्यासाठी
            आम्ही वचनबद्ध आहोत. हा प्लॅटफॉर्म मुलांसाठी नाही आणि आम्ही
            जाणूनबुजून 16 वर्षाखालील मुलांची माहिती संकलित करत नाही.
            कोवेस्ट्रोला माहीत असलेल्या 16 वर्षांपेक्षा कमी वयाच्या कोणत्याही
            व्यक्तीकडून हा प्लॅटफॉर्म वैयक्तिक डेटा संकलित करत नाही. प्लॅटफॉर्म
            वापरत असलेल्या 16 वर्षांखालील कोणीही तुम्हाला माहीत असल्यास, कृपया
            आमच्याशी{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            येथे संपर्क साधा आणि आम्ही अशी माहिती हटवण्यासाठी आणि (किंवा)
            त्याचे/तिचे खाते हटवण्यासाठी आवश्यक पावले उचलू.
          </p>
          <p>
            <b>युरोपियन युनियन रहिवाशांसाठी वयोमर्यादा.</b> कायदेशीर
            आवश्यकतांमुळे वेबसाइट आणि अॅप वापरण्यासाठी तुमचे वय किमान 16 वर्षे
            असावे. कोणत्याही युरोपियन युनियन रहिवाशांसाठी ही वेबसाइट नाही.
            तुम्ही किंवा तुम्हाला माहीत असलेला दुसरा वापरकर्ता युरोपियन युनियनचा
            रहिवासी असल्यास आणि प्लॅटफॉर्म वापरत असल्यास, कृपया आमच्याशी{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            येथे संपर्क साधा आणि आम्ही अशी माहिती हटवण्यासाठी आणि (किंवा)
            त्याचे/तिचे खाते हटवण्यासाठी पावले उचलू.
          </p>
        </li>
        <li>
          <h3>तुमचे गोपनीयतेचे अधिकार कोणते?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> काही प्रदेशांमध्ये, जसे की युरोपियन इकॉनॉमिक
              प्रदेश, तुमचे अधिकार आहेत जे तुम्हाला तुमच्या वैयक्तिक माहितीवर
              अधिक प्रवेश आणि नियंत्रण करण्याची परवानगी देतात. तुम्ही तुमच्या
              खात्याचे कधीही पुनरावलोकन करू शकता, ते बदलू शकता किंवा ते बंद करू
              शकता.
            </i>
          </p>
          <p>
            काही प्रदेशांमध्ये (जसे की युरोपियन इकॉनॉमिक प्रदेश), तुम्हाला लागू
            डेटा संरक्षण कायद्यांतर्गत काही अधिकार आहेत. यामध्ये (i) प्रवेशाची
            विनंती करण्याचा आणि आपल्या वैयक्तिक माहितीची एक प्रत प्राप्त
            करण्याचा अधिकार, (ii) दुरुस्ती किंवा पुसून टाकण्याची विनंती
            करण्याचा; (iii) आपल्या वैयक्तिक माहितीच्या प्रक्रियेस प्रतिबंधित
            करणे; आणि (iv) लागू असल्यास, डेटा पोर्टेबिलिटीचा अधिकार समाविष्ट असू
            शकतो. काही विशिष्ट परिस्थितींमध्ये, तुम्हाला तुमच्या वैयक्तिक
            माहितीच्या प्रक्रियेवर आक्षेप घेण्याचा सुद्धा अधिकार असू शकतो. अशी
            विनंती करण्यासाठी, कृपया खाली दिलेला संपर्क तपशील वापरा [HYPERLINK].
            तथापि, आम्ही लागू डेटा संरक्षण कायद्यांनुसार कोणत्याही विनंतीवर
            विचार करणे आणि त्यावर कार्यवाही करणे हा अधिकार राखून ठेवतो.
          </p>
          <p>
            आम्ही तुमच्या वैयक्तिक माहितीवर प्रक्रिया करण्यासाठी तुमच्या संमतीवर
            अवलंबून असल्यास, तुम्हाला तुमची संमती कधीही मागे घेण्याचा अधिकार
            आहे. तथापि, कृपया लक्षात ठेवा की माहिती मागे घेण्याआधी प्रक्रियेच्या
            कायदेशीरपणावर याचा परिणाम होणार नाही.
          </p>
          <p>
            तुम्ही युरोपियन इकॉनॉमिक प्रदेशातील रहिवासी असाल आणि आम्ही तुमच्या
            वैयक्तिक माहितीवर बेकायदेशीरपणे प्रक्रिया करत आहोत असे तुम्हाला वाटत
            असल्यास, तुम्हाला आमच्याशी संपर्क साधण्याची विनंती करू.
          </p>
          <ol>
            <li>
              <p>
                <i>
                  <b>खाते माहिती</b>
                </i>
              </p>
              <p>
                तुम्ही कोणत्याही वेळी तुमच्या खात्यातील माहितीचे पुनरावलोकन करू
                शकता किंवा त्यात बदल करू शकता किंवा तुमचे खाते याद्वारे बंद करू
                शकता:
              </p>
              <p>
                - आपल्या खाते सेटिंग्जमध्ये लॉग इन करणे आणि आपले खाते अद्यतनित
                करणे; किंवा
              </p>
              <p>- खाली दिलेली संपर्क माहिती वापरून आमच्याशी संपर्क साधणे</p>
              <p>
                तुमचे खाते बंद करण्याच्या तुमच्या विनंतीवर, आम्ही धोरणानुसार
                तुमचे खाते आणि माहिती आमच्या सक्रिय डेटाबेसमधून निष्क्रिय करू
                किंवा हटवू. तथापि, फसवणूक टाळण्यासाठी, समस्यांचे निवारण
                करण्यासाठी, कोणत्याही तपासात मदत करण्यासाठी, आमच्या वापराच्या
                अटींची अंमलबजावणी करण्यासाठी आणि/किंवा कायदेशीर आवश्यकतांचे पालन
                करण्यासाठी काही माहिती आमच्या फाइल्समध्ये ठेवली जाऊ शकते.
              </p>
            </li>
            <li>
              <p>
                <i>
                  <b>नियमन कायदा आणि विवाद निराकरण</b>
                </i>
              </p>
              <p>
                या धोरणांतर्गत कोणतेही विवाद असल्यास ते भारतीय कायद्यांच्या अधीन
                असतील आणि मुंबई, महाराष्ट्राच्या न्यायालयांना त्यासाठी विशेष
                अधिकार असतील.
              </p>
              <p>
                - वर नमूद केल्याप्रमाणे या धोरणाचा अर्थ लावणे, अंमलबजावणी करणे
                किंवा कथित संपुष्टात आणणे या संबंधात कोणताही विवाद किंवा फरक
                उद्भवल्यास, पक्षांनी प्रथमतः मैत्रीपूर्ण सल्लामसलत करून असे वाद
                मिटविण्याचा प्रयत्न केला पाहिजे.
              </p>
              <p>
                - चर्चा सुरू झाल्यानंतर 30 (तीस) दिवसांच्या आत किंवा पक्षांनी
                लेखी स्वरूपात सहमती दर्शविल्याप्रमाणे दीर्घ कालावधीत मैत्रीपूर्ण
                सल्लामसलत करून असा वाद सोडवला गेला नसल्यास, त्यानंतर कोणताही
                पक्ष या कराराच्या अटींनुसार लवादाद्वारे विवादाचे निराकरण
                करण्यासाठी संदर्भ देऊ शकतो.
              </p>
              <p>
                - असे सर्व विवाद मध्यस्थता आणि सामंजस्य कायदा, 1996 नुसार
                मध्यस्थाद्वारे संदर्भित केले जातील आणि शेवटी सोडवले जातील,
                वेळोवेळी आणि भारतीय लवादाच्या (&quot;ICA&quot;) नियमांनुसार
                त्यात सुधारणा किंवा पुनर्अधिनियम केले जातील. या क्लॉजमध्ये
                संदर्भाद्वारे नियमांचा समावेश केला आहे असे मानले जाते.
                न्यायाधिकरणात तीन मध्यस्थांचा समावेश असेल. प्रत्येक वादग्रस्त
                पक्ष एक मध्यस्थ नियुक्त करेल आणि आशा प्रकारे नियुक्त केलेले दोन
                मध्यस्थ एकत्रितपणे तिसरा मध्यस्थ नियुक्त करतील. लवादाची भाषा
                इंग्रजी असेल.
              </p>
              <p>- लवादाचे स्थान मुंबई, भारत असेल.</p>
              <p>
                - लवादाचा निवाडा अंतिम असेल आणि पक्षांवर बंधनकारक असेल आणि पक्ष
                त्याद्वारे बांधील राहण्यास आणि त्यानुसार कार्य करण्यास सहमत
                असतील
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>आम्ही या धोरणात अपडेट किंवा बदल करतो का?</h3>
          <p>
            <i>
              <b>संक्षिप्त:</b> होय, आम्ही संबंधित कायद्यांचे पालन करण्यासाठी
              आवश्यकतेनुसार हे धोरण अपडेट करू.
            </i>
          </p>
          <p>
            आम्ही हे गोपनीयता धोरण वेळोवेळी अद्यतनित करू शकतो. अद्ययावत आवृत्ती
            सुधारित &quot;सुधारित&quot; तारखेद्वारे दर्शविली जाईल आणि अद्ययावत
            आवृत्ती प्रवेशयोग्य झाल्यावर प्रभावी होईल.
          </p>
          <p>
            आम्ही या गोपनीयता धोरणामध्ये महत्त्वपूर्ण बदल केल्यास, अशा बदलांची
            सूचना ठळकपणे पोस्ट करून किंवा थेट तुम्हाला सूचनापत्र पाठवून आम्ही
            तुम्हाला सूचित करू शकतो. आम्ही तुमची माहिती कशी संरक्षित करत आहोत
            याची माहिती मिळण्यासाठी आम्ही तुम्हाला या गोपनीयता धोरणाचे वारंवार
            पुनरावलोकन करण्यास प्रोत्साहित करतो.
          </p>
        </li>
        <li>
          <h3>या धोरणाबद्दल तुम्ही आमच्याशी कसा संपर्क करू शकता?</h3>
          <p>
            या धोरणाबद्दल तुम्हाला प्रश्न किंवा टिप्पण्या असल्यास, आम्हाला{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            वर ईमेल करा
          </p>
          <p>
            तुमच्याकडे आमच्याबद्दल किंवा आमच्या धोरणांबद्दल आणखी काही प्रश्न
            किंवा टिप्पण्या असल्यास, आम्हाला{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            यावर ई-मेल करा.
          </p>
        </li>
      </ol>
    </div>
  );
};
export default PrivacyMA;
