import BannerImage from '../bannerimage';
import Description from '../description';
import HalfWidthImageText from '../halfwidthimagetext';
import ImageWithText from '../imagewithtext';
import Logo from '../logo';
import Title from '../title';
import TitleDescription from '../titledescription';

interface Props {
  data: any;
}
const ContentComp: React.FC<Props> = (props: Props) => {
  const { data } = props;
  switch (data.unique_key) {
    case 'text_with_title':
    case 'title_with_text':
      return (
        <TitleDescription
          title={data.title}
          titleTag={data.titleTag}
          titleCustomClass={data.titleCustomClass || ''}
          description={data.description}
          descPosition={data.descPosition}
          descTag={data.descTag}
          descCustomClass={data.descCustomClass || ''}
        />
      );
    case 'title':
      return (
        <Title
          title={data.title}
          titleTag={data.titleTag}
          titleCustomClass={data.titleCustomClass || ''}
        />
      );
    case 'description':
      return (
        <Description
          description={data.description}
          descPosition={data.descPosition}
          descTag={data.descTag}
          descCustomClass={data.descCustomClass || ''}
        />
      );
    case 'image_with_text':
    case 'image_with_title_description':
    case 'video_and_image_with_title_desc':
      return (
        <ImageWithText
          imagePath={data.imagePath}
          imageClass={data.imageClass}
          title={data.title}
          titleTag={data.titleTag}
          titleCustomClass={data.titleCustomClass || ''}
          description={data.description}
          descPosition={data.descPosition}
          descTag={data.descTag}
          descCustomClass={data.descCustomClass || ''}
          displayType={data.displayType}
          titleOnTop={data.titleOnTop}
          tagText={data.tagText}
          tagBgColor={data.tagBgColor}
        />
      );
    case 'bannerimg':
      return <BannerImage images={data.images} imageClass={data.imageClass} />;
    case 'logo':
      return <Logo url={data.imagePath} />;
    case 'half_width_image_with_text':
      return (
        <HalfWidthImageText
          imagePath={data.imagePath}
          imageClass={data.imageClass}
          title={data.title}
          titleTag={data.titleTag}
          titleCustomClass={data.titleCustomClass || ''}
          description={data.description}
          descTag={data.descTag}
          descCustomClass={data.descCustomClass || ''}
        />
      );
    default:
      return null;
      break;
  }
};

export default ContentComp;
