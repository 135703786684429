const ImprintEN: React.FC = () => {
  return (
    <div>
      <h1>Imprint</h1>
      <h2>Published by:</h2>
      <p>
        Covestro (India) Private Limited
        <br />
        Unit No. – SB 801, 8 th Floor,
        <br />
        Empire Tower, Cloud Campus,
        <br />
        Thane – Belapur Road, Airoli - 400708,
        <br />
        Maharashtra, India
      </p>
      <p>
        Represented by the Board of Directors:
        <br />
        Anand Srinivasan, Avinash Bagdi
      </p>
      <p>
        Corporate Identification Number (CIN): U19113MH1995PTC179724
        <br />
        Tax ID no. (Permanent Account Number): AAACB2419H
      </p>
      <h2>Edited by:</h2>
      <p>
        Navi Mumbai - Corporate &amp; Registered office
        <br />
        Covestro (India) Private Limited
      </p>
      <p>
        Unit No. SB-801, 8th Floor, Empire Tower, Cloud City Campus, Airoli,
        Thane - Belapur Rd, Navi Mumbai – 400 708, Maharashtra, India.
      </p>
      <p>
        Telephone: <a href='tel:+91 22 68195555'>+91 22 68195555</a>
        <br />
        Email :{' '}
        <a href='mailto:foodsecurity.platform@covestro.com'>
          foodsecurity.platform@covestro.com
        </a>
      </p>
    </div>
  );
};
export default ImprintEN;
