/* eslint-disable react/no-children-prop */
import './style.scss';
import {
  IonAccordionGroup,
  IonAccordion,
  IonPage,
  IonImg,
  IonIcon,
  IonTextarea,
  IonLabel,
} from '@ionic/react';
import Layout from '../../components/layouts';
import faqBanner from '../../assets/images/banner-aboutib.jpg';
import accArrow from '../../assets/images/icons/acc-arrow.svg';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContactUsSchema } from '../../schemas/auth.schema';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import { Form, Formik } from 'formik';

import InputText from '../../components/inputtext';
import ButtonComponent from '../../components/button-component';
import ValidationMessage from '../../components/validationMessage';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import DropDown from '../../components/dropdown';
import { contactUs } from '../../services/user.service';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
const Pagefaq: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const [categories, setCategories] = useState([]);

  const [selectedStakeCategory] = useState('Select');
  const { userDetails }: any = useContext(AppContext);
  const _categories: any = [
    { id: 0, value: 'select', name: 'Select' },
    { id: 1, value: 'farmer', name: 'Farmer' },
    { id: 2, value: 'non_farmer', name: 'Non Farmer' },
  ];
  const initialValues = {
    name:
      userDetails && userDetails.firstname
        ? `${userDetails.firstname} ${userDetails.lastname}`
        : '',
    phone_number:
      userDetails && userDetails.phone_number ? userDetails.phone_number : '',
    email: userDetails && userDetails.email ? userDetails.email : '',
    message: '',
    category: '',
  };
  useEffect(() => {
    setLoading(true);
    setCategories(_categories);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent('faq');
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    actions.setFieldError(undefined);
    const postData: any = {
      name: values.name,
      email: values.email,
      phone_number:
        values.phone_number.length == 10
          ? '+91' + values.phone_number
          : values.phone_number,
      message: values.message,
      category: values.category,
      user: userDetails && userDetails.id ? userDetails.id : null,
    };
    setLoading(true);
    const contactRes: any = await contactUs(postData);
    setLoading(false);
    if (contactRes == true) {
      console.log('contactRes ', contactRes);
      toastMessage(Messages.CONTACT_CREATE_MSG);
      window.location.reload();
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const ContactSchemaMemo: any = useMemo(
    () => ContactUsSchema(),
    [i18n.locale]
  );
  return (
    <IonPage className='page-faq'>
      <Layout>
        <div className='rect-img page-banner '>
          <IonImg src={faqBanner}></IonImg>
        </div>
        <div className='section content-sec' style={{ background: 'white' }}>
          <div className='container container--small'>
            <h1 className='content-heading'>
              {t({ id: 'faq', message: 'FAQs' })}
            </h1>

            {loading && !entry ? (
              <div></div>
            ) : !entry ? (
              <div></div>
            ) : (
              <IonAccordionGroup>
                {entry.question_and_answer &&
                entry.question_and_answer.length ? (
                  <>
                    {entry.question_and_answer.map((item: any) => {
                      return (
                        <IonAccordion key={item.id} class='acc' value={item.id}>
                          <div className='acc__heading' slot='header'>
                            <span className='acc__heading__text'>
                              {item.title.title}
                            </span>{' '}
                            <span className='acc__heading__arrow'>
                              <IonIcon
                                className='ion-accordion-toggle-icon md'
                                src={accArrow}
                              />
                            </span>
                          </div>
                          <div className='acc__copy' slot='content'>
                            <ReactMarkdown
                              className='faq-desc'
                              children={item.description.description}
                              remarkPlugins={[remarkGfm, remarkBreaks]}
                            />
                          </div>
                        </IonAccordion>
                      );
                    })}
                  </>
                ) : null}
              </IonAccordionGroup>
            )}
          </div>
        </div>
        <div className='section content-sec' style={{ background: 'white' }}>
          <div className='container container--small'>
            <h1 className='content-heading'>
              {' '}
              {t({ id: 'contact_us', message: 'CONTACT US' })}
            </h1>
            <Formik
              enableReinitialize={false}
              initialValues={initialValues}
              validationSchema={ContactSchemaMemo}
              onSubmit={handleSubmit}
              key={'contactus'}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                setFieldTouched,
                isSubmitting,
              }) => (
                <>
                  <Form className='contact-form'>
                    <div className='contact-wrapper'>
                      <div className='input-text-container mb-34'>
                        <Trans
                          id='name'
                          render={({ translation }) => (
                            <InputText
                              labelName='Name'
                              labelKey='name'
                              placeholder={'' + translation}
                              required
                              value={values.name}
                              onChange={(value: any) => {
                                setFieldValue('name', value);
                                setFieldTouched('name', true);
                              }}
                              error={Boolean(errors.name && touched.name)}
                              type='text'
                            ></InputText>
                          )}
                        >
                          Enter Name
                        </Trans>
                        <ValidationMessage name={'name'} />
                      </div>
                      <div className='input-text-container mb-34'>
                        <Trans
                          id='phone_number'
                          render={({ translation }) => (
                            <InputText
                              labelName='Phone number'
                              labelKey='phone_number'
                              placeholder={'' + translation}
                              required
                              value={values.phone_number}
                              onChange={(value: any) => {
                                setFieldValue('phone_number', value);
                                setFieldTouched('phone_number', true);
                              }}
                              error={Boolean(
                                errors.phone_number && touched.phone_number
                              )}
                              type='text'
                            ></InputText>
                          )}
                        >
                          Enter Phone number
                        </Trans>
                        <ValidationMessage name={'phone_number'} />
                      </div>
                      <div className='input-text-container mb-34'>
                        <Trans
                          id='category'
                          render={() => (
                            <DropDown
                              required
                              name='stakeholderCategory'
                              labelName='Are you a Farmer / Others'
                              labelKey='are_you_farmer'
                              optionList={categories}
                              isLocalTranslate
                              onChange={(item: any) => {
                                setFieldValue('category', item.value);
                                setFieldTouched('category', true);
                              }}
                              selectedItem={selectedStakeCategory}
                            />
                          )}
                        >
                          Are you a Farmer / Others
                        </Trans>
                        <ValidationMessage name={'category'} />
                      </div>
                      <div className='input-text-container mb-34'>
                        <Trans
                          id='email'
                          render={({ translation }) => (
                            <InputText
                              labelName='Email'
                              labelKey='email'
                              placeholder={'' + translation}
                              value={values.email}
                              required
                              onChange={(value: any) => {
                                setFieldValue('email', value);
                                setFieldTouched('email', true);
                              }}
                              error={Boolean(errors.email && touched.email)}
                            ></InputText>
                          )}
                        >
                          Enter Email
                        </Trans>
                        <ValidationMessage name={'email'} />
                      </div>
                      <div className='mb-34 message_con'>
                        <Trans
                          id='message'
                          render={() => (
                            <>
                              <IonLabel
                                className={`ion-label font-aktiv-grotest-regular`}
                              >
                                {t({ id: 'message', message: 'Message' })}
                                {<span className='mandatory'>*</span>}
                              </IonLabel>
                              <IonTextarea
                                className='dashboard-comment-text mt-30'
                                placeholder={t({
                                  id: 'message',
                                  message: `Message`,
                                })}
                                rows={3}
                                cols={1}
                                autoGrow
                                value={values.message}
                                onIonChange={(e: any) => {
                                  setFieldValue('message', e.detail.value);
                                  setFieldTouched('message', true);
                                }}
                              ></IonTextarea>
                              <ValidationMessage name={'message'} />
                            </>
                          )}
                        >
                          {`What's on your mind`}
                        </Trans>
                      </div>
                      <div className='mb-34'>
                        <ButtonComponent
                          name={'Submit'}
                          customClass='contact-btn-submit mb-16'
                          textKey='submit'
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default Pagefaq;
