import {
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonModal,
  IonPage,
  IonPopover,
  IonTextarea,
  useIonViewWillEnter,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router';
import Layout from '../../components/layouts';
import { App } from '@capacitor/app';
import {
  APP_DOMAIN,
  FIRST_LOGIN,
  INIT_PAGE,
  MAX_FILE_SIZE,
} from '../../config/appconfig';
import { AppContext } from '../../context/app.context';
import ButtonComponent from '../../components/button-component';
import ProfileImage from '../../components/profileimg';
import { t, Trans } from '@lingui/macro';
import PostCard from '../../components/postcard';
import NewsAndAnnouncement from '../../components/newsannouncements';
import Notification from '../../components/notifications';
import {
  getFormattedUrl,
  getLanguageText,
  getUserInitial,
  isWeb,
  openExternalUrl,
} from '../../utils/common.helper';
import {
  getLikePostCount,
  getPosts,
  likePost,
  submitPost,
  submitPostComment,
  submitRespond,
} from '../../services/post.service';
import Messages from '../../config/messages';
import Requirements from '../requirements';
import Sellrequirements from '../sellrequirements';
import ProductCard from '../../components/productcard';
import { addInterest } from '../../services/interest.service';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links';
import IconCamera from '../../assets/images/icons/icon_camera.svg';
import IconVideo from '../../assets/images/icons/icon_video.svg';
import {
  chooseFromGallery,
  chooseFromGallery2,
  imageCapture,
  videoRecorder,
} from '../../utils/media.helper';

import IonModalBack from '../../assets/images/icons/arrow-small-right.png';
import { Capacitor } from '@capacitor/core';
import Cropper from 'react-cropper';
import { uploadFile, uploadFileWeb } from '../../services/filetransfer.service';
import { CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture';
import Loader from '../../components/loader';
import VideoPlayer from '../../components/videoplayer';
import { getContent } from '../../services/about.service';
import Landing from '../landing';
import EmptyData from '../../components/emptydata';
import { getSliderContent } from '../../services/content.service';
import ContentSlider from '../../components/contentslider';
import { nofiticationInit } from '../../services/notification.service';
import {
  getNotificationList,
  userLoginCount,
} from '../../services/user.service';
import moment from 'moment';
import storageService from '../../services/storage.service';

let isOptionVisible = false;
let isCropVisible = false;
let isSubmitVisible = false;
const Home: React.FC = () => {
  const history = useHistory();
  const {
    isLoggedIn,
    setLoading,
    toastMessage,
    loading,
    userDetails,
    lang,
    isInActive,
  }: any = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [postText, setPostText] = useState('');
  const [imagePostText, setImagePostText] = useState('');
  const [videoPostText, setVideoPostText] = useState('');

  const [isPostSubmitting, setIsPostSubmitting] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isPaginationLoad, setIsPaginationLoad] = useState(false);
  const [lastPostRecieved, setLastPostRecieved] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [pageMeta, setPageMeta]: any = useState({
    currentPage: 0,
  });
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popoverSubmitState, setShowSubmitPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [showCameraOption, setShowCameraOption] = useState(false);
  const [showVideoOption, setShowVideoOption] = useState(false);
  const [imagePath, setImagePath]: any = useState(null);
  const fileInput = useRef(null);
  const fileInputVideo = useRef(null);

  const [showError, setShowError]: any = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [croppedImage, setCroppedImage]: any = useState(null);
  const [isVideoCompressing, setIsVideoCompressing]: any = useState(false);
  const [videoFileUri, setVideoFileUri]: any = useState('');
  const [thumbUri, setThumbUri]: any = useState(null);
  const [entry, setEntry]: any = useState(null);
  const [content, setContent]: any = useState(null);
  const [newsList, setNewsList]: any = useState(null);
  const [notificationList, setNotificationList]: any = useState([]);
  useEffect(() => {
    document.addEventListener('ionBackButton', handleBack);
    onPendingLink();
    return () => {
      document.removeEventListener('ionBackButton', handleBack);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (isLoggedIn == 'true') {
      !isWeb() && nofiticationInit();
      if (isInActive == 'true') {
        setLoading(false);
        history.push('/account-inactive');
      } else {
        Promise.all([
          fetchPostPagination(true, null),
          getContentForSlider(),
          getNewsList(),
          getNotifications(),
          loginCount(),
        ]).finally(() => {
          setLoading(false);
        });
      }
    } else {
      Promise.all([fetchLandingContent()]).finally(() => {
        setLoading(false);
      });
    }
  }, [isLoggedIn, isInActive]);

  useIonViewWillEnter(() => {
    if (isLoggedIn == 'true' && isInActive == 'true') {
      history.push('/account-inactive');
    }
  }, []);

  const onPendingLink = async () => {
    FirebaseDynamicLinks.getDynamicLink().then((data) => {
      if (!data.deepLink) return;
      const route = data.deepLink.split(APP_DOMAIN).pop();
      history.push(`/${route}`);
    });
  };

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isOptionVisible) {
        isOptionVisible = false;
        setShowCameraOption(false);
        setShowVideoOption(false);
        // document.removeEventListener('ionBackButton', handleBack);
      } else if (isCropVisible) {
        isCropVisible = false;
        setShowPopover({
          showPopover: false,
          event: undefined,
        });
        setImagePath(null);
      } else if (isSubmitVisible) {
        isSubmitVisible = false;
        setShowSubmitPopover({
          showPopover: false,
          event: undefined,
        });
        setCroppedImage(null);
        setVideoFileUri('');
        setThumbUri(null);
      } else if (
        window.location.pathname == '/' ||
        window.location.pathname == INIT_PAGE
      ) {
        App.exitApp();
      } else {
        history.goBack();
      }
    });
  };

  const fetchLandingContent = async () => {
    const resp = await getContent('landing');

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const getNewsList = async () => {
    const resp = await getContent('news');
    if (!resp.error && resp.entry) {
      setNewsList(JSON.parse(resp.entry));
    }
  };

  const getNotifications = async () => {
    const resp = await getNotificationList(2);
    if (!resp.error) {
      setNotificationList(resp);
    }
  };

  const getContentForSlider = async () => {
    const resp = await getSliderContent();
    if (!resp.error) {
      setContent(resp);
    }
  };

  const fetchPostPagination = async (isFirstLoad: boolean, event: any) => {
    if (isLoggedIn == 'false') return;
    if ((!isFirstLoad && lastPostRecieved) || isPaginationLoad) return;
    setIsPaginationLoad(true);
    const resp = await getPosts(`page=${pageMeta.currentPage + 1}`);
    setIsPaginationLoad(false);
    if (!resp.error) {
      if (event) {
        event.target.complete();
      }
      if (event) {
        event.target.disabled = resp.length == 0;
      }
      setPosts(posts.concat(resp.items));
      setPageMeta(resp.meta);
      setLastPostRecieved(resp.meta.currentPage >= resp.meta.totalPages);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onTabClick = (index: number) => {
    setSelectedTab(index);
  };

  const onTextPostSubmit = async () => {
    if (isPostSubmitting || postText.trim().length == 0) return;
    setLoading(true);
    setIsPostSubmitting(true);
    const resp = await submitPost({
      post_type: 'general',
      description: postText,
    });

    setLoading(false);
    setIsPostSubmitting(false);
    if (!resp.error) {
      setPostText('');
      history.push(`/submittedsuccessfully/${resp.id}`);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onPostCommentSubmit = async (req: any) => {
    const resp = await submitPostComment(req);
    if (!resp.error) {
      toastMessage(Messages.COMMENT_ADDED_MSG);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onRespondSubmit = async (req: any) => {
    const resp = await submitRespond(req);
    if (!resp.error) {
      toastMessage(Messages.RESPOND_ADDED_MSG);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onPostLike = async (req: any) => {
    if (isLikeLoading) return;
    setIsLikeLoading(true);
    const resp = await likePost(req);
    if (!resp.error) {
      const postObj: any = posts.find((item: any) => {
        return item.id == req.postId && item.post_type != 'product';
      });
      if (postObj) {
        postObj.isLiked = req.liked;
        // if (postObj.likes_count) {
        //   if (req.liked) {
        //     postObj.likes_count = postObj.likes_count + 1;
        //   } else {
        //     postObj.likes_count = Math.max(0, postObj.likes_count - 1);
        //   }
        // } else {
        //   if (req.liked) {
        //     postObj.likes_count = 1;
        //   } else {
        //     postObj.likes_count = 0;
        //   }
        // }
        const countRes = await getLikePostCount(req.postId);
        if (!countRes.error) {
          postObj.likes_count = Math.max(0, countRes);
        }
        // setRefresh(!refresh);
      }
      setIsLikeLoading(false);
      // toastMessage(Messages.COMMENT_ADDED_MSG);
      return resp;
    } else {
      setIsLikeLoading(false);
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onPostInterest = async (req: any) => {
    const resp = await addInterest(req);
    if (!resp.error) {
      const postObj: any = posts.find((item: any) => {
        return item.id == req.productId && item.post_type == 'product';
      });

      if (postObj) {
        postObj.user_interested = true;
        postObj.attended = resp.attended;
        setRefresh(!refresh);
      }
      // toastMessage(Messages.COMMENT_ADDED_MSG);
      return true;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onImagePostSubmit = async () => {
    if (isPostSubmitting) return;
    setIsPostSubmitting(true);
    const profileRes: any = await uploadFileWeb(croppedImage);
    if (!profileRes.error) {
      const resp = await submitPost({
        post_type: 'general',
        image_id: profileRes.id,
        description: imagePostText,
      });
      if (!resp.error) {
        setIsPostSubmitting(false);
        setShowSubmitPopover({
          showPopover: false,
          event: undefined,
        });
        setCroppedImage(null);
        isSubmitVisible = false;
        setImagePostText('');
        history.push(`/submittedsuccessfully/${resp.id}`);
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      setIsPostSubmitting(false);
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onVideoPostSubmit = async () => {
    if (isPostSubmitting) return;
    setIsPostSubmitting(true);

    const videoRes: any = await uploadFile(
      'file://' + videoFileUri,
      'video/mp4',
      ''
    );
    const thumbRes: any = await uploadFile(
      'file://' + thumbUri,
      'image/jpeg',
      ''
    );

    if (!videoRes.error && !thumbRes.error) {
      const resp = await submitPost({
        post_type: 'general',
        image_id: JSON.parse(thumbRes).id,
        video_id: JSON.parse(videoRes).id,
        description: videoPostText,
      });
      if (!resp.error) {
        setIsPostSubmitting(false);
        setShowSubmitPopover({
          showPopover: false,
          event: undefined,
        });
        setVideoFileUri('');
        setThumbUri(null);
        isSubmitVisible = false;
        setVideoPostText('');
        history.push(`/submittedsuccessfully/${resp.id}`);
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      setIsPostSubmitting(false);
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onWebVideoPostSubmit = async () => {
    if (isPostSubmitting) return;
    setIsPostSubmitting(true);

    const videoRes = await uploadFileWeb(videoFileUri);
    const thumbRes = await uploadFileWeb(thumbUri);

    if (!videoRes.error && !thumbRes.error) {
      const resp = await submitPost({
        post_type: 'general',
        image_id: thumbRes.id,
        video_id: videoRes.id,
        description: videoPostText,
      });
      if (!resp.error) {
        setIsPostSubmitting(false);
        setShowSubmitPopover({
          showPopover: false,
          event: undefined,
        });
        setVideoFileUri('');
        setThumbUri(null);
        isSubmitVisible = false;
        setVideoPostText('');
        history.push(`/submittedsuccessfully/${resp.id}`);
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      setIsPostSubmitting(false);
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const loginCount = async () => {
    let callAPI = true;

    const variableRes: any = await storageService.get(FIRST_LOGIN);
    if (variableRes) {
      if (!moment('DD-MM-YYYY').isAfter(moment(variableRes, 'DD-MM-YYYY'))) {
        callAPI = false;
      }
    }

    if (callAPI) {
      await userLoginCount();
      await storageService.set(FIRST_LOGIN, moment().format('DD-MM-YYYY'));
    }
  };

  return (
    <IonPage className={isLoggedIn != 'true' ? 'page-landing' : ''}>
      <Layout>
        {isLoggedIn == 'true' ? (
          <div className='user-dashboard-container page-container'>
            <div className='dashboard-main-container mr-24'>
              <>
                <div className='dashboard-main-top-container p-16 mb-24'>
                  <div className='dashboard-select-page-wrapper mb-10'>
                    <ButtonComponent
                      name={'Post'}
                      textKey={'post'}
                      outline
                      customClass={`dashboard-select-btn-text font-aktiv-grotest-medium pb-7 pl-16 pr-16 ${
                        selectedTab == 1 ? 'selected' : ''
                      }`}
                      onClick={() => {
                        onTabClick(1);
                      }}
                    />
                    <ButtonComponent
                      name={'Demand'}
                      textKey={'buy'}
                      outline
                      customClass={`dashboard-select-btn-text font-aktiv-grotest-medium pb-7 pl-16 pr-16 ${
                        selectedTab == 2 ? 'selected' : ''
                      }`}
                      onClick={() => {
                        onTabClick(2);
                      }}
                    />
                    <ButtonComponent
                      name={'Supply'}
                      textKey={'sell'}
                      outline
                      customClass={`dashboard-select-btn-text font-aktiv-grotest-medium pb-7 pl-16 pr-16 ${
                        selectedTab == 3 ? 'selected' : ''
                      }`}
                      onClick={() => {
                        onTabClick(3);
                      }}
                    />
                  </div>
                  {selectedTab == 1 ? (
                    <>
                      <div className='dashboard-comments-section'>
                        <ProfileImage
                          userInitial={getUserInitial(userDetails.firstname)}
                          customClass='dashboard-post-profile-image mr-10'
                          imgPath={
                            userDetails.profile_pic_obj
                              ? getFormattedUrl(userDetails.profile_pic_obj.uri)
                              : undefined
                          }
                        />
                        <Trans
                          id='what_on_your_mind'
                          render={() => (
                            <IonTextarea
                              className='dashboard-comment-text'
                              placeholder={t({
                                id: 'what_on_your_mind',
                                message: `What's on your mind`,
                              })}
                              rows={3}
                              cols={1}
                              autoGrow
                              value={postText}
                              onIonChange={(e: any) => {
                                setPostText(e.detail.value);
                              }}
                            ></IonTextarea>
                          )}
                        >
                          {`What's on your mind`}
                        </Trans>
                      </div>
                      <div className='post-divider mt-10'></div>
                      <div className='post-type-wrapper mt-10'>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            className='add-camera-wrapper  mr-10'
                            onClick={() => {
                              if (Capacitor.getPlatform() == 'web') {
                                // @ts-ignore
                                fileInput?.current?.click();
                              } else {
                                setShowCameraOption(true);
                                isOptionVisible = true;
                              }
                            }}
                          >
                            <IonImg
                              src={IconCamera}
                              style={{ width: 20, height: 20 }}
                              className='mr-10'
                            />
                            <div>
                              {t({ id: 'add_photo', message: 'Add Photo' })}
                            </div>
                          </div>
                          <div
                            className='add-video-wrapper '
                            onClick={() => {
                              if (Capacitor.getPlatform() == 'web') {
                                // @ts-ignore
                                fileInputVideo?.current?.click();
                              } else {
                                setShowVideoOption(true);
                                isOptionVisible = true;
                              }
                            }}
                          >
                            <IonImg
                              src={IconVideo}
                              style={{ width: 20, height: 20 }}
                              className='mr-10'
                            />
                            <div>
                              {t({ id: 'add_video', message: 'Add Video' })}
                            </div>
                          </div>
                        </div>

                        <div className='post-btn-wrapper'>
                          <ButtonComponent
                            name={'Post'}
                            textKey={'post'}
                            customClass=' post-btn'
                            disabled={isPostSubmitting}
                            onClick={() => {
                              onTextPostSubmit();
                            }}
                          />
                        </div>
                        {showError && (
                          <div className='error'>
                            {Messages.IMAGE_MAX_SIZE_MSG}
                          </div>
                        )}
                      </div>
                    </>
                  ) : selectedTab == 2 ? (
                    <>
                      <Requirements fromHome />
                    </>
                  ) : (
                    <>
                      <Sellrequirements fromHome />
                    </>
                  )}
                </div>

                {loading && posts.length == 0 ? (
                  <></>
                ) : (
                  <>
                    {/* {!isWeb() && content && <ContentSlider data={content} />} */}
                    {content && <ContentSlider data={content} />}

                    {posts.length > 0 ? (
                      <>
                        <IonList>
                          {posts.map((item: any) => {
                            let postImage = null;
                            if (
                              item.commodity_images_obj &&
                              item.commodity_images_obj.length > 0
                            ) {
                              postImage = item.commodity_images_obj[0].uri;
                            } else if (item.commodity_id?.image_pic_obj) {
                              postImage = item.commodity_id.image_pic_obj.uri;
                            }
                            return (
                              <div key={item.post_type + item.id}>
                                {item.post_type == 'general' ? (
                                  <PostCard
                                    postId={item.id}
                                    farmerName={
                                      item.user_id.firstname &&
                                      item.user_id.is_active
                                        ? `${item.user_id.firstname} ${
                                            item.user_id.lastname || ''
                                          }`
                                        : 'Anonymous'
                                    }
                                    farmerType={item.user_id.stakeholder_id}
                                    location={
                                      item.user_id.location_id.locality_name
                                    }
                                    postCardContent={item.description}
                                    isContentOnly={true}
                                    farmerProfilePic={
                                      userDetails.id == item.user_id.id
                                        ? userDetails.profile_pic_obj
                                          ? userDetails.profile_pic_obj.uri
                                          : undefined
                                        : item.user_id.profile_pic_obj
                                        ? item.user_id.profile_pic_obj.uri
                                        : undefined
                                    }
                                    commentList={item.commentList}
                                    onSubmit={async (
                                      commentText: string,
                                      postId: number
                                    ) => {
                                      return await onPostCommentSubmit({
                                        text: commentText,
                                        postId: postId,
                                        type: 'public',
                                      });
                                    }}
                                    isLiked={item.isLiked}
                                    likeCount={item.likes_count || 0}
                                    commentsCount={item.comments_count || 0}
                                    viewCount={
                                      item.viewList ? item.viewList.length : 0
                                    }
                                    onLike={async (
                                      isLiked: boolean,
                                      postId: number
                                    ) => {
                                      return await onPostLike({
                                        postId: postId,
                                        liked: isLiked,
                                      });
                                    }}
                                    postTime={item.created_at}
                                    updateComment={(
                                      commentList: any,
                                      postId: any
                                    ) => {
                                      const postObj: any = posts.find(
                                        (item: any) => {
                                          return (
                                            item.id == postId &&
                                            item.post_type != 'product'
                                          );
                                        }
                                      );
                                      if (postObj) {
                                        postObj.commentList = commentList;
                                        setRefresh(!refresh);
                                      }
                                    }}
                                    isActive={item.is_active}
                                    postStatus={item.status}
                                    postType={item.post_type}
                                    imgPath={
                                      item.image_pic_obj
                                        ? item.image_pic_obj.uri
                                        : null
                                    }
                                    videoPath={
                                      item.video_obj ? item.video_obj.uri : null
                                    }
                                    showComments={item.user_id.is_active}
                                    showInteraction={item.user_id.is_active}
                                  />
                                ) : item.post_type == 'product' ? (
                                  <ProductCard
                                    isClickable
                                    showReaction
                                    showDisc
                                    imgPath={
                                      item.image_pic_obj
                                        ? item.image_pic_obj.uri
                                        : null
                                    }
                                    videoPath={
                                      item.video_obj ? item.video_obj.uri : null
                                    }
                                    product={item}
                                    isFavourite={item.user_interested}
                                    isAttended={item.attended}
                                    onAddInterest={async (
                                      productId: number
                                    ) => {
                                      return await onPostInterest({
                                        productId,
                                      });
                                    }}
                                  />
                                ) : (
                                  <PostCard
                                    postUserId={item.user_id.id}
                                    postId={item.id}
                                    farmerName={
                                      item.user_id.firstname &&
                                      item.user_id.is_active
                                        ? `${item.user_id.firstname} ${
                                            item.user_id.lastname || ''
                                          }`
                                        : 'Anonymous'
                                    }
                                    farmerType={item.user_id.stakeholder_id}
                                    location={
                                      item.user_id.location_id.locality_name
                                    }
                                    productName={item.commodity_id}
                                    productCategory={item.category_id}
                                    productVarietyBreed={item.variety_breed}
                                    productQuantity={item.quantity}
                                    productUnit={getLanguageText(
                                      item.weightunit_id.unit_name,
                                      'name',
                                      item.weightunit_id.locale,
                                      lang
                                    )}
                                    expectedPrice={item.price}
                                    logisticRequired={item.is_logistic_required}
                                    productType={item.type}
                                    buying={item.post_type == 'buy'}
                                    imgPath={postImage}
                                    showLogistic={item.post_type == 'sell'}
                                    farmerProfilePic={
                                      userDetails.id == item.user_id.id
                                        ? userDetails.profile_pic_obj
                                          ? userDetails.profile_pic_obj.uri
                                          : undefined
                                        : item.user_id.profile_pic_obj
                                        ? item.user_id.profile_pic_obj.uri
                                        : undefined
                                    }
                                    commentList={item.commentList}
                                    onSubmit={async (
                                      commentText: string,
                                      postId: number
                                    ) => {
                                      return await onPostCommentSubmit({
                                        text: commentText,
                                        postId: postId,
                                        type: 'public',
                                      });
                                    }}
                                    isLiked={item.isLiked}
                                    likeCount={item.likes_count || 0}
                                    commentsCount={item.comments_count || 0}
                                    viewCount={
                                      item.viewList ? item.viewList.length : 0
                                    }
                                    onLike={async (
                                      isLiked: boolean,
                                      postId: number
                                    ) => {
                                      return await onPostLike({
                                        postId: postId,
                                        liked: isLiked,
                                      });
                                    }}
                                    postTime={item.created_at}
                                    updateComment={(
                                      commentList: any,
                                      postId: any
                                    ) => {
                                      const postObj: any = posts.find(
                                        (item: any) => {
                                          return (
                                            item.id == postId &&
                                            item.post_type != 'product'
                                          );
                                        }
                                      );
                                      if (postObj) {
                                        postObj.commentList = commentList;
                                        setRefresh(!refresh);
                                      }
                                    }}
                                    isActive={item.is_active}
                                    postStatus={item.status}
                                    postType={item.post_type}
                                    showRespondBtn={
                                      item.user_id.is_active &&
                                      userDetails.id != item.user_id.id
                                    }
                                    onRespondSubmit={async (
                                      commentText: string,
                                      postId: number,
                                      toUserId: number
                                    ) => {
                                      return await onRespondSubmit({
                                        text: commentText,
                                        postId: postId,
                                        toUserId,
                                        type: 'private',
                                      });
                                    }}
                                    extended={item.extended}
                                    extendedAt={item.expiring_at}
                                    showInteraction={item.user_id.is_active}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </IonList>
                        <IonInfiniteScroll
                          threshold='100px'
                          onIonInfinite={(event) => {
                            fetchPostPagination(false, event);
                          }}
                          disabled={lastPostRecieved}
                        >
                          <IonInfiniteScrollContent
                            loadingSpinner='circles'
                            loadingText='Loading more data...'
                          ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                      </>
                    ) : !loading && posts.length == 0 ? (
                      <div>
                        <EmptyData />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            </div>
            {isWeb() && (
              <div className='dashboard-notification-news-container'>
                {notificationList && notificationList.length > 0 && (
                  <div className='notification-wrapper mb-24 p-8'>
                    <div className='notification-news-title font-aktiv-grotest-medium pb-10'>
                      {t({
                        id: 'notifications',
                        message: `Notifications`,
                      })}
                    </div>
                    {notificationList.map((item: any) => {
                      const metaData = JSON.parse(item.metaData);
                      return (
                        <Notification
                          key={item.id}
                          message={item.title.replace('${user.fullname}', '')}
                          cutomerName={
                            metaData
                              ? (metaData.type != 'APPROVED' ||
                                  metaData.type != 'REJECTED') &&
                                metaData.user
                                ? metaData.user.fullname
                                : null
                              : null
                          }
                          profilePic={
                            metaData
                              ? (metaData.type != 'APPROVED' ||
                                  metaData.type != 'REJECTED') &&
                                metaData.user &&
                                metaData.user.profile_pic_obj
                                ? metaData.user.profile_pic_obj.uri
                                : undefined
                              : undefined
                          }
                          notificationTime={item.created_at}
                          metaData={metaData}
                        />
                      );
                    })}

                    <div
                      className='load-more-btn font-aktiv-grotest-medium mt-12'
                      onClick={() => {
                        history.push('/notifications');
                      }}
                    >
                      {t({
                        id: 'load_more',
                        message: 'Load more',
                      })}
                    </div>
                  </div>
                )}
                {newsList &&
                  newsList.news_list &&
                  newsList.news_list.length > 0 && (
                    <div className='news-wrapper p-8'>
                      <div className='notification-news-title font-aktiv-grotest-medium pb-10'>
                        {t({
                          id: 'news_and_announcements',
                          message: 'News and Announcements',
                        })}
                      </div>
                      {newsList.news_list
                        .sort((a: any, b: any) => b.id - a.id)
                        .slice(newsList.news_list.length > 5 ? 5 : 0)
                        .map((item: any) => {
                          return (
                            <div
                              key={item.id}
                              className='cursor'
                              onClick={() => {
                                if (item.external_link) {
                                  openExternalUrl(item.external_link);
                                }
                              }}
                            >
                              <NewsAndAnnouncement
                                message={item.description?.description}
                              />
                            </div>
                          );
                        })}
                      <div
                        className='load-more-btn font-aktiv-grotest-medium mt-12'
                        onClick={() => {
                          history.push('/news');
                        }}
                      >
                        {t({
                          id: 'load_more',
                          message: 'Load more',
                        })}
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        ) : loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <Landing data={entry} />
        )}

        <IonPopover
          event={popoverSubmitState.event}
          isOpen={popoverSubmitState.showPopover}
          onDidDismiss={() => {
            setCroppedImage(null);
            setVideoFileUri('');
            setThumbUri(null);
            setShowSubmitPopover({ showPopover: false, event: undefined });
            isSubmitVisible = false;
          }}
          className='img-popover modal-style'
          // backdropDismiss={false}
        >
          <div
            className='img-popover-inner'
            style={{ backgroundColor: 'white' }}
          >
            {isVideoCompressing ? (
              <Loader />
            ) : videoFileUri ? (
              <div>
                <VideoPlayer
                  url={
                    isWeb()
                      ? URL.createObjectURL(videoFileUri)
                      : Capacitor.convertFileSrc('file://' + videoFileUri)
                  }
                  isLocal
                  thumbnail={
                    isWeb()
                      ? thumbUri
                      : Capacitor.convertFileSrc('file://' + thumbUri)
                  }
                />
                <Trans
                  id='what_on_your_mind'
                  render={() => (
                    <IonTextarea
                      className='dashboard-comment-text mt-30'
                      placeholder={t({
                        id: 'what_on_your_mind',
                        message: `What's on your mind`,
                      })}
                      rows={3}
                      cols={1}
                      autoGrow
                      value={videoPostText}
                      onIonChange={(e: any) => {
                        setVideoPostText(e.detail.value);
                      }}
                    ></IonTextarea>
                  )}
                >
                  {`What's on your mind`}
                </Trans>
              </div>
            ) : (
              <>
                <IonImg
                  className='modal-style__img'
                  src={croppedImage}
                ></IonImg>
                <Trans
                  id='what_on_your_mind'
                  render={() => (
                    <IonTextarea
                      className='dashboard-comment-text'
                      placeholder={t({
                        id: 'what_on_your_mind',
                        message: `What's on your mind`,
                      })}
                      rows={3}
                      cols={1}
                      autoGrow
                      value={imagePostText}
                      onIonChange={(e: any) => {
                        setImagePostText(e.detail.value);
                      }}
                    ></IonTextarea>
                  )}
                >
                  {`What's on your mind`}
                </Trans>
              </>
            )}
            {isPostSubmitting ? (
              <Loader />
            ) : (
              <div className='submit-btn-wrapper'>
                <ButtonComponent
                  name={'Submit'}
                  textKey={'submit'}
                  customClass='popup-btn'
                  disabled={isVideoCompressing || isPostSubmitting}
                  onClick={() => {
                    if (croppedImage) {
                      onImagePostSubmit();
                    } else if (videoFileUri) {
                      if (isWeb()) {
                        onWebVideoPostSubmit();
                      } else {
                        onVideoPostSubmit();
                      }
                    }
                  }}
                />
                <ButtonComponent
                  name='Cancel'
                  textKey={'cancel'}
                  disabled={isVideoCompressing || isPostSubmitting}
                  customClass='popup-btn'
                  onClick={async () => {
                    isSubmitVisible = false;
                    setShowSubmitPopover({
                      showPopover: false,
                      event: undefined,
                    });
                    setCroppedImage(null);
                    setVideoFileUri('');
                    setThumbUri(null);
                    setImagePostText('');
                    setVideoPostText('');
                  }}
                />
              </div>
            )}
          </div>
        </IonPopover>

        <IonPopover
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
            isCropVisible = false;
          }}
          className='home-popover'
          backdropDismiss={false}
        >
          {imagePath && (
            <>
              <Cropper
                src={imagePath}
                style={{ height: 500, width: '100%' }}
                // Cropper.js options
                // initialAspectRatio={16 / 9}
                guides={false}
                ref={cropperRef}
                cropBoxResizable={true}
                dragMode='none'
                viewMode={1}
                background={false}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'black',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: '33%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <ButtonComponent
                    name='Crop & Save'
                    outline
                    textKey={'crop'}
                    customClass='crop-btn ml-10'
                    onClick={async () => {
                      const imageElement: any = cropperRef?.current;
                      const cropper: any = imageElement?.cropper;
                      const temp = cropper
                        .getCroppedCanvas({ maxWidth: 1024, maxHeight: 1024 })
                        .toDataURL('image/jpeg', 0.8);
                      setCroppedImage(temp);
                      setImagePath(null);
                      setShowPopover({ showPopover: false, event: undefined });
                      setShowSubmitPopover({
                        showPopover: true,
                        event: undefined,
                      });
                      isCropVisible = false;
                      isSubmitVisible = true;
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: '33%',
                    justifyContent: 'center',
                  }}
                >
                  <ButtonComponent
                    name='Rotate'
                    outline
                    textKey={'rotate'}
                    customClass='rotate-btn'
                    onClick={async () => {
                      const imageElement: any = cropperRef?.current;
                      const cropper: any = imageElement?.cropper;
                      cropper.rotate(90);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: '33%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ButtonComponent
                    name='Cancel'
                    outline
                    textKey={'cancel'}
                    customClass='cancel-btn mr-10'
                    onClick={async () => {
                      setShowPopover({ showPopover: false, event: undefined });
                      setImagePath(null);
                      isCropVisible = false;
                      // document.removeEventListener('ionBackButton', handleBack);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </IonPopover>
        <IonModal
          isOpen={showCameraOption || showVideoOption}
          initialBreakpoint={0.25}
          breakpoints={[0, 0.25, 0.5, 1]}
          className='ion-modal'
          onDidDismiss={() => {
            setShowCameraOption(false);
            setShowVideoOption(false);
            isOptionVisible = false;
          }}
        >
          <div
            className='ion-modal-back-wrapper p-16'
            onClick={() => {
              setShowCameraOption(false);
              setShowVideoOption(false);
              isOptionVisible = false;
            }}
          >
            <IonImg
              className='ion-modal-back-img mr-10'
              src={IonModalBack}
            ></IonImg>
            <span className='ion-modal-back-txt mt-4'>
              {t({ id: 'back', message: 'Back' })}
            </span>
          </div>
          <div className='ion-modal-btn-wrapper p-16'>
            <ButtonComponent
              name={showCameraOption ? 'Take a picture' : 'Record video'}
              textKey={showCameraOption ? 'take_a_picture' : 'record_video'}
              customClass='ion-modal-btn mb-10'
              onClick={async (e: any) => {
                if (showCameraOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setLoading(true);
                  const imageData = await imageCapture();
                  setLoading(false);
                  if (imageData) {
                    setImagePath(imageData);
                    setShowPopover({ showPopover: true, event: e });
                    isCropVisible = true;
                  }
                } else if (showVideoOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setIsVideoCompressing(true);
                  setShowSubmitPopover({ showPopover: true, event: e });
                  isSubmitVisible = true;
                  const options: CaptureVideoOptions = {};
                  const videoData = await videoRecorder(options);
                  console.log('video-----', videoData);
                  if (videoData) {
                    if (videoData.compressed) {
                      setVideoFileUri(videoData.compressed);
                    }
                    if (videoData.thumbnail) {
                      setThumbUri(videoData.thumbnail);
                    }
                    setIsVideoCompressing(false);
                  } else {
                    setShowSubmitPopover({ showPopover: false, event: e });
                    isSubmitVisible = false;
                    setIsVideoCompressing(false);
                  }
                }
              }}
            />
            <ButtonComponent
              name='Choose from gallery'
              textKey={'choose_from_gallery'}
              customClass='ion-modal-btn mb-10'
              onClick={async (e: any) => {
                if (showCameraOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setLoading(true);
                  const imageData = await chooseFromGallery();
                  setLoading(false);
                  if (imageData) {
                    setImagePath(imageData);
                    setShowPopover({ showPopover: true, event: e });
                    isCropVisible = true;
                  }
                } else if (showVideoOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setIsVideoCompressing(true);
                  setShowSubmitPopover({ showPopover: true, event: e });
                  isSubmitVisible = true;
                  const videoData = await chooseFromGallery2('video/*', true);
                  console.log('video-----', videoData);
                  if (videoData) {
                    if (videoData.compressed) {
                      setVideoFileUri(videoData.compressed);
                    }
                    if (videoData.thumbnail) {
                      setThumbUri(videoData.thumbnail);
                    }
                    setIsVideoCompressing(false);
                  } else {
                    setShowSubmitPopover({ showPopover: false, event: e });
                    isSubmitVisible = false;
                    setIsVideoCompressing(false);
                  }
                }
              }}
            />
          </div>
        </IonModal>
        <input
          ref={fileInput}
          hidden
          type='file'
          accept={'image/jpeg, image/jpg, image/png'}
          onClick={(e: any) => {
            e.target.value = '';
          }}
          onChange={(e) => {
            if (e.target.files) {
              setLoading(true);
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                setLoading(false);
                console.log(e.target.files);
                if (e.target.files && e.target.files[0].size <= MAX_FILE_SIZE) {
                  setImagePath(reader.result);
                  setShowPopover({ showPopover: true, event: undefined });
                  isCropVisible = true;
                  setShowError(false);
                } else {
                  setShowError(true);
                }
              });
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
        <input
          ref={fileInputVideo}
          hidden
          type='file'
          accept={'video/mp4'}
          onClick={(e: any) => {
            e.target.value = '';
          }}
          onChange={(e) => {
            if (e.target.files) {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                console.log(e.target.files);
                if (e.target.files) {
                  setShowSubmitPopover({ showPopover: true, event: undefined });
                  isSubmitVisible = true;
                  setVideoFileUri(e.target.files[0]);
                  const video = document.createElement('video');

                  const canvas = document.createElement('canvas');
                  const context = canvas.getContext('2d');
                  video.addEventListener(
                    'loadeddata',
                    function () {
                      if (context) {
                        context.drawImage(
                          video,
                          0,
                          0,
                          canvas.width,
                          canvas.height
                        );
                        video.pause();

                        const dataURI = canvas.toDataURL('image/jpeg');
                        setThumbUri(dataURI);
                      }
                    },
                    false
                  );

                  video.preload = 'metadata';
                  video.muted = true;
                  video.playsInline = true;
                  video.setAttribute('crossOrigin', 'anonymous');
                  video.src = URL.createObjectURL(e.target.files[0]);
                  video.play();
                }
              });
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
      </Layout>
    </IonPage>
  );
};

export default Home;
