import './style.scss';
import { IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { getFormattedUrlCMS, getRouteForCMS } from '../../utils/common.helper';
import { useHistory, useParams } from 'react-router';

const PartnerCategory: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const { id }: any = useParams();
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    setEntry(null);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent(id);
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  function onNavClick(route: string) {
    history.push(getRouteForCMS(route));
  }

  return (
    <IonPage className='page-partner-category-list'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <div className='section content-sec pt-0'>
            <div className='page-banner-with-text'>
              {entry.bannerimage && entry.bannerimage.bannerimg.length > 0 && (
                <ContentComp
                  data={{
                    images: [entry.bannerimage],
                    unique_key: entry.bannerimage.unique_key,
                  }}
                />
              )}

              {entry.text_with_title && (
                <div className='container'>
                  <div className='banner-content'>
                    <div className='banner-content__inner'>
                      <ContentComp
                        data={{
                          title: entry.text_with_title.short_text.title,
                          titleTag: entry.text_with_title.short_text.tag_name,
                          titleCustomClass: 'content-heading',
                          description:
                            entry.text_with_title.long_text?.description,
                          descPosition:
                            entry.text_with_title.long_text?.position,
                          unique_key: entry.text_with_title.unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className=' section-about-grid mt-20'>
              <div className='container'>
                {entry.partner_list && entry.partner_list.length > 0 && (
                  <div className='row'>
                    {entry.partner_list.map((item: any) => {
                      const col = entry.partner_list[0].column
                        ? entry.partner_list[0].column.trim() == '2'
                          ? '3'
                          : entry.partner_list[0].column
                        : '1';

                      return (
                        <div
                          className={`col col-12 col-sm-6 col-md-${
                            12 / parseInt(col)
                          }  ${item.redirect_to ? 'cursor' : ''} `}
                          key={item.id}
                          onClick={() => {
                            if (item.redirect_to) {
                              onNavClick(item.redirect_to.model);
                            }
                          }}
                        >
                          <ContentComp
                            data={{
                              imagePath: item.images
                                ? getFormattedUrlCMS(
                                    `${item.images[0].hash}${item.images[0].ext}`
                                  )
                                : null,
                              title: item.title.title,
                              titleTag: item.title.tag_name,
                              description: item.description?.description,
                              descPosition: item.description?.position,
                              unique_key: item.unique_key,
                              displayType: item.display_type,
                              titleOnTop: item.title_on_top,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Layout>
    </IonPage>
  );
};

export default PartnerCategory;
