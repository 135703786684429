import { IonPage } from '@ionic/react';
import { useContext } from 'react';
import Layout from '../../components/layouts';
import TNCEN from '../../components/tnclang/en';
import TNCHI from '../../components/tnclang/hi';
import TNCKN from '../../components/tnclang/kn';
import TNCMA from '../../components/tnclang/ma';
import TNCTA from '../../components/tnclang/ta';
import TNCTE from '../../components/tnclang/te';
import { AppContext } from '../../context/app.context';
import './style.scss';

const TNC: React.FC = () => {
  const { lang }: any = useContext(AppContext);

  return (
    <IonPage className='page-tnc'>
      <Layout>
        <div className='content-sec container mt-20'>
          {lang == 'en' ? (
            <TNCEN />
          ) : lang == 'hi' ? (
            <TNCHI />
          ) : lang == 'mr' ? (
            <TNCMA />
          ) : lang == 'kn' ? (
            <TNCKN />
          ) : lang == 'te' ? (
            <TNCTE />
          ) : lang == 'ta' ? (
            <TNCTA />
          ) : (
            <TNCEN />
          )}
        </div>
      </Layout>
    </IonPage>
  );
};

export default TNC;
