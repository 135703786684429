import Messages from '../config/messages';
import httpService from './http.service';

export async function getCertificateList() {
  try {
    const resp: any = await httpService.execute({
      url: `certificate`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getWeightUnitList() {
  try {
    const resp: any = await httpService.execute({
      url: `weight-unit`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
