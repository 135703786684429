/* eslint-disable security/detect-non-literal-fs-filename */
import './style.scss';
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import {
  //chevronForward,
  chevronBack,
} from 'ionicons/icons';
import {
  getLanguageText,
  isWeb,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import { useHistory } from 'react-router';
import { AppContext } from '../../context/app.context';
import { APP_DOMAIN, APP_VERSION, INIT_PAGE } from '../../config/appconfig';
import { logout } from '../../services/auth.service';
import { menuController } from '@ionic/core/components';
import { App } from '@capacitor/app';
import LanguageSelect from '../languageselect';
const MenuBar: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState('');
  const {
    isLoggedIn,
    setIsLoggedIn,
    productList,
    lang,
    setUserDetails,
    setIsInActive,
  }: any = useContext(AppContext);
  const [solutionList, setSolutionList] = useState(
    productList || [
      {
        id: 1,
        title: 'Solar Conduction Dryers (SCD)',
        shortname: 'SCD',
      },
      {
        id: 2,
        title: 'Solar-powered Greenhouse Dryers (GHD)',
        shortname: 'GHD',
      },
      {
        id: 3,
        title: 'Solar Cold Storage (SCS)',
        shortname: 'SCS',
      },
      {
        id: 4,
        title: 'Solar Greenhouse Cultivation',
        shortname: 'GHC',
      },
    ]
  );
  const history = useHistory();

  useEffect(() => {
    setSolutionList(productList);
  }, [productList]);

  useEffect(() => {
    return () => {
      document.removeEventListener('ionBackButton', handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(106, async () => {
      const isOpen = await menuController.isOpen();
      if (isOpen) {
        menuController.close();
      } else if (
        window.location.pathname == '/' ||
        window.location.pathname == INIT_PAGE
      ) {
        App.exitApp();
      } else {
        history.goBack();
      }
    });
  };

  function onNavClick(route: string) {
    setTimeout(() => {
      menuController.close();
      history.push(route);
      document.removeEventListener('ionBackButton', handleBack);
      setSelectedMenu('');
    }, 1000);
  }

  return (
    <IonMenu
      side='end'
      contentId='main'
      className='menu-container'
      disabled={!isWeb() && isLoggedIn == 'false'}
      swipeGesture={false}
      onIonDidOpen={() => {
        document.addEventListener('ionBackButton', handleBack);
      }}
      onIonDidClose={() => {
        setSelectedMenu('');
        document.removeEventListener('ionBackButton', handleBack);
      }}
    >
      <IonContent className='menu-container-inner'>
        <IonList>
          {/* <IonMenuToggle auto-hide='false'> */}
          <IonItem
            button
            detail={false}
            className='remove-detail-icon'
            onClick={() => {
              onNavClick(INIT_PAGE);
            }}
          >
            <IonLabel className='font-aktiv-grotest-bold'>
              {t({ id: 'home', message: 'Home' })}
            </IonLabel>
          </IonItem>
          {/* </IonMenuToggle> */}
        </IonList>
        <IonList>
          {/* <IonMenuToggle auto-hide='false'> */}
          <IonItem
            button
            detail={false}
            className='remove-detail-icon'
            onClick={() => {
              onNavClick('/aboutcovestro');
            }}
          >
            <IonLabel className='font-aktiv-grotest-bold'>
              {t({ id: 'about_us', message: 'About Us' })}
            </IonLabel>
          </IonItem>
          {/* </IonMenuToggle> */}
        </IonList>
        {/* <IonList
          className={`${
            selectedMenu == 'about'
              ? 'submenu-item'
              : selectedMenu == ''
              ? ''
              : 'd-none'
          }`}
        >
          <IonItem
            button
            onClick={() => {
              if (selectedMenu == 'about') {
                setSelectedMenu('');
              } else {
                setSelectedMenu('about');
              }
            }}
            className='remove-detail-icon'
          >
            {selectedMenu == 'about' ? (
              <IonLabel>
                <>
                  <IonIcon
                    slot='start'
                    className='submenu-back'
                    icon={chevronBack}
                  ></IonIcon>
                  <span className='submenu-back-text'>
                    {t({ id: 'back', message: 'Back' })}
                  </span>
                </>
              </IonLabel>
            ) : (
              <>
                <IonLabel>
                  {t({ id: 'about_us', message: 'About Us' })}
                </IonLabel>
              </>
            )}
          </IonItem>
          {selectedMenu == 'about' && (
            <IonMenuToggle className='submenutoggle'>
              <IonList className='submenutoggle-wrapper'>
                <IonItem className='main-menu-item'>
                  <IonLabel className='font-aktiv-grotest-bold'>
                    {t({ id: 'about_us', message: 'About Us' })}
                  </IonLabel>
                </IonItem>
                <IonItem
                  button
                  onClick={() => {
                    onNavClick('/aboutcovestro');
                    setSelectedMenu('');
                  }}
                  className='remove-detail-icon'
                >
                  <IonLabel>
                    {t({ id: 'about_covestro', message: 'About Covestro' })}
                  </IonLabel>
                </IonItem>
                <IonItem
                  button
                  onClick={() => {
                    onNavClick('/aboutib');
                    setSelectedMenu('');
                  }}
                  className='remove-detail-icon'
                >
                  <IonLabel>
                    {t({
                      id: 'about_inclusive_business_ib',
                      message: 'About Inclusive Business (IB)',
                    })}
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonMenuToggle>
          )}
        </IonList> */}

        <IonList
          className={`${
            selectedMenu == 'food_security_solution'
              ? 'submenu-item'
              : selectedMenu == ''
              ? ''
              : 'd-none'
          }`}
        >
          <IonItem
            button
            detail={false}
            onClick={() => {
              if (selectedMenu == 'food_security_solution') {
                setSelectedMenu('');
              } else {
                setSelectedMenu('food_security_solution');
              }
            }}
            className={`remove-detail-icon ${
              selectedMenu == '' ? 'has-sub-menu' : ''
            }`}
          >
            {selectedMenu == 'food_security_solution' ? (
              <div className='selected-menu-header'>
                <IonLabel>
                  <>
                    <IonIcon
                      slot='start'
                      className='submenu-back'
                      icon={chevronBack}
                    ></IonIcon>
                    <span className='submenu-back-text'>
                      {t({ id: 'back', message: 'Back' })}
                    </span>
                  </>
                </IonLabel>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'our_solutions',
                    message: 'Our Solutions',
                  })}
                </IonLabel>
              </div>
            ) : (
              <>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'our_solutions',
                    message: 'Our Solutions',
                  })}
                </IonLabel>
                {/* <IonIcon
                    slot="end"
                    icon={chevronForward}
                  ></IonIcon> */}
              </>
            )}
          </IonItem>
          {selectedMenu == 'food_security_solution' && (
            // <IonMenuToggle className='submenutoggle'>
            <IonList className='submenutoggle-wrapper'>
              <IonItem
                button
                detail={false}
                className='main-menu-item'
                onClick={() => {
                  onNavClick(isWeb() ? '/solutions' : '/tabs/foodsecurity');
                  // setSelectedMenu('');
                }}
              >
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'our_solutions',
                    message: 'Our Solutions',
                  })}
                </IonLabel>
              </IonItem>
              {solutionList.map((item: any) => {
                return (
                  <IonItem
                    key={item.id}
                    button
                    detail={false}
                    onClick={() => {
                      onNavClick(`/solution/fs-sol-${item.shortname}`);
                      // setSelectedMenu('');
                    }}
                    className='remove-detail-icon '
                  >
                    <IonLabel>
                      {getLanguageText(
                        item.title,
                        'title',
                        item.locale || null,
                        lang
                      )}
                    </IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
            // </IonMenuToggle>
          )}
        </IonList>

        <IonList
          className={`${
            selectedMenu == 'market-place'
              ? 'submenu-item'
              : selectedMenu == ''
              ? ''
              : 'd-none'
          }`}
        >
          <IonItem
            button
            detail={false}
            onClick={() => {
              if (selectedMenu == 'market-place') {
                setSelectedMenu('');
              } else {
                setSelectedMenu('market-place');
              }
            }}
            className={`remove-detail-icon ${
              selectedMenu == '' ? 'has-sub-menu' : ''
            }`}
          >
            {selectedMenu == 'market-place' ? (
              <div className='selected-menu-header'>
                <IonLabel>
                  <>
                    <IonIcon
                      slot='start'
                      className='submenu-back'
                      icon={chevronBack}
                    ></IonIcon>
                    <span className='submenu-back-text'>
                      {t({ id: 'back', message: 'Back' })}
                    </span>
                  </>
                </IonLabel>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'market_place',
                    message: 'Information Exchange',
                  })}
                </IonLabel>
              </div>
            ) : (
              <>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'market_place',
                    message: 'Information Exchange',
                  })}
                </IonLabel>
                {/* <IonIcon
                    slot="end"
                    icon={chevronForward}
                  ></IonIcon> */}
              </>
            )}
          </IonItem>
          {selectedMenu == 'market-place' && (
            // <IonMenuToggle className='submenutoggle'>
            <IonList className='submenutoggle-wrapper'>
              <IonItem button detail={false} className='main-menu-item'>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'market_place',
                    message: 'Information Exchange',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(isWeb() ? '/buy' : '/tabs/market/demand');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>{t({ id: 'buy', message: 'Demand' })}</IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(isWeb() ? '/sell' : '/tabs/market/supply');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>{t({ id: 'sell', message: 'Supply' })}</IonLabel>
              </IonItem>
            </IonList>
            // </IonMenuToggle>
          )}
        </IonList>

        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle auto-hide='false'> */}
            <IonItem
              button
              detail={false}
              className='remove-detail-icon'
              onClick={() => {
                onNavClick('/subsidyorloans');
              }}
            >
              <IonLabel className='font-aktiv-grotest-bold'>
                {t({
                  id: 'subsidy_loan',
                  message: 'Subsidy/loan',
                })}
              </IonLabel>
            </IonItem>
            {/* </IonMenuToggle> */}
          </IonList>
        )}
        {/* <IonList
          className={`${
            selectedMenu == 'subsidy-loan'
              ? 'submenu-item'
              : selectedMenu == ''
              ? ''
              : 'd-none'
          }`}
        >
          <IonItem
            button
            onClick={() => {
              if (selectedMenu == 'subsidy-loan') {
                setSelectedMenu('');
              } else {
                setSelectedMenu('subsidy-loan');
              }
            }}
            className='remove-detail-icon'
          >
            {selectedMenu == 'subsidy-loan' ? (
              <IonLabel>
                <>
                  <IonIcon
                    slot='start'
                    className='submenu-back'
                    icon={chevronBack}
                  ></IonIcon>
                  <span className='submenu-back-text'>
                    {t({ id: 'back', message: 'Back' })}
                  </span>
                </>
              </IonLabel>
            ) : (
              <>
                <IonLabel>
                  {t({
                    id: 'subsidy_loan',
                    message: 'Subsidy/loan',
                  })}
                </IonLabel>
             
              </>
            )}
          </IonItem>
          {selectedMenu == 'subsidy-loan' && (
            <IonMenuToggle className='submenutoggle'>
              <IonList className='submenutoggle-wrapper'>
                <IonItem className='main-menu-item'>
                  <IonLabel className='font-aktiv-grotest-bold'>
                    {t({
                      id: 'subsidy_loan',
                      message: 'Subsidy/loan',
                    })}
                  </IonLabel>
                </IonItem>
                <IonItem
                  button
                  onClick={() => {
                    setSelectedMenu('');
                  }}
                  routerLink='/aboutcovestro'
                  className='remove-detail-icon'
                >
                  <IonLabel>
                    {t({ id: 'subsidy_schemes', message: 'Subsidy Schemes' })}
                  </IonLabel>
                </IonItem>
                <IonItem
                  button
                  onClick={() => {
                    setSelectedMenu('');
                  }}
                  routerLink='/aboutib'
                  className='remove-detail-icon'
                >
                  <IonLabel>
                    {t({
                      id: 'loan_or_financial_assistance',
                      message: 'Loan/Financial Assistance ',
                    })}
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonMenuToggle>
          )}
        </IonList> */}
        <IonList
          className={`${
            selectedMenu == 'our-partners'
              ? 'submenu-item'
              : selectedMenu == ''
              ? ''
              : 'd-none'
          }`}
        >
          <IonItem
            button
            detail={false}
            onClick={() => {
              if (selectedMenu == 'our-partners') {
                setSelectedMenu('');
              } else {
                setSelectedMenu('our-partners');
              }
            }}
            className={`remove-detail-icon ${
              selectedMenu == '' ? 'has-sub-menu' : ''
            }`}
          >
            {selectedMenu == 'our-partners' ? (
              <div className='selected-menu-header'>
                <IonLabel>
                  <>
                    <IonIcon
                      slot='start'
                      className='submenu-back'
                      icon={chevronBack}
                    ></IonIcon>
                    <span className='submenu-back-text'>
                      {t({ id: 'back', message: 'Back' })}
                    </span>
                  </>
                </IonLabel>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'our_partners',
                    message: 'Our Partners',
                  })}
                </IonLabel>
              </div>
            ) : (
              <>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'our_partners',
                    message: 'Our Partners',
                  })}
                </IonLabel>
                {/* <IonIcon
                    slot="end"
                    icon={chevronForward}
                  ></IonIcon> */}
              </>
            )}
          </IonItem>
          {selectedMenu == 'our-partners' && (
            // <IonMenuToggle className='submenutoggle'>
            <IonList className='submenutoggle-wrapper'>
              <IonItem
                button
                detail={false}
                className='main-menu-item'
                onClick={() => {
                  onNavClick('/partners');
                }}
              >
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'our_partners',
                    message: 'Our Partners',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(
                    `/partnerscategory/financial_institutions_landing`
                  );
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'financial_institution',
                    message: 'Financial Institutions',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(
                    `/partnerscategory/government_departments_landing`
                  );
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'government_departments_and_institutions',
                    message: 'Government departments and institutions',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(`/partnerscategory/techology_provider_landing`);
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'technology_providers',
                    message: 'Technology Providers',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(`/partnerscategory/market_providers_landing`);
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'market_linkage_providers',
                    message: 'Market Linkage Providers',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick(`/partnerscategory/ngo_landing`);
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'development_agencies_or_ngos',
                    message: 'Development Agencies/ NGOs',
                  })}
                </IonLabel>
              </IonItem>
            </IonList>
            // </IonMenuToggle>
          )}
        </IonList>
        <IonList
          className={`${
            selectedMenu == 'media'
              ? 'submenu-item'
              : selectedMenu == ''
              ? ''
              : 'd-none'
          }`}
        >
          <IonItem
            button
            detail={false}
            onClick={() => {
              if (selectedMenu == 'media') {
                setSelectedMenu('');
              } else {
                setSelectedMenu('media');
              }
            }}
            className={`remove-detail-icon ${
              selectedMenu == '' ? 'has-sub-menu' : ''
            }`}
          >
            {selectedMenu == 'media' ? (
              <div className='selected-menu-header'>
                <IonLabel>
                  <>
                    <IonIcon
                      slot='start'
                      className='submenu-back'
                      icon={chevronBack}
                    ></IonIcon>
                    <span className='submenu-back-text'>
                      {t({ id: 'back', message: 'Back' })}
                    </span>
                  </>
                </IonLabel>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'media',
                    message: 'Media',
                  })}
                </IonLabel>
              </div>
            ) : (
              <>
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'media',
                    message: 'Media',
                  })}
                </IonLabel>
                {/* <IonIcon
                    slot="end"
                    icon={chevronForward}
                  ></IonIcon> */}
              </>
            )}
          </IonItem>
          {selectedMenu == 'media' && (
            // <IonMenuToggle className='submenutoggle'>
            <IonList className='submenutoggle-wrapper'>
              <IonItem
                button
                detail={false}
                className='main-menu-item'
                onClick={() => {
                  onNavClick('/medialanding');
                }}
              >
                <IonLabel className='font-aktiv-grotest-bold'>
                  {t({
                    id: 'media',
                    message: 'Media',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick('/news');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>{t({ id: 'news', message: 'News' })}</IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick('/videolist');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>{t({ id: 'videos', message: 'Videos' })}</IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick('/successstories');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({ id: 'success_stories', message: 'Success Stories' })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick('/testimonials');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'testimonials_or_reviews',
                    message: 'Testimonials/ Reviews',
                  })}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                detail={false}
                onClick={() => {
                  onNavClick('/accreditations');
                }}
                className='remove-detail-icon'
              >
                <IonLabel>
                  {t({
                    id: 'accreditations',
                    message: 'Accreditations',
                  })}
                </IonLabel>
              </IonItem>
            </IonList>
            // </IonMenuToggle>
          )}
        </IonList>
        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle> */}
            <IonItem button detail={false} className='remove-detail-icon'>
              <IonLabel className='normal-font'>
                {t({ id: 'change_language', message: 'Change Language' })}
              </IonLabel>
              <LanguageSelect customClass='lang-custom' />
            </IonItem>
            {/* </IonMenuToggle> */}
          </IonList>
        )}
        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle> */}
            <IonItem
              button
              detail={false}
              className='remove-detail-icon'
              onClick={() => {
                onNavClick('/faq');
              }}
            >
              <IonLabel className='normal-font'>
                {t({ id: 'contact_us', message: 'Contact Us' })}
              </IonLabel>
            </IonItem>
            {/* </IonMenuToggle> */}
          </IonList>
        )}
        {isLoggedIn == 'true' && selectedMenu == '' && (
          <>
            <IonList>
              {/* <IonMenuToggle auto-hide='false'> */}
              <IonItem
                detail={false}
                button
                className='remove-detail-icon'
                onClick={() => {
                  onNavClick('/my-profile');
                }}
              >
                <IonLabel className='normal-font'>
                  {t({ id: 'my_profile', message: 'My Profile' })}
                </IonLabel>
              </IonItem>
              {/* </IonMenuToggle> */}
            </IonList>
            <IonList>
              {/* <IonMenuToggle auto-hide='false'> */}
              <IonItem
                button
                detail={false}
                className='remove-detail-icon'
                onClick={() => {
                  onNavClick('/mycorner');
                }}
              >
                <IonLabel className='normal-font'>
                  {t({ id: 'my_corner', message: 'My Corner' })}
                </IonLabel>
              </IonItem>
              {/* </IonMenuToggle> */}
            </IonList>
            <IonList>
              {/* <IonMenuToggle auto-hide='false'> */}
              <IonItem
                button
                detail={false}
                className='remove-detail-icon'
                onClick={async () => {
                  menuController.close();
                  await logout();
                  setIsLoggedIn('false');
                  setUserDetails({});
                  setIsInActive('false');
                  history.replace(isWeb() ? INIT_PAGE : '/login');
                  window.location.reload();
                  setSelectedMenu('');
                }}
              >
                <IonLabel className='normal-font'>
                  {t({ id: 'logout', message: 'Logout' })}
                </IonLabel>
              </IonItem>
              {/* </IonMenuToggle> */}
            </IonList>
          </>
        )}
        {isLoggedIn == 'false' && selectedMenu == '' && (
          <>
            <IonList>
              {/* <IonMenuToggle auto-hide='false'> */}
              <IonItem
                button
                detail={false}
                className='remove-detail-icon'
                onClick={() => {
                  onNavClick('/login');
                }}
              >
                <IonLabel className='normal-font'>
                  {t({ id: 'login', message: 'Login' })}
                </IonLabel>
              </IonItem>
              {/* </IonMenuToggle> */}
            </IonList>
            <IonList>
              {/* <IonMenuToggle auto-hide='false'> */}
              <IonItem
                button
                detail={false}
                className='remove-detail-icon'
                onClick={() => {
                  onNavClick('/signup');
                }}
              >
                <IonLabel className='normal-font'>
                  {t({ id: 'register', message: 'Register' })}
                </IonLabel>
              </IonItem>
              {/* </IonMenuToggle> */}
            </IonList>
          </>
        )}
        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle> */}
            <IonItem
              button
              detail={false}
              className='remove-detail-icon'
              onClick={() => {
                if (isWeb()) {
                  onNavClick(`/legal/conditions-of-use`);
                } else {
                  setTimeout(() => {
                    menuController.close();
                    window.open(
                      `${APP_DOMAIN}legal/conditions-of-use`,
                      '_system',
                      'location=yes'
                    );
                    document.removeEventListener('ionBackButton', handleBack);
                    setSelectedMenu('');
                  }, 1000);
                }
              }}
            >
              <IonLabel className='normal-font'>
                {toFirstUpperCaseWord(
                  t({
                    id: 'terms_conditions',
                    message: 'terms & conditions',
                  })
                )}
              </IonLabel>
            </IonItem>
            {/* </IonMenuToggle> */}
          </IonList>
        )}
        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle> */}
            <IonItem
              button
              detail={false}
              className='remove-detail-icon'
              onClick={() => {
                if (isWeb()) {
                  onNavClick(`/legal/privacy-statement`);
                } else {
                  setTimeout(() => {
                    menuController.close();
                    window.open(
                      `${APP_DOMAIN}legal/privacy-statement`,
                      '_system',
                      'location=yes'
                    );
                    document.removeEventListener('ionBackButton', handleBack);
                    setSelectedMenu('');
                  }, 1000);
                }
              }}
            >
              <IonLabel className='normal-font'>
                {toFirstUpperCaseWord(
                  t({
                    id: 'privacy_statement',
                    message: 'privacy statement',
                  })
                )}
              </IonLabel>
            </IonItem>
            {/* </IonMenuToggle> */}
          </IonList>
        )}
        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle> */}
            <IonItem
              button
              detail={false}
              className='remove-detail-icon'
              onClick={() => {
                if (isWeb()) {
                  onNavClick(`/legal/imprint`);
                } else {
                  setTimeout(() => {
                    menuController.close();
                    window.open(
                      `${APP_DOMAIN}legal/imprint`,
                      '_system',
                      'location=yes'
                    );
                    document.removeEventListener('ionBackButton', handleBack);
                    setSelectedMenu('');
                  }, 1000);
                }
              }}
            >
              <IonLabel className='normal-font'>
                {toFirstUpperCaseWord(
                  t({
                    id: 'imprint',
                    message: 'Imprint',
                  })
                )}
              </IonLabel>
            </IonItem>
            {/* </IonMenuToggle> */}
          </IonList>
        )}
        {selectedMenu == '' && (
          <IonList>
            {/* <IonMenuToggle auto-hide='false'> */}
            <IonItem button detail={false} className='remove-detail-icon'>
              <IonLabel className='normal-font' style={{ textAlign: 'end' }}>
                Version {APP_VERSION}
              </IonLabel>
            </IonItem>
          </IonList>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default MenuBar;
