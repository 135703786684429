import { Capacitor } from '@capacitor/core';

export const api: any = {
  apiUrl: (() => {
    //return "https://api.foodsecurity.covestro.com/api/v1/";
    console.log(window?.location?.hostname, window?.location);
    if (window?.location?.hostname.includes('glpoly.net')) {
      return 'https://api.817389582006.aws.glpoly.net/api/v1/';
    }
    if (window?.location?.hostname.includes('foodsecurity')) {
      return 'https://api.foodsecurity.covestro.com/api/v1/';
    }
  })(),
};
export const FIRST_LOGIN = 'firstLogin';
export const FCM_TOKEN = 'fcmToken';
export const ACCESS_TOKEN = 'accessToken';
export const SELECTED_LANG = 'selectedLang';
export const INIT_PAGE = Capacitor.getPlatform() == 'web' ? '/' : '/tabs/home';
export const TIME_FOR_RESEND = 60 * 1;
export const MAX_FILE_SIZE = 2101546; // Bytes
// export const APP_DOMAIN = 'http://localhost:8100/';

export const APP_DOMAIN = (() => 'https://foodsecurity.covestro.com/')();

export const FIREBASE_WEB_API_KEY = 'AIzaSyBf3s3F2twkIF57R6YcCJKFhD87DShApIc';
// export const RESPOND_MSG = t({
//   id: 'post_contact',
//   message: 'Hi, I am interested in your post',
// });
export const APP_VERSION = '0.29';
export const SELL_IMAGE_NUMBER = 5;
