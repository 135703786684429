import { IonImg } from '@ionic/react';
import React from 'react';
import Placeholder from '../../../assets/images/cd-placeholder.png';
import { getFormattedUrlCMS } from '../../../utils/common.helper';
import './style.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
interface props {
  imageClass?: string;
  images: any | null;
}
const BannerImage: React.FC<props> = ({ imageClass, images }) => {
  return (
    <>
      {images.length == 1 ? (
        <div className={`rect-img page-banner ${imageClass || ''}`}>
          <IonImg
            src={
              getFormattedUrlCMS(
                `${images[0].bannerimg[0].hash}${images[0].bannerimg[0].ext}`
              ) || Placeholder
            }
          />
        </div>
      ) : images && images.length > 1 ? (
        <Carousel
          key={'Banner-slider'}
          autoPlay
          showArrows={false}
          stopOnHover={false}
          showStatus={false}
          infiniteLoop
          showThumbs={false}
          className='slide-bg slide-bg--pagination'
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
        >
          {images.map((item: any, index: number) => {
            return (
              <div className='rect-img page-banner' key={index}>
                <IonImg
                  src={getFormattedUrlCMS(
                    `${item.bannerimg[0].hash}${item.bannerimg[0].ext}`
                  )}
                  // className='slide-bg__img page-banner'
                />
              </div>
            );
          })}
        </Carousel>
      ) : null}
    </>
  );
};

export default BannerImage;
