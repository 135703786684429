import { IonPage } from '@ionic/react';

import './style.scss';
import Layout from '../../components/layouts';

import { PrivateRoute } from '../../components/privateroute';

import Sellrequirements from '../sellrequirements';
import { isWeb } from '../../utils/common.helper';

const AddSellPost: React.FC = () => {
  return (
    <IonPage className='page-requirement'>
      <Layout>
        <div className={`page-container ${isWeb() ? 'pt-0' : ''}`}>
          <Sellrequirements showBred />
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(AddSellPost);
