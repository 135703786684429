/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonItemDivider,
  IonLabel,
  IonRow,
} from '@ionic/react';
import { AppContext } from '../../context/app.context';
import { t } from '@lingui/macro';
import ProductCard from '../productcard';
import ButtonComponent from '../button-component';
import {
  getProductList,
  getSolutionByUserId,
} from '../../services/product.service';

interface Props {
  fromProfile?: boolean;
  onSubmit: (productList: any) => void;
  onSkip?: () => void;
}

const ChooseProduct: React.FC<Props> = (props) => {
  const { fromProfile, onSubmit, onSkip } = props;
  const { setLoading }: any = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const [selectedProductList, setSelectedProductList]: any = useState([]);
  const [productList, setproductList]: any = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const resp: any = await getProductList();
    if (!resp.error) {
      if (fromProfile) {
        const userSolution = await getSolutionByUserId();
        if (!userSolution.error && userSolution && userSolution.length > 0) {
          const userSelectedSolution: any = [];
          resp.map((item: any) => {
            const solItem = userSolution.find(
              (ui: any) => ui.solution.id == item.id
            );
            item.isChecked = solItem ? true : false;
            if (solItem) {
              userSelectedSolution.push(solItem.solution);
            }
          });
          setSelectedProductList(userSelectedSolution);
        }
      }
      setproductList(resp);
    }
    setLoading(false);
  };
  const onProductSubmit = async () => {
    setSubmitting(true);
    await onSubmit(selectedProductList);
    setSubmitting(false);
  };

  const onSkipClick = async () => {
    onSkip && onSkip();
  };

  const onCheckedProduct = (isChecked: boolean, value: string) => {
    const selectedProductItem = productList.find((item: any) => {
      return item.title == value;
    });

    if (selectedProductItem) {
      if (isChecked) {
        selectedProductList.push(selectedProductItem);
        setSelectedProductList([...selectedProductList]);
      } else {
        setSelectedProductList(
          selectedProductList.filter((item: any) => {
            return item.title != value;
          })
        );
      }
    }
  };

  return (
    <div className='product-container'>
      <div className='choose-product-wrapper'>
        <IonItemDivider className='ion-divider pl-0 mb-24'>
          <div className='choose-product-title font-aktiv-grotest-medium m-0'>
            {t({ id: 'choose_product', message: 'Solutions' })}
          </div>
        </IonItemDivider>

        <IonGrid className='p-0'>
          <IonRow className='ion-row'>
            {productList.map((item: any) => {
              return (
                <IonCol key={item.id} size-xs='6' size-md='3' className='mb-5'>
                  <ProductCard
                    product={item}
                    productLabel={item.title}
                    imgPath={
                      item.image_pic_obj ? item.image_pic_obj.uri : undefined
                    }
                    customClass='choose-product-card'
                    onCheckChanged={onCheckedProduct}
                    isChecked={item.isChecked}
                  />
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </div>
      <div>
        <div className='product-btn-wrapper mt-24'>
          <ButtonComponent
            name={'Submit'}
            customClass='choose-product-btn'
            textKey={'submit'}
            onClick={onProductSubmit}
            disabled={submitting || selectedProductList.length == 0}
          />
          {onSkip && (
            <ButtonComponent
              name={'Not using any solutions'}
              customClass='choose-product-btn ml-24'
              textKey={'not_using_any_solutions'}
              onClick={onSkipClick}
              disabled={selectedProductList.length > 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseProduct;
