import { Capacitor } from '@capacitor/core';
import languageService from './sqlite/languageSqlite.service';
import HttpService, { HttpMethod } from './http.service';
import { File } from '@awesome-cordova-plugins/file';
import { getFileDirectory } from '../utils/common.helper';
import { downloadFile } from './filetransfer.service';
import { i18n } from '@lingui/core';
import httpService from './http.service';
import { api } from '../config/appconfig';

export const getLangDescriptor = async (language: string) => {
  let lang_version = 0;

  if (Capacitor.getPlatform() !== 'web') {
    const resp: any = await languageService.instance.getData('code', language);
    if (!resp.error && resp.length > 0) {
      lang_version = resp.item(0).version;
    }
  }

  const descResp: any = await getDescriptor(language);

  if (!descResp.error && descResp.file_path) {
    console.log('desc', lang_version, descResp);
    const parsedFilePath = JSON.parse(descResp.file_path);
    const desc_url = `file/download/${parsedFilePath.uri}`;
    if (
      Capacitor.getPlatform() !== 'web' &&
      descResp.version &&
      descResp.file_path
    ) {
      const desc_file = `descriptor_${language}`;

      if (descResp.version != lang_version) {
        // download new file

        try {
          const isExist = await File.checkFile(
            getFileDirectory(),
            `${desc_file}.json`
          );
          if (isExist) {
            const removeRes: any = await File.removeFile(
              getFileDirectory(),
              `${desc_file}.json`
            );
            if (removeRes && removeRes.success) {
              const resDown: any = await downloadDescFile(desc_url, desc_file);
              if (!resDown.error) {
                languageService.instance.insertorupdate({
                  name: descResp.title,
                  code: descResp.code,
                  version: descResp.version,
                });
                const langDescData = await File.readAsText(
                  getFileDirectory(),
                  `${desc_file}.json`
                );
                return setI18Lang(language, langDescData);
              }
            }
          }
        } catch (error) {
          const resDown: any = await downloadDescFile(desc_url, desc_file);
          if (!resDown.error) {
            languageService.instance.insertorupdate({
              name: descResp.title,
              code: descResp.code,
              version: descResp.version,
            });
            const langDescData = await File.readAsText(
              getFileDirectory(),
              `${desc_file}.json`
            );
            return setI18Lang(language, langDescData);
          }
        }
      } else {
        const langDescData = await File.readAsText(
          getFileDirectory(),
          `${desc_file}.json`
        );
        return setI18Lang(language, langDescData);
      }
    } else {
      const res: any = await httpService.execute({
        method: HttpMethod.GET,
        url: `${api.apiUrl}${desc_url}`,
      });
      console.log('lang', res);

      if (!res.error) {
        setI18Lang(language, res);
      }
    }
  } else {
    await getLangDescriptor('en');
  }
};

export const setI18Lang = (languageCode: string, langDescData: any) => {
  i18n.load(
    languageCode,
    typeof langDescData === 'string' ? JSON.parse(langDescData) : langDescData
  );
  // i18n.loadLocaleData('hi', { plurals: hi });
  i18n.activate(languageCode);
  return i18n;
};
export const getDescriptor = async (languageCode: string) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/language/getDescriptor`,
    data: {
      languageCode,
    },
  });
  return response;
};

export const getLanguages = async () => {
  const response = await HttpService.execute({
    url: `/language`,
  });
  return response;
};

const downloadDescFile = async (desc_url: string, desc_file: string) => {
  const resDownload: any = await downloadFile(desc_url, desc_file, 'json');
  console.log('down', resDownload);
  //   if (!resDownload.error) {
  //     Capacitor.convertFileSrc(`${getFileDirectory()}${desc_file}.json`);
  //   }
  return resDownload;
};
