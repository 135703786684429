import { IonPage } from '@ionic/react';
import { useContext } from 'react';
import ImprintEN from '../../components/imprintlang/en';
import Layout from '../../components/layouts';
import { AppContext } from '../../context/app.context';
import './style.scss';

const Imprint: React.FC = () => {
  const { lang }: any = useContext(AppContext);
  return (
    <IonPage className='page-imprint'>
      <Layout>
        <div className='content-sec container mt-20'>
          {lang == 'en' ? <ImprintEN /> : <ImprintEN />}
        </div>
      </Layout>
    </IonPage>
  );
};

export default Imprint;
