import Messages from '../config/messages';
import httpService, { HttpMethod } from './http.service';

export async function getContent(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `staticpage/get/bymodel?model=${req}`,
    });
    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
