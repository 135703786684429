import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';

interface Props {
  message?: string;
  messageKey?: string;
  title?: string;
  titleKey?: string;
  confirmMsg?: string;
  confirmMsgKey?: string;
  positiveBtnText?: string;
  positiveBtnTextKey?: string;
  negativeBtnText?: string;
  negativeBtnTextKey?: string;
  isSubmitting: boolean;
  onPositiveClick: () => void;
  onNegativeClick: () => void;
}
const DeleteBox: React.FC<Props> = (props: Props) => {
  const {
    message,
    messageKey,
    title,
    titleKey,
    confirmMsg,
    confirmMsgKey,
    positiveBtnText,
    positiveBtnTextKey,
    negativeBtnText,
    negativeBtnTextKey,
    isSubmitting,
    onPositiveClick,
    onNegativeClick,
  } = props;
  return (
    <div className='img-popover-inner' style={{ backgroundColor: 'white' }}>
      <div className='popupSupport_description'>
        {title && (
          <>
            <p>
              {t({
                id: titleKey,
                message: title,
              })}
            </p>
            <br />
          </>
        )}
        {message && (
          <>
            <p>
              {t({
                id: messageKey,
                message: message,
              })}
            </p>
            <br />
          </>
        )}
        <p>
          {t({
            id: confirmMsgKey || 'are_you_sure',
            message: confirmMsg || 'Are You Sure?',
          })}
        </p>
      </div>
      <div className='popupSupport_btns'>
        <ButtonComponent
          name={negativeBtnText || 'No'}
          textKey={negativeBtnTextKey || 'no'}
          disabled={isSubmitting}
          customClass='popup-btn popup-btn-cancel mt-10'
          onClick={() => {
            onNegativeClick();
          }}
        />
        <ButtonComponent
          name={positiveBtnText || 'Yes'}
          textKey={positiveBtnTextKey || 'yes'}
          customClass='popup-btn mt-10'
          disabled={isSubmitting}
          onClick={async () => {
            await onPositiveClick();
          }}
        />
      </div>
    </div>
  );
};
export default DeleteBox;
