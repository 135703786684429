/* eslint-disable no-unused-vars */
import { IonList } from '@ionic/react';
import { t } from '@lingui/macro';
import React, { useState } from 'react';
import {
  getFormattedUrl,
  getUserInitial,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import ProfileImage from '../profileimg';
import './style.scss';
interface Props {
  messengerList: any;
  selectedUser: any;
  onSelectUser: (selectedUser: any) => void;
}
const Messengers: React.FC<Props> = (props: Props) => {
  const { messengerList, selectedUser, onSelectUser } = props;
  const [selectedIndex, setSelectedIndex]: any = useState(selectedUser);
  return (
    <div>
      <h3 className='mb-10'>{t({ id: 'messenger', message: 'Messenger' })}</h3>
      <div className='divider mb-5'></div>
      <div
        className='messenger-list-wrapper'
        style={{
          height:
            window.screen.availHeight - window.screen.availHeight / 2 - 60,
        }}
      >
        {messengerList.map((item: any) => {
          return (
            <div
              key={item.id}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  selectedIndex.user_id == item.user_id ? '#F2F2F2' : 'unset',
              }}
              onClick={() => {
                onSelectUser(item);
                setSelectedIndex(item);
              }}
            >
              {/* <div className='messenger-title font-aktiv-grotest-medium'>Messenger</div> */}
              <div className='messenger-flex messenger'>
                <ProfileImage
                  userInitial={getUserInitial(
                    item.user_firstname || 'Anonymous'
                  )}
                  imgPath={
                    item.user_profile_pic_obj
                      ? getFormattedUrl(
                          JSON.parse(item.user_profile_pic_obj).uri
                        )
                      : undefined
                  }
                  customClass='messenger-card-profile-img mr-8 ml-3'
                />
                <div className='messenger-text-wrapper mr-24'>
                  <div className='messenger-card-profile-name font-aktiv-grotest-bold mr-10px'>
                    {item.user_firstname && item.user_is_active
                      ? `${toFirstUpperCaseWord(item.user_firstname)} ${
                          item.user_lastname
                            ? toFirstUpperCaseWord(item.user_lastname || '')
                            : ''
                        }`
                      : 'Anonymous'}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Messengers;
