import SQLiteService from './sqlite.service';

const TABLE_NAME = 'language';

const COLUMN_NAME = 'name';
const COLUMN_CODE = 'code';
const COLUMN_VERSION = 'version';

const TABLE_CREATE =
  'create table if not exists ' +
  TABLE_NAME +
  '(' +
  COLUMN_NAME +
  ' TEXT, ' +
  COLUMN_CODE +
  ' TEXT, ' +
  COLUMN_VERSION +
  " int DEFAULT '0')";

let _singleton = true;
let _instance: LanguageService;
const sqliteService: SQLiteService = SQLiteService.instance;
class LanguageService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        'This is a singleton class. Please use LanguageService.instance instead!'
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new LanguageService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insertorupdate(langData: any) {
    const resp = await this.getData(COLUMN_CODE, langData.code);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
        result = await sqliteService.update(
          `update ${TABLE_NAME} set ${COLUMN_VERSION} = ${langData.version} where ${COLUMN_CODE} = '${langData.code}'`
        );
      } else {
        let values = '';
        values += "'" + langData.name;
        values += "','" + langData.code;
        values += "','" + langData.version + "'";

        result = await sqliteService.insert(TABLE_NAME, values);
      }
    }

    console.log('insert', result);
    return result;
  }

  public async getData(column?: string, value?: any) {
    let query = 'select * from ' + TABLE_NAME;
    if (column) {
      query = `select * from ${TABLE_NAME} where ${column} = '${value}'`;
    }
    const result: any = await sqliteService.getData(query);
    return result;
  }
}

export default LanguageService;
