/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonItemDivider,
  IonLabel,
  IonRow,
} from '@ionic/react';
import DropDown from '../dropdown';
import { t, Trans } from '@lingui/macro';
import InputText from '../../components/inputtext';
//import ValidationMessage from '../../components/validationMessage';
//import { UserDetailSchema } from '../../schemas/auth.schema';
//import { Formik, Form } from 'formik';
import ButtonComponent from '../button-component';
import { Form, Formik } from 'formik';
import { UserDetailSchema } from '../../schemas/auth.schema';
import ValidationMessage from '../validationMessage';
import UploadProfilePic from '../uploadprofilepic';
import { i18n } from '@lingui/core';
import FocusError from '../focus-error';
import { getDataForDropDown } from '../../utils/common.helper';
import AutocompleteInput from '../autocompleteinput';
import {
  getLocationByPincode,
  getLocationList,
} from '../../services/location.service';
import {
  removeUserProfilePic,
  stakeholderList,
} from '../../services/user.service';
import { getFPOs } from '../../services/fpo.service';
import { AppContext } from '../../context/app.context';

const yearOfRegistration = [
  {
    name: t({ id: 'select', message: 'Select' }),
    value: '',
  },
];
for (let index = 2000; index <= new Date().getFullYear(); index++) {
  yearOfRegistration.push({
    name: index.toString(),
    value: index.toString(),
  });
}
interface Props {
  data?: any;
  disablePhone?: boolean;
  disableEmail?: boolean;
  onSubmit: (values: any, actions: any) => void;
  onStakeCatChange?: (value: any) => void;
}
const RegisterForm: React.FC<Props> = (props) => {
  let interval: any = null;
  const { data, disableEmail, disablePhone, onSubmit, onStakeCatChange } =
    props;
  const { setLoading }: any = useContext(AppContext);
  const [error] = React.useState(null);
  const [showNameofOrg, setShowNameofOrg] = useState(
    (data.stakeholderType && data.stakeholderType != 'Individual Farmer') ||
      false
  );
  const [showFPO, setShowFPO] = useState(
    (data.stakeholderType &&
      data.stakeholderType == 'Farmer Producer Organisation (FPOs)') ||
      false
  );
  const [showLandline, setShowLandline] = useState(
    data && data.stakeholderCategory && data.stakeholderCategory != 'Farmers'
  );

  const [pincodeList, setPincodeList] = useState([]);
  const [villageOrLocality, setVillageOrLocality] = useState([]);
  // const [selectedVillage, setSelectedVillage] = useState(
  //   data && data.villageLocality
  // );
  const [villageSuggestions, setVillageSuggestions] = useState([]);

  const [stakeCategory, setStakeCategory] = useState([]);
  const [stakeType, setStakeType] = useState([]);
  const [stakeList, setStakeList] = useState([]);
  const [selectedStakeCategory] = useState(
    (data && data.stakeholderCategory) || 'Farmers'
  );
  const [selectedStakeType, setSelectedStakeType] = useState(
    data && data.stakeholderType
  );
  const [selectedRegYear, setSelectedRegYear] = useState(
    (data && data.yearOfReg) || ''
  );

  const [fpoList, setFpoList] = useState([]);
  const initialValues = {
    stakeholderCategory: data.stakeholderCategory || 'Farmers',
    stakeholderType: data.stakeholderType || 'Individual Farmer',
    nameOfOrganization: showNameofOrg ? data.nameOfOrganization || null : null,
    firstName: data.firstName || '',
    lastName: data.lastName || null,
    landlineNumber: showLandline ? data.landlineNumber || null : null,
    mobileNumber: data.mobileNumber || '',
    email: data.email || null,
    pinCode: data.pinCode || '',
    state: data.state || '',
    district: data.district || '',
    villageLocality: data.villageLocality || '',
    fpoRegNumber: showFPO ? data.fpoRegNumber || null : null,
    fpoPromoBy: showFPO ? data.fpoPromoBy || null : null,
    yearOfReg: showFPO ? data.yearOfReg || null : null,
    regFarmer: showFPO ? data.regFarmer || null : null,
    annualTurnover: showFPO ? data.annualTurnover || null : null,
    profilePic: data.profilePic || '',
    profilePicId: data.profilePicId || null,
    locationId: data.locationId || '',
    stakeholderCategoryId: data.stakeholderCategoryId || 3,
    stakeholderTypeId: data.stakeholderTypeId || 12,
  };
  const handleUserDetails = (values: any, actions: any) => {
    console.log(values);
    actions.setFieldError(undefined);
    // actions.setSubmitting(false);
    onSubmit(values, actions);
  };

  const UserDetailSchemaMemo: any = useMemo(
    () => UserDetailSchema(),
    [i18n.locale]
  );

  useEffect(() => {
    setLoading(true);
    if (data && data.pinCode) {
      getPincodeDetailsOnLoad(data.pinCode);
    }
    Promise.all([getStakeholdeList(), getFPOList()]).finally(() => {
      setLoading(false);
    });
  }, []);

  async function getFPOList() {
    const resp = await getFPOs();
    if (!resp.error) {
      setFpoList(resp.map((item: any) => item.name));
    }
  }
  async function getPincodeDetailsOnLoad(pincode: string) {
    const resp: any = await getLocationByPincode(pincode);
    if (!resp.error && resp.items.length > 0) {
      const data = getDataForDropDown(resp.items, 'locality_name');
      setVillageOrLocality(data);
      setVillageSuggestions(data.map((item: any) => item.name));
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  async function getStakeholdeList() {
    const resp: any = await stakeholderList();
    if (!resp.error && resp.length > 0) {
      setStakeList(resp);
      const uniqueStakeCategory: any = [];
      for (let index = 0; index < resp.length; index++) {
        const element = resp[index];
        if (
          !uniqueStakeCategory.find(
            (item: any) => item.stakeholder_name == element.stakeholder_name
          )
        ) {
          uniqueStakeCategory.push(element);
        }
      }

      setStakeCategory(
        getDataForDropDown(
          uniqueStakeCategory,
          'stakeholder_name',
          'stakeholder_id'
        ).reverse()
      );
      const stakeTypeList = resp.filter(
        (item: any) => item.stakeholder_name == selectedStakeCategory
      );
      setStakeType(
        getDataForDropDown(
          stakeTypeList,
          'stakeholdertype_name',
          'stakeholdertype_id'
        )
      );
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  async function getPincodeList(
    pincode: string,
    setFieldError: any,
    setFieldValue: any
  ) {
    setFieldValue('state', '');
    setFieldValue('district', '');
    setVillageOrLocality([]);
    setVillageSuggestions([]);
    setFieldValue('villageLocality', '');
    setFieldValue('locationId', '');
    if (pincode.trim().length >= 3) {
      const resp: any = await getLocationList(pincode);
      if (!resp.error) {
        setPincodeList(resp);
        if (resp.length == 0) {
          setFieldError('pinCode', 'No details found');
        }
      } else {
        setFieldError('pinCode', resp.message);
      }
    } else {
      setPincodeList([]);
    }
  }

  async function getPincodeDetails(pincode: string, setFieldValue: any) {
    const resp: any = await getLocationByPincode(pincode);
    if (!resp.error && resp.items.length > 0) {
      setFieldValue('pinCode', pincode);
      setFieldValue('state', resp.items[0].state);
      setFieldValue('district', resp.items[0].district);
      setVillageOrLocality(getDataForDropDown(resp.items, 'locality_name'));
      const data = getDataForDropDown(resp.items, 'locality_name');
      setVillageOrLocality(data);

      setVillageSuggestions(data.map((item: any) => item.name));
      // setFieldValue('villageLocality', resp.items[0].locality_name);
      // setFieldValue('locationId', resp.items[0].id);
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  async function onProfileImageSelect(image: string, setFieldValue: any) {
    setFieldValue('profilePic', image);
  }

  function onStakeCategoryChanged(
    item: any,
    setFieldValue: any,
    setFieldTouched: any
  ) {
    setFieldValue('stakeholderCategory', item.value);
    setFieldValue('stakeholderCategoryId', item.id);
    setFieldTouched('stakeholderCategory', true);
    const stakeTypeList = stakeList.filter((stakeItem: any) => {
      return stakeItem.stakeholder_name == item.value;
    });

    setStakeType(
      getDataForDropDown(
        stakeTypeList,
        'stakeholdertype_name',
        'stakeholdertype_id'
      )
    );
    setFieldValue('stakeholderType', stakeTypeList[0]['stakeholdertype_name']);
    setFieldValue('stakeholderTypeId', stakeTypeList[0]['stakeholdertype_id']);
    setSelectedStakeType(stakeTypeList[0]['stakeholdertype_name']);
    setShowNameofOrg(
      stakeTypeList[0]['stakeholdertype_name'] != 'Individual Farmer'
    );
    if (stakeTypeList[0]['stakeholdertype_name'] == 'Individual Farmer') {
      setFieldValue('nameOfOrganization', '');
    }
    setShowFPO(
      stakeTypeList[0]['stakeholdertype_name'] ==
        'Farmer Producer Organisation (FPOs)'
    );
    if (
      stakeTypeList[0]['stakeholdertype_name'] !=
      'Farmer Producer Organisation (FPOs)'
    ) {
      setFieldValue('fpoRegNumber', null);
      setFieldValue('fpoPromoBy', null);
      setFieldValue('yearOfReg', null);
      setFieldValue('regFarmer', null);
      setFieldValue('annualTurnover', null);
    }
    setShowLandline(item.value != 'Farmers');
    if (item.value == 'Farmers') {
      setFieldValue('landlineNumber', null);
    }
    onStakeCatChange && onStakeCatChange(item.value);
  }

  function onStakeTypeChanged(
    item: any,
    setFieldValue: any,
    setFieldTouched: any
  ) {
    setFieldValue('stakeholderType', item.value);
    setFieldValue('stakeholderTypeId', item.id);
    setFieldTouched('stakeholderType', true);
    setShowNameofOrg(item.value != 'Individual Farmer');
    if (item.value == 'Individual Farmer') {
      setFieldValue('nameOfOrganization', '');
    }
    setShowFPO(item.value == 'Farmer Producer Organisation (FPOs)');
    if (item.value != 'Farmer Producer Organisation (FPOs)') {
      setFieldValue('fpoRegNumber', null);
      setFieldValue('fpoPromoBy', null);
      setFieldValue('yearOfReg', null);
      setFieldValue('regFarmer', null);
      setFieldValue('annualTurnover', null);
      setSelectedRegYear('');
    }
  }
  return (
    <div className='form-container'>
      <Formik
        initialValues={initialValues}
        validationSchema={UserDetailSchemaMemo}
        onSubmit={handleUserDetails}
        validateOnChange={false}
        key='user-details'
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          setFieldError,
          isSubmitting,
        }) => (
          <Form className='registration-form'>
            <>
              <div className='form__user-details'>
                <IonGrid className='p-0'>
                  <div className='form__user-type mb-24'>
                    <IonItemDivider className='ion-divider pl-0'>
                      <IonLabel className='user-title font-aktiv-grotest-bold m-0'>
                        {t({
                          id: 'type_of_user',
                          message: 'Type of Users',
                        })}
                      </IonLabel>
                    </IonItemDivider>
                  </div>
                  <IonRow className='ion-row'>
                    <IonCol size-xs='12' size-sm='4' className='ion-col mb-34'>
                      <Trans
                        id='stakeholders_category'
                        render={() => (
                          <DropDown
                            name='stakeholderCategory'
                            labelName='Stakeholders Category'
                            labelKey='stakeholders_category'
                            required
                            isLocalTranslate
                            optionList={stakeCategory}
                            onChange={(item: any) => {
                              onStakeCategoryChanged(
                                item,
                                setFieldValue,
                                setFieldTouched
                              );
                            }}
                            selectedItem={selectedStakeCategory}
                            error={Boolean(
                              errors.stakeholderCategory &&
                                touched.stakeholderCategory
                            )}
                          />
                        )}
                      >
                        Stakeholders Category
                      </Trans>
                      <ValidationMessage name={'stakeholderCategory'} />
                    </IonCol>
                    <IonCol size-xs='12' size-sm='4' className='ion-col mb-34'>
                      <Trans
                        id='type_of_stakeholders'
                        render={() => (
                          <DropDown
                            name='type_of_stakeholders'
                            labelName='Type of Stakeholders'
                            labelKey='type_of_stakeholders'
                            required
                            isLocalTranslate
                            optionList={stakeType}
                            onChange={(item: any) => {
                              onStakeTypeChanged(
                                item,
                                setFieldValue,
                                setFieldTouched
                              );
                            }}
                            selectedItem={selectedStakeType}
                            error={Boolean(
                              errors.stakeholderType && touched.stakeholderType
                            )}
                          />
                        )}
                      >
                        Type of Stakeholders
                      </Trans>
                      <ValidationMessage name={'stakeholderType'} />
                    </IonCol>
                    {showNameofOrg && (
                      <IonCol
                        size-xs='12'
                        size-sm='4'
                        className='ion-col mb-34'
                      >
                        <div className='input-text-container'>
                          <Trans
                            id='name_of_organization'
                            render={({ translation }) => (
                              <InputText
                                name='nameOfOrganization'
                                labelName='Name of Organization'
                                labelKey='name_of_organization'
                                placeholder={'' + translation}
                                required
                                //value={""}
                                value={values.nameOfOrganization}
                                onChange={(value: any) => {
                                  setFieldValue('nameOfOrganization', value);
                                  setFieldTouched('nameOfOrganization', true);
                                }}
                                error={Boolean(
                                  errors.nameOfOrganization &&
                                    touched.nameOfOrganization
                                )}
                              ></InputText>
                            )}
                          >
                            Name of Organization
                          </Trans>
                        </div>
                        <ValidationMessage name={'nameOfOrganization'} />
                      </IonCol>
                    )}
                  </IonRow>

                  <div className='form__user-details mb-24'>
                    <IonItemDivider className='ion-divider pl-0'>
                      <IonLabel className='user-title font-aktiv-grotest-bold m-0'>
                        {t({
                          id: 'user_details',
                          message: 'User Details',
                        })}
                      </IonLabel>
                    </IonItemDivider>
                  </div>

                  <IonRow className='ion-row'>
                    <IonCol size-xs='12' size-sm='8' className='ion-col'>
                      <IonRow className='ion-row'>
                        <IonCol
                          size-xs='12'
                          size-sm='6'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='first_name'
                              render={({ translation }) => (
                                <InputText
                                  name='firstName'
                                  labelName='First Name'
                                  labelKey='first_name'
                                  placeholder={'' + translation}
                                  required
                                  //value={""}
                                  value={values.firstName}
                                  onChange={(value: any) => {
                                    setFieldValue('firstName', value);
                                    setFieldTouched('firstName', true);
                                  }}
                                  error={Boolean(
                                    errors.firstName && touched.firstName
                                  )}
                                ></InputText>
                              )}
                            >
                              First Name
                            </Trans>
                          </div>
                          <ValidationMessage name={'firstName'} />
                        </IonCol>
                        <IonCol
                          size-xs='12'
                          size-sm='6'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='last_name'
                              render={({ translation }) => (
                                <InputText
                                  name='lastName'
                                  labelName='Last Name'
                                  labelKey='last_name'
                                  placeholder={'' + translation}
                                  //value={""}
                                  value={values.lastName}
                                  onChange={(value: any) => {
                                    if (value && value.trim().length > 0) {
                                      setFieldValue('lastName', value);
                                      setFieldTouched('lastName', true);
                                    } else {
                                      setFieldValue('lastName', null);
                                      setFieldTouched('lastName', true);
                                    }
                                  }}
                                  error={Boolean(
                                    errors.lastName && touched.lastName
                                  )}
                                ></InputText>
                              )}
                            >
                              Last Name
                            </Trans>
                          </div>
                          <ValidationMessage name={'lastName'} />
                        </IonCol>
                      </IonRow>
                      <IonRow className='ion-row'>
                        {showLandline && (
                          <IonCol
                            size-xs='12'
                            size-sm='6'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='landline_number'
                                render={({ translation }) => (
                                  <InputText
                                    name='landlineNumber'
                                    labelName='Landline Number'
                                    labelKey='landline_number'
                                    placeholder={'' + translation}
                                    //value={""}
                                    value={values.landlineNumber}
                                    onChange={(value: any) => {
                                      setFieldValue('landlineNumber', value);
                                      setFieldTouched('landlineNumber', true);
                                    }}
                                    error={Boolean(
                                      errors.landlineNumber &&
                                        touched.landlineNumber
                                    )}
                                    type='number'
                                  ></InputText>
                                )}
                              >
                                Landline Number
                              </Trans>
                            </div>
                            <ValidationMessage name={'landlineNumber'} />
                          </IonCol>
                        )}
                        <IonCol
                          size-xs='12'
                          size-sm='6'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='mobile_number'
                              render={({ translation }) => (
                                <InputText
                                  name='mobileNumber'
                                  labelName='Mobile Number'
                                  labelKey='mobile_number'
                                  placeholder={'' + translation}
                                  required
                                  //value={""}
                                  value={values.mobileNumber}
                                  onChange={(value: any) => {
                                    setFieldValue('mobileNumber', value);
                                    setFieldTouched('mobileNumber', true);
                                  }}
                                  error={Boolean(
                                    errors.mobileNumber && touched.mobileNumber
                                  )}
                                  type='number'
                                  disabled={disablePhone}
                                  inputMode={'numeric'}
                                  maxlength={10}
                                ></InputText>
                              )}
                            >
                              Mobile Number
                            </Trans>
                          </div>
                          <ValidationMessage name={'mobileNumber'} />
                        </IonCol>
                      </IonRow>
                      <IonRow className='ion-row'>
                        <IonCol
                          size-xs='12'
                          size-sm='6'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='email_id'
                              render={({ translation }) => (
                                <InputText
                                  name='email'
                                  labelName='Email ID'
                                  labelKey='email_id'
                                  placeholder={'' + translation}
                                  //value={""}
                                  value={values.email}
                                  onChange={(value: any) => {
                                    setFieldValue('email', value);
                                    setFieldTouched('email', true);
                                  }}
                                  error={Boolean(errors.email && touched.email)}
                                  disabled={disableEmail}
                                ></InputText>
                              )}
                            >
                              Email ID
                            </Trans>
                          </div>
                          <ValidationMessage name={'email'} />
                        </IonCol>
                        <IonCol
                          size-xs='12'
                          size-sm='6'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='pin_code'
                              render={({ translation }) => (
                                <AutocompleteInput
                                  required
                                  maxlength={6}
                                  suggestions={pincodeList}
                                  onItemSelected={async (value: string) => {
                                    setFieldValue('pinCode', value);
                                    setFieldTouched('pinCode', true);
                                    await getPincodeDetails(
                                      value,
                                      setFieldValue
                                    );
                                  }}
                                  name='pinCode'
                                  labelName='Pin code'
                                  labelKey='pin_code'
                                  placeholder={'' + translation}
                                  //value={""}
                                  value={values.pinCode}
                                  onSuggestionChange={async (value: any) => {
                                    setFieldValue('pinCode', value);
                                    setFieldTouched('pinCode', true);
                                    if (interval) {
                                      clearInterval(interval);
                                      interval = null;
                                    }
                                    interval = setInterval(async () => {
                                      await getPincodeList(
                                        value,
                                        setFieldError,
                                        setFieldValue
                                      );
                                      clearInterval(interval);
                                      interval = null;
                                    }, 500);
                                  }}
                                  error={Boolean(
                                    errors.pinCode && touched.pinCode
                                  )}
                                  type='number'
                                  // debounce={500}
                                  inputMode={'numeric'}
                                />
                              )}
                            >
                              Pin code
                            </Trans>
                          </div>
                          <ValidationMessage name={'pinCode'} />
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs='12' size-sm='4' className='ion-col '>
                      <UploadProfilePic
                        onCrop={(image: string) => {
                          onProfileImageSelect(image, setFieldValue);
                        }}
                        onRemove={async () => {
                          await removeUserProfilePic();
                          setFieldValue('profilePicId', null);
                          setFieldValue('profilePic', '');
                          return true;
                        }}
                        imgPath={data.profilePic}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-row'>
                    <IonCol size-xs='12' size-sm='4' className='ion-col mb-34'>
                      <div className='input-text-container'>
                        <Trans
                          id='state'
                          render={({ translation }) => (
                            <InputText
                              name='state'
                              labelName='State'
                              labelKey='state'
                              required
                              placeholder={'' + translation}
                              disabled
                              value={values.state}
                              onChange={(value: any) => {
                                setFieldValue('state', value);
                                setFieldTouched('state', true);
                              }}
                              error={Boolean(errors.state && touched.state)}
                            ></InputText>
                          )}
                        >
                          State
                        </Trans>
                      </div>
                      <ValidationMessage name={'state'} />
                    </IonCol>
                    <IonCol size-xs='12' size-sm='4' className='ion-col mb-34'>
                      <div className='input-text-container'>
                        <Trans
                          id='district'
                          render={({ translation }) => (
                            <InputText
                              name='district'
                              labelName='District'
                              labelKey='district'
                              required
                              placeholder={'' + translation}
                              disabled
                              value={values.district}
                              onChange={(value: any) => {
                                setFieldValue('district', value);
                                setFieldTouched('district', true);
                              }}
                              error={Boolean(
                                errors.district && touched.district
                              )}
                            ></InputText>
                          )}
                        >
                          District
                        </Trans>
                      </div>
                      <ValidationMessage name={'district'} />
                    </IonCol>
                    <IonCol size-xs='12' size-sm='4' className='ion-col mb-34'>
                      <Trans
                        id='village_locality'
                        render={({ translation }) => (
                          <AutocompleteInput
                            showSuggestionOnFocus
                            required
                            suggestions={villageSuggestions}
                            onItemSelected={(value: string) => {
                              setFieldValue('villageLocality', value);
                              setFieldTouched('villageLocality', false);
                              const villageItem: any = villageOrLocality.find(
                                (item: any) => item.name == value
                              );
                              if (villageItem) {
                                setFieldTouched('locationId', false);
                                setFieldValue('locationId', villageItem.id);
                              }
                            }}
                            name='pinCode'
                            labelName='Village / Locality'
                            labelKey='village_locality'
                            placeholderKey='type_your_village'
                            placeholderName='Type your Village/Locality'
                            placeholder={'' + translation}
                            //value={""}
                            value={values.villageLocality}
                            onSuggestionChange={async (value: any) => {
                              setFieldValue('villageLocality', value);
                              setFieldTouched('villageLocality', true);
                              setFieldValue('locationId', '');
                              setFieldTouched('locationId', true);
                            }}
                            error={Boolean(
                              errors.locationId && touched.locationId
                            )}
                            debounce={500}
                          />
                        )}
                      >
                        Village
                      </Trans>
                      <ValidationMessage name={'locationId'} />
                    </IonCol>
                  </IonRow>

                  {showFPO && (
                    <>
                      <div className='form__fpo-details mb-24'>
                        <IonItemDivider className='ion-divider pl-0'>
                          <IonLabel className='user-title font-aktiv-grotest-bold m-0'>
                            {t({ id: 'fpo_details', message: 'FPOs Details' })}
                          </IonLabel>
                        </IonItemDivider>
                      </div>

                      <IonRow className='ion-row'>
                        <IonCol
                          size-xs='12'
                          size-sm='4'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='fpo_registration_number'
                              render={({ translation }) => (
                                <InputText
                                  name='fpoRegNumber'
                                  labelName='FPO Registration Number'
                                  labelKey='fpo_registration_number'
                                  placeholder={'' + translation}
                                  //value={""}
                                  value={values.fpoRegNumber}
                                  onChange={(value: any) => {
                                    setFieldValue('fpoRegNumber', value);
                                    setFieldTouched('fpoRegNumber', true);
                                  }}
                                  error={Boolean(
                                    errors.fpoRegNumber && touched.fpoRegNumber
                                  )}
                                ></InputText>
                              )}
                            >
                              FPO Registration Number
                            </Trans>
                          </div>
                          <ValidationMessage name={'fpoRegNumber'} />
                        </IonCol>
                        <IonCol
                          size-xs='12'
                          size-sm='4'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='fpo_promoted_by'
                              render={({ translation }) => (
                                <AutocompleteInput
                                  suggestions={fpoList}
                                  onItemSelected={(value: string) => {
                                    setFieldValue('fpoPromoBy', value);
                                    setFieldTouched('fpoPromoBy', true);
                                  }}
                                  name='fpoPromoBy'
                                  labelName='FPO Promoted By'
                                  labelKey='fpo_promoted_by'
                                  placeholder={'' + translation}
                                  value={values.fpoPromoBy}
                                  onSuggestionChange={(value: any) => {
                                    setFieldValue('fpoPromoBy', value);
                                    setFieldTouched('fpoPromoBy', true);
                                  }}
                                  debounce={500}
                                />
                              )}
                            >
                              FPO Promoted By
                            </Trans>
                          </div>
                          <ValidationMessage name={'fpoPromoBy'} />
                        </IonCol>
                        <IonCol
                          size-xs='12'
                          size-sm='4'
                          className='ion-col mb-34'
                        >
                          <Trans
                            id='year_of_registration'
                            render={() => (
                              <DropDown
                                labelName='Year of Registration'
                                labelKey='year_of_registration'
                                required
                                optionList={yearOfRegistration}
                                onChange={(item: any) => {
                                  setFieldValue('yearOfReg', item.value);
                                  setFieldTouched('yearOfReg', true);
                                }}
                                selectedItem={selectedRegYear}
                                error={Boolean(
                                  errors.yearOfReg && touched.yearOfReg
                                )}
                              />
                            )}
                          >
                            Year of Registration
                          </Trans>
                          <ValidationMessage name={'yearOfReg'} />
                        </IonCol>
                      </IonRow>

                      <IonRow className='ion-row'>
                        <IonCol
                          size-xs='12'
                          size-sm='4'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='no_farmer_register'
                              render={({ translation }) => (
                                <InputText
                                  name='regFarmer'
                                  labelName='No. of farmer registered under FPOs'
                                  labelKey='no_farmer_register'
                                  placeholder={'' + translation}
                                  required
                                  //value={""}
                                  value={values.regFarmer}
                                  onChange={(value: any) => {
                                    setFieldValue('regFarmer', value);
                                    setFieldTouched('regFarmer', true);
                                  }}
                                  error={Boolean(
                                    errors.regFarmer && touched.regFarmer
                                  )}
                                  type='number'
                                  inputMode={'numeric'}
                                  maxlength={3}
                                ></InputText>
                              )}
                            >
                              No. of farmer registered under FPOs
                            </Trans>
                          </div>
                          <ValidationMessage name={'regFarmer'} />
                        </IonCol>
                        <IonCol
                          size-xs='12'
                          size-sm='4'
                          className='ion-col mb-34'
                        >
                          <div className='input-text-container'>
                            <Trans
                              id='annual_turnover'
                              render={({ translation }) => (
                                <InputText
                                  name='annualTurnover'
                                  labelName='Annual Turnover(in Rs Lakhs)'
                                  labelKey='annual_turnover'
                                  placeholder={'' + translation}
                                  //value={""}
                                  value={values.annualTurnover}
                                  onChange={(value: any) => {
                                    setFieldValue('annualTurnover', value);
                                    setFieldTouched('annualTurnover', true);
                                  }}
                                  error={Boolean(
                                    errors.annualTurnover &&
                                      touched.annualTurnover
                                  )}
                                  type='number'
                                  inputMode={'numeric'}
                                ></InputText>
                              )}
                            >
                              Annual Turnover(in Rs Lakhs)
                            </Trans>
                          </div>
                          <ValidationMessage name={'annualTurnover'} />
                        </IonCol>
                      </IonRow>
                    </>
                  )}
                </IonGrid>
              </div>
              {error && <div className='text-center text-danger'>{error}</div>}
              {/* <div className='form__fpo-details'></div> */}
              <div className='form__submit-btn'>
                <ButtonComponent
                  name={'Submit'}
                  customClass='login-btn-submit'
                  textKey={'submit'}
                  disabled={isSubmitting}
                />
              </div>
            </>
            <FocusError />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterForm;
