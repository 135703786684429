import { Storage } from '@ionic/storage';

let _singleton = true;
let _instance: StorageService;

class StorageService {
  protected store: Storage | any = null;

  constructor() {
    this.init();
    if (_singleton) {
      throw new SyntaxError(
        'This is a singleton class. Please use StorageService.instance instead!'
      );
    }
  }

  private init = async () => {
    this.store = new Storage();
    await this.store.create();
  };

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new StorageService();
      _singleton = true;
    }
    return _instance;
  }

  public set = async (key: string, value: any) => {
    await this.store.set(key, value);
    console.log('stoarge set', key, value);
    return await this.store.get(key);
  };

  public get = async (key: string) => {
    return await this.store.get(key);
  };

  public remove = async (key: string) => {
    await this.store.remove(key);
  };

  public clearAll = async () => {
    await this.store.clear();
  };
}

export default StorageService.instance;
