import './style.scss';
import { IonButtons, IonHeader, IonImg, IonToolbar } from '@ionic/react';
import React, { useContext } from 'react';
import NavButtons from '../navbuttons';
import Logo from '../../assets/images/logo/covestro-logo-wh.png';
import TopNavigation from '../navigationmeta';
import { AppContext } from '../../context/app.context';
import { useHistory } from 'react-router';
import { isWeb } from '../../utils/common.helper';
import { INIT_PAGE } from '../../config/appconfig';

const Header: React.FC = () => {
  const { isLoggedIn }: any = useContext(AppContext);
  const history = useHistory();
  return (
    <div className='page-header-container'>
      <IonHeader>
        <div className='header-wrapper'>
          {isWeb() ? (
            <IonButtons slot='end' className='header-nav-btns'>
              <NavButtons />
            </IonButtons>
          ) : isLoggedIn == 'true' ? (
            <IonButtons slot='end' className='header-nav-btns'>
              <NavButtons />
            </IonButtons>
          ) : null}

          <TopNavigation />
          <IonToolbar className='page-header_toolbar-container'>
            <div
              className='logo-container'
              onClick={() => {
                if (isWeb() || isLoggedIn == 'true') {
                  history.push(INIT_PAGE);
                  window.location.reload();
                }
              }}
            >
              <IonImg src={Logo} className='logo-img mr-20' />
              <span className='logo__identifier'>Food Security Platform</span>
            </div>
          </IonToolbar>
        </div>
      </IonHeader>
    </div>
  );
};

export default Header;
