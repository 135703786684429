import './style.scss';
import { IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import Layout from '../../components/layouts';
import { t } from '@lingui/macro';
const Unauthenticate: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <Layout>
        <div className='unauthenticate__sec page-container'>
          <div className='unauthenticate__box'>
            <h2 className='intro-covestro-text font-aktiv-grotest-medium mb-40'>
              {t({
                id: 'login_register_to_continue',
                message: 'Login / Register to continue',
              })}
            </h2>
            <ul className='success__box__links'>
              <li
                className='lbl font-aktiv-grotest-medium'
                onClick={() => {
                  history.push(`/login`);
                }}
              >
                {t({
                  id: 'login',
                  message: 'Login',
                })}
              </li>
              <li
                className='lbl font-aktiv-grotest-medium'
                onClick={() => {
                  history.push('/signup');
                }}
              >
                {t({
                  id: 'register',
                  message: 'Register',
                })}
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default Unauthenticate;
