import './style.scss';
import { IonImg, IonPage } from '@ionic/react';
import Layout from '../../components/layouts';
import img404 from '../../assets/images/icon-404.png';
const page404: React.FC = () => {
  return (
    <IonPage>
      <Layout>
        <div className='page-404'>
          <IonImg className='page-404__icon' src={img404}></IonImg>
          <div className='page-404__copy'>
            <h1>Page not found</h1>
            <p>
              We can’t seem to find the page you’re looking for. Try going back
              to the previous page or see our <a href='#'>Support</a> for more
              information.
            </p>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default page404;
