/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useContext, useEffect, useState } from 'react';
import CommentSectionItem from '../commentsectionitem';
import MessengerIcon from '../../assets/images/icons/icon_messanger.svg';
import Messengers from '../messengers';
import ProfileImage from '../profileimg';
import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonImg,
  IonList,
  IonModal,
  IonRow,
  IonTextarea,
} from '@ionic/react';
import ButtonComponent from '../button-component';
import moment from 'moment';
import { AppContext } from '../../context/app.context';
import {
  formatDateToSingleChar,
  getFormattedUrl,
  getUserInitial,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import { t, Trans } from '@lingui/macro';
import {
  getPostComments,
  getPostCommentsChat,
} from '../../services/post.service';
import Messages from '../../config/messages';
import LogInBack from '../../assets/images/icons/arrow-small-right.png';

interface Props {
  userId?: number;
  isPrivateChat?: boolean;
  postId: number;
  showMessanger?: boolean;
  commentList: any;
  maxCount?: number;
  messengerList?: any;
  isLoadMore?: boolean;
  onSubmit?: (commentText: string) => Promise<any>;
  updateComment?: (commentList: any, postId: any) => void;
  onSelectUser?: (selectedUser: any) => Promise<any>;
}
let isModalVisible = false;
const CommentSection: React.FC<Props> = (props: Props) => {
  const {
    userId,
    isPrivateChat,
    postId,
    showMessanger,
    commentList,
    maxCount,
    messengerList,
    isLoadMore,
    onSubmit,
    updateComment,
    onSelectUser,
  } = props;
  const [commentText, setCommentText] = useState('');
  const [isSubmmiting, setIsSubmmiting] = useState(false);
  let sortedList = commentList;
  if (maxCount && maxCount < sortedList.length) {
    sortedList = sortedList.splice(0, maxCount);
  }
  const [list, setList] = useState(sortedList);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(isLoadMore);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { userDetails, toastMessage }: any = useContext(AppContext);
  const [selectedIndex, setSelectedIndex]: any = useState(
    messengerList && messengerList.length > 0 ? messengerList[0] : {}
  );
  const [showCommentBox, setShowCommentBox] = useState(
    commentList && commentList.length > 0
      ? commentList[0].type == 'public'
        ? commentList[0].user.is_active
        : commentList[0].to_user_id.is_active && commentList[0].user.is_active
      : 'true'
  );

  useEffect(() => {
    if (isPrivateChat) {
      let interval: any = null;
      if (!interval) {
        interval = setInterval(() => {
          fetchPostCommentsChatsAuto();
        }, 1000 * 60);
      }

      return () => {
        clearInterval(interval);
        interval = null;
      };
    }
  }, [isPrivateChat]);

  useEffect(() => {
    if (showModal) {
      document.addEventListener('ionBackButton', handleBack);
    } else {
      isModalVisible = false;
      document.removeEventListener('ionBackButton', handleBack);
    }
    return () => {
      document.removeEventListener('ionBackButton', handleBack);
      // isModalVisible = false;
    };
  }, [showModal]);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isModalVisible) {
        isModalVisible = false;
        setShowModal(false);
        document.removeEventListener('ionBackButton', handleBack);
      }
    });
  };

  const loadMoreComments = async () => {
    if (commentsLoading) return;
    setCommentsLoading(true);
    const resp = await getPostComments(`page=${currentPage}`, postId);
    setCommentsLoading(false);
    if (!resp.error) {
      setList(currentPage == 1 ? resp.items : list.concat(resp.items));
      setShowLoadMore(currentPage != resp.meta.totalPages);
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchPostCommentsChats = async () => {
    const resp = await getPostCommentsChat(
      postId,
      userDetails.id != userId ? userId : selectedIndex.user_id,
      `page=${currentPage}`
    );

    if (!resp.error) {
      if (resp.items.length > 0) {
        setShowCommentBox(!resp.items[0].to_user_id.is_active);
      }
      setList(currentPage == 1 ? resp.items : list.concat(resp.items));
      setShowLoadMore(currentPage != resp.meta.totalPages);
      setCurrentPage(currentPage + 1);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchPostCommentsChatsAuto = async () => {
    const resp = await getPostCommentsChat(
      postId,
      userDetails.id != userId ? userId : selectedIndex.user_id,
      `page=1`
    );

    if (!resp.error) {
      if (
        resp.items &&
        resp.items.length > 0 &&
        (list.length == 0 ||
          (list.length > 0 && resp.items[0].id != list[0].id))
      ) {
        setList(resp.items);
        // setShowLoadMore(currentPage != resp.meta.totalPages);
        // setCurrentPage(currentPage + 1);
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            size='12'
            sizeLg={showMessanger ? '9' : '12'}
            sizeMd={showMessanger ? '9' : '12'}
          >
            <div>
              {showCommentBox && (
                <>
                  <div className='card-comments-section'>
                    <ProfileImage
                      userInitial={getUserInitial(userDetails.firstname)}
                      customClass='card-comment-profile-img mr-10'
                      imgPath={
                        userDetails.profile_pic_obj
                          ? getFormattedUrl(userDetails.profile_pic_obj.uri)
                          : undefined
                      }
                    />
                    <Trans
                      id='add_comment'
                      render={() => (
                        <IonTextarea
                          className='card-comment-text'
                          placeholder={t({
                            id: 'add_comment',
                            message: 'Add a comment...',
                          })}
                          value={commentText}
                          rows={1}
                          cols={1}
                          autoGrow
                          onIonChange={(e: any) => {
                            setCommentText(e.detail.value);
                          }}
                        ></IonTextarea>
                      )}
                    >
                      Add a comment...
                    </Trans>
                  </div>
                  <div className='submit-btn-wrapper'>
                    <ButtonComponent
                      name={'Submit'}
                      textKey={'submit'}
                      customClass='mt-10 submit-btn'
                      disabled={isSubmmiting || commentText.trim().length == 0}
                      onClick={async () => {
                        setIsSubmmiting(true);
                        if (onSubmit) {
                          const resp = await onSubmit(commentText);
                          if (resp) {
                            let sortedList = [resp, ...list];
                            if (maxCount && maxCount < sortedList.length) {
                              sortedList = sortedList.splice(0, maxCount);
                            }

                            if (updateComment) {
                              updateComment(sortedList, postId);
                            }
                            setList(sortedList);
                            setCurrentPage(1);
                            setShowLoadMore([resp, ...list].length > 2);
                          }
                        }

                        setIsSubmmiting(false);
                        setCommentText('');
                      }}
                    />
                  </div>
                </>
              )}
              {commentList && commentList.length > 0 && (
                <>
                  <div>
                    <div className='flex mt-10'>
                      <div>
                        {list.map((comment: any) => {
                          return (
                            <CommentSectionItem
                              key={comment.id}
                              cutomerName={
                                comment.user.id == userDetails.id
                                  ? `${userDetails.firstname} ${
                                      userDetails.lastname || ''
                                    }`
                                  : comment.user.firstname
                                  ? `${comment.user.firstname} ${
                                      comment.user.lastname || ''
                                    }`
                                  : 'Anonymous'
                              }
                              time={`${t({
                                id: 'about',
                                message: 'about',
                              })} ${formatDateToSingleChar(
                                moment(comment.created_at).fromNow()
                              )}`}
                              text={comment.text}
                              profilePic={
                                comment.user.id == userDetails.id
                                  ? userDetails.profile_pic_obj
                                    ? userDetails.profile_pic_obj.uri
                                    : null
                                  : comment.user.profile_pic_obj
                                  ? comment.user.profile_pic_obj.uri
                                  : null
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                    {showLoadMore && (
                      <>
                        <div className='divider mt-10'></div>
                        <div
                          className='load-more-btn font-aktiv-grotest-medium mt-12'
                          onClick={() => {
                            if (!isPrivateChat) {
                              loadMoreComments();
                            } else {
                              fetchPostCommentsChats();
                            }
                          }}
                        >
                          {t({ id: 'load_more', message: 'Load more' })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {showMessanger && (
                <IonFab
                  vertical='bottom'
                  horizontal='end'
                  edge
                  slot='fixed'
                  style={{ right: -30 }}
                  className='messengericon'
                >
                  <IonFabButton
                    size='small'
                    onClick={() => {
                      isModalVisible = true;
                      setShowModal(true);
                    }}
                  >
                    <IonImg src={MessengerIcon}></IonImg>
                  </IonFabButton>
                </IonFab>
              )}
            </div>
          </IonCol>
          {showMessanger && (
            <IonCol
              size='12'
              sizeLg='3'
              sizeMd='3'
              className='messengers-container mt-5'
            >
              <Messengers
                messengerList={messengerList}
                selectedUser={selectedIndex}
                onSelectUser={async (selectedUser: any) => {
                  setShowCommentBox(selectedUser.user_is_active);
                  setSelectedIndex(selectedUser);
                  setShowLoadMore(true);
                  setCurrentPage(1);
                  if (onSelectUser) {
                    const resp = await onSelectUser(selectedUser);
                    if (resp) {
                      let sortedList = resp.items;
                      if (maxCount && maxCount < sortedList.length) {
                        sortedList = sortedList.splice(0, maxCount);
                      }

                      if (updateComment) {
                        updateComment(sortedList, postId);
                      }
                      setList(sortedList);
                    }
                  }
                }}
              />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
      <IonModal
        isOpen={showModal}
        initialBreakpoint={1}
        breakpoints={[1]}
        className='ion-modal'
        onDidDismiss={() => {
          setShowModal(false);
          isModalVisible = false;
        }}
      >
        <IonContent className='ion-padding comment'>
          <div
            style={{
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setShowModal(false);
                isModalVisible = false;
              }}
            >
              <IonImg
                className='login-otp-back-img mr-10'
                src={LogInBack}
              ></IonImg>
              <h3 className='ml-5'>
                {t({ id: 'messenger', message: 'Messenger' })}
              </h3>
            </div>
          </div>
          <div className='divider mb-5'></div>
          <IonList style={{ backgroundColor: 'white' }}>
            {messengerList &&
              messengerList.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      marginBottom: 5,
                      cursor: 'pointer',
                      backgroundColor:
                        selectedIndex.user_id == item.user_id
                          ? '#F2F2F2'
                          : 'unset',
                    }}
                    onClick={async () => {
                      setShowCommentBox(item.user_is_active);
                      setSelectedIndex(item);
                      setShowLoadMore(true);
                      setCurrentPage(1);
                      if (onSelectUser) {
                        const resp = await onSelectUser(item);
                        if (resp) {
                          let sortedList = resp.items;
                          if (maxCount && maxCount < sortedList.length) {
                            sortedList = sortedList.splice(0, maxCount);
                          }

                          if (updateComment) {
                            updateComment(sortedList, postId);
                          }
                          setList(sortedList);
                        }
                      }
                      setShowModal(false);
                      isModalVisible = false;
                    }}
                  >
                    {/* <div className='messenger-title font-aktiv-grotest-medium'>Messenger</div> */}
                    <div className='messenger-flex messenger'>
                      <ProfileImage
                        userInitial={getUserInitial(
                          item.user_firstname || 'Anonymous'
                        )}
                        imgPath={
                          item.user_profile_pic_obj
                            ? getFormattedUrl(
                                JSON.parse(item.user_profile_pic_obj).uri
                              )
                            : undefined
                        }
                        customClass='messenger-card-profile-img mr-8 ml-3'
                      />
                      <div className='messenger-text-wrapper mr-24'>
                        <div className='messenger-card-profile-name font-aktiv-grotest-bold mr-10px'>
                          {item.user_firstname && item.user_is_active
                            ? `${toFirstUpperCaseWord(item.user_firstname)} ${
                                item.user_lastname
                                  ? toFirstUpperCaseWord(
                                      item.user_lastname || ''
                                    )
                                  : ''
                              }`
                            : 'Anonymous'}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CommentSection;
