/* eslint-disable no-unused-vars */
import './style.scss';
import React from 'react';
import { IonButton, IonChip, IonIcon, IonLabel } from '@ionic/react';
import { close } from 'ionicons/icons';
import { t } from '@lingui/macro';

interface Props {
  label: string;
  type: string;
  onRemove: (value: string, type: string) => void;
}

const FilterChip: React.FC<Props> = ({ label, type, onRemove }) => {
  return (
    <>
      <div className=' mb-8 mr-8'>
        <IonChip className='ion-chip-filter m-0 pr-7'>
          <IonLabel className='filter-chip-label'>
            {t({ id: label, message: label })}
          </IonLabel>
          <IonButton
            className='filter-chip-close-btn'
            onClick={() => {
              onRemove(label, type);
            }}
          >
            <IonIcon className='filter-chip-close ml-10' icon={close} />
          </IonButton>
        </IonChip>
      </div>
    </>
  );
};

export default FilterChip;
