/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonImg,
  IonItem,
  IonPopover,
  IonRow,
  IonTextarea,
  useIonViewWillLeave,
} from '@ionic/react';
import ProfileImage from '../profileimg';
import CDPlaceholder from '../../assets/images/cd-placeholder.png';
import NoPreviewPlacholder from '../../assets/images/no_preview.jpg';
import Like from '../../assets/images/icons/like.svg';
import LikeBlue from '../../assets/images/icons/unlike.svg';
import Comment from '../../assets/images/icons/comment.svg';
import Share from '../../assets/images/icons/Share.svg';
import View from '../../assets/images/icons/eye.svg';
import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';
import CommentSection from '../commentsection';
import {
  formatDateToSingleChar,
  getDynamicLink,
  getFormattedUrl,
  getKeyFromString,
  getLanguageText,
  getUserInitial,
  isWeb,
  socialSharing,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import { AppContext } from '../../context/app.context';
import moment from 'moment';
import { useHistory } from 'react-router';
import { getDynamicLinkForWeb } from '../../services/firebase.service';
import VideoPlayer from '../videoplayer';
import ShareBox from '../sharebox';
import RespondBox from '../respondbox';
import observerService from '../../services/observer.service';
import MoreIcon from '../../assets/images/icons/More-icon.png';
import DeleteBox from '../deletebox';
import { deletePost } from '../../services/post.service';
import Messages from '../../config/messages';
interface Props {
  postUserId?: number;
  postId: number;
  imgPath?: string;
  videoPath?: string;
  customClass?: string;
  isLiked?: boolean;
  likeCount?: number;
  viewCount?: number;
  farmerName: string;
  buying?: boolean;
  farmerType?: string;
  productName?: any;
  location?: string;
  productCategory?: any;
  productVarietyBreed?: string;
  productQuantity?: string;
  productUnit?: any;
  expectedPrice?: string;
  logisticRequired?: boolean;
  productType?: string;
  postCardContent?: string;
  isContentOnly?: boolean;
  showLogistic?: boolean;
  farmerProfilePic: string;
  commentList?: any;
  commentsCount?: number;
  postTime?: string;
  postStatus?: string;
  isActive?: boolean;
  rejectReason?: string;
  postType?: string;
  showRespondBtn?: boolean;
  extendedAt?: string;
  extended?: boolean;
  showComments?: boolean;
  showInteraction?: boolean;
  onSubmit?: (commentText: string, postId: number) => Promise<any>;
  onLike?: (isLiked: boolean, postId: number) => Promise<any>;
  updateComment?: (commentList: any, postId: any) => void;
  onRespondSubmit?: (
    commentText: string,
    postId: number,
    toUserId: number
  ) => Promise<any>;
  onPostDelete?: (postId: number) => void;
}
let isRespondVisible = false,
  isOptionVisible = false,
  isDeleteVisible = false;
const PostCard: React.FC<Props> = ({
  postUserId,
  postId,
  customClass,
  isLiked,
  likeCount,
  viewCount,
  imgPath,
  videoPath,
  farmerName,
  buying,
  farmerType,
  productName,
  location,
  productCategory,
  productVarietyBreed,
  productQuantity,
  productUnit,
  expectedPrice,
  logisticRequired,
  productType,
  postCardContent,
  isContentOnly,
  showLogistic,
  farmerProfilePic,
  commentList,
  commentsCount,
  postTime,
  postStatus,
  isActive,
  rejectReason,
  postType,
  showRespondBtn,
  extended,
  extendedAt,
  showComments,
  showInteraction,
  onSubmit,
  onLike,
  updateComment,
  onRespondSubmit,
  onPostDelete,
}) => {
  // const [like, setLike] = useState(isLiked);
  const [cCount, setCCount] = useState(commentsCount || 0);
  const history = useHistory();
  const { lang, toastMessage, userDetails }: any = useContext(AppContext);
  const [isLoadingDynamicLink, setIsLoadingDynamicLink] = useState(false);
  const [dynamicLink, setDynamicLink] = useState('');
  const [popoverStateShare, setShowPopoverShare] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popoverStateRespond, setShowPopoverRepond] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popoverStateOption, setShowPopoverOption] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popoverStateDelete, setShowPopoverDelete] = useState({
    showPopover: false,
    event: undefined,
  });
  const [respondText, setRespondText]: any = useState(
    t({
      id: 'post_contact',
      message: 'Hi, I am interested in your post',
    })
  );
  const [isResponding, setIsResponding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const bottomObserver: any = useRef(null);

  const [bottomRef, setBottomRef]: any = useState(null);

  useEffect(() => {
    setObserver();
    // return () => {
    //   document.removeEventListener('ionBackButton', handleBack1);
    // };
  }, []);

  // useEffect(() => {
  //   if (
  //     popoverStateDelete.showPopover ||
  //     popoverStateOption.showPopover ||
  //     popoverStateRespond.showPopover
  //   ) {
  //     document.addEventListener('ionBackButton', handleBack1);
  //   }
  // }, [popoverStateDelete, popoverStateOption, popoverStateRespond]);

  useEffect(() => {
    if (bottomRef && bottomObserver) {
      bottomObserver.current.observe(bottomRef);
    }
    return () => {
      if (bottomRef && bottomObserver) {
        bottomObserver.current.unobserve(bottomRef);
      }
    };
  }, [bottomObserver, bottomRef]);

  // const handleBack1 = (ev: any) => {
  //   ev.detail.register(105, () => {
  //     if (isRespondVisible) {
  //       setShowPopoverRepond({ showPopover: false, event: undefined });
  //       isRespondVisible = false;
  //       document.removeEventListener('ionBackButton', handleBack1);
  //     } else if (isOptionVisible) {
  //       isOptionVisible = false;
  //       document.removeEventListener('ionBackButton', handleBack1);
  //       setShowPopoverOption({
  //         showPopover: false,
  //         event: undefined,
  //       });
  //     } else if (isDeleteVisible) {
  //       isDeleteVisible = false;
  //       document.removeEventListener('ionBackButton', handleBack1);
  //       setShowPopoverDelete({
  //         showPopover: false,
  //         event: undefined,
  //       });
  //     } else if (
  //       window.location.pathname == '/' ||
  //       window.location.pathname == INIT_PAGE
  //     ) {
  //       App.exitApp();
  //     } else {
  //       history.goBack();
  //     }
  //   });
  // };

  useIonViewWillLeave(() => {
    setShowPopoverDelete({ showPopover: false, event: undefined });
    setShowPopoverOption({ showPopover: false, event: undefined });
    setShowPopoverRepond({ showPopover: false, event: undefined });
  }, []);

  const setObserver = () => {
    const bottom = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          if (!entry.isIntersecting && entry.target.dataset.type == 'video') {
            observerService.handleEvents('video-pause');
          }
        });
      },
      { threshold: 0.1 }
    );
    bottomObserver.current = bottom;
  };

  const handleFavClick = async (isLiked: boolean, postId: number) => {
    // if (isLiked) {
    //   setLCount(lCount + 1);
    // } else {
    //   setLCount(Math.max(0, lCount - 1));
    // }
    // setLike(isLiked);
    if (onLike) {
      await onLike(isLiked, postId);
    }
  };

  return (
    <div className={`post-card-wrapper ${customClass || ''}`}>
      <IonCard className='post-card p-16 m-0 mb-24'>
        <IonItem className='post-card-header mb-16'>
          <ProfileImage
            userInitial={getUserInitial(farmerName || 'Anonymous')}
            customClass='post-card-profile-img'
            imgPath={
              farmerProfilePic ? getFormattedUrl(farmerProfilePic) : undefined
            }
          />
          <div className='profile-details ml-10'>
            <h1 className='post-profile-name mb-5'>
              <span className='farmer-name mr-5 font-aktiv-grotest-bold'>
                {toFirstUpperCaseWord(farmerName || 'Anonymous')}
              </span>
              {!isContentOnly && (
                <>
                  {buying ? (
                    <span className='profile-category-buy font-aktiv-grotest-regular'>
                      {t({
                        id: 'buyer',
                        message: 'Demand',
                      })}
                    </span>
                  ) : (
                    <span className='profile-category-sell font-aktiv-grotest-regular'>
                      {t({
                        id: 'seller',
                        message: 'Supply',
                      })}
                    </span>
                  )}
                </>
              )}
            </h1>
            <p className='post-farmer-location'>
              <span className='farmer-type mr-5'>
                {t({
                  id: getKeyFromString(farmerType || ''),
                  message: farmerType,
                })}{' '}
                -
              </span>
              <span className='farmer-location'>{location}</span>
            </p>
          </div>
          <div className='post-card-time-wrapper'>
            <div className='card-time'>
              {formatDateToSingleChar(moment(postTime).fromNow())}
            </div>
            {postStatus == 'pending' && userDetails.id == postUserId && (
              <IonImg
                src={MoreIcon}
                className='card-more-icon cursor'
                onClick={(e: any) => {
                  isOptionVisible = true;
                  setShowPopoverOption({ showPopover: true, event: e });
                  // document.addEventListener('ionBackButton', handleBack1);
                }}
              />
            )}
          </div>
        </IonItem>
        <IonCardContent
          onClick={() => {
            if ((isContentOnly && !videoPath && imgPath) || !isContentOnly) {
              history.push(`/commoditydetail/${postId}`);
            }
          }}
          className={`card-content-wrapper p-0 mb-24 card-content ${
            (isContentOnly && !videoPath && imgPath) || !isContentOnly
              ? ''
              : 'cursor-unset'
          }`}
          style={{
            maxHeight:
              isContentOnly && !videoPath && imgPath ? '400px' : 'auto',
          }}
        >
          {isContentOnly ? (
            <>
              {videoPath ? (
                <div
                  ref={setBottomRef}
                  data-type={isContentOnly && videoPath ? 'video' : 'other'}
                  className='cursor'
                >
                  {postCardContent && (
                    <p className='post-card-content mb-10'>{postCardContent}</p>
                  )}
                  <VideoPlayer
                    url={videoPath}
                    thumbnail={imgPath ? getFormattedUrl(imgPath) : undefined}
                  />
                </div>
              ) : imgPath ? (
                <>
                  {postCardContent && (
                    <p className='post-card-content mb-10'>{postCardContent}</p>
                  )}
                  <div className='cardImgbox'>
                    <IonImg
                      src={imgPath ? getFormattedUrl(imgPath) : CDPlaceholder}
                      className='post-card-image'
                    />
                  </div>
                </>
              ) : (
                <p className='post-card-content'>{postCardContent}</p>
              )}
              <div className='mt-20'>
                {postStatus != 'accepted' && (
                  <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'status',
                        message: 'Status',
                      })}{' '}
                      :
                    </p>
                    <p
                      className={`product-detail-disc ${
                        postStatus == 'pending'
                          ? 'post-status-pending'
                          : 'post-status-rejected'
                      }`}
                    >
                      {postStatus
                        ? toFirstUpperCaseWord(
                            t({
                              id: postStatus,
                              message: postStatus,
                            })
                          )
                        : ''}
                      {/* {toFirstUpperCaseWord(postStatus || '')} */}
                    </p>
                  </div>
                )}

                {postStatus == 'accepted' && !isActive && (
                  <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'status',
                        message: 'Status',
                      })}{' '}
                      :
                    </p>
                    <p
                      className={`product-detail-disc ${'post-status-rejected'}`}
                    >
                      {toFirstUpperCaseWord(
                        t({
                          id: 'de_activated',
                          message: 'De-activated',
                        })
                      )}
                    </p>
                  </div>
                )}

                {postStatus == 'rejected' &&
                  rejectReason &&
                  rejectReason != '' && (
                    <div className='card-product-detail'>
                      <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                        {t({
                          id: 'reject_reason',
                          message: 'Reject Reason',
                        })}{' '}
                        :
                      </p>
                      <p className='product-detail-disc'>{rejectReason}</p>
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className='post-card-with-img'>
              <IonRow className='card-with-img-wrapper'>
                <IonCol size-xs='12' size-sm='6'>
                  <IonImg
                    src={
                      imgPath
                        ? getFormattedUrl(imgPath)
                        : postType == 'buy'
                        ? NoPreviewPlacholder
                        : CDPlaceholder
                    }
                    className=' mr-20'
                  />
                </IonCol>
                <IonCol size-xs='12' size-sm='6'>
                  <h3 className='product-name font-aktiv-grotest-medium mb-5'>
                    {getLanguageText(
                      productName.title,
                      'title',
                      productName.locale,
                      lang
                    )}
                  </h3>
                  <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'category',
                        message: 'Category',
                      })}{' '}
                      :
                    </p>
                    <p className='product-detail-disc'>
                      {getLanguageText(
                        productCategory.name,
                        'name',
                        productCategory.locale,
                        lang
                      )}
                    </p>
                  </div>
                  <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'variety_breed',
                        message: 'Variety / Breed',
                      })}{' '}
                      :
                    </p>
                    <p className='product-detail-disc'>{productVarietyBreed}</p>
                  </div>
                  <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'quantity_unit',
                        message: 'Quantity & Unit',
                      })}{' '}
                      :
                    </p>
                    <p className='product-detail-disc'>
                      <span>{productQuantity}</span> <span>{productUnit}</span>
                    </p>
                  </div>
                  {/* <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'price_expected_ex_farm',
                        message: 'Price Expected Ex-Farm',
                      })}{' '}
                      :
                    </p>
                    <p className='product-detail-disc'>
                      <span>₹ {expectedPrice}/- </span>{' '}
                      <span>{productUnit}</span>
                    </p>
                  </div> */}
                  {showLogistic && (
                    <div className='card-product-detail'>
                      <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                        {t({
                          id: 'logistic_required',
                          message: 'Logistic required',
                        })}{' '}
                        :
                      </p>
                      <p className='product-detail-disc'>
                        {!logisticRequired ? (
                          <span>
                            {t({
                              id: 'no',
                              message: 'No',
                            })}
                          </span>
                        ) : (
                          <span>
                            {t({
                              id: 'yes',
                              message: 'Yes',
                            })}
                          </span>
                        )}
                      </p>
                    </div>
                  )}
                  <div className='card-product-detail'>
                    <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                      {t({
                        id: 'type',
                        message: 'Type',
                      })}{' '}
                      :
                    </p>
                    <p className='product-detail-disc'>
                      {productType
                        ? toFirstUpperCaseWord(
                            t({ id: productType, message: productType })
                          )
                        : ''}
                    </p>
                  </div>
                  {userDetails.id == postUserId &&
                    isActive &&
                    postStatus == 'accepted' &&
                    postType != 'general' &&
                    !extended && (
                      <div className='card-product-detail'>
                        <div className='product-detail-disc mt-10 mb-10'>
                          <p>
                            {t({
                              id: 'post_expiry_msg',
                              message: 'Your post will get expired on ',
                            })}
                            {extendedAt &&
                              moment(extendedAt).format('DD/MM/yyyy')}
                          </p>
                        </div>
                      </div>
                    )}
                  {postStatus != 'accepted' && (
                    <div className='card-product-detail'>
                      <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                        {t({
                          id: 'status',
                          message: 'Status',
                        })}{' '}
                        :
                      </p>
                      <p
                        className={`product-detail-disc ${
                          postStatus == 'pending'
                            ? 'post-status-pending'
                            : 'post-status-rejected'
                        }`}
                      >
                        {postStatus
                          ? toFirstUpperCaseWord(
                              t({
                                id: postStatus,
                                message: postStatus,
                              })
                            )
                          : ''}
                      </p>
                    </div>
                  )}

                  {postStatus == 'accepted' && !isActive && (
                    <div className='card-product-detail'>
                      <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                        {t({
                          id: 'status',
                          message: 'Status',
                        })}{' '}
                        :
                      </p>
                      <p
                        className={`product-detail-disc ${'post-status-rejected'}`}
                      >
                        {toFirstUpperCaseWord(
                          t({
                            id: 'de_activated',
                            message: 'De-activated',
                          })
                        )}
                      </p>
                    </div>
                  )}

                  {postStatus == 'rejected' &&
                    rejectReason &&
                    rejectReason != '' && (
                      <div className='card-product-detail'>
                        <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                          {t({
                            id: 'reject_reason',
                            message: 'Reject Reason',
                          })}{' '}
                          :
                        </p>
                        <p className='product-detail-disc'>{rejectReason}</p>
                      </div>
                    )}

                  {!isContentOnly &&
                    isActive &&
                    postStatus == 'accepted' &&
                    showRespondBtn && (
                      <ButtonComponent
                        outline
                        name={buying ? 'Contact' : 'Contact'}
                        textKey={buying ? 'contact' : 'contact'}
                        customClass='post-card-respond mt-5'
                        onClick={(e) => {
                          e.stopPropagation();
                          isRespondVisible = true;
                          setShowPopoverRepond({
                            showPopover: true,
                            event: e,
                          });
                        }}
                      />
                    )}
                </IonCol>
              </IonRow>
            </div>
          )}
        </IonCardContent>
        {showInteraction && isActive && postStatus == 'accepted' && (
          <>
            <IonRow className='card-reaction-wrapper p-12 mb-16'>
              <IonCol className='card-reaction-details p-0 card-reaction-like '>
                <div
                  className='flex cursor'
                  onClick={() => {
                    handleFavClick(!isLiked, postId);
                  }}
                >
                  <IonImg
                    src={isLiked ? LikeBlue : Like}
                    className='card-reaction-icon'
                  />
                  <div className='card-reaction-label font-aktiv-grotest-bold ml-8 mt-2 cursor'>
                    {!isLiked ? (
                      <>
                        {likeCount && likeCount > 0 ? (
                          <span className='mr-3'>
                            {Intl.NumberFormat('en-US', {
                              notation: 'compact',
                              maximumFractionDigits: 1,
                            }).format(Math.max(0, likeCount))}
                          </span>
                        ) : null}
                        {likeCount && likeCount > 1 ? (
                          <span className='social-text'>
                            {t({
                              id: 'likes',
                              message: 'Likes',
                            })}
                          </span>
                        ) : (
                          <span className='social-text'>
                            {t({
                              id: 'like',
                              message: 'Like',
                            })}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <div className='social-text'>
                          <span className='mr-3 social-text'>
                            {t({
                              id: 'you',
                              message: 'You',
                            })}
                          </span>{' '}
                          {likeCount && likeCount - 1 > 0 && (
                            <>
                              <span className='mr-3 social-text'>
                                {t({
                                  id: 'and',
                                  message: 'and',
                                })}
                              </span>
                              <span className='mr-3'>
                                {Intl.NumberFormat('en-US', {
                                  notation: 'compact',
                                  maximumFractionDigits: 1,
                                }).format(Math.max(0, likeCount - 1))}
                              </span>
                              <span className='social-text'>
                                {t({
                                  id: 'others',
                                  message: 'others',
                                })}
                              </span>
                            </>
                          )}
                        </div>
                        <div className='mobile-social-text'>
                          {likeCount && likeCount > 0 && (
                            <>
                              <span className='mr-3'>
                                {Intl.NumberFormat('en-US', {
                                  notation: 'compact',
                                  maximumFractionDigits: 1,
                                }).format(Math.max(0, likeCount))}
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </IonCol>
              {postType && postType == 'general' && (
                <IonCol className='card-reaction-details p-0 card-reaction-comment ml-20'>
                  <IonImg
                    src={Comment}
                    className='card-reaction-icon mt-5 cursor-unset'
                  />
                  <div className='card-reaction-label font-aktiv-grotest-bold ml-8 unselectable'>
                    {cCount > 0 && (
                      <span>
                        {Intl.NumberFormat('en-US', {
                          notation: 'compact',
                          maximumFractionDigits: 1,
                        }).format(Math.max(0, cCount))}{' '}
                      </span>
                    )}
                    <span className='social-text'>
                      {t({
                        id: 'comments',
                        message: 'Comments',
                      })}
                    </span>
                  </div>
                </IonCol>
              )}

              {/* {postType && postType != 'general' && (
                <IonCol className='card-reaction-details p-0 card-reaction-comment ml-20'>
                  <IonImg src={View} className='card-reaction-icon' />
                  <div className='card-reaction-label font-aktiv-grotest-bold ml-8'>
                    {viewCount && viewCount > 0 && (
                      <span>
                        {Intl.NumberFormat('en-US', {
                          notation: 'compact',
                          maximumFractionDigits: 1,
                        }).format(Math.max(0, viewCount))}
                      </span>
                    )}{' '}
                  </div>
                </IonCol>
              )} */}
              <IonCol className='card-reaction-details p-0 card-reaction-share'>
                <div
                  className='flex cursor'
                  onClick={async () => {
                    if (isLoadingDynamicLink) return;
                    setIsLoadingDynamicLink(true);
                    if (isWeb()) {
                      const dynamicUrl = await getDynamicLinkForWeb(
                        `commoditydetail/${postId}`
                      );

                      if (!dynamicUrl.error && dynamicUrl.shortLink) {
                        setDynamicLink(dynamicUrl.shortLink);
                        setShowPopoverShare({
                          showPopover: true,
                          event: undefined,
                        });
                      }
                      setIsLoadingDynamicLink(false);
                    } else {
                      const dynamicUrl = await getDynamicLink(
                        `commoditydetail/${postId}`
                      );
                      if (dynamicUrl) {
                        socialSharing(
                          'Visit my post from Covestro',
                          undefined,
                          undefined,
                          dynamicUrl
                        );
                      }
                      setIsLoadingDynamicLink(false);
                    }
                  }}
                >
                  <IonImg src={Share} className='card-reaction-icon' />
                  <div className='card-reaction-label font-aktiv-grotest-bold ml-8 social-text cursor'>
                    {t({
                      id: 'share',
                      message: 'Share',
                    })}
                  </div>
                </div>
              </IonCol>
            </IonRow>
            {showComments && postType && postType == 'general' && (
              <div className='mt-10'>
                <CommentSection
                  postId={postId}
                  maxCount={2}
                  commentList={commentList || []}
                  isLoadMore={cCount > 2}
                  onSubmit={async (commentText: string) => {
                    if (onSubmit) {
                      const resp = await onSubmit(commentText, postId);
                      if (resp) {
                        setCCount(Math.max(0, cCount + 1));
                      }
                      return resp;
                    } else {
                      return false;
                    }
                  }}
                  updateComment={(commentList: any, postId: any) => {
                    if (updateComment) {
                      updateComment(commentList, postId);
                    }
                  }}
                />
              </div>
            )}
          </>
        )}
      </IonCard>
      <IonPopover
        event={popoverStateShare.event}
        isOpen={popoverStateShare.showPopover}
        onDidDismiss={() => {
          setShowPopoverShare({ showPopover: false, event: undefined });
          setDynamicLink('');
        }}
        className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <ShareBox
          url={dynamicLink}
          onShareClose={() => {
            setShowPopoverShare({ showPopover: false, event: undefined });
            setDynamicLink('');
          }}
        />
      </IonPopover>
      <IonPopover
        event={popoverStateRespond.event}
        isOpen={popoverStateRespond.showPopover}
        onDidDismiss={() => {
          isRespondVisible = false;
          setShowPopoverRepond({ showPopover: false, event: undefined });
          setRespondText(
            t({
              id: 'post_contact',
              message: 'Hi, I am interested in your post',
            })
          );
        }}
        className='img-popover modal-style'
        backdropDismiss={false}
      >
        <RespondBox
          isSubmitting={isResponding}
          respondText={respondText}
          onRepondChange={(text) => {
            setRespondText(text);
          }}
          onRespondCancel={() => {
            isRespondVisible = false;
            setShowPopoverRepond({ showPopover: false, event: undefined });
            setRespondText(
              t({
                id: 'post_contact',
                message: 'Hi, I am interested in your post',
              })
            );
          }}
          onRespondSubmit={async () => {
            if (onRespondSubmit && postUserId) {
              setIsResponding(true);
              const resp = await onRespondSubmit(
                respondText,
                postId,
                postUserId
              );
              if (resp) {
                isRespondVisible = false;
                setShowPopoverRepond({ showPopover: false, event: undefined });
                setRespondText(
                  t({
                    id: 'post_contact',
                    message: 'Hi, I am interested in your post',
                  })
                );
              }
              setIsResponding(false);
            }
          }}
        />
      </IonPopover>
      <IonPopover
        event={popoverStateOption.event}
        isOpen={popoverStateOption.showPopover}
        onDidDismiss={() => {
          isOptionVisible = false;
          setShowPopoverOption({ showPopover: false, event: undefined });
        }}
        alignment='end'
        dismissOnSelect
        showBackdrop={false}
        // className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <div
          className='font-aktiv-grotest-regular cursor p-10 edit-option'
          onClick={() => {
            history.push(`/editpost/${postId}`);
          }}
        >
          {t({ id: 'edit', message: 'Edit' })}
        </div>
        <div
          className='font-aktiv-grotest-regular cursor p-10 edit-option'
          onClick={() => {
            isDeleteVisible = true;
            setShowPopoverDelete({ showPopover: true, event: undefined });
            // document.addEventListener('ionBackButton', handleBack1);
          }}
        >
          {t({ id: 'delete', message: 'Delete' })}
        </div>
      </IonPopover>
      <IonPopover
        event={popoverStateDelete.event}
        isOpen={popoverStateDelete.showPopover}
        onDidDismiss={() => {
          isDeleteVisible = false;
          setShowPopoverDelete({ showPopover: false, event: undefined });
        }}
        alignment='end'
        dismissOnSelect
        // className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <DeleteBox
          isSubmitting={isDeleting}
          onNegativeClick={() => {
            isDeleteVisible = false;
            setShowPopoverDelete({ showPopover: false, event: undefined });
          }}
          onPositiveClick={async () => {
            setIsDeleting(true);
            const resp = await deletePost(postId);
            if (!resp.error) {
              onPostDelete && onPostDelete(postId);
              toastMessage(Messages.POST_DELETE_MSG);
            } else {
              toastMessage(Messages.SERVER_ERROR, 'danger');
            }
            isDeleteVisible = false;
            setShowPopoverDelete({ showPopover: false, event: undefined });
            setIsDeleting(false);
          }}
        />
      </IonPopover>
    </div>
  );
};

export default PostCard;
