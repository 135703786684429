/* eslint-disable react/no-children-prop */
import { IonCol, IonImg, IonRow } from '@ionic/react';
import './style.scss';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
interface Props {
  imageClass?: string;
  imagePath: string;
  title?: string | null;
  description?: string;
  titleTag?: string | null;
  titleCustomClass?: string | null;
  descCustomClass?: string;
  descTag?: string | null;
}

const HalfWidthImageText: React.FC<Props> = (props: Props) => {
  const {
    imageClass,
    imagePath,
    title,
    titleTag,
    titleCustomClass,
    description,
    descCustomClass,
  } = props;
  const TitleTag = titleTag ? (titleTag as keyof JSX.IntrinsicElements) : 'h3';
  return (
    <div className='image-text mb-10'>
      <IonRow className='image-text__row'>
        <IonCol size-xs='12' size-sm='6' className='image-text__img'>
          <IonImg src={imagePath} className={imageClass} />
        </IonCol>
        <IonCol size-xs='12' size-sm='6' className='image-text__copy'>
          {title && (
            <TitleTag
              className={`font-aktiv-grotest-medium mb-5 image-text__copy__heading ${titleCustomClass}`}
            >
              {title}
            </TitleTag>
          )}
          {description && (
            <ReactMarkdown
              children={description}
              className={`image-text__copy__description ${descCustomClass}`}
              remarkPlugins={[remarkGfm, remarkBreaks]}
            />
          )}
          {/* <div className='image-text__copy__btn'><ButtonComponent
              name={'Button'}
              textKey={'button'}
              outline
              customClass=''
            /></div> */}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default HalfWidthImageText;
