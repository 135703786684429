import { IonImg } from '@ionic/react';
import { getFormattedUrlCMS } from '../../../utils/common.helper';
import Placeholder from '../../../assets/images/cd-placeholder.png';
import './style.scss';

interface Props {
  url: string;
}
const Logo: React.FC<Props> = (props: Props) => {
  const { url } = props;

  return (
    <div>
      <IonImg
        className='logo'
        src={getFormattedUrlCMS(`${url}`) || Placeholder}
      />
    </div>
  );
};

export default Logo;
