import './style.scss';
import React from 'react';
import { getKeyFromString } from '../../utils/common.helper';
import { t } from '@lingui/macro';

interface Props {
  label?: string;
  bgColor?: string;
}

const Tag: React.FC<Props> = (props: Props) => {
  const { label, bgColor } = props;
  return (
    <span className='tag-wrapper' style={{ backgroundColor: bgColor }}>
      {t({
        id: getKeyFromString(label || ''),
        message: label,
      })}
    </span>
  );
};

export default Tag;
