interface Props {
  title?: string | null;
  titleTag?: string | null;
  titleCustomClass?: string | null;
}
const Title: React.FC<Props> = (props: Props) => {
  const { title, titleTag, titleCustomClass } = props;
  const TitleTag = titleTag ? (titleTag as keyof JSX.IntrinsicElements) : 'div';

  return <TitleTag className={titleCustomClass}>{title}</TitleTag>;
};

export default Title;
