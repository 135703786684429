import './style.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonRadioGroup,
  IonTextarea,
} from '@ionic/react';
import DropDown from '../../components/dropdown';
import { t, Trans } from '@lingui/macro';
import InputText from '../../components/inputtext';
import CircularRadio from '../../components/circular-radio';
import ButtonComponent from '../../components/button-component';
import { Form, Formik } from 'formik';
import { SellRequirementSchema } from '../../schemas/buy.schema';
import ValidationMessage from '../../components/validationMessage';
import FocusError from '../../components/focus-error';
import Breadcrumbs from '../../components/breadcrumbs';
import UploadComponent from '../../components/dragdrop';
import {
  getDataForDropDown,
  getFormattedUrl,
  getLanguageText,
  isWeb,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import AutocompleteInput from '../../components/autocompleteinput';
import {
  getLocationByPincode,
  getLocationList,
} from '../../services/location.service';
import { AppContext } from '../../context/app.context';
import { getCommodityListByFilter } from '../../services/commodity.service';
import Messages from '../../config/messages';
import {
  getCertificateList,
  getWeightUnitList,
} from '../../services/master.service';
import { submitPost, updatePost } from '../../services/post.service';
import { uploadFileWeb } from '../../services/filetransfer.service';
import { useHistory } from 'react-router';

interface Props {
  showBred?: boolean;
  fromHome?: boolean;
  data?: any;
}
const SellRequirement: React.FC<Props> = ({ showBred, fromHome, data }) => {
  const history = useHistory();
  let interval: any = null;
  const { setLoading, toastMessage, lang, userDetails }: any =
    useContext(AppContext);
  const [pincodeList, setPincodeList] = useState([]);
  const [villageOrLocality, setVillageOrLocality] = useState([]);
  const [villageSuggestions, setVillageSuggestions] = useState([]);
  const [commodityList, setCommodityList] = useState([]);
  const [filterCommodityList, setFilteredCommodityList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [imageIndex, setImageIndex] = useState(1);
  const [error] = React.useState(null);
  const [logisticSelected, setLogisticSelected] = useState(
    data && data.is_logistic_required != null
      ? data.is_logistic_required
        ? 'Yes'
        : 'No'
      : ''
  );
  const [typeSelected, setTypeSelected] = useState(
    data && data.type ? toFirstUpperCaseWord(data.type) : ''
  );
  const [imageError, setImageError] = useState(true);
  const [imageErrorMsg, setImageErrorMsg] = useState('');

  const [initialValues, setInitialValues]: any = useState({
    commodity:
      data && data.commodity_id
        ? getLanguageText(
            data.commodity_id.title,
            'title',
            data.commodity_id.locale,
            lang
          )
        : '',
    commodityId: data && data.commodity_id ? data.commodity_id.id : null,
    category:
      data && data.category_id
        ? getLanguageText(
            data.category_id.name,
            'name',
            data.category_id.locale,
            lang
          )
        : '',
    categoryId: data && data.category_id ? data.category_id.id : null,
    varietyOrBreed: data && data.variety_breed ? data.variety_breed : '',
    type: data && data.type ? data.type : '',
    pinCode:
      data && data.location_id
        ? data.location_id.pincode
        : userDetails.locationDetail.pincode,
    quantity: data && data.quantity ? data.quantity : '',
    unit:
      data && data.weightunit_id
        ? getLanguageText(
            data.weightunit_id.unit_name,
            'name',
            data.weightunit_id.locale,
            lang
          )
        : '',
    unitId: data && data.weightunit_id ? data.weightunit_id.id : 0,
    state:
      data && data.location_id
        ? data.location_id.state
        : userDetails.locationDetail.state,
    district:
      data && data.location_id
        ? data.location_id.district
        : userDetails.locationDetail.district,
    villageOrLocality:
      data && data.location_id
        ? data.location_id.locality_name
        : userDetails.locationDetail.locality_name,
    geoTag: '',
    price_expected: data && data.price ? data.price : '',
    logistic:
      data && data.is_logistic_required != null
        ? data.is_logistic_required
          ? 'yes'
          : 'no'
        : '',
    certification: data && data.certification_id ? data.certification_id : [],
    other_description:
      data && data.other_description ? data.other_description : '',
    locationId:
      data && data.location_id
        ? data.location_id.id
        : userDetails.locationDetail.id,
    images:
      data && data.commodity_images_obj
        ? data.commodity_images_obj.map((item: any, index: number) => {
            return {
              id: item.id,
              name: `file${index + 1}`,
            };
          })
        : [],
  });

  useEffect(() => {
    const filteredCommodities = commodityList.filter((commodity) => {
      const { data }: any = commodity;
      return data.type === typeSelected;
    });
    setFilteredCommodityList(filteredCommodities);
  }, [typeSelected]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchCommodityList(),
      fetchCertificateList(),
      fetchUnitList(),
      getPincodeDetailsOnLoad(userDetails.locationDetail.pincode),
    ]).finally(() => {
      setLoading(false);
    });
  }, []);

  async function getPincodeDetailsOnLoad(pincode: string) {
    const resp: any = await getLocationByPincode(pincode);
    if (!resp.error && resp.items.length > 0) {
      const data = getDataForDropDown(resp.items, 'locality_name');
      setVillageOrLocality(data);
      setVillageSuggestions(data.map((item: any) => item.name));
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  const fetchCommodityList = async () => {
    let resp: any = await getCommodityListByFilter('all');
    if (!resp.error) {
      resp = resp.map((item: any) => {
        item.title = getLanguageText(item.title, 'title', item.locale, lang);
        return item;
      });
      setCommodityList(commodityList.concat(getDataForDropDown(resp, 'title')));
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchCertificateList = async () => {
    let resp: any = await getCertificateList();
    if (!resp.error) {
      resp = resp.map((item: any) => {
        item.certificate_name = getLanguageText(
          item.certificate_name,
          'name',
          item.locale,
          lang
        );
        return item;
      });
      setCertificateList(
        certificateList.concat(getDataForDropDown(resp, 'certificate_name'))
      );
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchUnitList = async () => {
    let resp: any = await getWeightUnitList();
    if (!resp.error) {
      resp = resp.map((item: any) => {
        item.unit_name = getLanguageText(
          item.unit_name,
          'name',
          item.locale,
          lang
        );
        return item;
      });
      setUnitList(unitList.concat(getDataForDropDown(resp, 'unit_name')));
      if (!data || !data.weightunit_id) {
        initialValues.unit = resp[0].unit_name;
        initialValues.unitId = resp[0].id;
        setInitialValues(initialValues);
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onTypeRadioChange = (e: any) => {
    const type = e.detail.value;
    setTypeSelected(type);
  };

  const onLogisticRadioChange = (e: any) => {
    const logisticRequired = e.detail.value;
    setLogisticSelected(logisticRequired);
  };

  const SellRequirementSchemaMemo: any = useMemo(
    () => SellRequirementSchema(),
    []
  );

  async function getPincodeList(
    pincode: string,
    setFieldError: any,
    setFieldValue: any
  ) {
    setFieldValue('state', '');
    setFieldValue('district', '');
    setVillageOrLocality([]);
    setVillageSuggestions([]);
    setFieldValue('villageOrLocality', '');
    setFieldValue('locationId', '');
    if (pincode.trim().length >= 3) {
      const resp: any = await getLocationList(pincode);
      if (!resp.error) {
        setPincodeList(resp);
        if (resp.length == 0) {
          setFieldError('pinCode', 'No details found');
        }
      } else {
        setFieldError('pinCode', resp.message);
      }
    } else {
      setPincodeList([]);
    }
  }

  async function getPincodeDetails(pincode: string, setFieldValue: any) {
    const resp: any = await getLocationByPincode(pincode);
    if (!resp.error && resp.items.length > 0) {
      // setFieldValue('pinCode', pincode);
      setFieldValue('state', resp.items[0].state);
      setFieldValue('district', resp.items[0].district);
      const data = getDataForDropDown(resp.items, 'locality_name');
      setVillageOrLocality(data);
      setVillageSuggestions(data.map((item: any) => item.name));
      // setSelectedVillage(resp.items[0].locality_name);
      // setFieldValue('villageOrLocality', resp.items[0].locality_name);
      // setFieldError('locationId', false);
      // setFieldValue('locationId', resp.items[0].id);
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  function onFileSelect(
    name: string,
    file: any,
    setFieldValue: any,
    setFieldError: any,
    values: any
  ) {
    const fileValues: any = values.images.find(
      (item: any) => item.name == name
    );
    if (fileValues) {
      fileValues['name'] = name;
      fileValues['file'] = file;
      setFieldValue('images', values.images);
    } else {
      const fileValues = {
        name,
        file,
      };
      values.images.push(fileValues);
      setFieldValue('images', values.images);
    }
    setFieldError('images', false);
    setImageError(false);
    setImageErrorMsg('');
  }

  const handleSellRequirementDetails = async (values: any, actions: any) => {
    actions.setFieldError(undefined);
    // setLoading(true);

    const postReq: any = {
      type: values.type.toLowerCase(),
      quantity: values.quantity,
      // price: values.price_expected,
      price: 0,
      commodity_id: values.commodityId,
      category_id: values.categoryId,
      certification_id: values.certification.map((item: any) => item.id),
      weightunit_id: values.unitId,
      location_id: values.locationId,
      variety_breed: values.varietyOrBreed,
      state: values.state,
      district: values.district,
      post_type: 'sell',
      other_description: values.other_description,
      is_logistic_required: values.logistic.toLowerCase() == 'yes',
      // commodity_images:
    };
    const imagesArr = values.images;
    if (imagesArr.length > 0) {
      const promises = imagesArr.map(
        (image: any) => image.file && uploadFileWeb(image.file)
      );

      const result = await Promise.all(promises.filter((item: any) => item));

      if (result && result.length && result.length > 0) {
        if (data && data.commodity_images) {
          postReq.commodity_images = data.commodity_images
            .split(',')
            .map((item: any) => {
              return imagesArr.find(
                (image: any) => item == image.id && !image.file
              );
            });
          postReq.commodity_images = postReq.commodity_images
            .filter((item: any) => {
              if (item) {
                return item.id;
              }
            })
            .map((i: any) => i.id);
        } else {
          postReq.commodity_images = [];
        }

        postReq.commodity_images = postReq.commodity_images.concat(
          result.map((item: any) => item.id)
        );
      }
    }
    if (data) {
      postReq.id = data.id;
      const resp: any = await updatePost(postReq);
      setLoading(false);
      actions.setSubmitting(false);

      if (!resp.error) {
        history.push(`/submittedsuccessfully/edit/${data.id}`);
      } else {
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      const resp: any = await submitPost(postReq);
      setLoading(false);
      actions.setSubmitting(false);

      if (!resp.error) {
        actions.resetForm();
        history.push(`/submittedsuccessfully/${resp.id}`);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    }
  };

  return (
    <div>
      {isWeb() && showBred && (
        <div className='page-menu-list p-32 mb-24'>
          <div className='page-menu-list-inner'>
            <Breadcrumbs
              menuText={data ? 'My Corner' : 'Supply'}
              subMenuText={data ? 'Edit Requirement' : 'Add Requirement'}
              menuTextKey={data ? 'my_corner' : 'sell'}
              subMenuTextKey={data ? 'edit_requirement' : 'add_requirement'}
              menuRoute={data ? '/mycorner' : '/sell'}
            />
          </div>
        </div>
      )}
      <div className='registrtation-form-container mb-32'>
        <div className='form-container'>
          <Formik
            initialValues={initialValues}
            validationSchema={SellRequirementSchemaMemo}
            onSubmit={handleSellRequirementDetails}
            validateOnChange={false}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched,
              setFieldError,
            }) => (
              <Form className='registration-form'>
                <>
                  {fromHome ? (
                    <>
                      <div className='form__user-details'>
                        <IonGrid>
                          <IonRow className='ion-row'>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='ion-field-wrapper mb-10'>
                                <IonLabel className='ion-label ion-label-type font-aktiv-grotest-regular pl-0 pt-0'>
                                  {t({ id: 'type', message: 'Type' })}
                                  <span className='mandatory'>*</span>
                                </IonLabel>

                                <div>
                                  <IonRadioGroup
                                    name='type'
                                    value={typeSelected}
                                    onIonChange={(e: any) => {
                                      onTypeRadioChange(e);
                                      setFieldValue('type', e.detail.value);
                                      setFieldTouched('type', true);
                                    }}
                                    className='choose-type-radio'
                                  >
                                    <CircularRadio
                                      label='Fresh'
                                      textKey={'fresh'}
                                      customClass='mr-20'
                                    />
                                    <CircularRadio
                                      label='Dehydrated'
                                      textKey={'dehydrated'}
                                      customClass='mr-20'
                                    />
                                  </IonRadioGroup>
                                </div>
                              </div>
                              <ValidationMessage name={'type'} />
                            </IonCol>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <Trans
                                id='commodity'
                                render={() => (
                                  <DropDown
                                    name='commodity'
                                    labelName='Commodity'
                                    labelKey='commodity'
                                    optionList={
                                      typeSelected
                                        ? filterCommodityList
                                        : commodityList
                                    }
                                    required
                                    onChange={(item: any) => {
                                      if (values.type) {
                                        setFieldValue('commodity', item.value);
                                        setFieldTouched('commodity', true);
                                        setFieldValue('commodityId', item.id);
                                        const categoryObj: any =
                                          commodityList.find(
                                            (cItem: any) => cItem.id == item.id
                                          );
                                        if (categoryObj) {
                                          setFieldValue(
                                            'category',
                                            getLanguageText(
                                              categoryObj.data.category_id.name,
                                              'name',
                                              categoryObj.data.category_id
                                                .locale,
                                              lang
                                            )
                                          );
                                          setFieldValue(
                                            'categoryId',
                                            categoryObj.data.category_id.id
                                          );
                                          setFieldTouched('category', true);
                                        }
                                      } else {
                                        setFieldValue(
                                          'commodity',
                                          `Please Select ${new Date().getTime()}`
                                        );
                                        setFieldTouched('type', true);
                                        setFieldError(
                                          'type',
                                          'Please enter type'
                                        );
                                      }
                                    }}
                                    error={Boolean(
                                      errors.commodity && touched.commodity
                                    )}
                                    selectedItem={
                                      values.commodity ||
                                      t({
                                        id: 'please_select',
                                        message: 'Please Select',
                                      })
                                    }
                                  />
                                )}
                              >
                                Commodity
                              </Trans>
                              <ValidationMessage name={'commodity'} />
                            </IonCol>
                          </IonRow>

                          {/* row 1 complete */}

                          <IonRow className='ion-row'>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='input-text-container'>
                                <Trans
                                  id='category'
                                  render={({ translation }) => (
                                    <InputText
                                      name='category'
                                      labelName='Category'
                                      labelKey='category'
                                      placeholder={translation?.toString()}
                                      value={values.category}
                                      // onChange={(value: any) => {
                                      //   setFieldValue('category', value);
                                      //   setFieldTouched('category', true);
                                      // }}
                                      error={Boolean(
                                        errors.category && touched.category
                                      )}
                                      disabled
                                    ></InputText>
                                  )}
                                >
                                  Category
                                </Trans>
                              </div>
                              <ValidationMessage name={'category'} />
                            </IonCol>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <Trans
                                id='variety_or_breed'
                                render={({ translation }) => (
                                  <InputText
                                    name='variety'
                                    labelName='Variety or Breed'
                                    labelKey='variety_or_breed'
                                    required
                                    placeholder={translation?.toString()}
                                    value={values.varietyOrBreed}
                                    onChange={(value: any) => {
                                      setFieldValue('varietyOrBreed', value);
                                      setFieldTouched('varietyOrBreed', true);
                                    }}
                                    error={Boolean(
                                      errors.varietyOrBreed &&
                                        touched.varietyOrBreed
                                    )}
                                  ></InputText>
                                )}
                              >
                                Variety or Breed
                              </Trans>
                              <ValidationMessage name={'varietyOrBreed'} />
                            </IonCol>
                          </IonRow>

                          {/* 2nd row complete */}

                          <IonRow className='ion-row'>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='input-text-container'>
                                <Trans
                                  id='quantity'
                                  render={({ translation }) => (
                                    <InputText
                                      name='quantity'
                                      labelName='Quantity/Units'
                                      labelKey='quantity'
                                      required
                                      placeholder={translation?.toString()}
                                      maxlength={4}
                                      value={values.quantity}
                                      onChange={(value: any) => {
                                        setFieldValue('quantity', value);
                                        setFieldTouched('quantity', true);
                                      }}
                                      error={Boolean(
                                        errors.quantity && touched.quantity
                                      )}
                                      type='number'
                                      inputMode={'numeric'}
                                    ></InputText>
                                  )}
                                >
                                  Quantity Or Units
                                </Trans>
                                <Trans
                                  id='unit'
                                  render={() => (
                                    <DropDown
                                      labelName='Unit'
                                      labelKey='unit'
                                      optionList={unitList}
                                      required
                                      onChange={(item: any) => {
                                        setFieldValue('unit', item.value);
                                        setFieldValue('unitId', item.id);

                                        setFieldTouched('unit', true);
                                      }}
                                      customClass='quantity-unit'
                                      customClassTop='quantity-unit-wrapper'
                                      selectedItem={values.unit}
                                      //error={Boolean(errors.unit && touched.unit)}
                                    />
                                  )}
                                >
                                  Unit
                                </Trans>
                              </div>
                              <ValidationMessage name={'quantity'} />
                            </IonCol>

                            {/* <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='input-text-container'>
                                <Trans
                                  id='price_expected'
                                  render={({ translation }) => (
                                    <InputText
                                      name='price'
                                      labelName='Price Expected - Ex-Farm (per Unit)'
                                      required
                                      labelKey='price'
                                      placeholder={translation?.toString()}
                                      value={values.price_expected}
                                      onChange={(value: any) => {
                                        setFieldValue('price_expected', value);
                                        setFieldTouched('price_expected', true);
                                      }}
                                      error={Boolean(
                                        errors.price_expected &&
                                          touched.price_expected
                                      )}
                                      type='number'
                                      inputMode={'numeric'}
                                      suffix={values.unit}
                                    ></InputText>
                                  )}
                                >
                                  Price Expected - Ex-Farm(per Unit)
                                </Trans>
                                <ValidationMessage name={'price_expected'} />
                              </div>
                            </IonCol> */}
                          </IonRow>
                          <IonRow className='ion-row'>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='input-text-container'>
                                <Trans
                                  id='pin_code'
                                  render={({ translation }) => (
                                    <AutocompleteInput
                                      required
                                      maxlength={6}
                                      suggestions={pincodeList}
                                      onItemSelected={async (value: string) => {
                                        setFieldValue('pinCode', value);
                                        setFieldTouched('pinCode', true);
                                        await getPincodeDetails(
                                          value,
                                          setFieldValue
                                        );
                                      }}
                                      name='pinCode'
                                      labelName='Pin code'
                                      labelKey='pin_code'
                                      placeholder={'' + translation}
                                      //value={""}
                                      value={values.pinCode}
                                      onSuggestionChange={async (
                                        value: any
                                      ) => {
                                        setFieldValue('pinCode', value);
                                        setFieldTouched('pinCode', true);
                                        if (interval) {
                                          clearInterval(interval);
                                          interval = null;
                                        }
                                        interval = setInterval(async () => {
                                          await getPincodeList(
                                            value,
                                            setFieldError,
                                            setFieldValue
                                          );
                                          clearInterval(interval);
                                          interval = null;
                                        }, 500);
                                      }}
                                      error={Boolean(
                                        errors.pinCode && touched.pinCode
                                      )}
                                      type='number'
                                      // debounce={500}
                                      inputMode={'numeric'}
                                    />
                                  )}
                                >
                                  Pin code
                                </Trans>
                              </div>
                              <ValidationMessage name={'pinCode'} />
                            </IonCol>

                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='input-text-container'>
                                <Trans
                                  id='state'
                                  render={({ translation }) => (
                                    <InputText
                                      name='state'
                                      labelName='State'
                                      labelKey='state'
                                      placeholder={'' + translation}
                                      disabled
                                      value={values.state}
                                      // onChange={(value: any) => {
                                      //   setFieldValue('state', value);
                                      //   setFieldTouched('state', true);
                                      // }}
                                      error={Boolean(
                                        errors.state && touched.state
                                      )}
                                    ></InputText>
                                  )}
                                >
                                  State
                                </Trans>
                              </div>
                              <ValidationMessage name={'state'} />
                            </IonCol>
                          </IonRow>

                          <IonRow className='ion-row'>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='input-text-container'>
                                <Trans
                                  id='district'
                                  render={({ translation }) => (
                                    <InputText
                                      name='district'
                                      labelName='District'
                                      labelKey='district'
                                      placeholder={'' + translation}
                                      disabled
                                      value={values.district}
                                      // onChange={(value: any) => {
                                      //   setFieldValue('district', value);
                                      //   setFieldTouched('district', true);
                                      // }}
                                      error={Boolean(
                                        errors.district && touched.district
                                      )}
                                    ></InputText>
                                  )}
                                >
                                  District
                                </Trans>
                              </div>
                              <ValidationMessage name={'district'} />
                            </IonCol>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <Trans
                                id='village_locality'
                                render={({ translation }) => (
                                  <AutocompleteInput
                                    showSuggestionOnFocus
                                    required
                                    suggestions={villageSuggestions}
                                    onItemSelected={(value: string) => {
                                      setFieldValue('villageOrLocality', value);
                                      setFieldTouched(
                                        'villageOrLocality',
                                        false
                                      );
                                      const villageItem: any =
                                        villageOrLocality.find(
                                          (item: any) => item.name == value
                                        );
                                      if (villageItem) {
                                        setFieldTouched('locationId', false);
                                        setFieldValue(
                                          'locationId',
                                          villageItem.id
                                        );
                                      }
                                    }}
                                    name='pinCode'
                                    labelName='Village / Locality'
                                    labelKey='village_locality'
                                    placeholderKey='type_your_village'
                                    placeholderName='Type your Village/Locality'
                                    placeholder={'' + translation}
                                    //value={""}
                                    value={values.villageOrLocality}
                                    onSuggestionChange={async (value: any) => {
                                      setFieldValue('villageOrLocality', value);
                                      setFieldTouched(
                                        'villageOrLocality',
                                        true
                                      );
                                      const villageItem: any =
                                        villageOrLocality.find(
                                          (item: any) => item.name == value
                                        );
                                      if (
                                        !villageItem ||
                                        villageItem?.id != values.locationId
                                      ) {
                                        setFieldValue('locationId', null);
                                        setFieldTouched('locationId', true);
                                      }
                                    }}
                                    error={Boolean(
                                      errors.locationId && touched.locationId
                                    )}
                                    debounce={500}
                                  />
                                )}
                              >
                                Villages Or Locality
                              </Trans>
                              <ValidationMessage name={'locationId'} />
                            </IonCol>

                            {/* <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='geotag'
                                render={() => (
                                  <InputText
                                    name='geotag'
                                    labelName='GeoTag'
                                    labelKey='geotag'
                                    placeholder={'Placeholder'}
                                    value={values.geoTag}
                                    onChange={(value: any) => {
                                      setFieldValue('geoTag', value);
                                      setFieldTouched('geoTag', true);
                                    }}
                                  ></InputText>
                                )}
                              >
                                GeoTag
                              </Trans>
                            </div>
                          </IonCol> */}
                          </IonRow>

                          {/* 4th row complete */}

                          <IonRow className='ion-row'>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <Trans
                                id='certification'
                                render={() => (
                                  <DropDown
                                    labelName='Certification'
                                    labelKey='certification'
                                    optionList={certificateList}
                                    onChange={(item: any) => {
                                      setFieldValue('certification', item);
                                      setFieldTouched('certification', true);
                                    }}
                                    selectedItem={
                                      values.certification &&
                                      values.certification.length > 0
                                        ? values.certification.map(
                                            (item: any) =>
                                              getLanguageText(
                                                item.certificate_name ||
                                                  item.name,
                                                'name',
                                                item.locale,
                                                lang
                                              )
                                          )
                                        : t({
                                            id: 'please_select',
                                            message: 'Please Select',
                                          })
                                    }
                                    multiple
                                    customClassTop='certification-dd'
                                  />
                                )}
                              >
                                Certification
                              </Trans>
                            </IonCol>
                            <IonCol
                              size-xs='12'
                              size-sm='6'
                              className='ion-col mb-34'
                            >
                              <div className='ion-field-wrapper mb-10'>
                                <IonLabel className='ion-label ion-label-type font-aktiv-grotest-regular pl-0 pt-0'>
                                  {t({
                                    id: 'logistic_required',
                                    message: 'Logistic required',
                                  })}
                                  <span className='mandatory'>*</span>
                                </IonLabel>

                                <div>
                                  <IonRadioGroup
                                    name='Logistic required'
                                    value={logisticSelected}
                                    onIonChange={(e: any) => {
                                      onLogisticRadioChange(e);
                                      setFieldValue('logistic', e.detail.value);
                                      setFieldTouched('logistic', true);
                                    }}
                                    className='choose-type-radio'
                                  >
                                    <CircularRadio
                                      label='Yes'
                                      textKey={'yes'}
                                      customClass='mr-20'
                                    />
                                    <CircularRadio
                                      label='No'
                                      textKey={'no'}
                                      customClass='mr-20'
                                    />
                                  </IonRadioGroup>
                                </div>
                              </div>
                              <ValidationMessage name={'logistic'} />
                            </IonCol>
                          </IonRow>

                          <div className='select-photo-title mb-10'>
                            {t({
                              id: 'select_photos',
                              message:
                                'Select Photos (Supported File JPEG,PNG | Max Size 2MB)',
                            })}
                          </div>
                          <IonRow className='ion-row'>
                            <IonCol size-xs='12' className='ion-col mb-16'>
                              <div className='photo-flex'>
                                <UploadComponent
                                  filePath={
                                    data &&
                                    data.commodity_images_obj &&
                                    data.commodity_images_obj[0]
                                      ? getFormattedUrl(
                                          data.commodity_images_obj[0].uri
                                        )
                                      : undefined
                                  }
                                  onFileSelect={(file: any) => {
                                    onFileSelect(
                                      'file1',
                                      file,
                                      setFieldValue,
                                      setFieldError,
                                      values
                                    );
                                  }}
                                  onError={(msg: string) => {
                                    setImageError(true);
                                    setImageErrorMsg(msg);
                                  }}
                                />
                                {imageIndex >= 2 && (
                                  <UploadComponent
                                    filePath={
                                      data &&
                                      data.commodity_images_obj &&
                                      data.commodity_images_obj[1]
                                        ? getFormattedUrl(
                                            data.commodity_images_obj[1].uri
                                          )
                                        : undefined
                                    }
                                    onFileSelect={(file: any) => {
                                      onFileSelect(
                                        'file2',
                                        file,
                                        setFieldValue,
                                        setFieldError,
                                        values
                                      );
                                    }}
                                    onError={(msg: string) => {
                                      setImageError(true);
                                      setImageErrorMsg(msg);
                                    }}
                                  />
                                )}
                                {imageIndex >= 3 && (
                                  <UploadComponent
                                    filePath={
                                      data &&
                                      data.commodity_images_obj &&
                                      data.commodity_images_obj[2]
                                        ? getFormattedUrl(
                                            data.commodity_images_obj[2].uri
                                          )
                                        : undefined
                                    }
                                    onFileSelect={(file: any) => {
                                      onFileSelect(
                                        'file3',
                                        file,
                                        setFieldValue,
                                        setFieldError,
                                        values
                                      );
                                    }}
                                    onError={(msg: string) => {
                                      setImageError(true);
                                      setImageErrorMsg(msg);
                                    }}
                                  />
                                )}
                                {imageIndex >= 4 && (
                                  <UploadComponent
                                    filePath={
                                      data &&
                                      data.commodity_images_obj &&
                                      data.commodity_images_obj[3]
                                        ? getFormattedUrl(
                                            data.commodity_images_obj[3].uri
                                          )
                                        : undefined
                                    }
                                    onFileSelect={(file: any) => {
                                      onFileSelect(
                                        'file4',
                                        file,
                                        setFieldValue,
                                        setFieldError,
                                        values
                                      );
                                    }}
                                    onError={(msg: string) => {
                                      setImageError(true);
                                      setImageErrorMsg(msg);
                                    }}
                                  />
                                )}
                                {imageIndex == 5 && (
                                  <UploadComponent
                                    filePath={
                                      data &&
                                      data.commodity_images_obj &&
                                      data.commodity_images_obj[4]
                                        ? getFormattedUrl(
                                            data.commodity_images_obj[4].uri
                                          )
                                        : undefined
                                    }
                                    onFileSelect={(file: any) => {
                                      onFileSelect(
                                        'file5',
                                        file,
                                        setFieldValue,
                                        setFieldError,
                                        values
                                      );
                                    }}
                                    onError={(msg: string) => {
                                      setImageError(true);
                                      setImageErrorMsg(msg);
                                    }}
                                  />
                                )}
                                {imageIndex < 5 && (
                                  <div
                                    className='add-more-photo'
                                    onClick={() => {
                                      setImageIndex(imageIndex + 1);
                                    }}
                                  >
                                    {t({
                                      id: 'add_more_image',
                                      message: '+ Add Image',
                                    })}
                                  </div>
                                )}

                                <ValidationMessage name={'images'} />
                                {/* {values.files &&
                                values.files.map((file, i) => (
                                  <li key={i}>
                                    {`File:${i}`}{" "}
                                  </li>
                                ))} */}
                              </div>
                              {imageError && (
                                <div className='error'>{imageErrorMsg}</div>
                              )}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                        <div className='other-descriptions-wrapper mb-34'>
                          <div className='other-desc-title mb-10'>
                            {t({
                              id: 'other_description',
                              message: 'Other Description',
                            })}
                          </div>
                          <Trans
                            id='other_description'
                            render={() => (
                              <IonTextarea
                                className='other-desc-text mt-0'
                                value={values.other_description}
                                placeholder={t({
                                  id: 'other_description',
                                  message: 'Other Description',
                                })}
                                onIonChange={(e: any) => {
                                  setFieldValue(
                                    'other_description',
                                    e.detail.value
                                  );
                                }}
                              ></IonTextarea>
                            )}
                          >
                            Other Description
                          </Trans>
                          {/* <IonItem className='font-aktiv-grotest-medium ion-field-main-container'>
                          <IonTextarea
                            className='input-text-select'
                            placeholder='Placeholder'
                          ></IonTextarea>
                        </IonItem> */}
                        </div>

                        {/* <div className='solution-tag-wrapper mb-34'>
                        <div className='solution_tag-title mb-10'>
                          <Trans id='solution_tag'>Solution Tag:</Trans>
                        </div>
                        <div className='solution-tag-links'>
                          <a href='#' className='solution-tag-text mb-10'>
                            <Trans id='are_you_using_post'>
                              Are you using any Post Harvesting Solution?
                            </Trans>
                          </a>
                          <a href='#' className='solution-tag-text'>
                            <Trans id='are_you_intrested'>
                              Are you intrested to know more?
                            </Trans>
                          </a>
                        </div>
                      </div> */}
                      </div>
                    </>
                  ) : (
                    <div className='form__user-details'>
                      <IonGrid>
                        <IonRow className='ion-row'>
                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='ion-field-wrapper mb-10'>
                              <IonLabel className='ion-label ion-label-type font-aktiv-grotest-regular pl-0 pt-0'>
                                {t({ id: 'type', message: 'Type' })}
                                <span className='mandatory'>*</span>
                              </IonLabel>

                              <div>
                                <IonRadioGroup
                                  name='type'
                                  value={typeSelected}
                                  onIonChange={(e: any) => {
                                    onTypeRadioChange(e);
                                    setFieldValue('type', e.detail.value);
                                    setFieldTouched('type', true);
                                  }}
                                  className='choose-type-radio'
                                >
                                  <CircularRadio
                                    label='Fresh'
                                    textKey={'fresh'}
                                    customClass='mr-20'
                                  />
                                  <CircularRadio
                                    label='Dehydrated'
                                    textKey={'dehydrated'}
                                    customClass='mr-20'
                                  />
                                </IonRadioGroup>
                              </div>
                            </div>
                            <ValidationMessage name={'type'} />
                          </IonCol>
                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <Trans
                              id='commodity'
                              render={() => (
                                <DropDown
                                  name='commodity'
                                  labelName='Commodity'
                                  labelKey='commodity'
                                  optionList={
                                    typeSelected
                                      ? filterCommodityList
                                      : commodityList
                                  }
                                  required
                                  onChange={(item: any) => {
                                    if (values.type) {
                                      setFieldValue('commodity', item.value);
                                      setFieldTouched('commodity', true);
                                      setFieldValue('commodityId', item.id);
                                      const categoryObj: any =
                                        commodityList.find(
                                          (cItem: any) => cItem.id == item.id
                                        );
                                      if (categoryObj) {
                                        setFieldValue(
                                          'category',
                                          getLanguageText(
                                            categoryObj.data.category_id.name,
                                            'name',
                                            categoryObj.data.category_id.locale,
                                            lang
                                          )
                                        );
                                        setFieldValue(
                                          'categoryId',
                                          categoryObj.data.category_id.id
                                        );
                                        setFieldTouched('category', true);
                                      }
                                    } else {
                                      setFieldValue(
                                        'commodity',
                                        `Please Select ${new Date().getTime()}`
                                      );
                                      setFieldTouched('type', true);
                                      setFieldError(
                                        'type',
                                        'Please enter type'
                                      );
                                    }
                                  }}
                                  error={Boolean(
                                    errors.commodity && touched.commodity
                                  )}
                                  selectedItem={
                                    values.commodity ||
                                    t({
                                      id: 'please_select',
                                      message: 'Please Select',
                                    })
                                  }
                                />
                              )}
                            >
                              Commodity
                            </Trans>
                            <ValidationMessage name={'commodity'} />
                          </IonCol>

                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='category'
                                render={({ translation }) => (
                                  <InputText
                                    name='category'
                                    labelName='Category'
                                    labelKey='category'
                                    placeholder={translation?.toString()}
                                    value={values.category}
                                    // onChange={(value: any) => {
                                    //   setFieldValue('category', value);
                                    //   setFieldTouched('category', true);
                                    // }}
                                    error={Boolean(
                                      errors.category && touched.category
                                    )}
                                    disabled
                                  ></InputText>
                                )}
                              >
                                Category
                              </Trans>
                            </div>
                            <ValidationMessage name={'category'} />
                          </IonCol>
                        </IonRow>

                        {/* row 1 complete */}

                        <IonRow className='ion-row'>
                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <Trans
                              id='variety_or_breed'
                              render={({ translation }) => (
                                <InputText
                                  name='variety'
                                  labelName='Variety or Breed'
                                  labelKey='variety_or_breed'
                                  required
                                  placeholder={translation?.toString()}
                                  value={values.varietyOrBreed}
                                  onChange={(value: any) => {
                                    setFieldValue('varietyOrBreed', value);
                                    setFieldTouched('varietyOrBreed', true);
                                  }}
                                  error={Boolean(
                                    errors.varietyOrBreed &&
                                      touched.varietyOrBreed
                                  )}
                                ></InputText>
                              )}
                            >
                              Variety or Breed
                            </Trans>
                            <ValidationMessage name={'varietyOrBreed'} />
                          </IonCol>

                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='quantity'
                                render={({ translation }) => (
                                  <InputText
                                    name='quantity'
                                    labelName='Quantity/Units'
                                    labelKey='quantity'
                                    required
                                    placeholder={translation?.toString()}
                                    maxlength={4}
                                    value={values.quantity}
                                    onChange={(value: any) => {
                                      setFieldValue('quantity', value);
                                      setFieldTouched('quantity', true);
                                    }}
                                    error={Boolean(
                                      errors.quantity && touched.quantity
                                    )}
                                    type='number'
                                    inputMode={'numeric'}
                                  ></InputText>
                                )}
                              >
                                Quantity Or Units
                              </Trans>
                              <Trans
                                id='unit'
                                render={() => (
                                  <DropDown
                                    labelName='Unit'
                                    labelKey='unit'
                                    optionList={unitList}
                                    required
                                    onChange={(item: any) => {
                                      setFieldValue('unit', item.value);
                                      setFieldValue('unitId', item.id);

                                      setFieldTouched('unit', true);
                                    }}
                                    customClass='quantity-unit'
                                    customClassTop='quantity-unit-wrapper'
                                    selectedItem={values.unit}
                                    //error={Boolean(errors.unit && touched.unit)}
                                  />
                                )}
                              >
                                Unit
                              </Trans>
                            </div>
                            <ValidationMessage name={'quantity'} />
                          </IonCol>

                          {/* <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='price_expected'
                                render={({ translation }) => (
                                  <InputText
                                    name='price'
                                    labelName='Price Expected - Ex-Farm (per Unit)'
                                    required
                                    labelKey='price'
                                    placeholder={translation?.toString()}
                                    value={values.price_expected}
                                    onChange={(value: any) => {
                                      setFieldValue('price_expected', value);
                                      setFieldTouched('price_expected', true);
                                    }}
                                    error={Boolean(
                                      errors.price_expected &&
                                        touched.price_expected
                                    )}
                                    type='number'
                                    inputMode={'numeric'}
                                    suffix={values.unit}
                                  ></InputText>
                                )}
                              >
                                Price Expected - Ex-Farm(per Unit)
                              </Trans>
                              <ValidationMessage name={'price_expected'} />
                            </div>
                          </IonCol> */}
                        </IonRow>

                        {/* 2nd row complete */}

                        <IonRow className='ion-row'>
                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='pin_code'
                                render={({ translation }) => (
                                  <AutocompleteInput
                                    required
                                    maxlength={6}
                                    suggestions={pincodeList}
                                    onItemSelected={async (value: string) => {
                                      setFieldValue('pinCode', value);
                                      setFieldTouched('pinCode', true);
                                      await getPincodeDetails(
                                        value,
                                        setFieldValue
                                      );
                                    }}
                                    name='pinCode'
                                    labelName='Pin code'
                                    labelKey='pin_code'
                                    placeholder={'' + translation}
                                    //value={""}
                                    value={values.pinCode}
                                    onSuggestionChange={async (value: any) => {
                                      setFieldValue('pinCode', value);
                                      setFieldTouched('pinCode', true);
                                      if (interval) {
                                        clearInterval(interval);
                                        interval = null;
                                      }
                                      interval = setInterval(async () => {
                                        await getPincodeList(
                                          value,
                                          setFieldError,
                                          setFieldValue
                                        );
                                        clearInterval(interval);
                                        interval = null;
                                      }, 500);
                                    }}
                                    error={Boolean(
                                      errors.pinCode && touched.pinCode
                                    )}
                                    type='number'
                                    // debounce={500}
                                    inputMode={'numeric'}
                                  />
                                )}
                              >
                                Pin code
                              </Trans>
                            </div>
                            <ValidationMessage name={'pinCode'} />
                          </IonCol>

                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='state'
                                render={({ translation }) => (
                                  <InputText
                                    name='state'
                                    labelName='State'
                                    labelKey='state'
                                    placeholder={'' + translation}
                                    disabled
                                    value={values.state}
                                    // onChange={(value: any) => {
                                    //   setFieldValue('state', value);
                                    //   setFieldTouched('state', true);
                                    // }}
                                    error={Boolean(
                                      errors.state && touched.state
                                    )}
                                  ></InputText>
                                )}
                              >
                                State
                              </Trans>
                            </div>
                            <ValidationMessage name={'state'} />
                          </IonCol>

                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='district'
                                render={({ translation }) => (
                                  <InputText
                                    name='district'
                                    labelName='District'
                                    labelKey='district'
                                    placeholder={'' + translation}
                                    disabled
                                    value={values.district}
                                    // onChange={(value: any) => {
                                    //   setFieldValue('district', value);
                                    //   setFieldTouched('district', true);
                                    // }}
                                    error={Boolean(
                                      errors.district && touched.district
                                    )}
                                  ></InputText>
                                )}
                              >
                                District
                              </Trans>
                            </div>
                            <ValidationMessage name={'district'} />
                          </IonCol>
                        </IonRow>

                        <IonRow className='ion-row'>
                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <Trans
                              id='village_locality'
                              render={({ translation }) => (
                                <AutocompleteInput
                                  showSuggestionOnFocus
                                  required
                                  suggestions={villageSuggestions}
                                  onItemSelected={(value: string) => {
                                    setFieldValue('villageOrLocality', value);
                                    setFieldTouched('villageOrLocality', false);
                                    const villageItem: any =
                                      villageOrLocality.find(
                                        (item: any) => item.name == value
                                      );
                                    if (villageItem) {
                                      setFieldTouched('locationId', false);
                                      setFieldValue(
                                        'locationId',
                                        villageItem.id
                                      );
                                    }
                                  }}
                                  name='pinCode'
                                  labelName='Village / Locality'
                                  labelKey='village_locality'
                                  placeholderKey='type_your_village'
                                  placeholderName='Type your Village/Locality'
                                  placeholder={'' + translation}
                                  //value={""}
                                  value={values.villageOrLocality}
                                  onSuggestionChange={async (value: any) => {
                                    setFieldValue('villageOrLocality', value);
                                    setFieldTouched('villageOrLocality', true);
                                    const villageItem: any =
                                      villageOrLocality.find(
                                        (item: any) => item.name == value
                                      );
                                    if (
                                      !villageItem ||
                                      villageItem?.id != values.locationId
                                    ) {
                                      setFieldValue('locationId', null);
                                      setFieldTouched('locationId', true);
                                    }
                                  }}
                                  error={Boolean(
                                    errors.locationId && touched.locationId
                                  )}
                                  debounce={500}
                                />
                              )}
                            >
                              Villages Or Locality
                            </Trans>
                            <ValidationMessage name={'locationId'} />
                          </IonCol>

                          {/* <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='input-text-container'>
                              <Trans
                                id='geotag'
                                render={() => (
                                  <InputText
                                    name='geotag'
                                    labelName='GeoTag'
                                    labelKey='geotag'
                                    placeholder={'Placeholder'}
                                    value={values.geoTag}
                                    onChange={(value: any) => {
                                      setFieldValue('geoTag', value);
                                      setFieldTouched('geoTag', true);
                                    }}
                                  ></InputText>
                                )}
                              >
                                GeoTag
                              </Trans>
                            </div>
                          </IonCol> */}

                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <Trans
                              id='certification'
                              render={() => (
                                <DropDown
                                  labelName='Certification'
                                  labelKey='certification'
                                  optionList={certificateList}
                                  onChange={(item: any) => {
                                    setFieldValue('certification', item);
                                    setFieldTouched('certification', true);
                                  }}
                                  selectedItem={
                                    values.certification &&
                                    values.certification.length > 0
                                      ? values.certification.map((item: any) =>
                                          getLanguageText(
                                            item.certificate_name || item.name,
                                            'name',
                                            item.locale,
                                            lang
                                          )
                                        )
                                      : t({
                                          id: 'please_select',
                                          message: 'Please Select',
                                        })
                                  }
                                  multiple
                                  customClassTop='certification-dd'
                                />
                              )}
                            >
                              Certification
                            </Trans>
                          </IonCol>
                        </IonRow>

                        {/* 4th row complete */}

                        <IonRow className='ion-row'>
                          <IonCol
                            size-xs='12'
                            size-sm='4'
                            className='ion-col mb-34'
                          >
                            <div className='ion-field-wrapper mb-10'>
                              <IonLabel className='ion-label ion-label-type font-aktiv-grotest-regular pl-0 pt-0'>
                                {t({
                                  id: 'logistic_required',
                                  message: 'Logistic required',
                                })}
                                <span className='mandatory'>*</span>
                              </IonLabel>

                              <div>
                                <IonRadioGroup
                                  name='Logistic required'
                                  value={logisticSelected}
                                  onIonChange={(e: any) => {
                                    onLogisticRadioChange(e);
                                    setFieldValue('logistic', e.detail.value);
                                    setFieldTouched('logistic', true);
                                  }}
                                  className='choose-type-radio'
                                >
                                  <CircularRadio
                                    label='Yes'
                                    textKey={'yes'}
                                    customClass='mr-20'
                                  />
                                  <CircularRadio
                                    label='No'
                                    textKey={'no'}
                                    customClass='mr-20'
                                  />
                                </IonRadioGroup>
                              </div>
                            </div>
                            <ValidationMessage name={'logistic'} />
                          </IonCol>
                        </IonRow>

                        <div className='select-photo-title mb-10'>
                          {t({
                            id: 'select_photos',
                            message:
                              'Select Photos (Supported File JPEG,PNG | Max Size 2MB)',
                          })}
                        </div>
                        <IonRow className='ion-row'>
                          <IonCol size-xs='12' className='ion-col mb-16'>
                            <div className='photo-flex'>
                              <UploadComponent
                                filePath={
                                  data &&
                                  data.commodity_images_obj &&
                                  data.commodity_images_obj[0]
                                    ? getFormattedUrl(
                                        data.commodity_images_obj[0].uri
                                      )
                                    : undefined
                                }
                                onFileSelect={(file: any) => {
                                  onFileSelect(
                                    'file1',
                                    file,
                                    setFieldValue,
                                    setFieldError,
                                    values
                                  );
                                }}
                                onError={(msg: string) => {
                                  setImageError(true);
                                  setImageErrorMsg(msg);
                                }}
                              />
                              {imageIndex >= 2 && (
                                <UploadComponent
                                  filePath={
                                    data &&
                                    data.commodity_images_obj &&
                                    data.commodity_images_obj[1]
                                      ? getFormattedUrl(
                                          data.commodity_images_obj[1].uri
                                        )
                                      : undefined
                                  }
                                  onFileSelect={(file: any) => {
                                    onFileSelect(
                                      'file2',
                                      file,
                                      setFieldValue,
                                      setFieldError,
                                      values
                                    );
                                  }}
                                  onError={(msg: string) => {
                                    setImageError(true);
                                    setImageErrorMsg(msg);
                                  }}
                                />
                              )}
                              {imageIndex >= 3 && (
                                <UploadComponent
                                  filePath={
                                    data &&
                                    data.commodity_images_obj &&
                                    data.commodity_images_obj[2]
                                      ? getFormattedUrl(
                                          data.commodity_images_obj[2].uri
                                        )
                                      : undefined
                                  }
                                  onFileSelect={(file: any) => {
                                    onFileSelect(
                                      'file3',
                                      file,
                                      setFieldValue,
                                      setFieldError,
                                      values
                                    );
                                  }}
                                  onError={(msg: string) => {
                                    setImageError(true);
                                    setImageErrorMsg(msg);
                                  }}
                                />
                              )}
                              {imageIndex >= 4 && (
                                <UploadComponent
                                  filePath={
                                    data &&
                                    data.commodity_images_obj &&
                                    data.commodity_images_obj[3]
                                      ? getFormattedUrl(
                                          data.commodity_images_obj[3].uri
                                        )
                                      : undefined
                                  }
                                  onFileSelect={(file: any) => {
                                    onFileSelect(
                                      'file4',
                                      file,
                                      setFieldValue,
                                      setFieldError,
                                      values
                                    );
                                  }}
                                  onError={(msg: string) => {
                                    setImageError(true);
                                    setImageErrorMsg(msg);
                                  }}
                                />
                              )}
                              {imageIndex == 5 && (
                                <UploadComponent
                                  filePath={
                                    data &&
                                    data.commodity_images_obj &&
                                    data.commodity_images_obj[4]
                                      ? getFormattedUrl(
                                          data.commodity_images_obj[4].uri
                                        )
                                      : undefined
                                  }
                                  onFileSelect={(file: any) => {
                                    onFileSelect(
                                      'file5',
                                      file,
                                      setFieldValue,
                                      setFieldError,
                                      values
                                    );
                                  }}
                                  onError={(msg: string) => {
                                    setImageError(true);
                                    setImageErrorMsg(msg);
                                  }}
                                />
                              )}
                              {imageIndex < 5 && (
                                <div
                                  className='add-more-photo'
                                  onClick={() => {
                                    setImageIndex(imageIndex + 1);
                                  }}
                                >
                                  {t({
                                    id: 'add_more_image',
                                    message: '+ Add Image',
                                  })}
                                </div>
                              )}
                              <ValidationMessage name={'images'} />
                              {/* {values.files &&
                                values.files.map((file, i) => (
                                  <li key={i}>
                                    {`File:${i}`}{" "}
                                  </li>
                                ))} */}
                            </div>
                            {imageError && (
                              <div className='error'>{imageErrorMsg}</div>
                            )}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <div className='other-descriptions-wrapper mb-34'>
                        <div className='other-desc-title mb-10'>
                          {t({
                            id: 'other_description',
                            message: 'Other Description',
                          })}
                        </div>
                        <Trans
                          id='other_description'
                          render={() => (
                            <IonTextarea
                              className='other-desc-text mt-0'
                              value={values.other_description}
                              placeholder={t({
                                id: 'other_description',
                                message: 'Other Description',
                              })}
                              onIonChange={(e: any) => {
                                setFieldValue(
                                  'other_description',
                                  e.detail.value
                                );
                              }}
                            ></IonTextarea>
                          )}
                        >
                          Other Description
                        </Trans>
                        {/* <IonItem className='font-aktiv-grotest-medium ion-field-main-container'>
                          <IonTextarea
                            className='input-text-select'
                            placeholder='Placeholder'
                          ></IonTextarea>
                        </IonItem> */}
                      </div>

                      {/* <div className='solution-tag-wrapper mb-34'>
                        <div className='solution_tag-title mb-10'>
                          <Trans id='solution_tag'>Solution Tag:</Trans>
                        </div>
                        <div className='solution-tag-links'>
                          <a href='#' className='solution-tag-text mb-10'>
                            <Trans id='are_you_using_post'>
                              Are you using any Post Harvesting Solution?
                            </Trans>
                          </a>
                          <a href='#' className='solution-tag-text'>
                            <Trans id='are_you_intrested'>
                              Are you intrested to know more?
                            </Trans>
                          </a>
                        </div>
                      </div> */}
                    </div>
                  )}
                  {error && (
                    <div className='text-center text-danger'>{error}</div>
                  )}
                  {/* <div className='form__fpo-details'></div> */}
                  <div className='form__submit-btn'>
                    <ButtonComponent
                      name={'Submit'}
                      customClass='sell-req-btn'
                      textKey={'submit'}
                    />
                  </div>
                </>
                <FocusError />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SellRequirement;
