/* eslint-disable no-unused-vars */
import { IonInput } from '@ionic/react';
import { t, Trans } from '@lingui/macro';
import { Component, Fragment } from 'react';
import './style.scss';

interface Props {
  suggestions: Array<any>;
  onItemSelected: (value: string) => void;
}

interface State {
  // The active selection's index
  activeSuggestion: number;
  // The suggestions that match the user's input
  filteredSuggestions: Array<any>;
  // Whether or not the suggestion list is shown
  showSuggestions: boolean;
  // What the user has entered
  userInput: string;
}
class Autocomplete extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: '',
    };
  }

  onChange = (e: any) => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e: any) => {
    this.props.onItemSelected(e.currentTarget.innerText);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: '',
    });
  };

  onKeyDown = (e: any) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.props.onItemSelected(filteredSuggestions[activeSuggestion].title);

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: '',
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className='suggestions mt-0 p-8'>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li
                  className={className}
                  key={suggestion.title}
                  onClick={onClick}
                >
                  {suggestion.title}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className='no-suggestions'>
            <em>No suggestions</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <div className='autocomplete-wrapper'>
          <Trans
            id='type'
            render={() => (
              <IonInput
                className={`input-text-select blinking-cursor`}
                onIonChange={onChange}
                onKeyDown={onKeyDown}
                value={userInput}
                //autofocus
                placeholder={t({ id: 'type', message: 'Type' })}
                debounce={1000}
              ></IonInput>
            )}
          >
            Type
          </Trans>
          {suggestionsListComponent}
        </div>
      </Fragment>
    );
  }
}

export default Autocomplete;
