/* eslint-disable no-unused-vars */
import './style.scss';
import React from 'react';
import { IonButton, IonChip, IonIcon, IonLabel } from '@ionic/react';
import { close } from 'ionicons/icons';

interface Props {
  label: string;
  onRemove: (value: string) => void;
}

const CommodityChip: React.FC<Props> = ({ label, onRemove }) => {
  return (
    <>
      <div className='commodity-chip-wrapper mb-8 mr-8'>
        <IonChip className='ion-chip m-0 pr-7'>
          <IonLabel className='chip-label'>{label}</IonLabel>
          <IonButton
            className='chip-close-btn'
            onClick={() => {
              onRemove(label);
            }}
          >
            <IonIcon className='chip-close ml-10' icon={close} />
          </IonButton>
        </IonChip>
      </div>
    </>
  );
};

export default CommodityChip;
