import './style.scss';
import { IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import ButtonComponent from '../../components/button-component';
import Layout from '../../components/layouts';
const FoodSecurity: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <Layout>
        Food Security
        <ButtonComponent
          name='Buy'
          textKey={'buy'}
          onClick={() => {
            history.push('/buy');
          }}
        />
        <ButtonComponent
          name='Sell'
          textKey={'sell'}
          onClick={() => {
            history.push('/addsellrequirement');
          }}
        />
      </Layout>
    </IonPage>
  );
};

export default FoodSecurity;
