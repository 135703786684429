import { IonPage } from '@ionic/react';
import './style.scss';
import Layout from '../../components/layouts';
import CommodityDetailCard from '../../components/commoditydetailscard';
import Breadcrumbs from '../../components/breadcrumbs';
import PrivateRoute from '../../components/privateroute';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/app.context';
import { useParams } from 'react-router';
import {
  extendExpiry,
  getPostComments,
  getPostCommentsChat,
  getPostDetails,
  getPrivateCommentUsersByPostId,
  submitPostComment,
  submitRespond,
  viewPost,
} from '../../services/post.service';
import Messages from '../../config/messages';
import { getLanguageText, isWeb } from '../../utils/common.helper';
import PostDetailsCard from '../../components/postdetailscard';
import { INIT_PAGE } from '../../config/appconfig';
import { t } from '@lingui/macro';

const CommodityDetail: React.FC = () => {
  const { setLoading, loading, toastMessage, userDetails, lang }: any =
    useContext(AppContext);
  const [postDetails, setPostDetails]: any = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [commentListLoading, setCommentListLoading] = useState(true);
  const [messengerList, setMessengerList] = useState([]);
  const [isPostDeactivated, setIsPostDeactivated] = useState(false);

  const { id }: any = useParams();

  useEffect(() => {
    setLoading(true);
    setCommentListLoading(true);
    setPostDetails(null);
    setCommentList([]);
    setMessengerList([]);
    Promise.all([fetchPostDetails()]).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const fetchPostDetails = async () => {
    const resp = await getPostDetails(id);
    if (!resp.error) {
      setPostDetails(resp);
      if (!resp.user_id.is_active) {
        setIsPostDeactivated(true);
      } else {
        if (resp.post_type == 'general') {
          await fetchPostComments();
          setCommentListLoading(false);
        } else {
          if (userDetails.id == resp.user_id.id) {
            await fetchPrivateCommentUsers();
          } else {
            const chatRes = await fetchPostCommentsChats(resp.user_id.id);
            if (chatRes) {
              setCommentList(chatRes.items);
            }
          }
          setCommentListLoading(false);
          if (resp.status == 'accepted') {
            setPostView();
          }
        }
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchPostComments = async () => {
    const resp = await getPostComments(`page=1`, id);

    if (!resp.error) {
      setCommentList(resp.items);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchPostCommentsChats = async (toUserId: number) => {
    const resp = await getPostCommentsChat(id, toUserId, `page=1`);

    if (!resp.error) {
      // setCommentList(resp.items);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const fetchPrivateCommentUsers = async () => {
    const resp = await getPrivateCommentUsersByPostId(id);

    if (!resp.error) {
      const uniqueItem: any = [];
      for (let index = 0; index < resp.length; index++) {
        const element = resp[index];
        if (
          !uniqueItem.find(
            (item: any) => item.comment_userId == element.comment_userId
          ) &&
          element.comment_userId != userDetails.id
        ) {
          uniqueItem.push(element);
        }
      }
      setMessengerList(uniqueItem);
      if (uniqueItem.length > 0) {
        const chatRes = await fetchPostCommentsChats(uniqueItem[0].user_id);
        if (chatRes) {
          setCommentList(chatRes.items);
        }
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const setPostView = async () => {
    viewPost({
      post_id: id,
    });
  };

  const onPostCommentSubmit = async (req: any) => {
    const resp = await submitPostComment(req);
    if (!resp.error) {
      toastMessage(Messages.COMMENT_ADDED_MSG);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onRespondSubmit = async (req: any) => {
    const resp = await submitRespond(req);
    if (!resp.error) {
      toastMessage(Messages.RESPOND_ADDED_MSG);
      return resp;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  const onExtendExpirySubmit = async () => {
    const resp = await extendExpiry(postDetails.id);
    if (!resp.error) {
      postDetails.extended = true;
      setPostDetails(postDetails);
      toastMessage(Messages.EXPIRY_EXTEND_ADDED_MSG);
      return true;
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
      return false;
    }
  };

  return (
    <IonPage className='page-commodity-detail'>
      <Layout>
        <div
          className={`page-commodity-detail-container page-container ${
            isWeb() ? 'pt-0' : ''
          }`}
        >
          {loading || !postDetails ? (
            <></>
          ) : (
            <>
              {isWeb() && (
                <div className='page-menu-list p-32 mb-24'>
                  <div className='page-menu-list-inner'>
                    <Breadcrumbs
                      subMenuText={
                        postDetails.post_type != 'general'
                          ? 'Detail'
                          : undefined
                      }
                      menuText={
                        postDetails.post_type != 'general'
                          ? postDetails.post_type == 'sell'
                            ? 'Supply'
                            : 'Demand'
                          : 'Detail'
                      }
                      subMenuTextKey={
                        postDetails.post_type != 'general'
                          ? 'detail'
                          : undefined
                      }
                      menuTextKey={
                        postDetails.post_type != 'general'
                          ? postDetails.post_type
                          : 'detail'
                      }
                      menuRoute={
                        postDetails.post_type != 'general'
                          ? `/${postDetails.post_type}`
                          : INIT_PAGE
                      }
                    />
                  </div>
                </div>
              )}
              {isPostDeactivated ? (
                <div className='post-deactivated font-aktiv-grotest-medium'>
                  {t({
                    id: 'post_deactivated',
                    message: 'This content isn’t available at the moment',
                  })}
                </div>
              ) : (
                <div
                  className={`buy__cl-card-list-wrapper ${
                    postDetails.post_type == 'general'
                      ? 'general-post-wrapper'
                      : ''
                  } `}
                >
                  {postDetails.post_type != 'general' ? (
                    <CommodityDetailCard
                      postType={postDetails.post_type}
                      messengerList={messengerList}
                      commentLoading={commentListLoading}
                      productId={postDetails.id}
                      productName={postDetails.commodity_id}
                      productCategory={postDetails.category_id}
                      ListedOn={postDetails.created_at}
                      productVarietyBreed={postDetails.variety_breed}
                      productQuantity={postDetails.quantity}
                      productUnit={getLanguageText(
                        postDetails.weightunit_id.unit_name,
                        'name',
                        postDetails.weightunit_id.locale,
                        lang
                      )}
                      expectedPrice={postDetails.price}
                      productType={postDetails.type}
                      logisticRequired={postDetails.is_logistic_required}
                      farmerName={
                        postDetails.user_id.firstname &&
                        postDetails.user_id.is_active
                          ? `${postDetails.user_id.firstname} ${
                              postDetails.user_id.lastname || ''
                            }`
                          : 'Anonymous'
                      }
                      userId={postDetails.user_id.id}
                      farmerLocation={postDetails.location_id.locality_name}
                      farmerCity={postDetails.location_id.district}
                      farmerState={postDetails.location_id.state}
                      farmerPinCode={postDetails.location_id.pincode}
                      farmerPic={
                        userDetails.id == postDetails.user_id.id
                          ? userDetails.profile_pic_obj
                            ? userDetails.profile_pic_obj.uri
                            : undefined
                          : postDetails.user_id.profile_pic_obj
                          ? postDetails.user_id.profile_pic_obj.uri
                          : undefined
                      }
                      certification={postDetails.certification_id || []}
                      productDescription={postDetails.other_description || ''}
                      showLogistic={postDetails.post_type == 'sell'}
                      images={
                        postDetails.commodity_images_obj &&
                        postDetails.commodity_images_obj.length > 0
                          ? postDetails.commodity_images_obj
                          : postDetails.commodity_id.image_pic_obj
                          ? [postDetails.commodity_id.image_pic_obj]
                          : null
                      }
                      commentList={commentList || []}
                      // onSubmit={async (commentText: string, postId: number) => {
                      //   return await onPostCommentSubmit({
                      //     text: commentText,
                      //     postId: postId,
                      //     type: 'private',
                      //   });
                      // }}
                      updateComment={(commentList: any) => {
                        setCommentList(commentList);
                      }}
                      isActive={postDetails.is_active}
                      postStatus={postDetails.status}
                      onRespondSubmit={async (
                        commentText: string,
                        postId: number,
                        toUserId: number
                      ) => {
                        return await onRespondSubmit({
                          text: commentText,
                          postId: postId,
                          toUserId,
                          type: 'private',
                        });
                      }}
                      onSelectUser={async (selectedUser: any) => {
                        return await fetchPostCommentsChats(
                          selectedUser.user_id
                        );
                      }}
                      rejectReason={postDetails.reject_reason}
                      extended={postDetails.extended}
                      extendedAt={postDetails.expiring_at}
                      onExtendExpiry={onExtendExpirySubmit}
                    />
                  ) : (
                    <PostDetailsCard
                      userId={postDetails.user_id.id}
                      commentLoading={commentListLoading}
                      postId={postDetails.id}
                      farmerName={`${postDetails.user_id.firstname} ${
                        postDetails.user_id.lastname || ''
                      }`}
                      postCardContent={postDetails.description}
                      farmerProfilePic={
                        userDetails.id == postDetails.user_id.id
                          ? userDetails.profile_pic_obj
                            ? userDetails.profile_pic_obj.uri
                            : undefined
                          : postDetails.user_id.profile_pic_obj
                          ? postDetails.user_id.profile_pic_obj.uri
                          : undefined
                      }
                      commentList={commentList || []}
                      onSubmit={async (commentText: string, postId: number) => {
                        return await onPostCommentSubmit({
                          text: commentText,
                          postId: postId,
                          type: 'public',
                        });
                      }}
                      updateComment={(commentList: any) => {
                        setCommentList(commentList);
                      }}
                      imgPath={
                        postDetails.image_pic_obj
                          ? postDetails.image_pic_obj.uri
                          : null
                      }
                      videoPath={
                        postDetails.video_obj ? postDetails.video_obj.uri : null
                      }
                      isActive={postDetails.is_active}
                      postStatus={postDetails.status}
                      rejectReason={postDetails.reject_reason}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(CommodityDetail);
