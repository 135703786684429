import { IonPage } from '@ionic/react';
import { t } from '@lingui/macro';
import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import PrivateRoute from '../../components/privateroute';
import Notification from '../../components/notifications';
import { AppContext } from '../../context/app.context';
import EmptyData from '../../components/emptydata';
import { getNotificationListById } from '../../services/user.service';
import Breadcrumbs from '../../components/breadcrumbs';
import { isWeb } from '../../utils/common.helper';
import { INIT_PAGE } from '../../config/appconfig';
import storageService from '../../services/storage.service';

const NotificationsList: React.FC = () => {
  const { setLoading, loading, setIsNewNotification }: any =
    useContext(AppContext);
  const [notificationList, setNotificationList]: any = useState([]);
  const [lastId, setLastId]: any = useState(0);
  const [notificationLoadMore, setNotificationLoadMore]: any = useState(false);

  useEffect(() => {
    setLoading(true);
    setIsNewNotification(false);
    storageService.set('setIsNewNotification', 'false');
    Promise.all([getNotifications()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const getNotifications = async () => {
    const resp = await getNotificationListById(lastId);
    if (!resp.error) {
      if (resp.length > 0) {
        if (lastId == 0) {
          const lastNotiId = await storageService.get('notificationLastId');
          if (lastNotiId) {
            if (lastNotiId != resp[0].id) {
              await storageService.set('notificationLastId', resp[0].id);
              // await storageService.set('setIsNewNotification', 'true');
              // setIsNewNotification(true);
            }
          } else {
            await storageService.set('notificationLastId', resp[0].id);
            // await storageService.set('setIsNewNotification', 'true');
            // setIsNewNotification(true);
          }
        }
        setNotificationList(notificationList.concat(resp));
        setLastId(resp[resp.length - 1].id);
      }
      setNotificationLoadMore(resp.length > 0);
    }
  };

  return (
    <IonPage className='page-notifications'>
      <Layout>
        {isWeb() && (
          <div className='page-menu-list p-32 mb-24'>
            <div className='page-menu-list-inner'>
              <Breadcrumbs
                menuText='Notifications'
                menuTextKey='notifications'
                menuRoute={INIT_PAGE}
              />
            </div>
          </div>
        )}
        <div className='buy__cl-card-list-wrapper mb-20'>
          <div className='cd-card-wrapper p-16 '>
            <div>
              {loading && notificationList.length == 0 ? (
                <></>
              ) : !loading && notificationList.length == 0 ? (
                <EmptyData message='No Data Found' messageKey='no_data_found' />
              ) : (
                notificationList.map((item: any) => {
                  const metaData = JSON.parse(item.metaData);
                  return (
                    <Notification
                      key={item.id}
                      message={item.title.replace('${user.fullname}', '')}
                      cutomerName={
                        metaData
                          ? (metaData.type != 'APPROVED' ||
                              metaData.type != 'REJECTED') &&
                            metaData.user
                            ? metaData.user.fullname
                            : null
                          : null
                      }
                      profilePic={
                        metaData
                          ? (metaData.type != 'APPROVED' ||
                              metaData.type != 'REJECTED') &&
                            metaData.user &&
                            metaData.user.profile_pic_obj
                            ? metaData.user.profile_pic_obj.uri
                            : undefined
                          : undefined
                      }
                      notificationTime={item.created_at}
                      metaData={metaData}
                    />
                  );
                })
              )}
              {notificationLoadMore && (
                <div
                  className='load-more-btn font-aktiv-grotest-medium mt-12'
                  onClick={() => {
                    getNotifications();
                  }}
                >
                  {t({
                    id: 'load_more',
                    message: 'Load more',
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(NotificationsList);
