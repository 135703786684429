/* eslint-disable no-unused-vars */
import React from 'react';
import './style.scss';
import { IonLabel, IonRadio } from '@ionic/react';
import { t } from '@lingui/macro';

interface Props {
  label?: string;
  onClick?: any;
  textKey?: any;
  customClass?: string;
}

const Radio: React.FC<Props> = ({ label, onClick, textKey, customClass }) => {
  const labelText: any = label;

  return (
    <div
      className={`radio-wrapper circularradio-btn circular-radio-wrapper ${
        customClass || ''
      }`}
    >
      <IonRadio
        slot='start'
        value={labelText}
        onClick={onClick}
        className='radio-btn mr-12'
      />
      <IonLabel className='ion-label'>
        {t({ id: textKey, message: labelText })}
      </IonLabel>
    </div>
  );
};

export default Radio;
