import { IonImg, IonPage } from '@ionic/react';
import { t } from '@lingui/macro';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ContentComp from '../../components/content/contentcomp';
import Layout from '../../components/layouts';
import Messages from '../../config/messages';
import { AppContext } from '../../context/app.context';
import { getContent } from '../../services/about.service';
import { getFormattedUrlCMS } from '../../utils/common.helper';
import './style.scss';

const Partners: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent('partners');
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  function onNavClick(route: string) {
    history.push(`/partnerscategory/${route}`);
  }

  return (
    <IonPage className='page-partner-list'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <>
            <div className='section content-sec pt-0'>
              <div className='page-banner-with-text'>
                {entry.bannerimage && entry.bannerimage.length > 0 && (
                  <ContentComp
                    data={{
                      images: entry.bannerimage,
                      unique_key: entry.bannerimage[0].unique_key,
                    }}
                  />
                )}

                {entry.text_with_title && (
                  <div className='container'>
                    <div className='banner-content'>
                      <div className='banner-content__inner'>
                        <ContentComp
                          data={{
                            title: entry.text_with_title.short_text.title,
                            titleTag: entry.text_with_title.short_text.tag_name,
                            titleCustomClass: 'content-heading',
                            description:
                              entry.text_with_title.long_text?.description,
                            descPosition:
                              entry.text_with_title.long_text?.position,
                            unique_key: entry.text_with_title.unique_key,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {entry.title_and_text_with_logo &&
                entry.title_and_text_with_logo.length > 0 && (
                  <>
                    {entry.title_and_text_with_logo.map((item: any) => {
                      return (
                        <div
                          key={item.id}
                          className='pt-30 pb-30 sm-pt-15 sm-pb-15'
                        >
                          <div className='container container--medium'>
                            <div className='row'>
                              <div className='col col-12 col-md-4 pt-15 pb-15'>
                                <h2 className='font-aktiv-grotest-medium mb-15 text-cyan'>
                                  {item.title.title}
                                </h2>
                                <p>{item.text?.description}</p>
                              </div>
                              <div className='col col-12 col-md-8'>
                                <div className='row align-items-center'>
                                  {item.logo.map((logo: any) => {
                                    return (
                                      <div
                                        key={logo.id}
                                        className='col col-6 col-md-4 pt-15 pb-15'
                                      >
                                        <IonImg
                                          className=''
                                          src={getFormattedUrlCMS(
                                            `${logo.image?.hash}${logo.image?.ext}`
                                          )}
                                        ></IonImg>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <div
                                className='show-more-btn font-aktiv-grotest-medium mt-12'
                                onClick={() => {
                                  if (item.redirect_to) {
                                    onNavClick(item.redirect_to.model);
                                  }
                                }}
                              >
                                {t({
                                  id: 'load_more',
                                  message: 'Load More',
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
            </div>
          </>
        )}
      </Layout>
    </IonPage>
  );
};
export default Partners;
