import React from 'react';
import './style.scss';
import { t } from '@lingui/macro';
import HomeIcon from '../../assets/images/icons/home-icon.png';
import { IonImg } from '@ionic/react';
import { isWeb } from '../../utils/common.helper';
import { useHistory } from 'react-router';
import { INIT_PAGE } from '../../config/appconfig';

interface Props {
  menuText: string;
  subMenuText?: string;
  customClass?: string;
  menuTextKey: string;
  subMenuTextKey?: string;
  menuRoute: string;
}

const Breadcrumbs: React.FC<Props> = ({
  menuText,
  subMenuText,
  customClass,
  menuTextKey,
  subMenuTextKey,
  menuRoute,
}) => {
  const history = useHistory();
  if (!isWeb()) {
    return <div></div>;
  }
  return (
    <div className={`breadcrumb__menu-list-wrapper ${customClass || ''}`}>
      <IonImg
        className='breadcrumb__menu-list-home-icon mr-16'
        src={HomeIcon}
        onClick={() => {
          history.push(INIT_PAGE);
        }}
      ></IonImg>
      <div
        onClick={() => {
          history.push(menuRoute);
        }}
        className='breadcrumb__menu pt-3 font-aktiv-grotest-medium ml-16 mr-16'
      >
        {t({ id: menuTextKey, message: menuText })}
      </div>
      {subMenuText && (
        <div className='breadcrumb__submenu pt-3 font-aktiv-grotest-medium ml-16'>
          {t({ id: subMenuTextKey, message: subMenuText })}
        </div>
      )}
    </div>
  );
};

export default Breadcrumbs;
