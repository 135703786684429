import { APP_DOMAIN } from '../../../config/appconfig';

const PrivacyTE: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        కోవెస్ట్రో ఫుడ్ సెక్యూరిటీ ప్లాట్‌ఫారం కోసం గోప్యతా నిబంధనలు
      </h3>
      <h4 style={{ textAlign: 'center' }}>
        చివరిగా సవరించబడినది [సెప్టెంబర్ 27, 2022]
      </h4>
      <h3>పరిచయం</h3>
      <p>
        కోవెస్ట్రో (ఇండియా) ప్రైవేట్ లిమిటెడ్‌లోని మా కమ్యూనిటీలో మీరు భాగం
        కావడాన్ని ఎంపిక చేసుకున్నందుకు ధన్యవాదాలు, ఈ కంపెనీనే ఇంతకుముందు బేయర్
        మెటీరియల్ సైన్స్ ప్రైవేట్ లిమిటెడ్ అని పిలిచేవారు, ఇది కంపెనీల చట్టం,
        1956 కింద పొందుపరచబడిన ప్రైవేట్ లిమిటెడ్ కంపెనీ, అలాగే సంస్థ రిజిస్టర్డ్
        కార్యాలయం యూనిట్ నంబర్. SB-801, 8వ అంతస్తు, ఎంపైర్ టవర్, క్లౌడ్ సిటీ
        క్యాంపస్, ఐరోలి, థానే - బేలాపూర్ రోడ్, నవీ థానే మహారాష్ట్ర 400708 ఇండియా
        వద్ద ఉన్నది. (“కోవెస్ట్రో”, “కంపెనీ”, “మేము”, “మమ్మల్ని”, లేదా “మా”,
        లేదా &quot;ప్లాట్‌ఫారం&quot; ) మేము మీ వ్యక్తిగత సమాచారాన్ని, మీ గోప్యత
        హక్కును రక్షించడానికి పూర్తిగా కట్టుబడి ఉన్నాము. మా విధానం గురించి లేదా
        మీ వ్యక్తిగత సమాచారానికి సంబంధించి మా వ్యవహారాల గురించి మీకు ఏవైనా
        ప్రశ్నలు లేదా ఆందోళనలు ఉంటే, దయచేసి మమ్మల్ని సంప్రదించండి.
      </p>
      <p>
        మా నిబంధనలు & షరతులతో పాటుగా ఈ గోప్యతా విధానం మీ వినియోగాన్ని,
        ప్లాట్‌ఫారం ప్రాప్యతను నియంత్రిస్తుంది. ఈ ఉపయోగ నిబంధనలను{' '}
        <a href={`${APP_DOMAIN}legal/conditions-of-use`}>
          {`${APP_DOMAIN}legal/conditions-of-use`}
        </a>{' '}
        లో ప్రాప్యత పొందవచ్చు
      </p>
      <p>
        మా సేవలను పొందే ముందు ప్రతి వినియోగదారు గోప్యతా నిబంధనలను వివరంగా
        చదవడానికి సమయాన్ని వెచ్చించాలని అభ్యర్థిస్తున్నాము, ఈ గోప్యతా విధానంలో
        మీరు ఏకీభవించని ఏవైనా నిబంధనలు ఉంటే, దయచేసి మా వెబ్‌సైట్, మా సేవల
        వినియోగాన్ని వెంటనే నిలిపివేయండి.
      </p>
      <p>
        ఈ గోప్యతా విధానం మా వెబ్‌సైట్/యాప్ [ఫుడ్ సెక్యూరిటీ ప్లాట్‌ఫారం] మరియు /
        లేదా ఏదైనా సంబంధిత సేవలు, విక్రయాలు, మార్కెటింగ్ లేదా ఈవెంట్‌ల ద్వారా
        సేకరించబడిన మొత్తం సమాచారానికి వర్తిస్తుంది (మేము వాటిని ఈ గోప్యతా
        విధానంలో సమిష్టిగా <b>&quot;ప్లాట్‌ఫారం&quot;</b>గా పేర్కొంటాము).
      </p>
      <p>
        ఈ గోప్యతా విధానం మా గోప్యతా పద్ధతులను వివరించడానికి ఉద్దేశించబడింది, అది
        ఈ కింద పేర్కొనబడిన అంశాలను కవర్ చేస్తుంది:
      </p>
      <ol>
        <li>
          <h3>మేము ఏయే సమాచారాన్ని సేకరిస్తాము?</h3>
          <ol>
            <li>
              <b>
                <p>
                  <i>మీరు మాకు వెల్లడించే వ్యక్తిగత సమాచారం </i>
                </p>
              </b>
              <i>
                <p>
                  <b>క్లుప్తంగా:</b> మీరు మాకు అందించే పేరు, చిరునామా,
                  సంప్రదింపు సమాచారం వంటి వ్యక్తిగత సమాచారాన్ని మేము బిల్లింగ్
                  చేయబడిన మొత్తంతో సహా సేకరిస్తాము.
                </p>
              </i>
              <p>
                ప్లాట్‌ఫారం‌లో నమోదు చేసుకునేటప్పుడు, మా లేదా మా ఉత్పత్తులు,
                సేవల గురించి సమాచారాన్ని పొందడంలో ఆసక్తిని వ్యక్తం చేస్తూ,
                ప్లాట్‌ఫారం‌లో కార్యకలాపాల్లో పాల్గొనేటప్పుడు లేదా దిగువ ఇచ్చిన
                సమాచారంలో మమ్మల్ని సంప్రదించేటప్పుడు మీరు స్వచ్ఛందంగా మాకు
                అందించే వ్యక్తిగత సమాచారాన్ని మేము సేకరిస్తాము.
              </p>
              <p>
                మీరు మాకు అందించే అన్ని రకాల వ్యక్తిగత సమాచారం తప్పనిసరిగా
                అసలైనది, సంపూర్ణమైనది, ఖచ్చితమైనది, అటువంటి వ్యక్తిగత సమాచారంలో
                ఏవైనా మార్పులను మీరు మాకు తెలియజేయాలి.
              </p>
              <p>
                మేము సేకరించే వ్యక్తిగత సమాచారం మాతో, ప్లాట్‌ఫారం‌తో మీ పరస్పర
                చర్యల సందర్భం, మీరు చేసే ఎంపికలు, మీరు పొందే ఉత్పత్తులు, సేవలపై
                ఆధారపడి ఉంటుంది.
              </p>
              <p>
                మేము సేకరించే వ్యక్తిగత సమాచారం ఈ కింది అంశాలను కలిగి ఉంటుంది:
              </p>
              <p>
                <b>పేరు మరియు సంప్రదింపు డేటా.</b> మేము మీ మొదటి, చివరి పేరు,
                ఇ-మెయిల్ చిరునామా, పోస్టల్ చిరునామా, ఫోన్ నంబర్లు, సేవలను
                యాక్సెస్ చేయడానికి మీరు అందించే ఇతర సారూప్య సంప్రదింపు డేటాను
                సేకరిస్తాము..
              </p>
              <p>
                <b>ఆధారాలు.</b> మేము పాస్‌వర్డ్‌లు, పాస్‌వర్డ్ సూచనలు,
                ప్రమాణీకరణ మరియు ఖాతా యాక్సెస్ కోసం ఉపయోగించే సారూప్య భద్రతా
                సమాచారాన్ని <b>సేకరించము.</b>
              </p>
              <p>
                <b>చెల్లింపు డేటా.</b> మేము మీ చెల్లింపును ప్రాసెస్ చేయడానికి,
                కొనుగోళ్లను ప్రాసెస్ చేయడానికి అవసరమైన డేటాను మాత్రమే
                సేకరిస్తాము. మొత్తం చెల్లింపు డేటా చెల్లింపు గేట్‌వే ద్వారా
                నిల్వ చేయబడుతుంది, దానిపై మాకు పరిమిత నియంత్రణ ఉంటుంది, మీరు
                దాని గోప్యతా విధానాలను సమీక్షించాలి, మీ ప్రశ్నలకు
                ప్రతిస్పందించడానికి చెల్లింపు ప్రాసెసర్‌ను నేరుగా సంప్రదించాలి.
              </p>
              <p>
                <b>
                  టెస్ట్ డేటా, టెస్ట్ అనాలిసిస్ రిపోర్ట్స్, సర్వేలు, AI
                  ప్రిడిక్టివ్ మోడల్స్.
                </b>{' '}
                మేము ప్లాట్‌ఫారం వినియోగం నుండి మీ డేటా నివేదికలు, విశ్లేషణలు,
                సర్వేలు, పండించిన పంటలు, ధరలను తనిఖీ చేయడానికి పంటలు, ఆసక్తి
                ఉన్న ఉత్పత్తులు, కథనాలు, పోస్ట్‌లను ఇష్టపడే ప్రిడిక్టివ్
                మోడల్‌లు, ఫలితాలు, ఇతర ఉత్పన్న సమాచారం మొదలైనవాటిని సేకరిస్తాము.
              </p>
              <p>
                <b>సోషల్ మీడియా లాగిన్ డేటా.</b> మీ Facebook, Twitter లేదా ఏదైనా
                ఇతర సోషల్ మీడియా ఖాతా వంటి సోషల్ మీడియా ఖాతా వివరాలను ఉపయోగించి
                నమోదు చేసుకునే అవకాశాన్ని మేము మీకు అందిస్తాము. మీరు ఈ విధంగా
                నమోదు చేసుకోవాలని ఎంచుకుంటే, మేము ఈ కింద పేర్కొనబడిన సమాచారాన్ని
                సేకరిస్తాము:
              </p>
              <p>
                Facebook, Google+ Instagram, Pinterest, Twitter, LinkedIn వంటి
                సోషల్ నెట్‌వర్కింగ్ ప్లాట్‌ఫారం నుండి వినియోగదారు సమాచారం, మీ
                పేరు, మీ సోషల్ నెట్‌వర్క్ వినియోగదారు పేరు, స్థానం, లింగం,
                పుట్టిన తేదీ, ఇ-మెయిల్ చిరునామా, ప్రొఫైల్ చిత్రం, కాంటాక్టుల
                కోసం పబ్లిక్ డేటా, మీరు మీ ఖాతాను అటువంటి సోషల్ నెట్‌వర్క్‌లకు
                కనెక్ట్ చేసినప్పుడు మాత్రమే. ఈ సమాచారం ప్లాట్‌ఫారం‌ను
                ఉపయోగించడానికి మరియు / లేదా చేరడానికి మీరు ఆహ్వానించే ఎవరికైనా
                సంప్రదింపు సమాచారాన్ని కూడా కలిగి ఉండవచ్చు
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>ఈ సమాచారం ఆటోమేటిగ్గా సేకరించబడుతుంది</i>
                </p>
              </b>
              <p>
                <i>
                  <b>క్లుప్తంగా:</b> మీరు భద్రతా ప్రయోజనాల కోసం మా
                  ప్లాట్‌ఫారాన్ని సందర్శించినప్పుడు IP చిరునామా మరియు / లేదా
                  బ్రౌజర్, పరికర లక్షణాలు వంటి కొంత సమాచారం ఆటోమేటిగ్గా
                  సేకరించబడుతుంది, అది నిరోధించబడినది కానిది మాత్రమే
                  సేకరించబడుతుంది.
                </i>
              </p>
              <p>
                మీరు ప్లాట్‌ఫారం‌ను సందర్శించినప్పుడు, ఉపయోగించినప్పుడు లేదా
                నావిగేట్ చేసినప్పుడు మేము ఆటోమేటిగ్గా నిర్దిష్ట సమాచారాన్ని
                సేకరిస్తాము ఈ సమాచారం మీ నిర్దిష్ట గుర్తింపును (మీ పేరు లేదా
                సంప్రదింపు సమాచారం వంటివి) బహిర్గతం చేయదు కానీ మీ IP చిరునామా,
                బ్రౌజర్ మరియు పరికర లక్షణాలు, ఆపరేటింగ్ సిస్టమ్, భాషా
                ప్రాధాన్యతలు, సూచించే URLలు, పరికరం పేరు, దేశం, స్థానం వంటి
                పరికరం, వినియోగ సమాచారాన్ని కలిగి ఉండవచ్చు,మీరు మా ప్లాట్‌ఫారం,
                ఇతర సాంకేతిక సమాచారాన్ని ఎలా, ఎప్పుడు ఉపయోగిస్తున్నారు అనే దాని
                గురించిన సమాచారం కూడా ఇందులో ఉండవచ్చు. మా ప్లాట్‌ఫారం భద్రత,
                ఆపరేషన్‌ను నిర్వహించడానికి, మా అంతర్గత విశ్లేషణలు, రిపోర్టింగ్
                ప్రయోజనాల కోసం ఈ సమాచారం ప్రాథమికంగా అవసరం.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>మా ప్లాట్‌ఫారం‌ల ద్వారా సేకరించిన సమాచారం</i>
                </p>
              </b>
              <p>
                <i>
                  <b>క్లుప్తంగా:</b> మీరు ప్లాట్‌ఫారాన్ని ఉపయోగించినప్పుడు మేము
                  మీ భౌగోళిక స్థానం, మొబైల్ పరికరం, పుష్ నోటిఫికేషన్‌లకు
                  సంబంధించిన సమాచారాన్ని సేకరించవచ్చు.
                </i>
              </p>
              <p>
                మీరు మా ప్లాట్‌ఫారం‌ను ఉపయోగిస్తుంటే, మేము ఈ కింద పేర్కొనబడిన
                సమాచారాన్ని కూడా సేకరించవచ్చు:
              </p>
              <p>
                - <i>జియో లొకేషన్ సమాచారం.</i> లొకేషన్ ఆధారిత సేవలను
                అందించడానికి మీ మొబైల్ పరికరం నుండి లొకేషన్ ఆధారిత సమాచారాన్ని
                నిరంతరంగా లేదా మీరు మా మొబైల్ అప్లికేషన్‌ని ఉపయోగిస్తున్నప్పుడు
                మేము యాక్సెస్ లేదా అనుమతిని అభ్యర్థించవచ్చు. మీరు మా యాక్సెస్
                లేదా అనుమతులను మార్చాలనుకుంటే, మీరు మీ పరికరం సెట్టింగ్‌లలో
                మార్చవచ్చు.
              </p>
              <p>
                - <i>మొబైల్ పరికరం ప్రాప్యత.</i> మేము మీ మొబైల్ పరికరం నుండి మీ
                మొబైల్ పరికరం [బ్లూటూత్, క్యాలెండర్, కెమెరా, కాంటాక్టులు,
                మైక్రోఫోన్, రిమైండర్‌లు, సెన్సార్‌లు, SMS సందేశాలు, సోషల్ మీడియా
                ఖాతాలు, నిల్వ,] IP చిరునామాతో సహా నిర్దిష్ట ఫీచర్‌లకు యాక్సెస్
                లేదా అనుమతిని అభ్యర్థించవచ్చు; టైమ్ జోన్; మీ మొబైల్ సర్వీస్
                ప్రొవైడర్ మొదలైన వాటి గురించిన సమాచారం, ఇతర ఫీచర్ల గురించి
                అభ్యర్థించవచ్చు. మీరు మా యాక్సెస్ లేదా అనుమతులను మార్చాలనుకుంటే,
                మీరు మీ పరికరం సెట్టింగ్‌లలో మార్చవచ్చు.
              </p>
              <p>
                - <i>మొబైల్ పరికరం డాటా.</i> మేము పరికర సమాచారాన్ని ఆటోమేటిగ్గా
                సేకరించవచ్చు (మీ మొబైల్ పరికరం ID, మోడల్, తయారీదారు వంటివి),
                హార్డ్‌వేర్ మోడల్; ఆపరేటింగ్ సిస్టమ్, దాని వెర్షన్ గురించి
                సమాచారం; ప్రత్యేక పరికర ఐడెంటిఫైయర్‌లు (ఉదా. IDFA); మొబైల్
                నెట్‌వర్క్ సమాచారం; పరికర నిల్వ సమాచారం, ఆపరేటింగ్ సిస్టమ్,
                వెర్షన్ సమాచారం, IP చిరునామా మొదలైనవి.
              </p>
              <p>
                - <i>పుష్ నోటిఫికేషన్లు.</i> మేము మీ ఖాతా లేదా మొబైల్
                అప్లికేషన్‌కు సంబంధించి పుష్ నోటిఫికేషన్‌లను పంపమని
                అభ్యర్థించవచ్చు. మీరు ఈ రకమైన కమ్యూనికేషన్‌లను స్వీకరించకుండా
                నిలిపివేయాలనుకుంటే, మీరు వాటిని మీ పరికరం సెట్టింగ్‌లలో ఆఫ్
                చేయవచ్చు.
              </p>
              <p>
                - <b>యాప్ వినియోగ డేటా, ఇతర వాటితో సహా:</b> మేము ఉపయోగం
                ఫ్రీక్వెన్సీ గురించి సమాచారాన్ని కూడా సేకరించవచ్చు; మీరు
                సందర్శించే మా యాప్ ప్రాంతాలు, లక్షణాలు; సాధారణంగా మీ వినియోగ
                నమూనాలు; ప్రత్యేక ఫీచర్లతో ఎంగేజ్మెంట్.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>ఇతర వనరుల నుండి సేకరించిన సమాచారం</i>
                </p>
              </b>
              <p>
                <i>
                  <b>క్లుప్తంగా:</b> మేము పబ్లిక్ డేటాబేస్‌లు, మార్కెటింగ్
                  భాగస్వాములు, సోషల్ మీడియా ప్లాట్‌ఫారం‌లు, ఇతర బయటి మూలాల నుండి
                  పరిమిత డేటాను సేకరించవచ్చు.
                </i>
              </p>
              <p>
                మేము మీ గురించిన సమాచారాన్ని పబ్లిక్ డేటాబేస్‌లు, జాయింట్
                మార్కెటింగ్ పార్టనర్‌లు, సోషల్ మీడియా ప్లాట్‌ఫారం‌లు (Facebookకు
                మాత్రమే పరిమితం కాకుండా) అలాగే ఇతర థర్డ్ పార్టీల నుండి
                పొందవచ్చు. ఈ సమాచారాన్ని సేకరించడానికి, మేము మీ మొబైల్ పరికరం
                లేదా కంప్యూటర్‌కు కుకీలను కూడా పంపవచ్చు లేదా ఇతర ట్రాకింగ్
                సాంకేతికతలను నిమగ్నం చేయవచ్చు. మా కుకీ విధానంలో ఇటువంటి అంశాలను
                మరిన్ని చూడండి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మేము మీ సమాచారాన్ని ఎలా ఉపయోగిస్తాము?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> చట్టబద్ధమైన వాణిజ్య ప్రయోజనాలు, విస్తృత సామాజిక
              ప్రయోజనాలు, మీతో మా ఒప్పందాన్ని నెరవేర్చడం, మా చట్టపరమైన బాధ్యతలకు
              అనుగుణంగా ఉండటం మరియు / లేదా మీ సమ్మతి ఆధారంగా మేము మీ సమాచారాన్ని
              ప్రాసెస్ చేస్తాము.
            </i>
          </p>
          <p>
            మేము మా ప్లాట్‌ఫారం ద్వారా సేకరించిన అనామక సమాచారాన్ని వివిధ రకాల
            వాణిజ్య ప్రయోజనాల కోసం, దిగువ వివరించిన విధంగా విస్తృత సామాజిక
            ప్రయోజనాల కోసం ఉపయోగిస్తాం. మీ సమ్మతితో (&quot;సమ్మతి&quot;) మీతో
            ఒప్పందం (&quot;సమ్మతి సంబంధమైన&quot;) లోకి ప్రవేశించడానికి లేదా
            నిర్వహించడానికి మా చట్టబద్ధమైన వాణిజ్య ప్రయోజనాలు, విస్తృత సామాజిక
            ప్రయోజనాల (&quot;వాణిజ్య, సామాజిక ప్రయోజనాల&quot;), పై ఆధారపడి ఈ
            ప్రయోజనాల కోసం మేము మీ వ్యక్తిగత సమాచారాన్ని ప్రాసెస్ చేస్తాము.
            సమ్మతి&quot; మరియు / లేదా మా చట్టపరమైన బాధ్యతలను (&quot;చట్టపరమైన
            కారణాలు&quot;) పాటించడం కోసం ఇందులో ఉండవచ్చు. ఈ దిగువ పేర్కొనబడిన
            ప్రతి ప్రయోజనం పక్కన మేము ఆధారపడే నిర్దిష్ట ప్రాసెసింగ్ గ్రౌండ్‌లను
            సూచిస్తున్నాము.
          </p>
          <p>
            మేము సేకరించిన లేదా స్వీకరించే సమాచారాన్ని కింది వాటితో సహా వాటికే
            పరిమితం కాకుండా ప్రయోజనాల కోసం ఉపయోగిస్తాము:
          </p>
          <p>
            - <b>ఖాతా సృష్టి మరియు లాగిన్ ప్రక్రియను సులభతరం చేయడానికి </b>[మీ
            సమ్మతితో]. మీరు మీ Google లేదా Facebook ఖాతా వంటి థర్డ్ పార్టీ
            ఖాతాకు మాతో మీ ఖాతాను లింక్ చేయాలని ఎంచుకుంటే, ఖాతా సృష్టి, లాగిన్
            ప్రక్రియను సులభతరం చేయడానికి ఆ థర్డ్ పార్టీల నుండి సేకరించడానికి
            మీరు మాకు అనుమతించిన సమాచారాన్ని మేము ఉపయోగిస్తాము. తదుపరి సమాచారం
            కోసం &quot;సోషల్ మీడియా లాగిన్ డేటా&quot; శీర్షిక క్రింద ఉన్న
            విభాగాన్ని చూడండి.
          </p>
          <p>
            - <b>మీకు మార్కెటింగ్, ప్రమోషనల్ కమ్యూనికేషన్‌లను పంపడానికి</b>{' '}
            [వాణిజ్య, సామాజిక ప్రయోజనాల కోసం మరియు / లేదా మీ సమ్మతితో]. మేము
            మరియు / లేదా మా థర్డ్ పార్టీ మార్కెటింగ్ భాగస్వాములు మీరు మాకు పంపే
            వ్యక్తిగత సమాచారాన్ని మా మార్కెటింగ్ ప్రయోజనాల కోసం ఉపయోగించవచ్చు,
            ఇది మీ మార్కెటింగ్ ప్రాధాన్యతలకు అనుగుణంగా ఉంటే. మీరు ఎప్పుడైనా మా
            మార్కెటింగ్ ఇ-మెయిల్‌లను నిలిపివేయవచ్చు (ఈ కింద పేర్కొనబడిన &quot;మీ
            గోప్యతా హక్కులు&quot; చూడండి).
          </p>
          <p>
            - <b>వాణిజ్య, సామాజిక ప్రయోజనాల</b> చట్టపరమైన కారణాలు మరియు / లేదా
            బహుశా ఒప్పందానికి సంబంధించిన పరిపాలనా సమాచారాన్ని మీకు పంపడానికి.
            ప్లాట్‌ఫారం / యాప్ ద్వారా మీకు అందించే సేవలను మెరుగుపరుస్తూ, మా
            నిబంధనలు, షరతులు, విధానాలకు మార్పుల గురించి మీకు ఉత్పత్తి, సేవ,
            కొత్త ఫీచర్ సమాచారం మరియు / లేదా సమాచారాన్ని పంపడానికి మేము మీ
            వ్యక్తిగత సమాచారాన్ని ఉపయోగించవచ్చు;
          </p>
          <p>
            - <b>ప్లాట్‌ఫారం‌ను విశ్లేషించడానికి మరియు ఆపరేట్ చేయడానికి,</b>{' '}
            నిర్వహించడానికి, మెరుగుపరచడానికి, ప్లాట్‌ఫారం‌కు కొత్త ఫీచర్‌లు,
            సేవలను జోడించడానికి, ప్లాట్‌ఫారం ప్రస్తుత ఫంక్షన్‌లకు మద్దతు
            ఇవ్వడానికి;
          </p>
          <p>
            - <b>థర్డ్ పార్టీ ఉత్పత్తులు, సమర్పణలతో సహా మీకు</b> ఉత్పత్తి, సేవా
            సమర్పణలు, సిఫార్సులను అనుకూలీకరించడానికి, మీ ఖాతాకు సంబంధించి మీకు
            తెలియజేయడానికి, మీ ఖాతాతో సమస్యలను పరిష్కరించేందుకు, వివాదాన్ని
            పరిష్కరించడానికి, రుసుములు లేదా బకాయిలను సేకరించడానికి, మీ
            గుర్తింపును నిర్ధారించడానికి మీరు ప్లాట్‌ఫారం/యాప్‌ని ఉపయోగించడానికి
            అర్హులని నిర్ధారించుకోవడానికి;
          </p>
          <p>
            - <b>మీరు అభ్యర్థించే ఉత్పత్తులు, సేవలను అందించడానికి,</b> డెలివరీ
            చేయడానికి, లావాదేవీలను ప్రాసెస్ చేయడానికి, నిర్ధారణలు, రిమైండర్‌లతో
            సహా సంబంధిత సమాచారాన్ని మీకు పంపడానికి;
          </p>
          <p>
            - <b>బిల్లింగ్ (ఇన్‌వాయిసింగ్), ఖాతా నిర్వహణ,</b> ఇతర పరిపాలనా
            ప్రయోజనాల కోసం, వర్తిస్తే;
          </p>
          <p>
            - <b>మీ వ్యాఖ్యలు, ప్రశ్నలుమరియు అభ్యర్థనలకు</b>{' '}
            ప్రతిస్పందించడానికి, కస్టమర్ సేవను అందించడానికి;
          </p>
          <p>
            - <b>మీకు సాంకేతిక నోటీసులు, నవీకరణలు,</b> భద్రతా హెచ్చరికలు,
            మద్దతు, నిర్వాహక సందేశాలను పంపడానికి;
          </p>
          <p>
            - <b>మా ప్లాట్‌ఫారం‌కు సంబంధించి ట్రెండ్‌లు,</b> వినియోగం,
            కార్యకలాపాలను పర్యవేక్షించడానికి మరియు విశ్లేషించడానికి;
          </p>
          <p>
            - కోవెస్ట్రో <b>ప్రచార ప్రయోజనాల కోసం;</b> మీరు భాగస్వామ్యం
            చేయడానికి అంగీకరించే సమాచారానికి సంబంధించి మాత్రమే;
          </p>
          <p>
            - <b>మీ గుర్తింపును ధృవీకరించడానికి;</b>
          </p>
          <p>
            - మా వ్యాపార ప్రయోజనాల కోసం మరియు / లేదా మీ సమ్మతితో{' '}
            <b>మీకు లక్ష్య ప్రకటనలను అందించండి.</b> మేము కంటెంట్, ప్రకటనలను
            అభివృద్ధి చేయడానికి, ప్రదర్శించడానికి మీ సమాచారాన్ని ఉపయోగించవచ్చు,
            మీ ఆసక్తులు మరియు / లేదా స్థానానికి అనుగుణంగా, దాని ప్రభావాన్ని
            అంచనా వేసే థర్డ్ పార్టీలతో కలిసి పని చేయవచ్చు.
          </p>
          <p>
            - మా వ్యాపార ప్రయోజనాల కోసం మరియు / లేదా{' '}
            <b>మీ సమ్మతితో బహుమతి డ్రాలు, పోటీలను నిర్వహించండి.</b> మీరు పోటీలలో
            పాల్గొనడానికి ఎంచుకున్నప్పుడు బహుమతి డ్రాలు, పోటీలను నిర్వహించడానికి
            మేము మీ సమాచారాన్ని ఉపయోగించవచ్చు.;
          </p>
          <p>
            - మా వ్యాపార ప్రయోజనాల కోసం మరియు / లేదా మీ సమ్మతితో{' '}
            <b>అభిప్రాయం కొరకు అభ్యర్థిస్తాం</b> అభిప్రాయాన్ని అభ్యర్థించడానికి,
            మా ప్లాట్‌ఫారం మీ ఉపయోగం గురించి మిమ్మల్ని సంప్రదించడానికి మేము మీ
            సమాచారాన్ని ఉపయోగించవచ్చు;
          </p>
          <p>
            - వ్యాపార ప్రయోజనాల కోసం మరియు / లేదా చట్టపరమైన కారణాల కోసం{' '}
            <b>మా ప్లాట్‌ఫారం‌ను రక్షించడానికి.</b> మా ప్లాట్‌ఫారం‌ను
            సురక్షితంగా, సురక్షితంగా ఉంచడానికి మా ప్రయత్నాలలో భాగంగా మేము మీ
            సమాచారాన్ని ఉపయోగించవచ్చు (ఉదాహరణకు, మోసం పర్యవేక్షణ, నివారణ కోసం);
          </p>
          <p>
            - వ్యాపార ప్రయోజనాల కోసం{' '}
            <b>
              మా నిబంధనలు, షరతులు, విధానాలను అమలు చేయడానికి, చట్టపరమైన కారణాలు
              మరియు / లేదా బహుశా ఒప్పందానికి;
            </b>
          </p>
          <p>
            - చట్టపరమైన అభ్యర్థనలకు{' '}
            <b>
              ప్రతిస్పందించడానికి, చట్టపరమైన కారణాల వల్ల హానిని నిరోధించడానికి.
            </b>{' '}
            మేము సబ్‌పోనా లేదా ఇతర చట్టపరమైన అభ్యర్థనను స్వీకరిస్తే, ఎలా
            ప్రతిస్పందించాలో నిర్ణయించడానికి మేము కలిగి ఉన్న డేటాను తనిఖీ
            చేయాల్సి ఉంటుంది;
          </p>
          <p>
            - <b>ఇతర వాణిజ్య మరియు సామాజిక ప్రయోజనాల కోసం.</b> డేటా విశ్లేషణ,
            వినియోగ ట్రెండ్‌లను గుర్తించడం, మా ప్రచార ప్రచారాల ప్రభావాన్ని
            గుర్తించడం, మా ప్లాట్‌ఫారం, మీ అనుభవాన్ని విశ్లేషించడం, మెరుగుపరచడం
            వంటి ఇతర వ్యాపార ప్రయోజనాల కోసం మేము మీ సమాచారాన్ని ఉపయోగించవచ్చు;
            మరియు
          </p>
          <p>
            - <b>కన్షార్షియం భాగస్వాముల కోసం.</b> ప్లాట్‌ఫారం‌లో నమోదు చేసుకున్న
            మా కన్సార్టియం భాగస్వాములు, యూజర్లు మీరు ప్లాట్‌ఫారం‌పై ఆసక్తి చూపిన
            ఉత్పత్తులు, సేవల కోసం మీ సమాచారాన్ని ఉపయోగించవచ్చు.
          </p>
        </li>
        <li>
          <h3>మీ సమాచారం ఎవరితోనైనా పంచుకోబడుతుందా?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> మేము మీ సమ్మతితో మాత్రమే సమాచారాన్ని
              పంచుకుంటాం, చట్టాలకు లోబడి ఉండటానికి, మీ హక్కులను రక్షించడానికి
              లేదా వ్యాపార బాధ్యతలను నెరవేర్చడానికి.
            </i>
          </p>
          <p>
            మేము మీ సమాచారాన్ని క్రింది సందర్భాలలో మాత్రమే భాగస్వామ్యం చేస్తాము,
            వెల్లడిస్తాం:
          </p>
          <p>
            - <b>వర్తించే చట్టాలు.</b> కోర్టు ఆర్డర్ లేదా సబ్పోనా ప్రతిస్పందనతో
            సహా వర్తించే చట్టం, ప్రభుత్వ అభ్యర్థనలు, న్యాయపరమైన విచారణ, కోర్టు
            ఆర్డర్ లేదా చట్టపరమైన ప్రక్రియకు కట్టుబడి ఉండటానికి మేము చట్టబద్ధంగా
            చేయవలసిన మీ సమాచారాన్ని మేము బహిర్గతం చేయవచ్చు (జాతీయ భద్రత లేదా
            చట్ట అమలు అవసరాలను తీర్చడానికి ప్రభుత్వ అధికారులకు).
          </p>
          <p>
            - <b>కీలకమైన ఆసక్తులు, చట్టపరమైన హక్కులు.</b> మా విధానంల సంభావ్య
            ఉల్లంఘనలు, అనుమానిత మోసం, ఏదైనా వ్యక్తి భద్రతకు సంభావ్య బెదిరింపులు,
            చట్టవిరుద్ధ కార్యకలాపాలకు సంబంధించిన పరిస్థితులు లేదా వ్యాజ్యంలో
            సాక్ష్యంగా ఉండటం వంటి వాటికి సంబంధించి దర్యాప్తు చేయడం, నిరోధించడం
            లేదా చర్య తీసుకోవడం అవసరమని మేము విశ్వసిస్తున్న చోట మేము
            పాలుపంచుకున్న విషయంలో మీ సమాచారాన్ని బహిర్గతం చేయవచ్చు.
          </p>
          <p>
            -{' '}
            <b>
              విక్రేతలు, కన్సల్టెంట్‌లు మమరియు ఇతర థర్డ్-పార్టీ సర్వీస్
              ప్రొవైడర్లు.
            </b>{' '}
            మేము మీ డేటాను మా కోసం లేదా మా తరపున సేవలను అందించే థర్డ్ పార్టీ
            విక్రేతలు, సర్వీస్ ప్రొవైడర్లు, కాంట్రాక్టర్లు లేదా ఏజెంట్లతో షేర్
            చేయవచ్చు, ఆ పని చేయడానికి అటువంటి సమాచారానికి ప్రాప్యత పొందాల్సి
            ఉంటుంది. ఉదాహరణలు: చెల్లింపు ప్రాసెసింగ్, డేటా విశ్లేషణ, ఇ-మెయిల్
            డెలివరీ, హోస్టింగ్ సేవలు, కస్టమర్ సేవ, మార్కెటింగ్ ప్రయత్నాలు.
            ప్లాట్‌ఫారం‌లో ట్రాకింగ్ టెక్నాలజీని ఉపయోగించడానికి మేము ఎంచుకున్న
            థర్డ్ పార్టీలను అనుమతించవచ్చు, ఇది కాలక్రమేణా మీరు ప్లాట్‌ఫారం‌తో
            ఎలా పరస్పర చర్య చేసే దాని గురించి డేటాను సేకరించడానికి వారిని
            అనుమతిస్తుంది. ఈ సమాచారం ఇతర విషయాలతోపాటు, డేటాను విశ్లేషించడానికి,
            ట్రాక్ చేయడానికి, నిర్దిష్ట కంటెంట్ జనాదరణను గుర్తించడానికి,
            ఆన్‌లైన్ కార్యాచరణను బాగా అర్థం చేసుకోవడానికి ఉపయోగించబడుతుంది. ఈ
            విధానంలో వివరించినంత వరకు, మేము థర్డ్ పార్టీల ప్రచార ప్రయోజనాల కోసం
            మీ సమాచారాన్ని భాగస్వామ్యం చేయము, అలాగే వాటిని విక్రయించం, అద్దెకు
            ఇవ్వడం లేదా వ్యాపారం చేయము.
          </p>
          <p>
            - <b>వ్యాపార బదిలీలు.</b> ఏదైనా విలీనం, కంపెనీ ఆస్తుల విక్రయం,
            ఫైనాన్సింగ్ లేదా మా వ్యాపారంలోని మొత్తం లేదా కొంత భాగాన్ని మరొక
            కంపెనీకి కొనుగోలు చేయడం వంటి వాటికి సంబంధించి లేదా చర్చల సమయంలో మేము
            మీ సమాచారాన్ని పంచుకోవచ్చు లేదా బదిలీ చేయవచ్చు.
          </p>
          <p>
            - <b>థర్డ్ పార్టీ ప్రకటనదారులు.</b> మీరు ప్లాట్‌ఫారాన్ని
            సందర్శించినప్పుడు ప్రకటనలను అందించడానికి మేము థర్డ్ పార్టీ ప్రకటనల
            కంపెనీలను ఉపయోగించవచ్చు. ఈ కంపెనీలు మీకు ఆసక్తి ఉన్న వస్తువులు, సేవల
            గురించి ప్రకటనలను అందించడానికి వెబ్ కుకీలు, ఇతర ట్రాకింగ్
            టెక్నాలజీలలో ఉన్న మా ప్లాట్‌ఫారం(లు), ఇతర ప్లాట్‌ఫారం‌లకు మీ
            సందర్శనల గురించిన సమాచారాన్ని ఉపయోగించవచ్చు. [మరింత సమాచారం కోసం మా
            కుకీ విధానం [హైపర్‌లింక్] చూడండి]
          </p>
          <p>
            - <b>అనుబంధ సంస్థలు.</b> మేము మీ సమాచారాన్ని మా అనుబంధ సంస్థలతో
            పంచుకోవచ్చు, ఈ సందర్భంలో ఆ అనుబంధ సంస్థలు ఈ గోప్యతా విధానాన్ని
            గౌరవించవలసి ఉంటుంది. మా అనుబంధాలలో మా మాతృ సంస్థ, ఏదైనా అనుబంధ
            సంస్థలు, జాయింట్ వెంచర్ భాగస్వాములు లేదా మేము నియంత్రించే లేదా మాతో
            ఉమ్మడి నియంత్రణలో ఉన్న ఇతర కంపెనీలు ఉంటాయి.
          </p>
          <p>
            - <b>వ్యాపార భాగస్వాములు.</b> మీకు నిర్దిష్ట ఉత్పత్తులు, సేవలు లేదా
            ప్రమోషన్‌లను అందించడానికి మేము మీ సమాచారాన్ని మా వ్యాపార
            భాగస్వాములతో పంచుకోవచ్చు.
          </p>
          <p>
            - <b>మీ సమ్మతితో.</b> మేము మీ సమ్మతితో ఏదైనా ఇతర ప్రయోజనం కోసం మీ
            వ్యక్తిగత సమాచారాన్ని బహిర్గతం చేయవచ్చు.
          </p>
          <p>
            - <b>ఇతర యూజర్లు.</b> మీరు వ్యక్తిగత సమాచారాన్ని (ఉదాహరణకు,
            ప్లాట్‌ఫారం‌కు వ్యాఖ్యలు, సహకారాలు లేదా ఇతర కంటెంట్‌ను పోస్ట్ చేయడం
            ద్వారా) లేదా ప్లాట్‌ఫారం‌లోని పబ్లిక్ ప్రాంతాలతో పరస్పర చర్య
            చేసినప్పుడు అటువంటి వ్యక్తిగత సమాచారాన్ని యూజర్లందరూ వీక్షించవచ్చు,
            శాశ్వతంగా ప్లాట్‌ఫారం వెలుపల పబ్లిక్‌గా పంపిణీ చేయబడవచ్చు. [మీరు మా
            ప్లాట్‌ఫారం‌లోని ఇతర యూజర్లతో పరస్పర చర్య చేసి, సోషల్ నెట్‌వర్క్
            (Facebook వంటివి) ద్వారా నమోదు చేసుకుంటే, సోషల్ నెట్‌వర్క్‌లోని మీ
            కాంటాక్టులు మీ పేరు, ప్రొఫైల్ ఫోటో, మీ కార్యాచరణ వివరణలను చూడవచ్చు.]
            అదేవిధంగా, ఇతర యూజర్లు మీ కార్యాచరణ వివరణలను వీక్షించగలరు, మా
            ప్లాట్‌ఫారం‌లో మీతో కమ్యూనికేట్ చేయగలరు, మీ ప్రొఫైల్‌ను
            వీక్షించగలరు.
          </p>
        </li>
        <li>
          <h3>మేము కుకీలు మరియు ఇతర ట్రాకింగ్ సాంకేతికతలను ఉపయోగిస్తామా?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> మేము మీ సమాచారాన్ని సేకరించడానికి, నిల్వ
              చేయడానికి కుకీలు, ఇతర ట్రాకింగ్ టెక్నాలజీలను ఉపయోగించవచ్చు.
            </i>
          </p>
          <p>
            ఏదైనా ఫిర్యాదీ సంస్థగా, మేము మీ అనుభవాన్ని, మా ఉత్పత్తులు, సేవలను
            మెరుగుపరచడంలో మాకు సహాయపడే కుకీలు, సారూప్య సాంకేతికతల ద్వారా కూడా
            సమాచారాన్ని సేకరిస్తాము. మేము ఉపయోగించే కుకీలు మా ప్లాట్‌ఫారం‌ను పని
            చేయడానికి అనుమతిస్తాయి, సందర్శకులకు ఏ సమాచారం, ప్రకటనలు ఎక్కువగా
            ఉపయోగపడతాయో అర్థం చేసుకోవడంలో మాకు సహాయపడతాయి.
          </p>
          <p>
            చాలా వెబ్ బ్రౌజర్‌లు డిఫాల్ట్‌గా కుకీలను ఆమోదించడానికి సెట్
            చేయబడ్డాయి. మీరు కావాలనుకుంటే, కుకీలను తీసివేయడానికి, కుకీలను
            తిరస్కరించడానికి మీరు సాధారణంగా మీ బ్రౌజర్‌ని సెట్ చేయడాన్ని
            ఎంచుకోవచ్చు. మీరు కుకీలను తీసివేయాలని లేదా కుకీలను తిరస్కరించాలని
            ఎంచుకుంటే, ఇది మా ప్లాట్‌ఫారం నిర్దిష్ట ఫీచర్లు లేదా సేవలను
            ప్రభావితం చేయవచ్చు.
          </p>
          <p>యూజర్‌సెంట్రిక్స్ టెక్నాలజీ (సమ్మతి సాధనం)</p>
          <p>
            ఈ సమ్మతి అవసరమయ్యే కుకీల కోసం, కుకీ-ఆధారిత అప్లికేషన్‌ల కోసం
            సమర్థవంతమైన యూజర్ సమ్మతిని పొందడానికి, మా వెబ్‌సైట్
            యూజర్‌సెంట్రిక్స్ GmbH, Sendlinger Str ద్వారా అభివృద్ధి చేయబడిన
            సాంకేతికతతో కుకీ సమ్మతి సాధనాన్ని ఉపయోగిస్తుంది. 7, 80331 మ్యూనిచ్
            (ఇకపై &quot;యూజర్‌సెంట్రిక్స్&quot;గా సూచిస్తారు).
          </p>
          <p>
            సంబంధిత JavaScript కోడ్‌ని ఏకీకృతం చేయడం ద్వారా, యూజర్లు పేజీని
            సందర్శించినప్పుడు వారికి బ్యానర్ చూపబడుతుంది, దీనిలో నిర్దిష్ట
            కుకీలు మరియు / లేదా కుకీ-ఆధారిత అప్లికేషన్‌ల కోసం సమ్మతి పెట్టెలను
            తనిఖీ చేయడం ద్వారా అందించబడుతుంది. టూల్ బాక్సులను తనిఖీ చేయడం ద్వారా
            సంబంధిత యూజర్ సమ్మతి ఇచ్చే వరకు సమ్మతి అవసరమయ్యే అన్ని కుకీల
            సెట్టింగ్‌ను సాధనం బ్లాక్ చేస్తుంది. సమ్మతి ఇవ్వబడినప్పుడు అటువంటి
            కుకీలు యూజర్ సంబంధిత తుది పరికరంలో మాత్రమే సెట్ చేయబడతాయని ఇది
            నిర్ధారిస్తుంది.
          </p>
          <p>
            వెబ్‌సైట్ దిగువన కుడివైపున ఉన్న UserCentrics బటన్‌ను నొక్కడం ద్వారా
            యూజర్ ఏ సమయంలోనైనా సెట్టింగ్‌లను మార్చవచ్చు.
          </p>
          <p>
            మా వెబ్‌సైట్ యాక్సెస్ చేయబడినప్పుడు, నిర్దిష్ట యూజర్ సమాచారం (IP
            చిరునామాతో సహా) కుకీ సమ్మతి సాధనం ద్వారా సేకరించబడుతుంది, యూజర్
            సెంట్రిక్‌ల సర్వర్‌లకు ప్రసారం చేయబడుతుంది, అక్కడ సేవ్ చేయబడుతుంది,
            తద్వారా కుకీ సమ్మతి సాధనం వ్యక్తిగత యూజర్లకు పేజీ వీక్షణలను
            స్పష్టంగా కేటాయించగలదు, వ్యక్తిగతంగా రికార్డ్ చేస్తుంది, లాగ్
            చేస్తుంది, సెషన్ వ్యవధి కోసం యూజర్ చేసిన సమ్మతి సెట్టింగ్‌లను సేవ్
            చేయండి.
          </p>
          <p>
            మీరు Usercentrics గోప్యతా విధానం{' '}
            <a
              href='https://usercentrics.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              https://usercentrics.com/privacy-policy/
            </a>{' '}
            లో యూజర్‌సెంట్రిక్స్ ద్వారా డేటా వినియోగంపై అదనపు సమాచారాన్ని
            కనుగొనవచ్చు.
          </p>
          <p>
            ఈ దిగువన ఉన్న “మమ్మల్ని సంప్రదించండి” ద్వారా మాకు ఇ-మెయిల్ పంపడం
            ద్వారా లేదా అభ్యర్థనను సమర్పించడం ద్వారా మీకు ఏవైనా సందేహాలు ఉంటే
            మాకు తెలియజేయండి.
          </p>
        </li>
        <li>
          <h3>మేము లొకేషన్ సేవలను ఉపయోగిస్తామా?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> అవును, మెరుగైన సేవను అందించడం కోసం మేము లొకేషన్
              సేవలను, అంతర్నిర్మిత ప్రోగ్రామ్ లేదా Google Maps వంటి బాహ్య
              ప్రదాతను ఉపయోగిస్తాము
            </i>
          </p>
          <p>
            ఈ విధంగా సేకరించిన సమాచారం ఈ విధానం ప్రయోజనాల కోసం మాత్రమే
            ఉపయోగించబడుతుంది, అటువంటి సర్వీస్ ప్రొవైడర్ వినియోగ నిబంధనలు, షరతులు
            మా పరిధి, నియంత్రణకు మించినవి.
          </p>
        </li>
        <li>
          <h3>మీ సమాచారం అంతర్జాతీయంగా బదిలీ చేయబడుతుందా?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> మేము మీ సమాచారాన్ని మీ స్వంత దేశాలలో కాకుండా
              ఇతర దేశాలకు బదిలీ చేయవచ్చు, నిల్వ చేయవచ్చు, ప్రాసెస్ చేయవచ్చు.
            </i>
          </p>
          <p>
            మేము మా కోసం మీ వ్యక్తిగత డేటాతో నిర్దిష్ట కార్యకలాపాలను నిర్వహించే
            ప్రాసెసర్‌లను నిమగ్నం చేస్తాము. ప్రాసెసర్‌లు అంటే ప్లాట్‌ఫారం‌ను
            అమలు చేయడం, మీతో మా కమ్యూనికేషన్‌కు మద్దతు ఇవ్వడం లేదా ఇతర
            ప్లాట్‌ఫారం సంబంధిత కార్యకలాపాలను చేయడంలో మాకు సహాయపడే కంపెనీలు.
            వెబ్‌సైట్, యాప్ ఫంక్షన్‌లు, అనుబంధ కార్యకలాపాలకు సంబంధించిన
            లక్ష్యాలను సాధించడానికి వారు మా తరపున నిర్దిష్ట వ్యక్తిగత డేటాను
            ప్రాసెస్ చేయవచ్చు. ప్రాసెసర్‌లు మా సూచనలకు అనుగుణంగా మాత్రమే
            పనిచేస్తాయి, మేము వాటిని ప్రాసెస్ చేయమని సూచించే వ్యక్తిగత డేటాను
            మాత్రమే ప్రాసెస్ చేస్తాయి. మా ప్రాసెసర్‌ల ఏవైనా చర్యలు లేదా లోపాలకు
            మేము పూర్తి బాధ్యత వహిస్తాము, వర్తించే చట్టం ప్రకారం అవసరమైన మేరకు
            వారితో అధికారిక డేటా ప్రాసెసింగ్ ఒప్పందాలను అమలు చేయడానికి మేము
            బాధ్యత వహిస్తాము.
          </p>
          <p>
            మా ప్రధాన ప్రాసెసర్ Amazon Web Services Ince (AWS). Amazon Web
            Services, Inc. యునైటెడ్ స్టేట్స్‌లో ఉంది, వాటి అనుబంధ కంపెనీలు
            ప్రపంచవ్యాప్తంగా ఉన్నాయి. AWS ఆఫర్‌లతో మా పరస్పర చర్యల పరిధిని
            బట్టి, వ్యక్తిగత సమాచారం యునైటెడ్ స్టేట్స్‌తో సహా అనేక దేశాలలో నిల్వ
            చేయబడవచ్చు లేదా యాక్సెస్ చేయబడవచ్చు. AWS వ్యక్తిగత సమాచారాన్ని ఇతర
            అధికార పరిధికి బదిలీ చేసినప్పుడల్లా, సమాచారం దాని గోప్యతా నోటీసు (
            <a
              href='https://aws.amazon.com/privacy/'
              target='_blank'
              rel='noreferrer'
            >
              https://aws.amazon.com/privacy/
            </a>
            ) కి అనుగుణంగా, వర్తించే డేటా రక్షణ చట్టాల ద్వారా అనుమతించబడినట్లు
            బదిలీ చేయబడిందని నిర్ధారిస్తుంది.
          </p>
          <p>
            వీటితో పాటుగా సేకరించిన వ్యక్తిగత డేటా కోవెస్ట్రో సర్వర్‌లను అద్దెకు
            తీసుకునే దేశాల్లో లేదా కోవెస్ట్రో లేదా దాని అనుబంధ సంస్థలు, అనుబంధ
            సంస్థలు లేదా ఏజెంట్లు సౌకర్యాలను నిర్వహించే ఏ ఇతర దేశంలోనైనా నిల్వ
            చేయబడవచ్చని, ప్రాసెస్ చేయబడవచ్చని మీరు అంగీకరిస్తున్నారు,
            ప్లాట్‌ఫారం‌ను ఉపయోగించడం ద్వారా మీ దేశం వెలుపల వ్యక్తిగత డేటా
            బదిలీకి మీరు దేనికైనా సమ్మతిస్తారు.
          </p>
        </li>
        <li>
          <h3>థర్డ్-పార్టీ ప్లాట్‌ఫారం‌పై మా వైఖరి ఏమిటి?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> మా ప్లాట్‌ఫారం‌తో అనుబంధించబడని, ప్రకటనలు ఇచ్చే
              థర్డ్-పార్టీ ప్రొవైడర్‌లతో మీరు భాగస్వామ్యం చేసే ఏదైనా సమాచారం
              భద్రతకు మేము బాధ్యత వహించం.
            </i>
          </p>
          <p>
            ప్లాట్‌ఫారం‌లో మాతో అనుబంధించని థర్డ్ పార్టీల నుండి లింక్‌లు,
            ప్రకటనలు ఉండవచ్చు, ఇతర ప్లాట్‌ఫారం‌లు, యాప్‌లు, ఆన్‌లైన్ సేవలు లేదా
            మొబైల్ అప్లికేషన్‌లకు లింక్ చేయబడవచ్చు. అటువంటి ప్రకటనలను యాక్సెస్
            చేయడం ద్వారా మీరు ఏదైనా థర్డ్ పార్టీలకు అందించే డేటా భద్రత, గోప్యతకు
            మేము హామీ ఇవ్వలేము.
          </p>
          <p>
            థర్డ్ పార్టీలు సేకరించిన ఏదైనా డేటా ఈ గోప్యతా విధానం పరిధిలోకి రాదు.
          </p>
          <p>
            ప్లాట్‌ఫారం‌కు లేదా దాని నుండి లింక్ చేయబడే ఇతర ప్లాట్‌ఫారం, సేవలు
            లేదా అప్లికేషన్‌లతో సహా ఏదైనా థర్డ్ పార్టీల కంటెంట్ లేదా గోప్యత,
            భద్రతా పద్ధతులు, విధానాలకు మేము బాధ్యత వహించం. మీరు అటువంటి థర్డ్
            పార్టీల విధానాలను సమీక్షించాలి, మీ ప్రశ్నలకు ప్రతిస్పందించడానికి
            నేరుగా వారిని సంప్రదించాలి.
          </p>
        </li>
        <li>
          <h3>మేము మీ సమాచారాన్ని ఎంతకాలం పాటు ఉంచుతాము?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> చట్టం ప్రకారం అవసరమైతే మినహా ఈ గోప్యతా విధానంలో
              పేర్కొన్న ప్రయోజనాలను నెరవేర్చడానికి మేము మీ సమాచారాన్ని అవసరమైనంత
              కాలం పాటు ఉంచుతాము.
            </i>
          </p>
          <p>
            మేము మీ వ్యక్తిగత సమాచారాన్ని ఈ గోప్యతా విధానంలో పేర్కొన్న ప్రయోజనాల
            కోసం అవసరమైనంత కాలం మాత్రమే ఉంచుతాం, ఎక్కువ కాలం నిలుపుదల వ్యవధి
            అవసరం లేదా చట్టం ద్వారా అనుమతించబడినట్లయితే (పన్ను, అకౌంటింగ్ లేదా
            ఇతర చట్టపరమైన అవసరాలు వంటివి). ఈ విధానంలో ఎటువంటి ఉద్దేశ్యంతో మీరు
            మా వద్ద ఖాతాను కలిగి ఉన్న కాలం కంటే ఎక్కువ కాలం పాటు మీ వ్యక్తిగత
            సమాచారాన్ని ఉంచడం మాకు అవసరం లేదు (లేదా) మీ ఖాతా రద్దు చేయబడిన 1
            సంవత్సరం తర్వాత, ఏది తర్వాత ఉంటుందో అది.
          </p>
          <p>
            మీ వ్యక్తిగత సమాచారాన్ని ప్రాసెస్ చేయడానికి మాకు కొనసాగుతున్న
            చట్టబద్ధమైన వ్యాపార అవసరం లేనప్పుడు, మేము దానిని తొలగిస్తాము లేదా
            అజ్ఞాతం చేస్తాము లేదా ఇది సాధ్యం కాకపోతే (ఉదాహరణకు, మీ వ్యక్తిగత
            సమాచారం బ్యాకప్ ఆర్కైవ్‌లలో నిల్వ చేయబడినందున), మేము సురక్షితంగా
            నిల్వ చేస్తాము, మీ వ్యక్తిగత సమాచారం, తొలగింపు సాధ్యమయ్యే వరకు
            తదుపరి ప్రాసెసింగ్ నుండి దానిని వేరు చేయండి.
          </p>
        </li>
        <li>
          <h3>మేము మీ సమాచారాన్ని ఎలా సురక్షితంగా ఉంచుతాము?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> మేము సంస్థాగత, సాంకేతిక భద్రతా చర్యల వ్యవస్థ
              ద్వారా మీ వ్యక్తిగత సమాచారాన్ని రక్షించాలని లక్ష్యంగా
              పెట్టుకున్నాము.
            </i>
          </p>
          <p>
            మేము ప్రాసెస్ లేదా స్వీకరించే ఏదైనా వ్యక్తిగత సమాచారం భద్రతను
            రక్షించడానికి రూపొందించిన తగిన సాంకేతిక, సంస్థాగత భద్రతా చర్యలను
            మేము అమలు చేశాము. ఏదైనా ఉల్లంఘనను గుర్తించడానికి, గుర్తించడానికి
            తగిన జాగ్రత్తలు, రక్షణ చేపట్టబడతాయి, దాని కోసం తగిన దిద్దుబాటు చర్య
            ప్రారంభించబడుతుంది. అయినప్పటికీ, అవసరమైన భద్రతా చర్యలు అమలు
            చేయబడినప్పటికీ, సాంకేతిక ఇంటర్‌ఫేస్ కారణంగా సంభావ్య ప్రమాదాలు
            ఉండవచ్చు అని మేము మీకు తెలియజేస్తాము.
          </p>
          <p>
            మేము మీ వ్యక్తిగత సమాచారాన్ని రక్షించడానికి మా వంతు కృషి
            చేసినప్పటికీ, మా ప్లాట్‌ఫారం‌కు, దాని నుండి వ్యక్తిగత సమాచారాన్ని
            ప్రసారం చేయడం మీ స్వంత పూచీకత్తుతో మాత్రమే ఉంటుంది. మీరు సురక్షితమైన
            వాతావరణంలో మాత్రమే సేవలకు ప్రాప్యత పొందాలి.
          </p>
        </li>
        <li>
          <h3>మేము మైనర్‌ల నుండి సమాచారాన్ని సేకరిస్తామా?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> మేము 16 సంవత్సరాల కంటే తక్కువ వయస్సు ఉన్న
              పిల్లల నుండి సమాచారాన్ని సేకరించం లేదా మార్కెట్ చేయము.
            </i>
          </p>
          <p>
            <b>సాధారణ వయస్సు పరిమితి.</b> మేము పిల్లల గోప్యతను రక్షించడానికి
            కట్టుబడి ఉన్నాము. ప్లాట్‌ఫారం పిల్లల కోసం ఉద్దేశించబడలేదు, మేము
            ఉద్దేశపూర్వకంగా 16 ఏళ్లలోపు పిల్లల గురించి సమాచారాన్ని సేకరించము..
            ప్లాట్‌ఫారం 16 సంవత్సరాల కంటే తక్కువ వయస్సు ఉన్న కోవెస్ట్రోకు
            తెలిసిన ఏ వ్యక్తి నుండి వ్యక్తిగత డేటాను సేకరించదు. ప్లాట్‌ఫారం‌ను
            16 ఏళ్లలోపు ఎవరైనా ఉపయోగిస్తున్నట్లు మీకు తెలిస్తే, దయచేసి మమ్మల్ని{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            లో సంప్రదించండి, మేము అలాంటి సమాచారాన్ని తొలగించడానికి, (లేదా)
            అతని/ఆమె ఖాతాను తొలగించడానికి అవసరమైన చర్యలను తీసుకుంటాము.
          </p>
          <p>
            <b>యూరోపియన్ యూనియన్ నివాసితులకు వయస్సు పరిమితి.</b> చట్టపరమైన
            అవసరాల కారణంగా మీరు వెబ్‌సైట్, యాప్‌ని ఉపయోగించడానికి కనీసం 16
            సంవత్సరాల వయస్సు కలిగి ఉండాలి. ఈ వెబ్‌సైట్ ఏ యూరోపియన్ యూనియన్
            నివాసితుల కోసం ఉద్దేశించినది కాదు. మీరు లేదా మీకు తెలిసిన యూజర్డు
            యూరోపియన్ యూనియన్ నివాసి అయితే, ప్లాట్‌ఫారం‌ని ఉపయోగిస్తుంటే, దయచేసి
            మమ్మల్ని{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            లో సంప్రదించండి, మేము అలాంటి సమాచారాన్ని తొలగించడానికి, (లేదా)
            అతని/ఆమె ఖాతాను తొలగించడానికి చర్యలు తీసుకుంటాము.
          </p>
        </li>
        <li>
          <h3>మీ గోప్యతా హక్కులు ఏమిటి?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> యూరోపియన్ ఎకనామిక్ ఏరియా వంటి కొన్ని
              ప్రాంతాలలో, మీ వ్యక్తిగత సమాచారానికి అధిక ప్రాప్యత, నియంత్రణను
              అనుమతించే హక్కులు మీకు ఉన్నాయి. మీరు ఎప్పుడైనా మీ ఖాతాను
              సమీక్షించవచ్చు, మార్చవచ్చు లేదా రద్దు చేయవచ్చు.
            </i>
          </p>
          <p>
            కొన్ని ప్రాంతాలలో (యూరోపియన్ ఎకనామిక్ ఏరియా వంటివి), వర్తించే డేటా
            రక్షణ చట్టాల ప్రకారం మీకు నిర్దిష్ట హక్కులు ఉంటాయి. వీటిలో హక్కు (i)
            యాక్సెస్‌ని అభ్యర్థించడం, మీ వ్యక్తిగత సమాచారం కాపీని పొందడం, (ii)
            సరిదిద్దడం లేదా ఎరేజర్‌ని అభ్యర్థించడం; (iii) మీ వ్యక్తిగత సమాచారం
            ప్రాసెసింగ్‌ను పరిమితం చేయడం;, (iv) ఒకవేళ వర్తిస్తే, డేటా
            పోర్టబిలిటీకి. నిర్దిష్ట పరిస్థితులలో, మీ వ్యక్తిగత సమాచారాన్ని
            ప్రాసెస్ చేయడంపై అభ్యంతరం చెప్పే హక్కు కూడా మీకు ఉండవచ్చు. అలాంటి
            అభ్యర్థన చేయడానికి, దయచేసి ఈ కింద పేర్కొనబడిన కాంటాక్ట్ వివరాలను
            [హైపర్‌లింక్] ఉపయోగించండి. అయితే, వర్తించే డేటా రక్షణ చట్టాలకు
            అనుగుణంగా ఏదైనా అభ్యర్థనను పరిగణనలోకి తీసుకునే, చర్య తీసుకునే హక్కు
            మాకు ఉంది.
          </p>
          <p>
            మీ వ్యక్తిగత సమాచారాన్ని ప్రాసెస్ చేయడానికి మేము మీ సమ్మతిపై
            ఆధారపడుతున్నట్లయితే, మీ సమ్మతిని ఎప్పుడైనా ఉపసంహరించుకునే హక్కు మీకు
            ఉంటుంది. అయితే ఇది సమాచారం ఉపసంహరణకు ముందు ప్రాసెసింగ్ చట్టబద్ధతను
            ప్రభావితం చేయదని దయచేసి గమనించండి.
          </p>
          <p>
            మీరు యూరోపియన్ ఎకనామిక్ ఏరియాలో నివసిస్తుంటే, మేము మీ వ్యక్తిగత
            సమాచారాన్ని చట్టవిరుద్ధంగా ప్రాసెస్ చేస్తున్నామని మీరు విశ్వసిస్తే,
            మమ్మల్ని సంప్రదించాల్సిందిగా అభ్యర్థించారు.
          </p>
          <ol>
            <li>
              <p>
                <i>
                  <b>ఖాతా సమాచారం</b>
                </i>
              </p>
              <p>
                మీరు ఎప్పుడైనా మీ ఖాతాలోని సమాచారాన్ని సమీక్షించవచ్చు లేదా
                మార్చవచ్చు లేదా దీని ద్వారా మీ ఖాతాను ముగించవచ్చు:
              </p>
              <p>
                - మీ ఖాతా సెట్టింగ్‌లకు లాగిన్ చేయడం, మీ ఖాతాను నవీకరించడం; లేదా
              </p>
              <p>
                - దిగువ అందించిన సంప్రదింపు సమాచారాన్ని ఉపయోగించి మమ్మల్ని
                సంప్రదిస్తున్నాము
              </p>
              <p>
                మీ ఖాతాను రద్దు చేయమని మీరు చేసిన అభ్యర్థనపై, మేము విధానంకి
                అనుగుణంగా మా క్రియాశీల డేటాబేస్‌ల నుండి మీ ఖాతాను, సమాచారాన్ని
                నిష్క్రియం చేస్తాము లేదా తొలగిస్తాము. అయినప్పటికీ, మోసాన్ని
                నిరోధించడానికి, సమస్యలను పరిష్కరించేందుకు, ఏవైనా పరిశోధనలతో
                సహాయం చేయడానికి, మా ఉపయోగ నిబంధనలను అమలు చేయడానికి మరియు / లేదా
                చట్టపరమైన అవసరాలకు అనుగుణంగా మా ఫైల్‌లలో కొంత సమాచారం నిల్వ
                చేయబడవచ్చు.
              </p>
            </li>
            <li>
              <p>
                <i>
                  <b>పాలక చట్టం మరియు వివాద పరిష్కారం</b>
                </i>
              </p>
              <p>
                ఈ విధానం కింద ఏవైనా వివాదాలు తలెత్తితే, భారత చట్టాలకు లోబడి
                ఉండాలి, మహారాష్ట్రలోని ముంబై కోర్టులు దాని కోసం ప్రత్యేక అధికార
                పరిధిని కలిగి ఉంటాయి.
              </p>
              <p>
                - పైన పేర్కొన్న విధంగా ఈ విధానం వివరణ, అమలు లేదా ఉద్దేశపూర్వక
                ముగింపుకు సంబంధించి ఏదైనా వివాదం లేదా వ్యత్యాసం తలెత్తిన
                సందర్భంలో, పార్టీలు స్నేహపూర్వక సంప్రదింపుల ద్వారా అటువంటి
                వివాదాన్ని పరిష్కరించడానికి మొదటి సందర్భంలో ప్రయత్నిస్తాయి.
              </p>
              <p>
                - చర్చలు ప్రారంభమైన 30 (ముప్పై) రోజులలోపు స్నేహపూర్వక
                సంప్రదింపుల ద్వారా అటువంటి వివాదం పరిష్కరించబడకపోతే లేదా
                పార్టీలు వ్రాతపూర్వకంగా అంగీకరించినంత ఎక్కువ కాలం, అప్పుడు ఏ
                పార్టీ అయినా ఈ ఒప్పందం నిబంధనలకు అనుగుణంగా మధ్యవర్తిత్వం ద్వారా
                వివాదాన్ని పరిష్కరించవచ్చు.
              </p>
              <p>
                - అటువంటి వివాదాలన్నీ ఆర్బిట్రేషన్, రాజీ చట్టం, 1996 ప్రకారం,
                కాలానుగుణంగా, ఇండియన్ కౌన్సిల్ ఆఫ్ ఆర్బిట్రేషన్
                (&quot;ICA&quot;) నిబంధనల ప్రకారం దానికి సవరణలు లేదా
                పునర్నిర్మించడం ద్వారా ఆర్బిట్రేషన్ ద్వారా సూచించబడతాయి, చివరకు
                పరిష్కరించబడతాయి. ఈ క్లాజ్‌లో సూచన ద్వారా నియమాలు చేర్చబడినట్లు
                భావించబడతాయి. ట్రిబ్యునల్‌లో ముగ్గురు మధ్యవర్తులు ఉంటారు.
                వివాదాస్పద పక్షంలో ప్రతి ఒక్కరు ఒక మధ్యవర్తిని నియమిస్తారు, అలా
                నియమించబడిన ఇద్దరు మధ్యవర్తులు సమిష్టిగా మూడవ మధ్యవర్తిని
                నియమిస్తారు. మధ్యవర్తిత్వ భాష ఇంగ్లీషులో ఉండాలి.
              </p>
              <p>- మధ్యవర్తిత్వ స్థలం భారతదేశంలోని ముంబైగా ఉండాలి.</p>
              <p>
                - మధ్యవర్తిత్వ అవార్డ్ అంతిమమైనది, పార్టీలకు కట్టుబడి ఉంటుంది,
                పార్టీలు దాని ప్రకారం కట్టుబడి ఉండటానికి అంగీకరిస్తాయి
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మేము ఈ విధానానికి అప్‌డేట్‌లు లేదా మార్పులు చేస్తామా?</h3>
          <p>
            <i>
              <b>క్లుప్తంగా:</b> అవును, సంబంధిత చట్టాలకు అనుగుణంగా ఉండటానికి
              మేము ఈ విధానాన్ని అవసరమైన విధంగా నవీకరిస్తాము.
            </i>
          </p>
          <p>
            మేము ఈ గోప్యతా విధానాన్ని ఎప్పటికప్పుడు అప్‌డేట్ చేయవచ్చు. ఈ
            నవీకరించబడిన వెర్షన్ నవీకరించబడిన “సవరించిన” తేదీ ద్వారా
            సూచించబడుతుంది, నవీకరించబడిన సంస్కరణ ప్రాప్యత చేయబడిన వెంటనే
            అమలులోకి వస్తుంది.
          </p>
          <p>
            మేము ఈ గోప్యతా విధానానికి ముఖ్యమైన మార్పులు చేస్తే, అటువంటి మార్పుల
            నోటీసును ప్రముఖంగా పోస్ట్ చేయడం ద్వారా లేదా మీకు నేరుగా నోటిఫికేషన్
            పంపడం ద్వారా మేము మీకు తెలియజేయవచ్చు. మేము మీ సమాచారాన్ని ఎలా
            రక్షిస్తున్నామో తెలియజేయడానికి ఈ గోప్యతా విధానాన్ని తరచుగా
            సమీక్షించమని మేము మిమ్మల్ని ప్రోత్సహిస్తున్నాము.
          </p>
        </li>
        <li>
          <h3>ఈ విధానం గురించి మీరు మమ్మల్ని ఎలా సంప్రదించగలరు?</h3>
          <p>
            మీకు ఈ విధానం గురించి ప్రశ్నలు లేదా వ్యాఖ్యలు ఉంటే,{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            లో మాకు ఇమెయిల్ చేయండి
          </p>
          <p>
            మా గురించి లేదా మా విధానాల గురించి మీకు ఏవైనా ప్రశ్నలు లేదా
            వ్యాఖ్యలు ఉంటే,{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            లో మాకు ఇమెయిల్ చేయండి.
          </p>
        </li>
      </ol>
    </div>
  );
};
export default PrivacyTE;
