import { IonPage, IonTextarea } from '@ionic/react';
import './style.scss';
import Layout from '../../components/layouts';
import NewsAndAnnouncement from '../../components/newsannouncements';
import { t, Trans } from '@lingui/macro';
import {} from 'react';
import ButtonComponent from '../../components/button-component';
import PrivateRoute from '../../components/privateroute';
import ProfileImage from '../../components/profileimg';

const postCardDetails = [
  {
    farmerName: 'Vijay Singh',
    buying: true,
    farmerType: 'Individual Farmer',
    location: 'Mumbai',
    postCardContent:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa at mi consectetur volutpat eu. Pellentesque vel faucibus nec cursus mi interdum. Arcu gravida non volutpat dignissim nascetur lacinia posuere in. Non amet ultrices ultrices sed nisi. Orci dictum vitae mattis dui. Cum nullam mauris nisl diam morbi in purus sapien. Odio ultrices a nibh etiam commodo dui.',
    isContentOnly: true,
  },
  {
    farmerName: 'Vijay Singh',
    buying: false,
    farmerType: 'Farmer',
    location: 'Mumbai',
    productName: 'Tomato',
    productCategory: 'Vegetable',
    productVarietyBreed: 'Red',
    productQuantity: '300',
    productUnit: 'kg',
    expectedPrice: '50',
    logisticRequired: true,
    productType: 'fresh',
  },
];

const MarketPlace: React.FC = () => {
  return (
    <IonPage className='page-user-dashboard'>
      <Layout>
        <div className='user-dashboard-container'>
          <div className='dashboard-main-container mr-24'>
            <>
              <div className='dashboard-main-top-container p-16 mb-24'>
                <div className='dashboard-select-page-wrapper'>
                  <ButtonComponent
                    name={'Post'}
                    textKey={'post'}
                    outline
                    customClass='dashboard-select-btn-text font-aktiv-grotest-medium pb-7 pl-16 pr-16'
                  />
                  <ButtonComponent
                    name={'Demand'}
                    textKey={'buy'}
                    outline
                    customClass='dashboard-select-btn-text font-aktiv-grotest-medium pb-7 pl-16 pr-16'
                  />
                  <ButtonComponent
                    name={'Supply'}
                    textKey={'sell'}
                    outline
                    customClass='dashboard-select-btn-text font-aktiv-grotest-medium pb-7 pl-16 pr-16'
                  />
                </div>
                <div className='dashboard-comments-section'>
                  <ProfileImage customClass='dashboard-post-profile-image mr-10' />
                  <Trans
                    id='what_on_your_mind'
                    render={() => (
                      <IonTextarea
                        className='dashboard-comment-text'
                        placeholder={t({
                          id: 'what_on_your_mind',
                          message: `What's on your mind`,
                        })}
                        rows={3}
                        cols={1}
                        autoGrow
                      ></IonTextarea>
                    )}
                  >
                    {`What's on your mind`}
                  </Trans>
                </div>
                <div className='post-divider mt-10'></div>
                <div className='post-btn-wrapper'>
                  <ButtonComponent
                    name={'Post'}
                    textKey={'post'}
                    customClass='mt-10 post-btn'
                  />
                </div>
              </div>
              {postCardDetails.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    {/* <PostCard
                      farmerName={item.farmerName}
                      farmerType={item.farmerType}
                      location={item.location}
                      productName={item.productName}
                      productCategory={item.productCategory}
                      productVarietyBreed={item.productVarietyBreed}
                      productQuantity={item.productQuantity}
                      productUnit={item.productUnit}
                      expectedPrice={item.expectedPrice}
                      logisticRequired={item.logisticRequired}
                      productType={item.productType}
                      buying={item.buying}
                      postCardContent={item.postCardContent}
                      isContentOnly={item.isContentOnly}
                      imgPath={item.imgPath}
                    /> */}
                  </div>
                );
              })}
            </>
          </div>
          <div className='dashboard-notification-news-container'>
            <div className='news-wrapper p-8'>
              <div className='notification-news-title font-aktiv-grotest-medium pb-10'>
                {t({
                  id: 'news_and_announcements',
                  message: 'News and Announcements',
                })}
              </div>
              <NewsAndAnnouncement message='Netus mi senectus blandit fermentum sit vulputate nulla nunc. Mattis molestie ante sed fringilla nascetur.' />
              <NewsAndAnnouncement message='Netus mi senectus blandit fermentum sit vulputate nulla nunc. Mattis molestie ante sed fringilla nascetur.' />
              <div className='load-more-btn font-aktiv-grotest-medium mt-12'>
                {t({
                  id: 'load_more',
                  message: 'Load more',
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(MarketPlace);
