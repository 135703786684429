/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { IonImg } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Duration from './duration';
import './style.scss';
import LoaderImg from '../../assets/images/covestro-logo.png';
import IconPlay from '../../assets/images/icon-play.png';
import { useLocation } from 'react-router';
import observerService from '../../services/observer.service';
import { getSignedVideoUrl } from '../../services/filetransfer.service';
import { getFormattedUrl } from '../../utils/common.helper';
interface Props {
  url: string;
  thumbnail?: string;
  hideControlsOnPause?: boolean;
  isLocal?: boolean;
  onVideoEnd?: () => void;
  onVideoPlay?: (isPlay: boolean) => void;
}
let local = false;
const VideoPlayer = (props: Props) => {
  const {
    url,
    thumbnail,
    hideControlsOnPause,
    isLocal,
    onVideoEnd,
    onVideoPlay,
  } = props;
  let player: any;
  let observer: any = null;
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [videoUrl, setVideoUrl]: any = useState(url);
  local = isLocal || false;
  const location = useLocation();
  const ref = (playerRef: any) => {
    player = playerRef;
  };

  useEffect(() => {
    if (!observer) {
      observer = observerService.observable.subscribe((value: any) => {
        if (value) {
          if (value.type == 'video' && !local && value.data.videoUrl != url) {
            setPlaying(false);
            onVideoPlay && onVideoPlay(false);
          } else if (value.type == 'video-pause') {
            setPlaying(false);
            onVideoPlay && onVideoPlay(false);
          }
        }
      });
    }
    return () => {
      // isPlaying = false;
      if (observer) {
        observer.unsubscribe();
        observer = null;
      }
    };
  }, []);

  useEffect(() => {
    setPlaying(false);
  }, [location]);

  useEffect(() => {
    local = isLocal || false;
    if (!isLocal) {
      getVideoUrl();
    } else {
      setVideoUrl(url);
    }
  }, [url, isLocal]);

  const getVideoUrl = async () => {
    const resp: any = await getSignedVideoUrl(url);
    if (!resp.error) {
      setVideoUrl(resp.body);
    } else {
      setVideoUrl(getFormattedUrl(url));
    }
  };
  const togglePlayPause = () => {
    if (!playerLoaded) {
      setPlayerLoaded(true);
    }
    onVideoPlay && onVideoPlay(!playing);
    setPlaying(!playing);
    observerService.handleEvents('video', { videoUrl: url });
  };

  const handleProgress = (event: any) => {
    if (!seeking) {
      setPlayed(event.played);
    }
  };

  const handleDuration = (duration: any) => {
    setDuration(duration);
  };

  const handleEnded = () => {
    setTimeout(() => {
      setPlaying(false);
      setPlayed(0);
      onVideoEnd && onVideoEnd();
    }, 1000);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);
    player.seekTo(parseFloat(e.target.value), 'fraction');
    setPlaying(true);
  };

  return (
    <div
      style={{
        backgroundColor: 'black',
      }}
      className='video-height-wrapper'
    >
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='fullscreen video-height'
      >
        <div className='player-wrapper video-height' onClick={togglePlayPause}>
          <ReactPlayer
            ref={ref}
            playing={playing}
            className='react-player video-height'
            url={videoUrl}
            light={thumbnail || false}
            width={'100%'}
            height={350}
            controls={false}
            onError={(error: any, data: any) => {
              console.log('error', error);
              console.log('data', data);
            }}
            onBuffer={() => {
              setBuffering(true);
            }}
            onBufferEnd={() => {
              setBuffering(false);
            }}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onEnded={handleEnded}
            playIcon={
              <IonImg
                style={{
                  top: '45%',
                  left: '45%',
                  width: 40,
                  height: 40,
                  position: 'absolute',
                }}
                src={IconPlay}
              />
            }
            stopOnUnmount
          />
          {!thumbnail && !playing && (
            <IonImg
              style={{
                top: '45%',
                left: '45%',
                width: 40,
                height: 40,
                position: 'absolute',
              }}
              src={IconPlay}
            />
          )}
          {thumbnail && playerLoaded && !playing && (
            <IonImg
              style={{
                top: '45%',
                left: '45%',
                width: 40,
                height: 40,
                position: 'absolute',
              }}
              src={IconPlay}
            />
          )}
          {buffering && (
            <div className='loader-position'>
              <IonImg className='loader-img' src={LoaderImg} />
            </div>
          )}
        </div>
        {!hideControlsOnPause ? (
          <div className='ion-video__controllers'>
            <div className='ion-video__controllers_align-left'>
              <div className='ion-video__time'>
                <Duration seconds={duration * played} />/
                <Duration seconds={duration} />
              </div>
            </div>
          </div>
        ) : (
          playing && (
            <div className='ion-video__controllers'>
              <div className='ion-video__controllers_align-left'>
                <div className='ion-video__time'>
                  <Duration seconds={duration * played} />/
                  <Duration seconds={duration} />
                </div>
              </div>
            </div>
          )
        )}
      </div>
      {!hideControlsOnPause ? (
        <div>
          <div className='ion-video__progress-bar'>
            <input
              type='range'
              min={0}
              max={0.999999}
              step='any'
              style={{ width: window.screen.availWidth }}
              value={played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              onTouchStart={handleSeekMouseDown}
              onTouchEnd={handleSeekMouseUp}
            />
          </div>
        </div>
      ) : (
        playing && (
          <div>
            <div className='ion-video__progress-bar'>
              <input
                type='range'
                min={0}
                max={0.999999}
                step='any'
                style={{ width: window.screen.availWidth }}
                value={played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
                onTouchStart={handleSeekMouseDown}
                onTouchEnd={handleSeekMouseUp}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default VideoPlayer;
