import './style.scss';
import React, { useContext, useEffect, useState } from 'react';
import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { getLanguages } from '../../services/lang-descriptor.service';
import { AppContext } from '../../context/app.context';
import { userUpdate } from '../../services/user.service';
interface Props {
  customClass?: string;
}
const LanguageSelect: React.FC<Props> = (props: Props) => {
  const { customClass } = props;
  const { setLoading, setSelectedLang, lang, isLoggedIn, loading }: any =
    useContext(AppContext);
  const [language, setLanguage] = useState({
    name: 'English',
    value: 'en',
  });
  const [languageList, setLanguageList] = useState([
    {
      name: 'English',
      value: 'en',
    },
  ]);
  const [langLoading, setLangLoading] = useState(true);
  useEffect(() => {
    getLangList();
  }, []);

  useEffect(() => {
    const selectedLangItem = languageList.find(
      (item: any) => item.value == lang
    );
    if (selectedLangItem) {
      setLanguage(selectedLangItem);
    }
  }, [lang]);
  async function getLangList() {
    const resp: any = await getLanguages();
    if (!resp.error) {
      const formatedData = resp.map((item: any) => {
        return {
          name: item.title,
          value: item.code,
        };
      });
      setLanguageList(formatedData);
      const selectedLangItem = formatedData.find(
        (item: any) => item.value == lang
      );
      if (selectedLangItem) {
        setLanguage(selectedLangItem);
      }
    }
    setLangLoading(false);
  }

  const onLangChange = async (langItem: any) => {
    if (loading) return;
    setLoading(true);
    await setSelectedLang(langItem);
    if (isLoggedIn == 'true') {
      const postData: any = {
        language_perfernce: langItem,
      };
      await userUpdate(postData);
    }
    const item = languageList.find((i: any) => i.value == langItem);
    setLanguage(item || language);
    setLoading(false);
    // setTimeout(() => {
    //   setLanguage(langItem);
    //   setLoading(false);
    //   window.location.reload();
    // }, 1000);
  };
  return langLoading ? (
    <></>
  ) : (
    <IonItem className={`language-select ${customClass || ''}`} lines='none'>
      <IonSelect
        interface='popover'
        selectedText={language.name}
        placeholder='English'
        onIonChange={(e) => {
          if (e.detail.value != language.value) {
            onLangChange(e.detail.value);
          }
        }}
        value={language.value}
      >
        {languageList.map((item: any) => {
          return (
            <IonSelectOption key={item.code} value={item.value}>
              {item.name}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};

export default LanguageSelect;
