/* eslint-disable no-unused-vars */
import './style.scss';
import { IonPage, IonPopover, useIonViewWillLeave } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { useHistory, useParams } from 'react-router';
import { getFormattedUrlCMS, getRouteForCMS } from '../../utils/common.helper';
import ButtonComponent from '../../components/button-component';
import { t } from '@lingui/macro';
import {
  addInterest,
  getInterestByShortanme,
} from '../../services/interest.service';
import { getProductByShortname } from '../../services/product.service';
import Tag from '../../components/tag';

const Solution: React.FC = () => {
  const { toastMessage, loading, setLoading, isLoggedIn }: any =
    useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const { id }: any = useParams();
  const [popover, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isUserIneterest, setIsUserIneterest] = useState(false);
  const [isAttended, setIsAttended] = useState(false);

  const [product, setProduct]: any = useState(null);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setEntry(null);
    Promise.all([fetchContents(), fetchProduct()]).finally(() => {
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (isLoggedIn == 'true') {
      fetchUserInterest();
    }
  }, [isLoggedIn, id]);

  useIonViewWillLeave(() => {
    setShowPopover({ showPopover: false, event: undefined });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent(id);
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchProduct = async () => {
    const temp = id.split('-');
    const resp = await getProductByShortname(temp[temp.length - 1]);

    if (!resp.error) {
      setProduct(resp);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const fetchUserInterest = async () => {
    const temp = id.split('-');
    const resp = await getInterestByShortanme(temp[temp.length - 1]);

    if (!resp.error) {
      setIsUserIneterest(resp.isInterested);
      setIsAttended(resp.isAttented);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onInterestedClick = async () => {
    if (isLoggedIn == 'false') {
      setShowPopover({ showPopover: true, event: undefined });
    } else {
      const resp = await addInterest({
        productId: product.id,
      });
      if (!resp.error) {
        setIsUserIneterest(true);
        setIsAttended(false);
      } else {
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    }
  };

  function onNavClick(route: string) {
    history.push(getRouteForCMS(route));
  }

  return (
    <IonPage className='page-solution'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <div className='section content-sec pt-0'>
            <div className='page-banner-with-text'>
              {entry.solution_detail_banner_image &&
                entry.solution_detail_banner_image.length > 0 && (
                  <ContentComp
                    data={{
                      images: entry.solution_detail_banner_image,
                      unique_key:
                        entry.solution_detail_banner_image[0].unique_key,
                    }}
                  />
                )}

              {entry.what_is && (
                <div className='container'>
                  <div className='banner-content'>
                    <div className='banner-tag-container'>
                      <Tag label={'SOLUTION'} bgColor={'#009FE4'} />
                    </div>
                    <div className='banner-content__inner'>
                      <ContentComp
                        data={{
                          title: entry.what_is.short_text.title,
                          titleTag: entry.what_is.short_text.tag_name,
                          titleCustomClass: 'content-heading',
                          description: entry.what_is.long_text?.description,
                          descPosition: entry.what_is.long_text?.position,
                          unique_key: entry.what_is.unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {entry.how_it_works && (
              <div>
                <div className='container container--medium mt-40'>
                  <div className=''>
                    <ContentComp
                      data={{
                        imagePath: getFormattedUrlCMS(
                          `${entry.how_it_works.images[0]?.hash}${entry.how_it_works.images[0]?.ext}`
                        ),
                        title: entry.how_it_works.title.title,
                        titleTag: entry.how_it_works.title.tag_name,
                        titleCustomClass: 'mb-20',
                        description:
                          entry.how_it_works.description?.description,
                        descPosition: entry.how_it_works.description?.position,
                        unique_key: entry.how_it_works.unique_key,
                        displayType: entry.how_it_works.display_type,
                        titleOnTop: entry.how_it_works.title_on_top,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {entry.usage && (
              <div>
                <div className='container container--medium '>
                  <div className=''>
                    <ContentComp
                      data={{
                        title: entry.usage.short_text?.title,
                        titleTag: entry.usage.short_text?.tag_name,
                        titleCustomClass: 'mb-20',
                        description: entry.usage.long_text?.description,
                        descPosition: entry.usage.long_text?.position,
                        unique_key: entry.usage.unique_key,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {entry.benefits && (
              <div className='bg-orange text-white pt-20 md-pt-10 pb-40  md-pb-20 mb-40'>
                <div className='container container--medium'>
                  <ContentComp
                    data={{
                      title: entry.benefits.short_text.title,
                      titleTag: entry.benefits.short_text.tag_name,
                      description: entry.benefits.long_text?.description,
                      descPosition: entry.benefits.long_text?.position,
                      unique_key: entry.benefits.unique_key,
                    }}
                  />
                </div>
              </div>
            )}
            {entry.installed_location && (
              <div>
                <div className='container container--medium mt-20'>
                  <div className=''>
                    <ContentComp
                      data={{
                        imagePath: getFormattedUrlCMS(
                          `${entry.installed_location.images[0]?.hash}${entry.installed_location.images[0]?.ext}`
                        ),
                        title: entry.installed_location.title.title,
                        titleTag: entry.installed_location.title.tag_name,
                        description:
                          entry.installed_location.description?.description,
                        descPosition:
                          entry.installed_location.description?.position,
                        unique_key: entry.installed_location.unique_key,
                        displayType: entry.installed_location.display_type,
                        titleOnTop: entry.installed_location.title_on_top,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {entry.accreditations_title && (
              <div>
                <div className='container container--medium mt-20'>
                  <div className=''>
                    <ContentComp
                      data={{
                        title: entry.accreditations_title.title,
                        titleTag: entry.accreditations_title.tag_name,
                        unique_key: entry.accreditations_title.unique_key,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {entry.accreditations_and_recognitions && (
              <div className='acc-rec mb-40'>
                <div
                  className='container container--medium mt-20 cursor'
                  onClick={() => {
                    onNavClick('accreditation');
                  }}
                >
                  <div className='acc-rec__card'>
                    <Tag label={'MEDIA'} bgColor={'#E6007E'} />
                    <ContentComp
                      data={{
                        imagePath: getFormattedUrlCMS(
                          `${entry.accreditations_and_recognitions?.images[0]?.hash}${entry.accreditations_and_recognitions?.images[0]?.ext}`
                        ),
                        title:
                          entry.accreditations_and_recognitions.title.title,
                        titleTag:
                          entry.accreditations_and_recognitions.title.tag_name,
                        description:
                          entry.accreditations_and_recognitions.description
                            ?.description,
                        descPosition:
                          entry.accreditations_and_recognitions.description
                            ?.position,
                        unique_key:
                          entry.accreditations_and_recognitions.unique_key,
                        displayType:
                          entry.accreditations_and_recognitions.display_type,
                        titleOnTop:
                          entry.accreditations_and_recognitions.title_on_top,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className='container container--medium mt-20'
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              {isLoggedIn == 'true' && isUserIneterest && !isAttended ? (
                <div className='font-aktiv-grotest-medium show_interest_box'>
                  {t({
                    id: 'shown_interest_contact',
                    message:
                      'You have shown interest in this product. Covestro team will connect you shortly!',
                  })}
                </div>
              ) : (
                // <ButtonComponent
                //   disabled
                //   name={
                //     'You have shown interest in this product. Covestro team will connect you shortly!'
                //   }
                //   textKey={'shown_interest_contact'}
                //   customClass='cursor-unset'
                // />
                <ButtonComponent
                  name={'I am interested'}
                  textKey={'i_am_interested'}
                  onClick={() => {
                    onInterestedClick();
                  }}
                />
              )}
            </div>

            {entry.success_story && entry.success_story.length > 0 && (
              <div>
                <div className='container mt-20 '>
                  <div className='row row-m-8'>
                    {entry.success_story.map((item: any) => {
                      const col = entry.success_story[0].column
                        ? entry.success_story[0].column.trim() == '2'
                          ? '3'
                          : entry.success_story[0].column
                        : '1';
                      return (
                        <div
                          className={`col col-12 col-sm-6 col-md-${
                            12 / parseInt(col)
                          } p-8 ${item.redirect_to ? 'cursor' : ''} `}
                          key={item.id}
                          onClick={() => {
                            if (item.redirect_to) {
                              onNavClick(item.redirect_to.model);
                            }
                          }}
                        >
                          <ContentComp
                            data={{
                              imagePath: getFormattedUrlCMS(
                                `${item.images[0]?.hash}${item.images[0]?.ext}`
                              ),
                              title: item.title.title,
                              titleTag: item.title.tag_name,
                              description: item.description?.description,
                              descPosition: item.description?.position,
                              unique_key: item.unique_key,
                              displayType: item.display_type,
                              titleOnTop: item.title_on_top,
                              tagText: 'Media',
                              tagBgColor: '#E6007E',
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <IonPopover
              event={popover.event}
              isOpen={popover.showPopover}
              onDidDismiss={() => {
                setShowPopover({ showPopover: false, event: undefined });
              }}
              className='img-popover modal-style'
              backdropDismiss={true}
            >
              <div className='unauthenticate__sec'>
                <div className='unauthenticate__box'>
                  <h2 className='intro-covestro-text font-aktiv-grotest-medium mb-40'>
                    Login / Register to continue
                  </h2>
                  <ul className='success__box__links'>
                    <li
                      className='lbl font-aktiv-grotest-medium'
                      onClick={() => {
                        setShowPopover({
                          showPopover: false,
                          event: undefined,
                        });
                        history.push(`/login`);
                      }}
                    >
                      {t({
                        id: 'login',
                        message: 'Login',
                      })}
                    </li>
                    <li
                      className='lbl font-aktiv-grotest-medium'
                      onClick={() => {
                        setShowPopover({
                          showPopover: false,
                          event: undefined,
                        });
                        history.push('/signup');
                      }}
                    >
                      {t({
                        id: 'register',
                        message: 'Register',
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </IonPopover>
          </div>
        )}
      </Layout>
    </IonPage>
  );
};

export default Solution;
