import { IonImg, IonPage, IonPopover } from '@ionic/react';
import ButtonComponent from '../../components/button-component';
import GoogleLogo from '../../assets/images/google-logo.png';
import LogInBack from '../../assets/images/icons/arrow-small-right.png';
import './style.scss';
import InputText from '../../components/inputtext';
import { t, Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import ValidationMessage from '../../components/validationMessage';
import { LoginSchema, RegisterOTPSchema } from '../../schemas/auth.schema';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { i18n } from '@lingui/core';

import Layout from '../../components/layouts';
import { getQueryStrings, isWeb, mastText } from '../../utils/common.helper';
import {
  deleteAccountResponse,
  deleteMyAccount,
  getUserDetails,
  login,
  resendOtp,
} from '../../services/user.service';
import { doSocialLogin, logout, verifyOTP } from '../../services/auth.service';
import { useHistory } from 'react-router';
import storageService from '../../services/storage.service';
import {
  ACCESS_TOKEN,
  INIT_PAGE,
  TIME_FOR_RESEND,
} from '../../config/appconfig';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import { App } from '@capacitor/app';
import { handleGoogleSingin } from '../../services/firebase.service';
import Loader from '../../components/loader';
import DeleteBox from '../../components/deletebox';
import RespondBox from '../../components/respondbox';
let interval: any;

const LogIn: React.FC = () => {
  const {
    setIsLoggedIn,
    setLoading,
    loading,
    toastMessage,
    setUserDetails,
    setIsInActive,
  }: any = useContext(AppContext);

  const [error] = useState(null);
  const history = useHistory();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [emailPhone, setEmailPhone] = useState('');
  const [maskEmailPhone, setMaskEmailPhone] = useState('');
  const [remainingTime, setRemainingTime]: any = useState('01:00');
  const [socialLoading, setSocialLoading] = useState(false);
  const queryParam = getQueryStrings(history.location.search);

  const [popoverStateDelete, setShowPopoverDelete] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popoverStateRespond, setShowPopoverRepond] = useState({
    showPopover: false,
    event: undefined,
  });
  const [respondText, setRespondText]: any = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const initialValues = {
    email: '',
  };
  const initialOTPValues = {
    otp: '',
  };

  useEffect(() => {
    document.addEventListener('ionBackButton', handleBack);
    return () => {
      document.removeEventListener('ionBackButton', handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(102, () => {
      if (window.location.pathname == '/login') {
        App.exitApp();
      } else {
        history.goBack();
      }
    });
  };

  const startTimer = (duration: any) => {
    let timer: any = duration;
    let minutes: any, seconds: any;
    clearOTPTimer();
    interval = setInterval(function () {
      const min: any = timer / 60;
      const sec: any = timer % 60;
      minutes = parseInt(min, 10);
      seconds = parseInt(sec, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      if (--timer < 0) {
        timer = duration;
      }
      setRemainingTime(minutes + ':' + seconds);
      if (interval != null && minutes == '00' && seconds == '00') {
        clearInterval(interval);
      }
    }, 1000);
  };

  const clearOTPTimer = () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
      setRemainingTime('01:00');
    }
  };
  const handleLogin = async (values: any, actions: any) => {
    console.log(values, emailPhone);
    actions.setFieldError(undefined);
    const resp: any = await login({
      input: values.email.toLowerCase(),
      sendOtp: true,
    });
    actions.setSubmitting(false);
    if (!resp.error) {
      setShowOtpForm(true);
      startTimer(TIME_FOR_RESEND);
      setEmailPhone(values.email);
      setMaskEmailPhone(mastText(values.email));
    } else {
      actions.setFieldError('email', resp.message);
    }

    // setTimeout(() => {
    //   // setting error status <---- 2
    //   actions.setFieldError('email', 'This phone number is already taken.');
    // }, 500);
  };

  const handleOtp = async (values: any, actions: any) => {
    actions.setFieldError(undefined);
    const resp: any = await verifyOTP({
      input: emailPhone.toLowerCase(),
      otp: values.otp,
    });
    if (!resp.error) {
      await storageService.set(ACCESS_TOKEN, resp.token);
      const userResp: any = await getUserDetails();
      if (!userResp.error) {
        clearOTPTimer();
        setUserDetails(userResp);
        actions.setSubmitting(false);
        if (queryParam && queryParam.action && queryParam.action == 'delete') {
          setShowPopoverDelete({
            showPopover: true,
            event: undefined,
          });
        } else if (!userResp.profile_complete) {
          history.replace('/signup', {
            step: 2,
            user: userResp,
            from: 'login',
          });
        } else {
          setIsInActive(userResp.is_in_active ? 'true' : 'false');
          setIsLoggedIn('true');
          history.replace(
            userResp.is_in_active ? '/account-inactive' : INIT_PAGE
          );
        }
      }
    } else {
      actions.setSubmitting(false);
      actions.setFieldError('otp', resp.message);
    }
  };

  const handleGoogleLogin = async () => {
    setSocialLoading(true);
    const socialRes = await handleGoogleSingin();
    if (!socialRes.error) {
      const resp: any = await doSocialLogin({
        firebaseToken: socialRes.firebaseToken,
      });
      setSocialLoading(false);
      if (!resp.error) {
        await storageService.set(ACCESS_TOKEN, resp.token);
        const userResp: any = await getUserDetails();
        if (!userResp.error) {
          setUserDetails(userResp);
          if (!userResp.profile_complete) {
            history.replace('/signup', {
              step: 2,
              user: userResp,
              from: 'login',
            });
          } else {
            setIsInActive(userResp.is_in_active ? 'true' : 'false');
            setIsLoggedIn('true');
            history.replace(
              userResp.is_in_active ? '/account-inactive' : INIT_PAGE
            );
          }
        }
      } else {
        toastMessage(
          resp.status && resp.status == 404
            ? resp.description || Messages.USER_NOT_REGISTER
            : Messages.SERVER_ERROR,
          'danger'
        );
        // if (resp.description && resp.description.includes('inactivity')) {
        //   setShowActivateLink(true);
        // } else {
        //   setShowActivateLink(false);
        // }
      }
    } else {
      setSocialLoading(false);
      // if (!socialRes.message || !socialRes.message.includes('canceled')) {
      //   toastMessage(Messages.SERVER_ERROR, 'danger');
      // }
    }
  };

  const onResendOTP = async () => {
    if (loading || remainingTime != '00:00') return;
    setLoading(true);
    await resendOtp({
      input: emailPhone.toLowerCase(),
      sendOtp: true,
    });
    setLoading(false);
    startTimer(TIME_FOR_RESEND);
    toastMessage(Messages.RESEND_OTP_MSG);
  };
  const RegisterOTPSchemaMemo: any = useMemo(
    () => RegisterOTPSchema(),
    [i18n.locale]
  );

  const RegisterSchemaMemo: any = useMemo(() => LoginSchema(), [i18n.locale]);

  return (
    <IonPage className='page-login page-login-container'>
      <Layout>
        <div className='page-login-container-inner page-container'>
          {!showOtpForm ? (
            <Formik
              enableReinitialize={false}
              initialValues={initialValues}
              validationSchema={RegisterSchemaMemo}
              onSubmit={handleLogin}
              key={'login'}
            >
              {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                <Form className='email-mobile-form'>
                  <>
                    <h1 className='intro-covestro-text font-aktiv-grotest-medium'>
                      {t({
                        id: 'sign_in',
                        message: 'Sign In',
                      })}
                    </h1>
                    <h2 className='intro-welcome-text mb-40'>
                      {t({
                        id: 'to_start_your_lesson',
                        message: 'to start your session',
                      })}
                    </h2>
                    {socialLoading ? (
                      <Loader />
                    ) : (
                      <div
                        className='social-login-container mb-32 cursor'
                        onClick={() => {
                          handleGoogleLogin();
                        }}
                      >
                        {/* <IonImg
                          className='google-login-img mb-32'
                          src={GoogleLogIn}
                        /> */}
                        <div className='google-login-img mb-32 font-aktiv-grotest-medium text-white'>
                          <IonImg
                            style={{ width: 50, height: 45 }}
                            src={GoogleLogo}
                          />
                          <div style={{ marginLeft: 60 }}>
                            {t({
                              id: 'sign_in_google',
                              message: 'Sign in with Google',
                            })}
                          </div>
                        </div>
                        <p className='login-or-text'>
                          {t({
                            id: 'or',
                            message: 'Or',
                          })}
                        </p>
                      </div>
                    )}
                    <div className='input-text-container mb-34'>
                      <Trans
                        id='enter_email_or_mobile'
                        render={({ translation }) => (
                          <InputText
                            labelName='Email / Mobile Number'
                            labelKey='enter_email_or_mobile'
                            placeholder={'' + translation}
                            required
                            value={values.email}
                            onChange={(value: any) => {
                              setFieldValue('email', value);
                            }}
                            error={Boolean(errors.email && touched.email)}
                          ></InputText>
                        )}
                      >
                        Enter Email / Mobile no
                      </Trans>
                      <ValidationMessage
                        name={'email'}
                        customClass='validationError-profile'
                      />
                    </div>
                    {error && (
                      <div className='text-center text-danger'>{error}</div>
                    )}

                    <ButtonComponent
                      disabled={isSubmitting}
                      name={'Sign In'}
                      customClass='login-btn-submit mb-16'
                      textKey={'sign_in'}
                    />
                    <p className='logIn-now font-aktiv-grotest-medium'>
                      {t({
                        id: 'new_to_covestro',
                        message: 'New to Covestro?',
                      })}{' '}
                      <a href='/signup' className='login-register ml-5'>
                        {t({
                          id: 'register_now',
                          message: 'Register now',
                        })}
                      </a>
                    </p>
                  </>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize={false}
              initialValues={initialOTPValues}
              validationSchema={RegisterOTPSchemaMemo}
              onSubmit={handleOtp}
              key={'otp'}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                setFieldTouched,
                isSubmitting,
              }) => (
                <>
                  <div
                    className='login-otp-back-wrapper otp-back-wrapper-outside mb-24'
                    onClick={() => {
                      clearOTPTimer();
                      setShowOtpForm(false);
                    }}
                  >
                    <IonImg
                      className='login-otp-back-img mr-10'
                      src={LogInBack}
                    ></IonImg>
                    <span className='login-otp-back-txt mt-4'>
                      {t({
                        id: 'back',
                        message: 'Back',
                      })}
                    </span>
                  </div>
                  <Form className='otp-mobile-form'>
                    <div className='login-otp-wrapper'>
                      <h2 className='intro-welcome-text mb-40'>
                        {t({
                          id: 'we_just_sent_otp',
                          message: `We just sent a OTP to`,
                        })}
                        <span> {maskEmailPhone}</span>
                      </h2>
                      <div className='input-text-container mb-34'>
                        <Trans
                          id='enter_otp'
                          render={({ translation }) => (
                            <InputText
                              labelName='Enter OTP'
                              labelKey='enter_otp'
                              placeholder={'' + translation}
                              required
                              value={values.otp}
                              onChange={(value: any) => {
                                setFieldValue('otp', value);
                                setFieldTouched('otp', true);
                              }}
                              error={Boolean(errors.otp && touched.otp)}
                              maxlength={6}
                              type='number'
                              inputMode={'numeric'}
                            ></InputText>
                          )}
                        >
                          Enter OTP
                        </Trans>
                        <ValidationMessage name={'otp'} />
                      </div>
                      <ButtonComponent
                        name={'Submit'}
                        customClass='login-btn-submit mb-16'
                        textKey='submit'
                        disabled={isSubmitting}
                      />
                      <p className='otp-try-again font-aktiv-grotest-medium'>
                        {t({
                          id: 'please_wait_for_few_min',
                          message:
                            'Didnt receive it? Please wait for a few minutes and',
                        })}{' '}
                        <span
                          className={`login-register ml-5 ${
                            remainingTime != '00:00' ? 'disabled-otp' : ''
                          } `}
                          onClick={() => {
                            onResendOTP();
                          }}
                        >
                          {t({
                            id: 'try_again',
                            message: 'Try Again',
                          })}
                        </span>
                        {remainingTime != '00:00' && (
                          <span> {remainingTime}</span>
                        )}
                      </p>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          )}
        </div>
        <IonPopover
          event={popoverStateDelete.event}
          isOpen={popoverStateDelete.showPopover}
          onDidDismiss={() => {
            setShowPopoverDelete({ showPopover: false, event: undefined });
          }}
          className='delete-pop'
        >
          <DeleteBox
            title='Please read this carefully'
            titleKey='delete_account_title'
            message='You are about to submit a request for us to permanently close your Covestro food security platform account and delete your data. Once your account is closed all the product and services accessed through your account will no longer available to you.'
            messageKey='delete_account_msg'
            confirmMsg='Do you really want to Proceed ?'
            confirmMsgKey='delete_account_confirm'
            positiveBtnText='Close My Account'
            positiveBtnTextKey='delete_account_ok_btn'
            negativeBtnText='Cancel'
            negativeBtnTextKey='cancel'
            isSubmitting={false}
            onNegativeClick={() => {
              setShowPopoverDelete({ showPopover: false, event: undefined });
            }}
            onPositiveClick={async () => {
              setShowPopoverDelete({ showPopover: false, event: undefined });
              setShowPopoverRepond({
                showPopover: true,
                event: undefined,
              });
            }}
          />
        </IonPopover>
        <IonPopover
          event={popoverStateRespond.event}
          isOpen={popoverStateRespond.showPopover}
          onDidDismiss={() => {
            setShowPopoverRepond({ showPopover: false, event: undefined });
            setRespondText('');
          }}
          className='img-popover modal-style respond-pop'
          // backdropDismiss={false}
        >
          <RespondBox
            placeholderName='Reason For Delete Account'
            placeholderKey='reason_delete'
            isSubmitting={isDeleting}
            respondText={respondText}
            onRepondChange={(text) => {
              setRespondText(text);
            }}
            onRespondCancel={() => {
              setShowPopoverRepond({ showPopover: false, event: undefined });
              setRespondText('');
            }}
            onRespondSubmit={async () => {
              setIsDeleting(true);
              await deleteAccountResponse(respondText);
              const resp = await deleteMyAccount();
              if (!resp.error) {
                toastMessage(Messages.ACCOUNT_DELETE_MSG);
              } else {
                toastMessage(Messages.SERVER_ERROR, 'danger');
              }
              setShowPopoverRepond({ showPopover: false, event: undefined });
              setIsDeleting(false);
              setTimeout(async () => {
                await logout();
                setIsLoggedIn('false');
                setUserDetails({});
                history.replace(isWeb() ? INIT_PAGE : '/login');
                window.location.reload();
              }, 500);
            }}
          />
        </IonPopover>
      </Layout>
    </IonPage>
  );
};

export default LogIn;
