/* eslint-disable react/no-children-prop */
import './style.scss';
import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import React from 'react';
import Placeholder from '../../../assets/images/cd-placeholder.png';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import Tag from '../../tag';
interface props {
  imageClass?: string;
  imagePath: string | null;
  title?: string | null;
  description: string;
  titleTag?: string | null;
  descPosition: string;
  titleCustomClass?: string | null;
  descCustomClass?: string;
  descTag?: string | null;
  displayType?: string | null;
  titleOnTop?: boolean;
  tagText?: string;
  tagBgColor?: string;
}
const ImageWithText: React.FC<props> = ({
  imageClass,
  imagePath,
  title,
  titleTag,
  titleCustomClass,
  description,
  descPosition,
  descCustomClass,
  displayType,
  titleOnTop,
  tagText,
  tagBgColor,
}) => {
  const TitleTag = titleTag ? (titleTag as keyof JSX.IntrinsicElements) : 'div';
  return (
    <>
      {titleOnTop && title && (
        <TitleTag className={`${titleCustomClass} card__copy p-0 mb-20`}>
          {title}
        </TitleTag>
      )}
      {descPosition ? (
        descPosition == 'top' ? (
          <div
            className={`card top-bottom ${
              displayType && displayType == 'card' ? 'card-bg' : ''
            }`}
          >
            <div className=''>
              {!titleOnTop && title && (
                <TitleTag
                  className={`${titleCustomClass} card__copy__heading pt-0 mb-10`}
                >
                  {title}
                </TitleTag>
              )}
              <ReactMarkdown
                className={`${
                  displayType && displayType == 'card'
                    ? 'card__copy__description'
                    : ''
                }  ${descCustomClass}`}
                remarkPlugins={[remarkGfm, remarkBreaks]}
              >
                {description}
              </ReactMarkdown>
            </div>
            {imagePath && (
              <div className={`card__img ${imageClass || ''}`}>
                <IonImg
                  className={`card-image ${imageClass || ''}`}
                  src={imagePath || Placeholder}
                />
              </div>
            )}
            {tagText && (
              <div className='tag-container'>
                <Tag label={tagText} bgColor={tagBgColor} />
              </div>
            )}
          </div>
        ) : descPosition == 'left' ? (
          <IonGrid
            className={`card p-0 ${
              displayType && displayType == 'card' ? 'card-bg' : ''
            }`}
          >
            <IonRow className='item-col m-0'>
              <IonCol
                sizeLg={'9'}
                sizeMd='12'
                sizeXs='12'
                className='p-0  m-order-2'
              >
                <div
                  className={`card__copy card__copy--left ${
                    displayType && displayType == 'card' ? 'card-bg' : ''
                  }`}
                >
                  {!titleOnTop && title && (
                    <TitleTag
                      className={`${titleCustomClass} card__copy__heading`}
                    >
                      {title}
                    </TitleTag>
                  )}
                  <ReactMarkdown
                    className={`${
                      displayType && displayType == 'card'
                        ? 'card__copy__description'
                        : ''
                    } ${descCustomClass}`}
                    children={description}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                  />
                </div>
              </IonCol>

              {imagePath && (
                <IonCol
                  sizeLg='3'
                  sizeMd='12'
                  sizeXs='12'
                  className='p-0 m-order-1'
                >
                  <div className={`card__img ${imageClass || ''}`}>
                    <IonImg
                      className={`card-image-lr ${imageClass || ''}`}
                      src={imagePath || Placeholder}
                    />
                  </div>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        ) : descPosition == 'right' ? (
          <IonGrid
            className={`card p-0 ${
              displayType && displayType == 'card' ? 'card-bg' : ''
            }`}
          >
            <IonRow className='item-col m-0'>
              {imagePath && (
                <IonCol
                  sizeLg='3'
                  sizeMd='12'
                  sizeXs='12'
                  className='p-0 m-order-1'
                >
                  <div className={`card__img ${imageClass || ''}`}>
                    <IonImg
                      className={`card-image-lr ${imageClass || ''}`}
                      src={imagePath || Placeholder}
                    />
                  </div>
                </IonCol>
              )}
              <IonCol
                sizeLg={'9'}
                sizeMd='12'
                sizeXs='12'
                className='p-0 m-order-2'
              >
                <div className='card__copy card__copy--right'>
                  {!titleOnTop && title && (
                    <TitleTag
                      className={`${titleCustomClass} card__copy__heading`}
                    >
                      {title}
                    </TitleTag>
                  )}
                  <ReactMarkdown
                    className={`${
                      displayType && displayType == 'card'
                        ? 'card__copy__description'
                        : ''
                    } ${descCustomClass}`}
                    children={description}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <div
            className={`card top-bottom ${
              displayType && displayType == 'card' ? 'card-bg' : ''
            }`}
          >
            {imagePath && (
              <div className={`card__img ${imageClass || ''}`}>
                <IonImg
                  className={`card-image ${imageClass || ''}`}
                  src={imagePath || Placeholder}
                />
              </div>
            )}
            {tagText && (
              <div className='tag-container'>
                <Tag label={tagText} bgColor={tagBgColor} />
              </div>
            )}

            <div className='card__copy'>
              {!titleOnTop && title && (
                <TitleTag
                  className={`${titleCustomClass} card__copy__heading pt-0 mb-10`}
                >
                  {title}
                </TitleTag>
              )}
              <ReactMarkdown
                className={`${
                  displayType && displayType == 'card'
                    ? 'card__copy__description'
                    : ''
                } ${descCustomClass}`}
                remarkPlugins={[remarkGfm, remarkBreaks]}
              >
                {description}
              </ReactMarkdown>
            </div>
          </div>
        )
      ) : (
        <div
          className={`card top-bottom ${
            displayType && displayType == 'card' ? 'card-bg' : ''
          }`}
        >
          <div className={`card__img ${imageClass || ''}`}>
            <IonImg
              className={`card-image ${imageClass || ''}`}
              src={imagePath || Placeholder}
            />
          </div>
          {tagText && (
            <div className='tag-container'>
              <Tag label={tagText} bgColor={tagBgColor} />
            </div>
          )}
          {!titleOnTop && title && (
            <TitleTag className={`${titleCustomClass} card__copy mb-20`}>
              {title}
            </TitleTag>
          )}
        </div>
      )}
    </>
  );
};

export default ImageWithText;
