/* eslint-disable security/detect-object-injection */
import './style.scss';
import { IonImg } from '@ionic/react';
import React, { useContext } from 'react';
import RightArrow from '../../assets/images/icons/arrow-small-right.svg';
import ProfileImage from '../profileimg';
import {
  formatDateToSingleChar,
  getFormattedUrl,
  getLanguageText,
  getUserInitial,
} from '../../utils/common.helper';
import moment from 'moment';
import { useHistory } from 'react-router';
import { AppContext } from '../../context/app.context';
import parse from 'html-react-parser';
interface props {
  cutomerName?: string;
  message: string;
  notificationTime: string;
  profilePic?: string;
  metaData?: any;
}
const Notification: React.FC<props> = ({
  cutomerName,
  message,
  notificationTime,
  profilePic,
  metaData,
}) => {
  const { lang }: any = useContext(AppContext);
  const history = useHistory();
  return (
    <div
      className={`notification-flex pt-8 pb-8 ${
        metaData &&
        (metaData.postId ||
          metaData.id ||
          (metaData.type && metaData.type == 'COMPLETE PROFILE'))
          ? 'cursor'
          : ''
      }`}
      onClick={() => {
        if (metaData && metaData.postId) {
          history.push(`/commoditydetail/${metaData.postId}`);
        } else if (metaData && metaData.id) {
          history.push(`/notifications/details/${metaData.id}`);
        } else if (metaData.type && metaData.type == 'COMPLETE PROFILE') {
          history.push('/my-profile');
        }
      }}
    >
      <ProfileImage
        imgPath={profilePic ? getFormattedUrl(profilePic) : undefined}
        customClass='notification-card-profile-img mr-16 ml-3'
        userInitial={getUserInitial(cutomerName || '')}
      />
      <div className='notification-text-wrapper mr-24'>
        <div className='notification-profile-name'>
          {metaData && metaData.notificationObj ? null : (
            <span className='font-aktiv-grotest-bold'>{cutomerName}</span>
          )}
          {metaData
            ? metaData.notificationObj
              ? metaData.notificationObj.title[lang] &&
                metaData.notificationObj.title[lang] != ''
                ? parse(metaData.notificationObj.title[lang])
                : parse(metaData.notificationObj.title.en)
              : getLanguageText(message, 'title', metaData.locale, lang)
            : message}
        </div>
        <div className='notification-time'>
          {formatDateToSingleChar(moment(notificationTime).fromNow())}
        </div>
      </div>
      <IonImg
        className='notification-right-arrow mr-3'
        src={RightArrow}
      ></IonImg>
    </div>
  );
};

export default Notification;
