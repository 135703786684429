import './style.scss';
import React from 'react';
import ProfileImage from '../profileimg';
import { getFormattedUrl, getUserInitial } from '../../utils/common.helper';

interface props {
  cutomerName?: string;
  time?: string;
  text?: string;
  profilePic: string;
}
const CommentSectionItem: React.FC<props> = ({
  cutomerName,
  time,
  text,
  profilePic,
}) => {
  return (
    <div className='commentsectionitem-flex pt-8 pb-8'>
      <ProfileImage
        userInitial={getUserInitial(cutomerName || '')}
        customClass='commentsectionitem-card-profile-img mr-10'
        imgPath={profilePic ? getFormattedUrl(profilePic) : undefined}
      />
      <div className='commentsectionitem-text-wrapper mr-24'>
        <div className='commentsectionitem-card-profile-name font-aktiv-grotest-bold'>
          {cutomerName}
        </div>
        <div className='commentsectionitem-time'>{time}</div>
        <div className='commentsectionitem-text'>{text}</div>
      </div>
    </div>
  );
};

export default CommentSectionItem;
