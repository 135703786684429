import { App } from '@capacitor/app';

import { ACCESS_TOKEN, FCM_TOKEN } from '../config/appconfig';
import {
  formatStringToJSON,
  notificationRedirect,
} from '../utils/common.helper';
import localnotificationService from './localnotification.service';
import observerService from './observer.service';
import { sendDeviceData } from './userdevice.service';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import storageService from './storage.service';
let isForeground = true;
export const nofiticationInit = () => {
  //   PushNotifications.requestPermission().then((permission) => {
  //     if (permission.granted) {
  //       // Register with Apple / Google to receive push via APNS/FCM
  //       PushNotifications.register();
  //     } else {
  //       // No permission for push granted
  //     }
  //   });

  registertToFCM();

  App.addListener('appStateChange', ({ isActive }) => {
    isForeground = isActive;
  });

  PushNotifications.addListener('registration', async (token: Token) => {
    const access_token = await storageService.get(ACCESS_TOKEN);
    const fcm_token = await storageService.get(FCM_TOKEN);
    if (access_token && access_token !== '') {
      if (!fcm_token) {
        await storageService.set(FCM_TOKEN, token.value);
        //call api
        const reqObj = {
          fcmToken: token.value,
          platform: Capacitor.getPlatform() === 'web' ? 'web' : 'mobile',
          osType:
            Capacitor.getPlatform() === 'web'
              ? 'browser'
              : Capacitor.getPlatform(),
        };
        console.log('call API');
        sendDeviceData(reqObj);
      } else if (fcm_token !== token.value) {
        await storageService.set(FCM_TOKEN, token.value);
        // call API
        const reqObj = {
          fcmToken: token.value,
          platform: Capacitor.getPlatform() === 'web' ? 'web' : 'mobile',
          osType:
            Capacitor.getPlatform() === 'web'
              ? 'browser'
              : Capacitor.getPlatform(),
        };
        console.log('call API');
        sendDeviceData(reqObj);
      }
    }
  });

  PushNotifications.addListener('registrationError', (error: any) => {
    console.log('Error: ' + JSON.stringify(error));
  });

  PushNotifications.addListener(
    'pushNotificationReceived',
    async (notification: PushNotificationSchema) => {
      console.log('Action performed: ' + JSON.stringify(notification));
      observerService.handleEvents('notification', { isNewNotification: true });
      if (
        notification.data.notificationType &&
        notification.data.notificationType === 'silent'
      ) {
        observerService.handleEvents('reward', {
          show: true,
          data: notification.data,
        });
      } else if (isForeground) {
        localnotificationService.schedule({
          id: new Date().getTime(),
          title: notification.title,
          text: notification.body,
          data: formatStringToJSON(notification.data.data),
          autoClear: true,
          trigger: { count: 1 },
          smallIcon: 'res://ic_notification_launcher.png',
          color: '#ffffff',
        });
      }
    }
  );

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    async (notification: ActionPerformed) => {
      const data = notification.notification.data;
      // console.log(
      //   'Action performed: ' + JSON.stringify(notification.notification)
      // );
      const parsedData: any = formatStringToJSON(data.data);
      notificationRedirect(parsedData);
    }
  );
};

export const registertToFCM = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
};
