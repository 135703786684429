/* eslint-disable no-unused-vars */
import {
  IonCard,
  IonCardContent,
  IonImg,
  IonItem,
  IonPopover,
  useIonViewWillLeave,
} from '@ionic/react';
import {
  getFormattedUrl,
  getUserInitial,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import CommentSection from '../commentsection';
import ProfileImage from '../profileimg';
import VideoPlayer from '../videoplayer';
import CDPlaceholder from '../../assets/images/cd-placeholder.png';
import './style.scss';
import Loader from '../loader';
import { useContext, useState } from 'react';
import { AppContext } from '../../context/app.context';
import MoreIcon from '../../assets/images/icons/More-icon.png';
import { useHistory } from 'react-router';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { t } from '@lingui/macro';

interface Props {
  userId?: number;
  commentLoading: boolean;
  customClass?: string;
  farmerProfilePic: string;
  farmerName: string;
  videoPath: string;
  imgPath: string;
  postCardContent: string;
  postId: number;
  commentList: any;
  isActive: boolean;
  postStatus: string;
  rejectReason: string;
  onSubmit?: (commentText: string, postId: number) => Promise<any>;
  updateComment?: (commentList: any, postId: any) => void;
}
let isOptionVisible = false;
const PostDetailsCard: React.FC<Props> = (props: Props) => {
  const {
    userId,
    commentLoading,
    postId,
    customClass,
    imgPath,
    videoPath,
    farmerName,
    postCardContent,
    farmerProfilePic,
    commentList,
    isActive,
    postStatus,
    rejectReason,
    onSubmit,
    updateComment,
  } = props;

  const { userDetails }: any = useContext(AppContext);
  const [popoverStateOption, setShowPopoverOption] = useState({
    showPopover: false,
    event: undefined,
  });
  const [imagePopoverState, setimagePopoverState] = useState({
    showPopover: false,
    event: undefined,
  });
  const history = useHistory();

  useIonViewWillLeave(() => {
    setShowPopoverOption({ showPopover: false, event: undefined });
    setimagePopoverState({ showPopover: false, event: undefined });
  }, []);

  return (
    <div className={`post-card-wrapper ${customClass || ''}`}>
      <IonCard className='post-card p-16 m-0 mb-24'>
        <IonItem className='post-card-header mb-16'>
          <ProfileImage
            userInitial={getUserInitial(farmerName)}
            customClass='post-card-profile-img'
            imgPath={
              farmerProfilePic ? getFormattedUrl(farmerProfilePic) : undefined
            }
          />
          <div className='profile-details ml-10'>
            <h1 className='post-profile-name mb-5'>
              <span className='farmer-name mr-5 font-aktiv-grotest-bold'>
                {toFirstUpperCaseWord(farmerName)}
              </span>
            </h1>
          </div>
          <div className='post-card-time-wrapper'>
            {postStatus == 'pending' && userDetails.id == userId && (
              <IonImg
                src={MoreIcon}
                className='card-more-icon cursor'
                onClick={(e: any) => {
                  isOptionVisible = true;
                  setShowPopoverOption({ showPopover: true, event: e });
                }}
              />
            )}
          </div>
        </IonItem>
        <IonCardContent className='card-content-wrapper p-0 mb-24 card-content'>
          <>
            {videoPath ? (
              <div>
                {postCardContent && (
                  <p className='post-card-content mb-10'>{postCardContent}</p>
                )}
                <VideoPlayer
                  url={videoPath}
                  thumbnail={imgPath ? getFormattedUrl(imgPath) : undefined}
                />
              </div>
            ) : imgPath ? (
              <>
                {postCardContent && (
                  <p className='post-card-content mb-10'>{postCardContent}</p>
                )}
                <IonImg
                  src={imgPath ? getFormattedUrl(imgPath) : CDPlaceholder}
                  className='post-card-image'
                  onClick={() => {
                    imgPath
                      ? setimagePopoverState({
                          showPopover: true,
                          event: undefined,
                        })
                      : null;
                  }}
                />
              </>
            ) : (
              <p className='post-card-content'>{postCardContent}</p>
            )}

            {postStatus != 'accepted' && (
              <div className='card-product-detail mt-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'status',
                    message: 'Status',
                  })}{' '}
                  :
                </p>
                <p
                  className={`product-detail-disc ${
                    postStatus == 'pending'
                      ? 'post-status-pending'
                      : 'post-status-rejected'
                  }`}
                >
                  {postStatus
                    ? toFirstUpperCaseWord(
                        t({
                          id: postStatus,
                          message: postStatus,
                        })
                      )
                    : ''}
                </p>
              </div>
            )}

            {postStatus == 'accepted' && !isActive && (
              <div className='card-product-detail mt-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'status',
                    message: 'Status',
                  })}{' '}
                  :
                </p>
                <p className={`product-detail-disc ${'post-status-rejected'}`}>
                  {toFirstUpperCaseWord(
                    t({
                      id: 'de_activated',
                      message: 'De-activated',
                    })
                  )}
                </p>
              </div>
            )}

            {postStatus == 'rejected' && rejectReason && rejectReason != '' && (
              <div className='card-product-detail mt-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'reject_reason',
                    message: 'Reject Reason',
                  })}{' '}
                  :
                </p>
                <p className='product-detail-disc'>{rejectReason}</p>
              </div>
            )}
          </>
        </IonCardContent>
        {isActive && postStatus == 'accepted' && (
          <div className='mt-10'>
            {commentLoading ? (
              <Loader />
            ) : (
              <CommentSection
                postId={postId}
                maxCount={2}
                commentList={commentList || []}
                isLoadMore={commentList && commentList.length > 2}
                onSubmit={async (commentText: string) => {
                  if (onSubmit) {
                    const resp = await onSubmit(commentText, postId);
                    return resp;
                  } else {
                    return false;
                  }
                }}
                updateComment={(commentList: any, postId: any) => {
                  if (updateComment) {
                    updateComment(commentList, postId);
                  }
                }}
              />
            )}
          </div>
        )}
      </IonCard>
      <IonPopover
        event={popoverStateOption.event}
        isOpen={popoverStateOption.showPopover}
        onDidDismiss={() => {
          isOptionVisible = false;
          setShowPopoverOption({ showPopover: false, event: undefined });
        }}
        alignment='end'
        dismissOnSelect
        showBackdrop={false}
        // className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <div
          className='font-aktiv-grotest-regular cursor p-10 edit-option'
          onClick={() => {
            history.push(`/editpost/${postId}`);
          }}
        >
          {t({ id: 'edit', message: 'Edit' })}
        </div>
      </IonPopover>
      <IonPopover
        event={imagePopoverState.event}
        isOpen={imagePopoverState.showPopover}
        onDidDismiss={() => {
          setimagePopoverState({ showPopover: false, event: undefined });
        }}
        className='img-popover modal-style modal-style--lg'
        // backdropDismiss={false}
      >
        <div className='img-popover-inner' style={{ backgroundColor: 'white' }}>
          <TransformWrapper>
            <TransformComponent>
              <IonImg
                className='modal-style__img'
                src={imgPath ? getFormattedUrl(imgPath) : CDPlaceholder}
              ></IonImg>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </IonPopover>
    </div>
  );
};

export default PostDetailsCard;
