import './style.scss';
import {
  IonCol,
  IonGrid,
  IonImg,
  IonPage,
  IonPopover,
  IonRow,
  useIonViewWillLeave,
} from '@ionic/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Layout from '../../components/layouts';
import { AppContext } from '../../context/app.context';
import ProfileImage from '../../components/profileimg';
import {
  getDataForDropDown,
  getFormattedUrl,
  getKeyFromString,
  isWeb,
} from '../../utils/common.helper';
import InputText from '../../components/inputtext';
import { Form, Formik } from 'formik';
import { UserProfileDetailSchema } from '../../schemas/auth.schema';
import { i18n } from '@lingui/core';
import ValidationMessage from '../../components/validationMessage';
import AutocompleteInput from '../../components/autocompleteinput';
import {
  getLocationByPincode,
  getLocationList,
} from '../../services/location.service';
import ButtonComponent from '../../components/button-component';
import {
  deleteAccountResponse,
  deleteMyAccount,
  getUserDetails,
  removeUserProfilePic,
  userUpdate,
} from '../../services/user.service';
import { uploadFileWeb } from '../../services/filetransfer.service';
import Messages from '../../config/messages';
import PrivateRoute from '../../components/privateroute';
import { t } from '@lingui/macro';
import DeleteBox from '../../components/deletebox';
import { logout } from '../../services/auth.service';
import { INIT_PAGE } from '../../config/appconfig';
import { useHistory } from 'react-router';
import RespondBox from '../../components/respondbox';
import ICONPENCIL from '../../assets/images/icons/icon_pencil.png';
const MyProfile: React.FC = () => {
  let isProfilePicChange = false;
  let interval: any = null;
  const {
    userDetails,
    setLoading,
    setUserDetails,
    toastMessage,
    setIsLoggedIn,
  }: any = useContext(AppContext);

  const [pincodeList, setPincodeList] = useState([]);
  const [villageOrLocality, setVillageOrLocality] = useState([]);

  const [villageSuggestions, setVillageSuggestions] = useState([]);
  const [popoverStateDelete, setShowPopoverDelete] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popoverStateRespond, setShowPopoverRepond] = useState({
    showPopover: false,
    event: undefined,
  });
  const [respondText, setRespondText]: any = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();
  const initialValues = {
    firstName: userDetails.firstname || '',
    lastName: userDetails.lastname || null,
    landLine: userDetails.landline || null,
    pinCode: userDetails.locationDetail.pincode || '',
    state: userDetails.locationDetail.state || '',
    district: userDetails.locationDetail.district || '',
    villageLocality: userDetails.locationDetail.locality_name || '',

    profilePic: userDetails.profile_pic_obj
      ? userDetails.profile_pic_obj.uri
      : '',
    profilePicId: userDetails.profile_pic_obj
      ? userDetails.profile_pic_obj.id
      : null,
    locationId: userDetails.location_id || '',
  };

  const UserDetailSchemaMemo: any = useMemo(
    () => UserProfileDetailSchema(),
    [i18n.locale]
  );

  useEffect(() => {
    setLoading(true);

    Promise.all([
      getPincodeDetailsOnLoad(userDetails.locationDetail.pincode),
    ]).finally(() => {
      setLoading(false);
    });
  }, []);

  useIonViewWillLeave(() => {
    setShowPopoverDelete({ showPopover: false, event: undefined });
    setShowPopoverRepond({ showPopover: false, event: undefined });
  }, []);

  async function getPincodeDetailsOnLoad(pincode: string) {
    const resp: any = await getLocationByPincode(pincode);
    if (!resp.error && resp.items.length > 0) {
      const data = getDataForDropDown(resp.items, 'locality_name');
      setVillageOrLocality(data);
      setVillageSuggestions(data.map((item: any) => item.name));
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  async function getPincodeList(
    pincode: string,
    setFieldError: any,
    setFieldValue: any
  ) {
    setFieldValue('state', '');
    setFieldValue('district', '');
    setVillageOrLocality([]);
    setVillageSuggestions([]);
    setFieldValue('villageLocality', '');
    setFieldValue('locationId', '');
    if (pincode.trim().length >= 3) {
      const resp: any = await getLocationList(pincode);
      if (!resp.error) {
        setPincodeList(resp);
        if (resp.length == 0) {
          setFieldError('pinCode', 'No details found');
        }
      } else {
        setFieldError('pinCode', resp.message);
      }
    } else {
      setPincodeList([]);
    }
  }

  async function getPincodeDetails(pincode: string, setFieldValue: any) {
    const resp: any = await getLocationByPincode(pincode);
    if (!resp.error && resp.items.length > 0) {
      setFieldValue('pinCode', pincode);
      setFieldValue('state', resp.items[0].state);
      setFieldValue('district', resp.items[0].district);
      setVillageOrLocality(getDataForDropDown(resp.items, 'locality_name'));
      const data = getDataForDropDown(resp.items, 'locality_name');
      setVillageOrLocality(data);

      setVillageSuggestions(data.map((item: any) => item.name));
      // setFieldValue('villageLocality', resp.items[0].locality_name);
      // setFieldValue('locationId', resp.items[0].id);
    } else {
      // actions.setFieldError('pincode', resp.message);
    }
  }

  const handleUserDetails = async (values: any, actions: any) => {
    actions.setFieldError(undefined);
    setLoading(true);
    // actions.setFieldError('stakeholderCategory', 'From API Error');
    const postData: any = {
      firstname: values.firstName,
      lastname: values.lastName,
      location_id: values.locationId,
      landline: values.landLine,
      fullname: `${values.firstName} ${values.lastName || ''}`,
    };

    if (values.profilePic && values.profilePic != '' && isProfilePicChange) {
      const profileRes: any = await uploadFileWeb(values.profilePic);
      if (!profileRes.error) {
        postData['profile_pic'] = profileRes.id;
        postData['profile_pic_obj'] = {
          id: profileRes.id,
          uri: profileRes.uri,
        };

        values.profilePicId = profileRes.id;
        isProfilePicChange = false;
      }
    }
    const resp: any = await userUpdate(postData);
    actions.setSubmitting(false);
    setLoading(false);
    if (!resp.error) {
      const userRes = await getUserDetails();
      if (!userRes.error) {
        setUserDetails(userRes);
        toastMessage(Messages.PROFILE_UPDATED_MSG);
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  async function onProfileImageSelect(image: string, setFieldValue: any) {
    setFieldValue('profilePic', image);
    isProfilePicChange = true;
  }

  return (
    <IonPage className='my-profile'>
      <Layout>
        <div className='section page-container'>
          <div className='container container--small'>
            <div className='content-sec--box'>
              <div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={UserDetailSchemaMemo}
                  onSubmit={handleUserDetails}
                  validateOnChange={false}
                  key='user-details'
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    setFieldTouched,
                    setFieldError,
                    isSubmitting,
                  }) => (
                    <Form>
                      <IonGrid>
                        <IonRow>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <ProfileImage
                              isUpload
                              customClass='userPicture'
                              imgPath={
                                userDetails.profile_pic_obj
                                  ? getFormattedUrl(
                                      userDetails.profile_pic_obj.uri
                                    )
                                  : undefined
                              }
                              onCrop={(image: string) => {
                                onProfileImageSelect(image, setFieldValue);
                              }}
                              onRemove={async () => {
                                await removeUserProfilePic();
                                const resp = await getUserDetails();
                                if (!resp.error) {
                                  setUserDetails(resp);
                                }
                                setFieldValue('profilePicId', null);
                                setFieldValue('profilePic', '');
                                return true;
                              }}
                            />
                          </IonCol>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <IonRow>
                              <IonCol size='12'>
                                <InputText
                                  required
                                  name='first_name'
                                  labelName='First Name'
                                  labelKey='first_name'
                                  placeholder={''}
                                  value={values.firstName}
                                  onChange={(value: any) => {
                                    setFieldValue('firstName', value);
                                    setFieldTouched('firstName', true);
                                  }}
                                  error={Boolean(
                                    errors.firstName && touched.firstName
                                  )}
                                ></InputText>
                                <ValidationMessage
                                  name={'firstName'}
                                  customClass='validationError-profile'
                                />
                              </IonCol>
                              <IonCol size='12'>
                                <InputText
                                  name='last_name'
                                  labelName='Last Name'
                                  labelKey='last_name'
                                  placeholder={''}
                                  value={values.lastName || ''}
                                  onChange={(value: any) => {
                                    if (value && value.trim().length > 0) {
                                      setFieldValue('lastName', value);
                                      setFieldTouched('lastName', true);
                                    } else {
                                      setFieldValue('lastName', null);
                                      setFieldTouched('lastName', true);
                                    }
                                  }}
                                  error={Boolean(
                                    errors.lastName && touched.lastName
                                  )}
                                ></InputText>
                                <ValidationMessage
                                  name={'lastName'}
                                  customClass='validationError-profile'
                                />
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              name='landlineNumber'
                              labelName='Landline Number'
                              labelKey='landline_number'
                              //value={""}
                              value={values.landLine}
                              type='number'
                              onChange={(value: any) => {
                                setFieldValue('landLine', value);
                                setFieldTouched('landLine', true);
                              }}
                            ></InputText>
                          </IonCol>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              name='mobileNumber'
                              labelName='Mobile Number'
                              labelKey='mobile_number'
                              required
                              //value={""}
                              value={userDetails.phone_number || ''}
                              type='number'
                              disabled
                              inputMode={'numeric'}
                              maxlength={10}
                            ></InputText>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              name='email'
                              labelName='Email ID'
                              labelKey='email_id'
                              //value={""}
                              value={userDetails.email || ''}
                              disabled
                            ></InputText>
                          </IonCol>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <AutocompleteInput
                              required
                              maxlength={6}
                              suggestions={pincodeList}
                              onItemSelected={async (value: string) => {
                                setFieldValue('pinCode', value);
                                setFieldTouched('pinCode', true);
                                await getPincodeDetails(value, setFieldValue);
                              }}
                              name='pinCode'
                              labelName='Pin code'
                              labelKey='pin_code'
                              //value={""}
                              value={values.pinCode}
                              onSuggestionChange={async (value: any) => {
                                setFieldValue('pinCode', value);
                                setFieldTouched('pinCode', true);
                                if (interval) {
                                  clearInterval(interval);
                                  interval = null;
                                }
                                interval = setInterval(async () => {
                                  await getPincodeList(
                                    value,
                                    setFieldError,
                                    setFieldValue
                                  );
                                  clearInterval(interval);
                                  interval = null;
                                }, 500);
                              }}
                              error={Boolean(errors.pinCode && touched.pinCode)}
                              type='number'
                              // debounce={1000}
                              inputMode={'numeric'}
                            />
                            <ValidationMessage
                              name={'pinCode'}
                              customClass='validationError-profile'
                            />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              name='state'
                              labelName='State'
                              labelKey='state'
                              required
                              disabled
                              value={values.state}
                              onChange={(value: any) => {
                                setFieldValue('state', value);
                                setFieldTouched('state', true);
                              }}
                              error={Boolean(errors.state && touched.state)}
                            ></InputText>
                            <ValidationMessage
                              name={'state'}
                              customClass='validationError-profile'
                            />
                          </IonCol>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              name='district'
                              labelName='District'
                              labelKey='district'
                              required
                              disabled
                              value={values.district}
                              onChange={(value: any) => {
                                setFieldValue('district', value);
                                setFieldTouched('district', true);
                              }}
                              error={Boolean(
                                errors.district && touched.district
                              )}
                            ></InputText>
                            <ValidationMessage
                              name={'district'}
                              customClass='validationError-profile'
                            />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <AutocompleteInput
                              showSuggestionOnFocus
                              required
                              suggestions={villageSuggestions}
                              onItemSelected={(value: string) => {
                                setFieldValue('villageLocality', value);
                                setFieldTouched('villageLocality', false);
                                const villageItem: any = villageOrLocality.find(
                                  (item: any) => item.name == value
                                );
                                if (villageItem) {
                                  setFieldTouched('locationId', false);
                                  setFieldValue('locationId', villageItem.id);
                                }
                              }}
                              name='pinCode'
                              labelName='Village / Locality'
                              labelKey='village_locality'
                              placeholderKey='type_your_village'
                              placeholderName='Type your Village/Locality'
                              //value={""}
                              value={values.villageLocality}
                              onSuggestionChange={async (value: any) => {
                                setFieldValue('villageLocality', value);
                                setFieldTouched('villageLocality', true);
                                setFieldValue('locationId', '');
                                setFieldTouched('locationId', true);
                              }}
                              error={Boolean(
                                errors.locationId && touched.locationId
                              )}
                              debounce={500}
                            />
                            <ValidationMessage
                              name={'locationId'}
                              customClass='validationError-profile'
                            />
                          </IonCol>
                        </IonRow>
                        <h4 className='section-heading mb-15 mt-15 ml-5'>
                          {t({
                            id: 'type_of_user',
                            message: 'Type of Users',
                          })}
                        </h4>
                        <IonRow>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              name='stakeholderCategory'
                              labelName='Stakeholders Category'
                              labelKey='stakeholders_category'
                              required
                              disabled
                              value={t({
                                id: getKeyFromString(
                                  userDetails.stakeholderDetails
                                    .stakeholder_name
                                ),
                                message:
                                  userDetails.stakeholderDetails
                                    .stakeholder_name,
                              })}
                            ></InputText>
                          </IonCol>
                          <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                            <InputText
                              labelName='Type of Stakeholders'
                              labelKey='type_of_stakeholders'
                              required
                              disabled
                              value={t({
                                id: getKeyFromString(
                                  userDetails.stakeholderDetails
                                    .stakeholdertype_name
                                ),
                                message:
                                  userDetails.stakeholderDetails
                                    .stakeholdertype_name,
                              })}
                            ></InputText>
                          </IonCol>
                        </IonRow>
                        {userDetails.organization && (
                          <IonRow>
                            <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                              <InputText
                                name='nameOfOrganization'
                                labelName='Name of Organization'
                                labelKey='name_of_organization'
                                required
                                disabled
                                value={userDetails.organization}
                              ></InputText>
                            </IonCol>
                          </IonRow>
                        )}
                        {userDetails.number_of_farmer_fpo &&
                        userDetails.number_of_farmer_fpo != 0 ? (
                          <>
                            <h4 className='section-heading mb-15 mt-15 ml-5'>
                              FPO’s Details
                            </h4>
                            <IonRow>
                              <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                                <InputText
                                  name='fpoRegNumber'
                                  labelName='FPO Registration Number'
                                  labelKey='fpo_registration_number'
                                  required
                                  disabled
                                  value={
                                    userDetails.fpo_registration_number || ''
                                  }
                                ></InputText>
                              </IonCol>
                              <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                                <InputText
                                  name='fpoPromoBy'
                                  labelName='FPO Promoted By'
                                  labelKey='fpo_promoted_by'
                                  required
                                  disabled
                                  value={userDetails.fpo_promoted_by || ''}
                                ></InputText>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                                <InputText
                                  labelName='Year of Registration'
                                  labelKey='year_of_registration'
                                  required
                                  disabled
                                  value={userDetails.year_of_registration || ''}
                                ></InputText>
                              </IonCol>
                              <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                                <InputText
                                  name='regFarmer'
                                  labelName='No. of farmer registered under FPOs'
                                  labelKey='no_farmer_register'
                                  required
                                  disabled
                                  value={userDetails.number_of_farmer_fpo || ''}
                                ></InputText>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol sizeLg='6' sizeMd='12' sizeXs='12'>
                                <InputText
                                  name='annualTurnover'
                                  labelName='Annual Turnover(in Rs Lakhs)'
                                  labelKey='annual_turnover'
                                  required
                                  disabled
                                  value={userDetails.annual_turn_over}
                                ></InputText>
                              </IonCol>
                            </IonRow>
                          </>
                        ) : null}
                      </IonGrid>

                      <div className='form__submit-btn'>
                        <ButtonComponent
                          name={'Submit'}
                          customClass='login-btn-submit ml-10 mt-15'
                          textKey={'submit'}
                          disabled={isSubmitting}
                        />
                      </div>

                      {userDetails.stakeholder_id == 3 && (
                        <>
                          <div className='post-divider mt-40 mb-15'></div>
                          <div
                            className='edit-commodity ml-10 font-aktiv-grotest-medium'
                            onClick={() => {
                              history.push('/my-profile/my-commodity');
                            }}
                          >
                            <IonImg
                              style={{ width: 25, height: 25, marginRight: 10 }}
                              src={ICONPENCIL}
                            />
                            {t({
                              id: 'commodities',
                              message: 'Commodities',
                            })}
                          </div>

                          <div
                            className='edit-commodity mt-15 ml-10 font-aktiv-grotest-medium'
                            onClick={() => {
                              history.push('/my-profile/my-solution');
                            }}
                          >
                            <IonImg
                              style={{ width: 25, height: 25, marginRight: 10 }}
                              src={ICONPENCIL}
                            />
                            {t({
                              id: 'solutions',
                              message: 'Solutions',
                            })}
                          </div>
                        </>
                      )}
                      <div className='post-divider mt-15'></div>

                      <div
                        className='ml-10 mt-10'
                        onClick={() => {
                          setShowPopoverDelete({
                            showPopover: true,
                            event: undefined,
                          });
                        }}
                      >
                        <span className='delete-account'>
                          {t({
                            id: 'delete_my_account',
                            message: 'Delete My Account',
                          })}
                        </span>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <IonPopover
          event={popoverStateDelete.event}
          isOpen={popoverStateDelete.showPopover}
          onDidDismiss={() => {
            setShowPopoverDelete({ showPopover: false, event: undefined });
          }}
          className='delete-pop'
        >
          <DeleteBox
            title='Please read this carefully'
            titleKey='delete_account_title'
            message='You are about to submit a request for us to permanently close your Covestro food security platform account and delete your data. Once your account is closed all the product and services accessed through your account will no longer available to you.'
            messageKey='delete_account_msg'
            confirmMsg='Do you really want to Proceed ?'
            confirmMsgKey='delete_account_confirm'
            positiveBtnText='Close My Account'
            positiveBtnTextKey='delete_account_ok_btn'
            negativeBtnText='Cancel'
            negativeBtnTextKey='cancel'
            isSubmitting={false}
            onNegativeClick={() => {
              setShowPopoverDelete({ showPopover: false, event: undefined });
            }}
            onPositiveClick={async () => {
              setShowPopoverDelete({ showPopover: false, event: undefined });
              setShowPopoverRepond({
                showPopover: true,
                event: undefined,
              });
            }}
          />
        </IonPopover>
        <IonPopover
          event={popoverStateRespond.event}
          isOpen={popoverStateRespond.showPopover}
          onDidDismiss={() => {
            setShowPopoverRepond({ showPopover: false, event: undefined });
            setRespondText('');
          }}
          className='img-popover modal-style respond-pop'
          // backdropDismiss={false}
        >
          <RespondBox
            placeholderName='Reason For Delete Account'
            placeholderKey='reason_delete'
            isSubmitting={isDeleting}
            respondText={respondText}
            onRepondChange={(text) => {
              setRespondText(text);
            }}
            onRespondCancel={() => {
              setShowPopoverRepond({ showPopover: false, event: undefined });
              setRespondText('');
            }}
            onRespondSubmit={async () => {
              setIsDeleting(true);
              await deleteAccountResponse(respondText);
              const resp = await deleteMyAccount();
              if (!resp.error) {
                toastMessage(Messages.ACCOUNT_DELETE_MSG);
              } else {
                toastMessage(Messages.SERVER_ERROR, 'danger');
              }
              setShowPopoverRepond({ showPopover: false, event: undefined });
              setIsDeleting(false);
              setTimeout(async () => {
                await logout();
                setIsLoggedIn('false');
                setUserDetails({});
                history.replace(isWeb() ? INIT_PAGE : '/login');
                window.location.reload();
              }, 500);
            }}
          />
        </IonPopover>
      </Layout>
    </IonPage>
  );
};
export default PrivateRoute(MyProfile);
