class Messages {
  static NO_INTERNET_MSG = 'No Internet Connection';
  static SERVER_ERROR = 'Something went wrong. Please try again';
  static REG_SUCCESS = 'Congratulations, you have successfully registered';
  static INTERNET_AVL_MSG = 'Back to Online';
  static EMAIL_VALIDATION_MSG = 'Email is Required';
  static INVALID_EMAIL_MSG = 'Email is Invalid';
  static OTP_VALIDATION_MSG = 'OTP is Required';
  static STAKEHOLDER_CATEGORY_MSG = 'Select Stakeholder Category';
  static STAKEHOLDER_TYPE_MSG = 'Select Stakeholder Type';
  static NAME_ORGANIZATION_MSG = 'Enter Name of Organization';
  static EMAIL_OR_MOBILE_VALIDATION_MSG = 'Email/Mobile is Required';
  static INVALID_EMAIL_OR_MOBILE_MSG = 'Email/Mobile is Invalid';
  static INVALID_MOBILE_MSG = 'Mobile number is Invalid';
  static FIRST_NAME_MSG = 'Enter Your First Name';
  static FIRST_NAME_MIN_MSG = 'Minimum 2 Char Required';
  static LAST_NAME_MSG = 'Enter Your Last Name';
  static LAST_NAME_MIN_MSG = 'Minimum 2 Char Required';
  static LANDLINE_NUMBER_MSG = 'Enter Your Landline Number';
  static MOBILE_NUMBER_MSG = 'Enter Your Mobile Number';
  static PINCODE_MSG = 'Enter Your Pin Code';
  static STATE_MSG = 'State is Required';
  static DISTRICT_MSG = 'District is Required';
  static VILLAGE_OR_LOCALITY_MSG = 'Select Village or Locality';
  static FPO_REG_NUM_MSG = 'FPO Registration Number is Required';
  static FPO_PROMO_BY_MSG = 'Enter Pomoted By';
  static YEAR_OF_REG_MSG = 'Select Year of Registration';
  static REG_FARMER_MSG = 'Enter Number of Register Farmer';
  static ANNUAL_TURNOVER_MSG = 'Enter Annual Turnover';
  static CHECK_TNC_MSG = 'Please check Terms & Conditions';
  static RESEND_OTP_MSG = 'OTP Sent Successfully';
  static USER_NOT_REGISTER = 'User not registered. Please register to login';
  static CATEGORY_VALIDATION_MSG = 'Category is Required';

  // Messages for requirement
  static COMMODITY_MSG = 'Please Select Commodity';
  static CATEGORY_MSG = 'Please Enter Category';
  static VARIETYORBREED_MSG = 'Please Enter Variety or Breed';
  static QUANTITY_MSG = 'Please Enter Quantity';
  static UNIT_MSG = 'Please Select Unit for Quantity';
  static PIN_MSG = 'Please Enter Your Pin Code';
  static VILLAGESORLOCALITY_MSG = 'Please Select Village';
  static DSTRICT_MSG = 'Please Enter District';
  static STATES_MSG = 'Please Enter State';
  static TYPE_OF_COMMODITY_MSG = 'Please Select Type of Commodity';
  static PRICE_EXPECTED_MSG = 'Please Enter Price Expected';
  static UNIT_PRICE_EXPECTED_MSG = 'Please Select Unit for Price Expected';

  //Message for sellrequirement
  static SELL_COMMODITY_MSG = 'Please Select Commodity';
  static SELL_CATEGORY_MSG = 'Please Enter Category';
  static SELL_VARIETYORBREED_MSG = 'Please Enter Variety Or Breed';
  static SELL_TYPE_MSG = 'Please Select Type of Commodity';
  static SELL_DISTRICT_MSG = 'Please Enter District';
  static SELL_PINCODE_MSG = 'Please Enter Your Pin Code';
  static SELL_STATES_MSG = 'Please Enter State';
  static SELL_VILLAGESORLOCALITY_MSG = 'Please Select Village';
  static LOGISTIC_MSG = 'Please Select Logistic Requirement';
  static UPLOAD_IMAGE_MSG = 'Please Upload Image';

  static IMAGE_MAX_SIZE_MSG = 'Image should be of max size 2mb';
  static COMMENT_ADDED_MSG = 'Comment added';
  static POST_ADDED_MSG = 'Post submitted';
  static RESPOND_ADDED_MSG = 'Your response sent to user';
  static PROFILE_UPDATED_MSG = 'Profile updated successfully';
  static POST_DELETE_MSG = 'Post deleted successfully';
  static EXPIRY_EXTEND_ADDED_MSG = 'Expiry of your post extended successfully';
  //Message for faq
  static CONTACT_CREATE_MSG = 'Contact created successfully';
  static MESSAGE_MSG = 'Please Enter message';
  static NAME_MSG = 'Enter Your Name';
  static ACCOUNT_DELETE_MSG = 'Your Account Deleted Successfully';
  static ACTIVATE_SUCCESS_MSG = 'Your request submitted successfully';
  static COMMODITY_UPDATE = 'Thank you, you have updated your commodity';
  static SOLUTION_UPDATE = 'Thank you, you have updated your solution used';
}

export default Messages;
