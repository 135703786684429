import { IonImg } from '@ionic/react';
import { t } from '@lingui/macro';
import EmptyIcon from '../../assets/images/icons/icon_empty.png';

interface Props {
  message?: string;
  messageKey?: string;
}
const EmptyData: React.FC<Props> = (props: Props) => {
  const { message, messageKey } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        color: 'black',
      }}
      className='mt-10'
    >
      <IonImg src={EmptyIcon} style={{ width: 60, height: 60 }} />
      <div className='font-aktiv-grotest-medium mt-10'>
        {t({
          id: `${messageKey ? messageKey : 'no_post_found'}`,
          message: `${message ? message : 'No Posts Found'}`,
        })}
      </div>
    </div>
  );
};
export default EmptyData;
