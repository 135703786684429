import { APP_DOMAIN } from '../../../config/appconfig';

const TNCHI: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        Covestro खाद्य सुरक्षा प्लेटफ़ॉर्म को एक्सेस करने से संबंधित नियम और
        शर्तें
      </h3>
      <h4 style={{ textAlign: 'center' }}>पिछला अपडेट [28 जुलाई, 2022]</h4>
      <p>
        Covestro (INDIA) प्राइवेट लिमिटेड में हमारे समुदाय का हिस्सा बनने के लिए
        धन्यवाद, इसे पहले Bayer Material Science Private लिमिटेड के नाम से जाना
        जाता था, कंपनी अधिनियम, 1956 के तहत शामिल एक प्राइवेट लिमिटेड कंपनी है
        और इसका पंजीकृत कार्यालय - यूनिट नंबर SB-801, 8वीं मंजिल, एम्पायर टॉवर,
        क्लाउड सिटी कैंपस, ऐरोली, ठाणे - बेलापुर रोड नवी मुंबई ठाणे MH 400708 IN
        में स्थित है (&quot;Covestro &quot;, &quot;कंपनी&quot;, &quot;हम&quot;,
        &quot;हमें&quot;, या &quot;हमारा&quot;, या &quot;प्लेटफ़ॉर्म&quot;)।
      </p>
      <p>
        नीचे दी गई ये शर्तें &quot;उपयोगकर्ता&quot; (इसके बाद &quot;आप&quot; या
        &quot;ग्राहक&quot; के रूप में भी उल्लिखित होंगे) और Covestro के बीच
        संविदात्मक संबंध को निर्धारित करती हैं। इस प्रकार से यह स्पष्ट किया जाता
        है कि &quot;उपयोगकर्ता&quot; या &quot;आप&quot; शब्द का अर्थ विधिवत
        पंजीकृत और प्लेटफ़ॉर्म का उपयोग करने वाले किसी भी व्यक्ति अर्थात
        उपभोक्ताओं, किसान और/या किसान समाज/समूह से है।
      </p>
      <p>
        COVESTRO ने इस वेबसाइट/ऐप [खाद्य सुरक्षा प्लेटफ़ॉर्म] और/या किसी भी
        संबंधित सेवा, बिक्री, विपणन या इवेंट (हम उन्हें इस नीति में सामूहिक रूप
        से &quot;प्लेटफ़ॉर्म&quot; या &quot;वेबसाइट/ऐप&quot; के रूप में संदर्भित
        करते हैं) को उपयोगकर्ताओं को उत्पाद और सेवाएं प्रदान करने के लिए बनाया
        गया है, जिनमें वित्तीय योजना या नीति, खाद्य और कृषि उत्पाद और उत्पाद
        संबंधी मार्गदर्शन और उपयोगकर्ताओं को विभिन्न तृतीय-पक्ष विक्रेताओं को
        एक्सेस प्रदान करने जैसी सेवाएं सम्मिलित हैं। जबकि अनुशंसाएं कई कारकों पर
        विचार करती हैं, जिनमें उपयोगकर्ताओं द्वारा प्रदान की गई जानकारी शामिल
        है, लेकिन यह केवल इसतक ही सीमित नहीं हैं और यह केवल प्रत्येक उपयोगकर्ता
        के लिए विशिष्ट हैं, हालांकि यह दोहराया जाता है और निर्दिष्ट किया जाता है
        कि हम इस प्लेटफ़ॉर्म पर उपलब्ध किसी भी वित्तीय योजना या नीति, भोजन की
        गुणवत्ता या प्रामाणिकता की गारंटी नहीं देते हैं और यह प्लेटफ़ॉर्म ऑनलाइन
        बाज़ारस्थल के रूप में केवल एक पुल की तरह कार्य करता है।
      </p>
      <p>
        इस प्लेटफ़ॉर्म का आपके द्वारा उपयोग से यह अर्थ है कि आपने निम्नलिखित,
        अस्वीकरण, नियम और शर्तों (&quot;नियम और शर्तें&quot;) को पढ़ और समझ लिया
        है और उनका पालन करने के लिए सहमत हैं। हमारे नियमों और शर्तों के साथ पढ़ी
        जाने वाली यह गोपनीयता नीति प्लेटफ़ॉर्म के आपके उपयोग और एक्सेस को
        नियंत्रित करती है। यदि आप इन नियमों और शर्तों के किसी भी भाग से असहमत
        हैं तो कृपया प्लेटफ़ॉर्म का उपयोग या एक्सेस न करें।
      </p>
      <ol>
        <li>
          <h3>एक्सेस और उपयोग करने संबंधी नियमों और शर्तों की स्वीकृति</h3>
          <ol>
            <li>
              <p>
                हमारी किसी भी सेवा की सदस्यता लेकर या उसका उपयोग करके आप सहमति
                देते हैं कि आपने शर्तों को पढ़ और समझ लिया है और अब किस तरह से
                सदस्यता लेते हैं या सेवाओं का उपयोग करते हैं, आप इनसे संबंधित
                नियमों का पालन करने के लिए बाध्य हैं। यदि आप शर्तों को बाध्य
                नहीं रहना चाहते हैं, तो आपको हमारी सेवाओं की सदस्यता या उपयोग
                नहीं करना चाहिए। सूचना प्रौद्योगिकी अधिनियम, 2000 के तहत तैयार
                किए गए सूचना प्रौद्योगिकी (मध्यवर्ती दिशानिर्देश) नियम, 2011 के
                प्रावधानों के अनुसार ये नियम और विभिन्न अन्य नीतियां बाध्यकारी
                हैं।
              </p>
            </li>
            <li>
              <p>
                ये शर्तें आपके और COVESTRO के लिए महत्वपूर्ण हैं क्योंकि इनका
                उपयोग एक मूल्यवान ग्राहक के रूप में आपके अधिकारों और व्यवसाय के
                रूप में हमारे अधिकारों की रक्षा के लिए किया जाता है।
              </p>
            </li>
            <li>
              <p>
                ये शर्तें लागू हैं और अन्य सभी शर्तों को प्रतिस्थापित करती हैं,
                COVESTRO द्वारा व्यक्त करने, लिखित,वितरण से पूर्व मामले के
                सिवाय।
              </p>
            </li>
            <li>
              <p>
                जब आप प्लेटफ़ॉर्म के माध्यम से Covestro द्वारा प्रदान की गई किसी
                भी सेवा का उपयोग करते हैं, जिसमें उत्पाद समीक्षाएं, विक्रेता
                समीक्षाएं, संभावित विक्रेताओं के संपर्क विवरण प्राप्त करना आदि
                शामिल हैं, लेकिन इसतक सीमित नहीं है। आप ऐसी सेवा पर लागू नियमों,
                दिशानिर्देशों, नीतियों, नियमों और शर्तों के अधीन होंगे और उन्हें
                इस उपयोग की शर्तों में शामिल माना जाएगा और उन्हें इस उपयोग की
                शर्तों का हिस्सा और पार्सल माना जाएगा।
              </p>
            </li>
            <li>
              <p>
                हम उपयोग इन की शर्तों को संशोधित करने के अपने अधिकारों को
                स्वीकृति नहीं देते हैं, किसी भी समय आपको लिखित रूप से पूर्व
                सूचित किए बिना। अद्यतन/परिवर्तनों के लिए समय-समय पर इन उपयोग की
                शर्तों की समीक्षा करना आपकी जिम्मेदारी है। परिवर्तनों को दर्ज
                करने के बाद आपके द्वारा प्लेटफ़ॉर्म के निरंतर उपयोग से यह अर्थ
                होगा कि आप संशोधनों को स्वीकार करते हैं और उनसे सहमत हैं। हम यह
                बनाए रखते हैं कि आप सेवा की शर्तों के सबसे वर्तमान संस्करण की
                समीक्षा यहां कर सकते हैं:{' '}
                <a
                  href={`${APP_DOMAIN}legal/conditions-of-use`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/conditions-of-use`}
                </a>
              </p>
            </li>
            <li>
              <p>
                जब तक आप इन उपयोग की शर्तों का पालन करते हैं, हम आपको
                प्लेटफ़ॉर्म में प्रवेश करने और उपयोग करने के लिए व्यक्तिगत,
                गैर-अनन्य, गैर-हस्तांतरणीय, सीमित विशेषाधिकार प्रदान करते हैं।
              </p>
            </li>
            <li>
              <p>
                हमने अपनी नीति को यूरोपीय संघ के जनरल डेटा प्रोटेक्शन रेगुलेशन
                (GDPR) के अनुरूप बनाने के लिए अद्यतित किया है। नए नीति
                परिवर्तनों के अनुसार, आपका अपने डेटा पर अधिक नियंत्रण होता है,
                जिससे यह आपके लिए अधिक सुरक्षित और महफूज हो जाता है
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>तृतीय पक्ष सेवाएं</h3>
          <ol>
            <li>
              <p>
                प्लेटफ़ॉर्म आपको तृतीय-पक्ष उत्पाद या सेवा (&quot;तृतीय पक्ष
                सेवाएं&quot;) के लिंक तक एक्सेस प्रदान कर सकता है. COVESTRO किसी
                भी तरह से तृतीय पक्ष की सेवाओं को नियंत्रित नहीं करता है और
                तदनुसार, ऐसी तृतीय पक्ष सेवाओं से जुड़ी किसी भी ज़िम्मेदारी को
                नहीं मानता है। आपको यह निर्धारित करने के लिए उचित कदम उठाने की
                आवश्यकता है कि क्या तृतीय पक्ष सेवा को एक्सेस करना उचित है या
                नहीं, जिसमें ऐसी किसी भी तृतीय पक्ष सेवाओं का उपयोग करने में
                आपकी व्यक्तिगत जानकारी और गोपनीयता की रक्षा करने सहित और
                प्रासंगिक अनुबंध का अनुपालन करना शामिल है।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>एक्सेस और उपयोग</h3>
          <ol>
            <li>
              <p>
                प्लेटफ़ॉर्म एक बाज़ारस्थल है, जहां उपयोगकर्ताओं को विविध प्रकार
                की खाद्य और कृषि उपज और संबंधित सेवाओं और समाधानों का एक्सेस
                प्रदान किया जाता है, इनमें सब्जी, फल, अनाज, दाल, मसाला, रोपण
                फसल, वित्तीय योजनाएं, नीतियां, योजनाएं शामिल हैं, लेकिन इसतक
                सीमित नहीं है।
              </p>
            </li>
            <li>
              <p>
                प्लेटफ़ॉर्म के माध्यम से आपके द्वारा सबमिट की गई कोई भी सामग्री{' '}
                <a
                  href={`${APP_DOMAIN}legal/privacy-statement`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/privacy-statement`}
                </a>{' '}
                पर उपलब्ध कंपनी की गोपनीयता नीति द्वारा नियंत्रित होती है। इस
                अनुसार, जहां अनुबंध और कंपनी की गोपनीयता नीति के बीच असंगतता है,
                ये शर्तें शासित होंगी।
              </p>
            </li>
            <li>
              <p>
                चर्चा फोरम के संबंध में, यदि आप कोई प्रश्न या प्रतिक्रिया
                प्रस्तुत करते हैं, तो आप अपने स्वयं के संप्रेषण, उन संप्रेषण को
                पोस्ट करने के परिणामों और सार्वजनिक क्षेत्रों में पाए जाने वाले
                किसी भी संप्रेषण पर अपनी निर्भरता के लिए पूरी तरह से जिम्मेदार
                हैं। सार्वजनिक क्षेत्रों में किसी भी संप्रेषण के परिणामों के लिए
                कंपनी और उसके लाइसेंसकर्ता जिम्मेदार नहीं हैं। ऐसी स्थिति, जहां
                आपको खतरा महसूस होता है या आपको लगता है कि कोई और खतरे में है,
                आपको तुरंत अपनी स्थानीय कानून प्रवर्तन एजेंसी से संपर्क करना
                चाहिए। आप प्लेटफ़ॉर्म के संबंध में अपनी सभी गतिविधियों के लिए आप
                स्वयं ज़िम्मेदार हैं और आप सभी स्थानीय, राज्य, राष्ट्रीय और
                अंतर्राष्ट्रीय कानून और विनियमों और किसी भी लागू नियामक कोड का
                पालन करेंगे।
              </p>
            </li>
            <li>
              <p>
                अस्थायी आधार पर उत्पाद और सेवाओं को एक्सेस करने की अनुमति है और
                हम उत्पाद और सेवाओं (या उत्पादों और सेवाओं के भीतर किसी भी
                विशेषता) को वापस लेने या संशोधित करने का अधिकार सुरक्षित रखते
                हैं, जिसे आपको कोई पूर्व सूचना दिए बिना, हम समय-समय पर
                प्लेटफ़ॉर्म पर आपको इस संबंध में सूचित करते रहते हैं। यदि किसी
                भी कारण से प्लेटफ़ॉर्म या उत्पाद और सेवाएं (या उत्पाद और सेवाओं
                के भीतर कोई विशेषता) किसी भी समय या किसी भी अवधि के लिए अनुपलब्ध
                हैं, तो हम इसके उत्तरदायी नहीं होंगे। समय-समय पर, हम अपने
                विवेकाधिकार से, हमारे साथ पंजीकृत उपयोगकर्ताओं के लिए प्लेटफार्म
                ,के कुछ हिस्से या संपूर्ण प्लेटफ़ॉर्म तक एक्सेस को प्रतिबंधित कर
                सकते हैं।
              </p>
            </li>
            <li>
              <p>
                समय-समय पर, हम अपने विवेकाधिकार से, हमारे साथ पंजीकृत
                उपयोगकर्ताओं के लिए प्लेटफ़ॉर्म के कुछ हिस्से या संपूर्ण
                प्लेटफ़ॉर्म तक एक्सेस को प्रतिबंधित कर सकते हैं। आप यह सुनिश्चित
                करने के लिए भी जिम्मेदार हैं कि कोई अन्य व्यक्ति प्लेटफ़ॉर्म का
                उपयोग नहीं करेगा और इस तरह आपके खाते के माध्यम से उत्पाद और
                सेवाओं को पता है कि इस तरह के किसी भी एक्सेस के लिए आप पूर्ण रूप
                से ज़िम्मेदार होंगे, इसमें हमारा किसी भी तरह का कोई दायित्व नहीं
                होगा।
              </p>
            </li>
            <li>
              <p>
                अपने खाते के अंतर्गत घटित या उत्पन्न होने वाली सभी तरह की
                गतिविधियों के लिए आप COVESTRO के प्रति पूरी तरह से ज़िम्मेदार और
                उत्तरदायी हैं। आप सहमति देते हैं कि आपके खाते में लॉग इन करने की
                क्षमता बाहरी कारकों जैसे कि इंटरनेट सेवा प्रदाता और इंटरनेट
                नेटवर्क कनेक्टिविटी पर निर्भर है और आपके खाते में लॉग इन करने
                में होने वाली अक्षमता से उत्पन्न होने वाले किसी भी नुकसान के लिए
                हम उत्तरदायी नहीं होंगे।
              </p>
            </li>
            <li>
              <p>
                COVESTRO नए उपयोगकर्ताओं के लिए प्लेटफ़ॉर्म पर पेश किए गए उत्पाद
                और सेवाओं का उपयोग को रद्द करने या मौजूदा उपयोगकर्ताओं के एक्सेस
                को किसी भी समय समाप्त करने का अधिकार अपने पास सुरक्षित रखता है,
                ऐसा करने के लिए किसी भी कारण के बिना। खाते का उपयोग किसी भी
                उपयोगकर्ता के लिए उपलब्ध नहीं है जिसे किसी भी कारण से
                प्लेटफ़ॉर्म या सेवाओं का उपयोग करने से COVESTRO द्वारा निलंबित
                या प्रतिबंधित किया गया है।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>सदस्यता/पंजीकरण और पात्रता</h3>
          <ol>
            <li>
              <p>
                उत्पाद और सेवाओं को एक्सेस करने के लिए, आपको प्लेटफ़ॉर्म पर
                पंजीकरण करना होगा और एक &quot;सदस्य&quot; खाता बनाना होगा। आपका
                खाता आपको उन उत्पाद और सेवाओं और कार्यक्षमता तक एक्सेस प्रदान
                करता है जिन्हें हम समय-समय पर और प्लेटफ़ॉर्म पर अपने विवेकाधिकार
                से स्थापित और बनाए रख सकते हैं।
              </p>
            </li>
            <li>
              <p>
                पंजीकरण प्रक्रिया पूरी करके, आप आश्वासन देते हैं कि: (अ) आपके
                द्वारा प्रदान किया गया सभी डेटा सटीक और पूर्ण है; (ब) आप ऐसी
                किसी भी जानकारी को नियमित रूप से अपडेट करके ऐसी जानकारी की
                सटीकता और उसमें किसी भी बदलाव को बनाए रखेंगे; (स) आप पुष्टि करते
                हैं कि आप 16 वर्ष से अधिक आयु के हैं और इस अनुबंध में निर्धारित
                नियम, शर्त, दायित्व, प्रतिज्ञान, प्रतिनिधित्व और वारंटियों में
                प्रवेश करने और इस अनुबंध का पालन करने और इससे बाध्य रहें के लिए
                पूरी तरह से सक्षम हैं; (द) यदि आप 18 (अठारह) वर्ष से कम आयु के
                हैं, तो आपके माता-पिता या अभिभावक को इस अनुबंध की शर्तों की
                समीक्षा करनी चाहिए और उन्हें स्वीकार करना चाहिए, और प्लेटफ़ॉर्म
                का उपयोग करके आप पुष्टि करते हैं कि आपके माता-पिता या अभिभावक ने
                इस अनुबंध की समीक्षा की है और इसे स्वीकार किया है। हम 18 वर्ष से
                कम आयु के उपयोगकर्ताओं के लिए प्लेटफ़ॉर्म में कुछ सुविधाओं की
                उपलब्धता को सीमित करने का अधिकार अपने विवेकाधिकार में सुरक्षित
                रखते हैं। (ई) आप बाध्यकारी अनुबंध में प्रवेश करने के लिए लागू
                कानून के अनुसार पात्र हैं और लागू कानून के तहत उत्पाद और सेवाओं
                को प्राप्त करने से प्रतिबंधित व्यक्ति नहीं हैं; और (च) आप केवल
                इस अनुबंध और सभी लागू स्थानीय, राज्य, राष्ट्रीय और
                अंतर्राष्ट्रीय कानून, नियम और विनियम के पालन में प्लेटफ़ॉर्म या
                प्लेटफ़ॉर्म के माध्यम से प्रदान की जाने वाली ऐसी अन्य सेवाओं का
                उपयोग कर सकते हैं। किसी भी तरह की हानि, क्षति या अन्य परिणाम,
                स्वास्थ्य संबंधी या अन्यथा प्लेटफ़ॉर्म पर आपके द्वारा प्रदान की
                गई जानकारी के कारण उत्पन्न होने वाली किसी भी अशुद्धि के लिए हम
                उत्तरदायी नहीं होंगे। अगर हमें लगता है कि आपने इस अनुबंध या
                गोपनीयता नीति के तहत उल्लिखित किसी भी शर्त का उल्लंघन किया है,
                तो बिना किसी चेतावनी के या बिना किसी नोटिस के 24 (चौबीस) घंटे
                पहले सूचित करके आपकी प्रोफ़ाइल को हमारे द्वारा हटाई जा सकती है।
              </p>
            </li>
            <li>
              <p>
                पंजीकरण प्रक्रिया को पूरा करने और उत्पाद और सेवाओं का उपयोग करने
                का अधिकार पाने के लिए आपको समय-समय पर अपना पूरा कानूनी नाम, फ़ोन
                नंबर, वैध ईमेल पता और आपसे अनुरोध की गई कोई अन्य जानकारी प्रदान
                करनी होगी। आपके द्वारा प्रदान की गई जानकारी हमारे द्वारा उत्पाद
                और सेवाओं, रिकॉर्ड रखने के प्रयोजन, आंतरिक कार्यविधियों या किसी
                अन्य उद्देश्यों को प्रदान करने के लिए किसी तृतीय पक्ष के साथ
                साझा की जा सकती है और इस प्लेटफ़ॉर्म का उपयोग करके आप अपने
                द्वारा प्रदान की गई जानकारी को साझा करने के लिए स्पष्ट रूप से
                सहमति देते हैं।
              </p>
            </li>
            <li>
              <p>
                पंजीकरण की प्रक्रिया पूरी करने पर, आपको हमारी सुरक्षा
                प्रक्रियाओं के भाग के रूप में एक उपयोगकर्ता पहचान कोड, पासवर्ड
                या कोई अन्य जानकारी प्रदान की जाएगी। ऐसी किसी भी तरह की जानकारी
                के साथ आपको गोपनीय रूप व्यवहार करना चाहिए और आपको किसी तृतीय
                पक्ष के समक्ष इसे प्रकट नहीं करना चाहिए। आप यह सुनिश्चित करने के
                लिए भी सहमति देते हैं कि प्रत्येक सत्र के अंत में आप अपने खाते
                से निकास/लॉग आउट करते हैं। हमारे पास किसी भी उपयोगकर्ता पहचान
                कोड या पासवर्ड को किसी भी समय अक्षम करने का अधिकार है, चाहे आपके
                द्वारा चुना गया हो या हमारे द्वारा आवंटित किया गया हो, अगर हमारे
                मतानुसार आप इस अनुबंध के किसी भी प्रावधान का पालन करने में विफल
                हो जाते हैं। आप अपने पासवर्ड या उपयोगकर्ता पहचान कोड के किसी भी
                अनधिकृत उपयोग के बारे में COVESTRO को तुरंत सूचित करेंगे, ऐसे
                अनधिकृत उपयोग की सूचना आपको{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                पर भेजनी होगी।
              </p>
            </li>
            <li>
              <p>
                प्लेटफ़ॉर्म पर पंजीकरण की प्रक्रिया पूरी होने पर, आप वह सदस्य
                होंगे जो:
              </p>
              <ol>
                <li>
                  <p>
                    आपको प्लेटफ़ॉर्म पर बिक्री या खरीदारी के आमंत्रण के रूप में
                    उत्पादों को एक्सेस करने और पोस्ट करने की अनुमति देता है;
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म के उपयोग की शर्तों में बदलाव या नई सेवाओं का
                    वर्णन करने के लिए, यदि आवश्यक हो, तो हमें आपसे संपर्क करने
                    की अनुमति देता है, जिसे हम प्लेटफ़ॉर्म पर सक्षम करते हैं;
                  </p>
                </li>
                <li>
                  <p>
                    आपको प्लेटफ़ॉर्म पर मौजूद उत्पाद और सेवाओं की जानकारी को
                    एक्सेस और एकत्रित करने की अनुमति देता है, और उसके बाद यहां
                    निर्धारित नियम और शर्तों के अधीन ऐसी जानकारी के प्रवर्तक से
                    संपर्क करता है;
                  </p>
                </li>
                <li>
                  <p>
                    आपको हमारे द्वारा विशेष रूप से अधिकृत किए गए प्रयोजन के
                    अलावा किसी अन्य प्रयोजन के लिए प्लेटफ़ॉर्म पर प्रदर्शित ईमेल
                    पतों का उपयोग करने की अनुमति नहीं देता है।
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                COVESTRO को अपना ईमेल पता/फ़ोन नंबर प्रदान करके आप इसके लिए सहमति
                देते हैं:
              </p>
              <ol>
                <li>
                  <p>
                    डाक मेल द्वारा संप्रेषण के बदले, कानून द्वारा आवश्यक किसी भी
                    नोटिस सहित आपको सेवा संबंधी नोटिस भेजने के लिए हम आपके ईमेल
                    पते या मोबाइल नंबर का उपयोग कर रहे हैं। आप ईमेल/मोबाइल नंबर
                    या पूरी तरह से सेवा से संबंधित संप्रेषण से बाहर निकलने के
                    लिए अपनी सेटिंग का उपयोग कर सकते हैं।
                  </p>
                </li>
                <li>
                  <p>
                    हम प्लेटफ़ॉर्म पर आपकी प्रगति देखने और हमारी सेवाओं के उपयोग
                    से संबंधित अपडेट और सलाह देने के लिए, और किसी भी नए प्रचार
                    और बिक्री के अवसर के लिए, आपको समय-समय पर संपर्क करने के
                    हेतु आपके द्वारा प्रदान किए गए प्लेटफ़ॉर्म फ़ोन नंबर का
                    उपयोग करते हैं।
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म पर आपके द्वारा दिए गए ऑर्डर को पूरा करने के लिए
                    आपके ईमेल पते, घर का पता, फ़ोन नंबर और अन्य सहायक जानकारी का
                    उपयोग करना;
                  </p>
                </li>
                <li>
                  <p>
                    हमारे उत्पाद और सेवाओं की विशेषता और खास ऑफ़र सहित, हम आपको
                    अन्य संदेश भेजने के लिए आपके ईमेल पते या फ़ोन नंबर का उपयोग
                    करते हैं। यदि आप इस तरह के ईमेल संदेश प्राप्त नहीं करना
                    चाहते हैं, तो आप हमें{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    पर ईमेल भेजकर इससे बाहर निकलने का चयन कर सकते हैं। ऑप्ट आउट
                    करके आप अपडेट, सुधार या ऑफ़र से संबंधित ईमेल संदेश या फ़ोन
                    संदेश प्राप्त करना प्रतिबंधित कर सकते हैं।
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>प्लेटफ़ॉर्म के इस्तेमाल की शर्तें</h3>
          <ol>
            <li>
              <p>
                आप समय-समय पर तृतीय पक्ष द्वारा सूचीबद्ध उत्पाद और सेवाओं की
                बिक्री, खरीद और उपयोग के लिए आमंत्रण संबंधी जानकारी को एक्सेस
                करने के लिए प्लेटफ़ॉर्म का उपयोग कर सकते हैं।
              </p>
            </li>
            <li>
              <p>
                प्लेटफ़ॉर्म के संदर्भ में COVESTRO की जिम्मेदारी पूरी तरह से और
                विशेष रूप से विस्तारित होगी:
              </p>
              <ol>
                <li>
                  <p>प्लेटफ़ॉर्म पर प्लेटफ़ॉर्म का प्रावधान;</p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म पर पंजीकृत उपयोगकर्ताओं के लिए उत्पादों और
                    सेवाओं की पहुंच और उपलब्धता सुनिश्चित करने के लिए बाज़ारस्थल
                    के रूप में कार्य करना।
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                इस प्रकार से यह स्पष्ट किया जाता है कि प्लेटफ़ॉर्म पर सूचीबद्ध
                उत्पाद और सेवाएं तृतीय पक्ष के विक्रेताओं के स्वामित्व और बिक्री
                वाले उत्पाद और सेवाएं हैं। तृतीय पक्ष के विक्रेता के उत्पाद और
                सेवाएं किसी भी तरह से COVESTRO से संबंधित नहीं हैं। इस प्रकार
                से, COVESTRO तृतीय पक्ष के विक्रेताओं के उत्पाद और सेवाओं की
                गुणवत्ता, मात्रा या प्रामाणिकता के लिए किसी भी तरह की कोई वारंटी
                नहीं देता है और न ही किसी भी तरह से इसके लिए जिम्मेदार होगा।
              </p>
            </li>
            <li>
              <p>
                एक बार ऑर्डर या पंजीकरण किए जाने और प्लेटफ़ॉर्म पर प्रोसेस होने
                के बाद, यहां निर्दिष्ट के अनुसार कोई रीफ़ंड या रद्दकरण नहीं होगा,
                न ही इसे प्रारंभ किया जाएगा न ही इसपर किसी तरह का विचार किया
                जाएगा।
              </p>
            </li>
            <li>
              <p>
                उपयोग के इन नियम और शर्तों के अधीन, COVESTRO आपको एक
                गैर-हस्तांतरणीय, गैर-अनन्य, गैर-उप-लाइसेंस योग्य सीमित अधिकार और
                लाइसेंस प्रदान करता है जिससे कि आप केवल अपने एकमात्र लाभ के लिए
                प्लेटफ़ॉर्म को एक्सेस और इसका उपयोग कर सकें।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>सेवाओं के उपयोग पर प्रतिबंध</h3>
          <ol>
            <li>
              <p>
                आपको निम्न में से कोई भी कार्य करने की अनुमति नहीं है और न ही आप
                ये कार्य करेंगे, जिसके उल्लंघन से COVESTRO को आपके उत्पाद और
                सेवाओं के उपयोग को समाप्त करने का अधिकार होगा, किसी भी अन्य
                अधिकार या उपचारों पर प्रतिकूल प्रभाव डाले बिना जो हमारे आपके
                विरुद्ध हो सकते हैं:
              </p>
              <ol>
                <li>
                  <p>
                    उत्पाद और सेवाओं के हिस्से के रूप में प्लेटफ़ॉर्म और आपको
                    प्रदान की गई सभी सामग्री का वितरण, बिक्री, आपूर्ति, संशोधन,
                    अनुकूलन, संशोधन, समावेश, विलय या अन्यथा परिवर्तन;
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म या वेबसाइट या ऐप के हिस्से के रूप में आपको
                    प्रदान की गई किसी भी सेवा या सामग्री को डिकंपाइल, रिवर्स
                    इंजीनियर या अन्यथा अलग करने का प्रयास;
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म या वेबसाइट या ऐप और विशेष रूप से इसके स्रोत कोड
                    के हिस्से के रूप में आपको प्रदान किए गए किसी भी सॉफ़्टवेयर
                    को कॉपी करने का प्रयास करें, या आपको प्रदान किए गए ऐसे
                    सॉफ़्टवेयर के किसी भी हिस्से को डिक्रिप्ट करने का प्रयास
                    करें;
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म या उत्पाद और सेवाओं के हिस्से के रूप में प्रदान
                    की गई किसी भी सामग्री के संबंध में या आपके उपयोग को
                    सुविधाजनक बनाने के लिए हमारे द्वारा प्रदान किए गए किसी भी
                    सॉफ़्टवेयर का व्युत्पन्न कार्य या संस्करण बनाएं;
                  </p>
                </li>
                <li>
                  <p>
                    किसी छद्मनाम वापसी ईमेल पते के रूप में किसी भी COVESTRO के
                    डोमेन नाम का उपयोग करें;
                  </p>
                </li>
                <li>
                  <p>
                    ऐसी किसी भी सामग्री को एक्सेस करना या एक्सेस करने का प्रयास
                    करना जिसे आप किसी भी तरह से एक्सेस करने के लिए अधिकृत नहीं
                    हैं;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पाद और सेवाओं या उत्पाद और सेवाओं के हिस्से के रूप में
                    प्रदान की गई किसी भी सामग्री से, किसी भी लोगो, कॉपीराइट या
                    मालिकाना नोटिस, लीजेंड, प्रतीक, लेबल, वॉटरमार्क, हस्ताक्षर
                    या उत्पाद और सेवाओं में जोड़े गए या या एम्बेड किए गए किसी भी
                    तरह के चिह्न को हटा दें;
                  </p>
                </li>
                <li>
                  <p>
                    झूठी पहचान बनाने के लिए, किसी व्यक्ति या संगठन का प्रतिरूपण
                    करने के लिए, या किसी सामग्री के मूल को छिपाने का प्रयास करने
                    के लिए उत्पाद और सेवाओं या उसके किसी भी भाग का उपयोग करें;
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म को किसी भी तरीके से एक्सेस या उपयोग करना जो
                    प्लेटफ़ॉर्म के किसी भी सर्वर या किसी भी सर्वर से जुड़े
                    नेटवर्क को नुकसान पहुंचा सकता है, निष्क्रिय कर सकता है,
                    अत्यधिक भार डाल सकता है या ख़राब कर सकता है, जिस पर
                    प्लेटफ़ॉर्म होस्ट किया गया है;
                  </p>
                </li>
                <li>
                  <p>
                    हमारे द्वारा प्रदान किए गए इंटरफ़ेस के अलावा किसी अन्य
                    माध्यम से प्लेटफ़ॉर्म एक्सेस करें;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पादों और सेवाओं के किसी भी हिस्से को बदलना या संशोधित
                    करना; और
                  </p>
                </li>
                <li>
                  <p>
                    प्लेटफ़ॉर्म, सामग्री, सिस्टम संसाधनों की सुरक्षा को बाधित
                    करना या हस्तक्षेप करना, या अन्यथा नुकसान पहुंचाना या
                    उपयोगकर्ता खाता, पासवर्ड, सर्वर या नेटवर्क से अनधिकृत एक्सेस
                    प्राप्त करना या प्लेटफ़ॉर्म या किसी संबद्ध लिंक साइट के
                    माध्यम से एक्सेस करना।
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>सामग्री</h3>
          <ol>
            <li>
              <p>
                जब भी आप उत्पाद और सेवाओं (या उत्पाद और सेवाओं की कोई सुविधा या
                भाग) का उपयोग करते हैं जो आपको प्लेटफ़ॉर्म पर सामग्री अपलोड करने
                की अनुमति देता है, या प्लेटफ़ॉर्म पर हमारे प्लेटफ़ॉर्म के अन्य
                उपयोगकर्ताओं या विक्रेताओं से संपर्क करने की अनुमति देता है, तो
                आपको इसका पालन करना चाहिए। सामग्री मानक किसी योगदान के प्रत्येक
                भाग के साथ-साथ उसके संपूर्ण भाग पर भी लागू होते हैं। आपका योगदान
                सटीक होना चाहिए जहां यह तथ्य बताता है, इसे वास्तविक स्वरूप में
                होना चाहिए (जहां यह राय प्रकट करता है), जिस किसी देश में इसे
                पोस्ट किया गया है वहां के कानून के अनुसार इसके द्वारा अनुपालन
                होना चाहिए; और आपकी पोस्टिंग या उत्पादो और सेवाओं के उपयोग के
                लिए यह प्रासंगिक हो।
              </p>
            </li>
            <li>
              <p>
                उत्पाद और सेवाओं का उपयोग करने वाली खोज परिणाम और साइट के लिए
                लिंक प्रदान कर सकती हैं, और ऐसे सामग्री प्रदान कर सकती जो जो
                आपको आपत्तिजनक, अनुचित या आपत्तिजनक लग सकती है और इस तरह की किसी
                भी तरह की सामग्री के लिए हम किसी भी तरह की ज़िम्मेदारी की देयता
                को स्वीकार नहीं करते हैं। यह भी संभव है कि उत्पाद और सेवाओं का
                आपका उपयोग आपको उत्पाद और सेवा, व्यापारियों और आपके द्वारा
                अनुरोधित उत्पाद जानकारी बेचने वाले तृतीय पक्ष की वेबसाइटों के
                लिंक के बारे में जानकारी लौटाएगा। COVESTRO इससे जुड़ी किसी भी और
                सभी जिम्मेदारी और दायित्व को अस्वीकार करता है।
              </p>
            </li>
            <li>
              <p>
                आप स्वीकार करते हैं और सहमति देते हैं कि COVESTRO ऐसे किसी भी
                तरह की हानि या क्षति के लिए ज़िम्मेदार नहीं है, जो ऐसी वेबसाइटों
                या संसाधनों से, आपके द्वारा ऐसी बाहरी साइटों या संसाधनों की
                उपलब्धता के परिणामस्वरूप, या किसी भी विज्ञापन, उत्पादों और
                सेवाओं या अन्य सामग्रियों की पूर्णता, किसी भी विज्ञापन की सटीकता
                या मौजूदगी या किसी भी सामग्री पर, या इससे उपलब्ध किसी भी
                निर्भरता के परिणामस्वरूप हो सकता है, जिसके लिए आप उत्तरदायी हो
                सकते हैं।
              </p>
            </li>
            <li>
              <p>
                आप अपनी सामग्री, दस्तावेज़ों और अन्य डेटा की निगरानी के लिए
                स्वयं ज़िम्मेदार होंगे और यह सुनिश्चित करने के लिए हमारे प्रति
                उत्तरदायी होंगे कि सेवाओं द्वारा या उसके भीतर स्थानांतरित या
                प्रबंधित की गई सामग्री किसी तृतीय पक्ष के अधिकारों का उल्लंघन
                नहीं करती है। आप आश्वासन देते हैं कि आपके पास ऐसे आवश्यक लाइसेंस
                हैं जो आपकी सामग्री को संसाधित करने या उत्पाद और सेवाओं का उपयोग
                करने के लिए आवश्यक हो सकते हैं।
              </p>
            </li>
            <li>
              <p>
                आप समझते हैं कि आपकी सामग्री सहित उत्पाद और सेवाओं के तकनीकी
                प्रोसेसिंग और प्रसारण को अनएन्क्रिप्टेड स्थानांतरित किया जा सकता
                है और इसमें शामिल हैं: (अ) विभिन्न नेटवर्क पर प्रसारण; और (ब)
                नेटवर्क या उपकरणों को जोड़ने की तकनीकी आवश्यकताओं के अनुरूप और
                अनुकूल होने के लिए परिवर्तन।
              </p>
            </li>
            <li>
              <p>
                इस प्रकार से आप स्वीकार करते हैं और समझते हैं कि प्लेटफ़ॉर्म पर
                उत्पाद और सेवाओं को बेचने के भाग के रूप में, विभिन्न विक्रेता
                चित्र, विवरण और अन्य सामग्री पोस्ट कर सकते हैं। COVESTRO के रूप
                में हम बाजारस्थल सेवाएं प्रदान करने में मदद करने के लिए केवल एक
                प्लेटफ़ॉर्म हैं, और उक्त तृतीय पक्ष विक्रेताओं द्वारा पोस्ट की
                गई ऐसी सामग्री के संबंध में हमारा कोई उत्तरदायित्व नहीं होगा,
                ऐसी सामग्री की प्रामाणिकता या विश्वसनीयता के संदर्भ में, जो किसी
                भी तरह से किसी भी तरीके से शामिल की गई हो, यह केवल इसतक सीमित
                नहीं है।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ऐप का सीमित लाइसेंस</h3>
          <ol>
            <li>
              <p>
                हम आपको इस अनुबंध की शर्तों के अनुसार व्यक्तिगत और गैर-वाणिज्यिक
                उद्देश्यों के लिए प्लेटफ़ॉर्म को एक्सेस और उपयोग करने के लिए एक
                व्यक्तिगत, विश्वनीय, खंडनीय, गैर-हस्तांतरणीय और गैर-विशिष्ट
                लाइसेंस प्रदान करते हैं।
              </p>
            </li>
            <li>
              <p>
                इस अनुबंध में, सभी अधिकार, शीर्षक और हित और प्लेटफार्म पर जिसकी
                स्पष्ट रूप से अनुमति नहीं है, COVESTRO द्वारा आरक्षित हैं। यदि
                आप COVESTRO के सॉफ़्टवेयर, शीर्षक, व्यापार नाम, ट्रेडमार्क, सेवा
                चिह्न, लोगो, डोमेन नाम और/या उल्लेखनीय ब्रैंड विशेषताओं या
                COVESTRO के स्वामित्व वाली अन्य सामग्री के साथ किसी अन्य पहचान
                का उपयोग करना चाहते हैं, तो आपको COVESTRO से लिखित अनुमति लेनी
                होगी। अनुमति अनुरोध{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                पर भेजे जा सकते हैं।
              </p>
            </li>
            <li>
              <p>
                किसी भी संदेह से बचने के लिए, COVESTRO सभी टेक्स्ट, छवियों,
                फ़ोटो, ऑडियो, वीडियो, स्थान डेटा और डेटा या संप्रेषण के अन्य सभी
                रूपों का स्वामी है, जो COVESTRO प्लेटफ़ॉर्म के संबंध में बनाता
                है और उपलब्ध करवाता है, इसमें विजुअल इंटरफ़ेस, इंटरैक्टिव फ़ीचर्स,
                ग्राफिक्स, डिजाइन, उपयोगकर्ता सामग्री का संकलन, और एकीकृत
                उपयोगकर्ता समीक्षा रेटिंग संकलन और प्लेटफ़ॉर्म पर मौजूद अन्य सभी
                तत्व और घटक शामिल हैं, उपयोगकर्ता सामग्री (जैसा कि ऊपर परिभाषित
                किया गया है) के सिवाय, लेकिन यह केवल इसतक सीमित नहीं है। यहां
                स्पष्ट रूप से और सुस्पष्ट तरीके प्रदान किए गए को छोड़कर, हम आपको
                कोई स्पष्ट या निहित अधिकार प्रदान नहीं करते हैं, और प्लेटफ़ॉर्म
                और COVESTRO की सामग्री के सभी अधिकार हमारे पास सुरक्षित हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>सामग्री मानकों का उल्लंघन</h3>
          <ol>
            <li>
              <p>
                जब हम मानते हैं कि सामग्री मानकों (जैसा कि ऊपर वर्णित है) का
                उल्लंघन हुआ है, तो हम अपने विवेकानुसार ऐसी कार्रवाई कर सकते हैं
                जो हमें उचित लगती है। अनुपालन करने में विफलता उपयोग की शर्तों का
                सामग्री भंग है, जिस पर आपको सेवा का उपयोग करने की अनुमति है, और
                हम कानूनी रूप से उपलब्ध कोई भी कार्रवाई कर सकते हैं, जिसमें
                निम्नलिखित सभी या कोई भी कार्रवाई शामिल है:
              </p>
              <ol>
                <li>
                  <p>
                    उत्पाद और सेवाओं का उपयोग करने के आपके अधिकार की तत्काल,
                    अस्थायी या स्थायी वापसी और प्लेटफ़ॉर्म पर आपके खाते की एक
                    साथ समाप्ति/निलंबन;
                  </p>
                </li>
                <li>
                  <p>
                    आपके ध्यान में लाए गए उल्लंघन को ठीक करने में आपके विफल होने
                    पर, उत्पाद और सेवाओं का उपयोग करके प्लेटफ़ॉर्म पर पहले से
                    पोस्ट किए गए किसी भी योगदान को तत्काल, अस्थायी या स्थायी रूप
                    से हटाना;
                  </p>
                </li>
                <li>
                  <p>आपको चेतावनी जारी करना;</p>
                </li>
                <li>
                  <p>आपके खिलाफ आगे की कानूनी कार्रवाई; </p>
                </li>
                <li>
                  <p>
                    उल्लंघन के परिणामस्वरूप क्षतिपूर्ति के आधार पर सभी लागत की
                    प्रतिपूर्ति के लिए आपके खिलाफ कानूनी कार्रवाई (उचित
                    प्रशासनिक और कानूनी लागत सहित, लेकिन इसतक ही सीमित नहीं है);
                    और
                  </p>
                </li>
                <li>
                  <p>
                    कानून प्रवर्तन अधिकारियों को इस तरह की जानकारी का खुलासा
                    जैसा कि हम यथोचित रूप से आवश्यक महसूस करते हैं या कानून
                    द्वारा आवश्यक है।
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                हम इस प्रकार इन नियमों के उल्लंघनों के जवाब में हमारे द्वारा की
                जाने वाली सभी कार्रवाइयों के संबंध में हम अपने उत्तरदायित्व को
                अस्वीकार करते हैं और इसे वर्जित रखते हैं। ऊपर वर्णित कार्रवाइयां
                इसतक सीमित नहीं हैं, और हम कोई अन्य कार्रवाई कर सकते हैं जिसे हम
                यथोचित रूप से उचित समझते हैं।
              </p>
            </li>
            <li>
              <p>
                आपके खाते की इस तरह समाप्ति/निलंबन पर, आप अपने खाते या सर्वर पर
                संग्रहित किसी भी सामग्री या डेटा को एक्सेस नहीं कर पाएंगे।
                COVESTRO द्वारा प्लेटफ़ॉर्म या उत्पाद और सेवाओं के लिए आपको दिए
                गए सभी लाइसेंस स्वतः समाप्त हो जाएंगे।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>बिलिंग और भुगतान</h3>
          <ol>
            <li>
              <p>
                प्लेटफ़ॉर्म की कुछ स्थितियों को फ़ीस या अन्य शुल्क के लिए प्रदान
                किया जा सकता है। यदि आप प्लेटफ़ॉर्म के भुगतान किए गए पहलुओं का
                उपयोग करना चुनते हैं, तो आप ऐसे फ़ीस और शुल्क पर लागू बिक्री,
                मूल्य निर्धारण, भुगतान और बिलिंग नीतियों की शर्तों को सहमति देते
                हैं। COVESTRO अपने पूर्ण विवेक से किसी भी समय अतिरिक्त फ़ीस और
                शुल्क के लिए नई सेवाएं जोड़ सकता है, या मौजूदा सेवाओं के लिए फ़ीस
                और शुल्क में संशोधन कर सकता है।
              </p>
            </li>
            <li>
              <p>
                किसी भी संपर्क या बिलिंग जानकारी में बदलाव या अपडेट (फ़ोन नंबर,
                ईमेल पता, क्रेडिट कार्ड नंबर आदि सहित) COVESTRO को तुरंत उपलब्ध
                कराना आपकी जिम्मेदारी है। COVESTRO भुगतान सुरक्षित करने के लिए
                ग्राहक के भुगतान प्रदाता द्वारा आवश्यक क्रेडिट कार्ड की सभी
                जानकारी को मान्य नहीं करता है।
              </p>
            </li>
            <li>
              <p>
                प्लेटफ़ॉर्म पर की गई खरीदारी के संबंध में, आप इस प्रकार स्वीकार
                करते हैं और सहमति देते हैं कि किसी भी तरीके से धनवापसी के
                अनुरोधों पर विचार नहीं किया जाएगा और इसके लिए कोई रीफ़ंड शुरू
                नहीं किया जाएगा।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>क्षतिपूर्ति और देयता की सीमा</h3>
          <ol>
            <li>
              <p>
                आप COVESTRO, इसके अधिकारियों, निदेशकों, कर्मचारियों और एजेंटों
                को इनसे उत्पन्न होने वाले किसी भी और सभी दावे, क्षति, दायित्व,
                नुकसान, देनदारी, लागत या ऋण और व्यय (इसके सहित लेकिन इसतक सीमित
                नहीं है) के लिए उनकी रक्षा करने, क्षतिपूर्ति करने और हानिरहित
                रखने की सहमति देते हैं: (i) उत्पाद और सेवाओं और प्लेटफ़ॉर्म का
                आपके द्वारा उपयोग और एक्सेस; (ii) आपके द्वारा इन शर्तों की किसी
                भी शर्त का उल्लंघन; (iii) किसी तृतीय पक्ष के अधिकार का आपका
                उल्लंघन, कोई कॉपीराइट, संपत्ति या गोपनीयता अधिकार शामिल है, केवल
                इसतक सीमित नहीं है; या (iv) कोई भी दावा कि आपकी उपयोगकर्ता
                सामग्री के कारण किसी तृतीय पक्ष को नुकसान हुआ है। यह सुरक्षा और
                क्षतिपूर्ति दायित्व इस अनुबंध और आपके द्वारा उत्पाद और सेवाओं और
                प्लेटफ़ॉर्म के उपयोग को बनाए रखेंगे।
              </p>
            </li>
            <li>
              <p>
                हमारी वेबसाइट या ऐप पर प्रदर्शित सामग्री इसकी सटीकता के लिए बिना
                किसी गारंटी, शर्तों या वारंटी के प्रदान की जाती है। कानून द्वारा
                अनुमत सीमा तक, हम, हमारी अनुषंगी और सहयोगी कंपनियां और हमसे अपने
                से जुड़े तृतीय पक्ष स्पष्ट रूप से वर्जित करते हैं:
              </p>
              <ol>
                <li>
                  <p>
                    शर्तें, वारंटी और अन्य शर्तें, जो अन्यथा कानून, सामान्य
                    कानून या इक्विटी के कानून द्वारा निहित हो सकती हैं;
                  </p>
                </li>
                <li>
                  <p>
                    उत्पाद और सेवाओं,हमारे प्लेटफ़ॉर्म के संबंध में या उत्पाद और
                    सेवाओं या प्लेटफ़ॉर्म के उपयोग, उपयोग करने में असमर्थता, या
                    उपयोग के परिणामों के संबंध में किसी भी उपयोगकर्ता द्वारा किए
                    गए किसी भी प्रत्यक्ष, अप्रत्यक्ष या परिणामी नुकसान या क्षति
                    के लिए कोई भी उत्तरदायित्व , इससे जुड़ी कोई भी वेबसाइट और उस
                    पर पोस्ट की गई कोई भी सामग्री शामिल हैं, लेकिन इसतक सीमित
                    नहीं है:
                  </p>
                  <ol>
                    <li>
                      <p>आय या राजस्व की हानि;</p>
                    </li>
                    <li>
                      <p>व्यवसाय का नुकसान;</p>
                    </li>
                    <li>
                      <p>लाभ या अनुबंधों की हानि;</p>
                    </li>
                    <li>
                      <p>अनुमानित बचत का नुकसान;</p>
                    </li>
                    <li>
                      <p>डेटा की हानि;</p>
                    </li>
                    <li>
                      <p>विश्वसनीयता की हानि;</p>
                    </li>
                    <li>
                      <p>अपशिष्ट प्रबंधन या कार्यालय समय; और</p>
                    </li>
                    <li>
                      <p>
                        किसी भी अन्य नुकसान या किसी भी प्रकार की क्षति के लिए,
                        चाहे अपकार से उत्पन्न होने वाली और इसके कारण (लापरवाही
                        सहित), अनुबंध के उल्लंघन या अन्यथा, भले ही पूर्वाभासी हो
                        या इसी की संभावना की सलाह दी गई हो।
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>
                कोई भी पक्ष अनुबंध, अपकार (सहित लेकिन इसतक सीमित नहीं, लापरवाही)
                या इससे उत्पन्न होने वाले या इसके लिए इन शर्तों के संबंध में,
                किसी भी तरह उत्तरदायी नहीं होगा;
                <br />
                अ) कोई भी आर्थिक नुकसान (बिना किसी सीमा के, राजस्व की हानि, लाभ,
                अनुबंध, डेटा, व्यवसाय, अनुमानित बचत या स्थानापन्न सेवाओं की लागत
                सहित);
                <br /> ब) विश्वसनीयता या प्रतिष्ठा का कोई नुकसान;
                <br /> स) कोई विशेष, अप्रत्यक्ष या परिणामी नुकसान; चाहे या नहीं,
                इस तरह के नुकसान इन शर्तों की तारीख में पक्षों के विचार थे।
              </p>
            </li>
            <li>
              <p>
                आप सहमति देते हैं कि COVESTRO और इसकी पूर्ण स्वामित्व वाली सहायक
                कंपनियां आपके प्रयोग से ग्राहक डेटा या डेटा के अनधिकृत उपयोग या
                परिवर्तन के संबंध में जिम्मेदार नहीं होंगी या कोई उत्तरदायित्व
                नहीं होगा।
              </p>
            </li>
            <li>
              <p>
                उत्पाद और सेवाओं में किसी भी त्रुटि, दोष, विफलता या देरी के लिए
                COVESTRO की कोई जिम्मेदारी नहीं होगी। पूर्वगामी की व्यापकता के
                प्रति पूर्वाग्रह के बिना, COVESTRO उत्पाद और सेवाओं में किसी भी
                विफलता या देरी के लिए कोई दायित्व नहीं होगा: कोई विशेष,
                अप्रत्यक्ष या परिणामी नुकसान; चाहे या नहीं, इस तरह के नुकसान इन
                शर्तों की तारीख में पक्षों के विचार थे।
                <br />
                अ) किसी भी सार्वजनिक इंटरनेट बैकबोन, नेटवर्क या सर्वर के रुकने
                के कारण; कोई विशेष, अप्रत्यक्ष या परिणामी नुकसान; इस तरह के
                नुकसान इस शर्तों की तारीख के अंतर्गत पक्षों के विचार थे या नहीं।
                <br />
                ब) आपके उपकरण, सिस्टम या स्थानीय एक्सेस सेवाओं की किसी भी विफलता
                के कारण या
                <br />
                स) पूर्व निर्धारित रखरखाव के कारण।
              </p>
            </li>
            <li>
              <p>
                COVESTRO की (और इसकी पूर्ण स्वामित्व वाली अनुषंगी कंपनियों की)
                कुल संचयी देयता (चाहे अनुबंध, अपकार में, (लापरवाही सहित) या
                अन्यथा) इन शर्तों के तहत या आपके द्वारा भुगतान की गई सदस्यता फ़ीस
                के 50 प्रतिशत से अधिक नहीं होगी।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>अपराध</h3>
          <ol>
            <li>
              <p>
                आपको जानबूझकर वायरस, ट्रोजन, वर्म्स, लॉजिक बम, टाइम बम, कैंसल
                बॉट्स, स्पाइवेयर या अन्य सामग्री, कंप्यूटर प्रोग्रामिंग रूटीन,
                कोड, फाइल या ऐसे अन्य प्रोग्राम जो दुर्भावनापूर्ण या तकनीकी रूप
                से हानिकारक हैं, या अन्य उपयोगकर्ताओं के अधिकारों के हितों को
                सीमित करते हैं या किसी कंप्यूटर सॉफ़्टवेयर, हार्डवेयर या
                दूरसंचार की कार्यक्षमता को सीमित करते हैं, का दुरूपयोग नहीं करना
                चाहिए। आपको प्लेटफ़ॉर्म, जिस सर्वर पर प्लेटफ़ॉर्म संग्रहित है या
                हमारी साइट से जुड़े किसी भी सर्वर, कंप्यूटर या डेटाबेस तक
                अनधिकृत एक्सेस प्राप्त करने का प्रयास नहीं करना चाहिए। आपको
                डिनायल-ऑफ-सर्विस अटैक या डिस्ट्रीब्यूटेड डिनायल-ऑफ सर्विस अटैक
                के जरिए प्लेटफ़ॉर्म पर हमला नहीं करना चाहिए।
              </p>
            </li>
            <li>
              <p>
                इस प्रावधान का उल्लंघन करके, आप सूचना प्रौद्योगिकी अधिनियम, 2000
                (और किसी भी संशोधन) के तहत एक आपराधिक अपराध करेंगे। हम संबंधित
                कानून प्रवर्तन अधिकारियों को ऐसे किसी भी उल्लंघन की रिपोर्ट
                करेंगे और हम उन अधिकारियों के साथ आपकी पहचान का खुलासा करके उनका
                सहयोग करेंगे। इस तरह के उल्लंघन की स्थिति में, प्लेटफ़ॉर्म का
                उपयोग करने का आपका अधिकार तत्काल समाप्त हो जाएगा।
              </p>
            </li>
            <li>
              <p>
                डिस्ट्रीब्यूटेड डिनायल-ऑफ़-सर्विस अटैक, वायरस या अन्य तकनीकी रूप
                से हानिकारक सामग्री के कारण होने वाले किसी भी नुकसान या क्षति के
                लिए हम उत्तरदायी नहीं होंगे, जो आपके प्लेटफ़ॉर्म के उपयोग के
                कारण आपके कंप्यूटर उपकरण, कंप्यूटर प्रोग्राम, डेटा या अन्य
                मालिकाना सामग्री या आपके द्वारा इस पर पोस्ट की गई किसी भी
                सामग्री को डाउनलोड करने, या इससे किसी भी प्लेटफ़ॉर्म को लिंक
                करने से इसे खराब कर सकते हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>मोबाइल ऐप के लिए अतिरिक्त शर्तें</h3>
          <ol>
            <li>
              <p>
                तृतीय पक्ष सॉफ़्टवेयर या वेबसाइट के माध्यम से आपको या अन्य
                उपयोगकर्ताओं को वेबसाइट या ऐप और/या उत्पादों और सेवाओं को एक्सेस
                करने में सक्षम बनाने के उद्देश्य से, मोबाइल ऐप, डेस्कटॉप ऐप,
                उत्पाद और सेवाओं और डेटा एक्सेस करने के लिए आपको सीमित,
                गैर-उप-लाइसेंस योग्य अधिकार प्रदान किया जाता है। मोबाइल ऐप,
                डेस्कटॉप ऐप का कोई भी उपयोग इस अनुबंध की शर्तों और निम्नलिखित
                विशिष्ट शर्तों से बाध्य है:
              </p>
              <ol>
                <li>
                  <p>
                    आप सहमत देते हैं कि आप किसी भी तृतीय पक्ष सॉफ़्टवेयर के
                    माध्यम से प्रदान की जाने वाली किसी भी सेवा और/या उत्पाद और
                    सेवाओं के लिए पूरी तरह से जिम्मेदार हैं (और यह कि आपके या
                    किसी तृतीय पक्ष के प्रति हमारी कोई ज़िम्मेदारी या दायित्व
                    नहीं है);
                  </p>
                </li>
                <li>
                  <p>
                    आप सॉफ़्टवेयर बनाने के लिए ऐसे मोबाइल ऐप या डेस्कटॉप ऐप का
                    उपयोग नहीं करेंगे जो किसी तृतीय पक्ष को अवांछित संप्रेषण
                    (चाहे वाणिज्यिक या अन्य) भेजता है;
                  </p>
                </li>
                <li>
                  <p>
                    हम किसी भी समय 48 (अड़तालीस) घंटे की सूचना देकर मोबाइल ऐप और
                    डेस्कटॉप ऐप तक आपके एक्सेस को अस्थायी या स्थायी रूप से
                    संशोधित या बंद करने का अधिकार सुरक्षित रखते हैं;
                  </p>
                </li>
                <li>
                  <p>
                    आप स्पष्ट रूप से समझते हैं और सहमत देते हैं कि हम किसी भी
                    प्रत्यक्ष, अप्रत्यक्ष, आकस्मिक, विशेष, परिणामी या अनुकरणीय
                    क्षतियों के लिए उत्तरदायी नहीं होंगे, जिनमें मोबाइल ऐप और
                    डेस्कटॉप ऐप के आपके उपयोग के परिणामस्वरूप लाभ, विश्वसनीयता ,
                    उपयोग, डेटा या अन्य अमूर्त हानि (यहां तक कि अगर हमें इस तरह
                    के नुकसान की संभावना के बारे में सूचित किया गया है) शामिल
                    हैं, लेकिन इसतक सीमित नहीं है;
                  </p>
                </li>
                <li>
                  <p>
                    हम किसी भी समय 48 (अड़तालीस) घंटे की सूचना देकर या सूचना दिए
                    बिना, मोबाइल ऐप और डेस्कटॉप ऐप तक आपके एक्सेस को अस्थायी या
                    स्थायी रूप से संशोधित या बंद करने का अधिकार सुरक्षित रखते
                    हैं;
                  </p>
                </li>
                <li>
                  <p>
                    डेस्कटॉप ऐप और मोबाइल ऐप के माध्यम से उत्पाद और सेवाओं के
                    लिए दुरुपयोग या अत्यधिक बार-बार अनुरोध करने से आपके खाते के
                    एक्सेस को अस्थायी या स्थायी रूप से निलंबित किया जा सकत है।
                    हम, अपने विवेकाधिकार से, दुरुपयोग या अत्यधिक उपयोग का
                    निर्धारण करेंगे;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>अधिकृत एक्सेस</h3>
          <ol>
            <li>
              <p>
                हम आपकी सुविधा और समय हमारे लिए मायने रखते हैं, जिसके लिए हम
                लगातार प्रयास करते हैं और सुनिश्चित करते हैं कि प्लेटफ़ॉर्म
                हमेशा चालू और उपलब्ध रहे, हालांकि, तकनीकी गड़बड़ियां और अन्य
                गैर-मानवीय त्रुटियां घटित होना निश्चित है।
              </p>
            </li>
            <li>
              <p>
                आपके सहज अनुभव को बनाए रखने के लिए, सामान्य रखरखाव के लिए हम ऐसी
                अवधि में प्लेटफ़ॉर्म/सर्वर/लिंक/वेब-पेज को पूरी तरह या आंशिक रूप
                से बंद करने का अधिकार सुरक्षित रखते हैं, लेकिन फिर भी आपकी
                संतुष्टि सुनिश्चित करने के लिए डाउन-टाइम को न्यूनतम रखने का
                प्रयास करेंगे।
              </p>
            </li>
            <li>
              <p>
                यह पुष्टि की जाती है और आप समझते हैं कि किसी भी कारण के लिए हम
                उत्तरदायी नहीं होंगे, यदि प्लेटफ़ॉर्म, पूरी तरह से या उसका कोई
                हिस्सा, रखरखाव के कारण इस अवधि के दौरान अनुपलब्ध होता है।
              </p>
            </li>
            <li>
              <p>
                हमारी सेवाओं के उपयोग की दिशा में, हमारी सुरक्षा प्रक्रिया के
                हिस्से के रूप में, हम चाहते हैं कि आप अपना ईमेल या मोबाइल नंबर
                दर्ज करके और एक सुरक्षित पासवर्ड चुनकर और/या आपके पास भेजे गए वन
                टाइम पासवर्ड (OTP) को दर्ज करके हमारे यहां अपना खाता पंजीकृत
                करें। उपयोगकर्ता पासवर्ड के मामले में, हम अत्यधिक अनुशंसा करते
                हैं कि आप एक ऐसा मजबूत पासवर्ड चुनें जिसका अनुमान दूसरों के लिए
                लगाना अत्यधिक कठिन हो और प्रत्येक सत्र के अंत में अपने खाते से
                लॉग आउट करें। सुविधा के उद्देश्य से, यदि आप अपना पासवर्ड भूल
                जाते हैं तो पासवर्ड रीसेट करने की प्रक्रिया उपलब्ध है। इस प्रकार
                आपके द्वारा यह सहमति दी जाती है कि आप अपने खाते की लॉग-इन
                जानकारी को किसी तृतीय पक्ष के समक्ष प्रकट नहीं करेंगे, इसे
                गोपनीय रखेंगे और व्यक्तिगत रूप से इसका उपयोग करेंगे। यदि आपको
                लगता है कि सुरक्षा का कोई उल्लंघन हुआ है तो यह आपकी जिम्मेदारी
                है कि आप हमें तुरंत सूचित करें और जहां लॉग-इन ब्लॉक नहीं किया
                गया, वहां तुरंत अपना पासवर्ड बदलें।
              </p>
            </li>
            <li>
              <p>
                प्लेटफ़ॉर्म के आपके उपयोग की एक शर्त यह है कि आपके द्वारा
                प्लेटफ़ॉर्म पर प्रदान की जाने वाली सभी जानकारी सही, वर्तमान और
                संपूर्ण हो। हम किसी भी उपयोगकर्ता खाते को अपने विवेकानुसार
                अनुसार, किसी भी समय किसी भी कारण से अक्षम करने का अधिकार
                सुरक्षित रखते हैं, इसके सहित अगर, जिसमें हमारी राय में और पूर्ण
                विवेकाधिकार अनुसार, आप इन सेवा की शर्तों के किसी भी प्रावधान का
                पालन करने में विफल पाए जाते हैं।
              </p>
            </li>
            <li>
              <p>
                आप सहमति देते हैं कि प्लेटफ़ॉर्म बिना किसी मानवीय हस्तक्षेप के
                एक संचालन है, और इस तरह स्वीकार करते हैं और समझते हैं कि
                प्लेटफ़ॉर्म या प्लेटफ़ॉर्म पर प्रदान की गई कोई भी सामग्री
                त्रुटिरहित नहीं है, और इस प्रकार से सहमति देते हैं कि मानवीय
                हस्तक्षेप के कारण होने वाली कोई भी असुविधा या हानि को सेवा का
                उल्लंघन या कमी का रूप नहीं माना जाएगा।
              </p>
            </li>
          </ol>
        </li>
        <p>
          COVESTRO का यह दायित्व है कि सभी तकनीकी गड़बड़ियां, घटनाएं जो असंभव
          नहीं हैं या विपत्ती के कारण हुई विफलता है, या प्राकृतिक आपदा या आग,
          सरकारी आदेश, दंगे या ऐसे कारकों के कारण उत्पन्न होने वाली घटनाएं जो
          COVESTRO के नियंत्रण से परे हैं, उन्हें 90 से 120 घंटों के भीतर ठीक
          किया जाएगा। हालांकि, COVESTRO को कुछ तकनीकी गड़बड़ियों, प्राकृतिक
          आपदा, सरकारी आदेश, दंगों या किसी अन्य घटना के कारण वेबसाइट तक एक्सेस
          प्रदान नहीं करने के लिए उत्तरदायी नहीं ठहराया जा सकता है।
        </p>
        <li>
          <h3>गोपनीयता</h3>
          <ol>
            <li>
              <p>
                इस शर्त के प्रयोजनों के लिए, “गोपनीय जानकारी” में कोई भी
                मालिकाना डेटा एक पक्ष द्वारा दूसरे पक्ष को लिखित रूप में प्रकट
                और “गोपनीय” रूप में चिह्नित कोई भी मालिकाना डेटा या कोई भी
                जानकारी या मौखिक रूप से प्रकट जानकारी, कामकाज के पांच दिनों के
                लिए भीतर, लिखित में संक्षिप्त और “गोपनीय” के रूप में चिन्हित
                किया गया। हालांकि, गोपनीय जानकारी में ऐसी कोई भी जानकारी शामिल
                नहीं होगी जो आम जनता के लिए ज्ञात है या जानी जाती है, जो किसी
                पक्ष द्वारा प्रकटीकरण से पहले ही प्राप्त करने वाले पक्ष के
                अधिकृत क्षेत्र में है या जिसे प्राप्तकर्ता पक्ष द्वारा गोपनीय
                जानकारी या किसी के उपयोग के बिना स्वतंत्र रूप से विकसित किया गया
                है या जानकारी का COVESTRO द्वारा किसी भी तरह के उपयोग की अनुमति,
                जैसा कि इन शर्तों में वर्णित है।
              </p>
            </li>
            <li>
              <p>
                कोई भी पक्ष दूसरे पक्ष की पूर्व लिखित सहमति के बिना दूसरे पक्ष
                की गोपनीय जानकारी का उपयोग या प्रकटीकरण नहीं करेगा, सिवाय इन
                शर्तों के तहत अपने दायित्वों को निभाने के उद्देश्य को छोड़कर या
                यदि कानून, विनियम या न्यायालय आदेश द्वारा आवश्यक हो; ऐसे मामले
                में, जिस पक्ष को गोपनीय जानकारी प्रकट करने के लिए बाध्य किया जा
                रहा है, वह गोपनीय जानकारी प्रकट करने से पहले दूसरे पक्ष को
                यथोचित रूप से यथासंभव नोटिस देगा।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>व्यक्तिगत डेटा की गोपनीयता और सुरक्षा</h3>
          <ol>
            <li>
              <p>
                साइट पर पंजीकरण करके, आप हमें वास्तविक और उचित जानकारी प्रदान
                करने के लिए सहमत हैं।
              </p>
            </li>
            <li>
              <p>
                असत्य सूचना का संचार करना वर्तमान सामान्य नियमों और शर्तों के
                विपरीत है और आपको परिणामी कानूनी कार्रवाई के लिए उत्तरदायी बना
                देगा।
              </p>
            </li>
            <li>
              <p>
                आप किसी भी समय हमसे यह पता लगाने का अनुरोध कर सकते हैं कि हम
                आपके बारे में कौन सी व्यक्तिगत जानकारी रखते हैं। आप किसी भी समय,
                और अनुरोध द्वारा, इस जानकारी को संशोधित कर सकते हैं।
              </p>
            </li>
            <li>
              <p>
                कुकीज़ आपकी हार्ड ड्राइव पर संग्रहित कुछ सूचनाओं को रिकॉर्ड करती
                हैं। यह जानकारी हमें हमारी साइट पर आने वाले विज़िटर्स के डेटा के
                आधार पर आंकड़े उत्पन्न करने में सक्षम बनाती है और उन उत्पाद और
                सेवाओं का सुझाव देती है जिनमें आपकी रुचि हो सकती है, जहां पिछले
                विज़िट के दौरान आपके द्वारा आइटम चयनित किए गए थे। कुकीज़ में आपके
                बारे में कोई गोपनीय जानकारी नहीं होती है।
              </p>
            </li>
            <li>
              <p>
                प्लेटफ़ॉर्म को कुछ सेवाओं का एक्सेस देने के लिए आपको डेटा प्रदान
                करने की आवश्यकता हो सकती है। जानकारी ऐसे मामलों से संबंधित हो
                सकती है, जिसमें आपके ऑर्डर से संबंधित विवरण शामिल हैं, लेकिन
                इसतक सीमित नहीं है। जबकि इस तरह की जानकारी की गोपनीयता हमारी
                शर्तों की आधारशिला है, हालांकि, आप स्वीकार करते हैं, सहमति देते
                हैं और अनुमति देते हैं कि हमारे व्यवसाय, अनुसंधान और विश्लेषण के
                लिए उत्पाद और सेवाओं के आपके उपयोग में एकत्रित जानकारी या डेटा,
                हमारी गोपनीयता नीति (इसपर स्थित (प्रदान की जाने वाली लिंक{' '}
                <a href={`${APP_DOMAIN}legal/privacy-statement`}>
                  {APP_DOMAIN}legal/privacy-statement
                </a>{' '}
                ) के शर्तों का विषय है, को बनाए रखने के साथ उपयोग कर सकते हैं।
              </p>
            </li>
            <li>
              <p>
                हम आपके डेटा या किसी तृतीय पक्ष के ग्राहक डेटा को किसी भी तृतीय
                पक्ष के साथ साझा नहीं करेंगे, इन शर्तों के अलावा जब तक कि हम (i)
                यह निष्कर्ष नहीं निकालते हैं कि यह कानून द्वारा आवश्यक है या
                नेकनीयती है कि डेटा का उपयोग, संरक्षण या प्रकटीकरण हमारे या
                हमारे उपयोगकर्ताओं या जनता के अधिकारों, संपत्ति या सुरक्षा की
                रक्षा के लिए उचित रूप से आवश्यक है; या (ii) सख्त प्रतिबंधों के
                साथ हमारी ओर से कार्य करने के लिए तृतीय पक्षों को कुछ सीमित
                परिस्थितियों में डेटा प्रदान किया जाता है (जैसे, बिलिंग या डेटा
                संग्रहण) जिससे डेटा का उपयोग किए जाने या साझा किए जाने से इसे
                रोका जाता है, सिवाय हमारे निर्देशानुसार।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>बौद्धिक संपदा</h3>
          <ol>
            <li>
              <p>
                हमारे प्लेटफ़ॉर्म पर सभी सामग्री (चित्र, टेक्स्ट, नाम, ब्रैंड
                नाम, चित्र और वीडियो) COVESTRO की संपत्ति है।
              </p>
            </li>
            <li>
              <p>
                इस सामग्री का कोई भी आंशिक या पूर्ण पुनरुत्पादन, किसी भी माध्यम
                से और किसी भी सहायता पर, COVESTRO द्वारा पूर्व और व्यक्त
                प्राधिकरण के अधीन है।
              </p>
            </li>
            <li>
              <p>
                सभी जानकारी, सामग्री और फाइलें बौद्धिक स्वामित्व और कॉपीराइट से
                संबंधित कानून द्वारा सुरक्षित हैं।
              </p>
            </li>
            <li>
              <p>
                हम आपको ऐसी सामग्री की प्रतिलिपि बनाने, प्रदर्शित करने या वितरित
                करने का अधिकार नहीं देंगे, जिसका आपके पास बौद्धिक अधिकार नहीं
                हैं।
              </p>
            </li>
            <li>
              <p>
                इस सामग्री का सभी छलपूर्ण उपयोग किसी तृतीय पक्ष द्वारा गठित
                अधिकारों का उल्लंघन करेगा, जो जालसाजी का अपराध है, जिसमें
                बौद्धिक संपदा कानून के तहत गंभीर रूप से दंडित किया जाता है।
              </p>
            </li>
            <li>
              <p>
                यदि कोई उपयोगकर्ता साइट पर अपनी गतिविधियों के माध्यम से किसी
                तृतीय पक्ष के अधिकारों का उल्लंघन करता है, तो हम किसी भी
                परिस्थिति में जिम्मेदार नहीं होंगे।
              </p>
            </li>
            <li>
              <p>
                उपयोगकर्ताओं को व्यक्तिगत उपयोग के अलावा किसी अन्य उद्देश्य के
                लिए, जैसा कि कॉपीराइट कानूनों में निर्दिष्ट है, किसी भी उद्देश्य
                के लिए इस प्लेटफ़ॉर्म से प्राप्त डेटा, छवियों और किसी भी अन्य
                प्रकार की सामग्री का उपयोग, ट्रांसपोज़िंग, कॉपी, वितरण या
                संशोधित करने से प्रतिबंधित किया गया है, या जो स्पष्ट रूप से अन्य
                कानून द्वारा अनुमत है, कंपनी या उक्त डेटा, छवियों और सामग्री के
                अधिकारों के धारकों की सहमति के बिना।
              </p>
            </li>
            <li>
              <p>
                COVESTRO उपयोगकर्ताओं या किसी अन्य तृतीय पक्ष द्वारा साइट पर
                पोस्ट की गई या साइट के माध्यम से प्रसारित सामग्री, या साइट पर
                विज्ञापित वस्तुओं से उत्पन्न बौद्धिक संपदा अधिकारों के किसी भी
                उल्लंघन के लिए उत्तरदायी नहीं होगा।
              </p>
              <ol>
                <li>
                  <p>
                    यदि आप बौद्धिक संपदा अधिकारों के स्वामी हैं या एक एजेंट हैं
                    जो बौद्धिक संपदा अधिकारों के स्वामी की ओर से कार्य करने के
                    लिए पूरी तरह से अधिकृत हैं और मानते हैं कि कोई भी सामग्री या
                    अन्य सामग्री आपके बौद्धिक संपदा अधिकार या उस स्वामी के
                    बौद्धिक संपदा अधिकार का उल्लंघन करती है, जिसकी ओर से आप
                    कार्य करने के लिए अधिकृत हैं, आप नेकनीयती के साथ प्रासंगिक
                    सामग्री के लिए COVESTRO को ईमेल आईडी:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    पर ईमेल भेजकर इसे हटाने का अनुरोध कर सकते हैं।
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>वारंटियों का अस्वीकरण</h3>
          <ol>
            <li>
              <p>
                COVESTRO किसी भी समय, सूचना के साथ या बिना किसी सूचना के
                उत्पादों और सेवाओं या सॉफ़्टवेयर में सुधार और/या परिवर्तन कर
                सकता है। सेवा, सॉफ़्टवेयर और उपयोग से संबंधित रिपोर्ट &quot;जिस
                स्थिति में है&quot; प्रदान की जाती है और COVESTRO उनमें से किसी
                के संबंध में कोई वारंटी नहीं देता है। पूर्वगामी की व्यापकता को
                सीमित किए बिना, COVESTRO यह प्रतिनिधित्व या निश्चित नहीं करता है
                कि:
                <br />
                अ) उत्पाद और सेवाओं या सॉफ़्टवेयर का उपयोग आपकी आवश्यकताओं को
                पूरा करेगा या निर्बाध, सुरक्षित या त्रुटि रहित होगा;
                <br />
                ब) दोषों को ठीक किया जाएगा;
                <br />
                स) सर्वर पर सेवा, सॉफ़्टवेयर या कोई अन्य सॉफ़्टवेयर वायरस या
                अन्य हानिकारक घटकों से मुक्त हैं;
                <br />
                द) उत्पाद और सेवाओं का उपयोग और उत्पाद और सेवाओं के माध्यम से
                उपलब्ध कोई भी जानकारी (अभ्यास से संबंधित रिपोर्ट सहित) सही,
                सटीक, समय पर या अन्यथा विश्वसनीय होगी।
              </p>
            </li>
            <li>
              <p>
                हमने आपकी व्यक्तिगत जानकारी और उपयोगकर्ता सामग्री को आकस्मिक
                नुकसान और अनधिकृत एक्सेस, उपयोग, परिवर्तन या प्रकटीकरण से
                सुरक्षित करने के लिए, व्यावसायिक रूप से उचित तकनीकी और
                संगठनात्मक उपाय को लागू किया है। हालांकि, हम इस बात की गारंटी
                नहीं दे सकते हैं कि अनधिकृत तृतीय पक्ष कभी भी उन उपायों को विफल
                नहीं कर पाएंगे या आपकी व्यक्तिगत जानकारी और उपयोगकर्ता सामग्री
                का उपयोग अनुचित उद्देश्यों के लिए नहीं कर पाएंगे। आप स्वीकार
                करते हैं कि आप अपनी व्यक्तिगत जानकारी स्वयं के जोखिम पर प्रदान
                करते हैं।
              </p>
            </li>
            <li>
              <p>
                उत्पाद और सेवाओं पर या प्लेटफ़ॉर्म पर विक्रेताओं के माध्यम से
                पाए जाने वाले विज्ञापनदाताओं के साथ आपका पत्राचार या व्यावसायिक
                व्यवहार, या प्रचार में भागीदारी, संबंधित वस्तु या सेवा के भुगतान
                सहित, और इस तरह के लेनदेन से जुड़े किसी भी अन्य नियम, शर्त,
                वारंटी या प्रतिनिधित्व , केवल आपके और ऐसे विज्ञापनदाता या
                विक्रेता के बीच किए जाते हैं। आप सहमति देते हैं कि, जैसा कि यहां
                निर्धारित किया गया है, COVESTRO किसी भी तरह के लेनदेन के
                परिणामस्वरूप या उत्पाद पर ऐसे विज्ञापनदाताओं या विक्रेताओं की
                उपस्थिति के परिणामस्वरूप होने वाले किसी भी प्रकार के नुकसान या
                क्षति के लिए जिम्मेदार या उत्तरदायी नहीं होगा।
              </p>
            </li>
            <li>
              <p>
                उत्पाद और सेवाएं अन्य वेबसाइट या संसाधन के लिंक प्रदान कर सकते
                हैं, या तृतीय पक्ष प्रदान कर सकते हैं। यह देखते हुए कि ऐसी
                साइटों और संसाधनों पर हमारा कोई नियंत्रण नहीं है, आप स्वीकार
                करते हैं और सहमति देते हैं कि हम ऐसी बाहरी साइटों या संसाधनों की
                उपलब्धता के लिए ज़िम्मेदार नहीं हैं, और ऐसी साइटों या संसाधनों
                पर मौजूद या प्राप्त की जाने वाली अन्य किसी भी सामग्री, विज्ञापन,
                उत्पादों और सेवाओं का समर्थन नहीं करते हैं और न ही इसके लिए
                ज़िम्मेदार हैं या उत्तरदायी हैं। इसके अतिरिक्त, आप स्वीकार करते
                हैं और सहमति देते हैं कि हम प्रत्यक्ष या अप्रत्यक्ष रूप से किसी
                भी तरह की सामग्री, ऐसी कोई साइट या संसाधन के ज़रिये सामान या
                सेवाओं के उपयोग या निर्भरता के कारण या कथित तौर पर होने वाली
                किसी भी क्षति या हानि के लिए जिम्मेदार या उत्तरदायी नहीं होंगे।
              </p>
            </li>
            <li>
              <p>
                आप सहमति देते हैं कि प्लेटफ़ॉर्म और उत्पाद और सेवाओं का आपके
                द्वारा होने वाले जोखिम के लिए आप स्वयं ज़िम्मेदार होंगे। आप
                स्पष्ट रूप से समझते हैं और सहमति देते हैं कि, लागू कानून द्वारा
                अनुमत अधिकतम सीमा तक, प्लेटफ़ॉर्म और उत्पाद और सेवाएं आपके उपयोग
                के लिए &quot;जिस स्थिति में है&quot; और &quot;जिस स्थिति में
                उपलब्ध है&quot; के आधार पर, किसी भी प्रकार की, व्यक्त या निहित
                वारंटी के बिना प्रदान की जाती हैं, लेकिन व्यापारिकता की वारंटी,
                किसी विशेष उद्देश्य के लिए फिटनेस, शीर्षक, गैर-उल्लंघन, और
                व्यापार के व्यवहार या उपयोग के दौरान उत्पन्न होने वाले व्यवहार
                तक ही सीमित नहीं है।
              </p>
            </li>
            <li>
              <p>
                हमें किसी तृतीय पक्ष के समक्ष आपकी पहचान को प्रकट करने का अधिकार
                है, जो मान्य रूप से दावा करते हैं और साक्ष्य प्रदान करते हैं कि
                आपके द्वारा प्लेटफ़ॉर्म पर पोस्ट या अपलोड की गई कोई भी सामग्री
                उनके बौद्धिक संपदा अधिकार या गोपनीयता के उनके अधिकार का उल्लंघन
                करती है।
              </p>
            </li>
            <li>
              <p>
                आपके द्वारा या उत्पाद और सेवाओं के किसी अन्य उपयोगकर्ता द्वारा
                पोस्ट की गई किसी भी सामग्री की सामग्री या सटीकता के लिए हम किसी
                तृतीय पक्ष के प्रति ज़िम्मेदार या उत्तरदायी नहीं हैं।
              </p>
            </li>
            <li>
              <p>
                इन नियमों और शर्तों, इस अनुबंध या गोपनीयता नीति का पालन न करने
                की स्थिति में, हमें आपके एक्सेस या उपयोग के अधिकारों को तुरंत
                समाप्त करने और गैर-अनुपालन वाली जानकारी या सामग्री को हटाने का
                अधिकार है।
              </p>
            </li>
            <li>
              <p>
                हमें कानून की पूरी सीमा तक इन नियमों और शर्तों के उल्लंघन की
                जांच करने और मुकदमा चलाने का अधिकार है और हम इन नियमों और शर्तों
                का उल्लंघन करने वाले उपयोगकर्ताओं पर मुकदमा चलाने में कानून
                प्रवर्तन अधिकारियों के साथ सहयोग कर सकते हैं।
              </p>
            </li>
            <li>
              <p>
                जबकि हमें उत्पाद और सेवाओं (या उत्पाद और सेवाओं की किसी भी
                सुविधा या भाग) तक आपके एक्सेस या आपके उपयोग की निगरानी करने का
                कोई दायित्व नहीं है, हमें प्लेटफ़ॉर्म के संचालन और उत्पाद प्रदान
                करने के उद्देश्य से ऐसा करने का अधिकार है और सेवाएं, इन नियमों
                और शर्तों का अनुपालन सुनिश्चित करने के लिए, या लागू कानून या
                न्यायलय, प्रशासनिक एजेंसी या अन्य सरकारी निकाय के आदेश या
                आवश्यकता का अनुपालन करने के लिए।
              </p>
            </li>
            <li>
              <p>
                किसी उपयोगकर्ता से शिकायत प्राप्त होने के अधीन, हमारे पास किसी
                भी सेवा से किसी भी या सभी सामग्री को प्री-स्क्रीन, समीक्षा,
                फ़्लैग, फ़िल्टर, संशोधित, अस्वीकार या हटाने का अधिकार है (लेकिन
                कोई दायित्व नहीं होगा)। कुछ उत्पाद और सेवाओं के लिए, COVESTRO
                स्पष्ट यौन सामग्री या ऐसी किसी भी सामग्री को फ़िल्टर करने के लिए
                उपकरण प्रदान कर सकता है (लेकिन इसके लिए कोई दायित्व नहीं होगा)
                जिसे वह अपने विवेकाधिकार पर अनुपयुक्त मानता है।
              </p>
            </li>
            <li>
              <p>
                हम किसी भी जानकारी को एक्सेस करने, पढ़ने, संरक्षित करने और प्रकट
                करने का अधिकार सुरक्षित रखते हैं, जैसा कि हम यथोचित रूप से
                आवश्यक मानते हैं: (अ) किसी भी लागू कानून, विनियमन, कानूनी
                प्रक्रिया या सरकारी अनुरोध को पूरा करना; (ब) इन नियमों और शर्तों
                को लागू करें, जिसमें संभावित उल्लंघनों की जांच शामिल है; (स)
                धोखाधड़ी, सुरक्षा या तकनीकी मुद्दों का पता लगाने, रोकने या
                अन्यथा संबोधित करने के लिए; (द) उपयोगकर्ता सहायता अनुरोधों का
                जवाब देना; या (इ) COVESTRO, इसके उपयोगकर्ताओं और जनता के
                अधिकारों, संपत्ति या सुरक्षा की रक्षा करना। COVESTRO इस संबंध
                में इन नियमों और शर्तों के तहत अपने अधिकारों के प्रयोग या
                गैर-प्रयोग के लिए जिम्मेदार या उत्तरदायी नहीं होगा।
              </p>
            </li>
            <li>
              <p>
                हम अपनी ओर से 30 (तीस) दिनों के नोटिस पर सभी सेवाओं की कीमतों को
                शुरू करने या बदलने का अधिकार सुरक्षित रखते हैं। इस तरह का नोटिस
                किसी भी समय प्लेटफ़ॉर्म पर उपयोग के नियमों और शर्तों या उत्पाद
                और सेवाओं में परिवर्तन पोस्ट करके प्रदान किया जा सकता है।
              </p>
            </li>
            <li>
              <p>
                उत्पाद और सेवाओं में किसी भी संशोधन, मूल्य परिवर्तन, निलंबन या
                समापन के लिए COVESTRO आपके या किसी तृतीय पक्ष के प्रति उत्तरदायी
                नहीं होगा।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>तृतीय पक्ष द्वारा उपयोगकर्ता सबमिशन और उपयोगकर्ता सामग्री</h3>
          <ol>
            <li>
              <p>
                आप समझते हैं कि प्लेटफ़ॉर्म का उपयोग करते समय, आप विभिन्न
                स्रोतों से सामग्री के संपर्क में आएंगे और ऐसी सामग्री की सटीकता,
                उपयोगिता, सुरक्षा या बौद्धिक संपदा अधिकारों के लिए COVESTRO
                ज़िम्मेदार नहीं है, और आप सहमति देते हैं और अपने उपयोग प्रति सभी
                उत्तरदायित्व मानते हैं।
              </p>
            </li>
            <li>
              <p>
                जबकि COVESTRO चिंताओं को कम करने के लिए सर्वोत्तम प्रयास को
                प्रयोग करने देता है, आप आगे समझते हैं और स्वीकार करते हैं कि आप
                ऐसी सामग्री के संपर्क में आ सकते हैं जो गलत, आक्रामक, अभद्र, या
                आपत्तिजनक, मानहानिकारक, या अपमानजनक है और आप दावा छोड़ देने के
                लिए सहमति देते हैं और दावा छोड़ देते हैं, COVESTRO के संबंध में
                आपके पास कोई कानूनी या न्यायसंगत अधिकार या उपाय हैं या इसके
                अतिरिक्त हो सकते हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>अधित्याग और पृथक्करणीयता</h3>
          <ol>
            <li>
              <p>
                सेवा की शर्तों के किसी भी अधिकार या प्रावधान को लागू करने या
                लागू करने में COVESTRO की कोई भी विफलता इस तरह के अधिकार या
                प्रावधान के अधित्याग का गठन नहीं करेगी।
              </p>
            </li>
            <li>
              <p>
                सेवा की शर्तें आपके और हमारे बीच पूरे अनुबंध का गठन करती हैं, और
                सेवा के आपके उपयोग को नियंत्रित करती हैं, किसी भी पूर्व अनुबंध
                (सेवा की शर्तों के किसी भी पूर्व संस्करण सहित, लेकिन इसतक सीमित
                नहीं) का स्थान लेती हैं। यदि सेवा की इन शर्तों का कोई प्रावधान
                किसी न्यायालय द्वारा किसी भी कारण से अमान्य, अवैध या अप्रवर्तनीय
                होने के लिए संघटित किया जाता है, तो ऐसे प्रावधान को समाप्त कर
                दिया जाएगा या न्यूनतम सीमा तक सीमित कर दिया जाएगा, ताकि सेवा की
                शर्तों के शेष प्रावधान जारी रहेंगे पूर्ण शक्ति और प्रभाव में।
              </p>
            </li>
            <li>
              <p>
                आप स्पष्ट रूप से स्वीकार करते हैं और सहमति देते हैं कि
                प्लेटफ़ॉर्म पर या सेवा के माध्यम से किसी भी संगठन और/या
                व्यक्तियों के साथ आपकी बातचीत के लिए COVESTRO उत्तरदायी नहीं
                होगा। इसमें माल और सेवाओं का भुगतान और वितरण, और अन्य संगठन
                और/या व्यक्तियों के साथ आपकी किसी भी बातचीत से जुड़े किसी भी
                अन्य नियम, शर्तें, वारंटी, या प्रतिनिधित्व शामिल हैं, लेकिन यह
                इसतक ही सीमित नहीं है। ये लेनदेन केवल आपके और ऐसे संगठनों और/या
                व्यक्तियों के बीच हैं।
              </p>
            </li>
            <li>
              <p>
                आप सहमति देते हैं और स्वीकार करते हैं कि COVESTRO ऐसे किसी भी
                सौदे या बातचीत के परिणामस्वरूप होने वाले किसी भी प्रकार के
                नुकसान या क्षति के लिए जिम्मेदार या उत्तरदायी नहीं होगा। यदि
                प्लेटफ़ॉर्म पर प्रतिभागियों के बीच, या उपयोगकर्ताओं और किसी
                तृतीय पक्ष के बीच कोई विवाद है, तो आप समझते हैं और सहमति देते
                हैं कि COVESTRO ऐसे विवाद में शामिल होने के लिए बाध्य नहीं है।
              </p>
            </li>
            <li>
              <p>
                ऐसी घटना जिसमें आपका एक या एक से अधिक अन्य उपयोगकर्ताओं के साथ
                विवाद है, आप COVESTRO, इसके अधिकारियों, कर्मचारियों, एजेंटों और
                उत्तराधिकारियों को किसी भी और सभी दावे, मांग और हर प्रकार या
                प्रकृति के नुकसान (वास्तविक और परिणामी) ज्ञात या अज्ञात, संदिग्ध
                और असंदिग्ध, खुलासा और अज्ञात, ऐसे विवाद और/या हमारी सेवा से
                संबंधित या किसी भी तरह से उत्पन्न होने वाले विवाद से मुक्त करते
                हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>मोबाइल डिवाइसों का उपयोग</h3>
          <ol>
            <li>
              <p>
                कृपया ध्यान दें कि यदि आप मोबाइल डिवाइस पर ऐप का उपयोग कर रहे
                हैं तो आपके वाहक की सामान्य दर और शुल्क, जैसे टेक्स्ट मैसेजिंग
                और डेटा शुल्क अभी भी लागू होंगे।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>निषिद्ध कारर्वाइयां</h3>
          <ol>
            <li>
              <p>
                इस प्लेटफ़ॉर्म के उपयोगकर्ताओं को निम्नलिखित कार्यों में शामिल
                होने से प्रतिबंधित किया गया है, इन नियमों और शर्तों के अन्य
                प्रावधानों द्वारा निषिद्ध कार्य सहित:
              </p>
              <ul>
                - ऐसी कार्रवाइयां जो इस प्लेटफ़ॉर्म के अन्य उपयोगकर्ताओं, तृतीय
                पक्ष, कंपनी या इसकी भागीदार कंपनियों के कॉपीराइट, गोपनीयता,
                संपत्ति और अन्य अधिकारों या हितों का उल्लंघन करती हैं या कर सकती
                हैं।
              </ul>
              <ul>
                - ऐसी कार्रवाइयां जो इस प्लेटफ़ॉर्म के अन्य उपयोगकर्ताओं, तृतीय
                पक्ष, कंपनी, या इसकी भागीदार कंपनियों को नुकसान, वित्तीय हानि या
                अन्य नुकसान का कारण बनेंगी या हो सकती हैं।
              </ul>
              <ul>
                - ऐसी कार्रवाइयां जो सार्वजनिक आदेश और नैतिकता के लिए अपमानजनक
                होंगी या हो सकती हैं, या कानूनों, विनियमों और अध्यादेशों का
                उल्लंघन करती हैं।
              </ul>
              <ul>
                - सदस्यता पंजीकरण या अन्य उद्देश्यों के लिए इस प्लेटफ़ॉर्म का
                उपयोग करते समय असत्य घोषणाएं।
              </ul>
              <ul>
                - ईमेल पता, पासवर्ड, या अन्य व्यक्तिगत जानकारी का अनुचित उपयोग।
              </ul>
              <ul>
                - इस प्लेटफ़ॉर्म का उपयोग व्यवसाय की मांग करने या लाभ के लिए
                अन्य व्यावसायिक उद्देश्यों को प्राप्त करने के लिए या उसकी तैयारी
                के लिए करना।
              </ul>
              <ul>
                - अन्य कार्रवाइयां जो इस प्लेटफ़ॉर्म के संचालन को बाधित कर सकती
                हैं या सेवाओं के प्रावधान में हस्तक्षेप कर सकती हैं।
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>शासी कानून</h3>
          <ol>
            <li>
              <p>
                इन नियमों और उपयोग की शर्तों का निर्माण, विधि-बल, आवेदन और
                व्याख्या भारत के कानूनों द्वारा शासित होगा।
              </p>
            </li>
            <li>
              <p>
                COVESTRO भारत के अलावा किसी भी देश के कानून के गैर-अनुपालन के
                लिए प्रत्यक्ष या अप्रत्यक्ष रूप से कोई भी दायित्व स्वीकार नहीं
                करता है, केवल यह तथ्य कि प्लेटफ़ॉर्म को एक्सेस किया जा सकता है
                या उपयोग किया जा सकता है या भारत के अलावा किसी अन्य देश में किसी
                भी सुविधा का लाभ उठाया जा सकता है, इसका अर्थ यह नहीं होगा कि हम
                ऐसे देश के कानून को स्वीकार करते हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>क्षेत्राधिकार</h3>
          <ol>
            <li>
              <p>
                इस प्लेटफ़ॉर्म के उपयोग को लेकर एक उपयोगकर्ता और कंपनी या
                भागीदार कंपनी के बीच हुए विवादों को विशेष रूप से मुंबई,
                महाराष्ट्र के न्यायालयों में सुना जाएगा।
              </p>
            </li>
            <li>
              <p>
                पूर्वगामी के बावजूद, आप सहमति देते हैं कि COVESTRO को सक्षम
                अधिकार क्षेत्र के किसी भी न्यायालय में निषेधाज्ञा उपाय (या
                समकक्ष प्रकार की तत्काल कानूनी राहत) प्राप्त करने का अधिकार
                होगा।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>शर्तों और प्लेटफ़ॉर्म में संशोधन</h3>
          <ol>
            <li>
              <p>
                प्लेटफ़ॉर्म एक सतत रूप से प्रगति करने वाला कार्य है और इस तरह यह
                परिवर्धन या विलोपन या प्रतिस्थापन या अन्यथा के माध्यम से संशोधन
                के अधीन है।
              </p>
            </li>
            <li>
              <p>
                हम अपने विवेकानुसार बिना किसी सूचना के समय-समय पर वेबसाइट, ऐप और
                इन शर्तों को अपडेट करने का अधिकार सुरक्षित रखते हैं।
              </p>
            </li>
            <li>
              <p>
                हम अपने विवेकानुसार और बिना किसी सूचना के समय-समय पर वेबसाइट, ऐप
                और इन शर्तों को अपडेट करने का अधिकार सुरक्षित रखते हैं।
              </p>
            </li>
            <li>
              <p>
                अद्यतन सेवा की शर्तों को प्रकाशित करने के बाद प्लेटफ़ॉर्म के
                निरंतर उपयोग से यह समझा जाएगा कि उपयोगकर्ता ने संशोधित शर्तों को
                पढ़ लिया है और उनसे सहमत हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>अपने जोखिम पर उपयोग करें</h3>
          <ol>
            <li>
              <p>
                हमारा लक्ष्य खाद्य सुरक्षा में मदद करने, प्रेरित करने और
                सुनिश्चित करने के लिए मूल, अनुसंधान-आधारित समाधान विकसित करके
                बाज़ार सेवाएं प्रदान करना है। हालांकि, प्लेटफ़ॉर्म किसी भी
                तृतीय-पक्ष विक्रेता की गुणवत्ता की गारंटी नहीं दे सकता है और न
                ही देता है। प्लेटफ़ॉर्म का आपका उपयोग और प्लेटफ़ॉर्म पर प्रदान
                की गई कोई भी जानकारी, पूर्वानुमान या सुझाव पूर्ण रूप से आपकी
                संतुष्टि पर निर्भर हैं। हम गुणवत्ता, मात्रा, डेटा, सूचना,
                अनुमानों और पूर्वानुमानों की सटीकता के बारे में किसी भी प्रकार
                का कोई प्रतिनिधित्व नहीं करते या वारंटी नहीं देते हैं जिसे हम
                आपको प्लेटफ़ॉर्म के माध्यम से प्रदान कर सकते हैं और आप सहमति
                देते हैं और समझते हैं कि प्लेटफ़ॉर्म बाज़ारस्थल के रूप में सेवा
                प्रदान करने के लिए बनाया गया है, और खरीदार के जोखिम के अधीन हैं।
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>विचार और फ़ीडबैक</h3>
          <ol>
            <li>
              <p>
                प्लेटफ़ॉर्म पर COVESTRO को आपके द्वारा दी गई कोई भी राय जांच और
                निरीक्षण के अधीन हैं।
              </p>
            </li>
            <li>
              <p>
                यदि यह पाया जाता है कि टिप्पणियां कानून का उल्लंघन करती हैं या
                अनुचित हैं (अपमानजनक प्रचार, मानहानि, अपमान, संदर्भ से बाहर
                टिप्पणी करना), तो हम इसे अस्वीकार करने या संशोधित करने का अधिकार
                सुरक्षित रखते हैं, और उचित कार्रवाई करते हैं।
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TNCHI;
