import {
  IonImg,
  IonModal,
  IonPage,
  IonPopover,
  IonTextarea,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../components/layouts';
import PrivateRoute from '../../components/privateroute';
import VideoPlayer from '../../components/videoplayer';
import Messages from '../../config/messages';
import { AppContext } from '../../context/app.context';
import { getPostDetails, updatePost } from '../../services/post.service';
import { getFormattedUrl, isWeb } from '../../utils/common.helper';
import CDPlaceholder from '../../assets/images/cd-placeholder.png';
import { t, Trans } from '@lingui/macro';
import ButtonComponent from '../../components/button-component';
import './style.scss';
import { Capacitor } from '@capacitor/core';
import IonModalBack from '../../assets/images/icons/arrow-small-right.png';
import {
  chooseFromGallery,
  chooseFromGallery2,
  imageCapture,
  videoRecorder,
} from '../../utils/media.helper';
import { CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture';
import { INIT_PAGE, MAX_FILE_SIZE } from '../../config/appconfig';
import Cropper from 'react-cropper';
import { App } from '@capacitor/app';
import Loader from '../../components/loader';
import { uploadFile, uploadFileWeb } from '../../services/filetransfer.service';
import SellRequirement from '../sellrequirements';
import Requirement from '../requirements';
import Breadcrumbs from '../../components/breadcrumbs';

let isOptionVisible = false;
let isCropVisible = false;
const EditPost: React.FC = () => {
  const { setLoading, toastMessage }: any = useContext(AppContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [postDetails, setPostDetails]: any = useState(null);
  const [postText, setPostText] = useState('');
  const [imagePostText, setImagePostText] = useState('');
  const [videoPostText, setVideoPostText] = useState('');

  const [video, setVideo]: any = useState(null);
  const [isLocalVideo, setIsLocalVideo]: any = useState(false);

  const [image, setImage]: any = useState(null);
  const [newVideo, setNewVideo]: any = useState(null);
  const [newImage, setNewImage]: any = useState(null);
  const [showVideoOption, setShowVideoOption] = useState(false);
  const [showCameraOption, setShowCameraOption] = useState(false);
  const [imagePath, setImagePath]: any = useState(null);
  const fileInput = useRef(null);
  const fileInputVideo = useRef(null);

  const [showError, setShowError]: any = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [isVideoCompressing, setIsVideoCompressing]: any = useState(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isPostSubmitting, setIsPostSubmitting] = useState(false);

  const { id }: any = useParams();
  const history = useHistory();
  useEffect(() => {
    document.addEventListener('ionBackButton', handleBack);
    return () => {
      document.removeEventListener('ionBackButton', handleBack);
    };
  }, []);

  useEffect(() => {
    setDataLoading(true);
    setPostDetails(null);
    Promise.all([fetchPostDetails()]).finally(() => {
      setDataLoading(false);
    });
  }, [id]);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isOptionVisible) {
        isOptionVisible = false;
        setShowCameraOption(false);
        setShowVideoOption(false);
        // document.removeEventListener('ionBackButton', handleBack);
      } else if (isCropVisible) {
        isCropVisible = false;
        setShowPopover({
          showPopover: false,
          event: undefined,
        });
        setImagePath(null);
      } else if (
        window.location.pathname == '/' ||
        window.location.pathname == INIT_PAGE
      ) {
        App.exitApp();
      } else {
        history.goBack();
      }
    });
  };

  const fetchPostDetails = async () => {
    const resp = await getPostDetails(id);
    if (!resp.error) {
      setPostDetails(resp);
      if (resp.post_type == 'general') {
        if (resp.video_obj) {
          setVideo(resp.video_obj.uri);
          setIsLocalVideo(false);
          setImage(
            resp.image_pic_obj ? getFormattedUrl(resp.image_pic_obj.uri) : null
          );
          setVideoPostText(resp.description);
        } else if (resp.image_pic_obj) {
          setImage(getFormattedUrl(resp.image_pic_obj.uri));
          setImagePostText(resp.description);
        } else {
          setPostText(resp.description);
        }
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const onImagePostSubmit = async () => {
    if (isPostSubmitting) return;
    setIsPostSubmitting(true);
    const req: any = {
      id: postDetails.id,
      post_type: postDetails.post_type,
      description: imagePostText,
    };
    if (newImage) {
      const profileRes: any = await uploadFileWeb(newImage);
      if (!profileRes.error) {
        req.image_id = profileRes.id;
        const resp = await updatePost(req);
        if (!resp.error) {
          setIsPostSubmitting(false);
          history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
        } else {
          setIsPostSubmitting(false);
          toastMessage(Messages.SERVER_ERROR, 'danger');
        }
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      const resp = await updatePost(req);
      if (!resp.error) {
        setIsPostSubmitting(false);
        history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    }
  };

  const onVideoPostSubmit = async () => {
    if (isPostSubmitting) return;
    setIsPostSubmitting(true);
    const req: any = {
      id: postDetails.id,
      post_type: 'general',
      description: videoPostText,
    };
    if (newVideo) {
      const videoRes: any = await uploadFile(
        'file://' + newVideo,
        'video/mp4',
        ''
      );
      const thumbRes: any = await uploadFile(
        'file://' + newImage,
        'image/jpeg',
        ''
      );
      if (!videoRes.error && !thumbRes.error) {
        req.image_id = JSON.parse(thumbRes).id;
        req.video_id = JSON.parse(videoRes).id;
        const resp = await updatePost(req);
        if (!resp.error) {
          setIsPostSubmitting(false);
          history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
        } else {
          setIsPostSubmitting(false);
          toastMessage(Messages.SERVER_ERROR, 'danger');
        }
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      const resp = await updatePost(req);
      if (!resp.error) {
        setIsPostSubmitting(false);
        history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    }
  };

  const onWebVideoPostSubmit = async () => {
    if (isPostSubmitting) return;
    setIsPostSubmitting(true);
    const req: any = {
      id: postDetails.id,
      post_type: 'general',
      description: videoPostText,
    };

    if (newVideo) {
      const videoRes = await uploadFileWeb(newVideo);
      const thumbRes = await uploadFileWeb(newImage);

      if (!videoRes.error && !thumbRes.error) {
        req.image_id = thumbRes.id;
        req.video_id = videoRes.id;
        const resp = await updatePost(req);
        if (!resp.error) {
          setIsPostSubmitting(false);
          history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
        } else {
          setIsPostSubmitting(false);
          toastMessage(Messages.SERVER_ERROR, 'danger');
        }
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    } else {
      const resp = await updatePost(req);
      if (!resp.error) {
        setIsPostSubmitting(false);
        history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
      } else {
        setIsPostSubmitting(false);
        toastMessage(Messages.SERVER_ERROR, 'danger');
      }
    }
  };

  const onTextPostSubmit = async () => {
    if (isPostSubmitting || postText.trim().length == 0) return;
    setIsPostSubmitting(true);
    const resp = await updatePost({
      id: postDetails.id,
      post_type: 'general',
      description: postText,
    });

    setIsPostSubmitting(false);
    if (!resp.error) {
      history.push(`/submittedsuccessfully/edit/${postDetails.id}`);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  return (
    <IonPage className='page-edit-post'>
      <Layout>
        <div className={` page-container ${isWeb() ? 'pt-0' : ''}`}>
          {dataLoading || !postDetails ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {postDetails.post_type == 'general' ? (
                <div>
                  {isWeb() && (
                    <div className='page-menu-list p-32 mb-24'>
                      <div className='page-menu-list-inner'>
                        <Breadcrumbs
                          menuText={'My Corner'}
                          subMenuText={'Edit Post'}
                          menuTextKey={'my_corner'}
                          subMenuTextKey={'edit_post'}
                          menuRoute={'/mycorner'}
                        />
                      </div>
                    </div>
                  )}

                  <div className='edit-post-container'>
                    <div className='edit-post-main-container'>
                      {isVideoCompressing ? (
                        <Loader />
                      ) : video ? (
                        <div>
                          <VideoPlayer
                            isLocal={isLocalVideo}
                            url={video}
                            thumbnail={image ? image : undefined}
                          />
                          <Trans
                            id='what_on_your_mind'
                            render={() => (
                              <IonTextarea
                                className='edit-post-comment-text mt-30'
                                placeholder={t({
                                  id: 'what_on_your_mind',
                                  message: `What's on your mind`,
                                })}
                                rows={3}
                                cols={1}
                                autoGrow
                                value={videoPostText}
                                onIonChange={(e: any) => {
                                  setVideoPostText(e.detail.value);
                                }}
                              ></IonTextarea>
                            )}
                          >
                            {`What's on your mind`}
                          </Trans>
                          <div className='edit-post-btn-wrapper mt-40'>
                            <ButtonComponent
                              disabled={isPostSubmitting}
                              name={'Choose Video'}
                              textKey={'choose_video'}
                              customClass='edit-post-btn'
                              onClick={() => {
                                if (Capacitor.getPlatform() == 'web') {
                                  // @ts-ignore
                                  fileInputVideo?.current?.click();
                                } else {
                                  setShowVideoOption(true);
                                  isOptionVisible = true;
                                }
                              }}
                            />
                            <ButtonComponent
                              disabled={isPostSubmitting}
                              name={'Submit'}
                              textKey={'submit'}
                              customClass='edit-post-btn ml-10'
                              onClick={() => {
                                if (isWeb()) {
                                  onWebVideoPostSubmit();
                                } else {
                                  onVideoPostSubmit();
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : image ? (
                        <div>
                          <IonImg
                            style={{ height: 500 }}
                            src={image ? image : CDPlaceholder}
                          />
                          <Trans
                            id='what_on_your_mind'
                            render={() => (
                              <IonTextarea
                                className='edit-post-comment-text'
                                placeholder={t({
                                  id: 'what_on_your_mind',
                                  message: `What's on your mind`,
                                })}
                                rows={3}
                                cols={1}
                                autoGrow
                                value={imagePostText}
                                onIonChange={(e: any) => {
                                  setImagePostText(e.detail.value);
                                }}
                              ></IonTextarea>
                            )}
                          >
                            {`What's on your mind`}
                          </Trans>
                          <div className='edit-post-btn-wrapper mt-10'>
                            <ButtonComponent
                              disabled={isPostSubmitting}
                              name={'Choose Image'}
                              textKey={'choose_image'}
                              customClass='edit-post-btn'
                              onClick={() => {
                                if (Capacitor.getPlatform() == 'web') {
                                  // @ts-ignore
                                  fileInput?.current?.click();
                                } else {
                                  setShowCameraOption(true);
                                  isOptionVisible = true;
                                }
                              }}
                            />
                            <ButtonComponent
                              disabled={isPostSubmitting}
                              name={'Submit'}
                              textKey={'submit'}
                              customClass='edit-post-btn ml-10'
                              onClick={() => {
                                onImagePostSubmit();
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Trans
                            id='what_on_your_mind'
                            render={() => (
                              <IonTextarea
                                className='edit-post-comment-text'
                                placeholder={t({
                                  id: 'what_on_your_mind',
                                  message: `What's on your mind`,
                                })}
                                rows={3}
                                cols={1}
                                autoGrow
                                value={postText}
                                onIonChange={(e: any) => {
                                  setPostText(e.detail.value);
                                }}
                              ></IonTextarea>
                            )}
                          >
                            {`What's on your mind`}
                          </Trans>
                          <div className='edit-post-btn-wrapper mt-10'>
                            <ButtonComponent
                              name={'Post'}
                              textKey={'post'}
                              customClass='edit-post-btn'
                              disabled={isPostSubmitting}
                              onClick={() => {
                                onTextPostSubmit();
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {showError && (
                        <div className='error'>
                          {Messages.IMAGE_MAX_SIZE_MSG}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : postDetails.post_type == 'sell' ? (
                <div className={`${!isWeb() ? 'pt-20' : ''}`}>
                  <SellRequirement data={postDetails} showBred />
                </div>
              ) : (
                <div className={`${!isWeb() ? 'pt-20' : ''}`}>
                  <Requirement data={postDetails} showBred />
                </div>
              )}
            </>
          )}
        </div>
        <IonModal
          isOpen={showCameraOption || showVideoOption}
          initialBreakpoint={0.25}
          breakpoints={[0, 0.25, 0.5, 1]}
          className='ion-modal'
          onDidDismiss={() => {
            setShowCameraOption(false);
            setShowVideoOption(false);
            isOptionVisible = false;
          }}
        >
          <div
            className='ion-modal-back-wrapper p-16'
            onClick={() => {
              setShowCameraOption(false);
              setShowVideoOption(false);
              isOptionVisible = false;
            }}
          >
            <IonImg
              className='ion-modal-back-img mr-10'
              src={IonModalBack}
            ></IonImg>
            <span className='ion-modal-back-txt mt-4'>
              {t({ id: 'back', message: 'Back' })}
            </span>
          </div>
          <div className='ion-modal-btn-wrapper p-16'>
            <ButtonComponent
              name={showCameraOption ? 'Take a picture' : 'Record video'}
              textKey={showCameraOption ? 'take_a_picture' : 'record_video'}
              customClass='ion-modal-btn mb-10'
              onClick={async (e: any) => {
                if (showCameraOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setLoading(true);
                  const imageData = await imageCapture();
                  setLoading(false);
                  if (imageData) {
                    setImagePath(imageData);
                    setShowPopover({ showPopover: true, event: e });
                    isCropVisible = true;
                  }
                } else if (showVideoOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setIsVideoCompressing(true);

                  const options: CaptureVideoOptions = {};
                  const videoData = await videoRecorder(options);
                  console.log('video-----', videoData);
                  if (videoData) {
                    if (videoData.compressed) {
                      setNewVideo(videoData.compressed);
                      setIsLocalVideo(true);
                      setVideo(
                        Capacitor.convertFileSrc(
                          'file://' + videoData.compressed
                        )
                      );
                    }
                    if (videoData.thumbnail) {
                      setNewImage(videoData.thumbnail);
                      setImage(
                        Capacitor.convertFileSrc(
                          'file://' + videoData.thumbnail
                        )
                      );
                    }
                    setIsVideoCompressing(false);
                  }
                }
              }}
            />
            <ButtonComponent
              name='Choose from gallery'
              textKey={'choose_from_gallery'}
              customClass='ion-modal-btn mb-10'
              onClick={async (e: any) => {
                if (showCameraOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setLoading(true);
                  const imageData = await chooseFromGallery();
                  setLoading(false);
                  if (imageData) {
                    setImagePath(imageData);
                    setShowPopover({ showPopover: true, event: e });
                    isCropVisible = true;
                  }
                } else if (showVideoOption) {
                  setShowCameraOption(false);
                  setShowVideoOption(false);
                  isOptionVisible = false;
                  setIsVideoCompressing(true);
                  const videoData = await chooseFromGallery2('video/*', true);
                  console.log('video-----', videoData);
                  if (videoData) {
                    if (videoData.compressed) {
                      setNewVideo(videoData.compressed);
                      setIsLocalVideo(true);
                      setVideo(
                        Capacitor.convertFileSrc(
                          'file://' + videoData.compressed
                        )
                      );
                    }
                    if (videoData.thumbnail) {
                      setNewImage(videoData.thumbnail);
                      setImage(
                        Capacitor.convertFileSrc(
                          'file://' + videoData.thumbnail
                        )
                      );
                    }
                    setIsVideoCompressing(false);
                  }
                }
              }}
            />
          </div>
        </IonModal>
        <input
          ref={fileInput}
          hidden
          type='file'
          accept={'image/jpeg, image/jpg, image/png'}
          onClick={(e: any) => {
            e.target.value = '';
          }}
          onChange={(e) => {
            if (e.target.files) {
              setLoading(true);
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                setLoading(false);
                console.log(e.target.files);
                if (e.target.files && e.target.files[0].size <= MAX_FILE_SIZE) {
                  setImagePath(reader.result);
                  setShowPopover({ showPopover: true, event: undefined });
                  isCropVisible = true;
                  setShowError(false);
                } else {
                  setShowError(true);
                }
              });
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
        <input
          ref={fileInputVideo}
          hidden
          type='file'
          accept={'video/mp4'}
          onClick={(e: any) => {
            e.target.value = '';
          }}
          onChange={(e) => {
            if (e.target.files) {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                if (e.target.files) {
                  setIsLocalVideo(true);
                  setVideo(URL.createObjectURL(e.target.files[0]));
                  setNewVideo(e.target.files[0]);
                  const video = document.createElement('video');

                  const canvas = document.createElement('canvas');
                  const context = canvas.getContext('2d');
                  video.addEventListener(
                    'loadeddata',
                    function () {
                      if (context) {
                        context.drawImage(
                          video,
                          0,
                          0,
                          canvas.width,
                          canvas.height
                        );
                        video.pause();

                        const dataURI = canvas.toDataURL('image/jpeg');
                        setImage(dataURI);
                        setNewImage(dataURI);
                      }
                    },
                    false
                  );

                  video.preload = 'metadata';
                  video.muted = true;
                  video.playsInline = true;
                  video.setAttribute('crossOrigin', 'anonymous');
                  video.src = URL.createObjectURL(e.target.files[0]);
                  video.play();
                }
              });
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
        <IonPopover
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
            isCropVisible = false;
          }}
          className='home-popover'
          backdropDismiss={false}
        >
          {imagePath && (
            <>
              <Cropper
                src={imagePath}
                style={{ height: 500, width: '100%' }}
                // Cropper.js options
                // initialAspectRatio={16 / 9}
                guides={false}
                ref={cropperRef}
                cropBoxResizable={true}
                dragMode='none'
                viewMode={1}
                background={false}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'black',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: '33%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <ButtonComponent
                    name='Crop & Save'
                    outline
                    textKey={'crop'}
                    customClass='crop-btn ml-10'
                    onClick={async () => {
                      const imageElement: any = cropperRef?.current;
                      const cropper: any = imageElement?.cropper;
                      const temp = cropper
                        .getCroppedCanvas({ maxWidth: 1024, maxHeight: 1024 })
                        .toDataURL('image/jpeg', 0.8);
                      setImage(temp);
                      setNewImage(temp);
                      setImagePath(null);
                      setShowPopover({ showPopover: false, event: undefined });

                      isCropVisible = false;
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: '33%',
                    justifyContent: 'center',
                  }}
                >
                  <ButtonComponent
                    name='Rotate'
                    outline
                    textKey={'rotate'}
                    customClass='rotate-btn'
                    onClick={async () => {
                      const imageElement: any = cropperRef?.current;
                      const cropper: any = imageElement?.cropper;
                      cropper.rotate(90);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: '33%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ButtonComponent
                    name='Cancel'
                    outline
                    textKey={'cancel'}
                    customClass='cancel-btn mr-10'
                    onClick={async () => {
                      setShowPopover({ showPopover: false, event: undefined });
                      setImagePath(null);
                      isCropVisible = false;
                      // document.removeEventListener('ionBackButton', handleBack);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </IonPopover>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(EditPost);
