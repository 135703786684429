import httpService, { HttpMethod } from './http.service';

export const sendDeviceData = async (req: any) => {
  const resp = httpService.execute({
    url: 'userDevice',
    method: HttpMethod.POST,
    data: req,
  });
  return resp;
};

export const deleteDeviceData = async (token: any) => {
  const resp = httpService.execute({
    url: `userDevice/${token}`,
    method: HttpMethod.DELETE,
  });
  return resp;
};
