import { IonSpinner } from '@ionic/react';
import React from 'react';
// import LoaderImg from '../../assets/images/covestro-logo.png';
import './style.scss';

interface Props {
  customClass?: string;
}

const Loader: React.FC<Props> = ({ customClass }) => {
  return (
    <div className={`loader-wrapper loader-img  ${customClass || ''}`}>
      <IonSpinner color='primary' />
    </div>
  );
};

export default Loader;
