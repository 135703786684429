import './style.scss';
import { IonPage } from '@ionic/react';
//import ButtonComponent from '../../components/button-component';
import Layout from '../../components/layouts';
import { t } from '@lingui/macro';
import PrivateRoute from '../../components/privateroute';
import { useHistory, useParams } from 'react-router-dom';
import { INIT_PAGE } from '../../config/appconfig';
const SubmittedSuccessfully: React.FC = () => {
  const history = useHistory();
  const { id, edit }: any = useParams();

  return (
    <IonPage>
      <Layout>
        <div className='success__sec mt-32 page-container'>
          <div className='success__box'>
            <div className='intro-covestro-text font-aktiv-grotest-medium mb-40'>
              {t({
                id: 'post_submitted_review',
                message:
                  'Your post has been submitted successfully and will be reviewed by Admin.',
              })}
            </div>
            <ul className='success__box__links'>
              {id && (
                <li
                  className='lbl font-aktiv-grotest-medium'
                  onClick={() => {
                    history.push(`/commoditydetail/${id}`);
                  }}
                >
                  {t({
                    id: 'view_post',
                    message: 'View Post',
                  })}
                </li>
              )}
              {!edit && (
                <li
                  className='lbl font-aktiv-grotest-medium'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {t({
                    id: 'add_another_post',
                    message: 'Add Another Post',
                  })}
                </li>
              )}
              <li
                className='lbl font-aktiv-grotest-medium'
                onClick={() => {
                  history.push(INIT_PAGE);
                }}
              >
                {t({
                  id: 'home',
                  message: 'Home',
                })}
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(SubmittedSuccessfully);
