import './style.scss';
import { IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { useHistory, useParams } from 'react-router';
import { getFormattedUrlCMS, getRouteForCMS } from '../../utils/common.helper';
import Tag from '../../components/tag';

const SuccessStoryDetail: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  const { id }: any = useParams();

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setEntry(null);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent(id);
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  function onNavClick(route: string) {
    history.push(getRouteForCMS(route));
  }

  return (
    <IonPage className='page-successstorydetail'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <div className='section content-sec pt-0'>
            <div className='page-banner-with-text'>
              {entry.bannerimage && entry.bannerimage.length > 0 && (
                <ContentComp
                  data={{
                    images: entry.bannerimage,
                    unique_key: entry.bannerimage[0].unique_key,
                  }}
                />
              )}

              {entry.text_with_title && (
                <div className='container'>
                  <div className='banner-content'>
                    <div className='banner-tag-container'>
                      <Tag label={'MEDIA'} bgColor={'#E6007E'} />
                    </div>
                    <div className='banner-content__inner'>
                      <ContentComp
                        data={{
                          title: entry.text_with_title.short_text.title,
                          titleTag: entry.text_with_title.short_text.tag_name,
                          titleCustomClass: 'content-heading',
                          description:
                            entry.text_with_title.long_text?.description,
                          descPosition:
                            entry.text_with_title.long_text?.position,
                          unique_key: entry.text_with_title.unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className=' section-about-grid'>
              <div className='container container--medium mt-20'>
                {entry.quick_bites_img_and_desciption &&
                  entry.quick_bites_img_and_desciption.length > 0 && (
                    <div className='row'>
                      {entry.quick_bites_img_and_desciption.map((item: any) => {
                        const col = entry.quick_bites_img_and_desciption[0]
                          .column
                          ? entry.quick_bites_img_and_desciption[0].column.trim() ==
                            '2'
                            ? '3'
                            : entry.quick_bites_img_and_desciption[0].column
                          : '1';
                        return (
                          <div
                            className={`col col-12 col-sm-6 col-md-${
                              12 / parseInt(col)
                            } mt-24 mb-24 md-mt-15 md-mb-15`}
                            key={item.id}
                            onClick={() => {
                              if (item.redirect_to) {
                                onNavClick(item.redirect_to.model);
                              }
                            }}
                          >
                            <ContentComp
                              data={{
                                imagePath: getFormattedUrlCMS(
                                  `${item.images[0].hash}${item.images[0].ext}`
                                ),
                                titleCustomClass: 'mb-5',
                                description: item.description?.description,
                                descPosition: item.description?.position,
                                unique_key: item.unique_key,
                                displayType: item.display_type,
                                titleOnTop: item.title_on_top,
                                imageClass: 'quick-bite-img',
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>

            {entry.title_background && (
              <div>
                <div className='container container--medium mt-20'>
                  <div className=''>
                    <ContentComp
                      data={{
                        imagePath: getFormattedUrlCMS(
                          `${entry.title_background.images[0]?.hash}${entry.title_background.images[0]?.ext}`
                        ),
                        title: entry.title_background.title.title,
                        titleTag: entry.title_background.title.tag_name,
                        description:
                          entry.title_background.description?.description,
                        descPosition:
                          entry.title_background.description?.position,
                        unique_key: entry.title_background.unique_key,
                        displayType: entry.title_background.display_type,
                        titleOnTop: entry.title_background.title_on_top,
                      }}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            )}

            {entry.title_interventions && (
              <div>
                <div className='container container--medium mt-20'>
                  <div className=''>
                    <ContentComp
                      data={{
                        imagePath: getFormattedUrlCMS(
                          `${entry.title_interventions.images[0]?.hash}${entry.title_interventions.images[0]?.ext}`
                        ),
                        title: entry.title_interventions.title.title,
                        titleTag: entry.title_interventions.title.tag_name,
                        description:
                          entry.title_interventions.description?.description,
                        descPosition:
                          entry.title_interventions.description?.position,
                        unique_key: entry.title_interventions.unique_key,
                        displayType: entry.title_interventions.display_type,
                        titleOnTop: entry.title_interventions.title_on_top,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {entry.title_impact && (
              <div>
                <div className='container container--medium mt-20'>
                  <div className=''>
                    <ContentComp
                      data={{
                        title: entry.title_impact.title,
                        titleTag: entry.title_impact.tag_name,
                        unique_key: entry.title_impact.unique_key,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className=' section-about-grid'>
              <div className='container container--medium'>
                {entry.impact_title_and_description &&
                  entry.impact_title_and_description.length > 0 && (
                    <div className='row'>
                      {entry.impact_title_and_description.map((item: any) => {
                        const col = entry.impact_title_and_description[0].column
                          ? entry.impact_title_and_description[0].column.trim() ==
                            '2'
                            ? '3'
                            : entry.impact_title_and_description[0].column
                          : '1';
                        return (
                          <div
                            className={`col col-12 col-sm-6 col-md-${
                              12 / parseInt(col)
                            } `}
                            key={item.id}
                          >
                            <ContentComp
                              data={{
                                title: item.short_text.title,
                                titleTag: item.short_text.tag_name,
                                titleCustomClass: 'mb-5',
                                description: item.long_text?.description,
                                descPosition: item.long_text?.position,
                                unique_key: item.unique_key,
                                displayType: item.display_type,
                                titleOnTop: item.title_on_top,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>

            {entry.title_conclusion && (
              <div className='container container--medium mt-20'>
                <ContentComp
                  data={{
                    title: entry.title_conclusion.short_text.title,
                    titleTag: entry.title_conclusion.short_text.tag_name,
                    titleCustomClass: 'content-heading',
                    description: entry.title_conclusion.long_text?.description,
                    descPosition: entry.title_conclusion.long_text?.position,
                    unique_key: entry.title_conclusion.unique_key,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Layout>
    </IonPage>
  );
};

export default SuccessStoryDetail;
