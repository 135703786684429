import { APP_DOMAIN } from '../../../config/appconfig';

const PrivacyTA: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        COVESTRO உணவு பாதுகாப்பு பிளாட்ஃபார்முக்கான தனியுரிமை விதிமுறைகள்
      </h3>
      <h4 style={{ textAlign: 'center' }}>
        கடைசியாகப் புதுப்பிக்கப்பட்டது [செப்டம்பர் 27, 2022]
      </h4>
      <h3>அறிமுகம்</h3>
      <p>
        Covestro (INDIA) PRIVATE LIMITED இல் எங்கள் சமூகத்தின் ஒரு பகுதியாக
        இருக்கத் தேர்ந்தெடுத்ததற்கு நன்றி, முன்னதாக பேயர் மெட்டீரியல் சயின்ஸ்
        பிரைவேட் லிமிடெட் என அறியப்பட்ட இந்நிறுவனம், நிறுவனங்கள் சட்டம், 1956
        இன் கீழ் இணைக்கப்பட்ட ஒரு பிரைவேட் லிமிடெட் நிறுவனமாகும், இதன் பதிவு
        செய்யப்பட்ட அலுவலகம் பினவரும் முகவரியில் உள்ளது: யூனிட் எண் SB-801, 8வது
        தளம், எம்பயர் டவர், கிளவுட் சிட்டி கேம்பஸ், ஐரோலி, தானே - பேலாப்பூர்
        ரோடு நவி மும்பை தானே MH 400708 IN (&quot;Covestro&quot;,
        &quot;நிறுவனம்&quot;, &quot;நாங்கள்&quot;, &quot;எங்களது&quot;, அல்லது
        &quot;எங்கள்&quot;, அல்லது &quot;பிளாட்ஃபார்ம்&quot;). உங்கள் தனிப்பட்ட
        தகவல் மற்றும் தனியுரிமைக்கான உங்கள் உரிமையைப் பாதுகாப்பதில் நாங்கள்
        உறுதியாக உள்ளோம். எங்கள் கொள்கை அல்லது உங்கள் தனிப்பட்ட தகவல் தொடர்பான
        எங்கள் நடைமுறைகள் குறித்து ஏதேனும் கேள்விகள் அல்லது கவலைகள் இருந்தால்,
        தயவுசெய்து எங்களைத் தொடர்பு கொள்ளவும்.
      </p>
      <p>
        இந்த தனியுரிமைக் கொள்கையானது எங்களின் விதிமுறைகள் மற்றும் நிபந்தனைகளுடன்
        இணைந்து உங்கள் பயன்பாடு மற்றும் பிளாட்ஃபார்மின் அணுகலைக்
        கட்டுப்படுத்துகிறது. பயன்பாட்டு விதிமுறைகளை{' '}
        <a href={`${APP_DOMAIN}legal/conditions-of-use`}>
          {`${APP_DOMAIN}legal/conditions-of-use`}
        </a>{' '}
        இல் அணுகலாம்
      </p>
      <p>
        எங்கள் சேவைகளைப் பெறுவதற்கு முன் ஒவ்வொரு பயனரும் தனியுரிமை விதிமுறைகளை
        நேரம் ஒதுக்கி விரிவாகப் படித்து புரிந்து கொள்ளுமாறு கேட்டுக்
        கொள்ளப்படுகிறது, மேலும் இந்த தனியுரிமைக் கொள்கையில் நீங்கள் உடன்படாத
        விதிமுறைகள் ஏதேனும் இருந்தால், எங்கள் இணையதளம் மற்றும் எங்கள் சேவைகளைப்
        பயன்படுத்துவதை நிறுத்திக் கொள்ளவும்.
      </p>
      <p>
        இந்தத் தனியுரிமைக் கொள்கை எங்கள் இணையதளம்/பயன்பாடு [உணவுப் பாதுகாப்புத்
        தளம்] மற்றும்/அல்லது தொடர்புடைய சேவைகள், விற்பனை, சந்தைப்படுத்தல் அல்லது
        நிகழ்வுகள் மூலம் சேகரிக்கப்பட்ட அனைத்துத் தகவல்களுக்கும் பொருந்தும்
        (இந்த தனியுரிமைக் கொள்கையின் கோரிக்கைக் கூட்டாக &quot;பிளாட்ஃபாரம்&quot;
        என்று குறிப்பிடப்படும்).
      </p>
      <p>
        இந்த தனியுரிமைக் கொள்கையானது எங்களின் தனியுரிமை நடைமுறைகளை விளக்குவதை
        நோக்கமாகக் கொண்டது மற்றும் பின்வரும் பகுதிகளை உள்ளடக்கியது:
      </p>
      <ol>
        <li>
          <h3>நாங்கள் என்ன தகவல்களை சேகரிக்கிறோம்?</h3>
          <ol>
            <li>
              <b>
                <p>
                  <i>நீங்கள் எங்களுக்கு வெளிப்படுத்தும் தனிப்பட்ட தகவல்கள் </i>
                </p>
              </b>
              <i>
                <p>
                  <b>சுருக்கமானது:</b> பெயர், முகவரி, தொடர்புத் தகவல் போன்ற
                  நீங்கள் எங்களுக்கு வழங்கும் தனிப்பட்ட தகவல்களை நாங்கள்
                  சேகரிக்கிறோம், இதில் பில்லிங் தொகையும் அடங்கும் ஆனால் இவை
                  மட்டும் அல்ல.
                </p>
              </i>
              <p>
                பிளாட்ஃபார்மில் பதிவு செய்யும் போது, எங்களைப் பற்றிய அல்லது
                எங்கள் தயாரிப்புகள் மற்றும் சேவைகளைப் பற்றிய தகவல்களைப்
                பெறுவதில் ஆர்வத்தை வெளிப்படுத்தும் போது, பிளாட்ஃபாரம்
                செயல்பாடுகளில் பங்கேற்கும் போது அல்லது கீழே கொடுக்கப்பட்டுள்ள
                தகவலில் எங்களைத் தொடர்பு கொள்ளும்போது நீங்கள் தானாக முன்வந்து
                எங்களுக்கு வழங்கும் தனிப்பட்ட தகவல்களை நாங்கள் சேகரிக்கிறோம்.
              </p>
              <p>
                நீங்கள் எங்களுக்கு வழங்கும் அனைத்து தனிப்பட்ட தகவல்களும்
                உண்மையாகவும், முழுமையாகவும், துல்லியமாகவும் இருக்க வேண்டும்,
                மேலும் இது போன்ற தனிப்பட்ட தகவல்களில் ஏதேனும் மாற்றங்கள்
                இருந்தால் தெரிவிக்கவும்.
              </p>
              <p>
                நாங்கள் சேகரிக்கும் தனிப்பட்ட தகவல்கள், எங்களுடனும்
                இயங்குதளத்துடனும் நீங்கள் மேற்கொள்ளும் தொடர்புகளின் சூழல்,
                நீங்கள் செய்யும் தேர்வுகள் மற்றும் நீங்கள் பெறும் தயாரிப்புகள்
                மற்றும் சேவைகளைப் பொறுத்தது.
              </p>
              <p>
                நாங்கள் &quot;சேகரிக்கும்&quot; தனிப்பட்ட தகவல்களில் பின்வருவன
                அடங்கும்:
              </p>
              <p>
                <b>பெயர் மற்றும் தொடர்பு தரவு.</b> உங்கள் முதல் மற்றும் கடைசி
                பெயர், மின்னஞ்சல் முகவரி, அஞ்சல் முகவரி, தொலைபேசி எண்கள் மற்றும்
                சேவைகளை அணுகுவதற்கு நீங்கள் வழங்கும் இதர தொடர்புத் தரவுகளை
                நாங்கள் சேகரிக்கிறோம்.
              </p>
              <p>
                <b>சான்றுகள்.</b> அங்கீகாரம் மற்றும் கணக்கு அணுகலுக்குப்
                பயன்படுத்தப்படும் கடவுச்சொற்கள், கடவுச்சொல் குறிப்புகள் மற்றும்
                ஒத்த பாதுகாப்புத் தகவல்களை நாங்கள் <b>சேகரிக்க மாட்டோம்.</b>
              </p>
              <p>
                <b>பணம் செலுத்தும் தரவு.</b> உங்கள் கட்டணத்தைச்
                செயல்படுத்துவதற்கும், வாங்குதல்களைச் செயல்படுத்துவதற்கும்
                தேவையான தரவுகளை மட்டுமே நாங்கள் சேகரிக்கிறோம். அனைத்து கட்டணத்
                தரவும் பணம் செலுத்தும் தரவு மூலம் சேமிக்கப்படும், அதன் மீது
                எங்களுக்கு வரையறுக்கப்பட்ட கட்டுப்பாடு உள்ளது, அதன் தனியுரிமைக்
                கொள்கைகளை நீங்கள் மதிப்பாய்வு செய்து, உங்கள் கேள்விகளுக்குப்
                பதிலைப் பெற, கட்டணச் செயலியை நேரடியாகத் தொடர்புகொள்ளவும்.
              </p>
              <p>
                <b>
                  சோதனை தரவு, சோதனை பகுப்பாய்வு அறிக்கைகள், ஆய்வுகள், AI
                  முன்கணிப்பு மாதிரிகள்.
                </b>{' '}
                உங்கள் தரவு அறிக்கைகள், பகுப்பாய்வு, ஆய்வுகள், விளைவிக்கப்பட்ட
                பயிர்கள், விலைகளை சரிபார்க்கும் பயிர்கள், ஆர்வமுள்ள
                தயாரிப்புகள், கட்டுரைகள் மற்றும் இடுகைகள் போன்ற முன்கணிப்பு
                மாதிரிகள் மற்றும் முடிவுகள் மற்றும் பிற வழித்தோன்றல் தகவல்கள்
                போன்றவற்றை இயங்குதளத்தின் பயன்பாட்டிலிருந்து சேகரிக்கிறோம்.
              </p>
              <p>
                <b>சமூக ஊடக உள்நுழைவு தரவு.</b> உங்கள் Facebook, Twitter அல்லது
                பிற சமூக ஊடக கணக்கு போன்ற சமூக ஊடக கணக்கு விவரங்களைப்
                பயன்படுத்தி பதிவு செய்வதற்கான விருப்பத்தை நாங்கள் உங்களுக்கு
                வழங்குகிறோம். இந்த முறையில் பதிவு செய்ய நீங்கள் தேர்வுசெய்தால்,
                பின்வரும் தகவலை நாங்கள் சேகரிப்போம்:
              </p>
              <p>
                உங்கள் பெயர், உங்கள் சமூக வலைப்பின்னல் பயனர்பெயர், இருப்பிடம்,
                பாலினம், பிறந்த தேதி, மின்னஞ்சல் முகவரி, சுயவிவரப் படம் மற்றும்
                தொடர்புகளுக்கான பொதுத் தரவு உட்பட Facebook, Google+ Instagram,
                Pinterest, Twitter, LinkedIn போன்ற சமூக வலைப்பின்னல்
                பிளாட்ஃபார்மிலிருந்து பயனர் தகவல், அத்தகைய சமூக
                வலைப்பின்னல்களுடன் உங்கள் கணக்கை இணைத்திருந்தால். இந்தத்
                தகவல்களில் இயங்குதளத்தைப் பயன்படுத்த மற்றும்/அல்லது சேர நீங்கள்
                அழைக்கும் எவரேனும் ஒருவருடைய தொடர்புத் தகவல்களும்
                உள்ளடக்கியிருக்கலாம்
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>தானாகவே சேகரிக்கப்படும் தகவல்</i>
                </p>
              </b>
              <p>
                <i>
                  <b>சுருக்கமானது:</b> IP முகவரி மற்றும்/அல்லது உலாவி மற்றும்
                  சாதனப் பண்புகள் போன்ற சில தகவல்கள் - பாதுகாப்பு
                  நோக்கங்களுக்காக, எங்கள் இயங்குதளத்தை நீங்கள் பார்வையிடும்போது
                  தானாகவே சேகரிக்கப்படும், மேலும் அவற்றைக் கட்டுப்படுத்த
                  முடியாது.
                </i>
              </p>
              <p>
                நீங்கள் பிளாட்ஃபார்மை பார்வையிடும்போது, பயன்படுத்தும் போது
                அல்லது வழிசெலுத்தும்போது, சில தகவல்களைத் தானாகச் சேகரிப்போம்.
                இந்தத் தகவல் உங்கள் பிரத்யேக அடையாளத்தை (உங்கள் பெயர் அல்லது
                தொடர்புத் தகவல் போன்றவை) வெளிப்படுத்தாது, ஆனால் உங்கள் IP
                முகவரி, உலாவி மற்றும் சாதனத்தின் பண்புகள், இயக்க முறைமை, மொழி
                விருப்பத்தேர்வுகள், குறிப்பிடும் URLகள், சாதனத்தின் பெயர், நாடு,
                இருப்பிடம் போன்ற சாதனம் மற்றும் எங்களின் பிளாட்ஃபார்ம் மற்றும்
                பிற தொழில்நுட்ப தகவல்களை நீங்கள் எப்படி, எப்போது
                பயன்படுத்துகிறீர்கள் என்பது பற்றிய சாதனத்தின் தகவல் மற்றும்
                பயன்பாட்டுத் தகவலை உள்ளடக்கியிருக்கலாம். இந்தத் தகவல் முதன்மையாக
                எங்கள் பிளாட்ஃபார்மின் பாதுகாப்பையும் செயல்பாட்டையும்
                பராமரிக்கவும், எங்கள் உள் பகுப்பாய்வு மற்றும் அறிக்கையிடல்
                நோக்கங்களுக்காகவும் தேவைப்படுகிறது.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>எங்கள் பிளாட்ஃபார்ம்கள் மூலம் சேகரிக்கப்பட்ட தகவல்கள்</i>
                </p>
              </b>
              <p>
                <i>
                  <b>சுருக்கமானது:</b> நீங்கள் இயங்குதளத்தைப் பயன்படுத்தும்
                  போது, உங்கள் புவியியல் இருப்பிடம், மொபைல் சாதனம், புஷ்
                  அறிவிப்புகள் தொடர்பான தகவல்களை நாங்கள் சேகரிக்கலாம்.
                </i>
              </p>
              <p>
                நீங்கள் எங்கள் இயங்குதளத்தைப் பயன்படுத்தினால், பின்வரும்
                தகவலையும் நாங்கள் சேகரிக்கலாம்:
              </p>
              <p>
                - <i>புவியியல் இருப்பிடத் தகவல்.</i> இருப்பிட அடிப்படையிலான
                சேவைகளை வழங்க, உங்கள் மொபைல் சாதனத்தில் இருந்து இருப்பிட
                அடிப்படையிலான தகவலை, தொடர்ச்சியாகவோ அல்லது உங்கள் மொபைல்
                பயன்பாட்டைப் பயன்படுத்தும் போதோ, நாங்கள் அணுக அல்லது அனுமதியைப்
                பெற உங்களைக் கோரலாம். எங்களின் அணுகல் அல்லது அனுமதிகளை மாற்ற
                விரும்பினால், உங்கள் சாதனத்தின் அமைப்புகளில் அதைச் செய்யலாம்.
              </p>
              <p>
                - <i>மொபைல் சாதன அணுகல். </i> உங்கள் மொபைல் சாதனத்தின்
                [புளூடூத், கேலெண்டர், கேமரா, தொடர்புகள், மைக்ரோஃபோன்,
                நினைவூட்டல்கள், சென்சார்கள், எஸ்எம்எஸ் செய்திகள், சமூக ஊடக
                கணக்குகள், சேமிப்பு,] ஐபி முகவரி; நேரம் மண்டலம்; உங்கள் மொபைல்
                சேவை வழங்குநர், மற்றும் பிற அம்சங்கள் பற்றிய தகவல் உள்ளிட்ட,
                உங்கள் மொபைல் சாதனத்தின் சில அம்சங்களுக்கான அணுகல் அல்லது
                அனுமதியை நாங்கள் கோரலாம். எங்களின் அணுகல் அல்லது அனுமதிகளை மாற்ற
                விரும்பினால், உங்கள் சாதனத்தின் அமைப்புகளில் அதைச் செய்யலாம்.
              </p>
              <p>
                - <i>மொபைல் சாதன தரவு.</i> சாதனத் தகவலை நாங்கள் தானாகவே
                சேகரிக்கலாம் (உங்கள் மொபைல் சாதன ஐடி, மாடல் மற்றும்
                உற்பத்தியாளர் போன்றவை), வன்பொருள் மாதிரி; இயக்க முறைமை மற்றும்
                அதன் பதிப்பு பற்றிய தகவல்கள்; தனிப்பட்ட சாதன அடையாளங்காட்டிகள்
                (எ.கா. IDFA); மொபைல் நெட்வொர்க் தகவல்; சாதன சேமிப்பாகத் தகவல்,
                இயக்க முறைமை, பதிப்புத் தகவல், ஐபி முகவரி போன்றவை.
              </p>
              <p>
                - <i>புஷ் அறிவிப்புகள்.</i> உங்கள் கணக்கு அல்லது மொபைல் பயன்பாடு
                தொடர்பான புஷ் அறிவிப்புகளை உங்களுக்கு அனுப்ப நாங்கள் கோரலாம்.
                இந்த வகையான தகவல்தொடர்புகளைப் பெறுவதிலிருந்து விலக விரும்பினால்,
                உங்கள் சாதனத்தின் அமைப்புகள் மூலம் அவற்றை முடக்கலாம்.
              </p>
              <p>
                - <b>செயலி உபயோகத் தரவு, மற்றவை உட்பட:</b> செயலியின் அதிர்வெண்;
                நீங்கள் பார்வையிடும் எங்கள் செயலியின் பகுதிகள் மற்றும்
                அம்சங்கள்; பொதுவான உங்கள் பயன்பாட்டு முறைகள்; குறிப்பிட்ட
                அம்சங்களுடன்டடு பற்றிய தகவலையும் நாங்கள் சேகரிக்கலாம்.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>பிற ஆதாரங்களில் இருந்து சேகரிக்கப்பட்ட தகவல்கள்</i>
                </p>
              </b>
              <p>
                <i>
                  <b>சுருக்கமானது:</b> பொது தரவுத்தளங்கள், சந்தைப்படுத்தல்
                  கூட்டாளர்கள், சமூக ஊடக தளங்கள் மற்றும் பிற வெளிப்புற
                  ஆதாரங்களில் இருந்து வரையறுக்கப்பட்ட தரவை நாங்கள் சேகரிக்கலாம்.
                </i>
              </p>
              <p>
                பொது தரவுத்தளங்கள், கூட்டு சந்தைப்படுத்தல் கூட்டாளர்கள், சமூக
                ஊடக தளங்கள் (பேஸ்புக் உட்பட ஆனால் பேஸ்புக் மட்டும் அல்ல) மற்றும்
                பிற மூன்றாம் தரப்பினரிடமிருந்து உங்களைப் பற்றிய தகவல்களை நாங்கள்
                பெறலாம். இந்தத் தகவலைச் சேகரிக்க, நாங்கள் உங்கள் மொபைல் சாதனம்
                அல்லது கணினிக்கு குக்கீகளை அனுப்பலாம் அல்லது பிற கண்காணிப்பு
                தொழில்நுட்பங்களை பயன்படுத்தலாம். எங்கள் குக்கீ கொள்கையில் மேலும்
                பார்க்கவும்.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>உங்கள் தகவலை நாங்கள் எப்படிப் பயன்படுத்துகிறோம்?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> முறையான வணிக நலன்கள் மற்றும் பரந்த சமூக
              நலன்கள், உங்களுடன் எங்களின் ஒப்பந்தத்தை நிறைவேற்றுதல், எங்களின்
              சட்டப்பூர்வ கடமைகளுக்கு இணங்குதல் மற்றும்/அல்லது உங்கள் சம்மதம்
              ஆகியவற்றின் அடிப்படையில் உங்கள் தகவலை நாங்கள் செயல்படுத்துகிறோம்.
            </i>
          </p>
          <p>
            எங்கள் பிளாட்ஃபார்மின் மூலம் சேகரிக்கப்பட்ட அநாமதேய தகவலை கீழே
            விவரிக்கப்பட்டுள்ள பல்வேறு வணிக நலன்கள் மற்றும் பரந்த சமூக
            நலன்களுக்காகப் பயன்படுத்துகிறோம். உங்களின் சம்மதத்துடன்
            (&quot;சம்மதம்&quot;) உங்களுடன் (&quot;ஒப்பந்தம்&quot;) ஒப்பந்தம்
            செய்துகொள்வதற்காக, எங்களின் சட்டபூர்வமான வணிக நலன்கள் மற்றும் பரந்த
            சமூக நலன்கள் (&quot; வணிக மற்றும் சமூக நோக்கங்கள்&quot; )
            மற்றும்/அல்லது எங்கள் சட்டக் கடமைகளுக்கு (&quot;சட்ட
            காரணங்கள்&quot;) இணங்குதல் ஆகியவற்றின் அடிப்படையிலான நோக்கங்களுக்காக
            உங்கள் தனிப்பட்ட தகவலைச் செயலாக்குகிறோம். கீழே பட்டியலிடப்பட்டுள்ள
            ஒவ்வொரு நோக்கத்திற்கும் அடுத்ததாக நாங்கள் சார்ந்திருக்கும்
            குறிப்பிட்ட செயலாக்க அடிப்படைகளைக் குறிப்பிடுகிறோம்.
          </p>
          <p>
            பின்வரும் நோக்கங்களுக்காக நாங்கள் சேகரிக்கும் அல்லது பெறும் தகவலைப்
            பயன்படுத்துகிறோம், ஆனால் பின்வருவனவற்றிற்காக மட்டும் அல்ல:
          </p>
          <p>
            -{' '}
            <b>
              கணக்கு உருவாக்கம் மற்றும் உள்நுழைவு செயல்முறையை எளிதாக்குவதற்கு{' '}
            </b>
            [உங்கள் ஒப்புதலுடன்] . உங்கள் Google அல்லது Facebook கணக்கு போன்ற
            மூன்றாம் தரப்புக் கணக்குடன் எங்களுடனான உங்கள் கணக்கை இணைக்க நீங்கள்
            தேர்வுசெய்தால், கணக்கை உருவாக்குவதற்கும் உள்நுழைவு செயல்முறைக்கும்
            வசதியாக அந்த மூன்றாம் தரப்பினரிடமிருந்து சேகரிக்க நீங்கள் அனுமதித்த
            தகவலைப் பயன்படுத்துவோம். கூடுதல் தகவல்களுக்கு &quot;சமூக ஊடக
            உள்நுழைவு தரவு&quot; என்ற தலைப்பில் உள்ள பகுதியைப் பார்க்கவும்.
          </p>
          <p>
            -{' '}
            <b>
              உங்களுக்கு சந்தைப்படுத்தல் மற்றும் விளம்பரத் தொடர்புகளை அனுப்ப{' '}
            </b>
            [வணிக மற்றும் சமூக நோக்கங்களுக்காக மற்றும்/அல்லது உங்கள்
            ஒப்புதலுடன்]. நாங்கள் மற்றும்/அல்லது எங்கள் மூன்றாம் தரப்பு
            சந்தைப்படுத்தல் கூட்டாளிகள் நீங்கள் எங்களுக்கு அனுப்பும் தனிப்பட்ட
            தகவலை எங்கள் சந்தைப்படுத்தல் நோக்கங்களுக்காகப் பயன்படுத்தலாம், இது
            உங்கள் மார்க்கெட்டிங் விருப்பங்களுக்கு ஏற்ப இருந்தால். நீங்கள் எந்த
            நேரத்திலும் எங்கள் மார்க்கெட்டிங் மின்னஞ்சல்களை பெறுவதிலிருந்து
            விலகலாம் (கீழே உள்ள &quot;உங்கள் தனியுரிமை உரிமைகளைப்&quot;
            பார்க்கவும்).
          </p>
          <p>
            - <b>வணிக மற்றும் சமூக நோக்கங்களுக்</b> காக உங்களுக்கு நிர்வாகத்
            தகவலை அனுப்பசட்ட காரணங்கள் மற்றும்/அல்லது சாத்தியமான
            ஒப்பந்தத்தின்படி. இயங்குதளம்/பயன்பாடு மூலம் உங்களுக்கு வழங்கப்படும்
            சேவைகளை மேம்படுத்தும் போது, தயாரிப்பு, சேவை மற்றும் புதிய அம்சத்
            தகவல் மற்றும்/அல்லது எங்கள் விதிமுறைகள், நிபந்தனைகள் மற்றும்
            கொள்கைகளில் செய்யப்படும் மாற்றங்கள் பற்றிய தகவல்களை உங்களுக்கு
            அனுப்ப உங்கள் தனிப்பட்ட தகவலை நாங்கள் பயன்படுத்தலாம்;
          </p>
          <p>
            -{' '}
            <b>
              இயங்குதளத்தை பகுப்பாய்வு செய்ய, இயக்க, பராமரிக்க மற்றும்
              மேம்படுத்த,
            </b>{' '}
            புதிய அம்சங்கள் மற்றும் சேவைகளை பிளாட்ஃபார்மில் சேர்க்க,
            இயங்குதளத்தின் தற்போதைய செயல்பாடுகளை ஆதரிக்க;
          </p>
          <p>
            - மூன்றாம் தரப்பு தயாரிப்புகள் மற்றும் சலுகைகள் உட்பட,{' '}
            <b>
              தயாரிப்பு மற்றும் சேவை சலுகைகள் மற்றும் பரிந்துரைகளைத்
              தனிப்பயனாக்க,
            </b>{' '}
            உங்கள் கணக்கைப் பற்றி உங்களுக்குத் தெரிவிக்க, உங்கள் கணக்கில் உள்ள
            சிக்கல்களைத் தீர்க்க, சர்ச்சையைத் தீர்க்க, கட்டணம் அல்லது செலுத்த
            வேண்டிய பணத்தை வசூலிக்க, இயங்குதளம்/ஆப்பைப் பயன்படுத்த நீங்கள்
            தகுதியுடையவரா என்பதை உறுதி செய்து உங்கள் அடையாளத்தை உறுதிப்படுத்த;
          </p>
          <p>
            -{' '}
            <b>
              நீங்கள் கோரும் தயாரிப்புகள் மற்றும் சேவைகளை வழங்குவதற்கும்
              அளிப்பதற்கும்,
            </b>{' '}
            பரிவர்த்தனைகளைச் செயல்படுத்துதல் மற்றும் உறுதிப்படுத்தல்கள் மற்றும்
            நினைவூட்டல்கள் உட்பட தொடர்புடைய தகவல்களை உங்களுக்கு அனுப்ப;
          </p>
          <p>
            - <b>பில்லிங் (இன்வாய்சிங்), கணக்கு மேலாண்மை</b> மற்றும் பிற நிர்வாக
            நோக்கங்களுக்காக, பொருந்தினால்;
          </p>
          <p>
            -{' '}
            <b>
              உங்கள் கருத்துகள், கேள்விகள் மற்றும் கோரிக்கைகளுக்கு பதிலளிக்க
            </b>{' '}
            மற்றும் வாடிக்கையாளர் சேவையை வழங்க;
          </p>
          <p>
            - <b>உங்களுக்கு தொழில்நுட்ப அறிவிப்புகள், புதுப்பிப்புகள்,</b>{' '}
            பாதுகாப்பு எச்சரிக்கைகள் மற்றும் ஆதரவு மற்றும் நிர்வாக செய்திகளை
            அனுப்ப;
          </p>
          <p>
            - <b>எங்கள் இயங்குதளத்துடன் தொடர்புடைய போக்குகள்,</b> பயன்பாடு
            மற்றும் செயல்பாடுகளை கண்காணிக்கவும் பகுப்பாய்வு செய்யவும்;
          </p>
          <p>
            - Covestro விளம்பர நோக்கங்களுக்காக,{' '}
            <b>நீங்கள் பகிரங்கமாக ஒப்புக்கொள்ளும் தகவல் தொடர்பாக மட்டுமே;</b>
          </p>
          <p>
            - <b>உங்கள் அடையாளத்தை சரிபார்க்க;</b>
          </p>
          <p>
            - எங்கள் வணிக நோக்கங்களுக்காக மற்றும்/அல்லது உங்கள் ஒப்புதலுடன்
            <b>இலக்கு விளம்பரங்களை உங்களுக்கு வழங்கவும்.</b> உள்ளடக்கம் மற்றும்
            விளம்பரங்களை உருவாக்கவும் காட்டவும் மற்றும் உங்கள் ஆர்வங்கள்
            மற்றும்/அல்லது இருப்பிடத்திற்கு ஏற்றவாறு மூன்றாம் தரப்பினருடன்
            இணைந்து பணியாற்றவும் மற்றும் அதன் செயல்திறனை அளவிடவும் உங்கள் தகவலை
            நாங்கள் பயன்படுத்தலாம்.
          </p>
          <p>
            - எங்கள் வணிக நோக்கங்களுக்காக மற்றும்/அல்லது உங்கள் ஒப்புதலுடன்
            <b>பரிசுக் குலுக்கல்கள் மற்றும் போட்டிகளை நிர்வகிக்கவும்.</b>{' '}
            நீங்கள் போட்டிகளில் பங்கேற்கத் தேர்ந்தெடுக்கும் போது பரிசுக்
            குலுக்கல் மற்றும் போட்டிகளை நிர்வகிப்பதற்கு உங்கள் தகவலை நாங்கள்
            பயன்படுத்தலாம்.;
          </p>
          <p>
            - எங்கள் வணிக நோக்கங்களுக்காக மற்றும்/அல்லது உங்கள் ஒப்புதலுடன்
            <b>கருத்துக்களைக் கோரவும்.</b> எங்கள் பிளாட்ஃபாரமை பயன்படுத்தியது
            குறித்து உங்கள் கருத்தைப் பெறுவதற்கும் உங்களைத் தொடர்புகொள்வதற்கும்
            நாங்கள் உங்கள் தகவலைப் பயன்படுத்தலாம்;
          </p>
          <p>
            - வணிக நோக்கங்கள் மற்றும்/அல்லது சட்டக் காரணங்களுக்காக{' '}
            <b>எங்கள் பிளாட்ஃபார்மை பாதுகாக்க.</b> எங்கள் பிளாட்ஃபார்மை
            பாதுகாப்பாகவும் பத்திரமாகவும் வைத்திருக்கும் முயற்சியின் ஒரு
            பகுதியாக உங்கள் தகவலை நாங்கள் பயன்படுத்தலாம் (எடுத்துக்காட்டாக,
            மோசடி கண்காணிப்பு மற்றும் தடுப்புக்காக);
          </p>
          <p>
            - வணிக நோக்கங்கள், சட்ட காரணங்கள் மற்றும்/அல்லது சாத்தியமான
            ஒப்பந்தத்திற்கான{' '}
            <b>
              எங்கள் விதிமுறைகள், நிபந்தனைகள் மற்றும் கொள்கைகளைச் செயல்படுத்த
            </b>
          </p>
          <p>
            - <b>சட்டக் கோரிக்கைகளுக்குப் பதில் அளிப்பதற்கும்,</b> சட்டக்
            காரணங்களினால் ஏற்படும் தீங்கைத் தடுப்பதற்கும். நாங்கள் ஒரு நீதிமன்ற
            அழைப்பாணை அல்லது பிற சட்டக் கோரிக்கையைப் பெற்றால், எவ்வாறு
            பதிலளிப்பது என்பதைத் தீர்மானிக்க, நாங்கள் வைத்திருக்கும் தரவை ஆய்வு
            செய்ய வேண்டியிருக்கும்;
          </p>
          <p>
            - <b>பிற வணிக மற்றும் சமூக நோக்கங்களுக்காக.</b> தரவு பகுப்பாய்வு,
            பயன்பாட்டுப் போக்குகளைக் கண்டறிதல், எங்கள் விளம்பரப் பிரச்சாரங்களின்
            செயல்திறனைத் தீர்மானித்தல் மற்றும் எங்கள் பிளாட்ஃபார்ம் மற்றும்
            உங்கள் அனுபவத்தை மதிப்பீடு செய்து மேம்படுத்துதல் போன்ற பிற வணிக
            நோக்கங்களுக்காக உங்கள் தகவலைப் பயன்படுத்துவோம்; மற்றும்
          </p>
          <p>
            - <b>கூட்டமைப்பு கூட்டாளர்களுக்கு.</b> இயங்குதளத்தில்
            பதிவுசெய்யப்பட்ட எங்கள் கூட்டமைப்பு கூட்டாளர்களும் பயனர்களும் உங்கள்
            தளத்தில் நீங்கள் ஆர்வம் காட்டிய தயாரிப்புகள் மற்றும் சேவைகளுக்காக
            உங்கள் தகவலைப் பயன்படுத்தலாம்.
          </p>
        </li>
        <li>
          <h3>உங்கள் தகவல் வேறு யாருடனாவது பகிரப்படுமா?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> உங்கள் சம்மதத்துடன், சட்டங்களுக்கு இணங்க,
              உங்கள் உரிமைகளைப் பாதுகாக்க அல்லது வணிகக் கடமைகளை
              நிறைவேற்றுவதற்காக மட்டுமே தகவலைப் பகிர்கிறோம்.
            </i>
          </p>
          <p>
            பின்வரும் சூழ்நிலைகளில் மட்டுமே நாங்கள் உங்கள் தகவலைப் பகிர்கிறோம்
            மற்றும் வெளிப்படுத்துகிறோம்:
          </p>
          <p>
            - <b>சட்டங்களுடன் இணங்குதல்.</b> பொருந்தக்கூடிய சட்டம், அரசாங்கக்
            கோரிக்கைகள், நீதித்துறை நடவடிக்கைகள், நீதிமன்ற உத்தரவு அல்லது சட்ட
            செயல்முறை அல்லது நீதிமன்ற அழைப்பாணை போன்றவற்றுக்கு இணங்க, (தேசிய
            பாதுகாப்பு அல்லது சட்ட அமலாக்கத் தேவைகளைப் பூர்த்தி செய்ய பொதுத்துறை
            அதிகாரிகளுக்கு பதில் அளிப்பது உட்பட) சட்டப்பூர்வமாக அவ்வாறு
            பதிலளிக்க வேண்டிய நிலையில் உங்கள் தகவலை நாங்கள் வெளியிடலாம்.
          </p>
          <p>
            - <b>முக்கிய நலன்கள் மற்றும் சட்ட உரிமைகள் .</b> எங்கள் கொள்கைகளின்
            சாத்தியமான மீறல்கள், சந்தேகிக்கப்படும் மோசடிகள், எந்தவொரு நபரின்
            பாதுகாப்பிற்கும், சட்ட விரோதமான செயல்களுக்கும் அச்சுறுத்தல்களை
            ஏற்படுத்தக்கூடிய சூழ்நிலைகள் அல்லது நாங்கள் ஈடுபடும் வழக்குகளில்
            ஆதாரமாக, விசாரணை செய்வது, தடுப்பது அல்லது நடவடிக்கை எடுப்பது அவசியம்
            என்று நாங்கள் நம்பும் இடத்தில் உங்கள் தகவலை நாங்கள் வெளியிடலாம்.
          </p>
          <p>
            -{' '}
            <b>
              விற்பனையாளர்கள், ஆலோசகர்கள் மற்றும் பிற மூன்றாம் தரப்பு சேவை
              வழங்குநர்கள்.
            </b>{' '}
            எங்களுக்காக அல்லது எங்கள் சார்பாக சேவைகளைச் செய்யும் மற்றும் அந்த
            சேவைகளைச் செய்வதற்குப் பயன்படும் தகவலின் அணுகல் தேவைப்படும்,
            மூன்றாம் தரப்பு விற்பனையாளர்கள், சேவை வழங்குநர்கள், ஒப்பந்ததாரர்கள்
            அல்லது முகவர்களுடன் உங்கள் தரவைப் பகிரலாம். எடுத்துக்காட்டுகளில்
            பின்வருவன அடங்கும்: கட்டணச் செயலாக்கம், தரவு பகுப்பாய்வு, மின்னஞ்சல்
            விநியோகம், ஹோஸ்டிங் சேவைகள், வாடிக்கையாளர் சேவை மற்றும்
            சந்தைப்படுத்தல் முயற்சிகள். தேர்ந்தெடுக்கப்பட்ட மூன்றாம் தரப்பினர்
            இயங்குதளத்தில் கண்காணிப்பு தொழில்நுட்பத்தைப் பயன்படுத்த நாங்கள்
            அனுமதிக்கலாம், இது காலப்போக்கில் பிளாட்ஃபார்முடன் நீங்கள் எவ்வாறு
            தொடர்பு கொள்கிறீர்கள் என்பது பற்றிய தரவைச் சேகரிக்க அவர்களுக்கு
            உதவும். இந்தத் தகவல், மற்ற செயல்களுடன், தரவை பகுப்பாய்வு செய்யவும்,
            கண்காணிக்கவும், குறிப்பிட்ட உள்ளடக்கத்தின் பிரபலத்தைத்
            தீர்மானிக்கவும், ஆன்லைன் செயல்பாட்டை நன்கு புரிந்துகொள்ளவும்
            பயன்படுத்தப்படலாம். இந்தக் கொள்கையில் விவரிக்கப்பட்டு இருந்தால்
            ஒழிய, மூன்றாம் தரப்பினரின் விளம்பர நோக்கங்களுக்காக உங்களின் எந்த
            தகவலையும் நாங்கள் பகிரவோ, விற்கவோ, வாடகைக்கு விடவோ அல்லது வர்த்தகம்
            செய்யவோ மாட்டோம்.
          </p>
          <p>
            - <b>வணிக பரிமாற்றங்கள்.</b> எந்தவொரு இணைப்பு, நிறுவன சொத்துக்களை
            விற்பனை செய்தல், நிதியளித்தல் அல்லது எங்கள் வணிகத்தின் அனைத்தையும்
            அல்லது ஒரு பகுதியை மற்றொரு நிறுவனத்திற்கு கையகப்படுத்துதல் தொடர்பாக
            அல்லது பேச்சுவார்த்தைகளின் போது உங்கள் தகவலை நாங்கள் பகிரலாம் அல்லது
            மாற்றலாம்.
          </p>
          <p>
            - <b>மூன்றாம் தரப்பு விளம்பரதாரர்கள்.</b> நீங்கள் பிளாட்ஃபார்முக்கு
            வருகை தரும்போது விளம்பரங்களை வழங்க நாங்கள் மூன்றாம் தரப்பு விளம்பர
            நிறுவனங்களைப் பயன்படுத்தலாம். உங்களுக்கு விருப்பமான பொருட்கள்
            மற்றும் சேவைகள் பற்றிய விளம்பரங்களை வழங்குவதற்காக, வலை குக்கீகள்
            மற்றும் பிற கண்காணிப்பு தொழில்நுட்பங்களில் உள்ள எங்கள்
            பிளாட்ஃபார்ம்(கள்) மற்றும் பிற இயங்குதளத்திற்கு நீங்கள் வருகை
            தரும்போது உங்கள் வருகைகள் பற்றிய தகவலை இந்த நிறுவனங்கள்
            பயன்படுத்தலாம். [மேலும் தகவலுக்கு எங்கள் குக்கீ கொள்கை
            [ஹைப்பர்லிங்க்] பார்க்கவும்]
          </p>
          <p>
            - <b>இணைந்த நிறுவனங்கள்.</b> உங்கள் தகவலை நாங்கள் எங்கள் இணைந்த
            நிறுவனங்களுடன் பகிர்ந்து கொள்ளலாம், அந்த சூழலில் அந்த இணைந்த
            நிறுவனங்கள் இந்த தனியுரிமைக் கொள்கையை மதித்து நடக்க வேண்டும். இணைந்த
            நிறுவனங்களில் எங்கள் தாய் நிறுவனம் மற்றும் துணை நிறுவனங்கள், கூட்டு
            முயற்சி பங்குதாரர்கள் அல்லது நாங்கள் கட்டுப்படுத்தும் அல்லது
            எங்களுடன் பொதுவான கட்டுப்பாட்டில் இருக்கும் பிற நிறுவனங்கள்
            அடங்கும்.
          </p>
          <p>
            - <b>வணிக பங்குதாரர்கள்.</b> சில தயாரிப்புகள், சேவைகள் அல்லது
            விளம்பரங்களை உங்களுக்கு வழங்க, எங்கள் வணிகக் கூட்டாளர்களுடன் உங்கள்
            தகவலைப் பகிரலாம்.
          </p>
          <p>
            - <b>உங்கள் சம்மதத்துடன்.</b> உங்கள் ஒப்புதலுடன் வேறு எந்த
            நோக்கத்திற்காகவும் உங்கள் தனிப்பட்ட தகவலை நாங்கள் வெளியிடலாம்.
          </p>
          <p>
            - <b>பிற பயனர்கள்.</b> நீங்கள் தனிப்பட்ட தகவலைப் பகிரும்போது
            (உதாரணமாக, கருத்துகள், பங்களிப்புகள் அல்லது பிற உள்ளடக்கத்தை
            இயங்குதளத்தில் இடுகையிடுவதன் மூலம்) அல்லது இயங்குதளத்தின் பொதுப்
            பகுதிகளுடன் தொடர்பு கொள்ளும்போது, அத்தகைய தனிப்பட்ட தகவல்கள் எல்லாப்
            பயனர்களாலும் பார்க்கப்படலாம் மற்றும் நிரந்தரமாக தளத்திற்கு வெளியே
            பகிரங்கமாக விநியோகிக்கப்படலாம். [எங்கள் பிளாட்ஃபார்மின் பிற
            பயனர்களுடன் நீங்கள் தொடர்புகொண்டு, சமூக வலைப்பின்னல் (பேஸ்புக்
            போன்றவை) மூலம் பதிவுசெய்தால், சமூக வலைப்பின்னலில் உங்களுடன்
            தொடர்புடையவர்கள் உங்கள் பெயர், சுயவிவரப் புகைப்படம் மற்றும் உங்கள்
            செயல்பாட்டின் விளக்கங்களைக் காண்பார்கள்.] இதேபோல், பிற பயனர்கள்
            உங்கள் செயல்பாட்டின் விளக்கங்களைப் பார்க்க முடியும், எங்கள்
            இயங்குதளத்திற்குள் உங்களுடன் தொடர்புகொள்ளவும் மற்றும் உங்கள்
            சுயவிவரத்தைப் பார்க்கவும் முடியும்.
          </p>
        </li>
        <li>
          <h3>
            நாம் குக்கீகள் மற்றும் பிற கண்காணிப்பு தொழில்நுட்பங்களைப்
            பயன்படுத்துகிறோமா?
          </h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> உங்கள் தகவலைச் சேகரிக்கவும் சேமிக்கவும்
              நாங்கள் குக்கீகள் மற்றும் பிற கண்காணிப்பு தொழில்நுட்பங்களைப்
              பயன்படுத்தலாம்.
            </i>
          </p>
          <p>
            எந்தவொரு இணக்கமான நிறுவனம் போன்றே, உங்கள் அனுபவத்தையும் எங்கள்
            தயாரிப்புகள் மற்றும் சேவைகளையும் மேம்படுத்த உதவும் குக்கீகள் மற்றும்
            ஒத்த தொழில்நுட்பங்கள் மூலமாகவும் நாங்கள் தகவலைச் சேகரிக்கிறோம்.
            நாங்கள் பயன்படுத்தும் குக்கீகள் எங்கள் இயங்குதளத்தை வேலை செய்ய
            அனுமதிக்கின்றன மற்றும் பார்வையாளர்களுக்கு எந்த தகவல் மற்றும்
            விளம்பரம் மிகவும் பயனுள்ளதாக இருக்கும் என்பதைப் புரிந்துகொள்ள
            உதவுகின்றன.
          </p>
          <p>
            பெரும்பாலான இணைய உலாவிகள் இயல்பாக குக்கீகளை ஏற்கும் வகையில்
            அமைக்கப்பட்டுள்ளன. நீங்கள் விரும்பினால், பொதுவாக உங்கள் உலாவியை
            குக்கீகளை அகற்றும் வகையிலும் குக்கீகளை நிராகரிக்கும் வகையிலும்
            அமைக்கலாம். குக்கீகளை அகற்றவதை அல்லது குக்கீகளை நிராகரிப்பதை நீங்கள்
            தேர்வுசெய்தால், இது எங்கள் பிளாட்ஃபார்மின் சில அம்சங்கள் அல்லது
            சேவைகளைப் பாதிக்கலாம்
          </p>
          <p>பயனர் மையங்கள் தொழில்நுட்பம் (ஒப்புதல் கருவி)</p>
          <p>
            ஒப்புதல் தேவைப்படும் குக்கீகளுக்கும் குக்கீ அடிப்படையிலான
            பயன்பாடுகளுக்கும் பயனுள்ள பயனர் ஒப்புதலைப் பெற, எங்கள் இணையதளம்,
            Usercentrics GmbH, Sendlinger Str போன்ற தளங்கள் உருவாக்கிய
            தொழில்நுட்பத்துடன் கூடிய குக்கீ ஒப்புதல் கருவியைப் பயன்படுத்துகிறது.
            7, 80331 முனிச் (இனி &quot;பயனர் மையங்கள்&quot; என்று
            குறிப்பிடப்படுகிறது).
          </p>
          <p>
            தொடர்புடைய ஒரு ஜாவாஸ்கிரிப்ட் குறியீட்டை ஒருங்கிணைப்பதன் மூலம்,
            பயனர்கள் பக்கத்தைப் பார்வையிடும்போது ஒரு பேனர் காட்டப்படும், அதில்
            குறிப்பிட்ட குக்கீகள் மற்றும்/அல்லது குக்கீ அடிப்படையிலான செயலிகளை
            பயன்படுத்துவதற்கான ஒப்புதல் பெட்டிகளை சரிபார்ப்பதன் மூலம்
            வழங்கப்படலாம். தொடர்புடைய பெட்டிகளைச் சரிபார்த்து, அந்தந்த பயனர்
            ஒப்புதல் அளிக்கும் வரை, ஒப்புதல் தேவைப்படும் அனைத்து குக்கீகளின்
            அமைப்பையும் அந்தக் கருவி தடுத்து நிறுத்துகிறது. ஒப்புதல்
            அளிக்கப்பட்டு இருந்தால், அத்தகைய குக்கீகள் பயனரின் அந்தந்த இறுதி
            சாதனத்தில் மட்டுமே அமைக்கப்படுவதை இது உறுதி செய்கிறது.
          </p>
          <p>
            இணையதளத்தின் கீழ் வலதுபுறத்தில் உள்ள UserCentrics பொத்தானை
            அழுத்துவதன் மூலம் எந்த நேரத்திலும் பயனர் அமைப்புகளை மாற்றலாம்.
          </p>
          <p>
            எங்கள் வலைத்தளத்தை அணுகும்போது, குறிப்பிட்ட பயனர் தகவல் (IP முகவரி
            உட்பட) குக்கீ ஒப்புதல் கருவி மூலம் சேகரிக்கப்பட்டு, பயனர் மையங்களின்
            சேவையகங்களுக்கு அனுப்பப்பட்டு, அங்கு சேமிக்கப்படும், இதனால் குக்கீ
            ஒப்புதல் கருவியானது தனிப்பட்ட பயனர்களுக்கு பக்கக் காட்சிகளைத்
            தெளிவாக ஒதுக்கி, தனித்தனியாகப் பதிவுசெய்து, கணினியில் பதிவை
            உருவாக்க, அமர்வின் காலத்திற்குப் பயனரால் செய்யப்பட்ட ஒப்புதல்
            அமைப்புகளைச் சேமித்து வைக்க முடியும்.
          </p>
          <p>
            பயனர் மையத்தால் தரவைப் பயன்படுத்துவது பற்றிய கூடுதல் தகவலை
            Usercentrics தனியுரிமைக் கொள்கை{' '}
            <a
              href='https://usercentrics.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              https://usercentrics.com/privacy-policy/
            </a>{' '}
            இணையதளத்தில் நீங்கள் காணலாம்.
          </p>
          <p>
            உங்களுக்கு ஏதேனும் கேள்விகள் இருந்தால் எங்களுக்கு மின்னஞ்சல்
            அனுப்புவதன் மூலமோ அல்லது கீழே உள்ள &quot;எங்களைத் தொடர்பு
            கொள்ளுங்கள்&quot; மூலம் கோரிக்கையைச் சமர்ப்பிப்பதன் மூலமோ
            எங்களுக்குத் தெரியப்படுத்துங்கள்.
          </p>
        </li>
        <li>
          <h3>நாம் இருப்பிடச் சேவைகளைப் பயன்படுத்துகிறோமா?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> ஆம், இருப்பிடச் சேவைகளை வழங்க,
              உள்ளமைக்கப்பட்ட நிரலை அல்லது சிறந்த சேவையை வழங்கும் நோக்கத்திற்காக
              Google Maps போன்ற வெளிப்புற வழங்குநரை நாங்கள் பயன்படுத்துகிறோம்.
            </i>
          </p>
          <p>
            சேகரிக்கப்பட்ட தகவல்கள் இந்தக் கொள்கையின் நோக்கங்களுக்காக மட்டுமே
            பயன்படுத்தப்படும், மேலும் அத்தகைய சேவை வழங்குநரின் பயன்பாட்டு
            விதிமுறைகள் மற்றும் நிபந்தனைகள் எங்கள் நோக்கம் மற்றும்
            கட்டுப்பாட்டிற்கு அப்பாற்பட்டவை ஆகும்.
          </p>
        </li>
        <li>
          <h3>உங்கள் தகவல் சர்வதேசத்திற்கு மாற்றப்பட்டதா? </h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> உங்களுடைய நாட்டைத் தவிர வேறு நாடுகளில் உங்கள்
              தகவலை நாங்கள் மாற்றலாம், சேமிக்கலாம் மற்றும் செயலாக்கலாம்.
            </i>
          </p>
          <p>
            எங்களுக்காக உங்களின் தனிப்பட்ட தரவுகளுடன் குறிப்பிட்ட செயல்பாடுகளைச்
            செய்யும் செயலிகளை நாங்கள் ஈடுபடுத்துகிறோம். செயலிகள் என்பவை
            இயங்குதளத்தை இயக்குவதற்கும், உங்களுடன் எங்களின் தொடர்பை ஆதரிக்கும்
            அல்லது இயங்குதளம் தொடர்பான பிற செயல்பாடுகளைச் செய்வதற்கும் உதவும்
            நிறுவனங்கள் ஆகும். இணையதளம் மற்றும் பயன்பாட்டுச் செயல்பாடுகள்
            மற்றும் அவற்றுடன் தொடர்புடைய செயல்பாடுகள் தொடர்பான இலக்குகளை
            நிறைவேற்ற செயலிகள் எங்கள் சார்பாக சில தனிப்பட்ட தரவைச்
            செயல்படுத்தலாம். செயலிகள் எங்கள் அறிவுறுத்தல்களின்படி மட்டுமே
            செயல்படுகின்றன மற்றும் நாங்கள் செயல்படுத்த அறிவுறுத்தும் அளவிலான
            தனிப்பட்ட தரவை மட்டுமே செயலாக்குகின்றன. எங்கள் செயலிகளின் ஏதேனும்
            செயல்கள் அல்லது குறைபாடுகளுக்கு நாங்கள் முழுப் பொறுப்பாளியாக
            இருக்கிறோம் மற்றும் பொருந்தக்கூடிய சட்டத்தின்படி அவர்களுடன் முறையான
            தரவு செயலாக்க ஒப்பந்தங்களைச் செயல்படுத்த நாங்கள் பொறுப்பேற்கிறோம்.
          </p>
          <p>
            எங்களின் முக்கிய செயலி Amazon Web Services Ince (AWS) ஆகும். Amazon
            Web Services, Inc. அமெரிக்காவில் அமைந்துள்ளது, மேலும் அவற்றின்
            இணைந்த நிறுவனங்கள் உலகம் முழுவதும் பரவியுள்ளன. AWS சலுகைகள் பற்றிய
            எங்கள் தொடர்புகளின் நோக்கத்தைப் பொறுத்து, தனிப்பட்ட தகவல்கள்
            அமெரிக்கா உட்பட பல நாடுகளில் சேமிக்கப்படலாம் அல்லது அணுகப்படலாம்.
            AWS தனிப்பட்ட தகவல்களை பிற அதிகார வரம்புகளுக்கு மாற்றும் போதெல்லாம்,
            அதன் தனியுரிமை அறிவிப்பு ({' '}
            <a
              href='https://aws.amazon.com/privacy/'
              target='_blank'
              rel='noreferrer'
            >
              (https://aws.amazon.com/privacy/)
            </a>{' '}
            ) மற்றும் பொருந்தக்கூடிய தரவுப் பாதுகாப்புச் சட்டங்களால்
            அனுமதிக்கப்படும் அளவுக்கு தகவல் பரிமாற்றம் செய்யப்படுவதை உறுதி
            செய்கிறது.
          </p>
          <p>
            கூடுதலாக, சேகரிக்கப்பட்ட தனிப்பட்ட தரவு Covestro சேவையகங்களை
            வாடகைக்கு விடும் நாடுகளில் அல்லது Covestro அல்லது அதன் இணைந்த
            நிறுவனங்கள், துணை நிறுவனங்கள் அல்லது முகவர்கள் வசதிகளைப்
            பராமரிக்கும் வேறு எந்த நாட்டிலும் சேமிக்கப்பட்டு செயலாக்கப்படும்
            என்பதை ஒப்புக்கொள்கிறீர்கள், இயங்குதளத்தைப் பயன்படுத்துவதன் மூலம்,
            உங்கள் நாட்டிற்கு வெளியே தனிப்பட்ட தரவை மாற்றுவதற்கு நீங்கள்
            ஒப்புக்கொள்கிறீர்கள்.
          </p>
        </li>
        <li>
          <h3>மூன்றாம் தரப்பு இயங்குதளத்தைப் பற்றிய எங்கள் நிலைப்பாடு என்ன?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> எங்கள் இயங்குதளத்துடன் தொடர்பில்லாத,
              விளம்பரப் பொறுப்பேற்கும் மூன்றாம் தரப்பு வழங்குநர்களுடன் நீங்கள்
              பகிரும் எந்தவொரு தகவலின் பாதுகாப்பிற்கும் நாங்கள் பொறுப்பல்ல.
            </i>
          </p>
          <p>
            இயங்குதளத்தில் எங்களுடன் தொடர்பில்லாத மூன்றாம் தரப்பினரின்
            இணைப்புகள் மற்றும் விளம்பரங்கள் இருக்கலாம், மேலும் அவை பிற
            இயங்குதளங்கள் மற்றும் பயன்பாடுகள், ஆன்லைன் சேவைகள் அல்லது மொபைல்
            பயன்பாடுகளுடன் இணைக்கப்படலாம். அத்தகைய விளம்பரங்களை அணுகுவதன் மூலம்
            எந்தவொரு மூன்றாம் தரப்பினருக்கும் நீங்கள் வழங்கும் தரவின் பாதுகாப்பு
            மற்றும் தனியுரிமைக்கு நாங்கள் உத்தரவாதம் அளிக்க முடியாது.
          </p>
          <p>
            மூன்றாம் தரப்பினரால் சேகரிக்கப்பட்ட எந்தத் தரவும் இந்தத் தனியுரிமைக்
            கொள்கையின் கீழ் வராது.
          </p>
          <p>
            எந்தவொரு மூன்றாம் தரப்பினரின் உள்ளடக்கம் அல்லது தனியுரிமை மற்றும்
            பாதுகாப்பு நடைமுறைகள் மற்றும் கொள்கைகளுக்கு நாங்கள் பொறுப்பாக
            மாட்டோம், இவற்றில் மற்ற இயங்குதளம் அல்லது அந்த இயங்குதளத்துடன்
            இணைக்கப்பட்டிருக்கும் சேவைகள் அல்லது பயன்பாடுகளும் அடங்கும். அத்தகைய
            மூன்றாம் தரப்பினரின் கொள்கைகளை நீங்கள் மதிப்பாய்வு செய்து, உங்கள்
            கேள்விகளுக்கு பதிலைப் பெற அவர்களை நேரடியாக தொடர்பு கொள்ள வேண்டும்.
          </p>
        </li>
        <li>
          <h3>உங்கள் தகவலை நாங்கள் எவ்வளவு காலம் வைத்திருப்போம்?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> சட்டப்படி தேவைப்பட்டால் ஒழிய, இந்த
              தனியுரிமைக் கொள்கையில் குறிப்பிடப்பட்டுள்ள நோக்கங்களை
              நிறைவேற்றுவதற்கு தேவையான வரை உங்கள் தகவலை நாங்கள் வைத்திருப்போம்.
            </i>
          </p>
          <p>
            இந்த தனியுரிமைக் கொள்கையில் குறிப்பிடப்பட்டுள்ள நோக்கங்களுக்காக
            உங்கள் தனிப்பட்ட தகவலைத் தேவைப்படும் வரை மட்டுமே நாங்கள்
            வைத்திருப்போம், சட்டத்தின் மூலம் நீண்ட காலம் தக்கவைப்பு
            தேவைப்படுகிறது அல்லது அனுமதிக்கப்படுகிறது என்று இருந்தால் ஒழிய (வரி,
            கணக்கியல் அல்லது பிற சட்டத் தேவைகள் போன்றவை). இந்தக் கொள்கையில் எந்த
            நோக்கத்திற்காகவும் எங்களிடம் நீங்கள் கணக்கு வைத்திருக்கும் காலம்
            (அல்லது) உங்கள் கணக்கு நிறுத்தப்பட்ட 1 வருடம், இதில் எது பிந்தையதோ
            அதைவிட அதிக காலத்திற்கு உங்கள் தனிப்பட்ட தகவலை வைத்திருக்க
            வேண்டியதில்லை.
          </p>
          <p>
            உங்கள் தனிப்பட்ட தகவலைச் செயலாக்குவதற்கு சட்டபூர்வமான வணிகத் தேவை
            இல்லாதபோது, நாங்கள் அதை நீக்குவோம் அல்லது அநாமதேயமாக்குவோம் அல்லது
            இது முடியாவிட்டால் (உதாரணமாக, உங்கள் தனிப்பட்ட தகவல்கள் காப்புப்
            பிரதி காப்பகங்களில் சேமிக்கப்பட்டுள்ளதால்), நாங்கள் உங்கள் தனிப்பட்ட
            தகவலைப் பாதுகாப்பாகச் சேமித்து, நீக்குவது சாத்தியமாகும் வரை, அதை
            மேலும் செயலாக்கத்தில் இருந்து தனிமைப்படுத்துவோம்.
          </p>
        </li>
        <li>
          <h3>உங்கள் தகவலை நாங்கள் எவ்வாறு பாதுகாப்பாக வைத்திருக்கிறோம்?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> நாங்கள் நிறுவனம் மற்றும் தொழில்நுட்ப
              பாதுகாப்பு நடவடிக்கைகளின் மூலம் உங்கள் தனிப்பட்ட தகவலைப்
              பாதுகாப்பதை நோக்கமாகக் கொண்டுள்ளோம்.
            </i>
          </p>
          <p>
            நாங்கள் செயலாக்கும் அல்லது பெறும் எந்தவொரு தனிப்பட்ட தகவலின்
            பாதுகாப்பையும் பத்திரப்படுத்த வடிவமைக்கப்பட்ட பொருத்தமான தொழில்நுட்ப
            மற்றும் நிறுவன பாதுகாப்பு நடவடிக்கைகளை நாங்கள் செயல்படுத்தியுள்ளோம்.
            எந்தவொரு விதிமீறலையும் கண்டறிந்து அடையாளம் காண உரிய கவனிப்பு மற்றும்
            பாதுகாப்பு மேற்கொள்ளப்படுகிறது, மேலும் அதற்கான சரியான திருத்த
            நடவடிக்கை மேற்கொள்ளப்படும் இருப்பினும், தேவையான பாதுகாப்பு
            ஏற்பாடுகள் செயல்படுத்தப்பட்டிருந்தாலும், தொழில்நுட்ப இடைமுகம்
            காரணமாக சாத்தியமான அபாயங்கள் இருக்கலாம் என்று நாங்கள் உங்களுக்குத்
            தெரிவிக்கிறோம்.
          </p>
          <p>
            உங்கள் தனிப்பட்ட தகவலைப் பாதுகாக்க எங்களால் முடிந்த அனைத்தையும்
            செய்வோம் என்றாலும், எங்கள் பிளாட்ஃபார்முக்கு மற்றும் அதிலிருந்து
            தனிப்பட்ட தகவல்களைப் பரிமாற்றுவது உங்கள் சொந்த பொறுப்பாகும்.
            பாதுகாப்பான சூழலில் மட்டுமே நீங்கள் சேவைகளை அணுக வேண்டும்.
          </p>
        </li>
        <li>
          <h3>நாம் சிறுவர்களிடம் இருந்து தகவல்களை சேகரிக்கிறோமா?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> 16 வயதுக்குட்பட்ட குழந்தைகளிடமிருந்து நாங்கள்
              தெரிந்தே தரவுகளை சேகரிக்கவோ அல்லது சந்தைப்படுத்தவோ மாட்டோம்.
            </i>
          </p>
          <p>
            <b>பொதுவான வயது வரம்பு.</b> குழந்தைகளின் தனியுரிமையைப் பாதுகாப்பதில்
            நாங்கள் உறுதியாக உள்ளோம். இந்த இயங்குதளம் குழந்தைகளுக்கானது அல்ல,
            மேலும் 16 வயதுக்குட்பட்ட குழந்தைகளைப் பற்றிய தகவல்களை நாங்கள்
            வேண்டும் என்று சேகரிப்பதில்லை. 16 வயதுக்குட்பட்டவர் என்று
            Covestroவுக்குத் தெரிந்த எந்தவொரு நபரிடமிருந்தும் இயங்குதளம்
            தனிப்பட்ட தரவைச் சேகரிப்பதில்லை. 16 வயதிற்குட்பட்டவர்கள்
            இயங்குதளத்தைப் பயன்படுத்துவதை நீங்கள் அறிந்திருந்தால், எங்களை{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            இல் தொடர்பு கொள்ளவும், நாங்கள் அத்தகைய தகவலை நீக்குவதற்கும் (அல்லது)
            அவரது/அவளது கணக்கை நீக்குவதற்கும் தேவையான நடவடிக்கைகளை எடுப்போம்.
          </p>
          <p>
            <b>ஐரோப்பிய ஒன்றிய குடியிருப்பாளர்களுக்கான வயது வரம்பு.</b> சட்டத்
            தேவைகள் காரணமாக, இணையதளம் மற்றும் பயன்பாட்டைப் பயன்படுத்த உங்களுக்கு
            குறைந்தது 16 வயது இருக்க வேண்டும். இந்த இணையதளம் எந்த ஒரு ஐரோப்பிய
            யூனியன் குடியிருப்பாளர்களுக்கானது அல்ல. நீங்கள் அல்லது உங்களுக்குத்
            தெரிந்த எந்தவொரு பயனரும் ஐரோப்பிய ஒன்றியத்தில் வசிப்பவராக இருந்து
            இயங்குதளத்தைப் பயன்படுத்தினால், எங்களை{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            இல் தொடர்பு கொள்ளவும், நாம் அத்தகைய தகவலை நீக்குவதற்கும் (அல்லது)
            அவருடைய/அவளுடைய கணக்கை நீக்குவதற்கும் நாங்கள் நடவடிக்கைகளை
            எடுப்போம்.
          </p>
        </li>
        <li>
          <h3>உங்கள் தனியுரிமை உரிமைகள் என்ன?</h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> ஐரோப்பியப் பொருளாதாரப் பகுதி போன்ற சில
              பிராந்தியங்களில், உங்களின் தனிப்பட்ட தகவலை அதிக அளவில் அணுகவும்
              கட்டுப்படுத்தவும் உங்களுக்கு உரிமைகள் உள்ளன. நீங்கள் எந்த
              நேரத்திலும் உங்கள் கணக்கை மதிப்பாய்வு செய்யலாம், மாற்றலாம் அல்லது
              நிறுத்தலாம்.
            </i>
          </p>
          <p>
            சில பிராந்தியங்களில் (ஐரோப்பிய பொருளாதாரப் பகுதி போன்றவை),
            பொருந்தக்கூடிய தரவுப் பாதுகாப்புச் சட்டங்களின் கீழ் உங்களுக்கு சில
            உரிமைகள் உள்ளன இந்த உரிமைகளில் (i) உங்கள் தனிப்பட்ட தகவலின் அணுகலைக்
            கோருவதும் நகலைப் பெறுவதும், (ii) திருத்தம் அல்லது அழிப்பைக்
            கோருவதும்; (iii) உங்கள் தனிப்பட்ட தகவலைச் செயலாக்குவதைக்
            கட்டுப்படுத்துவதும்; மற்றும் (iv) பொருந்தினால், தரவு
            பெயர்வுத்திறனும் அடங்கும். சில சூழ்நிலைகளில், உங்கள் தனிப்பட்ட
            தகவலைச் செயலாக்குவதை எதிர்க்கும் உரிமையும் உங்களுக்கு இருக்கலாம்.
            அத்தகைய கோரிக்கையைச் செய்ய, கீழே கொடுக்கப்பட்டுள்ள தொடர்பு
            விவரங்களைப் பயன்படுத்தவும் [HYPERLINK]. எவ்வாறாயினும்,
            பொருந்தக்கூடிய தரவுப் பாதுகாப்புச் சட்டங்களின்படி எந்தவொரு
            கோரிக்கையையும் பரிசீலித்து செயல்படுவதற்கான உரிமையை நாங்கள்
            பெற்றிருக்கிறோம்.
          </p>
          <p>
            உங்கள் தனிப்பட்ட தகவலைச் செயல்படுத்த உங்கள் ஒப்புதலை நாங்கள்
            சார்ந்து இருக்கும் போது, எந்த நேரத்திலும் உங்கள் ஒப்புதலைத்
            திரும்பப் பெற உங்களுக்கு உரிமை உண்டு. எவ்வாறாயினும், இது திரும்பப்
            பெறுவதற்கு முந்தைய தகவலைச் செயலாக்குவதன் சட்டபூர்வமான தன்மையை
            பாதிக்காது என்பதை நினைவில் கொள்ளவும்.
          </p>
          <p>
            நீங்கள் ஐரோப்பிய பொருளாதாரப் பகுதியில் வசிப்பவராக இருந்து, உங்கள்
            தனிப்பட்ட தகவல்களை நாங்கள் சட்டவிரோதமாகச் செயலாக்குகிறோம் என நீங்கள்
            கருதினால், எங்களைத் தொடர்புகொள்ளுமாறு கேட்டுக் கொள்ளப்படுகிறீர்கள்.
          </p>
          <ol>
            <li>
              <p>
                <i>
                  <b>கணக்கு விபரம்</b>
                </i>
              </p>
              <p>
                நீங்கள், பின்வரும் முறைகளில், எந்த நேரத்திலும் உங்கள் கணக்கில்
                உள்ள தகவலை மறுபரிசீலனை செய்யலாம் அல்லது மாற்றலாம் அல்லது உங்கள்
                கணக்கை நிறுத்தலாம்:
              </p>
              <p>
                - உங்கள் கணக்கு அமைப்புகளில் உள்நுழைந்து உங்கள் கணக்கைப்
                புதுப்பித்தல்; அல்லது
              </p>
              <p>
                - கீழே கொடுக்கப்பட்டுள்ள தொடர்புத் தகவலைப் பயன்படுத்தி எங்களைத்
                தொடர்பு கொள்வது
              </p>
              <p>
                உங்கள் கணக்கை நிறுத்துவதற்கான உங்கள் கோரிக்கையின் பேரில், எங்கள்
                கொள்கையின்படி உங்கள் கணக்கையும் எங்கள் செயலில் உள்ள
                தரவுத்தளங்களிலிருந்து தகவலையும் நாங்கள் செயலிழக்கச் செய்வோம்
                அல்லது நீக்குவோம். எவ்வாறாயினும், மோசடியைத் தடுக்க, சிக்கல்களைத்
                தீர்க்க, எந்தவொரு விசாரணையிலும் உதவ, எங்கள் பயன்பாட்டு
                விதிமுறைகளை அமல்படுத்த மற்றும்/அல்லது சட்டத் தேவைகளுக்கு
                இணங்குவதற்கு சில தகவல்கள் எங்கள் கோப்புகளில் சேமிக்கப்படும்.
              </p>
            </li>
            <li>
              <p>
                <i>
                  <b>ஆளும் சட்டம் மற்றும் சர்ச்சைத் தீர்வு</b>
                </i>
              </p>
              <p>
                இந்தக் கொள்கையின் கீழ் ஏதேனும் தகராறுகள் ஏற்பட்டால், அவை
                இந்தியாவின் சட்டங்களுக்கு உட்பட்டவை, மேலும் மும்பை, மகாராஷ்டிரா
                நீதிமன்றங்கள் அதற்கான பிரத்யேக அதிகார வரம்பைக் கொண்டிருக்கும்.
              </p>
              <p>
                - மேலே குறிப்பிட்டுள்ளபடி இந்தக் கொள்கையின் பொருள்விளக்கம்,
                செயல்படுத்தல் அல்லது உத்தேசிக்கப்பட்ட முடிவு தொடர்பாக ஏதேனும்
                தகராறு அல்லது வேறுபாடு ஏற்பட்டால், கட்சிகள் நட்புரீதியான
                ஆலோசனைகள் மூலம் அத்தகைய மோதலைத் தீர்க்க முதல் சந்தர்ப்பத்தில்
                முயற்சிக்கும்.
              </p>
              <p>
                - விவாதங்கள் தொடங்கிய 30 நாட்களுக்குள் அல்லது கட்சிகள்
                எழுத்துப்பூர்வமாக ஒப்புக் கொள்ளும் நீண்ட காலத்திற்குள்
                நட்புரீதியான ஆலோசனைகள் மூலம் அத்தகைய சர்ச்சை
                தீர்க்கப்படாவிட்டால், எந்தவொரு தரப்பினரும் இந்த ஒப்பந்தத்தின்
                விதிமுறைகளின்படி நடுவர் மூலம் சர்ச்சையைத் தீர்க்க
                முயற்சிக்கலாம்.
              </p>
              <p>
                - அத்தகைய அனைத்து சர்ச்சைகளும் நடுவர் மற்றும் சமரசச் சட்டம்,
                1996 இன் படி, அவ்வப்போது மற்றும் இந்திய நடுவர் மன்றத்தின்
                (&quot;ICA&quot;) விதிகளின் கீழ், திருத்தம் அல்லது மறு
                சட்டத்தின்படி நடுவர் மன்றத்தால் குறிப்பிடப்பட்டு இறுதியாக
                தீர்க்கப்படும். இந்த உட்பிரிவில் குறிப்பிடுவதன் மூலம் நடுவர்
                மன்ற விதிகள் இணைக்கப்பட்டதாகக் கருதப்படும். தீர்ப்பாயம் மூன்று
                நடுவர்களைக் கொண்டிருக்கும். சர்ச்சைக்குரிய ஒவ்வொரு தரப்பினரும்
                ஒரு நடுவரை நியமிக்க வேண்டும் மற்றும் அவ்வாறு நியமிக்கப்பட்ட
                இரண்டு நடுவர்கள் கூட்டாக மூன்றாவது நடுவரை நியமிக்க வேண்டும்.
                நடுவர் மன்றத்தின் மொழி ஆங்கிலமாக இருக்க வேண்டும்.
              </p>
              <p>- நடுவர் மன்ற இடம் இந்தியாவின் மும்பையாக இருக்கும்.</p>
              <p>
                - நடுவர் மன்ற தீர்ப்பு இறுதியானது மற்றும் அது அனைத்து
                கட்சிகளையும் கட்டுப்படுத்தும் மேலும் கட்சிகள் அதற்குக்
                கட்டுப்பட்டு அதன்படி செயல்பட ஒப்புக்கொள்கின்றன.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>
            இந்தக் கொள்கையில் புதுப்பிப்புகள் அல்லது மாற்றங்களைச் செய்கிறோமா?
          </h3>
          <p>
            <i>
              <b>சுருக்கமானது:</b> ஆம், தொடர்புடைய சட்டங்களுடன் இணங்குவதற்கு
              தேவையான அளவுக்கு இந்தக் கொள்கையைப் புதுப்பிப்போம்.
            </i>
          </p>
          <p>
            இந்த தனியுரிமைக் கொள்கையை நாங்கள் அவ்வப்போது புதுப்பிக்கலாம்.
            புதுப்பிக்கப்பட்ட பதிப்பு புதுப்பிக்கப்பட்ட
            &quot;திருத்தப்பட்ட&quot; தேதியால் குறிக்கப்படும், மேலும்
            புதுப்பிக்கப்பட்ட பதிப்பு அணுகப்பட்டவுடன் நடைமுறைக்கு வரும்.
          </p>
          <p>
            இந்தத் தனியுரிமைக் கொள்கையில் நாங்கள் முக்கிய மாற்றங்களைச் செய்தால்,
            அத்தகைய மாற்றங்களின் அறிவிப்பை முக்கியமாக இடுகையிடுவதன் மூலமோ அல்லது
            நேரடியாக உங்களுக்கு அறிவிப்பை அனுப்புவதன் மூலமோ நாங்கள் உங்களுக்குத்
            தெரிவிக்கலாம். உங்கள் தகவலை நாங்கள் எப்படிப் பாதுகாக்கிறோம் என்பதைத்
            தெரிவிக்க, இந்தத் தனியுரிமைக் கொள்கையை அடிக்கடி மதிப்பாய்வு
            செய்யுமாறு உங்களை ஊக்குவிக்கிறோம்.
          </p>
        </li>
        <li>
          <h3>
            இந்தக் கொள்கையைப் பற்றி நீங்கள் எப்படி எங்களைத் தொடர்புகொள்ளலாம்?
          </h3>
          <p>
            இந்தக் கொள்கையைப் பற்றி உங்களிடம் கேள்விகள் அல்லது கருத்துகள்
            இருந்தால்,{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            இல் எங்களுக்கு மின்னஞ்சல் அனுப்பவும்
          </p>
          <p>
            எங்களைப் பற்றியோ எங்கள் கொள்கைகளைப் பற்றியோ உங்களுக்கு ஏதேனும்
            கேள்விகள் அல்லது கருத்துகள் இருந்தால்,{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            இல் எங்களுக்கு மின்னஞ்சல் அனுப்பவும்.
          </p>
        </li>
      </ol>
    </div>
  );
};
export default PrivacyTA;
