import { IonPage } from '@ionic/react';
import { t } from '@lingui/macro';
import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import NewsAndAnnouncement from '../../components/newsannouncements';
import PrivateRoute from '../../components/privateroute';
import Notification from '../../components/notifications';
import './style.scss';
import { openExternalUrl } from '../../utils/common.helper';
import { useHistory } from 'react-router';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import EmptyData from '../../components/emptydata';
import { getNotificationListById } from '../../services/user.service';
import storageService from '../../services/storage.service';

const NotificationsWithTab: React.FC = () => {
  const { setLoading, loading, setIsNewNotification }: any =
    useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [newsList, setNewsList]: any = useState(null);
  const [notificationList, setNotificationList]: any = useState([]);
  const [lastId, setLastId]: any = useState(0);
  const [notificationLoadMore, setNotificationLoadMore]: any = useState(false);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setIsNewNotification(false);
    storageService.set('setIsNewNotification', 'false');
    Promise.all([getNewsList(), getNotifications()]).finally(() => {
      setLoading(false);
    });
  }, []);

  function onTabClick(index: number) {
    setSelectedTab(index);
  }

  const getNewsList = async () => {
    const resp = await getContent('news');
    if (!resp.error && resp.entry) {
      setNewsList(JSON.parse(resp.entry));
    }
  };

  const getNotifications = async () => {
    const resp = await getNotificationListById(lastId);
    if (!resp.error) {
      if (resp.length > 0) {
        if (lastId == 0) {
          const lastNotiId = await storageService.get('notificationLastId');
          if (lastNotiId) {
            if (lastNotiId != resp[0].id) {
              await storageService.set('notificationLastId', resp[0].id);
              // await storageService.set('setIsNewNotification', 'true');
              // setIsNewNotification(true);
            }
          } else {
            await storageService.set('notificationLastId', resp[0].id);
            // await storageService.set('setIsNewNotification', 'true');
            // setIsNewNotification(true);
          }
        }
        setNotificationList(notificationList.concat(resp));
        setLastId(resp[resp.length - 1].id);
      }
      setNotificationLoadMore(resp.length > 0);
    }
  };

  return (
    <IonPage className='page-notifications'>
      <Layout>
        <div className='buy__cl-card-list-wrapper'>
          <div className='cd-card-wrapper p-16 '>
            <ul className='notificationTab'>
              <li className={selectedTab == 1 ? 'active' : ''}>
                <div
                  className='font-aktiv-grotest-medium userTab__link'
                  onClick={() => {
                    onTabClick(1);
                  }}
                >
                  {t({
                    id: 'notifications',
                    message: 'Notifications',
                  })}
                </div>
              </li>
              <li className={selectedTab == 2 ? 'active' : ''}>
                <div
                  className='font-aktiv-grotest-medium userTab__link'
                  onClick={() => {
                    onTabClick(2);
                  }}
                >
                  {t({
                    id: 'news_and_announcements',
                    message: 'News and Announcements',
                  })}
                </div>
              </li>
            </ul>
            <div style={{ display: selectedTab == 1 ? 'block' : 'none' }}>
              {loading && notificationList.length == 0 ? (
                <></>
              ) : notificationList.length == 0 ? (
                <EmptyData message='No Data Found' messageKey='no_data_found' />
              ) : (
                notificationList.map((item: any) => {
                  const metaData = JSON.parse(item.metaData);
                  return (
                    <Notification
                      key={item.id}
                      message={item.title.replace('${user.fullname}', '')}
                      cutomerName={
                        metaData
                          ? (metaData.type != 'APPROVED' ||
                              metaData.type != 'REJECTED') &&
                            metaData.user
                            ? metaData.user.fullname
                            : null
                          : null
                      }
                      profilePic={
                        metaData
                          ? (metaData.type != 'APPROVED' ||
                              metaData.type != 'REJECTED') &&
                            metaData.user &&
                            metaData.user.profile_pic_obj
                            ? metaData.user.profile_pic_obj.uri
                            : undefined
                          : undefined
                      }
                      notificationTime={item.created_at}
                      metaData={metaData}
                    />
                  );
                })
              )}
              {notificationLoadMore && (
                <div
                  className='load-more-btn font-aktiv-grotest-medium mt-12'
                  onClick={() => {
                    getNotifications();
                  }}
                >
                  {t({
                    id: 'load_more',
                    message: 'Load more',
                  })}
                </div>
              )}
            </div>
            <div style={{ display: selectedTab == 2 ? 'block' : 'none' }}>
              {loading && !newsList ? (
                <></>
              ) : newsList &&
                newsList.news_list &&
                newsList.news_list.length > 0 ? (
                <div className='news-wrapper p-8'>
                  {newsList.news_list
                    .sort((a: any, b: any) => b.id - a.id)
                    .slice(newsList.news_list.length > 5 ? 5 : 0)
                    .map((item: any) => {
                      return (
                        <div
                          key={item.id}
                          className='cursor'
                          onClick={() => {
                            if (item.external_link) {
                              openExternalUrl(item.external_link);
                            }
                          }}
                        >
                          <NewsAndAnnouncement
                            message={item.description?.description}
                          />
                        </div>
                      );
                    })}
                  <div
                    className='load-more-btn font-aktiv-grotest-medium mt-12'
                    onClick={() => {
                      history.push('/news');
                    }}
                  >
                    {t({
                      id: 'load_more',
                      message: 'Load more',
                    })}
                  </div>
                </div>
              ) : (
                <EmptyData message='No Data Found' messageKey='no_data_found' />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </IonPage>
  );
};

export default PrivateRoute(NotificationsWithTab);
