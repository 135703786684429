import {
  ILocalNotification,
  LocalNotifications,
} from '@ionic-native/local-notifications';

let _singleton = true;
let _instance: LocationNotificationService;

class LocationNotificationService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        'This is a singleton class. Please use LocationNotificationService.instance instead!'
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new LocationNotificationService();
      _singleton = true;
    }
    return _instance;
  }

  public schedule = (options: ILocalNotification | ILocalNotification[]) => {
    LocalNotifications.schedule(options);
  };

  public cancel = (notificationId: any) => {
    LocalNotifications.cancel(notificationId);
  };

  public cancelAll = async () => {
    await LocalNotifications.cancelAll();
  };

  public getAllList = async () => {
    await LocalNotifications.getAll();
  };

  public clickListener = async (cb: any) => {
    LocalNotifications.on('click').subscribe((notification: any) => {
      cb(notification);
    });
  };
}

export default LocationNotificationService.instance;
