import { useContext } from 'react';
import { AppContext } from '../../context/app.context';
import ButtonComponent from '../button-component';
import './style.scss';

interface Props {
  url: string;
  onShareClose: () => void;
}
const ShareBox: React.FC<Props> = (props: Props) => {
  const { url, onShareClose } = props;
  const { toastMessage }: any = useContext(AppContext);
  return (
    <div className='img-popover-inner' style={{ backgroundColor: 'white' }}>
      <h3 className='shareURL_head'>Share URL</h3>
      <div className='shareURL_sec'>
        <div className='shareURL_link'>{url}</div>
        <ButtonComponent
          name={'Copy URL'}
          textKey={'copyurl'}
          customClass='popup-btn'
          onClick={() => {
            navigator.clipboard.writeText(url);
            toastMessage('Link Copied');
            onShareClose();
          }}
        />
      </div>
    </div>
  );
};

export default ShareBox;
