import * as Yup from 'yup';
import Messages from '../config/messages';
export const RequirementSchema = () =>
  Yup.object().shape({
    commodity: Yup.string().required(Messages.COMMODITY_MSG),
    category: Yup.string().required(Messages.CATEGORY_MSG),
    varietyOrBreed: Yup.string().required(Messages.VARIETYORBREED_MSG),
    type: Yup.string().required(Messages.TYPE_OF_COMMODITY_MSG),
    quantity: Yup.string().required(Messages.QUANTITY_MSG),
    unit: Yup.string().required(Messages.UNIT_MSG),
    pinCode: Yup.string().required(Messages.PINCODE_MSG),
    state: Yup.string().required(Messages.STATES_MSG),
    district: Yup.string().required(Messages.DSTRICT_MSG),
    villageOrLocality: Yup.string().required(Messages.VILLAGESORLOCALITY_MSG),
    locationId: Yup.string()
      .not([null], Messages.VILLAGESORLOCALITY_MSG)
      .nullable()
      .required(Messages.VILLAGESORLOCALITY_MSG),
    // price_expected: Yup.string().required(Messages.PRICE_EXPECTED_MSG),

    // unit_price_expected: Yup.string().required(
    //   Messages.UNIT_PRICE_EXPECTED_MSG
    // ),
  });

export const SellRequirementSchema = () =>
  Yup.object().shape({
    commodity: Yup.string().required(Messages.SELL_COMMODITY_MSG),
    category: Yup.string().required(Messages.SELL_CATEGORY_MSG),
    varietyOrBreed: Yup.string().required(Messages.SELL_VARIETYORBREED_MSG),
    type: Yup.string().required(Messages.SELL_TYPE_MSG),
    quantity: Yup.string().required(Messages.QUANTITY_MSG),
    // price_expected: Yup.string().required(Messages.PRICE_EXPECTED_MSG),
    pinCode: Yup.string().required(Messages.SELL_PINCODE_MSG),
    state: Yup.string().required(Messages.SELL_STATES_MSG),
    district: Yup.string().required(Messages.SELL_DISTRICT_MSG),
    villageOrLocality: Yup.string().required(
      Messages.SELL_VILLAGESORLOCALITY_MSG
    ),
    locationId: Yup.string()
      .not([null], Messages.SELL_VILLAGESORLOCALITY_MSG)
      .nullable()
      .required(Messages.SELL_VILLAGESORLOCALITY_MSG),
    logistic: Yup.string().required(Messages.LOGISTIC_MSG),
    images: Yup.array()
      .min(1, Messages.UPLOAD_IMAGE_MSG)
      .required(Messages.UPLOAD_IMAGE_MSG),
  });
