/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useEffect, useState } from 'react';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { t } from '@lingui/macro';
import { getKeyFromString } from '../../utils/common.helper';

interface Props {
  name?: string;
  labelName?: string;
  labelKey?: string;
  customClass?: string;
  customClassTop?: string;
  optionList: any;
  onChange(selectedItem: any): void;
  error?: boolean;
  required?: boolean;
  selectedItem?: string;
  multiple?: boolean;
  isLocalTranslate?: boolean;
}
// const select = document.querySelector('ion-select');

// select.interfaceOptions = {
//   cssClass: 'custom-popover'
// }
const DropDown: React.FC<Props> = ({
  name,
  labelName,
  labelKey,
  customClass,
  customClassTop,
  optionList,
  onChange,
  error,
  selectedItem,
  required,
  multiple,
  isLocalTranslate,
}) => {
  labelKey = labelKey || '';
  labelName = labelName || '';
  const [value, setValue]: any = useState(
    selectedItem
      ? isLocalTranslate
        ? t({
            id: getKeyFromString(selectedItem),
            message: selectedItem,
          })
        : selectedItem
      : null
  );

  useEffect(() => {
    if (selectedItem?.includes('Please Select')) {
      setValue(
        t({
          id: 'please_select',
          message: 'Please Select',
        })
      );
    } else {
      setValue(
        isLocalTranslate
          ? t({
              id: getKeyFromString(selectedItem || ''),
              message: selectedItem,
            })
          : selectedItem
      );
    }
  }, [selectedItem]);
  const options = {
    cssClass: 'my-custom-class',
    size: 'cover',
  };

  return (
    <div className={`ion-field-wrapper ${customClassTop || ''}`}>
      <IonLabel
        className={`ion-label font-aktiv-grotest-regular ${
          error ? 'ion-field-label-error' : ''
        }`}
      >
        {t({ id: labelKey, message: labelName })}
        {required && <span className='mandatory'>*</span>}
      </IonLabel>
      <IonItem
        className={`font-aktiv-grotest-medium ion-field-main-container ion-field-drop-down ${
          error ? 'ion-field-error' : ''
        } ${customClass || ''}`}
      >
        <IonSelect
          name={name}
          // value={value}
          selectedText={value}
          className='dropdown-select'
          interface='popover'
          interfaceOptions={options}
          placeholder={
            isLocalTranslate
              ? selectedItem
                ? t({
                    id: getKeyFromString(selectedItem),
                    message: selectedItem,
                  })
                : optionList && optionList.length
                ? t({
                    id: getKeyFromString(optionList[0].name),
                    message: optionList[0].name,
                  })
                : ''
              : selectedItem
              ? selectedItem
              : optionList && optionList.length
              ? optionList[0].name
              : ''
          }
          onIonChange={(e) => {
            setValue(
              isLocalTranslate
                ? t({
                    id: getKeyFromString(e.detail.value?.name),
                    message: e.detail.value?.name,
                  })
                : e.detail.value?.name
            );
            onChange(e.detail.value);
          }}
          multiple={multiple}
        >
          {optionList.map((item: any, index: any) => {
            return (
              <IonSelectOption
                aria-selected
                className='dropdown-select-option'
                value={item}
                key={index}
              >
                {isLocalTranslate
                  ? t({
                      id: getKeyFromString(item.name),
                      message: item.name,
                    })
                  : item.name}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </div>
  );
};

export default DropDown;
