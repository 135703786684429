/* eslint-disable react/jsx-no-target-blank */
import { IonImg, IonPage, IonPopover } from '@ionic/react';
import ButtonComponent from '../../components/button-component';
import GoogleLogo from '../../assets/images/google-logo.png';
import LogInBack from '../../assets/images/icons/arrow-small-right.png';
import './style.scss';
import InputText from '../../components/inputtext';
import { t, Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import ValidationMessage from '../../components/validationMessage';
import { RegisterOTPSchema, RegisterSchema } from '../../schemas/auth.schema';
import StepBar from '../../components/stepper';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import RegisterForm from '../../components/registerform';
import { i18n } from '@lingui/core';
import Layout from '../../components/layouts';
import ChooseCommodity from '../../components/choosecommodity';
import { mastText } from '../../utils/common.helper';
import { useHistory, useLocation } from 'react-router';
import {
  createUserCommodity,
  getUserDetails,
  profileRegistration,
  registration,
  resendOtp,
  userUsingSolutions,
} from '../../services/user.service';
import { addSocialLogin, verifyOTP } from '../../services/auth.service';
import storageService from '../../services/storage.service';
import {
  ACCESS_TOKEN,
  INIT_PAGE,
  TIME_FOR_RESEND,
} from '../../config/appconfig';
import { AppContext } from '../../context/app.context';
import { uploadFileWeb } from '../../services/filetransfer.service';
import CheckBox from '../../components/checkbox';
import Messages from '../../config/messages';
import ChooseProduct from '../../components/chooseproduct';
import { handleGoogleSingin } from '../../services/firebase.service';
import Loader from '../../components/loader';
let interval: any;

const SignUp: React.FC = () => {
  const history = useHistory();
  const { state }: any = useLocation();

  const [error] = React.useState(null);
  const [step, setStep] = useState(state && state.step ? state.step : 0);
  const [totalStep, setTotalStep] = useState(5);

  const [emailPhone, setEmailPhone] = useState('');
  const [maskEmailPhone, setMaskEmailPhone] = useState('');
  const [userData, setUserData]: any = useState({});
  const [disableEmail, setDisableEmail] = useState(false);
  const [disablePhone, setDisablePhone] = useState(false);
  const [remainingTime, setRemainingTime]: any = useState('01:00');
  const [popoverStateShare, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const [socialLoading, setSocialLoading] = useState(false);
  const {
    setLoading,
    lang,
    setIsLoggedIn,
    toastMessage,
    loading,
    setUserDetails,
  }: any = useContext(AppContext);

  useEffect(() => {
    setStep(state && state.step ? state.step : step);
    const user = (state && state.user) || null;
    if (user) {
      setDisableEmail(user.email);
      setDisablePhone(user.phone_number);
      setUserData({
        mobileNumber: user.phone_number || null,
        email: user.email || null,
      });
    }
  }, [state]);

  const startTimer = (duration: any) => {
    let timer: any = duration;
    let minutes: any, seconds: any;
    clearOTPTimer();
    interval = setInterval(function () {
      const min: any = timer / 60;
      const sec: any = timer % 60;
      minutes = parseInt(min, 10);
      seconds = parseInt(sec, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      if (--timer < 0) {
        timer = duration;
      }
      setRemainingTime(minutes + ':' + seconds);
      if (interval != null && minutes == '00' && seconds == '00') {
        clearInterval(interval);
      }
    }, 1000);
  };

  const clearOTPTimer = () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
      setRemainingTime('01:00');
    }
  };

  const initialValues = {
    email: '',
    tnc: false,
  };
  const initialOTPValues = {
    otp: '',
  };
  const handleRegister = async (values: any, actions: any) => {
    actions.setFieldError(undefined);
    const resp: any = await registration({
      input: values.email.toLowerCase(),
      term_and_condition: values.tnc,
    });
    actions.setSubmitting(false);
    if (!resp.error) {
      setEmailPhone(values.email);
      setMaskEmailPhone(mastText(values.email));
      setStep(1);
      startTimer(TIME_FOR_RESEND);
    } else {
      actions.setFieldError('email', resp.message);
    }
  };

  const handleOtp = async (values: any, actions: any) => {
    actions.setFieldError(undefined);
    const resp: any = await verifyOTP({
      input: emailPhone.toLowerCase(),
      otp: values.otp,
    });
    actions.setSubmitting(false);
    if (!resp.error) {
      clearOTPTimer();
      await storageService.set(ACCESS_TOKEN, resp.token);
      const data: any = {};
      if (!emailPhone.includes('@')) {
        data.mobileNumber = emailPhone;
        setDisablePhone(true);
        setDisableEmail(false);
      } else {
        data.email = emailPhone;
        setDisableEmail(true);
        setDisablePhone(false);
      }
      setUserData(data);
      setStep(2);
    } else {
      actions.setFieldError('otp', resp.message);
    }
  };

  const onResendOTP = async () => {
    if (loading || remainingTime != '00:00') return;
    setLoading(true);
    await resendOtp({
      input: emailPhone.toLowerCase(),
      sendOtp: true,
    });
    setLoading(false);
    startTimer(TIME_FOR_RESEND);
    toastMessage(Messages.RESEND_OTP_MSG);
  };

  const handleGoogleLogin = async (
    values: any,
    setFieldError: any,
    setFieldTouched: any
  ) => {
    if (!values.tnc) {
      setFieldTouched('tnc', true);
      setFieldError('tnc', Messages.CHECK_TNC_MSG);
      return;
    }
    setSocialLoading(true);
    const socialRes = await handleGoogleSingin();
    if (!socialRes.error) {
      const resp: any = await addSocialLogin({
        firebaseToken: socialRes.firebaseToken,
      });
      setSocialLoading(false);
      if (!resp.error) {
        await storageService.set(ACCESS_TOKEN, resp.token);
        const data: any = {};
        data.email = socialRes.email;
        setDisableEmail(true);
        setDisablePhone(false);
        setUserData(data);
        setStep(2);
      } else {
        toastMessage(resp.message || Messages.SERVER_ERROR, 'danger');
      }
    } else {
      setSocialLoading(false);
      // if (!socialRes.message || !socialRes.message.includes('canceled')) {
      //   toastMessage(Messages.SERVER_ERROR, 'danger');
      // }
    }
  };

  const handleUserDetails = async (values: any, actions: any) => {
    setLoading(true);
    // actions.setFieldError('stakeholderCategory', 'From API Error');
    const postData: any = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email ? values.email.toLowerCase() : null,
      landline: values.landlineNumber,
      phone_number: values.mobileNumber,
      location_id: values.locationId,
      stakeholder_id: values.stakeholderCategoryId,
      stakeholder_type_id: values.stakeholderTypeId,
      language_perfernce: lang,
      term_and_condition: true,
      organization: values.nameOfOrganization,
      fpo_registration_number: values.fpoRegNumber,
      fpo_promoted_by: values.fpoPromoBy,
      year_of_registration: values.yearOfReg,
      number_of_farmer_fpo: values.regFarmer,
      annual_turn_over: values.annualTurnover,
      profile_id: values.profilePicId,
    };

    if (
      values.profilePic &&
      values.profilePic != '' &&
      userData.profilePic != values.profilePic
    ) {
      const profileRes: any = await uploadFileWeb(values.profilePic);
      if (!profileRes.error) {
        postData['profile_id'] = profileRes.id;
        values.profilePicId = profileRes.id;
      }
    }
    console.log('postdata', values);
    setUserData(values);
    const resp: any = await profileRegistration(postData);
    actions.setSubmitting(false);
    setLoading(false);
    if (!resp.error) {
      if (values.stakeholderCategoryId == 3) {
        setStep(3);
      } else {
        const userRes = await getUserDetails();
        setIsLoggedIn('true');
        if (!userRes.error) {
          setUserDetails(userRes);
        }
        setShowPopover({ showPopover: true, event: undefined });
      }
    } else {
      try {
        const parsedMsg = JSON.parse(resp.message);
        if (parsedMsg.phone_number) {
          actions.setFieldError('mobileNumber', parsedMsg.phone_number);
        } else if (parsedMsg.email) {
          actions.setFieldError('email', parsedMsg.email);
        } else if (parsedMsg.landline) {
          actions.setFieldError('landlineNumber', parsedMsg.landline);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCommodity = async (commodityData: any) => {
    const finalData = commodityData.map((item: any) => item.id);
    console.log(finalData);
    setLoading(true);
    const resp: any = await createUserCommodity({
      commodity_id: finalData,
    });
    setLoading(false);
    if (!resp.error) {
      setStep(4);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const handleProductSubmit = async (productList: any) => {
    console.log(productList);
    const ids = productList.map((item: any) => item.id);
    const resp = await userUsingSolutions({
      ids,
    });
    if (!resp.error) {
      const userRes = await getUserDetails();
      setIsLoggedIn('true');
      if (!userRes.error) {
        setUserDetails(userRes);
      }
      setShowPopover({ showPopover: true, event: undefined });
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  const handleSkipProduct = async () => {
    const userRes = await getUserDetails();
    setIsLoggedIn('true');
    if (!userRes.error) {
      setUserDetails(userRes);
    }
    setShowPopover({ showPopover: true, event: undefined });
  };

  const handleSkipCommodity = async () => {
    setStep(4);
  };

  const RegisterOTPSchemaMemo: any = useMemo(
    () => RegisterOTPSchema(),
    [i18n.locale]
  );

  const RegisterSchemaMemo: any = useMemo(
    () => RegisterSchema(),
    [i18n.locale]
  );

  return (
    <IonPage className='page-signup page-signup-container'>
      <Layout>
        <div className='page-signup-container-inner page-container'>
          <StepBar totalStep={totalStep} activeStep={step} />
          {
            step == 0 ? (
              <Formik
                enableReinitialize={false}
                initialValues={initialValues}
                validationSchema={RegisterSchemaMemo}
                onSubmit={handleRegister}
                key='email'
              >
                {({
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  setFieldTouched,
                  isSubmitting,
                  setFieldError,
                }) => (
                  <Form className='email-mobile-form'>
                    <>
                      <h2 className='intro-welcome-text mb-40'>
                        {t({
                          id: 'registration',
                          message: 'Registration',
                        })}
                      </h2>
                      {socialLoading ? (
                        <Loader />
                      ) : (
                        <div
                          className='social-login-container mb-32 cursor'
                          onClick={() => {
                            handleGoogleLogin(
                              values,
                              setFieldError,
                              setFieldTouched
                            );
                          }}
                        >
                          {/* <IonImg
                            className='google-login-img mb-32'
                            src={GoogleSignUp}
                          /> */}
                          <div className='google-login-img mb-32 font-aktiv-grotest-medium text-white'>
                            <IonImg
                              style={{ width: 50, height: 45 }}
                              src={GoogleLogo}
                            />
                            <div style={{ marginLeft: 60 }}>
                              {t({
                                id: 'sign_up_google',
                                message: 'Sign up with Google',
                              })}
                            </div>
                          </div>
                          <p className='login-or-text'>
                            {t({
                              id: 'or',
                              message: 'Or',
                            })}
                          </p>
                        </div>
                      )}
                      <div className='input-text-container mb-34'>
                        <Trans
                          id='enter_email_or_mobile'
                          render={({ translation }) => (
                            <InputText
                              labelName='Email / Mobile Number'
                              labelKey='enter_email_or_mobile'
                              placeholder={'' + translation}
                              required
                              value={values.email}
                              onChange={(value: any) => {
                                setFieldValue('email', value);
                                // setFieldTouched('email', true);
                              }}
                              error={Boolean(errors.email && touched.email)}
                            ></InputText>
                          )}
                        >
                          Enter Email / Mobile no
                        </Trans>
                        <ValidationMessage name={'email'} />
                      </div>
                      <div className='intro-term-condition-wrapper mb-32'>
                        <CheckBox
                          // label='I agree to the'
                          // textKey={'i_agree_to_the'}
                          onchange={(isChecked: boolean) => {
                            setFieldValue('tnc', isChecked);
                            setFieldTouched('tnc', true);
                          }}
                        />
                        <p className='pt-5'>
                          {t({
                            id: 'i_agree_to_the',
                            message: 'I agree to the',
                          })}{' '}
                          <a
                            href={`/legal/conditions-of-use`}
                            className='intro-term-condition'
                          >
                            {t({
                              id: 'terms_conditions',
                              message: 'terms & conditions',
                            })}
                          </a>{' '}
                          {t({
                            id: 'and',
                            message: 'and',
                          })}{' '}
                          <a
                            href={`/legal/privacy-statement`}
                            className='intro-term-condition'
                          >
                            {t({
                              id: 'privacy_statement',
                              message: 'privacy statement',
                            })}
                          </a>
                        </p>
                        <ValidationMessage
                          customClass='tnc-error'
                          name={'tnc'}
                        />
                      </div>
                      {error && (
                        <div className='text-center text-danger'>{error}</div>
                      )}
                      <ButtonComponent
                        name={'Sign Up'}
                        customClass='signup-btn-submit mb-16'
                        textKey={'sign_up'}
                        disabled={isSubmitting}
                      />
                      <p className='logIn-now font-aktiv-grotest-medium'>
                        {t({
                          id: 'already_register',
                          message: 'Already registered?',
                        })}{' '}
                        <a href='/login' className='login-register ml-5'>
                          {t({
                            id: 'sign_in_now',
                            message: 'Sign In now',
                          })}
                        </a>
                      </p>
                    </>
                  </Form>
                )}
              </Formik>
            ) : step == 1 ? (
              <>
                <div
                  className='login-otp-back-wrapper otp-back-wrapper-outside mb-24'
                  onClick={() => {
                    clearOTPTimer();
                    setStep(0);
                  }}
                >
                  <IonImg
                    className='login-otp-back-img mr-10'
                    src={LogInBack}
                  ></IonImg>
                  <span className='login-otp-back-txt mt-4'>
                    {t({
                      id: 'back',
                      message: 'Back',
                    })}
                  </span>
                </div>

                <Formik
                  enableReinitialize={false}
                  initialValues={initialOTPValues}
                  validationSchema={RegisterOTPSchemaMemo}
                  onSubmit={handleOtp}
                  key='otp'
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    setFieldTouched,
                    isSubmitting,
                  }) => (
                    <Form className='otp-mobile-form mb-32'>
                      <div className='login-otp-wrapper'>
                        <h2 className='intro-welcome-text mb-40'>
                          {t({
                            id: 'we_just_sent_otp',
                            message: `We just sent a OTP to`,
                          })}
                          <span> {maskEmailPhone}</span>
                        </h2>
                        <div className='input-text-container mb-34'>
                          <Trans
                            id='enter_otp'
                            render={({ translation }) => (
                              <InputText
                                labelName='Enter OTP'
                                labelKey='enter_otp'
                                placeholder={'' + translation}
                                required
                                value={values.otp}
                                onChange={(value: any) => {
                                  setFieldValue('otp', value);
                                  setFieldTouched('otp', true);
                                }}
                                error={Boolean(errors.otp && touched.otp)}
                                maxlength={6}
                                type='number'
                                inputMode={'numeric'}
                              ></InputText>
                            )}
                          >
                            Enter OTP
                          </Trans>
                          <ValidationMessage name={'otp'} />
                        </div>
                        <ButtonComponent
                          name={'Submit'}
                          textKey={'submit'}
                          customClass='login-btn-submit mb-16'
                          disabled={isSubmitting}
                        />
                        <p className='otp-try-again font-aktiv-grotest-medium'>
                          {t({
                            id: 'please_wait_for_few_min',
                            message:
                              'Didnt receive it? Please wait for a few minutes and',
                          })}{' '}
                          <span
                            className={`login-register ml-5 ${
                              remainingTime != '00:00' ? 'disabled-otp' : ''
                            } `}
                            onClick={() => {
                              onResendOTP();
                            }}
                          >
                            {t({
                              id: 'try_again',
                              message: 'Try Again',
                            })}
                          </span>
                          {remainingTime != '00:00' && (
                            <span> {remainingTime}</span>
                          )}
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            ) : step == 2 ? (
              <div>
                <div
                  className='login-otp-back-wrapper back-wrapper-outside mb-24'
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <IonImg
                    className='login-otp-back-img mr-10'
                    src={LogInBack}
                  ></IonImg>
                  <span className='login-otp-back-txt mt-4'>
                    {t({
                      id: 'back',
                      message: 'Back',
                    })}
                  </span>
                </div>
                <div className='registrtation-form-container mb-32'>
                  <RegisterForm
                    data={userData}
                    disableEmail={disableEmail}
                    disablePhone={disablePhone}
                    onSubmit={handleUserDetails}
                    onStakeCatChange={(value: any) => {
                      if (value == 'Farmers') {
                        setTotalStep(5);
                      } else {
                        setTotalStep(3);
                      }
                    }}
                  />
                </div>
              </div>
            ) : step == 3 ? (
              <div>
                <div
                  className='login-otp-back-wrapper back-wrapper-outside mb-24'
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  <IonImg
                    className='login-otp-back-img mr-10'
                    src={LogInBack}
                  ></IonImg>
                  <span className='login-otp-back-txt mt-4'>
                    {t({
                      id: 'back',
                      message: 'Back',
                    })}
                  </span>
                </div>
                <div className='registrtation-commodity-container mb-32'>
                  <ChooseCommodity
                    onSubmit={handleCommodity}
                    onSkip={handleSkipCommodity}
                  />
                </div>
              </div>
            ) : (
              <div className='registrtation-commodity-container mb-32'>
                <ChooseProduct
                  onSubmit={handleProductSubmit}
                  onSkip={handleSkipProduct}
                />
              </div>
            )
            // : (
            //   <div className='registrtation-complete-container'>
            //     <h1 className='thank-you__text font-aktiv-grotest-bold mb-10'>
            //       <Trans id='thank_you'>Thank you!</Trans>
            //     </h1>
            //     <h2 className='thank-you__disc mb-40'>
            //       <Trans id='welcome_to'>
            //         Our Team will review your profile and will get back in touch
            //         with you soon via SMS / E-mail
            //       </Trans>
            //     </h2>
            //     <ButtonComponent
            //       name={'Back to Sign in'}
            //       outline
            //       customClass='back-to-signIn-btn mb-16'
            //       textKey={'back_to_sign_in'}
            //       onClick={() => {
            //         history.replace('/login');
            //       }}
            //     />
            //   </div>
            // )
          }
          <IonPopover
            event={popoverStateShare.event}
            isOpen={popoverStateShare.showPopover}
            onDidDismiss={() => {
              setShowPopover({ showPopover: false, event: undefined });
              history.replace(INIT_PAGE);
            }}
            className='img-popover modal-style'
            // backdropDismiss={false}
          >
            <div
              className='img-popover-inner success-reg-popover'
              style={{ backgroundColor: 'white' }}
            >
              <h3 className='shareURL_head'>
                {t({
                  id: 'congratulations_you_have_successfully_registered',
                  message: Messages.REG_SUCCESS,
                })}
              </h3>
              {/* <a href={INIT_PAGE} className='homepage-link'>Visit homepage</a> */}
              <ButtonComponent
                name={'Visit Homepage'}
                customClass=' mt-16 homepage-link'
                textKey='visit_homepage'
                onClick={() => {
                  setShowPopover({ showPopover: false, event: undefined });
                  history.replace(INIT_PAGE);
                }}
              />
            </div>
          </IonPopover>
        </div>
      </Layout>
    </IonPage>
  );
};

export default SignUp;
