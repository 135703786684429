import { useHistory } from 'react-router';
import ContentComp from '../../components/content/contentcomp';
import {
  getFormattedUrlCMS,
  getRouteForCMS,
  getTagsByModel,
} from '../../utils/common.helper';
import './style.scss';

interface Props {
  data: any;
}
const Landing: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const history = useHistory();

  function onNavClick(route: string) {
    history.push(getRouteForCMS(route));
  }

  return (
    <div
      className='section content-sec pt-0'
      style={{ backgroundColor: 'white' }}
    >
      <div className='page-banner-with-text'>
        {data.cover_image && data.cover_image.length > 0 && (
          <ContentComp
            data={{
              images: data.cover_image,
              unique_key: data.cover_image[0].unique_key,
            }}
          />
        )}
        {data.landing_page_title && (
          <div className='container'>
            <div className='banner-content'>
              <div className='banner-content__inner'>
                {/* <div className='container mt-15'> */}
                <ContentComp
                  data={{
                    title: data.landing_page_title.short_text.title,
                    titleTag: data.landing_page_title.short_text.tag_name,
                    titleCustomClass: 'content-heading',
                    // descCustomClass: 'about-cov-content',
                    description: data.landing_page_title.long_text?.description,
                    descPosition: data.landing_page_title.long_text?.position,
                    unique_key: data.landing_page_title.unique_key,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {data.about_fs_platform_title && (
        <div>
          <div className='container container--medium mt-30'>
            <div className=''>
              <ContentComp
                data={{
                  imagePath: getFormattedUrlCMS(
                    `${data.about_fs_platform_title.images[0]?.hash}${data.about_fs_platform_title.images[0]?.ext}`
                  ),
                  title: data.about_fs_platform_title.title.title,
                  titleTag: data.about_fs_platform_title.title.tag_name,
                  description:
                    data.about_fs_platform_title.description?.description,
                  descPosition:
                    data.about_fs_platform_title.description?.position,
                  descCustomClass: 'desc',
                  unique_key: data.about_fs_platform_title.unique_key,
                  displayType: data.about_fs_platform_title.display_type,
                  titleOnTop: data.about_fs_platform_title.title_on_top,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {
        <div className=' section-about-grid'>
          <div className='container'>
            {data.card_type && data.card_type.length > 0 && (
              <div className='row'>
                {data.card_type.map((item: any) => {
                  const col = data.card_type[0].column
                    ? data.card_type[0].column.trim() == '2'
                      ? '3'
                      : data.card_type[0].column
                    : '1';
                  const tagData = item.redirect_to
                    ? getTagsByModel(item.redirect_to.model)
                    : null;
                  return (
                    <div
                      className={`col col-12 col-sm-6 col-md-${
                        12 / parseInt(col)
                      } mt-24 mb-24 md-mt-15 md-mb-15 ${
                        item.redirect_to ? 'cursor' : ''
                      } `}
                      key={item.id}
                      onClick={() => {
                        if (item.redirect_to) {
                          onNavClick(item.redirect_to.model);
                        }
                      }}
                    >
                      <ContentComp
                        data={{
                          imagePath: getFormattedUrlCMS(
                            `${item.images[0].hash}${item.images[0].ext}`
                          ),
                          title: item.title.title,
                          titleTag: item.title.tag_name,
                          description: item.description?.description,
                          descPosition: item.description?.position,
                          unique_key: item.unique_key,
                          displayType: item.display_type,
                          titleOnTop: item.title_on_top,
                          tagText: tagData && tagData.tagText,
                          tagBgColor: tagData && tagData.tagBgColor,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      }
      {data.qrcode_title && (
        <div>
          <div className='container'>
            <div className=''>
              <ContentComp
                data={{
                  imagePath: getFormattedUrlCMS(
                    `${data.qrcode_title.images[0]?.hash}${data.qrcode_title.images[0]?.ext}`
                  ),
                  title: data.qrcode_title.title.title,
                  titleTag: data.qrcode_title.title.tag_name,
                  description: data.qrcode_title.description?.description,
                  descPosition: data.qrcode_title.description?.position,
                  unique_key: data.qrcode_title.unique_key,
                  displayType: data.qrcode_title.display_type,
                  titleOnTop: data.qrcode_title.title_on_top,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Landing;
