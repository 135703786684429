import { APP_DOMAIN } from '../../../config/appconfig';

const PrivacyEN: React.FC = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        TERMS OF PRIVACY FOR COVESTRO FOOD SECURITY PLATFORM
      </h3>
      <h4 style={{ textAlign: 'center' }}>LAST UPDATED [September 27, 2022]</h4>
      <h3>INTRODUCTION</h3>
      <p>
        Thank you for choosing to be part of our community at Covestro (INDIA)
        PRIVATE LIMITED, earlier known as Bayer MaterialScience Private Limited,
        a private Limited company incorporated under the Companies Act, 1956 and
        having its registered office at Unit No. SB- 801, 8th Floor, Empire
        Tower, Cloud City Campus, Airoli, Thane – Belapur Rd Navi Mumbai Thane
        MH 400708 IN (“Covestro”, “company”, “we”, “us”, or “our”, or ‘the
        Platform’). We are committed to protecting your personal information and
        your right to privacy. If you have any questions or concerns about our
        policy, or our practices with regards to your personal information,
        kindly contact us.
      </p>
      <p>
        This Privacy Policy read together with our Terms &amp; Conditions govern
        your usage and access of the Platform. The terms of use may be accessed
        at{' '}
        <a href={`${APP_DOMAIN}legal/conditions-of-use`}>
          {`${APP_DOMAIN}legal/conditions-of-use`}
        </a>
      </p>
      <p>
        It is requested that every user takes the time to read the Terms of
        Privacy in detail before availing our services, and if there be any
        terms in this privacy policy that you do not agree with, please
        discontinue the use of our website and our services.
      </p>
      <p>
        This privacy policy applies to all information collected through our
        website/app [Food Security Platform], and/or any related services,
        sales, marketing or events (we refer to them collectively in this
        privacy policy as the <b>&quot;Platform&quot;</b>).
      </p>
      <p>
        This Privacy Policy is intended to explain our privacy practices and
        covers the following areas:
      </p>
      <ol>
        <li>
          <h3>WHAT INFORMATION DO WE COLLECT?</h3>
          <ol>
            <li>
              <b>
                <p>
                  <i>Personal information you disclose to us</i>
                </p>
              </b>
              <i>
                <p>
                  <b>Brief:</b> We collect personal information that you provide
                  to us such as name, address, contact information, including
                  but not limited to the Billing Amount.
                </p>
              </i>
              <p>
                We collect personal information that you voluntarily provide to
                us when registering at the Platform, expressing an interest in
                obtaining information about us or our products and services,
                when participating in activities on the Platform or otherwise
                contacting us in the Information given below.
              </p>
              <p>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </p>
              <p>
                The personal information that we collect depends on the context
                of your interactions with us and the Platform, the choices you
                make and the products and services that you avail.
              </p>
              <p>
                The personal information we COLLECT can include the following:
              </p>
              <p>
                <b>Name and Contact Data.</b> We collect your first and last
                name, e-mail address, postal address, phone numbers, and other
                similar contact data that you will provide for accessing the
                services.
              </p>
              <p>
                <b>Credentials.</b> We <b>DO NOT</b> collect passwords, password
                hints, and similar security information used for authentication
                and account access.
              </p>
              <p>
                <b>Payment Data.</b> We collect only such data as is necessary
                to process your payment, to process purchases. All payment data
                is stored by the payment gateway, over which we have limited
                control and you should review its privacy policies and contact
                the payment processor directly to respond to your questions.
              </p>
              <p>
                <b>
                  Test Data, Test Analysis Reports, Surveys, AI predictive
                  models.
                </b>{' '}
                We collect all your data reports, analysis, surveys, crops
                grown, crops to check prices for, products interested in,
                articles and posts liked Predictive models and outcomes and
                other derivative information, etc. from the use of the Platform.
              </p>
              <p>
                <b>Social Media Login Data.</b> We provide you with the option
                to register using social media account details, like your
                Facebook, Twitter or any other social media account. If you
                choose to register in this way, we will collect the following
                Information:
              </p>
              <p>
                User information from social networking Platform, such as
                Facebook, Google+ Instagram, Pinterest, Twitter, LinkedIn,
                including your name, your social network username, location,
                gender, birth date, e-mail address, profile picture, and public
                data for contacts, if you connect your account to such social
                networks. This information may also include the contact
                information of anyone you invite to use and/or join the Platform
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>Information automatically collected</i>
                </p>
              </b>
              <p>
                <i>
                  <b>Breif:</b> Some information – such as IP address and/or
                  browser and device characteristics – is collected
                  automatically when you visit our Platform, for security
                  purposes, and the same cannot be restricted.
                </i>
              </p>
              <p>
                We automatically collect certain information when you visit, use
                or navigate the Platform. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Platform
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Platform,
                and for our internal analytics and reporting purposes.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>Information collected through our Platforms</i>
                </p>
              </b>
              <p>
                <i>
                  <b>Brief:</b> We may collect information regarding your
                  geo-location, mobile device, push notifications, when you use
                  the Platform.
                </i>
              </p>
              <p>
                If you use our Platform, we may also collect the following
                information:
              </p>
              <p>
                - <i>Geo-Location Information.</i> We may request access or
                permission to and track location- based information from your
                mobile device, either continuously or while you are using our
                mobile application, to provide location-based services. If you
                wish to change our access or permissions, you may do so in your
                device’s settings.
              </p>
              <p>
                - <i>Mobile Device Access.</i> We may request access or
                permission to certain features from your mobile device,
                including your mobile device’s [Bluetooth, calendar, camera,
                contacts, microphone, reminders, sensors, SMS messages, social
                media accounts, storage,]IP address; Time zone; Information
                about your mobile service provider, etc. and other features. If
                you wish to change our access or permissions, you may do so in
                your device’s settings.
              </p>
              <p>
                - <i>Mobile Device Data.</i> We may automatically collect device
                information (such as your mobile device ID, model and
                manufacturer), Hardware model; Information about the operating
                system and its version; Unique device identifiers (e.g. IDFA);
                Mobile network information; Device storage information,
                operating system, version information, IP address, etc.
              </p>
              <p>
                - <i>Push Notifications.</i> We may request to send you push
                notifications regarding your account or the mobile application.
                If you wish to opt-out from receiving these types of
                communications, you may turn them off in your device’s settings.
              </p>
              <p>
                - <b>App usage data, including, among others:</b> We may also
                collect the information about the frequency of use; Areas and
                features of our App you visit; Your use patterns generally;
                Engagement with particular features.
              </p>
            </li>
            <li>
              <b>
                <p>
                  <i>Information collected from other Sources</i>
                </p>
              </b>
              <p>
                <i>
                  <b>Brief:</b> We may collect limited data from public
                  databases, marketing partners, social media platforms, and
                  other outside sources.
                </i>
              </p>
              <p>
                We may obtain information about you from other sources, such as
                public databases, joint marketing partners, social media
                platforms (including but not limited to Facebook), as well as
                from other third parties. To collect this information, we may
                also send cookies to your mobile device or computer or engage
                other tracking technologies. See more in our Cookie Policy.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>HOW DO WE USE YOUR INFORMATION?</h3>
          <p>
            <i>
              <b>Brief:</b> We process your information for purposes based on
              legitimate commercial interests and wider societal benefits, the
              fulfillment of our contract with you, compliance with our legal
              obligations, and/or your consent.
            </i>
          </p>
          <p>
            We use anonymous information collected via our Platform for a
            variety of commercial interests and wider societal benefits as
            described below. We process your personal information for these
            purposes in reliance on our legitimate commercial interests and
            wider societal benefits (&quot;commercial and Societal
            Purposes&quot;), in order to enter into or perform a contract with
            you (&quot;Contractual&quot;), with your consent
            (&quot;Consent&quot;), and/or for compliance with our legal
            obligations (&quot;Legal Reasons&quot;). We indicate the specific
            processing grounds we rely on next to each purpose listed below.
          </p>
          <p>
            We use the information we collect or receive for the purposes
            including but not limited to the following:
          </p>
          <p>
            - <b>To facilitate account creation and logon process </b>[with your
            Consent]. If you choose to link your account with us to a
            third-party account such as your Google or Facebook account, we use
            the information you allowed us to collect from those third parties
            to facilitate account creation and logon process. See the section
            below headed &quot;Social Media Login Data&quot; for further
            information.
          </p>
          <p>
            - <b>To send you marketing and promotional communications</b> [for
            commercial and Societal Purposes and/or with your Consent]. We
            and/or our third-party marketing partners may use the personal
            information you send to us for our marketing purposes, if this is in
            accordance with your marketing preferences. You can opt-out of our
            marketing e-mails at any time (see the &quot;Your Privacy
            Rights&quot; below).
          </p>
          <p>
            - <b>To send administrative information to you</b> for commercial
            and Societal Purposes Legal Reasons and/or possibly Contractual. We
            may use your personal information to send you product, service and
            new feature information and/or information about changes to our
            terms, conditions, and policies, while improving the services
            offered to You through the Platform/App;
          </p>
          <p>
            - <b>To analyze, operate, maintain and improve the Platform,</b> to
            add new features and services to the Platform, to support the
            existing functions of the Platform;
          </p>
          <p>
            -{' '}
            <b>
              To customize product and service offerings and recommendations to
              you,
            </b>{' '}
            including third-party products and offerings, to notify you
            regarding your account, to troubleshoot problems with Your account,
            to resolve a dispute, to collect fees or monies owed, to confirm
            Your identity in order to ensure that You are eligible to Use the
            Platform/App;
          </p>
          <p>
            -{' '}
            <b>To provide and deliver the products and services you request,</b>{' '}
            process transactions and send you related information, including
            confirmations and reminders;
          </p>
          <p>
            - <b>For billing (invoicing), account management</b> and other
            administrative purposes, if applicable;
          </p>
          <p>
            - <b>To respond to your comments, questions and requests</b> and to
            provide customer service;
          </p>
          <p>
            - <b>To send you technical notices, updates,</b> security alerts and
            support and administrative messages;
          </p>
          <p>
            - <b>To monitor and analyze trends,</b> usage and activities in
            connection with our Platform;
          </p>
          <p>
            - Solely with respect to information that you agree to share, for
            Covestro <b>promotional purposes;</b>
          </p>
          <p>
            - <b>To verify your identity;</b>
          </p>
          <p>
            - <b>Deliver targeted advertising to you</b> for our Business
            Purposes and/or with your Consent. We may use your information to
            develop and display content and advertising and work with third
            parties who do so tailored to your interests and/or location and to
            measure its effectiveness.
          </p>
          <p>
            - <b>Administer prize draws and competitions</b> for our Business
            Purposes and/or with your Consent. We may use your information to
            administer prize draws and competitions when you elect to
            participate in competitions.;
          </p>
          <p>
            - <b>Request Feedback</b> for our Business Purposes and/or with your
            Consent. We may use your information to request feedback and to
            contact you about your use of our Platform;
          </p>
          <p>
            - <b>To protect our Platform</b> for Business Purposes and/or Legal
            Reasons. We may use your information as part of our efforts to keep
            our Platform safe and secure (for example, for fraud monitoring and
            prevention);
          </p>
          <p>
            - <b>To enforce our terms, conditions and policies</b> for Business
            Purposes, Legal Reasons and/or possibly Contractual;
          </p>
          <p>
            - <b>To respond to legal requests and prevent harm</b> for Legal
            Reasons. If we receive a subpoena or other legal request, we may
            need to inspect the data we hold to determine how to respond;
          </p>
          <p>
            - <b>For other Commercial and Societal Purposes.</b> We may use your
            information for other Business Purposes, such as data analysis,
            identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our Platform and
            your experience; and
          </p>
          <p>
            - <b>For consortium partners.</b> Our consortium partners and users
            registered on the platform may use your information for the products
            and services for which you have shown interest on the platform.
          </p>
        </li>
        <li>
          <h3>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
          <p>
            <i>
              <b>Brief:</b> We only share information with your consent, to
              comply with laws, to protect your rights, or to fulfill business
              obligations.
            </i>
          </p>
          <p>
            We only share and disclose your information in the following
            situations:
          </p>
          <p>
            - <b>Compliance with Laws.</b> We may disclose your information
            where we are legally required to do so in order to comply with
            applicable law, governmental requests, a judicial proceeding, court
            order, or legal process, such as in response to a court order or a
            subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).
          </p>
          <p>
            - <b>Vital Interests and Legal Rights.</b> We may disclose your
            information where we believe it is necessary to investigate,
            prevent, or take action regarding potential violations of our
            policies, suspected fraud, situations involving potential threats to
            the safety of any person and illegal activities, or as evidence in
            litigation in which we are involved.
          </p>
          <p>
            -{' '}
            <b>Vendors, Consultants and Other Third-Party Service Providers.</b>{' '}
            We may share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, e-mail delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Platform,
            which will enable them to collect data about how you interact with
            the Platform over time. This information may be used to, among other
            things, analyze and track data, determine the popularity of certain
            content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your
            information with third parties for their promotional purposes.
          </p>
          <p>
            - <b>Business Transfers.</b> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </p>
          <p>
            - <b>Third-Party Advertisers.</b> We may use third-party advertising
            companies to serve ads when you visit the Platform. These companies
            may use information about your visits to our Platform(s) and other
            Platform that are contained in web cookies and other tracking
            technologies in order to provide advertisements about goods and
            services of interest to you. [See our Cookie Policy [Hyperlink] for
            further information]
          </p>
          <p>
            - <b>Affiliates.</b> We may share your information with our
            affiliates, in which case we will require those affiliates to honor
            this privacy policy. Affiliates include our parent company and any
            subsidiaries, joint venture partners or other companies that we
            control or that are under common control with us.
          </p>
          <p>
            - <b>Business Partners.</b> We may share your information with our
            business partners to offer you certain products, services or
            promotions.
          </p>
          <p>
            - <b>With your Consent.</b> We may disclose your personal
            information for any other purpose with your consent.
          </p>
          <p>
            - <b>Other Users.</b> When you share personal information (for
            example, by posting comments, contributions or other content to the
            Platform) or otherwise interact with public areas of the Platform
            such personal information may be viewed by all users and may be
            publicly distributed outside the platform in perpetuity. [If you
            interact with other users of our Platform and register through a
            social network (such as Facebook), your contacts on the social
            network will see your name, profile photo, and descriptions of your
            activity.] Similarly, other users will be able to view descriptions
            of your activity, communicate with you within our Platform, and view
            your profile.
          </p>
        </li>
        <li>
          <h3>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
          <p>
            <i>
              <b>Brief:</b> We may use cookies and other tracking technologies
              to collect and store your information.
            </i>
          </p>
          <p>
            As any compliant organisation, we also collect information through
            cookies and similar technologies, that helps us enhance your
            experience and our products and services. The cookies that we use
            allow our Platform to work and help us to understand what
            information and advertising is most useful to visitors.
          </p>
          <p>
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Platform.
          </p>
          <p>UserCentrics Technology (Consent Tool)</p>
          <p>
            To obtain effective user consent for cookies that require consent
            and for cookie-based applications, our website uses a cookie consent
            tool with technology developed by Usercentrics GmbH, Sendlinger Str.
            7, 80331 Munich (hereafter referred to as “Usercentrics”).
          </p>
          <p>
            By integrating a corresponding JavaScript code, users are shown a
            banner when they visit the page, in which consent for certain
            cookies and/or cookie-based applications can be given by checking
            boxes. The tool blocks the setting of all cookies that require
            consent until the respective user gives consent by checking the
            corresponding boxes. This ensures that such cookies are only set on
            the respective end device of the user when consent has been given.
          </p>
          <p>
            The settings can be changed by the user at any time by pressing the
            UserCentrics button - at the bottom right of the website.
          </p>
          <p>
            When our website is accessed, specific user information (including
            IP address) is collected by the cookie consent tool, transmitted to
            the servers of Usercentrics and saved there so that the cookie
            consent tool can clearly assign page views to individual users and
            individually record, log and save for the duration of the session
            the consent settings made by the user.
          </p>
          <p>
            You can find additional information on the use of data by
            Usercentrics in the Usercentrics privacy policy{' '}
            <a
              href='https://usercentrics.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              https://usercentrics.com/privacy-policy/
            </a>
          </p>
          <p>
            Do let us know if you have any questions by sending us an e-mail or
            submitting a request through the “Contact Us” hereinbelow.
          </p>
        </li>
        <li>
          <h3>DO WE USE LOCATION SERVICES?</h3>
          <p>
            <i>
              <b>Brief:</b> Yes, we use Location Services, either in-built
              program or an external provider such as Google Maps for the
              purpose of providing better service.
            </i>
          </p>
          <p>
            The Information collected shall be used for purposes of this policy
            alone, and the terms and conditions of usage of such service
            provider are beyond our scope and control.
          </p>
        </li>
        <li>
          <h3>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
          <p>
            <i>
              <b>Brief:</b> We may transfer, store, and process your information
              in countries other than your own.
            </i>
          </p>
          <p>
            We engage processors that perform particular operations with your
            Personal Data for us. Processors are companies that help us run the
            Platform, support our communication with you or perform other
            Platform-related activities. They may process certain Personal Data
            on our behalf to accomplish the goals related to the Webiste and App
            functions and associated activities. Processors act only in
            accordance with our instructions and process only such amount of
            Personal Data as we instruct them to process. We remain fully liable
            for any acts or omissions of our processors and undertake to execute
            formal data processing agreements with them to the extent required
            by applicable law.
          </p>
          <p>
            Our main processor is Amazon Web Services Ince (AWS). Amazon Web
            Services, Inc. is located in the United States, and their affiliated
            companies are located throughout the world. Depending on the scope
            of our interactions with AWS Offerings, the personal information may
            be stored in or accessed from multiple countries, including the
            United States. Whenever AWS transfer personal information to other
            jurisdictions, it ensures that the information is transferred in
            accordance with its Privacy Notice{' '}
            <a
              href='https://aws.amazon.com/privacy/'
              target='_blank'
              rel='noreferrer'
            >
              (https://aws.amazon.com/privacy/)
            </a>{' '}
            and as permitted by applicable data protection laws.
          </p>
          <p>
            In addition, you agree that Personal Data collected may be stored
            and processed in countries, where Covestro rents servers, or in any
            other country in which Covestro or its affiliates, subsidiaries or
            agents maintain facilities, and by using the Platform, you consent
            to any such transfer of Personal Data outside of your country.
          </p>
        </li>
        <li>
          <h3>WHAT IS OUR STANCE ON THIRD-PARTY PLATFORM?</h3>
          <p>
            <i>
              <b>Brief:</b> We are not responsible for the safety of any
              information that you share with third-party providers who
              advertise, and not affiliated with, our Platform.
            </i>
          </p>
          <p>
            The Platform may contain links and advertisements from third parties
            that are not affiliated with us, and which may link to other
            Platforms and App, online services or mobile applications. We cannot
            guarantee the safety and privacy of data you provide to any third
            parties through access to such advertisements.
          </p>
          <p>
            Any data collected by third parties is not covered by this privacy
            policy.
          </p>
          <p>
            We shall not be responsible for the content or privacy and security
            practices and policies of any third parties, including other
            Platform, services or applications that may be linked to or from the
            Platform. You should review the policies of such third parties and
            contact them directly to respond to your questions.
          </p>
        </li>
        <li>
          <h3>HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
          <p>
            <i>
              <b>Brief:</b> We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy policy unless
              otherwise required by law.
            </i>
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy policy, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            policy will require us keeping your personal information for a
            duration longer than the period of time in which you have an account
            with us (or)1 year after the termination of your account, whichever
            is later.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize it, or, if
            this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store
            your personal information and isolate it from any further processing
            until deletion is possible.
          </p>
        </li>
        <li>
          <h3>HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
          <p>
            <i>
              <b>Brief:</b> We aim to protect your personal information through
              a system of organizational and technical security measures.
            </i>
          </p>
          <p>
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process or receive. Due care and protection are
            undertaken to determine and identify any breach, for which
            appropriate corrective action will be initiated. However, we do
            inform you although there are requisite safeguards implemented,
            there may be potential risks owing to the technological interface.
          </p>
          <p>
            Although we will do our best to protect your personal information,
            transmission of personal information to and from our Platform is at
            your own risk. You should only access the services within a secure
            environment.
          </p>
        </li>
        <li>
          <h3>DO WE COLLECT INFORMATION FROM MINORS?</h3>
          <p>
            <i>
              <b>Brief:</b> We do not knowingly collect data from or market to
              children under 16 years of age.
            </i>
          </p>
          <p>
            <b>General age limitation.</b> We are committed to protecting the
            privacy of children. The Platform is not intended for children, and
            we do not intentionally collect information about children under 16
            years old. The Platform does not collect Personal Data from any
            person Covestro knows is under the age of 16. If you are aware of
            anyone under 16 using the Platform, please contact us at{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            and we will take the required steps to delete such information and
            (or) delete his/her account.
          </p>
          <p>
            <b>Age limitation for the European Union residents.</b> Due to legal
            requirements you shall be at least 16 years old to use the Webiste
            and App. This website is not meant for any European Union residents.
            If you or any user you know is a European Union Resident and are
            using the Platform, please contact us at{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>{' '}
            and we will take steps to delete such information and (or) delete
            his/her account.
          </p>
        </li>
        <li>
          <h3>WHAT ARE YOUR PRIVACY RIGHTS?</h3>
          <p>
            <i>
              <b>Brief:</b> In some regions, such as the European Economic Area,
              you have rights that allow you greater access to and control over
              your personal information. You may review, change, or terminate
              your account at any time.
            </i>
          </p>
          <p>
            In some regions (like the European Economic Area), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. To make such a request, please use the contact details
            provided below [HYPERLINK]. However, we reserve the right to
            consider and act upon any request in accordance with applicable data
            protection laws.
          </p>
          <p>
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness
            of the processing of information before its withdrawal.
          </p>
          <p>
            If you are resident in the European Economic Area and you believe we
            are unlawfully processing your personal information, you are
            requested to contact us.
          </p>
          <ol>
            <li>
              <p>
                <i>
                  <b>Account Information</b>
                </i>
              </p>
              <p>
                You may at any time review or change the information in your
                account or terminate your account by:
              </p>
              <p>
                - Logging into your account settings and updating your account;
                or
              </p>
              <p>
                - Contacting us using the contact information provided below
              </p>
              <p>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active databases
                in accordance with the Policy. However, some information may be
                retained in our files to prevent fraud, troubleshoot problems,
                assist with any investigations, enforce our Terms of Use and/or
                comply with legal requirements.
              </p>
            </li>
            <li>
              <p>
                <i>
                  <b>Governing Law and Dispute Resolution</b>
                </i>
              </p>
              <p>
                In case of any disputes under this Policy shall be subject to
                the Laws of India, and the Courts of Mumbai, Maharashtra shall
                have exclusive jurisdiction for the same.
              </p>
              <p>
                - In the event any dispute or difference arises in connection
                with the interpretation, implementation or purported termination
                of this Policy as specified above, the Parties shall attempt in
                the first instance to resolve such dispute through friendly
                consultations.
              </p>
              <p>
                - If such dispute is not resolved through friendly consultations
                within 30 (Thirty) days after commencement of discussions or
                such longer period as the Parties agree to in writing, then any
                Party may refer the dispute for resolution by arbitration in
                accordance with the terms of this Agreement.
              </p>
              <p>
                - All such disputes shall be referred to and finally resolved by
                arbitration pursuant to the Arbitration and Conciliation Act,
                1996 thereof, amendment or re-enactment thereto from time to
                time and under the Rules of the Indian Council of Arbitration
                (“ICA”), which Rules are deemed to be incorporated by reference
                into this Clause. The tribunal shall consist of three
                arbitrators. Each of the disputing Party shall appoint one
                arbitrator and the two arbitrators so appointed shall
                collectively appoint the third arbitrator. The language of the
                arbitration shall be English.
              </p>
              <p>- The place of arbitration shall be Mumbai, India.</p>
              <p>
                - The arbitration award shall be final and binding on the
                Parties and the Parties agree to be bound thereby and to act
                accordingly
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>DO WE MAKE UPDATES OR CHANGES TO THIS POLICY?</h3>
          <p>
            <i>
              <b>Brief:</b> Yes, we will update this policy as necessary to stay
              compliant with relevant laws.
            </i>
          </p>
          <p>
            We may update this privacy policy from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible.
          </p>
          <p>
            If we make material changes to this privacy policy, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting
            your information.
          </p>
        </li>
        <li>
          <h3>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
          <p>
            If you have questions or comments about this policy, e-mail us at{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>
          </p>
          <p>
            If you have any further questions or comments about us or our
            policies, e-mail us at{' '}
            <a href='mailto:foodsecurity.platform@covestro.com'>
              foodsecurity.platform@covestro.com
            </a>
            .
          </p>
        </li>
      </ol>
    </div>
  );
};
export default PrivacyEN;
