import { t } from '@lingui/macro';
import { ErrorMessage } from 'formik';
import React from 'react';
import { getKeyFromString } from '../../utils/common.helper';

interface Props {
  name?: any;
  message?: string;
  customClass?: string;
}

const ValidationMessage: React.FC<Props> = ({ name, message, customClass }) => {
  const renderMsg = (msg: string) => {
    const textKey = getKeyFromString(msg);

    console.log('normal', textKey, msg);

    return (
      <div>
        {t({
          id: textKey,
          message: Array.isArray(msg) ? msg[0] : msg,
        })}
      </div>
    );
  };
  return (
    <div className={`validationError ${customClass || ''}`}>
      <div className='text-danger mt-1'>
        {message ? (
          renderMsg(message)
        ) : (
          <ErrorMessage
            name={name}
            render={(errorMessage: string) => {
              return renderMsg(errorMessage);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ValidationMessage;
