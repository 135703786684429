/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import {
  IonCol,
  IonGrid,
  IonImg,
  IonPopover,
  IonRow,
  IonTextarea,
  useIonViewWillLeave,
} from '@ionic/react';
import CDPlaceholder from '../../assets/images/cd-placeholder.png';
import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';
import ProfileImage from '../profileimg';
import {
  getFormattedUrl,
  getLanguageText,
  getUserInitial,
  toFirstUpperCaseWord,
} from '../../utils/common.helper';
import { AppContext } from '../../context/app.context';
import moment from 'moment';
import CommentSection from '../commentsection';
import RespondBox from '../respondbox';
import Loader from '../loader';
import NoPreviewPlacholder from '../../assets/images/no_preview.jpg';
import MoreIcon from '../../assets/images/icons/More-icon.png';
import { useHistory } from 'react-router';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
interface Props {
  postType: string;
  userId?: number;
  customClass?: string;
  images?: any;
  productDescription?: string;
  productName?: any;
  productCategory?: any;
  ListedOn?: string;
  productVarietyBreed?: string;
  productQuantity?: string;
  productUnit?: any;
  expectedPrice?: string;
  logisticRequired?: boolean;
  productType?: string;
  farmerName?: string;
  farmerLocation?: string;
  farmerCity?: string;
  farmerState?: string;
  farmerPinCode?: string;
  certification?: any;
  showLogistic?: boolean;
  farmerPic?: string;
  productId: number;
  commentList?: any;
  isActive: boolean;
  postStatus: string;
  commentLoading: boolean;
  messengerList?: any;
  rejectReason?: string;
  extendedAt?: string;
  extended?: boolean;
  // onSubmit?: (commentText: string, postId: number) => Promise<any>;
  updateComment?: (commentList: any, postId: any) => void;
  onRespondSubmit?: (
    commentText: string,
    postId: number,
    toUserId: number
  ) => Promise<any>;
  onSelectUser?: (selectedUser: any) => Promise<any>;
  onExtendExpiry?: () => Promise<any>;
}
let isOptionVisible = false;

const CommodityDetailCard: React.FC<Props> = ({
  postType,
  userId,
  showLogistic,
  customClass,
  images,
  productDescription,
  productName,
  productCategory,
  ListedOn,
  productVarietyBreed,
  productQuantity,
  productUnit,
  expectedPrice,
  logisticRequired,
  productType,
  farmerName,
  farmerLocation,
  farmerCity,
  farmerState,
  farmerPinCode,
  certification,
  farmerPic,
  productId,
  commentList,
  isActive,
  postStatus,
  commentLoading,
  messengerList,
  rejectReason,
  extendedAt,
  extended,
  // onSubmit,
  updateComment,
  onRespondSubmit,
  onSelectUser,
  onExtendExpiry,
}) => {
  const { lang, userDetails }: any = useContext(AppContext);
  const [currentImage, setCurrentImage] = useState(
    images ? images[0].uri : null
  );

  let certText = null;

  if (certification) {
    const certArray = certification.map((certItem: any) => {
      return getLanguageText(
        certItem.certificate_name,
        'name',
        certItem.locale,
        lang
      );
    });

    if (certArray && certArray.length > 0) {
      certText = certArray.join(', ');
    }
  }
  const [cert] = useState(certText);
  const [selectedIndex, setSelectedIndex]: any = useState(
    messengerList && messengerList.length > 0 ? messengerList[0] : {}
  );
  const [popoverStateOption, setShowPopoverOption] = useState({
    showPopover: false,
    event: undefined,
  });
  const [imagePopoverState, setimagePopoverState] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isExpirySubmitting, setIsExpirySubmitting] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setCurrentImage(images ? images[0].uri : null);
  }, [images]);
  const [popoverStateRespond, setShowPopoverRepond] = useState({
    showPopover: false,
    event: undefined,
  });
  const [respondText, setRespondText]: any = useState(
    t({
      id: 'post_contact',
      message: 'Hi, I am interested in your post',
    })
  );
  const [isResponding, setIsResponding] = useState(false);
  const [isExtended, setIsExtended] = useState(extended);
  useIonViewWillLeave(() => {
    setShowPopoverOption({ showPopover: false, event: undefined });
    setShowPopoverRepond({ showPopover: false, event: undefined });
    setimagePopoverState({ showPopover: false, event: undefined });
  }, []);

  useEffect(() => {
    setIsExtended(extended);
  }, [extended]);
  return (
    <div className={`cd-card-wrapper p-16 ${customClass || ''}`}>
      <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
        <ProfileImage
          userInitial={getUserInitial(farmerName || '')}
          customClass='cd-card-profile-img'
          imgPath={farmerPic ? getFormattedUrl(farmerPic) : undefined}
        />

        <div className='profile-details ml-10'>
          <h1 className='post-profile-name mb-5'>
            <span className='farmer-name mr-5 font-aktiv-grotest-bold'>
              {toFirstUpperCaseWord(farmerName || '')}
            </span>
          </h1>
        </div>
        <div className='post-card-time-wrapper'>
          {postStatus == 'pending' && userDetails.id == userId && (
            <IonImg
              src={MoreIcon}
              className='card-more-icon cursor'
              onClick={(e: any) => {
                isOptionVisible = true;
                setShowPopoverOption({ showPopover: true, event: e });
              }}
            />
          )}
        </div>
      </div>
      <IonGrid className='p-0'>
        <IonRow className='ion-row'>
          <IonCol size-xs='12' size-sm='6' className='ion-col p-0'>
            <IonImg
              src={
                currentImage
                  ? getFormattedUrl(currentImage)
                  : postType == 'buy'
                  ? NoPreviewPlacholder
                  : CDPlaceholder
              }
              className={`cd-card-image mb-16 ${currentImage ? 'cursor' : ''}`}
              onClick={() => {
                currentImage
                  ? setimagePopoverState({
                      showPopover: true,
                      event: undefined,
                    })
                  : null;
              }}
            />
            {images && images.length > 0 && (
              <div className='uploaded-product-images'>
                {images.map((item: any, index: number) => {
                  return (
                    <IonImg
                      key={index}
                      src={getFormattedUrl(item.uri)}
                      className='uploaded-image mr-16'
                      onClick={() => {
                        setCurrentImage(item.uri);
                      }}
                    />
                  );
                })}
              </div>
            )}
            {productDescription && productDescription != '' && (
              <>
                <p className='product-description-name font-aktiv-grotest-bold mt-32'>
                  {t({ id: 'description', message: 'Description' })} :
                </p>
                <p className='product-description'>{productDescription}</p>
              </>
            )}
          </IonCol>
          <IonCol size-xs='12' size-sm='6' className='ion-col p-0'>
            <div className='cd-card-details ml-20 mr-64'>
              <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({ id: 'commodity', message: 'Commodity' })}
                </p>
                <p className='product-detail-disc'>
                  {' '}
                  :{' '}
                  {getLanguageText(
                    productName.title,
                    'title',
                    productName.locale,
                    lang
                  )}
                </p>
              </div>
              <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({ id: 'listed_on', message: 'Listed on' })}
                </p>
                <p className='product-detail-disc'>
                  {' '}
                  : {moment(ListedOn).format('DD/MM/yyyy')}
                </p>
              </div>
              <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({ id: 'category', message: 'Category' })}
                </p>
                <p className='product-detail-disc'>
                  {' '}
                  :{' '}
                  {getLanguageText(
                    productCategory.name,
                    'name',
                    productCategory.locale,
                    lang
                  )}
                </p>
              </div>
              <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({ id: 'variety_breed', message: 'Variety / Breed' })}
                </p>
                <p className='product-detail-disc'> : {productVarietyBreed}</p>
              </div>
              <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({ id: 'quantity_unit', message: 'Quantity & Unit' })}
                </p>
                <p className='product-detail-disc'>
                  {' '}
                  : <span>{productQuantity}</span> <span>{productUnit}</span>
                </p>
              </div>
              {/* <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'price_expected_ex_farm',
                    message: 'Price Expected Ex-Farm',
                  })}{' '}
                </p>
                <p className='product-detail-disc font-aktiv-grotest-bold'>
                  {' '}
                  : <span>₹ {expectedPrice}/- </span> per{' '}
                  <span>{productUnit}</span>
                </p>
              </div> */}
              {showLogistic && (
                <div className='card-product-detail mb-18'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'logistic_required',
                      message: 'Logistic required',
                    })}
                  </p>
                  <p className='product-detail-disc'>
                    {!logisticRequired ? (
                      <span>
                        {' '}
                        :{' '}
                        {t({
                          id: 'no',
                          message: 'No',
                        })}
                      </span>
                    ) : (
                      <span>
                        {' '}
                        :{' '}
                        {t({
                          id: 'yes',
                          message: 'Yes',
                        })}
                      </span>
                    )}
                  </p>
                </div>
              )}
              <div className='card-product-detail mb-18'>
                <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                  {t({
                    id: 'type',
                    message: 'Type',
                  })}
                </p>
                <p className='product-detail-disc'>
                  :{' '}
                  {productType
                    ? toFirstUpperCaseWord(
                        t({ id: productType, message: productType })
                      )
                    : ''}
                </p>
              </div>
              {cert && (
                <div className='card-product-detail mb-18'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'certification',
                      message: 'Certification',
                    })}
                  </p>
                  <p className='product-detail-disc'> : {cert}</p>
                </div>
              )}
              {postStatus != 'accepted' && (
                <div className='card-product-detail mb-18'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'status',
                      message: 'Status',
                    })}{' '}
                    :
                  </p>
                  <p
                    className={`product-detail-disc ${
                      postStatus == 'pending'
                        ? 'post-status-pending'
                        : 'post-status-rejected'
                    }`}
                  >
                    {postStatus
                      ? toFirstUpperCaseWord(
                          t({
                            id: postStatus,
                            message: postStatus,
                          })
                        )
                      : ''}
                  </p>
                </div>
              )}

              {postStatus == 'accepted' && !isActive && (
                <div className='card-product-detail mb-18'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'status',
                      message: 'Status',
                    })}{' '}
                    :
                  </p>
                  <p
                    className={`product-detail-disc ${'post-status-rejected'}`}
                  >
                    {toFirstUpperCaseWord(
                      t({
                        id: 'de_activated',
                        message: 'De-activated',
                      })
                    )}
                  </p>
                </div>
              )}
              {postStatus == 'rejected' && rejectReason && rejectReason != '' && (
                <div className='card-product-detail mb-18'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'reject_reason',
                      message: 'Reject Reason',
                    })}{' '}
                    :
                  </p>
                  <p className='product-detail-disc'>{rejectReason}</p>
                </div>
              )}
            </div>
            {userDetails.id != userId ? (
              <>
                <div className='card-product-detail label-contact ml-20 mb-16'>
                  <p className='product-detail-name font-aktiv-grotest-bold mr-5'>
                    {t({
                      id: 'contact',
                      message: 'Contact',
                    })}{' '}
                    :
                  </p>
                </div>
                <div className='cd-card-profile-details-wrapper ml-20 mb-20'>
                  <ProfileImage
                    userInitial={getUserInitial(farmerName || '')}
                    customClass='cd-card-profile-img mr-24'
                    imgPath={farmerPic ? getFormattedUrl(farmerPic) : undefined}
                  />
                  <p className='farmer-details'>
                    <h4 className='farmer-name font-aktiv-grotest-bold'>
                      {toFirstUpperCaseWord(farmerName || '')}
                    </h4>
                    <span className='farmer-detail-text'>
                      {farmerLocation},{' '}
                    </span>
                    <span className='farmer-detail-text'>{farmerCity}, </span>
                    <span className='farmer-detail-text'>{farmerState}, </span>
                    <span className='farmer-detail-text'>
                      {farmerPinCode},{' '}
                    </span>
                  </p>
                </div>
                {commentList && commentList.length > 0 ? (
                  <></>
                ) : (
                  <ButtonComponent
                    name={postType == 'buy' ? 'Contact' : 'Contact'}
                    customClass='cd-card-contact-supplier ml-20'
                    textKey={postType == 'buy' ? 'contact' : 'contact'}
                    onClick={() => {
                      setShowPopoverRepond({
                        showPopover: true,
                        event: undefined,
                      });
                    }}
                  />
                )}
              </>
            ) : isActive && postStatus == 'accepted' && !isExtended ? (
              <div className='expiry-container mt-10 mb-10'>
                <span>
                  {t({
                    id: 'post_expiry_msg',
                    message: 'Your post will get expired on ',
                  })}
                  {extendedAt && moment(extendedAt).format('DD/MM/yyyy')}
                </span>
                <ButtonComponent
                  name={'Extend Expiry'}
                  disabled={isExpirySubmitting}
                  customClass='expiry-btn mt-10'
                  textKey={'extend_expiry'}
                  onClick={async () => {
                    if (onExtendExpiry) {
                      setIsExpirySubmitting(true);
                      const res = await onExtendExpiry();
                      if (res) {
                        setIsExtended(true);
                      }
                      setIsExpirySubmitting(false);
                    }
                  }}
                />
              </div>
            ) : null}
          </IonCol>
        </IonRow>
        {isActive && postStatus == 'accepted' && (
          <div className='mt-30'>
            {commentLoading ? (
              <Loader />
            ) : (
              commentList &&
              commentList.length > 0 && (
                <CommentSection
                  userId={userId}
                  isPrivateChat
                  messengerList={messengerList}
                  postId={productId}
                  maxCount={2}
                  commentList={commentList || []}
                  isLoadMore={commentList && commentList.length > 2}
                  onSubmit={async (commentText: string) => {
                    if (onRespondSubmit) {
                      const resp = await onRespondSubmit(
                        commentText,
                        productId,
                        userDetails.id != userId
                          ? userId
                          : selectedIndex.user_id
                      );

                      return resp;
                    } else {
                      return false;
                    }
                  }}
                  updateComment={(commentList: any, postId: any) => {
                    if (updateComment) {
                      updateComment(commentList, postId);
                    }
                  }}
                  showMessanger={
                    userDetails.id == userId && messengerList.length > 0
                  }
                  onSelectUser={async (selectedUser: any) => {
                    setSelectedIndex(selectedUser);
                    if (onSelectUser) {
                      return await onSelectUser(selectedUser);
                    }
                  }}
                />
              )
            )}
          </div>
        )}
      </IonGrid>
      <IonPopover
        event={popoverStateRespond.event}
        isOpen={popoverStateRespond.showPopover}
        onDidDismiss={() => {
          setShowPopoverRepond({ showPopover: false, event: undefined });
          setRespondText(
            t({
              id: 'post_contact',
              message: 'Hi, I am interested in your post',
            })
          );
        }}
        className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <RespondBox
          isSubmitting={isResponding}
          respondText={respondText}
          onRepondChange={(text) => {
            setRespondText(text);
          }}
          onRespondCancel={() => {
            setShowPopoverRepond({ showPopover: false, event: undefined });
            setRespondText(
              t({
                id: 'post_contact',
                message: 'Hi, I am interested in your post',
              })
            );
          }}
          onRespondSubmit={async () => {
            if (onRespondSubmit && userId) {
              setIsResponding(true);
              const resp = await onRespondSubmit(
                respondText,
                productId,
                userId
              );
              if (resp) {
                if (updateComment) {
                  updateComment([resp], productId);
                }
                setShowPopoverRepond({ showPopover: false, event: undefined });
                setRespondText(
                  t({
                    id: 'post_contact',
                    message: 'Hi, I am interested in your post',
                  })
                );
              }
              setIsResponding(false);
            }
          }}
        />
      </IonPopover>
      <IonPopover
        event={popoverStateOption.event}
        isOpen={popoverStateOption.showPopover}
        onDidDismiss={() => {
          isOptionVisible = false;
          setShowPopoverOption({ showPopover: false, event: undefined });
        }}
        alignment='end'
        dismissOnSelect
        showBackdrop={false}
        // className='img-popover modal-style'
        // backdropDismiss={false}
      >
        <div
          className='font-aktiv-grotest-regular cursor p-10 edit-option'
          onClick={() => {
            history.push(`/editpost/${productId}`);
          }}
        >
          {t({ id: 'edit', message: 'Edit' })}
        </div>
      </IonPopover>
      <IonPopover
        event={imagePopoverState.event}
        isOpen={imagePopoverState.showPopover}
        onDidDismiss={() => {
          setimagePopoverState({ showPopover: false, event: undefined });
        }}
        className='img-popover modal-style modal-style--lg'
        // backdropDismiss={false}
      >
        <div className='img-popover-inner' style={{ backgroundColor: 'white' }}>
          <TransformWrapper>
            <TransformComponent>
              <IonImg
                className='modal-style__img'
                src={
                  currentImage ? getFormattedUrl(currentImage) : CDPlaceholder
                }
              ></IonImg>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </IonPopover>
    </div>
  );
};

export default CommodityDetailCard;
