/* eslint-disable no-unused-vars */
import React from 'react';
import { IonButton, IonLabel, IonRippleEffect } from '@ionic/react';
import './style.scss';
import { t } from '@lingui/macro';

interface Props {
  name: string;
  outline?: boolean;
  disabled?: boolean;
  onClick?(e: any): void;
  customClass?: string;
  textKey?: any;
  // metaData?: any;
}

const ButtonComponent: React.FC<Props> = ({
  name,
  outline,
  disabled,
  onClick,
  customClass,
  textKey,
}) => {
  textKey = textKey || 'save';
  name = name || 'Save';
  return (
    <IonButton
      className={`font-aktiv-grotest-medium ${
        outline ? 'ion-button__btnoutline' : 'ion-button__btnfill'
      } ${customClass || ''}`}
      //expand="block"
      type='submit'
      disabled={disabled}
      onClick={(e: any) => {
        onClick && onClick(e);
      }}
    >
      <IonRippleEffect type='unbounded'></IonRippleEffect>
      <IonLabel>{t({ id: textKey, message: name })}</IonLabel>
    </IonButton>
  );
};

export default ButtonComponent;
