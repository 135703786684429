/* eslint-disable no-unused-vars */
import './style.scss';
import React, { useContext, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonLabel, IonRadioGroup, IonRow } from '@ionic/react';
import { t } from '@lingui/macro';
import ButtonComponent from '../button-component';
import CommodityCard from '../commoditycard';
import CommodityChip from '../commoditychip';
import Radio from '../radio';
import Autocomplete from '../autocomplete';
import { AppContext } from '../../context/app.context';
import { getCommodityListByFilter } from '../../services/commodity.service';
import { getFormattedUrl, getLanguageText } from '../../utils/common.helper';
import { getCommodityByUserId } from '../../services/user.service';

interface Props {
  fromProfile?: any;
  onSubmit: (commodityData: any) => void;
  onSkip?: () => void;
}
const ChooseCommodity: React.FC<Props> = (props) => {
  const { fromProfile, onSubmit, onSkip } = props;
  const { setLoading, lang }: any = useContext(AppContext);
  const [selected, setSelected] = useState<string>('All');
  const [commodity, setCommodity]: any = useState([]);
  const [searchCommodityList, setSearchCommodityList]: any = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const onRadioChange = (e: any) => {
    const type = e.detail.value;
    setSelected(type);
  };

  useEffect(() => {
    setLoading(true);
    getCommodity();
  }, []);

  async function getCommodity() {
    const resp: any = await getCommodityListByFilter('images');
    if (!resp.error) {
      if (fromProfile) {
        const userCommodity = await getCommodityByUserId();
        if (!userCommodity.error && userCommodity && userCommodity.length > 0) {
          const selectedSearchCommodity: any = [];
          resp.map((item: any) => {
            const isChecked = userCommodity.find(
              (ui: any) => ui.commodity_id == item.id
            );
            item.isChecked = isChecked;
            if (isChecked) {
              selectedSearchCommodity.push(item.title);
            }
          });
          setSearchCommodityList(selectedSearchCommodity);
        }
      }
      setCommodity(resp);
    } else {
      //error
    }
    setLoading(false);
  }
  const onSearchCommodity = (value: string) => {
    const selectedCommodity = commodity.find((item: any) => {
      return item.title.trim() == value.trim();
    });
    if (selectedCommodity) {
      selectedCommodity['isChecked'] = true;
      setSearchCommodityList([...searchCommodityList, [value]]);
      // setCommodity(commodity);
    }
  };

  const onChipRemove = (value: string) => {
    const selectedCommodity = commodity.find((item: any) => {
      return item.title.trim() == value;
    });
    if (selectedCommodity) {
      selectedCommodity['isChecked'] = false;
      const temp = searchCommodityList.filter((item: any) => {
        return value != item;
      });

      setSearchCommodityList(temp);
    }
  };

  const onCommodityCardCheckChanged = (isChecked: boolean, value: string) => {
    const selectedCommodity = commodity.find((item: any) => {
      return getLanguageText(item.title, 'title', item.locale, lang) == value;
    });

    if (
      selectedCommodity &&
      (selectedCommodity['isChecked'] == undefined ||
        selectedCommodity['isChecked'] != isChecked)
    ) {
      selectedCommodity['isChecked'] = isChecked;
      if (isChecked) {
        setSearchCommodityList([...searchCommodityList, [value]]);
      } else {
        const temp = searchCommodityList.filter((item: any) => {
          return value != item;
        });

        setSearchCommodityList(temp);
      }
      // setSearchCommodityList([...searchCommodityList, [value]]);
      setCommodity(commodity);
    }
  };

  const onCommoditySubmit = async () => {
    setSubmitting(true);
    const finalData = commodity.filter((item: any) => item.isChecked);
    await onSubmit(finalData);
    setSubmitting(false);
  };
  const getCommodityListItem = () => {
    let data = commodity;
    if (selected.toLowerCase() !== 'all') {
      data = commodity.filter(
        (item: any) => item.type.toLowerCase() == selected.toLowerCase()
      );
    }

    return data.map((item: any, index: any) => {
      return (
        <IonCol size-xs='4' size-md='3' className='mb-5' key={index}>
          <CommodityCard
            label={getLanguageText(item.title, 'title', item.locale, lang)}
            imgPath={
              item.image_pic_obj ? getFormattedUrl(item.image_pic_obj?.uri) : ''
            }
            isChecked={item.isChecked}
            onCheckChanged={onCommodityCardCheckChanged}
          />
        </IonCol>
      );
    });
  };

  const getSearchCommodityList = () => {
    return searchCommodityList.map((item: any, index: any) => {
      return (
        <CommodityChip
          label={item}
          key={index}
          onRemove={(value: string) => {
            onChipRemove(value);
          }}
        />
      );
    });
  };

  const onSkipClick = async () => {
    if (onSkip) {
      onSkip();
    }
  };

  return (
    <div className='commodity-container'>
      <div className='choose-commodity-wrapper'>
        <IonGrid>
          <div className='commodity-choose-wrapper mb-5 pb-5'>
            <IonLabel className='commodity-title font-aktiv-grotest-medium'>
              {t({ id: 'choose_commodity', message: 'Choose Commodity' })}
            </IonLabel>

            <div className='choose-commodity-checkbox font-aktiv-grotest-medium'>
              <IonRadioGroup
                value={selected}
                onIonChange={onRadioChange}
                className='choose-commodity-radio'
              >
                <Radio
                  label='Fresh'
                  textKey={'fresh'}
                  customClass='filter-checkbox mr-20'
                />
                <Radio
                  label='Dehydrated'
                  textKey={'dehydrated'}
                  customClass='filter-checkbox mr-20'
                />
                <Radio
                  label='All'
                  textKey={'all'}
                  customClass='filter-checkbox'
                />
              </IonRadioGroup>
            </div>
          </div>
          <IonRow className='ion-row'>{getCommodityListItem()}</IonRow>
        </IonGrid>
        <IonLabel className='commodity-search font-aktiv-grotest-medium mt-32 mb-5'>
          {t({ id: 'search_commodity_and_add', message: 'Add Commodity' })}
        </IonLabel>
        <div className='input-textarea p-8'>
          {getSearchCommodityList()}
          <Autocomplete
            suggestions={
              selected != 'All'
                ? commodity.filter(
                    (item: any) =>
                      item.type.toLowerCase() == selected.toLowerCase()
                  )
                : commodity
            }
            onItemSelected={(value: string) => onSearchCommodity(value)}
          />
        </div>
      </div>

      <div className='commodity-btn-wrapper mt-24'>
        <ButtonComponent
          name={'Submit'}
          customClass='choose-commodity-btn'
          textKey={'submit'}
          onClick={onCommoditySubmit}
          disabled={
            submitting ||
            commodity.filter((item: any) => item.isChecked).length == 0
          }
        />
        {onSkip && (
          <ButtonComponent
            name={'Skip'}
            customClass='choose-commodity-btn ml-24'
            textKey={'skip'}
            onClick={onSkipClick}
            disabled={
              commodity.filter((item: any) => item.isChecked).length > 0
            }
          />
        )}
      </div>
    </div>
  );
};

export default ChooseCommodity;
