/* eslint-disable no-unused-vars */

import { t } from '@lingui/macro';
import { Component, Fragment } from 'react';
import InputText from '../inputtext';
import './style.scss';

interface Props {
  name?: string;
  labelName?: string;
  labelKey?: string;
  placeholderName?: string;
  placeholderKey?: string;
  customClass?: string;
  value?: any;
  placeholder?: string;
  type?: any;
  error?: boolean;
  maxlength?: any;
  onSuggestionChange?: (val: string) => void;
  suggestions: Array<any>;
  onItemSelected: (value: string) => void;
  debounce?: number;
  inputMode?: string;
  required?: boolean;
  showSuggestionOnFocus?: boolean;
}

interface State {
  // The active selection's index
  activeSuggestion: number;
  // The suggestions that match the user's input
  filteredSuggestions: Array<any>;
  // Whether or not the suggestion list is shown
  showSuggestions: boolean;
  // What the user has entered
  userInput: string;
  firstLoad: boolean;
}
class AutocompleteInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: '',
      firstLoad: true,
    };
  }

  onChange = (value: string) => {
    if (this.state.userInput == value) return;
    const { suggestions } = this.props;
    const userInput = value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: value,
      firstLoad: false,
    });
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.suggestions != this.props.suggestions) {
      const filteredSuggestions = this.props.suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(this.state.userInput.toLowerCase()) >
          -1
      );

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
      });
    }
  }

  // componentDidMount() {
  //   this.setState({
  //     firstLoad: false,
  //   });
  // }
  onClick = (e: any) => {
    if (e.currentTarget.innerText) {
      this.props.onItemSelected(e.currentTarget.innerText);
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: e.currentTarget.innerText || '',
      });
    }
  };

  onKeyDown = (e: any) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      if (filteredSuggestions[activeSuggestion]) {
        this.props.onItemSelected(filteredSuggestions[activeSuggestion]);

        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion] || '',
        });
      }
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;
    const {
      name,
      labelName,
      labelKey,
      customClass,
      value,
      placeholder,
      type,
      error,
      maxlength,
      onSuggestionChange,
      debounce,
      inputMode,
      required,
      showSuggestionOnFocus,
      placeholderKey,
      placeholderName,
    } = this.props;
    let suggestionsListComponent;

    if (
      showSuggestions &&
      !this.state.firstLoad &&
      (showSuggestionOnFocus || userInput.trim().length > 0)
    ) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className='suggestions mt-0 p-8'>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      }
      //  else {
      //   suggestionsListComponent = (
      //     <div className='no-suggestions'>
      //       <em>No suggestions</em>
      //     </div>
      //   );
      // }
    }

    return (
      <Fragment>
        <div className='autocomplete-wrapper'>
          {/* <Trans
            id='type'
            render={({ translation }) => (
              <IonInput
                className={`input-text-select blinking-cursor`}
                onIonChange={onChange}
                onKeyDown={onKeyDown}
                value={userInput}
                //autofocus
                placeholder={translation?.toString()}
              ></IonInput>
            )}
          >
            Type
          </Trans> */}
          <InputText
            name={name}
            labelName={labelName}
            labelKey={labelKey}
            placeholder={t({
              id: placeholderKey || labelKey,
              message: placeholderName || labelName,
            })}
            placeholderKey={placeholderKey}
            placeholderName={placeholderName}
            required={required}
            //value={""}
            value={value}
            onChange={(value: any) => {
              if (userInput != value) {
                onSuggestionChange && onSuggestionChange(value);
                onChange(value);
              }
            }}
            onKeyDown={onKeyDown}
            error={Boolean(error)}
            type={type}
            maxlength={maxlength}
            debounce={debounce}
            inputMode={inputMode || 'text'}
            onFocus={() => {
              this.setState({
                showSuggestions: true,
                firstLoad: false,
              });
            }}
            // onBlur={() => {
            //   this.setState({
            //     showSuggestions: false,
            //   });
            // }}
          ></InputText>
          {suggestionsListComponent}
        </div>
      </Fragment>
    );
  }
}

export default AutocompleteInput;
