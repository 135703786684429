import { APP_DOMAIN, FIREBASE_WEB_API_KEY } from '../config/appconfig';
import Messages from '../config/messages';
import { initializeApp, getApps } from 'firebase/app';
import 'firebase/auth';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
} from 'firebase/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';

export const firebaseInit = () => {
  if (getApps().length === 0) {
    initializeApp({
      apiKey: 'AIzaSyBf3s3F2twkIF57R6YcCJKFhD87DShApIc',
      authDomain: 'covestro-food-security.firebaseapp.com',
      projectId: 'covestro-food-security',
      storageBucket: 'covestro-food-security.appspot.com',
      messagingSenderId: '194838697009',
      appId: '1:194838697009:web:ad196bae7bb17e8ec8f110',
    });
  }
};

export const googleInit = () => {
  GoogleAuth.initialize({
    clientId:
      '194838697009-g7gladmksiatssj7mjt4u1pniga2eq2g.apps.googleusercontent.com',
    scopes: ['profile', 'email'],
    grantOfflineAccess: true,
  });
};

export async function getDynamicLinkForWeb(link: string) {
  try {
    const promise = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_WEB_API_KEY}`,
      {
        method: 'POST', // POST, PUT, DELETE, etc.
        headers: {
          // the content type header value is usually auto-set
          // depending on the request body
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: 'https://covestrofoodsecurity.page.link',
            link: `${APP_DOMAIN}${link}`,
            androidInfo: {
              androidPackageName: 'com.covestro.foodsecurity',
              androidFallbackLink: `${APP_DOMAIN}${link}`,
            },
          },
        }), // string, FormData, Blob, BufferSource, or URLSearchParams
        referrer: '', // or "" to send no Referer header,
        // or an url from the current origin
        referrerPolicy: 'no-referrer-when-downgrade', // no-referrer, origin, same-origin...
        mode: 'cors', // same-origin, no-cors
        credentials: 'same-origin', // omit, include
        cache: 'default', // no-store, reload, no-cache, force-cache, or only-if-cached
        redirect: 'follow', // manual, error
        integrity: '', // a hash, like "sha256-abcdef1234567890"
        keepalive: false, // true
        signal: undefined, // AbortController to abort request
      }
    );

    if (promise.status == 200) {
      return await promise.json();
    } else {
      return {
        error: true,
        message: Messages.SERVER_ERROR,
      };
    }
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export const webGoogleSignIn = async () => {
  try {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const resp: any = await signInWithPopup(auth, provider);
    console.log(resp);

    const credential = GoogleAuthProvider.credentialFromResult(resp);
    console.log(credential);

    // const credentialResp = resp.credential;
    // const idToken = credentialResp.idToken;
    // const credential = await GoogleAuthProvider.credential(idToken);
    const respSocial: any = await webSocialLogin(credential);
    return respSocial;
  } catch (e: any) {
    return {
      error: true,
      message: e.message,
    };
  }
};

const webSocialLogin = async (credential: any) => {
  const respSignIn = await signInWithCredential(getAuth(), credential);
  console.log('respSignIn', respSignIn);

  const firebaseToken = await getAuth().currentUser?.getIdToken();
  console.log('firebaseToken', firebaseToken);

  return { firebaseToken, email: respSignIn.user.email };
};

const mobileGoogleLogin = async () => {
  try {
    await GoogleAuth.signOut();
    const res: any = await GoogleAuth.signIn();
    console.log('first', res);

    const idToken = res.authentication.idToken;
    const credential = GoogleAuthProvider.credential(idToken);
    console.log('second', credential);

    const respSocial: any = await webSocialLogin(credential);
    return respSocial;
  } catch (e: any) {
    return {
      error: true,
      message: e.message,
    };
  }
};

export const handleGoogleSingin = async () => {
  let resp: any = null;
  if (Capacitor.getPlatform() == 'web') {
    resp = await webGoogleSignIn();
  } else {
    resp = await mobileGoogleLogin();
  }
  return resp;
};
