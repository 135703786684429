import './style.scss';
import { IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layouts';
import { getContent } from '../../services/about.service';
import { AppContext } from '../../context/app.context';
import Messages from '../../config/messages';
import ContentComp from '../../components/content/contentcomp';
import { getFormattedUrlCMS, openExternalUrl } from '../../utils/common.helper';

const SubsidyOrLoanList: React.FC = () => {
  const { toastMessage, loading, setLoading }: any = useContext(AppContext);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [entry, setEntry]: any = useState(null);
  useEffect(() => {
    setLoading(true);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const fetchContents = async () => {
    if (isContentLoaded) return;

    setIsContentLoaded(true);
    const resp = await getContent('subsidy/loan');
    setIsContentLoaded(false);

    if (!resp.error) {
      const entry = JSON.parse(resp.entry);
      setEntry(entry);
    } else {
      toastMessage(Messages.SERVER_ERROR, 'danger');
    }
  };

  return (
    <IonPage className='page-subsidy-or-loan-list'>
      <Layout>
        {loading && !entry ? (
          <div></div>
        ) : !entry ? (
          <div></div>
        ) : (
          <div className='section content-sec pt-0'>
            <div className='page-banner-with-text'>
              {entry.bannerimage && entry.bannerimage.length > 0 && (
                <ContentComp
                  data={{
                    images: entry.bannerimage,
                    unique_key: entry.bannerimage[0].unique_key,
                  }}
                />
              )}

              {entry.text_with_title && (
                <div className='container'>
                  <div className='banner-content'>
                    <div className='banner-content__inner'>
                      <ContentComp
                        data={{
                          title: entry.text_with_title.short_text.title,
                          titleTag: entry.text_with_title.short_text.tag_name,
                          titleCustomClass: 'content-heading',
                          description:
                            entry.text_with_title.long_text?.description,
                          descPosition:
                            entry.text_with_title.long_text?.position,
                          unique_key: entry.text_with_title.unique_key,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className=' section-about-grid'>
              <div className='container'>
                {entry.subsidy_or_loan_list &&
                  entry.subsidy_or_loan_list.length > 0 && (
                    <div className='row'>
                      {entry.subsidy_or_loan_list.map((item: any) => {
                        const col = entry.subsidy_or_loan_list[0].column
                          ? entry.subsidy_or_loan_list[0].column.trim() == '2'
                            ? '3'
                            : entry.subsidy_or_loan_list[0].column
                          : '1';

                        return (
                          <div
                            className={`col col-12 col-sm-6 col-md-${
                              12 / parseInt(col)
                            } mt-24 mb-24 md-mt-15 md-mb-15 ${
                              item.external_link ? 'cursor' : ''
                            } `}
                            key={item.id}
                            onClick={() => {
                              if (item.external_link) {
                                openExternalUrl(item.external_link);
                              }
                            }}
                          >
                            <ContentComp
                              data={{
                                imagePath: item.images
                                  ? getFormattedUrlCMS(
                                      `${item.images[0].hash}${item.images[0].ext}`
                                    )
                                  : null,
                                title: item.title.title,
                                titleTag: item.title.tag_name,
                                description: item.description?.description,
                                descPosition: item.description?.position,
                                unique_key: item.unique_key,
                                displayType: item.display_type,
                                titleOnTop: item.title_on_top,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </Layout>
    </IonPage>
  );
};

export default SubsidyOrLoanList;
