import { IonPage } from '@ionic/react';
import { t } from '@lingui/macro';
import ButtonComponent from '../../components/button-component';
import DropDown from '../../components/dropdown';
import './style.scss';
import Layout from '../../components/layouts';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/app.context';
import { Form, Formik } from 'formik';
import { getLanguages } from '../../services/lang-descriptor.service';

const Intro: React.FC = ({ history }: any) => {
  const { setLoading, setSelectedLang, lang }: any = useContext(AppContext);
  const [languageList, setLanguageList] = useState([
    {
      name: 'English',
      value: 'en',
    },
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const initialValues = {
    language: 'en',
  };

  useEffect(() => {
    getLangList();
  }, []);

  useEffect(() => {
    const item = languageList.find((i) => i.value === lang);
    setSelectedLanguage(item?.name || 'English');
  }, [lang, languageList]);
  async function getLangList() {
    setLoading(true);
    const resp: any = await getLanguages();
    if (!resp.error) {
      const formatedData = resp.map((item: any) => {
        return {
          name: item.title,
          value: item.code,
        };
      });
      setLanguageList(formatedData);
    }
    setLoading(false);
  }
  const handleSubmit = async (values: any, actions: any) => {
    console.log(values);
    actions.setFieldError(undefined);

    // setLoading(true);
    // await setSelectedLang(values.language);
    // setLoading(false);

    history.push('/login');
  };

  return (
    <IonPage className='page-intro'>
      <Layout>
        <div className='page-intro-container page-container'>
          <h2 className='intro-welcome-text'>
            {t({ id: 'welcome_to', message: 'Welcome to' })}
          </h2>
          <h1 className='intro-covestro-text font-aktiv-grotest-bold mb-40'>
            {t({
              id: 'covestro_food_security',
              message: 'Covestro Food Security Digital Platform',
            })}
          </h1>
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className='intro-language-selector mb-32'>
                  <DropDown
                    labelName='Select your language'
                    labelKey='select_your_language'
                    optionList={languageList}
                    selectedItem={selectedLanguage}
                    onChange={async (item: any) => {
                      setFieldValue('language', item.value);
                      setSelectedLanguage(item.name);
                      setLoading(true);
                      await setSelectedLang(item.value);
                      setLoading(false);
                    }}
                    customClass=''
                  />
                </div>

                <ButtonComponent
                  name={'Submit'}
                  customClass='intro-btn-submit'
                  textKey={'submit'}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Layout>
    </IonPage>
  );
};

export default Intro;
