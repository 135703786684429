import { APP_DOMAIN } from '../../../config/appconfig';

const TNCTE: React.FC = () => {
  //   const tableCotent = [
  //     {
  //       title: 'ACCEPTANCE OF THE TERMS AND CONDITIONS OF ACCESS AND USAGE',
  //       page: '5',
  //     },
  //     {
  //       title: 'THIRD-PARTY SERVICES',
  //       page: '6',
  //     },
  //     {
  //       title: 'ACCESS AND USE',
  //       page: '6',
  //     },
  //     {
  //       title: 'MEMBERSHIP/REGISTRATION AND ELIGIBILITY',
  //       page: '7',
  //     },
  //     { title: 'TERMS OF USE OF THE PLATFORM', page: '9' },
  //     { title: 'RESTRICTION ON USE OF SERVICES', page: '10' },
  //     { title: 'CONTENT', page: '11' },
  //     { title: 'LIMITED LICENSE TO THE APP', page: '12' },
  //     { title: 'BREACH OF CONTENT STANDARDS', page: '13' },
  //     { title: 'BILLING AND PAYMENT', page: '14' },
  //     { title: 'INDEMNITY AND LIMITATION OF LIABILITY', page: '14' },
  //     { title: 'OFFENCES', page: '16' },
  //     { title: 'ADDITIONAL TERMS FOR THE MOBILE APP', page: '17' },
  //     { title: 'AUTHORISED ACCESS', page: '17' },
  //     { title: 'CONFIDENTIALITY', page: '19' },
  //     { title: 'PRIVACY AND PROTECTION OF PERSONAL DATA', page: '19' },
  //     { title: 'INTELLECTUAL PROPERTY', page: '20' },
  //     { title: 'DISCLAIMER OF WARRANTIES', page: '21' },
  //     { title: 'USER SUBMISSION AND USER CONTENT BY THIRD PARTIES', page: '23' },
  //     { title: 'WAIVER AND SEVERABILITY', page: '24' },
  //     { title: 'USE OF MOBILE DEVICES', page: '24' },
  //     { title: 'PROHIBITED ACTIONS', page: '25' },
  //     { title: 'GOVERNING LAWS', page: '25' },
  //     { title: 'JURISDICTION', page: '25' },
  //     { title: 'MODIFICATION OF TERMS AND THE PLATFORM', page: '26' },
  //     { title: 'USE AT YOUR OWN RISK', page: '26' },
  //     { title: 'OPINIONS AND FEEDBACK', page: '26' },
  //   ];
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        కోవెస్ట్రో ఫుడ్ సెక్యూరిటీ ప్లాట్‌ఫారం యాక్సెస్, వినియోగానికి సంబంధించిన
        నిబంధనలు, షరతులు
      </h3>
      {/* {tableCotent.map((item: any, index: number) => {
        return (
          <div key={index} className='table-row '>
            <div className='first-col'>{index + 1}.</div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <div style={{ flex: 0.1, textAlign: 'right' }}>{item.page}</div>
          </div>
        );
      })}
      <h4 style={{ textAlign: 'center', marginTop: 40 }}>
        TERMS AND CONDITIONS OF ACCESS AND USAGE FOR COVESTRO FOOD SECURITY
        PLATFORM
      </h4> */}
      <h4 style={{ textAlign: 'center' }}>
        చివరిగా సవరించబడినది [జూలై 28, 2022]
      </h4>
      <p>
        కోవెస్ట్రో (ఇండియా) ప్రైవేట్ లిమిటెడ్‌లోని మా కమ్యూనిటీలో మీరు భాగం
        కావడాన్ని ఎంపిక చేసుకున్నందుకు ధన్యవాదాలు, ఈ కంపెనీనే ఇంతకుముందు బేయర్
        మెటీరియల్ సైన్స్ ప్రైవేట్ లిమిటెడ్ అని పిలిచేవారు, ఇది కంపెనీల చట్టం,
        1956 కింద పొందుపరచబడిన ప్రైవేట్ లిమిటెడ్ కంపెనీ, అలాగే సంస్థ రిజిస్టర్డ్
        కార్యాలయం యూనిట్ నంబర్. SB-801, 8వ అంతస్తు, ఎంపైర్ టవర్, క్లౌడ్ సిటీ
        క్యాంపస్, ఐరోలి, థానే - బేలాపూర్ రోడ్, నవీ థానే మహారాష్ట్ర 400708 ఇండియా
        వద్ద ఉన్నది. (“కోవెస్ట్రో”, “కంపెనీ”, “మేము”, “మమ్మల్ని”, లేదా “మా”,
        లేదా &quot;ప్లాట్‌ఫారం&quot; ).
      </p>
      <p>
        ఈ దిగువన పేర్కొనబడిన నిబంధనలు &quot;యూజర్&quot; (ఇకపై &quot;మీరు&quot;
        లేదా &quot;కస్టమర్&quot; అని కూడా సూచించబడతారు), కోవెస్ట్రో మధ్య గల
        ఒప్పంద సంబంధాన్ని నియంత్రిస్తాయి. &quot;యూజర్‌లు&quot; లేదా
        &quot;మీరు&quot; అనే పదానికి ప్లాట్‌ఫారం‌ను సక్రమంగా నమోదు చేసి,
        ఉపయోగిస్తున్న వ్యక్తి అంటే వినియోగదారులు, రైతులుమరియు/లేదా రైతు
        సంఘాలు/సమూహాలు అని అర్థం అనే విషయాన్ని ఈ సందర్భంగా స్పష్టం చేయబడింది.
      </p>
      <p>
        యూజర్లకు అందించడానికి వీలుగా కోవెస్ట్రో ఈ వెబ్‌సైట్/యాప్ [ఫుడ్
        సెక్యూరిటీ ప్లాట్‌ఫారం] మరియు/లేదా ఏదైనా సంబంధిత సేవలు, విక్రయాలు,
        మార్కెటింగ్ లేదా ఈవెంట్‌లను (ఈ విధానంలో ఇలాంటి వాటిని సమష్టిగా
        &quot;ప్లాట్‌ఫారం&quot; లేదా &quot;వెబ్‌సైట్/యాప్&quot;గా సూచిస్తాము)
        సృష్టించింది, ఆర్థిక పథకాలు లేదా విధానాలు, ఆహారం & వ్యవసాయ దిగుబడులు,
        ఉత్పత్తులపై మార్గదర్శకత్వంతో పాటు వివిధ థర్డ్-పార్టీ వెండర్‌లకు యూజర్లకు
        యాక్సెస్‌ను అందించడం వంటి ఉత్పత్తులు, సేవలను కలిగి ఉంటుంది. యూజర్లకు
        అందించే సిఫార్సులలో అనేక అంశాలను పరిగణనలోకి తీసుకోబడతాయి, ముఖ్యంగా
        యూజర్లకు అందించే సమాచారం ప్రతీ ఒక్క యూజర్‌కు నిర్దిష్టంగా ఉంటుంది, అయితే
        ఈ సందర్భంగా సూచించే ఏదైనా ఆర్థిక పథకాలు లేదా విధానాలు, అలాగే
        ప్లాట్‌ఫారం‌లో అందుబాటులో ఉండే ఆహారం, వ్యవసాయ దిగుబడులు, ఉత్పత్తుల
        నాణ్యత లేదా ప్రామాణికతకు మేము హామీ ఇవ్వలేమనే విషయాన్ని పునరుద్ఘాటిస్తూ,
        స్పష్టంగా పేర్కొంటున్నాము. అసలు ఈ ప్లాట్‌ఫారం అనేది ఆన్‌లైన్ మార్కెట్
        ప్లేస్‌గా పనిచేసే వంతెన మాత్రమేనని ఈ సందర్భంగా మేము స్పష్టం
        చేస్తున్నాము.
      </p>
      <p>
        మీరు ఈ ప్లాట్‌ఫారం‌ని ఉపయోగించడం అంటే మీరు ఈ కింద పేర్కొనబడిన నిరాకరణ,
        నిబంధనలు & షరతులు (&quot;నిబంధనలు & షరతులు&quot;) చదివి, అర్థం
        చేసుకున్నారని, అలాగే వాటికి కట్టుబడి ఉండటానికి అంగీకరించారని
        సూచించడమైనది. మీ వినియోగాన్ని, ప్లాట్‌ఫారం యాక్సెస్‌ను నియంత్రించే మా
        గోప్యతా విధానంతో పాటుగా ఈ నిబంధనలు & షరతులు చదవాల్సిందిగా
        సూచిస్తున్నాము. ఈ నిబంధనలు & షరతులలో ఏదైనా ఒక అంశంతో మీరు విభేదిస్తే,
        దయచేసి ప్లాట్‌ఫారాన్ని ఉపయోగించవద్దు లేదా యాక్సెస్ చేయవద్దు.
      </p>
      <ol>
        <li>
          <h3>ప్రాప్యత, వినియోగానికి సంబంధించిన నిబంధనలు, షరతుల అంగీకారం</h3>
          <ol>
            <li>
              <p>
                ఒకవేళ మా సేవల్లో దేనికైనా సభ్యత్వం చేసుకోవడం లేదా ఉపయోగించడం
                ద్వారా మీరు మా సేవలను ఎలా సభ్యత్వం పొందినా లేదా ఎలా
                ఉపయోగించుకున్నా, మీరు నిబంధనలను చదివి, అర్థం చేసుకున్నారని,
                వాటికి కట్టుబడి ఉన్నారని ఈ సందర్భంగా మీరు అంగీకరిస్తున్నారు.
                మీరు నిబంధనలకు కట్టుబడి ఉండకూడదనుకుంటే, మీరు మా సేవలకు సభ్యత్వం
                పొందకూడదు లేదా వాటిని మీరు ఉపయోగించకూడదు. ఇన్ఫర్మేషన్ టెక్నాలజీ
                చట్టం 2000 ప్రకారం రూపొందించబడిన ఇన్ఫర్మేషన్ టెక్నాలజీ
                (మధ్యవర్తుల మార్గదర్శకాలు) రూల్స్, 2011లోని నిబంధనల ప్రకారం ఈ
                నిబంధనలు, అనేక ఇతర విధానాల మేరకు కట్టుబడి ఉంటాయి.
              </p>
            </li>
            <li>
              <p>
                ఈ నిబంధనలు మీకు, కోవెస్ట్రోకి ఎంతో ముఖ్యమైనవి ఎందుకంటే అవి ఎంతో
                విలువైన ఒక కస్టమర్‌గా మీ హక్కులతో పాటు ఒక వ్యాపారంగా మా హక్కులను
                రక్షించడానికి ఉపయోగించబడతాయి.
              </p>
            </li>
            <li>
              <p>
                కోవెస్ట్రో ద్వారా ఏదైనా ప్రకటించబడి, రాయబడి, ముందస్తుగా
                వెల్లడించబడిన విషయంలో మినహా ఇతర అన్ని సందర్భాలలో ఈ షరతులు
                వర్తిస్తాయి, ఇతర షరతులను భర్తీ చేస్తాయి.
              </p>
            </li>
            <li>
              <p>
                మీరు ప్లాట్‌ఫారం ద్వారా కోవెస్ట్రో అందించిన సేవలలో దేనినైనా
                ఉపయోగించినప్పుడు, ఉత్పత్తి సమీక్షలు, విక్రేత సమీక్షలు, సంభావ్య
                విక్రేతల సంప్రదింపు వివరాలను స్వీకరించడం మొదలైన వాటికే పరిమితం
                కాకుండా, అటువంటి సేవకు వర్తించే నిబంధనలు, షరతులకు సంబంధించిన
                నియమాలు, మార్గదర్శకాలు, విధానాలకు మీరు లోబడి ఉంటారు. అవి ఈ ఉపయోగ
                నిబంధనలలో చేర్చబడినట్లు భావించబడతాయి, అంతేగాకుండా ఈ ఉపయోగ
                నిబంధనలలో భాగంగా,పార్సల్‌గా అవి పరిగణించబడతాయి.
              </p>
            </li>
            <li>
              <p>
                మీకు ఎలాంటి ముందస్తు రాతపూర్వక నోటీసు లేకుండా ఏ సమయంలోనైనా ఈ
                ఉపయోగ నిబంధనలను సవరించడంతో పాటు వాటిని నిలిపివేసే హక్కులను మేము
                కలిగివున్నాము. సవరణలు / మార్పుల కోసం కాలానుగుణంగా ఈ ఉపయోగ
                నిబంధనలను ఎప్పటికప్పుడు సమీక్షించడం మీ బాధ్యత అవుతుంది. ఒకసారి
                మార్పులను పోస్ట్ చేసిన తర్వాత మీరు ప్లాట్‌ఫారాన్ని నిరంతరం
                ఉపయోగించడం వలన మీరు పునర్విమర్శలను ఒప్పుకుని, అంగీకరిస్తున్నట్లు
                భావించడమైనది. సేవా నిబంధనలకు సంబంధించి సరికొత్త అత్యంత తాజా
                సంస్కరణలను ఇక్కడ మీరు సమీక్షించుకోవచ్చని మేము
                నిర్ధారిస్తున్నాము:{' '}
                <a
                  href={`${APP_DOMAIN}legal/conditions-of-use`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/conditions-of-use`}
                </a>
              </p>
            </li>
            <li>
              <p>
                మీరు ఈ ఉపయోగ నిబంధనలకు కట్టుబడి ఉన్నంత వరకు, ప్లాట్‌ఫారం‌లోకి
                ప్రవేశించడానికి, ఉపయోగించడానికి మేము మీకు వ్యక్తిగత, ప్రత్యేకం
                కాని, బదిలీ చేయలేని, పరిమిత అధికారాన్ని మంజూరు చేస్తాము.
              </p>
            </li>
            <li>
              <p>
                యూరోపియన్ యూనియన్ జనరల్ డేటా ప్రొటెక్షన్ రెగ్యులేషన్ (GDPR)కి
                అనుగుణంగా ఉండేలా మేము మా విధానాన్ని అప్‌డేట్ చేశాము కొత్త విధాన
                మార్పుల ప్రకారం, మీ స్వంత డేటాపై మీకు మరింత నియంత్రణ ఉంటుంది,
                ఇది మీ కోసం మరింత సురక్షితంగా, భద్రంగా కాపాడబడుతుంది.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>థర్డ్-పార్టీ సేవలు</h3>
          <ol>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం మీకు థర్డ్-పార్టీ ప్రోడక్ట్‌లు లేదా సేవలకు (“థర్డ్
                పార్టీ సేవలు”) సంబంధించిన లింక్‌లకు ప్రాప్యత ఇవ్వవచ్చు.
                కోవెస్ట్రో థర్డ్ పార్టీ సేవలను ఏ పద్ధతిలోనూ నియంత్రించదు,
                తదనుగుణంగా, అటువంటి థర్డ్ పార్టీ సేవలతో అనుబంధించబడిన ఎటువంటి
                బాధ్యతను స్వీకరించదు. అటువంటి థర్డ్-పార్టీ సేవలను ఉపయోగించడంలో
                మీ వ్యక్తిగత సమాచారాన్ని, గోప్యతను రక్షించడం, సంబంధిత ఒప్పందాలకు
                అనుగుణంగా ఉండటంతో సహా థర్డ్-పార్టీ సేవకు ప్రాప్యత అందించడం
                సముచితమో కాదో నిర్ధారించడానికి మీరు తగిన చర్యలు తీసుకోవాలి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ప్రాప్యత మరియు ఉపయోగం</h3>
          <ol>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం అనేది మార్కెట్ ప్లేస్, దీనిలో భాగంగా యూజర్లకు
                వివిధ రకాల ఆహారం & వ్యవసాయ ఉత్పత్తులు, కనెక్ట్ చేయబడిన సేవలు &
                పరిష్కారాలు, కూరగాయలు, పండ్లు, ధాన్యాలు, పప్పుధాన్యాలు, సుగంధ
                ద్రవ్యాలు, తోటల పంటలు, ఆర్థిక పథకాలు, విధానాలు, ప్రణాళికలు వంటి
                మొదలైన వాటికి ప్రాప్యత అందించబడతాయి (సమిష్టిగా వీటన్నిటినీ
                &quot;ఉత్పత్తులు, సేవలు&quot;గా సూచిస్తారు).
              </p>
            </li>
            <li>
              <p>
                మీరు ప్లాట్‌ఫారం ద్వారా సమర్పించే ఏదైనా కంటెంట్{' '}
                <a
                  href={`${APP_DOMAIN}legal/privacy-statement`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/privacy-statement`}
                </a>{' '}
                లో అందుబాటులో ఉన్న కంపెనీ గోప్యతా విధానం ద్వారా
                నిర్వహించబడుతుంది. ఈ ఒప్పందం, కంపెనీ గోప్యతా విధానానికి మధ్య
                వైరుధ్యం ఉన్నంత వరకు, ఈ నిబంధనలు నియంత్రించబడతాయి.
              </p>
            </li>
            <li>
              <p>
                ఈ చర్చా ఫోరమ్‌కు సంబంధించి, మీరు ప్రశ్న లేదా ప్రతిస్పందనను
                సమర్పించినట్లయితే, మీ స్వంత కమ్యూనికేషన్‌లు, ఆ కమ్యూనికేషన్‌లను
                పోస్ట్ చేయడం వల్ల కలిగే పరిణామాలు, పబ్లిక్ ఏరియాల్లో కనిపించే
                ఏవైనా కమ్యూనికేషన్‌లపై మీరు ఆధారపడటం వంటి వాటికి మీరు పూర్తిగా
                బాధ్యత వహిస్తారు. కంపెనీ, దాని లైసెన్సర్‌లు పబ్లిక్ ఏరియాలలో
                ఏదైనా కమ్యూనికేషన్‌ల పరిణామాలకు బాధ్యత వహించరు. మీరు
                బెదిరింపులకు గురవుతున్నట్లు లేదా మరొకరు ప్రమాదంలో ఉన్నారని మీరు
                విశ్వసించిన సందర్భాల్లో, మీరు వెంటనే మీ స్థానికంగా ఉండే
                చట్టాన్ని అమలు చేసే సంస్థను సంప్రదించవలసి ఉంటుంది. ఈ
                ప్లాట్‌ఫారానికి సంబంధించిన కార్యకలాపాలు అన్నింటికీ మీరు బాధ్యత
                వహిస్తారు, మీరు స్థానిక, రాష్ట్ర, జాతీయ, అంతర్జాతీయ చట్టాలు,
                నిబంధనలు, అలాగే ఇంకా ఏవైనా వర్తించే నియంత్రణ కోడ్‌లకు కట్టుబడి
                ఉండాలి.
              </p>
            </li>
            <li>
              <p>
                ఈ ఉత్పత్తులు, సేవలకు యాక్సెస్ తాత్కాలిక ప్రాతిపదికన
                అనుమతించబడుతుంది, ప్లాట్‌ఫారం‌లో మేము అందించే ఉత్పత్తులు, సేవలకు
                సంబంధించి (లేదా ఉత్పత్తులు, సేవలలోని ఏవైనా ఫీచర్‌లు) మీకు
                ముందస్తు నోటీసు ఇవ్వకుండానే వాటిని ఉపసంహరించుకునే లేదా సవరించే
                హక్కు మాకు ఉంది. ఏ కారణం చేతనైనా ప్లాట్‌ఫారం లేదా ఉత్పత్తులు,
                సేవలు (లేదా ఉత్పత్తులు, సేవలలోని ఏవైనా ఫీచర్‌లు) ఏ సమయంలోనైనా
                లేదా ఏ కాలంలోనైనా అందుబాటులో లేకుంటే వాటికి సంబంధించి మేము
                ఎలాంటి బాధ్యత వహించబోము. ఎప్పటికప్పుడు, మేము మా స్వంత
                అభీష్టానుసారం, ప్లాట్‌ఫారం‌లోని కొన్ని భాగాలకు లేదా మొత్తం
                ప్లాట్‌ఫారానికి, మా వద్ద నమోదు చేసుకున్న వినియోగదారులకు మాత్రమే
                ప్రాప్యతను పరిమితం చేయవచ్చు.
              </p>
            </li>
            <li>
              <p>
                మీరు మా ప్లాట్‌ఫారానికి, తద్వారా ఉత్పత్తులు, సేవలను ప్రాప్యత
                పొందడానికి అవసరమైన అన్ని ఏర్పాట్లను చేయడంలో మీరు బాధ్యత
                వహిస్తారు. మరే ఇతర వ్యక్తి ప్లాట్‌ఫారం ప్రాప్యత పొందకూడదని
                నిర్ధారించుకోవాల్సిన బాధ్యత కూడా మీపై ఉంది, తద్వారా మీ ఖాతా
                ద్వారా ఉత్పత్తులు, సేవలకు సంబంధించి అటువంటి ప్రాప్యతకు మేము
                ఎలాంటి బాధ్యత వహించం, దానికి పూర్తిగా బాధ్యతను మీరే
                తీసుకోవాల్సివుంటుంది.
              </p>
            </li>
            <li>
              <p>
                మీ ఖాతా కింద జరిగే లేదా జరుగుతున్న అన్ని కార్యకలాపాలకు కూడా మీరు
                పూర్తిగా బాధ్యత వహిస్తారు, తదనుగుణంగా కోవెస్ట్రోకి కూడా బాధ్యత
                వహించాల్సివుంటుంది. మీ ఖాతాకు లాగిన్ చేయగల సామర్థ్యం ఇంటర్నెట్
                సర్వీస్ ప్రొవైడర్లు, ఇంటర్నెట్ నెట్‌వర్క్ కనెక్టివిటీ వంటి బాహ్య
                కారకాలపై ఆధారపడి ఉంటుందనే విషయాన్ని ఈ సందర్భంగా మీరు
                అంగీకరిస్తున్నారు, మీ ఖాతాలోకి లాగిన్ చేయడంలో మీ అసమర్థత కారణంగా
                ఉత్పన్నమయ్యే ఏవైనా నష్టాలకు మేము మీకు బాధ్యత వహించం.
              </p>
            </li>
            <li>
              <p>
                కొత్త వినియోగదారులకు ప్లాట్‌ఫారం‌లో అందించబడిన ఉత్పత్తులు,
                సేవలను ఉపయోగించడానికి ప్రాప్యతను తిరస్కరించే హక్కును కోవెస్ట్రో
                కలిగి ఉంది లేదా అలా చేయడానికి ఎటువంటి కారణాలు లేకున్నప్పటికీ ఏ
                సమయంలోనైనా ఇప్పటికే ఉన్న యూజర్లకు మంజూరు చేయబడిన ప్రాప్యతను
                రద్దు చేయవచ్చు. ఏ కారణం చేతనైనా ప్లాట్‌ఫారం లేదా సేవలను
                ఉపయోగించకుండా కోవెస్ట్రో ద్వారా సస్పెండ్ చేయబడిన లేదా
                నిషేధించబడిన ఏ వినియోగదారు ఖాతాను ఉపయోగించడానికి అందుబాటులో
                ఉండదు.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>సభ్యత్వం/నమోదు మరియు అర్హత</h3>
          <ol>
            <li>
              <p>
                ఉత్పత్తులు, సేవలకు ప్రాప్యత పొందడానికి, మీరు ప్లాట్‌ఫారం‌లో
                నమోదు చేసుకోవాలి, &quot;సభ్యుడు&quot; ఖాతాను
                సృష్టించాల్సివుంటుంది. ప్లాట్‌ఫారం‌పై మా స్వంత అభీష్టానుసారం
                మేము ఎప్పటికప్పుడు ఏర్పాటు చేసి నిర్వహించగల ఉత్పత్తులు, సేవలు,
                కార్యాచరణకు గాను మీ ఖాతా మీకు ప్రాప్యతను అందిస్తుంది.
              </p>
            </li>
            <li>
              <p>
                ఈ నమోదు ప్రక్రియను పూర్తి చేయడం ద్వారా, ఈ సందర్భంగా మీరు హామీ
                ఇస్తున్నారు: (ఎ) మీరు అందించిన మొత్తం డేటా ఖచ్చితమైనది,
                పరిపూర్ణమైనది; (బి) మీరు అటువంటి సమాచారం ఖచ్చితత్వాన్ని, అలాగే ఈ
                సమాచారాన్ని క్రమం తప్పకుండా నవీకరించడం ద్వారా దానిలో ఏవైనా
                మార్పులను నిర్వహించాలి; (సి) మీరు 16 సంవత్సరాల కంటే ఎక్కువ
                వయస్సు ఉన్నారని, ఈ ఒప్పందంలో నిర్దేశించబడిన నిబంధనలు, షరతులు,
                బాధ్యతలు, ధృవీకరణలు, ప్రాతినిధ్యాలు, వారెంటీలలోకి
                ప్రవేశించడానికి, ఈ ఒప్పందానికి కట్టుబడి, బాధ్యులుగా ఉండటానికి
                పూర్తి సామర్థ్యం, సమర్థత కలిగి ఉన్నారని మీరు ధృవీకరిస్తున్నారు;
                (డి) మీరు 18 (పద్దెనిమిది) సంవత్సరాల కంటే తక్కువ వయస్సు
                ఉన్నట్లయితే, మీ తల్లిదండ్రులు లేదా సంరక్షకులు తప్పనిసరిగా ఈ
                ఒప్పందం నిబంధనలను సమీక్షించి, ఆమోదించాలి, ప్లాట్‌ఫారాన్ని
                ఉపయోగించడం ద్వారా, మీ తల్లిదండ్రులు లేదా సంరక్షకులు ఈ
                ఒప్పందాన్ని సమీక్షించి, అంగీకరించినట్లు ఈ సందర్భంగా మీరు
                ధృవీకరిస్తున్నారు. మా స్వంత అభీష్టానుసారం ప్లాట్‌ఫారం‌లోని
                నిర్దిష్ట ఫీచర్ల లభ్యతను 18 ఏళ్ల కంటే తక్కువ వయస్సు ఉన్న
                వినియోగదారులకు పరిమితం చేసే హక్కు మాకు ఉంది. (ఇ) మీరు బైండింగ్
                కాంట్రాక్ట్‌లోకి ప్రవేశించడానికి వర్తించే చట్టాల ప్రకారం
                అర్హులు, అలాగే వర్తించే చట్టాల ప్రకారం ఉత్పత్తులు, సేవలను
                స్వీకరించకుండా నిరోధించబడిన వ్యక్తి కాదు; (ఎఫ్) మీరు ఈ ఒప్పందం,
                వర్తించే అన్ని రకాల స్థానిక, రాష్ట్ర, జాతీయ, అంతర్జాతీయ చట్టాలు,
                నియమాలు, నిబంధనలకు అనుగుణంగా మాత్రమే ప్లాట్‌ఫారం ద్వారా
                అందించబడిన ప్లాట్‌ఫారం లేదా ఇతర సేవలను ఉపయోగించవచ్చు.
                ప్లాట్‌ఫారం‌లో మీరు అందించిన సమాచారంలో ఏదైనా పొరపాటు వల్ల కలిగే
                ఏదైనా గాయం, నష్టం లేదా ఇతర పర్యవసానాలు, ఆరోగ్యానికి సంబంధించిన
                లేదా ఇతరత్రా జరిగినదానికి మేము బాధ్యత వహించము. ఈ ఒప్పందం లేదా
                గోప్యతా విధానం కింద పేర్కొన్న ఏవైనా షరతులను మీరు ఉల్లంఘించారని
                మేము విశ్వసిస్తే, హెచ్చరిక లేకుండా లేదా ఎలాంటి నోటీసు లేకుండా
                మీకు 24 (ఇరవై నాలుగు) గంటల ముందుగానే తెలియజేయడం ద్వారా మీ
                ప్రొఫైల్ మాచేత తొలగించబడవచ్చు.
              </p>
            </li>
            <li>
              <p>
                ఈ నమోదు ప్రక్రియను పూర్తి చేయడానికి, ఉత్పత్తులు, సేవలను
                ఉపయోగించడానికి అర్హత పొందడానికి, మీరు మీ పూర్తి చట్టపరమైన పేరు,
                ఫోన్ నంబర్, చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామా, మీ నుండి
                అభ్యర్థించిన ఏదైనా ఇతర సమాచారాన్ని ఎప్పటికప్పుడు అందించాలి.
                ఉత్పత్తులు, సేవలు, రికార్డ్ కీపింగ్ ప్రయోజనాల కోసం, అంతర్గత
                విధానాలు లేదా మరేదైనా ఇతర ప్రయోజనాల కోసం మీరు అందించిన
                సమాచారాన్ని మేము ఏదైనా థర్డ్ పార్టీకి భాగస్వామ్యం చేయవచ్చు, ఈ
                ప్లాట్‌ఫారాన్ని ఉపయోగించడం ద్వారా మీరు అందించిన సమాచారాన్ని
                భాగస్వామ్యం చేయడానికి మీరు స్పష్టంగా సమ్మతించినట్లు ఈ సందర్భంగా
                గుర్తించబడమైనది.
              </p>
            </li>
            <li>
              <p>
                ఈ రిజిస్ట్రేషన్ ప్రక్రియను పూర్తి చేసిన తర్వాత, మా భద్రతా
                విధానాలలో భాగంగా మీకు వినియోగదారు గుర్తింపు కోడ్, పాస్‌వర్డ్
                లేదా ఏదైనా ఇతర సమాచారం అందించబడుతుంది. మీరు అటువంటి సమాచారాన్ని
                గోప్యంగా పరిగణించాలి, మీరు దానిని ఏ థర్డ్ పార్టీ వ్యక్తులకు
                బహిర్గతం చేయకూడదు. ప్రతి సెషన్ ముగింపులో మీరు మీ ఖాతా నుండి
                నిష్క్రమించడం/లాగ్ అవుట్ చేస్తారని నిర్ధారించుకోవడానికి కూడా
                మీరు ఈ సందర్భంగా అంగీకరిస్తున్నారు. మీరు ఎంచుకున్న లేదా మేము
                కేటాయించిన ఏదైనా యూజర్ గుర్తింపు కోడ్ లేదా పాస్‌వర్డ్‌ను మా
                అభిప్రాయం ప్రకారం మీరు ఈ ఒప్పందంలోని ఏదైనా నిబంధనలను పాటించడంలో
                విఫలమైనట్లుగా గుర్తిస్తే, ఏ సమయంలోనైనా నిలిపివేసే హక్కు మాకు
                ఉంది. మీరు మీ పాస్‌వర్డ్ లేదా వినియోగదారు గుర్తింపు కోడ్ ఏదైనా
                అనధికారిక ఉపయోగం గురించి వెంటనే కోవెస్ట్రోకి తెలియజేయాలి,
                అటువంటి అనధికారిక ఉపయోగం వివరాలను దీనికి పంపడం ద్వారా ఈ పని
                చేయండి:{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో నమోదు ప్రక్రియ పూర్తయిన తర్వాత, మీరు ఇందులో
                సభ్యులుగా ఉంటారు:
              </p>
              <ol>
                <li>
                  <p>
                    ఈప్లాట్‌ఫారం‌లో; అమ్మకం లేదా కొనుగోలు కోసం ఆహ్వానంగా
                    ఉత్పత్తుల ప్రాప్యత పొందడానికి, అలాగే పోస్ట్ చేయడానికి
                    మిమ్మల్ని అనుమతిస్తుంది;
                  </p>
                </li>
                <li>
                  <p>
                    ప్లాట్‌ఫారం ఉపయోగ నిబంధనలలో మార్పులను మీకు తెలియజేయడానికి
                    లేదా ప్లాట్‌ఫారం‌లో మేము ప్రారంభించే కొత్త సేవలను
                    వివరించడానికి, అవసరమైతే మిమ్మల్ని సంప్రదించడానికి
                    ప్లాట్‌ఫారం; మమ్మల్ని అనుమతిస్తుంది;
                  </p>
                </li>
                <li>
                  <p>
                    ప్లాట్‌ఫారం‌లోని ఉత్పత్తులు, సేవలపై సమాచారానికి సంబంధించి
                    ప్రాప్యత పొందడానికి, సేకరించడానికి మిమ్మల్ని అనుమతిస్తుంది,
                    ఆ తర్వాత ఇక్కడ పేర్కొన్న నిబంధనలు, షరతులకు లోబడి అటువంటి
                    సమాచారాన్ని రూపొందించిన మూలకర్తను సంప్రదించండి;
                  </p>
                </li>
                <li>
                  <p>
                    ఈ ప్లాట్‌ఫారం‌లో ప్రదర్శించబడే ఇమెయిల్ చిరునామాలను మా ద్వారా
                    అధీకృతం కాకుండా మరే ఇతర ప్రయోజనం కోసం ఉపయోగించడానికి
                    మిమ్మల్ని అనుమతించదు.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                కోవెస్ట్రోకు మీ ఇమెయిల్ చిరునామా/ఫోన్ నంబర్‌ను అందించడం ద్వారా
                మీరు సమ్మతిని తెలియజేస్తున్నారు:
              </p>
              <ol>
                <li>
                  <p>
                    పోస్టల్ మెయిల్ ద్వారా కమ్యూనికేట్ చేయడానికి బదులుగా చట్టం
                    ప్రకారం ఏవైనా నోటీసులతో సహా మీకు సేవా సంబంధిత నోటీసులను
                    పంపడానికి మేము మీ ఇమెయిల్ చిరునామా లేదా మొబైల్ నంబర్‌ని
                    ఉపయోగిస్తాం. మీరు ఇమెయిల్/మొబైల్ నంబర్ లేదా పూర్తిగా
                    సేవా-సంబంధిత కమ్యూనికేషన్‌లను నిలిపివేయడానికి మీ
                    సెట్టింగ్‌లను ఉపయోగించవచ్చు.
                  </p>
                </li>
                <li>
                  <p>
                    ప్లాట్‌ఫారం‌లో మీ పురోగతి, మా సేవల వినియోగానికి సంబంధించి,
                    ఏదైనా కొత్త ప్రమోషన్‌లు, విక్రయ అవకాశాలకు సంబంధించి మీకు
                    సవరణలు, సలహాలను అందించడానికి, ఎప్పటికప్పుడు మిమ్మల్ని
                    సంప్రదించడానికి, మీరు ప్లాట్ ఫారంలో అందించిన ఫోన్ నంబర్‌లను
                    మేము ఉపయోగించుకుంటాం.
                  </p>
                </li>
                <li>
                  <p>
                    ఈ ప్లాట్‌ఫారం‌లో మీరు చేసిన ఆర్డర్‌లను నెరవేర్చడానికి మేము
                    మీ ఇమెయిల్ చిరునామా, ఇంటి చిరునామా, ఫోన్ నంబర్, ఇతర అనుబంధ
                    సమాచార ఉపయోగం; అలాగే
                  </p>
                </li>
                <li>
                  <p>
                    ఉత్పత్తులు, సేవల ఫీచర్‌లకు మార్పులు, ప్రత్యేక ఆఫర్‌లతో సహా
                    మీకు ఇతర సందేశాలను పంపడానికి మేము మీ ఇమెయిల్ చిరునామా లేదా
                    ఫోన్ నంబర్‌ని ఉపయోగిస్తాము. మీరు అటువంటి ఇమెయిల్ సందేశాలను
                    అందుకోకూడదనుకుంటే, మీరు మాకు ఇమెయిల్ పంపడం ద్వారా వాటిని
                    నిలిపివేయవచ్చు:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    ఇలా నిలిపివేయడం వలన మీరు అప్‌డేట్‌లు, మెరుగుదలలు లేదా
                    ఆఫర్‌లకు సంబంధించిన ఇమెయిల్ సందేశాలు లేదా ఫోన్ సందేశాలను
                    స్వీకరించకుండా నిరోధించవచ్చు.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>ప్లాట్‌ఫారం వినియోగ నిబంధనలు</h3>
          <ol>
            <li>
              <p>
                అమ్మకం కోసం ఆహ్వానం సమాచారాన్ని యాక్సెస్ చేయడానికి మీరు
                ప్లాట్‌ఫారం ప్రాప్యతను పొందవచ్చు, ఉపయోగించవచ్చు, ఎప్పటికప్పుడు
                థర్డ్ పార్టీలు అందులో పొందుపరిచిన ఉత్పత్తులు, సేవలను కొనుగోలు
                చేయవచ్చు, ఉపయోగించుకోవచ్చు.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం పరంగా కోవెస్ట్రో బాధ్యత పూర్తిగా మరియు ప్రత్యేకంగా
                వీటికి మాత్రమే వర్తిస్తుంది:
              </p>
              <ol>
                <li>
                  <p>ఈప్లాట్‌ఫారం‌పై; ప్లాట్‌ఫారం సదుపాయం;</p>
                </li>
                <li>
                  <p>
                    ఈప్లాట్‌ఫారం‌లో నమోదు చేసుకున్నూ యూజర్లకు ఉత్పత్తులు, సేవల
                    ప్రాప్యత, లభ్యతను నిర్ధారించడానికి మార్కెట్‌ప్లేస్‌గా సేవలు
                    అందిస్తోంది
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం‌లో పొందుపరచబడిన ఉత్పత్తులు, సేవలు థర్డ్-పార్టీ
                విక్రేతల స్వంతం,ఇక్కడ విక్రయించబడే ఉత్పత్తులు, సేవలు వారికి
                చెందినవని దీని ద్వారా స్పష్టం చేయబడింది. థర్డ్ పార్టీ విక్రేత
                ఉత్పత్తులు, సేవలు ఏ విధంగానూ కోవెస్ట్రోకి చెందినవి కావు.
                అందువల్ల, కోవెస్ట్రో ఎటువంటి వారంటీని అందించదు, థర్డ్-పార్టీ
                విక్రేతల ఉత్పత్తులు, సేవల నాణ్యత, పరిమాణం లేదా ప్రామాణికతకు ఏ
                విధంగానూ బాధ్యత వహించదు.
              </p>
            </li>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం‌లో ఆర్డర్ చేసిన లేదా రిజిస్ట్రేషన్ చేయబడిన తర్వాత,
                ప్రాసెస్ చేయబడిన తర్వాత, ఇక్కడ పేర్కొన్న విధంగా సేవ్ చేసిన
                తర్వాత, చెల్లింపులు తిరిగి ఇవ్వడం లేదా రద్దు చేయడం వంటివి
                ప్రాసెస్ చేయబడవు లేదా అవి అనుమతించబడవు.
              </p>
            </li>
            <li>
              <p>
                ఈ నిబంధనలు, ఉపయోగ షరతులకు లోబడి, కోవెస్ట్రో మీకు బదిలీ చేయలేని,
                ప్రత్యేకించబడని, సబ్‌లైసెన్సు చేయని పరిమిత హక్కును, మీ ఏకైక
                ప్రయోజనం కోసం మాత్రమే ప్లాట్‌ఫారం‌ను ప్రాప్యత పొందడానికి,
                ఉపయోగించడానికి మీకు లైసెన్స్‌ని మంజూరు చేస్తుంది.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>సేవల వినియోగంపై పరిమితి</h3>
          <ol>
            <li>
              <p>
                మీరు ఈ దిగువ పేర్కొనబడిన చర్యలలో దేనినైనా నిర్వహించడానికి
                అనుమతించరు, చేయకూడదు, దీని ఉల్లంఘన కోవెస్ట్రో మీకు వ్యతిరేకంగా
                మేము కలిగి ఉన్న ఇతర హక్కులు లేదా నివారణలకు ఎటువంటి పక్షపాతం
                లేకుండా మీ ఉత్పత్తులు, సేవల వినియోగాన్ని నిలిపివేసే హక్కును
                కలిగి ఉంటుంది:
              </p>
              <ol>
                <li>
                  <p>
                    ప్లాట్‌ఫారం, ఉత్పత్తులు, సేవలలో భాగంగా మీకు అందించబడిన
                    మొత్తం కంటెంట్‌ను పంపిణీ చేయడం, విక్రయించడం, సరఫరా చేయడం,
                    సవరించడం, స్వీకరించడం, సవరించడం, విలీనం చేయడం, విలీనం చేయడం
                    లేదా మార్చడం;
                  </p>
                </li>
                <li>
                  <p>
                    ప్లాట్‌ఫారం లేదా వెబ్‌సైట్ లేదా యాప్‌లో భాగంగా మీకు
                    అందించబడిన ఏదైనా సేవ లేదా కంటెంట్‌ను డీకంపైల్ చేయడానికి,
                    రివర్స్ ఇంజనీర్ చేయడానికి లేదా విడదీయడానికి ప్రయత్నించడం;
                  </p>
                </li>
                <li>
                  <p>
                    ప్లాట్‌ఫారం లేదా వెబ్‌సైట్ లేదా యాప్‌లో భాగంగా మీకు
                    అందించబడిన ఏదైనా సాఫ్ట్‌వేర్‌ను కాపీ చేయడానికి ప్రయత్నించడం,
                    ప్రత్యేకించి దాని సోర్స్ కోడ్ లేదా మీకు అందించిన
                    సాఫ్ట్‌వేర్‌లోని ఏదైనా భాగాన్ని డీక్రిప్ట్ చేయడానికి
                    ప్రయత్నించడం;
                  </p>
                </li>
                <li>
                  <p>
                    ప్లాట్‌ఫారం లేదా ఉత్పత్తులు, సేవలలో భాగంగా అందించబడిన ఏదైనా
                    కంటెంట్‌ను మీ వినియోగాన్ని సులభతరం చేయడానికి లేదా దానికి
                    సంబంధించి మేము అందించిన ఏదైనా సాఫ్ట్‌వేర్ ఏదైనా ఉత్పన్నమైన
                    పనిని లేదా సంస్కరణను సృష్టించడం;
                  </p>
                </li>
                <li>
                  <p>
                    కోవెస్ట్రో డొమైన్ పేరులో ఏదైనా ఒక మారుపేరుతో తిరిగి వచ్చే
                    ఇమెయిల్ చిరునామాగా ఉపయోగించడం;
                  </p>
                </li>
                <li>
                  <p>
                    ఏ విధంగానైనా ప్రాప్యత పొందడానికి మీకు అధికారం లేని ఏదైనా
                    కంటెంట్‌ని ప్రాప్యత పొందడం లేదా ప్రాప్యత పొందడానికి
                    ప్రయత్నించడం;
                  </p>
                </li>
                <li>
                  <p>
                    ఈ ఉత్పత్తులు, సేవల నుండి లేదా ఉత్పత్తులు, సేవలలో భాగంగా
                    అందించబడిన ఏదైనా కంటెంట్ నుండి ఏదైనా లోగో, కాపీరైట్ లేదా
                    యాజమాన్య నోటీసులు, లెజెండ్‌లు, చిహ్నాలు, లేబుల్‌లు,
                    వాటర్‌మార్క్‌లు, సంతకాలు లేదా ఉత్పత్తులు, సేవలలో అతికించబడిన
                    లేదా పొందుపరిచిన ఏవైనా ఇతర గుర్తులు తీసివేయడం;
                  </p>
                </li>
                <li>
                  <p>
                    తప్పుడు గుర్తింపును సృష్టించడానికి, ఏదైనా వ్యక్తి లేదా సంస్థ
                    వలె నటించడానికి లేదా ఏదైనా కంటెంట్ మూలాన్ని దాచిపెట్టడానికి
                    ఉత్పత్తులు, సేవలు లేదా దానిలోని ఏదైనా భాగాన్ని ఉపయోగించడం;
                  </p>
                </li>
                <li>
                  <p>
                    ఈప్లాట్‌ఫారం‌లోని ఏదైనా సర్వర్‌లు లేదా ప్లాట్‌ఫారం హోస్ట్
                    చేయబడిన ఏదైనా సర్వర్‌లకు కనెక్ట్ చేయబడిన నెట్‌వర్క్‌లను
                    నాశనం చేసే, నిలిపివేయగల, అధిక భారం కలిగించే లేదా బలహీనపరిచే
                    ఏ పద్ధతిలోనైనా ప్లాట్‌ఫారంకు ప్రాప్యత పొందడం లేదా
                    ఉపయోగించడం;
                  </p>
                </li>
                <li>
                  <p>
                    మేము అందించిన ఇంటర్‌ఫేస్ ద్వారా కాకుండా ఇతర మార్గాల ద్వారా
                    ప్లాట్‌ఫారం‌కు ప్రాప్యత పొందడం;
                  </p>
                </li>
                <li>
                  <p>
                    ఉత్పత్తులు, సేవలలో ఏదైనా భాగాన్ని మార్చడం లేదా సవరించడం;
                    అలాగే
                  </p>
                </li>
                <li>
                  <p>
                    ఈప్లాట్‌ఫారం, మెటీరియల్‌లు, సిస్టమ్ వనరులు, లేదా ప్లాట్‌ఫారం
                    లేదా ఏదైనా అనుబంధ లింక్ చేయబడిన సైట్‌ల ద్వారా కనెక్ట్
                    చేయబడిన లేదా ప్రాప్యత చేయగల వినియోగదారు ఖాతాలు,
                    పాస్‌వర్డ్‌లు, సర్వర్లు లేదా నెట్‌వర్క్‌లకు అనధికారిక
                    ప్రాప్యత పొందడం వంటి వాటి భద్రతకు భంగం కలిగించడం లేదా జోక్యం
                    చేసుకోవడం లేదా హాని కలిగించడం.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>కంటెంట్</h3>
          <ol>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో మెటీరియల్‌ని అప్‌లోడ్ చేయడానికి లేదా
                ప్లాట్‌ఫారం‌లోని ఇతర వినియోగదారులతో లేదా ప్లాట్‌ఫారం‌లోని
                విక్రేతలను సంప్రదించడానికి మిమ్మల్ని అనుమతించే ఉత్పత్తులు,
                సేవలను (లేదా ఏదైనా ఫీచర్ లేదా ఉత్పత్తులు, సేవలలో భాగం) మీరు
                ఉపయోగించుకున్నప్పుడల్లా, మీరు తప్పనిసరిగా పాటించాల్సిన వర్తించే
                డేటా రక్షణ నియమాలు/చట్టాలు, దిగువ పేర్కొన్న కంటెంట్ ప్రమాణాలతో
                వీటిని పాటించాలి. కంటెంట్ ప్రమాణాలు ప్రతీ ఒక భాగానికి అలాగే దాని
                మొత్తానికి వర్తిస్తాయి. మీ సహకారం తప్పనిసరిగా వాస్తవాలను తెలిపే
                చోట, యథార్థంగా ఉంచబడాలి (అభిప్రాయాలను తెలిపే చోట), అది పోస్ట్
                చేయబడిన ఏ దేశంలోనైనా వర్తించే చట్టానికి లోబడి ఉండాలి;, మీ
                పోస్టింగ్ లేదా ఉత్పత్తులు, సేవల వినియోగానికి సంబంధించినది.
              </p>
            </li>
            <li>
              <p>
                ఉత్పత్తులు, సేవలను ఉపయోగించి శోధన ఫలితాలు, సైట్‌లు, కంటెంట్‌కు
                లింక్‌లను అందించబడవచ్చు, అలాంటి వాటి విషయంలో మీరు అభ్యంతరకరమైన,
                అనుచితమైన లేదా సరైనది కానిదిగా మీరు గుర్తించవచ్చు, అటువంటి
                కంటెంట్‌కు మేము ఎటువంటి బాధ్యత వహించాల్సిన అవసరం లేదు. మీరు
                ఉత్పత్తులు, సేవలను ఉపయోగించడం ద్వారా మీరు అభ్యర్థించిన ఉత్పత్తి
                సమాచారాన్ని విక్రయించే థర్డ్ పార్టీల వెబ్‌సైట్‌లకు ఉత్పత్తులు,
                సేవలు, వ్యాపారులు, లింక్‌లకు సంబంధించిన సమాచారాన్ని మీకు తిరిగి
                అందించే అవకాశం కూడా ఉంది. కోవెస్ట్రో దీనితో అనుబంధించబడిన ఏదైనా,
                అన్నిరకాల బాధ్యతలు, ఇతర బాధ్యతలను నిరాకరిస్తుంది.
              </p>
            </li>
            <li>
              <p>
                అటువంటి బాహ్య సైట్‌లు లేదా వనరుల లభ్యత ఫలితంగా లేదా సంపూర్ణత,
                ఖచ్చితత్వం లేదా ఉనికిపై అటువంటి వెబ్‌సైట్‌లు లేదా వనరుల నుండి
                ఏదైనా ప్రకటనలు, ఉత్పత్తులు, సేవలు లేదా ఇతర మెటీరియల్‌లు లేదా
                అందుబాటులో ఉన్నప్పుడు మీరు వాటిపై ఉంచిన ఏదైనా నమ్మకం ఫలితంగా
                మీకు సంభవించే నష్టం లేదా నష్టానికి కోవెస్ట్రో బాధ్యత వహించదని
                మీరు ఈ సందర్భంగా గుర్తించి, అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                మీ కంటెంట్, డాక్యుమెంట్‌లు, ఇతర డేటాను పర్యవేక్షించడానికి మీరు
                బాధ్యత వహించాలి, సేవల ద్వారా లేదా సేవలకు బదిలీ చేయబడిన లేదా
                నిర్వహించబడే కంటెంట్ ఏదైనా థర్డ్ పార్టీ హక్కులను ఉల్లంఘించదని
                నిర్ధారించడానికి వీలుగా మాకు బాధ్యత వహిస్తారు. మీరు మీ
                కంటెంట్‌ను ప్రాసెస్ చేయడానికి లేదా ఉత్పత్తులు, సేవలను
                ఉపయోగించడానికి అవసరమైన లైసెన్స్‌లను కలిగి ఉండాలనే అంశంలో మీరు
                హామీ ఇస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                మీ కంటెంట్‌తో సహా ఉత్పత్తులు, సేవల సాంకేతిక ప్రాసెసింగ్, ప్రసారం
                ఎన్‌క్రిప్ట్ చేయబడకుండా బదిలీ చేయబడవచ్చని, వీటిని కలిగి
                ఉండవచ్చనే అంశాన్ని మీరు అర్థం చేసుకున్నారు: (ఎ) వివిధ
                నెట్‌వర్క్‌ల ద్వారా ప్రసారాలు; మరియు (బి) కనెక్ట్ నెట్‌వర్క్‌లు
                లేదా పరికరాల సాంకేతిక అవసరాలకు అనుగుణంగా, స్వీకరించడానికి
                మార్పులు.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో ఉత్పత్తులు, సేవలను విక్రయించడంలో భాగంగా, వివిధ
                విక్రేతలు చిత్రాలు, వివరణలు, ఇతర కంటెంట్‌ను పోస్ట్ చేయవచ్చని
                మీరు దీని ద్వారా గుర్తించి, అర్థం చేసుకుంటున్నారు. కోవెస్ట్రోగా
                మేము కేవలం మార్కెట్‌ప్లేస్ సేవలను అందించడంలో సదుపాయం
                కల్పించడానికి ఒక వేదికగా మాత్రమే ఉపయోగపడతాం, అటువంటి కంటెంట్
                ప్రామాణికత లేదా విశ్వసనీయత పరంగా పరిమితి లేకుండా సహా, పేర్కొన్న
                థర్డ్ పార్టీ విక్రేతలు పోస్ట్ చేసిన కంటెంట్‌కు సంబంధించి మేము
                ఎటువంటి బాధ్యత వహించము.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>యాప్‌కి పరిమిత లైసెన్స్</h3>
          <ol>
            <li>
              <p>
                ఈ ఒప్పందం నిబంధనలకు అనుగుణంగా వ్యక్తిగత, వాణిజ్యేతర ప్రయోజనాల
                కోసం ప్లాట్‌ఫారానికి ప్రాప్యత పొందడానికి, ఉపయోగించడానికి మేము
                మీకు వ్యక్తిగత, ప్రపంచవ్యాప్తంగా, ఉపసంహరించదగిన, బదిలీ చేయలేని,
                ప్రత్యేకమైన లైసెన్స్‌ను మంజూరు చేస్తున్నాము.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారానికి సంబంధించి ఈ ఒప్పందంలో స్పష్టంగా మంజూరు చేయబడని
                వాటితో సహా అన్ని హక్కులు, టైటిల్, ప్రయోజనాలు కోవెస్ట్రో ద్వారా
                రిజర్వ్ చేయబడ్డాయి. మీరు కోవెస్ట్రో సాఫ్ట్‌వేర్, టైటిల్, ట్రేడ్
                నేమ్, ట్రేడ్‌మార్క్, సర్వీస్ మార్క్, లోగో, డొమైన్ పేరు
                మరియు/లేదా ప్రముఖ బ్రాండ్ ఫీచర్‌లు లేదా కోవెస్ట్రో యాజమాన్యంలోని
                ఇతర కంటెంట్‌తో ఏదైనా ఇతర గుర్తింపును ఉపయోగించాలనుకుంటే, మీరు
                తప్పనిసరిగా కోవెస్ట్రో నుండి రాతపూర్వక అనుమతిని పొందాలి. అనుమతి
                అభ్యర్థనలు{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                కు పంపబడవచ్చు.
              </p>
            </li>
            <li>
              <p>
                ఏదైనా సందేహాన్ని నివారించడానికి, కోవెస్ట్రో అన్ని సందేశాలు,
                చిత్రాలు, ఫోటోలు, ఆడియో, వీడియో, లొకేషన్ డేటా, కోవెస్ట్రో
                సృష్టించే, ప్లాట్‌ఫారానికి సంబంధించి అందుబాటులో ఉంచే అన్ని ఇతర
                రకాల డేటా లేదా కమ్యూనికేషన్‌లను కలిగి ఉంటుంది, అయితే ఈ హక్కులు
                వీటికి మాత్రమే పరిమితం కాదు. వినియోగదారు కంటెంట్ (పైన
                నిర్వచించినట్లు) మినహా ప్లాట్‌ఫారం‌లోని అన్ని ఇతర అంశాలు,
                భాగాలు, విజువల్ ఇంటర్‌ఫేస్‌లు, ఇంటరాక్టివ్ ఫీచర్‌లు, గ్రాఫిక్స్,
                డిజైన్, యూజర్ కంటెంట్ కంపైలేషన్, మొత్తం వినియోగదారు రివ్యూ
                రేటింగ్‌ల సంకలనం కూడా వీటిలో కలిసే ఉంటాయి. ఇక్కడ స్పష్టంగా,
                నిస్సందేహంగా అందించినవి తప్ప, మేము మీకు ఎలాంటి స్పష్టమైన లేదా
                పరోక్ష హక్కులను మంజూరు చేయము, ప్లాట్‌ఫారం, కోవెస్ట్రో కంటెంట్‌పై
                అన్ని హక్కులు మాచే ఉంచబడతాయి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>కంటెంట్ ప్రమాణాల ఉల్లంఘన</h3>
          <ol>
            <li>
              <p>
                మేము కంటెంట్ ప్రమాణాల ఉల్లంఘన (పైన పేర్కొన్నట్లుగా)
                సంభవించినట్లు పరిగణించినప్పుడు, మా అభీష్టానుసారం తగిన చర్య
                తీసుకోవచ్చు. వీటిని అనుసరించడంలో వైఫల్యం మీరు సేవను
                ఉపయోగించడానికి అనుమతించబడిన ఉపయోగ నిబంధనల భౌతిక ఉల్లంఘనను
                ఏర్పరుస్తుంది, మేము ఈ క్రింద పేర్కొన్న అన్నిరకాల లేదా ఏవైనా
                చర్యలతో సహా చట్టపరంగా అందుబాటులో ఉన్న ఏదైనా చర్య తీసుకోవచ్చు:
              </p>
              <ol>
                <li>
                  <p>
                    ఉత్పత్తులు, సేవలను ఉపయోగించడానికి మీ హక్కును తక్షణం,
                    తాత్కాలికంగా లేదా శాశ్వతంగా ఉపసంహరించుకోవడం, ప్లాట్‌ఫారం‌లో
                    మీ ఖాతాను ఏకకాలంలో రద్దు చేయడం/సస్పెండ్ చేయడం;
                  </p>
                </li>
                <li>
                  <p>
                    ఉత్పత్తులు, సేవలను ఉపయోగించి ప్లాట్‌ఫారం‌లో ఇప్పటికే పోస్ట్
                    చేసిన ఏదైనా సహకారాన్ని తక్షణం, తాత్కాలికంగా లేదా శాశ్వతంగా
                    తొలగించడం, మీ దృష్టికి తీసుకురాబడిన ఉల్లంఘనను నయం చేయడంలో
                    మీరు విఫలమైనప్పుడు;
                  </p>
                </li>
                <li>
                  <p>మీకు హెచ్చరిక జారీ;</p>
                </li>
                <li>
                  <p>మీపై తదుపరి చట్టపరమైన చర్యలు; </p>
                </li>
                <li>
                  <p>
                    ఉల్లంఘించిన ఫలితంగా నష్టపరిహారం ప్రాతిపదికన (సహజమైన
                    పరిపాలనా, చట్టపరమైన ఖర్చులతో సహా, పరిమితం కాకుండా) అన్ని
                    ఖర్చుల రీయింబర్స్‌మెంట్ కోసం మీపై చట్టపరమైన చర్యలు
                    తీసుకోబడతాయి;,
                  </p>
                </li>
                <li>
                  <p>
                    మేము సహేతుకంగా భావిస్తున్నట్లుగా లేదా చట్టం ప్రకారం అవసరమైన
                    విధంగా చట్టాన్ని అమలు చేసే అధికారులకు అటువంటి సమాచారాన్ని
                    బహిర్గతం చేయడం.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ఈ నియమాల ఉల్లంఘనలకు ప్రతిస్పందనగా మేము తీసుకోగల అన్ని చర్యలకు మా
                బాధ్యతను మేము నిరాకరించడంతో పాటు మినహాయింపును కలిగివున్నాము. పైన
                వివరించిన చర్యలు పరిమితం కావు, మేము సముచితంగా భావించే ఏదైనా ఇతర
                చర్యను కూడా తీసుకునే అవకాశాలు ఉన్నాయి.
              </p>
            </li>
            <li>
              <p>
                మీ ఖాతాను రద్దు చేయడం/సస్పెండ్ చేయడం ద్వారా, మీరు ఇకపై మీ ఖాతాను
                లేదా మీరు సర్వర్‌లలో నిల్వ చేసిన ఏదైనా కంటెంట్ లేదా డేటాకు ఇకపై
                మీరు ప్రాప్యతను పొందలేరు. ప్లాట్‌ఫారం లేదా ఉత్పత్తులు, సేవలకు
                కోవెస్ట్రో ద్వారా మీకు మంజూరు చేయబడిన అన్ని లైసెన్స్‌లు
                ఆటోమేటిగ్గా రద్దు చేయబడతాయి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>బిల్లింగ్ మరియు చెల్లింపు</h3>
          <ol>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లోని కొన్ని అంశాలు రుసుము లేదా ఇతర ఛార్జీ కోసం
                అందించబడతాయి. మీరు ప్లాట్‌ఫారం చెల్లింపు విధానాన్ని ఉపయోగించాలని
                ఎంచుకుంటే, అటువంటి రుసుములు, ఛార్జీలకు వర్తించే విక్రయ నిబంధనలు,
                ధర, చెల్లింపు, బిల్లింగ్ విధానాలకు మీరు అంగీకరిస్తున్నట్లుగా
                గుర్తించబడింది. కోవెస్ట్రో తన స్వంత అభీష్టానుసారం ఏ సమయంలోనైనా
                అదనపు రుసుములు, ఛార్జీల కోసం కొత్త సేవలను జోడించవచ్చు లేదా
                ఇప్పటికే ఉన్న సేవలకు రుసుము, ఛార్జీలను సవరించవచ్చు.
              </p>
            </li>
            <li>
              <p>
                ఏదైనా కాంటాక్ట్ లేదా బిల్లింగ్ సమాచారం మార్పులు లేదా
                అప్‌డేట్‌లతో (ఫోన్ నంబర్, ఇమెయిల్ చిరునామా, క్రెడిట్ కార్డ్
                నంబర్‌లు మొదలైన వాటితో సహా) కోవెస్ట్రోకి తక్షణమే అందించడం మీ
                బాధ్యత. కోవెస్ట్రో సురక్షిత చెల్లింపు కోసం కస్టమర్ చెల్లింపు
                ప్రదాతకు అవసరమైన మొత్తానికి సంబంధించి క్రెడిట్ కార్డ్
                సమాచారాన్ని ధృవీకరించదు.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో చేసిన కొనుగోళ్లకు సంబంధించి, రీఫండ్ అభ్యర్థనలు ఏ
                విధంగానూ స్వీకరించబడవని, రిఫండ్ చేయడం ప్రారంభించబడదని మీరు ఈ
                సందర్భంగా గుర్తించి, అంగీకరిస్తున్నారు.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>నష్టపరిహారం మరియు బాధ్యత పరిమితి</h3>
          <ol>
            <li>
              <p>
                అన్ని రకాల క్లెయిమ్లు, నష్టాలు, బాధ్యతలు, నష్టాలు, బాధ్యతలు,
                ఖర్చులు లేదా అప్పులు, ఖర్చులు (అటార్నీ ఫీజులతో సహా, కానీ వీటికే
                పరిమితం కాకుండా) కోవెస్ట్రో, దాని అధికారులు, డైరెక్టర్లు,
                ఉద్యోగులు, ఏజెంట్లను రక్షించడానికి, నష్టపరిహారం ఇవ్వడానికి,
                వసూలు చేయడానికి మీరు అంగీకరిస్తున్నారు. దీని నుండి ఉత్పన్నమయ్యే
                వాటికి సంబంధించి బాధ్యత వహిస్తున్నారు: (i) ఉత్పత్తులు, సేవలు,
                ప్లాట్‌ఫారానికి సంబంధించి మీ ఉపయోగం, యాక్సెస్; (ii) ఈ
                నిబంధనలలోని ఏదైనా నిబంధనను మీరు ఉల్లంఘించడం; (iii) పరిమితి
                లేకుండా, ఏదైనా కాపీరైట్, ఆస్తి లేదా గోప్యతా హక్కుతో సహా ఏదైనా
                థర్డ్ పార్టీ హక్కుకు సంబంధించి మీ ఉల్లంఘన; లేదా (iv) మీ
                వినియోగదారు కంటెంట్ థర్డ్ పార్టీకి నష్టం కలిగించిందని ఏదైనా
                దావా. మీ ఉత్పత్తులు, సేవలు, ప్లాట్‌ఫారం‌ను ఉపయోగానికి సంబంధించి
                ఏర్పరచిన ఒప్పందం ఈ రక్షణ, నష్టపరిహారం బాధ్యతను కలిగివుంటుంది.
              </p>
            </li>
            <li>
              <p>
                మా వెబ్‌సైట్ లేదా యాప్‌లో ప్రదర్శించబడే మెటీరియల్ దాని
                ఖచ్చితత్వానికి సంబంధించి ఎలాంటి హామీలు, షరతులు లేదా వారెంటీలు
                లేకుండా అందించబడుతుంది. చట్టం ద్వారా అనుమతించబడిన మేరకు, మేము,
                మా అనుబంధ సంస్థలు, అనుబంధ సంస్థలు, మాకు కనెక్ట్ చేయబడిన థర్డ్
                పార్టీలు దీని ద్వారా స్పష్టంగా మినహాయించబడతాయి:
              </p>
              <ol>
                <li>
                  <p>
                    చట్టం, సాధారణ చట్టం లేదా ఈక్విటీ చట్టం ద్వారా సూచించబడినవి
                    తప్పించి షరతులు, వారెంటీలు, ఇతర నిబంధనలు.
                  </p>
                </li>
                <li>
                  <p>
                    ఉత్పత్తులు, సేవలు, మా ప్లాట్‌ఫారం లేదా ఉత్పత్తులు, సేవలు
                    లేదా ప్లాట్‌ఫారం ఉపయోగం, ఉపయోగించడానికి అసమర్థత లేదా
                    ఫలితాలకు సంబంధించి దీనికి లింక్ చేయబడిన ఏవైనా వెబ్‌సైట్‌లు,
                    దానిపై పోస్ట్ చేసిన ఏవైనా మెటీరియల్‌లు, వీటితో సహా, కానీ
                    వీటికే పరిమితం కాకుండా ఏదైనా వినియోగదారుకు ప్రత్యక్షంగా,
                    పరోక్షంగా లేదా పర్యవసానంగా నష్టం లేదా నష్టానికి ఏదైనా బాధ్యత
                    :
                  </p>
                  <ol>
                    <li>
                      <p>ఆదాయం లేదా రాబడి నష్టం;</p>
                    </li>
                    <li>
                      <p>వ్యాపార నష్టం;</p>
                    </li>
                    <li>
                      <p>లాభాలు లేదా ఒప్పందాల నష్టం;</p>
                    </li>
                    <li>
                      <p>ఊహించిన పొదుపు నష్టం;</p>
                    </li>
                    <li>
                      <p>డాటా నష్టం;</p>
                    </li>
                    <li>
                      <p>గుడ్ విల్ నష్టం;</p>
                    </li>
                    <li>
                      <p>నిర్వహణ లేదా కార్యాలయ సమయ నష్టం; మరియు</p>
                    </li>
                    <li>
                      <p>
                        ఏదైనా ఇతర నష్టం లేదా నష్టపరిహారం కోసం, అయితే
                        ఉత్పన్నమయ్యే, టార్ట్ (నిర్లక్ష్యంతో సహా), ఒప్పందాన్ని
                        ఉల్లంఘించడం లేదా ఇతరత్రా, ఊహించగలిగినప్పటికీ లేదా అదే
                        అవకాశం గురించి సలహా ఇచ్చినప్పటికీ.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ఈ నిబంధనలకు సంబంధించి లేదా దానితో సంబంధం ఉన్న ఒప్పందం, టార్ట్
                (పరిమితి లేకుండా, నిర్లక్ష్యంతో సహా) లేదా ఇతరత్రా ఏ పార్టీ అయినా
                బాధ్యత వహించదు;
                <br />
                ఎ) ఏదైనా ఆర్థిక నష్టాలు (పరిమితి లేకుండా, ఆదాయాల నష్టం, లాభాలు,
                ఒప్పందాలు, డేటా, వ్యాపారం, ఊహించిన పొదుపులు లేదా ప్రత్యామ్నాయ
                సేవల ఖర్చుతో సహా);
                <br /> బి) గుడ్ విల్ లేదా ఖ్యాతిని కోల్పోవడం;
                <br /> సి) ఏదైనా ప్రత్యేక, పరోక్ష లేదా పర్యవసాన నష్టాలు; అటువంటి
                నష్టాలు ఈ నిబంధనల తేదీలో పార్టీల ఆలోచనలో ఉన్నాయో లేదో వాటికి
                సంబంధించి.
              </p>
            </li>
            <li>
              <p>
                మీ ద్వారా కస్టమర్ డేటా లేదా డేటాకు అనధికారిక యాక్సెస్ లేదా
                మార్పుకు సంబంధించి కోవెస్ట్రో, దాని పూర్తి యాజమాన్యంలోని అనుబంధ
                సంస్థలు ఎటువంటి బాధ్యత వహించవని ఈ సందర్భంగా మీరు
                అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                ఉత్పత్తులు, సేవలలో ఏదైనా లోపం, లోపం, వైఫల్యం లేదా ఆలస్యం కోసం
                కోవెస్ట్రో ఎటువంటి బాధ్యత వహించదు. పైన పేర్కొన్న సాధారణ అంశాలకు
                ఎలాంటి పక్షపాతం లేకుండా, ఉత్పత్తులు, సేవలలో ఏదైనా వైఫల్యం లేదా
                ఆలస్యం కోసం కోవెస్ట్రో బాధ్యత వహించదు: ఏదైనా ప్రత్యేక, పరోక్ష
                లేదా పర్యవసాన నష్టాలు; అటువంటి నష్టాలు ఈ నిబంధనల తేదీలో పార్టీల
                ఆలోచనలో ఉన్నాయో లేదో వాటికి సంబంధించి కూడా బాధ్యత వహించదు.
                <br />
                ఎ) ఏదైనా పబ్లిక్ ఇంటర్నెట్ బ్యాక్‌బోన్‌లు, నెట్‌వర్క్‌లు లేదా
                సర్వర్‌లకు అంతరాయాలు ఏర్పడటం; ఏదైనా ప్రత్యేక, పరోక్ష లేదా
                పర్యవసాన నష్టాలు; అటువంటి నష్టాలు ఈ నిబంధనల తేదీలో పార్టీల
                ఆలోచనలో ఉన్నాయో లేదో వాటికి కూడా బాధ్యత వహించదు.
                <br />
                బి) మీ పరికరాలు, సిస్టమ్‌లు లేదా స్థానిక ప్రాప్యతా సేవలు ఏదైనా
                వైఫల్యాల వల్ల లేదా
                <br />
                సి) గతంలో షెడ్యూల్ చేయబడిన నిర్వహణ కారణంగా.
              </p>
            </li>
            <li>
              <p>
                కోవెస్ట్రో (దాని పూర్తి యాజమాన్యంలోని అనుబంధ సంస్థల) మొత్తం
                సంచిత బాధ్యత (కాంట్రాక్ట్, టార్ట్ (నిర్లక్ష్యంతో సహా) లేదా
                ఇతరత్రా) కింద లేదా ఈ నిబంధనలకు సంబంధించి మీరు చెల్లించే సభ్యత్వ
                రుసుములలో 50 శాతానికి మించకూడదు.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>నేరాలు</h3>
          <ol>
            <li>
              <p>
                12.1 వైరస్‌లు, ట్రోజన్‌లు, వార్మ్‌లు, లాజిక్ బాంబులు, టైమ్
                బాంబ్‌లు, క్యాన్సిల్ బాట్‌లు, స్పైవేర్ లేదా ఇతర మెటీరియల్,
                కంప్యూటర్ ప్రోగ్రామింగ్ రొటీన్‌లు, కోడ్‌లు, ఫైల్‌లు లేదా
                హానికరమైన లేదా సాంకేతికంగా హానికరమైన ఇతర ప్రోగ్రామ్‌లను పరిచయం
                చేయడం ద్వారా మీరు ప్లాట్‌ఫారం‌ను దుర్వినియోగం చేయకూడదు. ఇతర
                యూజర్ల హక్కుల ఆసక్తులు లేదా ఏదైనా కంప్యూటర్ సాఫ్ట్‌వేర్,
                హార్డ్‌వేర్ లేదా టెలికమ్యూనికేషన్‌ల కార్యాచరణను పరిమితం చేయడం.
                మీరు ప్లాట్‌ఫారం, ప్లాట్‌ఫారం నిల్వ చేయబడిన సర్వర్ లేదా మా
                సైట్‌కు కనెక్ట్ చేయబడిన ఏదైనా సర్వర్, కంప్యూటర్ లేదా డేటాబేస్‌కు
                అనధికారిక ప్రాప్యతను పొందడానికి ప్రయత్నించకూడదు. మీరు సేవ
                తిరస్కరణ దాడి లేదా పంపిణీ చేయబడిన సేవ తిరస్కరణ దాడి ద్వారా
                ప్లాట్‌ఫారం‌పై దాడి చేయకూడదు.
              </p>
            </li>
            <li>
              <p>
                ఈ నిబంధనను ఉల్లంఘించడం ద్వారా, మీరు ఇన్ఫర్మేషన్ టెక్నాలజీ చట్టం,
                2000 (ఏవైనా సవరణలు) కింద క్రిమినల్ నేరానికి పాల్పడినట్లుగా
                గుర్తించబడతారు. మేము అలాంటి ఉల్లంఘనలను సంబంధిత చట్టాన్ని అమలు
                చేసే అధికారులకు నివేదించి, మీ గుర్తింపును వారికి బహిర్గతం చేయడం
                ద్వారా మేము వారికి సహకరిస్తాము. అటువంటి ఉల్లంఘన జరిగినప్పుడు,
                ప్లాట్‌ఫారాన్ని ఉపయోగించుకునే మీ హక్కు వెంటనే నిలిపివేయబడుతుంది.
              </p>
            </li>
            <li>
              <p>
                మీరు ప్లాట్‌ఫారాన్ని ఉపయోగించడం వల్ల మీ కంప్యూటర్ పరికరాలు,
                కంప్యూటర్ ప్రోగ్రామ్‌లు, డేటా లేదా ఇతర యాజమాన్య మెటీరియల్‌కు
                హాని కలిగించే పంపిణీ చేయబడిన సేవా నిరాకరణ దాడి, వైరస్‌లు లేదా
                సాంకేతికంగా హానికరమైన ఇతర విషయాల వల్ల కలిగే నష్టానికి లేదా అలాగే
                దానిపై పోస్ట్ చేసిన ఏదైనా మెటీరియల్‌ని లేదా దానికి లింక్ చేయబడిన
                ఏదైనా ప్లాట్‌ఫారం‌లో మీరు డౌన్‌లోడ్ చేసుకోవడంలో జరిగిన నష్టానికి
                మేము బాధ్యత వహించము..
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మొబైల్ యాప్ కోసం అదనపు నిబంధనలు</h3>
          <ol>
            <li>
              <p>
                మీరు లేదా ఇతర యూజర్ల వెబ్‌సైట్ లేదా యాప్ మరియు/లేదా ఉత్పత్తులు,
                సేవలను యాక్సెస్ చేసేందుకు వీలుగా మొబైల్ యాప్, డెస్క్‌టాప్ యాప్,
                ఉత్పత్తులు, సేవలు, డేటాను యాక్సెస్ చేయడానికి థర్డ్ పార్టీ
                సాఫ్ట్‌వేర్ లేదా వెబ్‌సైట్ ద్వారా మీకు పరిమిత, ఉప-లైసెన్సు లేని
                హక్కు మంజూరు చేయబడింది. మొబైల్ యాప్, డెస్క్‌టాప్ యాప్ ఏదైనా
                ఉపయోగం ఈ ఒప్పందం నిబంధనలతో పాటు ఈ దిగువ పేర్కొనబడిన నిర్దిష్ట
                నిబంధనలకు కట్టుబడి ఉంటుంది:
              </p>
              <ol>
                <li>
                  <p>
                    మీరు ఏదైనా థర్డ్ పార్టీ సాఫ్ట్‌వేర్ ద్వారా అందించే ఏవైనా
                    సేవలు మరియు/లేదా ఉత్పత్తులు, సేవలకు (మీకు లేదా ఏదైనా థర్డ్
                    పార్టీకి మాకు ఎటువంటి బాధ్యత లేదా బాధ్యత ఉండదు) మీరు
                    పూర్తిగా బాధ్యత వహిస్తారని ఈ సందర్భంగా మీరు
                    అంగీకరిస్తున్నారు;
                  </p>
                </li>
                <li>
                  <p>
                    మీరు ఏదైనా థర్డ్ పార్టీకి అయాచిత కమ్యూనికేషన్‌లను
                    (వాణిజ్యపరమైన లేదా ఇతరత్రా) పంపే సాఫ్ట్‌వేర్‌ను
                    రూపొందించడానికి మొబైల్ యాప్ లేదా డెస్క్‌టాప్ యాప్‌ని
                    ఉపయోగించరాదు;
                  </p>
                </li>
                <li>
                  <p>
                    48 (నలభై ఎనిమిది) గంటల నోటీసును అందించడం ద్వారా మొబైల్ యాప్,
                    డెస్క్‌టాప్ యాప్‌కి మీ ప్రాప్యతను తాత్కాలికంగా లేదా
                    శాశ్వతంగా సవరించడానికి లేదా నిలిపివేయడానికి మేము ఎప్పుడైనా
                    హక్కును కలిగి ఉన్నాము;
                  </p>
                </li>
                <li>
                  <p>
                    మీరు మొబైల్ యాప్, డెస్క్‌టాప్ యాప్‌ని ఉపయోగించడం వల్ల కలిగే
                    నష్టాల గురించి మాకు సలహా ఇవ్వబడితే లాభాల నష్టం, గుడ్‌విల్,
                    ఉపయోగం, డేటా లేదా ఇతర కనిపించని నష్టాలకు (కూడా వీటికే
                    పరిమితం కాకుండా) ప్రత్యక్ష, పరోక్ష, యాదృచ్ఛిక, ప్రత్యేక,
                    పర్యవసానమైన లేదా సరైన నష్టాలకు మేము బాధ్యులం కాదని స్పష్టంగా
                    అర్థం చేసుకుని, ఈ సందర్భంగా మీరు అంగీకరిస్తున్నారు;
                  </p>
                </li>
                <li>
                  <p>
                    నోటీసుతో లేదా నోటీసు లేకుండా 48 (నలభై ఎనిమిది) గంటల నోటీసును
                    అందించడం ద్వారా డెస్క్‌టాప్ యాప్, మొబైల్ యాప్‌ను
                    తాత్కాలికంగా లేదా శాశ్వతంగా సవరించడానికి లేదా
                    నిలిపివేయడానికి మేము ఎప్పుడైనా హక్కును కలిగి ఉన్నాము;
                  </p>
                </li>
                <li>
                  <p>
                    డెస్క్‌టాప్ యాప్, మొబైల్ యాప్ ద్వారా ఉత్పత్తులు, సేవలను
                    దుర్వినియోగం చేయడం లేదా అధిక మొత్తంలో తరచుగా చేసే అభ్యర్థనలు
                    మీ ఖాతా ప్రాప్యతను తాత్కాలికంగా లేదా శాశ్వతంగా
                    నిలిపివేయబడవచ్చు. మేము, మా స్వంత అభీష్టానుసారం, దుర్వినియోగం
                    లేదా అధిక వినియోగాన్ని నిర్ణయిస్తాము;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>ఆధీకృత ప్రాప్యత</h3>
          <ol>
            <li>
              <p>
                మేము మీ సౌలభ్యం, సమయం కోసం శ్రద్ధ వహిస్తాం, దీని కోసం మేము
                నిరంతరం ప్రయత్నిస్తాము, ప్లాట్‌ఫారం ఎల్లప్పుడూ అందుబాటులో
                ఉండేలా, అందుబాటులో ఉండేలా చూసుకుంటాం, అయినప్పటికీ, సాంకేతిక
                లోపాలు, ఇతర మానవేతర లోపాలు సంభవించవచ్చు.
              </p>
            </li>
            <li>
              <p>
                మీకు అంతరాయం లేని అనుభవాన్ని కొనసాగించడానికి, సాధారణ నిర్వహణ
                కోసం ప్లాట్‌ఫారం/సర్వర్/లింక్‌లు/వెబ్-పేజీని పూర్తిగా లేదా
                పాక్షికంగా మూసివేసే హక్కు మాకు ఉంది, అయితే మీ సంతృప్తిని
                నిర్ధారించడానికి వీలుగా డౌన్-టైమ్‌ను కనిష్టంగా ఉంచడానికి
                ప్రయత్నిస్తాము.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం, పూర్తిగా లేదా దానిలోని ఏదైనా భాగం, నిర్వహణ కారణంగా,
                అటువంటి సమయ వ్యవధిలో అందుబాటులో లేకుంటే, ఏ కారణం చేతనైనా మేము
                బాధ్యులం కాదని మీరు ఈ సందర్భంగా ధృవీకరించారు, అలాగే అర్థం
                చేసుకున్నట్లు భావించబడుతుంది.
              </p>
            </li>
            <li>
              <p>
                మా సేవల వినియోగానికి సంబంధించి, మా భద్రతా ప్రక్రియలో భాగంగా,
                మీరు మీ ఇమెయిల్ లేదా మొబైల్ నంబర్‌ను నమోదు చేసి, సురక్షిత
                పాస్‌వర్డ్‌ను ఎంచుకోవడం ద్వారా మరియు/లేదా మొబైల్ నంబర్ లేదా
                ఇమెయిల్ చిరునామా ద్వారా మీకు పంపిన వన్ టైమ్ పాస్‌వర్డ్ (OTP)ని
                నమోదు చేయడం ద్వారా మాతో ఒక ఖాతాను నమోదు చేసుకోవాలని మేము
                కోరుతున్నాము వినియోగదారు పాస్‌వర్డ్‌ల విషయంలో, ఇతరులు ఊహించడం
                కష్టంగా ఉండే బలమైన పాస్‌వర్డ్‌ను ఎంచుకోవాలని మేము బాగా సిఫార్సు
                చేస్తున్నాము, అలాగే ప్రతి సెషన్ చివరిలో మీరు మీ ఖాతా నుండి లాగ్
                అవుట్ అవ్వండి. సౌలభ్యం కోసం, మీరు మీ పాస్‌వర్డ్‌ను మర్చిపోతే
                పాస్‌వర్డ్ రీసెట్ విధానం అందుబాటులో ఉంది. మీరు మీ ఖాతా లాగిన్
                సమాచారాన్ని ఏదైనా థర్డ్ పార్టీకి బహిర్గతం చేయకుండా గోప్యంగా
                పరిగణించాలని, వాటిని వ్యక్తిగతంగా ఉపయోగించాలనే విషయాన్ని మీరు ఈ
                సందర్భంగా అంగీకరించారు. ఏదైనా భద్రతా ఉల్లంఘన జరిగినట్లు మీరు
                భావిస్తే, తక్షణమే మీ పాస్‌వర్డ్‌ను మార్చడానికి, లాగిన్ బ్లాక్
                చేయబడని సందర్భంలో వెంటనే మాకు తెలియజేయడం మీ బాధ్యత.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో మీరు అందించే మొత్తం సమాచారం సరైనది, సరికొత్తది,
                సంపూర్ణమైనది అనే షరతును మీరు పాటిస్తున్నారు. మా అభిప్రాయం, స్వంత
                అభీష్టానుసారం, మీరు ఈ సేవా నిబంధనలలోని ఏదైనా నిబంధనలను
                పాటించడంలో విఫలమైతే, అటువంటి కారణాల వల్ల ఏ సమయంలోనైనా మా స్వంత
                అభీష్టానుసారం ఏదైనా యూజర్ ఖాతాను నిలిపివేయడానికి మాకు హక్కు
                ఉంది.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం ఎటువంటి మానవ ప్రమేయం లేని ఆపరేషన్ అనే విషయాన్ని మీరు
                అంగీకరిస్తున్నారు, తద్వారా ప్లాట్‌ఫారం లేదా ప్లాట్‌ఫారం‌లో
                అందించబడిన ఏదైనా కంటెంట్ లోపం నుండి విముక్తి పొందలేరనే విషయాన్ని
                గుర్తించి, అర్థం చేసుకోండి, మానవ జోక్యం వల్ల తప్ప, దాని వల్ల
                ఏదైనా అసౌకర్యం లేదా నష్టం సంభవించిందని దీని ద్వారా
                అంగీకరిస్తున్నారు. ఇది సేవ ఉల్లంఘన లేదా లోపంగా పరిగణించబడదు.
              </p>
            </li>
          </ol>
        </li>
        <p>
          అసాధ్యమైన సంఘటన లేదా విపత్తు వైఫల్యం లేదా ప్రకృతి వైపరీత్యం లేదా
          అగ్నిప్రమాదం, ప్రభుత్వ ఉత్తరవు, అల్లర్లు లేదా కోవెస్ట్రో నియంత్రణకు
          మించిన ఇతర కారణాల వల్ల సంభవించే అన్ని సాంకేతిక లోపాలను కోవెస్ట్రో
          చేపట్టి 90 నుండి 120 గంటలలోపు వాటిని సరిదిద్దుతుంది. అయితే, కొన్ని
          సాంకేతిక లోపాలు, ప్రకృతి వైపరీత్యాలు, ప్రభుత్వ ఉత్తర్వు, అల్లర్లు లేదా
          మరేదైనా సంఘటన కారణంగా వెబ్‌సైట్‌కు ప్రాప్యతను అందించనందుకు కోవెస్ట్రో
          బాధ్యత వహించదు.
        </p>
        <li>
          <h3>గోప్యత</h3>
          <ol>
            <li>
              <p>
                ఈ నిబంధనల ప్రయోజనాల కోసం, “గోప్య సమాచారం” అనేది ఏదైనా యాజమాన్య
                డేటా, ఒక పక్షం మరొకరికి రాతపూర్వకంగా వెల్లడించిన ఏదైనా ఇతర
                సమాచారాన్ని కలిగి ఉంటుంది, “గోప్యమైనది” లేదా మౌఖికంగా బహిర్గతం
                చేయబడుతుంది, ఐదు పనిదినాల్లోపు రాయడం, “గోప్యమైనది” అని గుర్తు
                పెట్టబడుతుంది”. అయితే, గోప్యతా సమాచారంలో సాధారణ ప్రజలకు తెలిసిన
                లేదా తెలిసిన ఏ సమాచారాన్ని చేర్చదు, ఇది పార్టీ ద్వారా బహిర్గతం
                చేయడానికి ముందు ఇప్పటికే స్వీకరించే పార్టీ ఆధీనంలో ఉంది లేదా
                గోప్య సమాచారం లేదా ఏదైనా ఉపయోగించకుండా స్వీకరించే పార్టీ
                స్వతంత్రంగా అభివృద్ధి చేసింది. ఈ నిబంధనలలో వివరించిన విధంగా
                సమాచారాన్ని కోవెస్ట్రో ద్వారా అనుమతించబడింది.
              </p>
            </li>
            <li>
              <p>
                ఈ నిబంధనల ప్రకారం లేదా చట్టం, నియంత్రణ లేదా న్యాయస్థానం ఉత్తర్వు
                ద్వారా అవసరమైతే, ఏ పార్టీ కూడా తన బాధ్యతలను నిర్వర్తించే
                ఉద్దేశ్యంతో మినహా, ఇతర పార్టీల రహస్య సమాచారాన్ని ఇతరుల ముందస్తు
                రాతపూర్వక అనుమతి లేకుండా ఉపయోగించదు లేదా బహిర్గతం చేయదు; ఈ
                సందర్భంలో, గోప్యతా సమాచారాన్ని బహిర్గతం చేయమని బలవంతం చేయబడిన
                పార్టీ ఇతర పక్షానికి రహస్య సమాచారాన్ని బహిర్గతం చేయడానికి ముందు
                సహేతుకంగా ఆచరణీయమయ్యేలా నోటీసును ఇస్తుంది.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>వ్యక్తిగత డేటా గోప్యత మరియు రక్షణ</h3>
          <ol>
            <li>
              <p>
                ఈ సైట్‌లో నమోదు చేసుకోవడం ద్వారా, మీరు నిజాయితీగా, సరైన
                సమాచారాన్ని మాకు అందించడానికి అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                తప్పుడు సమాచారాన్ని కమ్యూనికేట్ చేయడం ప్రస్తుత సాధారణ నిబంధనలు,
                షరతులకు విరుద్ధం, పర్యవసానంగా చట్టపరమైన చర్యలకు మిమ్మల్ని
                బాధ్యులను చేస్తుంది.
              </p>
            </li>
            <li>
              <p>
                మీకు సంబంధించి మేము కలిగి ఉన్న వ్యక్తిగత సమాచారాన్ని
                తెలుసుకోవడానికి మీరు ఎప్పుడైనా మాకు అభ్యర్థన చేయవచ్చు. మీరు
                ఎప్పుడైనా, అభ్యర్థన ద్వారా ఈ సమాచారాన్ని సవరించవచ్చు.
              </p>
            </li>
            <li>
              <p>
                కుకీలు మీ హార్డ్ డ్రైవ్‌లో నిల్వ చేయబడిన నిర్దిష్ట సమాచారాన్ని
                రికార్డ్ చేస్తాయి. ఈ సమాచారం మా సైట్‌కు సందర్శకుల నుండి డేటా
                ఆధారంగా గణాంకాలను రూపొందించడానికి, మీరు ఇంతకు ముందు సైట్‌ని
                సందర్శించినప్పుడు మీరు ఎంచుకున్న అంశాల ప్రకారం మీకు ఆసక్తి
                కలిగించే ఉత్పత్తులు, సేవలను సూచించడానికి మమ్మల్ని అనుమతిస్తుంది.
                మీ గురించి ఎలాంటి రహస్య సమాచారాన్ని కుకీలు కలిగి ఉండవు.
              </p>
            </li>
            <li>
              <p>
                నిర్దిష్ట సేవలను యాక్సెస్ చేయడానికి ప్లాట్‌ఫారానికి మీరు డేటాను
                అందించవలసి ఉంటుంది. ఈ సమాచారం మీ ఆర్డర్‌కు సంబంధించిన వివరాలతో
                సహా, అటువంటి విషయాలకు సంబంధించినది కావచ్చు. అటువంటి సమాచారం
                గోప్యత మా నిబంధనలకు మూలస్తంభం అయినప్పటికీ, మా గోప్యతా విధానం
                నిబంధనలకు లోబడి మేము ఉంచుకోవచ్చని, వాడుకోవచ్చని మీరు గుర్తించి,
                అంగీకరిస్తున్నారు, సమ్మతిస్తున్నారు ((ఈ లింక్ అందించడానికి లింక్
                ఇక్కడ ఉంది{' '}
                <a href={`${APP_DOMAIN}legal/privacy-statement`}>
                  {APP_DOMAIN}legal/privacy-statement
                </a>
                ), మా వ్యాపారం, పరిశోధన, విశ్లేషణ కోసం మీరు ఉత్పత్తులు, సేవల
                వినియోగంలో సేకరించిన సమాచారం లేదా డేటా.
              </p>
            </li>
            <li>
              <p>
                మేము (i) చట్టం ప్రకారం అవసరమని లేదా డేటాను ప్రాప్యత చేయడం,
                భద్రపరచడం లేదా బహిర్గతం చేయడం అనే చిత్తశుద్ధి ఉంటే తప్ప, ఈ
                నిబంధనలకు అనుగుణంగా మినహా మీ డేటా లేదా ఏదైనా థర్డ్ పార్టీ
                కస్టమర్ డేటాను మేము ఏ థర్డ్ పార్టీలతో భాగస్వామ్యం చేయం. మా
                హక్కులు, ఆస్తి లేదా భద్రత లేదా మా యూజర్ల లేదా ప్రజల రక్షణ కోసం
                సహేతుకంగా అవసరం; లేదా (ii) మా తరపున (ఉదా., బిల్లింగ్ లేదా డేటా
                నిల్వ) విధులను నిర్వహించడానికి థర్డ్ పార్టీలకు నిర్దిష్ట పరిమిత
                పరిస్థితులలో డేటాను అందించడం, ఇది మేము నిర్దేశించినట్లు మినహా
                డేటాను ఉపయోగించడం లేదా భాగస్వామ్యం చేయకుండా నిరోధించే కఠినమైన
                పరిమితులతో కూడుకుని ఉంటుంది.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మేథోసంపత్తి హక్కులు</h3>
          <ol>
            <li>
              <p>
                మా ప్లాట్‌ఫారం‌లోని మొత్తం కంటెంట్ (ఇలస్ట్రేషన్‌లు, టెక్స్ట్‌లు,
                పేర్లు, బ్రాండ్ పేర్లు, చిత్రాలు, వీడియోలు) కోవెస్ట్రో ఆస్తి.
              </p>
            </li>
            <li>
              <p>
                ఈ కంటెంట్ ఏదైనా పాక్షిక లేదా మొత్తం పునరుత్పత్తి, ఏ విధంగానైనా,
                ఏదైనా మద్దతుపై అయినా, కోవెస్ట్రో ద్వారా ముందస్తు, స్పష్టమైన
                అధికారానికి లోబడి ఉంటుంది.
              </p>
            </li>
            <li>
              <p>
                మొత్తం సమాచారం, కంటెంట్, ఫైల్‌లు మేధో యాజమాన్యం, కాపీరైట్‌కు
                సంబంధించిన చట్టం ద్వారా రక్షించబడతాయి.
              </p>
            </li>
            <li>
              <p>
                మీకు మేధోపరమైన హక్కులు లేని కంటెంట్‌ని కాపీ చేయడానికి,
                ప్రదర్శించడానికి లేదా పంపిణీ చేయడానికి మేము మీకు హక్కు ఇవ్వము.
              </p>
            </li>
            <li>
              <p>
                ఈ కంటెంట్ అన్ని రకాల మోసపూరిత ఉపయోగం థర్డ్ పార్టీ కలిగి ఉన్న
                హక్కుల ఉల్లంఘనగా భావించబడుతుంది, ఇది ఫోర్జరీ నేరంగా
                పరిగణించబడుతుంది, ఈ అంశం మేధో సంపత్తి చట్టాల ప్రకారం కఠినంగా
                శిక్షించబడుతుంది.
              </p>
            </li>
            <li>
              <p>
                సైట్‌లోని తన కార్యకలాపాల ద్వారా యూజర్ థర్డ్ పార్టీ కలిగి ఉన్న
                హక్కులను ఉల్లంఘిస్తే, ఎట్టి పరిస్థితుల్లోనూ మేము బాధ్యత వహించం.
              </p>
            </li>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం నుండి వ్యక్తిగత ఉపయోగం కోసం కాకుండా, కాపీరైట్
                చట్టాలలో పేర్కొనబడినట్లుగా లేదా స్పష్టంగా ఉన్న ఏదైనా ప్రయోజనం
                కోసం ఈ ప్లాట్‌ఫారం నుండి పొందబడిన డేటా, చిత్రాలు, ఏదైనా ఇతర
                కంటెంట్ మొత్తం లేదా భాగాన్ని ఉపయోగించడం, బదిలీ చేయడం, కాపీ
                చేయడం, పంపిణీ చేయడం లేదా సవరించడంతో పాటు ఇతర చట్టాల ద్వారా
                అనుమతించబడినది, కంపెనీ లేదా చెప్పబడిన డేటా, చిత్రాలు, కంటెంట్‌కు
                హక్కులను కలిగి ఉన్నవారి సమ్మతి లేకుండా చేయడం నుండి యూజర్లు
                నిషేధించబడ్డారు.
              </p>
            </li>
            <li>
              <p>
                సైట్‌లో పోస్ట్ చేయబడిన లేదా దాని ద్వారా ప్రసారం చేయబడిన
                మెటీరియల్‌లు లేదా సైట్‌లో తుది యూజర్లు లేదా ఏదైనా ఇతర థర్డ్
                పార్టీ ద్వారా ప్రచారం చేయబడిన అంశాల నుండి ఉత్పన్నమయ్యే మేధో
                సంపత్తి హక్కుల ఉల్లంఘనకు కోవెస్ట్రో బాధ్యత వహించదు.
              </p>
              <ol>
                <li>
                  <p>
                    మీరు మేధో సంపత్తి హక్కుల యజమాని లేదా మేధో సంపత్తి హక్కుల
                    యజమాని తరపున వ్యవహరించడానికి పూర్తి అధికారం కలిగిన ఏజెంట్
                    అయితే, ఏదైనా కంటెంట్ లేదా ఇతర కంటెంట్ మీ మేధో సంపత్తి హక్కు
                    లేదా యజమాని మేధో సంపత్తి హక్కును ఉల్లంఘిస్తుందని విశ్వసిస్తే
                    మీరు పని చేయడానికి అధికారం కలిగి ఉన్నందున, మీరు కోవెస్ట్రోకి
                    ఒక ఇమెయిల్‌ను సమర్పించవచ్చు, అలాగే సంబంధిత కంటెంట్‌ను
                    చిత్తశుద్ధితో తొలగించాలనే అభ్యర్థనతో ఇమెయిల్ ఐడి:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    కు ఇమెయిల్ చేయండి.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>వారెంటీల నిరాకరణ</h3>
          <ol>
            <li>
              <p>
                కోవెస్ట్రో ఏ సమయంలోనైనా నోటీసుతో లేదా నోటీసు లేకుండా ఉత్పత్తులు,
                సేవలు లేదా సాఫ్ట్‌వేర్‌లో మెరుగుదలలుమరియు/లేదా మార్పులు
                చేయవచ్చు. సర్వీస్, సాఫ్ట్‌వేర్లకు సంబంధించిన నివేదికలు “ఎలా ఉంటే
                అలా” అందించబడ్డాయి, వాటిలో దేనికీ సంబంధించి కోవెస్ట్రో ఎటువంటి
                వారెంటీలు ఇవ్వదు. పైన పేర్కొన్న సాధారణ అంశాలకే పరిమితం చేయకుండా,
                కోవెస్ట్రో ఏ ఇతర వాటికి ప్రాతినిధ్యం వహించదు లేదా హామీ ఇవ్వదు:
                <br />
                ఎ) ఉత్పత్తులు, సేవలు లేదా సాఫ్ట్‌వేర్ ఉపయోగం మీ అవసరాలను
                తీరుస్తుంది లేదా అంతరాయం లేకుండా, సురక్షితంగా లేదా దోష రహితంగా
                ఉంటుంది;
                <br />
                బి) అయితే లోపాలు సరి చేయబడతాయి;
                <br />
                సి) సేవ, సాఫ్ట్‌వేర్ లేదా సర్వర్‌లోని ఏదైనా ఇతర సాఫ్ట్‌వేర్
                వైరస్‌లు లేదా ఇతర హానికరమైన భాగాలు లేనివి;
                <br />
                డి) ఉత్పత్తులు, సేవల వినియోగం, ఉత్పత్తులు, సేవల ద్వారా లభించే
                ఏదైనా సమాచారం (వ్యాయామాలకు సంబంధించిన నివేదికలతో సహా) సరైనది,
                ఖచ్చితమైనది, సమయానుకూలమైనది లేదా నమ్మదగినది.
              </p>
            </li>
            <li>
              <p>
                మేము మీ వ్యక్తిగత సమాచారాన్ని, యూజర్ కంటెంట్‌ను ప్రమాదవశాత్తూ
                కోల్పోకుండా, అనధికారిక యాక్సెస్, ఉపయోగం, మార్పు లేదా బహిర్గతం
                నుండి సురక్షితం చేయడానికి రూపొందించిన వాణిజ్యపరంగా సహేతుకమైన
                సాంకేతిక, సంస్థాగత చర్యలను అమలు చేశాం. అయినప్పటికీ, అనధికారిక
                థర్డ్ పార్టీలు ఆ చర్యలను ఎప్పటికీ ఓడించలేవని లేదా మీ వ్యక్తిగత
                సమాచారాన్ని, యూజర్ కంటెంట్‌ను అక్రమ ప్రయోజనాల కోసం ఉపయోగించలేరని
                మేము హామీ ఇవ్వలేము. మీరు మీ స్వంత పూచీతో మీ వ్యక్తిగత
                సమాచారాన్ని అందించారని మీరు ఈ సందర్భంగా అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                సంబంధిత వస్తువులు లేదా సేవల చెల్లింపుతో సహా ప్లాట్‌ఫారం‌లోని
                ఉత్పత్తులు, సేవల ద్వారా లేదా వాటి ద్వారా కనుగొనబడిన ప్రకటనకర్తలు
                లేదా అటువంటి డీలింగ్‌లకు సంబంధించిన ఏవైనా ఇతర నిబంధనలు, షరతులు,
                వారెంటీలు లేదా ప్రాతినిధ్యాలతో సహా మీ కరస్పాండెన్స్ లేదా వ్యాపార
                లావాదేవీలు లేదా ప్రమోషన్‌లలో పాల్గొనడం, మీకు, అటువంటి ప్రకటనకర్త
                లేదా విక్రేతకు మధ్య మాత్రమే ఉంటాయి. ఇక్కడ పేర్కొన్న విధంగా
                మినహాయించి, అటువంటి లావాదేవీల ఫలితంగా లేదా ఉత్పత్తులు,
                సేవలు/మార్కెట్ ప్లేస్‌పై అటువంటి ప్రకటనదారులు లేదా విక్రేతల
                ఉనికి కారణంగా సంభవించే ఏదైనా నష్టం లేదా నష్టానికి కోవెస్ట్రో
                బాధ్యత వహించదు లేదా బాధ్యత వహించదు అని మీరు అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                ఉత్పత్తులు, సేవలు ఇతర వెబ్‌సైట్‌లు లేదా వనరులకు లింక్‌లను
                అందించవచ్చు లేదా థర్డ్ పార్టీలు అందించవచ్చు. అటువంటి సైట్‌లు,
                వనరులపై మాకు నియంత్రణ లేనందున, అటువంటి బాహ్య సైట్‌లు లేదా వనరుల
                లభ్యతకు మేము బాధ్యత వహించమని ఈ సందర్భంగా మీరు గుర్తించి,
                అంగీకరిస్తున్నారు, ఏదైనా కంటెంట్, ప్రకటనలు, ఉత్పత్తులు, అటువంటి
                సైట్‌లు లేదా వనరుల నుండి లభించే ఇతర సేవలను మేము ఆమోదించం, బాధ్యత
                వహించం లేదా బాధ్యత వహించబోమని స్పష్టం చేస్తున్నాము. అటువంటి
                కంటెంట్, వస్తువులు లేదా సేవలపై లేదా వాటి ద్వారా అందుబాటులో ఉన్న
                ఏదైనా ఉపయోగం అటువంటి ఏదైనా సైట్ లేదా వనరు లేదా వాటిపై ఆధారపడటం
                వల్ల లేదా ఆరోపించబడిన ఏదైనా నష్టం లేదా నష్టానికి ప్రత్యక్షంగా
                లేదా పరోక్షంగా మేము బాధ్యత వహించబోమని మీరు గుర్తించి,
                అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం, ఉత్పత్తులు, సేవల ఉపయోగానికి సంబంధించి పూర్తి బాధ్యత
                మీదేనని మీరు అంగీకరిస్తున్నారు. వర్తించే చట్టం ద్వారా గరిష్టంగా
                అనుమతించబడినంత వరకు, ప్లాట్‌ఫారం, ఉత్పత్తులు, సేవలు మీ ఉపయోగం
                కోసం &quot;ఉన్నట్లుగా&quot;, &quot;అందుబాటులో ఉన్నవి&quot;
                ప్రాతిపదికన అందించబడతాయని ఎలాంటి పరిమితి లేకుండా వర్తకం హామీలు,
                నిర్దిష్ట ప్రయోజనం కోసం ఫిట్‌నెస్, టైటిల్, ఉల్లంఘించకపోవడం,
                వాణిజ్యం లావాదేవీ లేదా వినియోగం నుండి ఉత్పన్నమయ్యే వాటితో సహా
                అన్ని అంశాలను మీరు స్పష్టంగా అర్థం చేసుకున్నారు,
                అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో మీరు పోస్ట్ చేసిన లేదా అప్‌లోడ్ చేసిన ఏదైనా
                మెటీరియల్ వారి మేధో సంపత్తి హక్కులు లేదా వారి గోప్యత హక్కును
                ఉల్లంఘిస్తుందని చెల్లుబాటుగా క్లెయిమ్ చేసే, సాక్ష్యాలను అందించే
                ఏదైనా థర్డ్ పార్టీకి మీ గుర్తింపును బహిర్గతం చేసే హక్కు మాకు
                ఉంది.
              </p>
            </li>
            <li>
              <p>
                మీరు లేదా ఉత్పత్తులు, సేవల ఇతర యూజర్లు పోస్ట్ చేసిన ఏదైనా
                కంటెంట్ లేదా ఖచ్చితత్వానికి మేము ఏ థర్డ్ పార్టీకి బాధ్యత వహించం
                లేదా బాధ్యత తీసుకోం.
              </p>
            </li>
            <li>
              <p>
                ఈ నిబంధనలు, షరతులు, ఈ ఒప్పందం లేదా గోప్యతా విధానానికి అనుగుణంగా
                లేని పక్షంలో, మీ యాక్సెస్ లేదా వినియోగ హక్కులను తక్షణమే రద్దు
                చేయడానికి, కంప్లైంట్ లేని సమాచారం లేదా మెటీరియల్‌ని
                తీసివేయడానికి మాకు హక్కు ఉంది.
              </p>
            </li>
            <li>
              <p>
                ఈ నిబంధనలు, షరతుల ఉల్లంఘనలను చట్టం పూర్తి స్థాయిలో
                పరిశోధించడానికి, ప్రాసిక్యూట్ చేయడానికి మాకు హక్కు ఉంది, ఈ
                నిబంధనలు, షరతులను ఉల్లంఘించే యూజర్లను ప్రాసిక్యూట్ చేయడంలో
                చట్టాన్ని అమలు చేసే అధికారులను కలిగి ఉండవచ్చు, సహకరించవచ్చు.
              </p>
            </li>
            <li>
              <p>
                ఉత్పత్తులు, సేవలకు (లేదా ఉత్పత్తులు, సేవలలో ఏదైనా ఫీచర్ లేదా
                భాగం) మీ యాక్సెస్‌ను లేదా మీ వినియోగాన్ని పర్యవేక్షించాల్సిన
                బాధ్యత మాకు లేనప్పటికీ, ప్లాట్‌ఫారం‌ను నిర్వహించడం, ఉత్పత్తులను
                అందించడం కోసం అలా చేసే హక్కు మాకు ఉంది, సేవలు, ఈ నిబంధనలు,
                షరతులతో మీ సమ్మతిని నిర్ధారించడానికి లేదా వర్తించే చట్టం లేదా
                న్యాయస్థానం, అడ్మినిస్ట్రేటివ్ ఏజెన్సీ లేదా ఇతర ప్రభుత్వ సంస్థ
                ఆర్డర్ లేదా ఆవశ్యకతకు అనుగుణంగా ఉంటాయి.
              </p>
            </li>
            <li>
              <p>
                వినియోగదారు నుండి ఫిర్యాదు రసీదుకు లోబడి, ఏదైనా సేవ నుండి ఏదైనా
                లేదా మొత్తం కంటెంట్‌ను ప్రీ-స్క్రీన్ చేయడానికి, సమీక్షించడానికి,
                ఫ్లాగ్ చేయడానికి, ఫిల్టర్ చేయడానికి, సవరించడానికి,
                తిరస్కరించడానికి లేదా తీసివేయడానికి మాకు హక్కు ఉంది (కానీ
                ఎటువంటి బాధ్యత ఉండదు). కొన్ని ఉత్పత్తులు, సేవల కోసం, కోవెస్ట్రో
                తన స్వంత అభీష్టానుసారం అనుచితమైనదిగా భావించే స్పష్టమైన లైంగిక
                కంటెంట్ లేదా ఏదైనా కంటెంట్‌ను ఫిల్టర్ చేయడానికి సాధనాలను
                అందించవచ్చు (కానీ ఎటువంటి బాధ్యత ఉండదు).
              </p>
            </li>
            <li>
              <p>
                ఏదైనా సమాచారాన్ని యాక్సెస్ చేయడానికి, చదవడానికి, భద్రపరచడానికి,
                బహిర్గతం చేయడానికి మాకు హక్కు ఉంది: (ఎ) ఏదైనా వర్తించే చట్టం,
                నియంత్రణ, చట్టపరమైన ప్రక్రియ లేదా ప్రభుత్వ అభ్యర్థనను సంతృప్తి
                పరచడం; (బి) ఈ నిబంధనలు, షరతులను అమలు చేయడం, ఇందులోని సంభావ్య
                ఉల్లంఘనల విచారణతో సహా; (సి) మోసం, భద్రత లేదా సాంకేతిక సమస్యలను
                గుర్తించడం, నిరోధించడం లేదా పరిష్కరించడం; (డి) యూజర్ మద్దతు
                అభ్యర్థనలకు ప్రతిస్పందించడం; లేదా (ఇ) కోవెస్ట్రో, దాని యూజర్లు,
                ప్రజల హక్కులు, ఆస్తి లేదా భద్రతను రక్షించడం. కోవెస్ట్రో ఈ
                విషయంలో ఈ నిబంధనలు, షరతుల ప్రకారం దాని హక్కులను అమలు చేయడం లేదా
                ఉపయోగించకపోవడంపై బాధ్యత తీసుకోదు లేదా బాధ్యత వహించదు.
              </p>
            </li>
            <li>
              <p>
                మా నుండి 30 (ముప్పై) రోజుల నోటీసుపై అన్నిరకాల సేవల ధరలను
                ప్రవేశపెట్టడానికి లేదా మార్చడానికి మాకు హక్కు ఉంది. ఈ
                ప్లాట్‌ఫారం‌లోనే యూజర్ నిబంధనలు, షరతులు లేదా ఉత్పత్తులు, సేవలకు
                మార్పులను పోస్ట్ చేయడం ద్వారా అటువంటి నోటీసు ఏ సమయంలో అయినా
                అందించబడవచ్చు.
              </p>
            </li>
            <li>
              <p>
                ఉత్పత్తులు, సేవల ఏదైనా సవరణ, ధర మార్పు, సస్పెన్షన్ లేదా
                నిలిపివేత కోసం కోవెస్ట్రో మీకు లేదా ఏదైనా థర్డ్ పార్టీకి బాధ్యత
                వహించదు.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>థర్డ్ పార్టీల ద్వారా యూజర్ సమర్పణ, యూజర్ కంటెంట్</h3>
          <ol>
            <li>
              <p>
                ప్లాట్‌ఫారం‌ను ఉపయోగిస్తున్నప్పుడు, మీరు వివిధ మూలాల నుండి
                కంటెంట్‌ను బహిర్గతం చేస్తారని, అటువంటి కంటెంట్ ఖచ్చితత్వం,
                ఉపయోగం, భద్రత లేదా మేధో సంపత్తి హక్కులకు లేదా దానికి సంబంధించిన
                మేధో సంపత్తి హక్కులకు కోవెస్ట్రో బాధ్యత వహించదని మీరు అర్థం
                చేసుకున్నారు, మీరు అన్నింటినీ అంగీకరిస్తున్నారు, మీ ఉపయోగం కోసం
                బాధ్యతను మీరే స్వీకరించారని భావించడమైనది.
              </p>
            </li>
            <li>
              <p>
                ఆందోళనలను తగ్గించడానికి కోవెస్ట్రో ఉత్తమ ప్రయత్నాలను అమలు
                చేయడానికి ప్రయత్నిస్తున్నప్పుడు, మీరు సరికాని, అభ్యంతరకరమైన,
                అసభ్యకరమైన లేదా అభ్యంతరకరమైన, పరువు నష్టం కలిగించే లేదా
                అవమానకరమైన కంటెంట్‌కు గురికావచ్చని మీరు మరింత అర్థం
                చేసుకున్నారు, అంగీకరిస్తున్నారు, మీరు మాఫీ చేయడానికి
                అంగీకరిస్తున్నారు, దీని ద్వారా ఏదైనా మాఫీ చేయడం కోవెస్ట్రోకి
                సంబంధించి మీకు చట్టపరమైన లేదా సమానమైన హక్కులు లేదా నివారణలు
                ఉన్నాయి లేదా కలిగి ఉండవచ్చు.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మినహాయింపు మరియు వేర్పాటు</h3>
          <ol>
            <li>
              <p>
                సేవా నిబంధనల ఏదైనా హక్కు లేదా నిబంధనను అమలు చేయడంలో లేదా అమలు
                చేయడంలో కోవెస్ట్రో ఏదైనా వైఫల్యం అటువంటి హక్కు లేదా నిబంధన
                మాఫీని ఏర్పరచదు.
              </p>
            </li>
            <li>
              <p>
                సేవా నిబంధనలు మీకు, మాకు మధ్య ఉన్న మొత్తం ఒప్పందాన్ని
                ఏర్పరుస్తాయి, సేవ మీ వినియోగాన్ని నియంత్రిస్తాయి, ఏవైనా ముందస్తు
                ఒప్పందాలను (సేవా నిబంధనల ఏవైనా మునుపటి సంస్కరణలతో సహా, కానీ
                వీటికే పరిమితం కాకుండా) భర్తీ చేస్తాయి. ఈ సేవా నిబంధనలలోని ఏదైనా
                నిబంధన ఏదైనా కారణం చేత చెల్లనిది, చట్టవిరుద్ధమైనది లేదా అమలు
                చేయలేనిది అని సమర్థ అధికార పరిధిలోని న్యాయస్థానం భావిస్తే,
                అటువంటి నిబంధన తొలగించబడుతుంది లేదా సేవా నిబంధనల మిగిలిన
                నిబంధనలు కొనసాగే విధంగా కనీస స్థాయికి పరిమితం చేయబడుతుంది.
                పూర్తి నియంత్రణతో అమలవుతుంది.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో లేదా సేవ ద్వారా ఏవైనా సంస్థలు మరియు/లేదా
                వ్యక్తులతో మీ పరస్పర చర్యలకు కోవెస్ట్రో బాధ్యత వహించదని మీరు
                స్పష్టంగా గుర్తించి, అంగీకరిస్తున్నారు. ఇది వస్తువులు, సేవల
                చెల్లింపు, డెలివరీ, ఇతర సంస్థలు మరియు/లేదా వ్యక్తులతో మీరు కలిగి
                ఉండే ఏదైనా పరస్పర చర్యతో అనుబంధించబడిన ఏవైనా ఇతర నిబంధనలు,
                షరతులు, వారెంటీలు లేదా ప్రాతినిధ్యాలను కలిగి ఉంటుంది, కానీ
                వీటికే పరిమితం కాదు. ఈ లావాదేవీలు మీకు, అటువంటి సంస్థలు
                మరియు/లేదా వ్యక్తుల మధ్య మాత్రమే ఉంటాయి.
              </p>
            </li>
            <li>
              <p>
                అటువంటి లావాదేవీలు లేదా పరస్పర చర్యల ఫలితంగా సంభవించే ఏదైనా
                నష్టం లేదా నష్టానికి కోవెస్ట్రో బాధ్యత వహించదని, బాధ్యత వహించదని
                మీరు అంగీకరిస్తున్నారు మరియు ఒప్పుకుంటున్నారు. ప్లాట్‌ఫారం‌లో
                పాల్గొనేవారి మధ్య లేదా యూజర్లు, ఏదైనా థర్డ్ పార్టీ మధ్య వివాదం
                ఉన్నట్లయితే, అటువంటి వివాదంలో పాల్గొనడానికి కోవెస్ట్రో ఎటువంటి
                బాధ్యత వహించదని మీరు అర్థం చేసుకుని, అంగీకరిస్తున్నారు.
              </p>
            </li>
            <li>
              <p>
                మీకు ఒకరు లేదా అంతకంటే ఎక్కువ మంది ఇతర యూజర్లతో వివాదం ఉన్న
                సందర్భంలో, మీరు దీని ద్వారా కోవెస్ట్రో, దాని అధికారులు,
                ఉద్యోగులు, ఏజెంట్లు, వారసులను ఏదైనా, అన్ని క్లెయిమ్‌లు,
                డిమాండ్‌లు, నష్టాల (వాస్తవమైన, పర్యవసానమైన) ప్రతి రకమైన లేదా
                స్వభావం నుండి విడుదల చేస్తారు, తెలిసిన లేదా తెలియని,
                అనుమానించబడిన, అనుమానించని, బహిర్గతం చేయబడిన, బహిర్గతం చేయని,
                అటువంటి వివాదాలు మరియు/లేదా మా సేవకు సంబంధించిన లేదా ఏ
                విధంగానైనా ఉత్పన్నమయ్యేవి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మొబైల్ పరికరాల ఉపయోగం</h3>
          <ol>
            <li>
              <p>
                మీరు మొబైల్ పరికరంలో యాప్‌ని ఉపయోగిస్తుంటే మీ క్యారియర్ సాధారణ
                రేట్లు, టెక్స్ట్ మెసేజింగ్, డేటా ఛార్జీలు వంటి రుసుములు ఇప్పటికీ
                వర్తిస్తాయని దయచేసి గమనించండి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>నిషేధించబడిన చర్యలు</h3>
          <ol>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం యూజర్లు ఈ నిబంధనలు, షరతులలోని ఇతర నిబంధనల ద్వారా
                నిషేధించబడిన వాటితో సహా ఈ కింద పేర్కొనబడిన చర్యలలో పాల్గొనడం
                నుండి నిషేధించబడ్డారు:
              </p>
              <ul>
                - కాపీరైట్‌లు, గోప్యత, ఆస్తి, ఇతర హక్కులు లేదా ఈ ప్లాట్‌ఫారం ఇతర
                యూజర్లు, థర్డ్ పార్టీలు, కంపెనీ లేదా దాని భాగస్వామి కంపెనీల
                ప్రయోజనాలను ఉల్లంఘించే లేదా అతిక్రమించే చర్యలు.
              </ul>
              <ul>
                - ఈ ప్లాట్‌ఫారం, థర్డ్ పార్టీలు, కంపెనీ లేదా దాని భాగస్వామ్య
                కంపెనీల ఇతర యూజర్లకు ప్రతికూలత, ఆర్థిక నష్టం లేదా ఇతర నష్టాలను
                కలిగించే లేదా కలిగించే చర్యలు.
              </ul>
              <ul>
                - ప్రభుత్వ ఆదేశం, నైతికతలకు హాని కలిగించే లేదా అవమానకరమైన
                చర్యలు, లేదా చట్టాలు, నిబంధనలు, శాసనాలను ఉల్లంఘించవచ్చు.
              </ul>
              <ul>
                - సభ్యత్వ నమోదు లేదా ఇతర ప్రయోజనాల కోసం ఈ ప్లాట్‌ఫారం‌ను
                ఉపయోగిస్తున్నప్పుడు తప్పుడు ప్రకటనలు.
              </ul>
              <ul>
                - ఇమెయిల్ చిరునామాలు, పాస్‌వర్డ్‌లు లేదా ఇతర వ్యక్తిగత
                సమాచారాన్ని చట్టవిరుద్ధంగా ఉపయోగించడం.
              </ul>
              <ul>
                - ఈ ప్లాట్‌ఫారం‌ను వ్యాపారాన్ని అభ్యర్థించడానికి లేదా లాభం కోసం
                ఇతర వాణిజ్య లక్ష్యాలను సాధించడానికి లేదా దాని తయారీకి
                ఉపయోగించడం.
              </ul>
              <ul>
                - ఈ ప్లాట్‌ఫారం ఆపరేషన్‌కు అంతరాయం కలిగించే లేదా సేవలను
                అందించడంలో జోక్యం చేసుకునే ఇతర చర్యలు.
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>పాలక చట్టాలు</h3>
          <ol>
            <li>
              <p>
                ఈ నిబంధనలు, ఉపయోగ షరతుల సూత్రీకరణ, చట్టపరమైన బలం, అప్లికేషన్,
                వివరణ భారతదేశ చట్టాలచే నిర్వహించబడుతుంది.
              </p>
            </li>
            <li>
              <p>
                కోవెస్ట్రో భారతదేశం కాకుండా మరే ఇతర దేశంలోని చట్టాలను
                పాటించనందుకు ప్రత్యక్షంగా లేదా పరోక్షంగా ఎటువంటి బాధ్యతను
                అంగీకరించదు, భారతదేశం కాకుండా వేరే దేశంలలో ప్లాట్‌ఫారం‌ను
                యాక్సెస్ చేయవచ్చు లేదా ఉపయోగించవచ్చు లేదా ఏదైనా సదుపాయాన్ని
                పొందవచ్చనే వాస్తవం సూచించదు. అటువంటి దేశం చట్టాలకు మేము
                అంగీకరిస్తాము.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>అధికార పరిధి</h3>
          <ol>
            <li>
              <p>
                ఈ ప్లాట్‌ఫారం‌ను ఉపయోగించడంపై యూజర్, కంపెనీ లేదా భాగస్వామి
                కంపెనీ మధ్య వివాదాలు మహారాష్ట్రలోని ముంబై కోర్టులలో ప్రత్యేకంగా
                విచారించబడతాయి.
              </p>
            </li>
            <li>
              <p>
                పైన పేర్కొన్న వాటితో పాటుగా, కోవెస్ట్రో సమర్థ అధికార పరిధిలోని
                ఏదైనా న్యాయస్థానంలో నిషేధాజ్ఞల నివారణలను (లేదా సమానమైన తక్షణ
                చట్టపరమైన ఉపశమనం) కోరే హక్కును కలిగి ఉంటుందని ఈ సందర్భంగా మీరు
                అంగీకరిస్తున్నారు.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>నిబంధనలు మరియు ప్లాట్‌ఫారం‌ల సవరణ</h3>
          <ol>
            <li>
              <p>
                ప్లాట్‌ఫారం అనేది పురోగతిలో ఉన్న నిరంతర పని, చేర్పులు లేదా
                తొలగింపులు లేదా ప్రత్యామ్నాయాలు లేదా ఇతర మార్గాల ద్వారా
                మార్పులకు లోబడి ఉంటుంది.
              </p>
            </li>
            <li>
              <p>
                వెబ్‌సైట్, యాప్, ఈ నిబంధనలను ఎప్పటికప్పుడు, మా అభీష్టానుసారం,
                ఎటువంటి నోటీసు లేకుండా అప్‌డేట్ చేసే హక్కు మాకు ఉంది.
              </p>
            </li>
            <li>
              <p>
                ప్లాట్‌ఫారం లేదా సేవలను యాక్సెస్ చేసిన ప్రతిసారీ తాజా నిబంధనలను
                చదవడం ద్వారా వాటి గురించి తెలుసుకోవడం కస్టమర్ ఏకైక బాధ్యత, విధి.
              </p>
            </li>
            <li>
              <p>
                అప్‌డేట్ చేయబడిన సేవా నిబంధనలను ప్రచురించిన తర్వాత
                ప్లాట్‌ఫారం‌ను కొనసాగించిన తర్వాత, యూజర్ సవరించిన నిబంధనలను
                చదివి అంగీకరించినట్లు భావించబడుతుంది.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>మీ స్వంత బాధ్యతపై ఉపయోగం</h3>
          <ol>
            <li>
              <p>
                ఆహార భద్రతకు సహాయం చేయడానికి, ప్రేరేపించడానికి, నిర్ధారించడానికి
                అసలైన, పరిశోధన-ఆధారిత పరిష్కారాలను అభివృద్ధి చేయడం ద్వారా
                మార్కెట్ సేవలను అందించడం మా లక్ష్యం. అయితే, ప్లాట్‌ఫారం ఏ
                థర్డ్-పార్టీ విక్రేతల నాణ్యతకు హామీ ఇవ్వదు, బాధ్యత తీసుకోదు.
                మీరు ప్లాట్‌ఫారం‌ని ఉపయోగించడం, ప్లాట్‌ఫారం‌లో అందించబడిన ఏదైనా
                సమాచారం, అంచనాలు లేదా సూచనలు మీ సంతృప్తిని కలిగి ఉంటాయి. మేము
                ప్లాట్‌ఫారం ద్వారా మీకు అందించగల నాణ్యత, పరిమాణం, డేటా, సమాచారం,
                అంచనాలు, అంచనాల ఖచ్చితత్వానికి సంబంధించి మేము ఎలాంటి
                ప్రాతినిధ్యం లేదా హామీని ఇవ్వము, ప్లాట్‌ఫారం ఎలక్ట్రానిక్‌గా
                పనిచేయడానికి ఉద్దేశించబడిందని మీరు అంగీకరిస్తున్నారు, అర్థం
                చేసుకున్నారు మార్కెట్ స్థలం, కొనుగోలుదారు రిస్కుకు లోబడి ఉంటాయి.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>అభిప్రాయాలు, ఫీడ్‌బ్యాక్</h3>
          <ol>
            <li>
              <p>
                ప్లాట్‌ఫారం‌లో మీరు కోవెస్ట్రోకి ఇచ్చిన ఏవైనా అభిప్రాయాలు
                స్క్రీనింగ్, నియంత్రణకు లోబడి ఉంటాయి.
              </p>
            </li>
            <li>
              <p>
                ఈ వ్యాఖ్యలు చట్టాన్ని ఉల్లంఘిస్తున్నట్లు లేదా అనుచితమైనవి
                (దుర్వినియోగ ప్రచారం, పరువు నష్టం, అవమానాలు, సందర్భానుసారం
                వ్యాఖ్యానం లేనివి) ఉన్నట్లు గుర్తించినట్లయితే, దానిని
                తిరస్కరించే లేదా సవరించే హక్కు మాకు ఉంది, దీనిపై తగిన చర్య
                తీసుకోవచ్చు.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TNCTE;
