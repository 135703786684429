/* eslint-disable no-useless-escape */
/* eslint-disable security/detect-unsafe-regex */
import * as Yup from 'yup';
import Messages from '../config/messages';

export const RegisterSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      // .email("Enter a valid email")
      .required(Messages.EMAIL_OR_MOBILE_VALIDATION_MSG)
      .test(
        'test-name',
        Messages.INVALID_EMAIL_OR_MOBILE_MSG,
        function (value = '') {
          const emailRegex =
            /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex =
            /^((?:(?:\+|0{0,2})91([0-9]*[\-][0-9]*)?|[0]?)?[6-9][0-9]{9})$/; // Change this regex based on requirement
          const isValidEmail = emailRegex.test(value);
          const isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
      ),
    tnc: Yup.boolean().oneOf([true], Messages.CHECK_TNC_MSG),
    // password: Yup.string()
    //   .required('Password cannot be empty')
    //   .min(8, 'Minimum 8'),
    // stakeholderCategory: Yup.string().required('Select Stakeholder Category'),
    // profilePic: Yup.string().required('Select file'),
  });

export const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      // .email("Enter a valid email")
      .required(Messages.EMAIL_OR_MOBILE_VALIDATION_MSG)
      .test(
        'test-name',
        Messages.INVALID_EMAIL_OR_MOBILE_MSG,
        function (value = '') {
          const emailRegex =
            /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex =
            /^((?:(?:\+|0{0,2})91([0-9]*[\-][0-9]*)?|[0]?)?[6-9][0-9]{9})$/; // Change this regex based on requirement
          const isValidEmail = emailRegex.test(value);
          const isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
      ),
    // password: Yup.string()
    //   .required('Password cannot be empty')
    //   .min(8, 'Minimum 8'),
    // stakeholderCategory: Yup.string().required('Select Stakeholder Category'),
    // profilePic: Yup.string().required('Select file'),
  });

export const RegisterOTPSchema = () =>
  Yup.object().shape({
    otp: Yup.string().required(Messages.OTP_VALIDATION_MSG),
  });

export const UserDetailSchema = () =>
  Yup.object().shape({
    stakeholderCategory: Yup.string().required(
      Messages.STAKEHOLDER_CATEGORY_MSG
    ),
    stakeholderType: Yup.string().required(Messages.STAKEHOLDER_TYPE_MSG),
    // nameOfOrganization: Yup.string().required(Messages.NAME_ORGANIZATION_MSG),
    nameOfOrganization: Yup.string()
      .nullable()
      .when('stakeholderType', {
        is: (stakeholderType: any) => stakeholderType != 'Individual Farmer',
        then: Yup.string().nullable().required(Messages.NAME_ORGANIZATION_MSG),
      }),

    firstName: Yup.string()
      .required(Messages.FIRST_NAME_MSG)
      .min(2, Messages.FIRST_NAME_MIN_MSG),
    lastName: Yup.string().nullable().min(2, Messages.LAST_NAME_MIN_MSG),
    landlineNumber: Yup.string().nullable(),
    mobileNumber: Yup.string()
      .required(Messages.MOBILE_NUMBER_MSG)
      .test('test-name', Messages.INVALID_MOBILE_MSG, function (value = '') {
        const phoneRegex =
          /^((?:(?:\+|0{0,2})91([0-9]*[\-][0-9]*)?|[0]?)?[6-9][0-9]{9})$/; // Change this regex based on requirement
        const isValidPhone = phoneRegex.test(value);
        if (!isValidPhone) {
          return false;
        }
        return true;
      }),
    email: Yup.string().email(Messages.INVALID_EMAIL_MSG).nullable(),
    // password: Yup.string()
    //   .required('Password cannot be empty')
    //   .min(8, 'Minimum 8'),

    pinCode: Yup.string().required(Messages.PINCODE_MSG),
    state: Yup.string().required(Messages.STATE_MSG),
    district: Yup.string().required(Messages.DISTRICT_MSG),
    villageLocality: Yup.string().required(Messages.VILLAGE_OR_LOCALITY_MSG),
    locationId: Yup.string().required(Messages.VILLAGE_OR_LOCALITY_MSG),
    fpoRegNumber: Yup.string().nullable(),
    fpoPromoBy: Yup.string().nullable(),
    yearOfReg: Yup.string()
      .nullable()
      .when('stakeholderType', {
        is: (stakeholderType: any) =>
          stakeholderType == 'Farmer Producer Organisation (FPOs)',
        then: Yup.string().nullable().required(Messages.YEAR_OF_REG_MSG),
      }),
    regFarmer: Yup.string()
      .nullable()
      .when('stakeholderType', {
        is: (stakeholderType: any) =>
          stakeholderType == 'Farmer Producer Organisation (FPOs)',
        then: Yup.string().nullable().required(Messages.REG_FARMER_MSG),
      }),
    annualTurnover: Yup.string().nullable(),
    profilePic: Yup.string().nullable(),
  });

export const UserProfileDetailSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .required(Messages.FIRST_NAME_MSG)
      .min(2, Messages.FIRST_NAME_MIN_MSG),
    lastName: Yup.string().nullable().min(2, Messages.LAST_NAME_MIN_MSG),

    pinCode: Yup.string().required(Messages.PINCODE_MSG),
    state: Yup.string().required(Messages.STATE_MSG),
    district: Yup.string().required(Messages.DISTRICT_MSG),
    villageLocality: Yup.string().required(Messages.VILLAGE_OR_LOCALITY_MSG),
    locationId: Yup.string().required(Messages.VILLAGE_OR_LOCALITY_MSG),
    profilePic: Yup.string().nullable(),
  });

export const ContactUsSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .required(Messages.NAME_MSG)
      .min(2, Messages.FIRST_NAME_MIN_MSG),
    phone_number: Yup.string().required(Messages.MOBILE_NUMBER_MSG),
    category: Yup.string().required(Messages.CATEGORY_VALIDATION_MSG),
    email: Yup.string()
      .email(Messages.INVALID_EMAIL_MSG)
      .required(Messages.EMAIL_VALIDATION_MSG),
    message: Yup.string().required(Messages.MESSAGE_MSG),
  });
