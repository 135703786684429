import Messages from '../config/messages';
import httpService, { HttpMethod } from './http.service';

export async function submitPost(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'post',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getPosts(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `post?${req}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getUsersPosts(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `post/user?${req}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function submitPostComment(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'comment',
      data: req,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function submitRespond(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'comment',
      data: req,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function likePost(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'like',
      data: req,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getPostComments(params: any, postId: number) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `comment/post/${postId}?${params}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getPostCommentsChat(
  postId: number,
  toUserId: number,
  params: any
) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `comment/post/${postId}/user/${toUserId}?${params}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getPostDetails(postId: number) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `post/${postId}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function viewPost(req: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.POST,
      url: 'post/view',
      data: req,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getPrivateCommentUsersByPostId(postId: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `comment/post/privatecomment/${postId}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function updatePost(params: any) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.PUT,
      url: 'post',
      data: params,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function deletePost(postId: number) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.DELETE,
      url: `post/${postId}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function extendExpiry(id: number) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.PUT,
      url: `post/${id}/extend`,
      data: {},
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}

export async function getLikePostCount(postId: number) {
  try {
    const resp: any = await httpService.execute({
      method: HttpMethod.GET,
      url: `like/${postId}`,
    });

    return resp;
  } catch (error) {
    return {
      error: true,
      message: Messages.SERVER_ERROR,
    };
  }
}
