import { APP_DOMAIN } from '../../../config/appconfig';

const TNCKN: React.FC = () => {
  //   const tableCotent = [
  //     {
  //       title: 'ACCEPTANCE OF THE TERMS AND CONDITIONS OF ACCESS AND USAGE',
  //       page: '5',
  //     },
  //     {
  //       title: 'THIRD-PARTY SERVICES',
  //       page: '6',
  //     },
  //     {
  //       title: 'ACCESS AND USE',
  //       page: '6',
  //     },
  //     {
  //       title: 'MEMBERSHIP/REGISTRATION AND ELIGIBILITY',
  //       page: '7',
  //     },
  //     { title: 'TERMS OF USE OF THE PLATFORM', page: '9' },
  //     { title: 'RESTRICTION ON USE OF SERVICES', page: '10' },
  //     { title: 'CONTENT', page: '11' },
  //     { title: 'LIMITED LICENSE TO THE APP', page: '12' },
  //     { title: 'BREACH OF CONTENT STANDARDS', page: '13' },
  //     { title: 'BILLING AND PAYMENT', page: '14' },
  //     { title: 'INDEMNITY AND LIMITATION OF LIABILITY', page: '14' },
  //     { title: 'OFFENCES', page: '16' },
  //     { title: 'ADDITIONAL TERMS FOR THE MOBILE APP', page: '17' },
  //     { title: 'AUTHORISED ACCESS', page: '17' },
  //     { title: 'CONFIDENTIALITY', page: '19' },
  //     { title: 'PRIVACY AND PROTECTION OF PERSONAL DATA', page: '19' },
  //     { title: 'INTELLECTUAL PROPERTY', page: '20' },
  //     { title: 'DISCLAIMER OF WARRANTIES', page: '21' },
  //     { title: 'USER SUBMISSION AND USER CONTENT BY THIRD PARTIES', page: '23' },
  //     { title: 'WAIVER AND SEVERABILITY', page: '24' },
  //     { title: 'USE OF MOBILE DEVICES', page: '24' },
  //     { title: 'PROHIBITED ACTIONS', page: '25' },
  //     { title: 'GOVERNING LAWS', page: '25' },
  //     { title: 'JURISDICTION', page: '25' },
  //     { title: 'MODIFICATION OF TERMS AND THE PLATFORM', page: '26' },
  //     { title: 'USE AT YOUR OWN RISK', page: '26' },
  //     { title: 'OPINIONS AND FEEDBACK', page: '26' },
  //   ];
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        ಕೊವೆಸ್ಟ್ರೋ ಫುಡ್ ಸೆಕ್ಯುರಿಟಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಪ್ರವೇಶ ಮತ್ತು ಬಳಕೆಗೆ ಸಂಬಂಧಿಸಿದ
        ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು
      </h3>
      {/* {tableCotent.map((item: any, index: number) => {
        return (
          <div key={index} className='table-row '>
            <div className='first-col'>{index + 1}.</div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <div style={{ flex: 0.1, textAlign: 'right' }}>{item.page}</div>
          </div>
        );
      })}
      <h4 style={{ textAlign: 'center', marginTop: 40 }}>
        TERMS AND CONDITIONS OF ACCESS AND USAGE FOR COVESTRO FOOD SECURITY
        PLATFORM
      </h4> */}
      <h4 style={{ textAlign: 'center' }}>
        ಕೊನೆಯದಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ [ಜುಲೈ 28, 2022]
      </h4>
      <p>
        ಮೊದಲು ಬೇಯರ್ ಮೆಟೀರಿಯಲ್ ಸೈನ್ಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತಿದ್ದ
        ಕೊವೆಸ್ಟ್ರೋ (ಇಂಡಿಯಾ) ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್ ನಲ್ಲಿ ನಮ್ಮ ಸಮುದಾಯದ ಭಾಗವಾಗುವುದನ್ನು
        ಆಯ್ಕೆ ಮಾಡಿರುವುದಕ್ಕೆ ಧನ್ಯವಾದಗಳು. ಇದು ಕಂಪನಿಗಳ ಕಾಯಿದೆ, 1956 ರ ಅಡಿಯಲ್ಲಿ
        ಸಂಘಟಿತವಾದ ಖಾಸಗಿ ಲಿಮಿಟೆಡ್ ಕಂಪನಿಯಾಗಿದೆ ಮತ್ತು ಘಟಕ ಸಂಖ್ಯೆ SB-801, 8ನೆಯ ಮಹಡಿ,
        ಎಂಪೈರ್ ಟವರ್, ಕ್ಲೌಡ್ ಸಿಟಿ ಕ್ಯಾಂಪಸ್, ಐರೋಲಿ, ಥಾಣೆ - ಬೇಲಾಪುರ್ ರಸ್ತೆ ನವಿ
        ಮುಂಬೈ ಥಾಣೆ MH 400708 IN ನಲ್ಲಿ ತನ್ನ ನೋಂದಾಯಿತ ಕಚೇರಿಯನ್ನು ಹೊಂದಿದೆ
        (&quot;ಕೊವೆಸ್ಟ್ರೋ&quot;, &quot;ಕಂಪನಿ&quot;, &quot;ನಾವು&quot;,
        &quot;ನಮಗೆ&quot;, ಅಥವಾ &quot;ನಮ್ಮ&quot;, ಅಥವಾ
        &quot;ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌&quot;).
      </p>
      <p>
        ಕೆಳಗೆ ವಿವರಿಸಿದ ಈ ನಿಯಮಗಳು &quot;ಬಳಕೆದಾರ&quot; (ಇನ್ನು ಮುಂದೆ
        &quot;ನೀವು&quot; ಅಥವಾ &quot;ಗ್ರಾಹಕ&quot; ಎಂದೂ ಸಹ ಉಲ್ಲೇಖಿಸಲಾಗುತ್ತದೆ)
        ಮತ್ತು ಕೊವೆಸ್ಟ್ರೋ ನಡುವಿನ ಒಪ್ಪಂದದ ಸಂಬಂಧವನ್ನು ನಿಯಂತ್ರಿಸುತ್ತವೆ. ಬಳಕೆದಾರರು
        ಅಥವಾ &quot;ನೀವು&quot; ಎಂಬ ಪದವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಸಮರ್ಪಕವಾಗಿ ನೋಂದಾಯಿಸಿದ
        ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ ಅನ್ನು ಸರಿಯಾಗಿ ಬಳಸುತ್ತಿರುವ ಯಾವುದೇ ವ್ಯಕ್ತಿ ಅಂದರೆ
        ಗ್ರಾಹಕರು, ರೈತರು ಮತ್ತು/ಅಥವಾ ರೈತರ ಸಂಘಗಳು/ಗುಂಪುಗಳು ಎಂಬುದಾಗಿ
        ಅರ್ಥೈಸಿಕೊಳ್ಳಲಾಗುತ್ತದೆ ಎಂದು ಈ ಮೂಲಕ ಸ್ಪಷ್ಟಪಡಿಸಲಾಗಿದೆ.
      </p>
      <p>
        ಬಳಕೆದಾರರಿಗೆ ಹಣಕಾಸು ಯೋಜನೆಗಳು ಅಥವಾ ನೀತಿಗಳು, ಆಹಾರ ಮತ್ತು ಕೃಷಿ ಉತ್ಪನ್ನಗಳಿಗೆ
        ಸಂಬಂಧಿಸಿದಂತೆ ಮಾರ್ಗದರ್ಶನ ಒದಗಿಸುವುದು, ಹಾಗೂ ಬಳಕೆದಾರರಿಗೆ ವಿವಿಧ ಮೂರನೆಯ
        ವ್ಯಕ್ತಿ ಮಾರಾಟಗಾರರಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಪ್ರವೇಶವನ್ನು ಒದಗಿಸುವುದನ್ನು ಒಳಗೊಂಡಿರುವ
        ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಒದಗಿಸುವುದಕ್ಕೆ ಕೊವೆಸ್ಟ್ರೋ ಈ
        ವೆಬ್‌ಸೈಟ್/ಅಪ್ಲಿಕೇಶನ್‌ [ಫುಡ್ ಸೆಕ್ಯುರಿಟಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌], ಮತ್ತು/ಅಥವಾ
        ಯಾವುದೇ ಇತರ ಸಂಬಂಧಿತ ಸೇವೆಗಳು, ಮಾರಾಟಗಳು, ಮಾರ್ಕೆಟಿಂಗ್ ಅಥವಾ ಈವೆಂಟ್‌ಗಳನ್ನು
        ರಚಿಸಿದೆ. ಬಳಕೆದಾರರು ಒದಗಿಸಿದ ಮಾಹಿತಿಯನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ಅದಕ್ಕೆ ಮಾತ್ರ
        ಸೀಮಿತವಾಗಿರದೇ ಮತ್ತು ಪ್ರತಿ ಬಳಕೆದಾರರಿಗೆ ಮಾತ್ರ ನಿರ್ದಿಷ್ಟವಾಗಿರುವಂತಹ ಹಲವಾರು
        ಅಂಶಗಳನ್ನು ಶಿಫಾರಸುಗಳು ಪರಿಗಣಿಸುತ್ತವೆ, ಆದಾಗ್ಯೂ ಪ್ಲ್ಯಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ
        ಲಭ್ಯವಿರುವ ಯಾವುದೇ ಹಣಕಾಸು ಯೋಜನೆಗಳು ಅಥವಾ ನೀತಿಗಳು, ಆಹಾರ ಮತ್ತು ಕೃಷಿ ಉತ್ಪನ್ನಗಳ
        ಗುಣಮಟ್ಟ ಅಥವಾ ದೃಢೀಕರಣವನ್ನು ನಾವು ಸಮರ್ಥಿಸುವುದಿಲ್ಲ ಎಂಬುದನ್ನು ಸ್ಪಷ್ಟವಾಗಿ
        ಪುನರುಚ್ಚರಿಸಲಾಗಿದೆ ಮತ್ತು ಹೇಳಲಾಗಿದೆ ಮತ್ತು ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಆನ್‌ಲೈನ್
        ಮಾರುಕಟ್ಟೆ ಸ್ಥಳವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುವ ಒಂದು ಸೇತುವೆಯಾಗಿದೆ ಅಷ್ಟೇ.
      </p>
      <p>
        ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ನಿಮ್ಮ ಬಳಕೆಯು ನೀವು ಕೆಳಗಿನ, ಹಕ್ಕು ನಿರಾಕರಣೆ, ಷರತ್ತುಗಳು
        ಮತ್ತು ನಿಬಂಧನೆಗಳನ್ನು (&quot;ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು&quot;) ಓದಿದ್ದೀರಿ,
        ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಮತ್ತು ಅದಕ್ಕೆ ಬದ್ಧರಾಗಿರಲು ಒಪ್ಪಿಕೊಂಡಿದ್ದೀರಿ ಎಂಬುದನ್ನು
        ಸೂಚಿಸುತ್ತದೆ. ನಮ್ಮ ಗೌಪ್ಯತೆ ನೀತಿಯೊಂದಿಗೆ ಓದಿದಾಗ ಈ ಷರತ್ತುಗಳು ಮತ್ತು
        ನಿಬಂಧನೆಗಳು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ನಿಮ್ಮ ಬಳಕೆ ಹಾಗೂ ಪ್ರವೇಶವನ್ನು ನಿಯಂತ್ರಿಸುತ್ತವೆ.
        ಈ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳ ಯಾವುದೇ ಭಾಗವನ್ನು ನೀವು ಒಪ್ಪದಿದ್ದರೆ ದಯವಿಟ್ಟು
        ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸಬೇಡಿ ಅಥವಾ ಪ್ರವೇಶಿಸಬೇಡಿ.
      </p>
      <ol>
        <li>
          <h3>ಪ್ರವೇಶ ಮತ್ತು ಬಳಕೆಯ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳ ಅಂಗೀಕಾರ</h3>
          <ol>
            <li>
              <p>
                ನಮ್ಮ ಯಾವುದೇ ಸೇವೆಗಳಿಗೆ ಚಂದಾದಾರರಾಗುವ ಅಥವಾ ಬಳಸುವ ಮೂಲಕ, ನೀವು ಹೇಗೆ
                ಚಂದಾದಾರರಾಗುತ್ತೀರಿ ಅಥವಾ ಸೇವೆಗಳನ್ನು ಹೇಗೆ ಬಳಸುತ್ತೀರಿ ಎಂಬುದನ್ನು
                ಪರಿಗಣಿಸದೇ, ನೀವು ನಿಬಂಧನೆಗಳನ್ನು ಓದಿದ್ದೀರಿ, ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ
                ಮತ್ತು ನಿಯಮಗಳಿಗೆ ಬದ್ಧರಾಗಿರುತ್ತೀರಿ ಎಂಬುದಾಗಿ ನೀವು ಒಪ್ಪುತ್ತೀರಿ. ನೀವು
                ನಿಯಮಗಳಿಗೆ ಬದ್ಧರಾಗಿರಲು ಬಯಸದಿದ್ದರೆ, ನೀವು ನಮ್ಮ ಸೇವೆಗಳಿಗೆ
                ಚಂದಾದಾರರಾಗಬಾರದು ಅಥವಾ ಸೇವೆಗಳನ್ನು ಬಳಸಬಾರದು. ಈ ನಿಯಮಗಳು ಮತ್ತು ಇತರ
                ಹಲವಾರು ನೀತಿಗಳು 2000 ರ ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ ಕಾಯಿದೆಯಡಿಯಲ್ಲಿ ರೂಪಿಸಲಾದ
                ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ (ಮಧ್ಯವರ್ತಿಗಳ ಮಾರ್ಗಸೂಚಿಗಳು) ನಿಯಮಗಳು, 2011 ರ
                ನಿಬಂಧನೆಗಳ ಪ್ರಕಾರ ಕಾರ್ಯಬದ್ಧವಾಗಿವೆ.
              </p>
            </li>
            <li>
              <p>
                ಈ ನಿಯಮಗಳು ನಿಮಗೆ ಮತ್ತು ಕೊವೆಸ್ಟ್ರೋ ಗೆ ಮುಖ್ಯವಾಗಿದೆ ಏಕೆಂದರೆ ಮೌಲ್ಯಯುತ
                ಗ್ರಾಹಕರಾಗಿ ನಿಮ್ಮ ಹಕ್ಕುಗಳನ್ನು ಹಾಗೂ ವ್ಯವಹಾರ ಸಂಸ್ಥೆಯಾಗಿ ನಮ್ಮ
                ಹಕ್ಕುಗಳನ್ನು ರಕ್ಷಿಸಲು ಅವುಗಳನ್ನು ಬಳಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಕೊವೆಸ್ಟ್ರೋ ಮೂಲಕ ವ್ಯಕ್ತಪಡಿಸಲ್ಪಟ್ಟ ಲಿಖಿತವಾಗಿ ಹೇಳಲ್ಪಟ್ಟ, ಪೂರ್ವ
                ವಿತರಣೆಯ ಹೊರತಾಗಿ ಈ ನಿಯಮಗಳು ಅನ್ವಯವಾಗುತ್ತವೆ ಮತ್ತು ಇತರ ಎಲ್ಲ
                ನಿಬಂಧನೆಗಳನ್ನು ಬದಲಾಯಿಸುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಉತ್ಪನ್ನ ವಿಮರ್ಶೆಗಳು, ಮಾರಾಟಗಾರರ ವಿಮರ್ಶೆಗಳು, ಸಂಭಾವ್ಯ ಮಾರಾಟಗಾರರ
                ಸಂಪರ್ಕ ವಿವರಗಳನ್ನು ಸ್ವೀಕರಿಸುವುದು ಇತ್ಯಾದಿಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ
                ಅವುಗಳಿಗೆ ಮಾತ್ರ ಸೀಮಿತವಾಗಿರದೆ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೂಲಕ ಕೊವೆಸ್ಟ್ರೋ
                ಒದಗಿಸಿದ ಯಾವುದೇ ಸೇವೆಗಳನ್ನು ನೀವು ಬಳಸಿದಾಗ, ನೀವು ಅಂತಹ ಸೇವೆಗೆ
                ಅನ್ವಯವಾಗುವಂತಹ ನಿಯಮಗಳು, ಮಾರ್ಗಸೂಚಿಗಳು, ನೀತಿಗಳು, ಷರತ್ತುಗಳು ಮತ್ತು
                ನಿಬಂಧನೆಗಳಿಗೆ ಒಳಪಟ್ಟಿರುತ್ತೀರಿ, ಮತ್ತು ಅವುಗಳನ್ನು ಈ ಬಳಕೆಯ ನಿಯಮಗಳಲ್ಲಿ
                ಸಂಯೋಜಿಸಲಾಗಿದೆ ಮತ್ತು ಈ ಬಳಕೆಯ ನಿಯಮಗಳ ಅವಿಭಾಜ್ಯ ಭಾಗ ಎಂದು
                ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮಗೆ ಯಾವುದೇ ಲಿಖಿತ ಪೂರ್ವ ಸೂಚನೆಯಿಲ್ಲದೇ, ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಈ ಬಳಕೆಯ
                ನಿಯಮಗಳನ್ನು ಮಾರ್ಪಡಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಹೊಂದಿರುತ್ತೇವೆ.
                ನವೀಕರಣಗಳು/ಬದಲಾವಣೆಗಳನ್ನು ತಿಳಿಯುವುದಕ್ಕೆ ನಿಯತಕಾಲಿಕವಾಗಿ ಈ ಬಳಕೆಯ
                ನಿಯಮಗಳನ್ನು ಪರಿಶೀಲಿಸುವುದು ನಿಮ್ಮ ಜವಾಬ್ದಾರಿಯಾಗಿದೆ. ಬದಲಾವಣೆಗಳನ್ನು
                ಪೋಸ್ಟ್ ಮಾಡಿದ ನಂತರ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ನಿಮ್ಮ ಮುಂದುವರಿದ ಬಳಕೆಯು ನೀವು
                ಪರಿಷ್ಕರಣೆಗಳನ್ನು ಸ್ವೀಕರಿಸುತ್ತೀರಿ ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ ಎಂಬುದಾಗಿ
                ಸೂಚಿಸುತ್ತದೆ. ನಿಮ್ಮ ಪರಿಶೀಲನೆಗಾಗಿ ಸೇವಾ ನಿಯಮಗಳ ಅತ್ಯಂತ ಪ್ರಸ್ತುತ
                ಆವೃತ್ತಿಯನ್ನು ನಾವು ಇಲ್ಲಿ ನಿರ್ವಹಿಸುತ್ತೇವೆ:{' '}
                <a
                  href={`${APP_DOMAIN}legal/conditions-of-use`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/conditions-of-use`}
                </a>
              </p>
            </li>
            <li>
              <p>
                ಈ ಬಳಕೆಯ ನಿಯಮಗಳನ್ನು ನೀವು ಅನುಸರಿಸುವವರೆಗೆ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು
                ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಬಳಸಲು ನಾವು ನಿಮಗೆ ವೈಯಕ್ತಿಕ, ವಿಶೇಷವಲ್ಲದ, ವರ್ಗಾವಣೆ
                ಮಾಡಲಾಗದ, ಸೀಮಿತ ಸವಲತ್ತುಗಳನ್ನು ನೀಡುತ್ತೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಯುರೋಪಿಯನ್ ಯೂನಿಯನ್‌ನ ಜನರಲ್ ಡೇಟಾ ಪ್ರೊಟೆಕ್ಷನ್ ರೆಗ್ಯುಲೇಷನ್
                (ಜಿಡಿಪಿಆರ್) ಅನ್ನು ಅನುಸರಿಸುವ ಸಲುವಾಗಿ ನಾವು ನಮ್ಮ ನೀತಿಯನ್ನು
                ನವೀಕರಿಸಿದ್ದೇವೆ. ಹೊಸ ನೀತಿ ಬದಲಾವಣೆಗಳ ಪ್ರಕಾರ, ನಿಮ್ಮ ಸ್ವಂತ ಡೇಟಾದ
                ಮೇಲೆ ನೀವು ಹೆಚ್ಚಿನ ನಿಯಂತ್ರಣವನ್ನು ಹೊಂದಿದ್ದೀರಿ, ಅದು ನಿಮಗೆ ಅತ್ಯಂತ
                ಸುಭದ್ರ ಮತ್ತು ಸುರಕ್ಷಿತವಾಗಿದೆ
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಮೂರನೆಯ ವ್ಯಕ್ತಿ ಸೇವೆಗಳು</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ನಿಮಗೆ ಮೂರನೆಯ ವ್ಯಕ್ತಿ ಉತ್ಪನ್ನಗಳು ಅಥವಾ ಸೇವೆಗಳ
                (“ಮೂರನೆಯ ವ್ಯಕ್ತಿ ಸೇವೆಗಳು”) ಲಿಂಕ್‌ಗಳಿಗೆ ಪ್ರವೇಶವನ್ನು ನೀಡಬಹುದು.
                ಕೊವೆಸ್ಟ್ರೋ ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳ ಸೇವೆಗಳನ್ನು
                ನಿಯಂತ್ರಿಸುವುದಿಲ್ಲ ಹಾಗೆಯೇ ಅಂತಹ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ಸೇವೆಗಳಿಗೆ
                ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ಹೊಣೆಗಾರಿಕೆಯನ್ನೂ ತೆಗೆದುಕೊಳ್ಳುವುದಿಲ್ಲ. ಅಂತಹ
                ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿ ಸೇವೆಗಳನ್ನು ಬಳಸುವಲ್ಲಿ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿ
                ಮತ್ತು ಗೌಪ್ಯತೆಯನ್ನು ರಕ್ಷಿಸುವುದು ಮತ್ತು ಸಂಬಂಧಿತ ಒಪ್ಪಂದಗಳನ್ನು
                ಅನುಸರಿಸುವುದು ಸೇರಿದಂತೆ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ಸೇವೆಯನ್ನು ಪ್ರವೇಶಿಸುವುದು
                ಸೂಕ್ತವೇ ಎಂಬುದನ್ನು ನಿರ್ಧರಿಸಲು ನೀವು ಸೂಕ್ತ ಕ್ರಮಗಳನ್ನು
                ತೆಗೆದುಕೊಳ್ಳಬೇಕಾಗುತ್ತದೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಪ್ರವೇಶ ಮತ್ತು ಬಳಕೆ</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಒಂದು ಮಾರುಕಟ್ಟೆ ಸ್ಥಳವಾಗಿದೆ, ಇಲ್ಲಿ ಬಳಕೆದಾರರಿಗೆ
                ತರಕಾರಿಗಳು, ಹಣ್ಣುಗಳು, ಧಾನ್ಯಗಳು, ಬೇಳೆಕಾಳುಗಳು, ಮಸಾಲೆಗಳು, ತೋಟದ
                ಬೆಳೆಗಳು, ಹಣಕಾಸು ಯೋಜನೆಗಳು, ನೀತಿಗಳು, ಯೋಜನೆಗಳು ಇತ್ಯಾದಿಗಳು ಸೇರಿದಂತೆ,
                ಆದರೆ ಅವುಗಳಿಗೆ ಮಾತ್ರ ಸೀಮಿತವಾಗಿರದೇ (ಒಟ್ಟಾರೆಯಾಗಿ &quot;ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳು&quot; ಎಂದು ಉಲ್ಲೇಖಿಸಲಾಗಿದೆ) ವಿವಿಧ ಆಹಾರ ಮತ್ತು ಕೃಷಿ
                ಉತ್ಪನ್ನಗಳಿಗೆ ಪ್ರವೇಶವನ್ನು ಒದಗಿಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೂಲಕ ನೀವು ಸಲ್ಲಿಸುವ ಯಾವುದೇ ವಿಷಯವು{' '}
                <a
                  href={`${APP_DOMAIN}legal/privacy-statement`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${APP_DOMAIN}legal/privacy-statement`}
                </a>{' '}
                ನಲ್ಲಿ ಲಭ್ಯವಿರುವ ಕಂಪನಿಯ ಗೌಪ್ಯತಾ ನೀತಿಯಿಂದ ನಿಯಂತ್ರಿಸಲ್ಪಡುತ್ತದೆ. ಈ
                ಒಪ್ಪಂದ ಮತ್ತು ಕಂಪನಿಯ ಗೌಪ್ಯತೆ ನೀತಿಯ ನಡುವೆ ಸ್ವಲ್ಪ ಮಟ್ಟಿಗೆ
                ಅಸಂಗತತೆಯಿದ್ದರೆ, ಈ ನಿಯಮಗಳು ಚಾಲ್ತಿಯಲ್ಲಿರುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಚರ್ಚಾ ವೇದಿಕೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ನೀವು ಪ್ರಶ್ನೆ ಅಥವಾ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು
                ಸಲ್ಲಿಸಬೇಕಾದರೆ, ನಿಮ್ಮ ಸ್ವಂತ ಸಂವಹನಗಳು, ಆ ಸಂವಹನಗಳನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿದ
                ನಂತರದ ಪರಿಣಾಮಗಳು ಮತ್ತು ಸಾರ್ವಜನಿಕ ಪ್ರದೇಶಗಳಲ್ಲಿ ಕಂಡುಬರುವ ಯಾವುದೇ
                ಸಂವಹನಗಳ ಮೇಲಿನ ನಿಮ್ಮ ನಂಬಿಕೆಗೆ ನೀವು ಮಾತ್ರವೇ ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ.
                ಸಾರ್ವಜನಿಕ ಪ್ರದೇಶಗಳಲ್ಲಿನ ಯಾವುದೇ ಸಂವಹನಗಳ ಪರಿಣಾಮಗಳಿಗೆ ಕಂಪನಿ ಮತ್ತು
                ಅದರ ಪರವಾನಗಿದಾರರು ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ. ನೀವು ಬೆದರಿಕೆಯನ್ನು
                ಅನುಭವಿಸುವ ಸಂದರ್ಭಗಳಲ್ಲಿ ಅಥವಾ ಬೇರೊಬ್ಬರು ಅಪಾಯದಲ್ಲಿದ್ದಾರೆ ಎಂದು ನೀವು
                ಭಾವಿಸಿದರೆ, ನೀವು ತಕ್ಷಣ ನಿಮ್ಮ ಸ್ಥಳೀಯ ಕಾನೂನು ಜಾರಿ ಸಂಸ್ಥೆಯನ್ನು
                ಸಂಪರ್ಕಿಸಬೇಕು. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಿಮ್ಮ ಎಲ್ಲ
                ಚಟುವಟಿಕೆಗಳಿಗೆ ನೀವು ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ ಮತ್ತು ನೀವು ಎಲ್ಲ ಸ್ಥಳೀಯ,
                ರಾಜ್ಯ, ರಾಷ್ಟ್ರೀಯ ಮತ್ತು ಅಂತರಾಷ್ಟ್ರೀಯ ಕಾನೂನುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು
                ಮತ್ತು ಅನ್ವಯವಾಗುವ ಯಾವುದೇ ನಿಯಂತ್ರಣಾ ನಿಯಮಗಳಿಗೆ ಬದ್ಧರಾಗಿರುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ತಾತ್ಕಾಲಿಕ ಆಧಾರದ ಮೇಲೆ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ ಪ್ರವೇಶವನ್ನು
                ಅನುಮತಿಸಲಾಗಿದೆ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನಾವು ಒದಗಿಸುವ ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳನ್ನು (ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಲ್ಲಿನ ಯಾವುದೇ
                ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು) ಕಾಲಕಾಲಕ್ಕೆ ನಿಮಗೆ ಪೂರ್ವ ಸೂಚನೆ ಇಲ್ಲದೆಯೇ
                ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ಅಥವಾ ತಿದ್ದುಪಡಿ ಮಾಡುವ ಹಕ್ಕನ್ನು ನಾವು
                ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ. ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು (ಅಥವಾ ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳಲ್ಲಿನ ಯಾವುದೇ ವೈಶಿಷ್ಟ್ಯಗಳು) ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಅಥವಾ
                ಯಾವುದೇ ಅವಧಿಗೆ ಲಭ್ಯವಿಲ್ಲದಿದ್ದರೆ ಅದಕ್ಕೆ ನಾವು
                ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ. ಕಾಲಕಾಲಕ್ಕೆ, ನಾವು ನಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ,
                ನಮ್ಮೊಂದಿಗೆ ನೋಂದಾಯಿಸಿದ ಬಳಕೆದಾರರಿಗೆ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಕೆಲವು ಭಾಗಗಳಿಗೆ
                ಅಥವಾ ಸಂಪೂರ್ಣ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಪ್ರವೇಶವನ್ನು ನಿರ್ಬಂಧಿಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಮತ್ತು ಆ ಮೂಲಕ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ
                ಪ್ರವೇಶವನ್ನು ಹೊಂದಲು ಅಗತ್ಯವಿರುವ ಎಲ್ಲ ವ್ಯವಸ್ಥೆಗಳನ್ನು ಮಾಡಿಕೊಳ್ಳಲು
                ನೀವು ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ. ನಿಮ್ಮ ಖಾತೆಯ ಮೂಲಕ ಯಾವುದೇ ಇತರ
                ವ್ಯಕ್ತಿಗಳು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸುತ್ತಿಲ್ಲ ಎಂದು
                ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನೀವು ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ ಹಾಗೂ ಅಂತಹ ಯಾವುದೇ
                ಪ್ರವೇಶಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಮಗೆ ಯಾವುದೇ ಹೊಣೆಗಾರಿಕೆಯಿಲ್ಲದೆ ನೀವು
                ಮಾತ್ರವೇ ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ ಎಂಬುದನ್ನು ನೀವು ತಿಳಿದಿರುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ಖಾತೆಯ ಅಡಿಯಲ್ಲಿ ನಡೆಯುವ ಅಥವಾ ಸಂಭವಿಸುವ ಎಲ್ಲ ಚಟುವಟಿಕೆಗಳಿಗೆ
                ನೀವು ಕೊವೆಸ್ಟ್ರೋ ಗೆ ಸಂಪೂರ್ಣ ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ ಮತ್ತು
                ಬಾಧ್ಯಸ್ಥರಾಗಿರುತ್ತೀರಿ. ನಿಮ್ಮ ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡುವ ಸಾಮರ್ಥ್ಯವು
                ಇಂಟರ್ನೆಟ್ ಸೇವಾ ಪೂರೈಕೆದಾರರು ಮತ್ತು ಇಂಟರ್ನೆಟ್ ನೆಟ್‌ವರ್ಕ್ ಸಂಪರ್ಕದಂತಹ
                ಬಾಹ್ಯ ಅಂಶಗಳ ಮೇಲೆ ಅವಲಂಬಿತವಾಗಿದೆ ಎಂದು ನೀವು ಒಪ್ಪುತ್ತೀರಿ ಮತ್ತು ನಿಮ್ಮ
                ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡುವ ಅಸಮರ್ಥತೆಯಿಂದ ಉಂಟಾಗುವ ಯಾವುದೇ ಹಾನಿಗಳಿಗೆ ನಾವು
                ನಿಮಗೆ ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಹೊಸ ಬಳಕೆದಾರರಿಗೆ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೀಡಲಾದ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                ಸೇವೆಗಳನ್ನು ಬಳಸಲು ಪ್ರವೇಶವನ್ನು ನಿರಾಕರಿಸುವ ಹಕ್ಕನ್ನು ಅಥವಾ ಪ್ರಸ್ತುತ
                ಬಳಕೆದಾರರಿಗೆ ನೀಡಲಾದ ಪ್ರವೇಶವನ್ನು ರದ್ದುಗೊಳಿಸುವ ಹಕ್ಕನ್ನು ಕೊವೆಸ್ಟ್ರೋ
                ಕಾಯ್ದಿರಿಸಿಕೊಂಡಿದೆ. ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಹಾಗೆ ಮಾಡುವುದಕ್ಕೆ ಯಾವ ಕಾರಣವೂ
                ಇಲ್ಲದಿದ್ದರೂ ಇದು ಕೊವೆಸ್ಟ್ರೋ ದ ನಿರ್ಧಾರವಾಗಿರುತ್ತದೆ. ಯಾವುದೇ
                ಕಾರಣಕ್ಕಾಗಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ ಸೇವೆಗಳನ್ನು ಬಳಸದಂತೆ ಕೊವೆಸ್ಟ್ರೋ ದಿಂದ
                ಅಮಾನತುಗೊಳಿಸಲಾದ ಅಥವಾ ನಿಷೇಧಿಸಲ್ಪಟ್ಟ ಬಳಕೆದಾರರಿಗೆ ಯಾವುದೇ
                ಸಂದರ್ಭದಲ್ಲಿಯೂ ಖಾತೆಯ ಬಳಕೆ ಲಭ್ಯವಿರುವುದಿಲ್ಲ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಸದಸ್ಯತ್ವ/ನೋಂದಣಿ ಮತ್ತು ಅರ್ಹತೆ</h3>
          <ol>
            <li>
              <p>
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಪ್ರವೇಶಿಸಲು, ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ
                ನೋಂದಾಯಿಸಿಕೊಳ್ಳಬೇಕು ಮತ್ತು &quot;ಸದಸ್ಯ&quot; ಖಾತೆಯನ್ನು ರಚಿಸಬೇಕು.
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನಾವು ಕಾಲಕಾಲಕ್ಕೆ ಮತ್ತು ನಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ
                ಸ್ಥಾಪಿಸಬಹುದಾದ ಮತ್ತು ನಿರ್ವಹಿಸಬಹುದಾದ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು
                ಮತ್ತು ಕಾರ್ಯಗಳಿಗೆ ನಿಮ್ಮ ಖಾತೆಯು ನಿಮಗೆ ಪ್ರವೇಶವನ್ನು ನೀಡುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸುವ ಮೂಲಕ, ನೀವು ಇದನ್ನು
                ಸಮರ್ಥಿಸುತ್ತೀರಿ: (ಎ) ನೀವು ಒದಗಿಸಿದ ಎಲ್ಲಾ ಡೇಟಾ ನಿಖರವಾಗಿದೆ ಮತ್ತು
                ಸಂಪೂರ್ಣವಾಗಿದೆ; (ಬಿ) ನೀವು ಅಂತಹ ಮಾಹಿತಿಯ ನಿಖರತೆಯನ್ನು ಮತ್ತು ಅಂತಹ
                ಯಾವುದೇ ಮಾಹಿತಿಯನ್ನು ನಿಯಮಿತವಾಗಿ ನವೀಕರಿಸುವ ಮೂಲಕ ಅದರ ಯಾವುದೇ
                ಬದಲಾವಣೆಗಳನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಬೇಕು; (ಸಿ) ನೀವು 16 ವರ್ಷಕ್ಕಿಂತ
                ಮೇಲ್ಪಟ್ಟವರಾಗಿದ್ದೀರಿ ಮತ್ತು ಈ ಒಪ್ಪಂದದಲ್ಲಿ ಸೂಚಿಸಲಾದ ನಿಯಮಗಳು,
                ಷರತ್ತುಗಳು, ಕಟ್ಟುಪಾಡುಗಳು, ದೃಢೀಕರಣಗಳು, ಪ್ರಾತಿನಿಧ್ಯಗಳು ಮತ್ತು ಖಾತರಿ
                ಕರಾರುಗಳನ್ನು ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಈ ಒಪ್ಪಂದಕ್ಕೆ ಬದ್ಧರಾಗಿರಲು ಮತ್ತು
                ಅನುಸರಿಸಲು ಸಂಪೂರ್ಣವಾಗಿ ಶಕ್ಯರು ಮತ್ತು ಸಮರ್ಥರು ಎಂಬುದಾಗಿ ನೀವು
                ದೃಢೀಕರಿಸುತ್ತೀರಿ; (ಡಿ) ನೀವು 18 (ಹದಿನೆಂಟು) ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ
                ವಯಸ್ಸಿನವರಾಗಿದ್ದರೆ, ನಿಮ್ಮ ಪೋಷಕರು ಅಥವಾ ಗಾರ್ಡಿಯನ್ ಈ ಒಪ್ಪಂದದ
                ನಿಯಮಗಳನ್ನು ಪರಿಶೀಲಿಸಬೇಕು ಮತ್ತು ಒಪ್ಪಿಕೊಳ್ಳಬೇಕು ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್
                ಅನ್ನು ಬಳಸುವ ಮೂಲಕ, ನಿಮ್ಮ ಪೋಷಕರು ಅಥವಾ ಗಾರ್ಡಿಯನ್ ಈ ಒಪ್ಪಂದವನ್ನು
                ಪರಿಶೀಲಿಸಿದ್ದಾರೆ ಮತ್ತು ಒಪ್ಪಿಕೊಂಡಿದ್ದಾರೆ ಎಂಬುದನ್ನು ನೀವು
                ಖಚಿತಪಡಿಸುತ್ತೀರಿ. ನಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ 18 ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ
                ವಯಸ್ಸಿನ ಬಳಕೆದಾರರಿಗೆ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿನ ಕೆಲವು ವೈಶಿಷ್ಟ್ಯಗಳ
                ಲಭ್ಯತೆಯನ್ನು ಸೀಮಿತಗೊಳಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ. (ಇ)
                ನೀವು ಒಂದು ಬದ್ಧ ಒಪ್ಪಂದಕ್ಕೆ ಪ್ರವೇಶಿಸಲು ಅನ್ವಯವಾಗುವ ಕಾನೂನುಗಳಿಗೆ
                ಅನುಸಾರವಾಗಿ ಅರ್ಹರಾಗಿದ್ದೀರಿ ಮತ್ತು ಅನ್ವಯವಾಗುವ ಕಾನೂನುಗಳ ಅಡಿಯಲ್ಲಿ
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಸ್ವೀಕರಿಸುವುದನ್ನು ನಿರ್ಬಂಧಿಸಲ್ಪಟ್ಟ
                ವ್ಯಕ್ತಿಯಾಗಿರುವುದಿಲ್ಲ; ಮತ್ತು (ಎಫ್) ಈ ಒಪ್ಪಂದ ಮತ್ತು ಅನ್ವಯವಾಗುವ ಎಲ್ಲ
                ಸ್ಥಳೀಯ, ರಾಜ್ಯ, ರಾಷ್ಟ್ರೀಯ ಮತ್ತು ಅಂತರಾಷ್ಟ್ರೀಯ ಕಾನೂನುಗಳು, ನಿಯಮಗಳು
                ಮತ್ತು ನಿಬಂಧನೆಗಳಿಗೆ ಅನುಸಾರವಾಗಿ ಮಾತ್ರವೇ ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೂಲಕ
                ಒದಗಿಸಲಾದ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ ಇತರ ಸೇವೆಗಳನ್ನು ಬಳಸಬಹುದು.
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೀವು ಒದಗಿಸಿದ ಮಾಹಿತಿಯಲ್ಲಿನ ಯಾವುದೇ
                ಅಸಮರ್ಪಕತೆಯಿಂದ ಉಂಟಾಗುವ ಆರೋಗ್ಯಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಅಥವಾ ಇತರ ಯಾವುದೇ ರೀತಿಯ
                ಗಾಯ, ಹಾನಿ ಅಥವಾ ಇತರ ಪರಿಣಾಮಗಳಿಗೆ ನಾವು ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ. ಈ
                ಒಪ್ಪಂದ ಅಥವಾ ಗೌಪ್ಯತಾ ನೀತಿಯ ಅಡಿಯಲ್ಲಿ ಉಲ್ಲೇಖಿಸಲಾದ ಯಾವುದೇ
                ಷರತ್ತುಗಳನ್ನು ನೀವು ಉಲ್ಲಂಘಿಸಿದ್ದೀರಿ ಎಂಬುದಾಗಿ ನಾವು ಭಾವಿಸಿದರೆ,
                ಎಚ್ಚರಿಕೆ ನೀಡದೇ ಅಥವಾ ಯಾವುದೇ ಸೂಚನೆಯಿಲ್ಲದೇ, ನಿಮಗೆ 24 (ಇಪ್ಪತ್ನಾಲ್ಕು)
                ಗಂಟೆಗಳ ಮುಂಚಿತವಾಗಿ ತಿಳಿಸುವ ಮೂಲಕ ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಾವು
                ಅಳಿಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಮತ್ತು ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                ಸೇವೆಗಳನ್ನು ಬಳಸಲು ಅರ್ಹರಾಗಲು ನಿಮ್ಮ ಸಂಪೂರ್ಣ ಕಾನೂನು ಹೆಸರು, ಫೋನ್
                ಸಂಖ್ಯೆ, ಮಾನ್ಯ ಇಮೇಲ್ ವಿಳಾಸ ಮತ್ತು ನಿಮ್ಮಿಂದ ವಿನಂತಿಸಿದ ಯಾವುದೇ
                ಮಾಹಿತಿಯನ್ನು ಕಾಲಕಾಲಕ್ಕೆ ನೀವು ಒದಗಿಸಬೇಕು. ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು,
                ರೆಕಾರ್ಡ್ ಕೀಪಿಂಗ್ ಉದ್ದೇಶಗಳು, ಆಂತರಿಕ ಕಾರ್ಯವಿಧಾನಗಳು ಅಥವಾ ಇತರ ಯಾವುದೇ
                ಉದ್ದೇಶಗಳಿಗಾಗಿ ನೀವು ಒದಗಿಸಿದ ಮಾಹಿತಿಯನ್ನು ನಾವು ಯಾವುದೇ ಮೂರನೆಯ
                ವ್ಯಕ್ತಿಯೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಬಹುದು ಮತ್ತು ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವ
                ಮೂಲಕ ನೀವು ಒದಗಿಸಿದ ಮಾಹಿತಿಯ ಹಂಚಿಕೆಗೆ ನೀವು ಸ್ಪಷ್ಟವಾಗಿ ಸಮ್ಮತಿ
                ಸೂಚಿಸುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ನಮ್ಮ ಭದ್ರತಾ
                ಕಾರ್ಯವಿಧಾನಗಳ ಭಾಗವಾಗಿ ನಿಮಗೆ ಬಳಕೆದಾರ ಗುರುತಿನ ಕೋಡ್, ಪಾಸ್‌ವರ್ಡ್ ಅಥವಾ
                ಯಾವುದೇ ಇತರ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಲಾಗುತ್ತದೆ. ಅಂತಹ ಮಾಹಿತಿಯನ್ನು ನೀವು
                ಗೌಪ್ಯ ಎಂಬುದಾಗಿ ಪರಿಗಣಿಸಬೇಕು ಮತ್ತು ನೀವು ಅದನ್ನು ಯಾವುದೇ ಮೂರನೆಯ
                ವ್ಯಕ್ತಿಗೆ ಬಹಿರಂಗಪಡಿಸಬಾರದು. ಪ್ರತಿ ಸೆಷನ್‌ನ ಕೊನೆಯಲ್ಲಿ ನಿಮ್ಮ
                ಖಾತೆಯಿಂದ ನಿರ್ಗಮಿಸುವುದನ್ನು/ಲಾಗ್ ಔಟ್ ಆಗುವುದನ್ನು
                ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದನ್ನೂ ಸಹ ನೀವು ಒಪ್ಪುತ್ತೀರಿ. ನಮ್ಮ ಅಭಿಪ್ರಾಯದಲ್ಲಿ
                ನೀವು ಈ ಒಪ್ಪಂದದ ಯಾವುದೇ ನಿಬಂಧನೆಗಳನ್ನು ಅನುಸರಿಸಲು ವಿಫಲವಾದರೆ, ಯಾವುದೇ
                ಸಮಯದಲ್ಲಿ ನೀವು ಆಯ್ಕೆ ಮಾಡಿದ ಅಥವಾ ನಮ್ಮಿಂದ ನಿಯೋಜಿಸಲಾದ ಯಾವುದೇ ಬಳಕೆದಾರ
                ಗುರುತಿನ ಕೋಡ್ ಅಥವಾ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವ ಹಕ್ಕನ್ನು
                ನಾವು ಹೊಂದಿದ್ದೇವೆ. ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅಥವಾ ಬಳಕೆದಾರ ಗುರುತಿನ ಕೋಡ್‌ನ
                ಯಾವುದೇ ಅನಧಿಕೃತ ಬಳಕೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ಅಂತಹ ಅನಧಿಕೃತ ಬಳಕೆಯ
                ವಿವರಗಳನ್ನು{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                ಗೆ ಕಳುಹಿಸುವ ಮೂಲಕ ನೀವು ತಕ್ಷಣವೇ ಕೊವೆಸ್ಟ್ರೋ ಗೆ ಸೂಚನೆ ನೀಡುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ,
                ನೀವು ಕೆಳಗಿನ ಪ್ರಯೋಜನ ಪಡೆಯುವ ಸದಸ್ಯರಾಗುತ್ತೀರಿ:
              </p>
              <ol>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಮಾರಾಟ ಅಥವಾ ಖರೀದಿಗೆ ಆಹ್ವಾನವಾಗಿ
                    ಉತ್ಪನ್ನಗಳನ್ನು ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಪೋಸ್ಟ್ ಮಾಡಲು ನಿಮಗೆ
                    ಅನುಮತಿಸುತ್ತದೆ;
                  </p>
                </li>
                <li>
                  <p>
                    ಅಗತ್ಯವಿದ್ದರೆ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಕೆಯ ನಿಯಮಗಳಲ್ಲಿನ
                    ಬದಲಾವಣೆಗಳನ್ನು ನಿಮಗೆ ತಿಳಿಸಲು ಅಥವಾ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನಾವು
                    ಸಕ್ರಿಯಗೊಳಿಸುವ ಹೊಸ ಸೇವೆಗಳನ್ನು ವಿವರಿಸಲು, ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು
                    ನಮಗೆ ಅನುಮತಿಸುತ್ತದೆ;
                  </p>
                </li>
                <li>
                  <p>
                    ಇಲ್ಲಿ ನಿಗದಿಪಡಿಸಿದ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳಿಗೆ ಅನುಗುಣವಾಗಿ,
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ
                    ಮಾಹಿತಿಯನ್ನು ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಸಂಗ್ರಹಿಸಲು, ಹಾಗೆಯೇ ನಂತರದಲ್ಲಿ
                    ಅಂತಹ ಮಾಹಿತಿಯನ್ನು ನೀಡಿದವರನ್ನು ಸಂಪರ್ಕಿಸುವುದಕ್ಕೆ ಅನುಮತಿಸುತ್ತದೆ;
                  </p>
                </li>
                <li>
                  <p>
                    ನಮ್ಮಿಂದ ನಿರ್ದಿಷ್ಟವಾಗಿ ಅಧಿಕೃತಗೊಳಿಸಿರುವುದನ್ನು ಹೊರತುಪಡಿಸಿ ಇತರ
                    ಯಾವುದೇ ಉದ್ದೇಶಕ್ಕಾಗಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಪ್ರದರ್ಶಿಸಲಾದ ಇಮೇಲ್
                    ವಿಳಾಸಗಳನ್ನು ಬಳಸಲು ನಿಮಗೆ ಅನುಮತಿಸುವುದಿಲ್ಲ.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ಕೊವೆಸ್ಟ್ರೋ ಗೆ ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸ/ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಒದಗಿಸುವ ಮೂಲಕ
                ನೀವು ಇವುಗಳಿಗೆ ಸಮ್ಮತಿಸುತ್ತೀರಿ:
              </p>
              <ol>
                <li>
                  <p>
                    ಕಾನೂನಿನ ಮೂಲಕ ಅಗತ್ಯವಿರುವ ಯಾವುದೇ ಸೂಚನೆಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಸೇವೆಗೆ
                    ಸಂಬಂಧಿಸಿದ ನೋಟೀಸ್‌ಗಳನ್ನು ನಿಮಗೆ ಕಳುಹಿಸಲು ನಾವು ಅಂಚೆ ಮೂಲಕ
                    ಸಂವಹನಕ್ಕೆ ಬದಲಾಗಿ ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸ ಅಥವಾ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು
                    ಬಳಸುತ್ತೇವೆ. ಇಮೇಲ್/ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ಅಥವಾ ಸಂಪೂರ್ಣವಾಗಿ
                    ಸೇವೆ-ಸಂಬಂಧಿತ ಸಂವಹನಗಳಿಂದ ಹೊರಗುಳಿಯಲು ನೀವು ನಿಮ್ಮ
                    ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಬಳಸಬಹುದು.
                  </p>
                </li>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿನ ನಿಮ್ಮ ಪ್ರಗತಿ ಮತ್ತು ನಮ್ಮ ಸೇವೆಗಳ ಬಳಕೆ
                    ಮತ್ತು ಯಾವುದೇ ಹೊಸ ಪ್ರಚಾರಗಳು ಮತ್ತು ಮಾರಾಟದ ಅವಕಾಶಗಳಿಗೆ
                    ಸಂಬಂಧಿಸಿದಂತೆ ನಿಮಗೆ ನವೀಕರಣಗಳು ಮತ್ತು ಸಲಹೆಗಳನ್ನು ಒದಗಿಸುವ
                    ಸಲುವಾಗಿ ಕಾಲಕಾಲಕ್ಕೆ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು ನೀವು ಒದಗಿಸಿದ ಫೋನ್
                    ಸಂಖ್ಯೆಗಳನ್ನು ನಾವು ಬಳಸುತ್ತೇವೆ.
                  </p>
                </li>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೀವು ಮಾಡಿದ ಆರ್ಡರ್‌ಗಳನ್ನು ಪೂರೈಸಲು ನಿಮ್ಮ
                    ಇಮೇಲ್ ವಿಳಾಸ, ಮನೆಯ ವಿಳಾಸ, ಫೋನ್ ಸಂಖ್ಯೆ ಮತ್ತು ಇತರ ಸಹಾಯಕ
                    ಮಾಹಿತಿಯನ್ನು ನಾವು ಬಳಸಿಕೊಳ್ಳುತ್ತೇವೆ; ಮತ್ತು
                  </p>
                </li>
                <li>
                  <p>
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ವೈಶಿಷ್ಟ್ಯಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ
                    ಬದಲಾವಣೆಗಳು ಮತ್ತು ವಿಶೇಷ ಕೊಡುಗೆಗಳು ಸೇರಿದಂತೆ ಇತರ ಸಂದೇಶಗಳನ್ನು
                    ನಿಮಗೆ ಕಳುಹಿಸಲು ನಾವು ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸ ಅಥವಾ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು
                    ಬಳಸುತ್ತೇವೆ. ನೀವು ಅಂತಹ ಇಮೇಲ್ ಸಂದೇಶಗಳನ್ನು ಸ್ವೀಕರಿಸಲು
                    ಬಯಸದಿದ್ದರೆ, ನಮಗೆ{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    ಗೆ ಇಮೇಲ್ ಕಳುಹಿಸುವ ಮೂಲಕ ನೀವು ಇದರಿಂದ ಹೊರಗುಳಿಯಬಹುದು. ಆಯ್ಕೆಯಿಂದ
                    ಹೊರಗುಳಿಯುವಿಕೆಯು ನವೀಕರಣಗಳು, ಸುಧಾರಣೆಗಳು ಅಥವಾ ಕೊಡುಗೆಗಳಿಗೆ
                    ಸಂಬಂಧಿಸಿದಂತೆ ಇಮೇಲ್ ಸಂದೇಶಗಳು ಅಥವಾ ಫೋನ್ ಸಂದೇಶಗಳನ್ನು
                    ಸ್ವೀಕರಿಸುವುದಕ್ಕೆ ನಿಮ್ಮನ್ನು ನಿರ್ಬಂಧಿಸುತ್ತದೆ.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಕೆಯ ನಿಯಮಗಳು</h3>
          <ol>
            <li>
              <p>
                ಕಾಲಕಾಲಕ್ಕೆ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳಿಂದ ಪಟ್ಟಿ ಮಾಡಲಾದ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                ಸೇವೆಗಳನ್ನು ಮಾರಾಟ, ಖರೀದಿ ಮತ್ತು ಬಳಕೆಗಾಗಿ ಆಹ್ವಾನದ ಮಾಹಿತಿಯನ್ನು
                ಪ್ರವೇಶಿಸಲು ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸಬಹುದು ಮತ್ತು
                ಬಳಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ಕೆಳಗಿನವುಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ ದ ಜವಾಬ್ದಾರಿಯು
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ವಿಷಯದಲ್ಲಿ ಏಕಮಾತ್ರವಾಗಿ ಮತ್ತು ಪ್ರತ್ಯೇಕವಾಗಿ
                ವಿಸ್ತರಿಸುತ್ತದೆ:
              </p>
              <ol>
                <li>
                  <p>ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಒದಗಿಸುವುದು;</p>
                </li>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೋಂದಾಯಿಸಿದ ಬಳಕೆದಾರರಿಗೆ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                    ಸೇವೆಗಳ ಪ್ರವೇಶ ಮತ್ತು ಲಭ್ಯತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು
                    ಮಾರುಕಟ್ಟೆಯಾಗಿ ಸೇವೆ ಸಲ್ಲಿಸುತ್ತಿದೆ.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಪಟ್ಟಿ ಮಾಡಲಾದ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು
                ಮೂರನೆಯ-ವ್ಯಕ್ತಿ ಮಾರಾಟಗಾರರ ಮಾಲೀಕತ್ವದ ಮತ್ತು ಮಾರಾಟ ಮಾಡಲ್ಪಡುವ
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಾಗಿವೆ ಎಂಬುದಾಗಿ ಈ ಮೂಲಕ
                ಸ್ಪಷ್ಟಪಡಿಸಲಾಗುತ್ತದೆ. ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ಮಾರಾಟಗಾರರ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                ಸೇವೆಗಳು ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ ಕೊವೆಸ್ಟ್ರೋ ಗೆ ಸಂಬಂಧಪಟ್ಟಿರುವುದಿಲ್ಲ.
                ಹೀಗಾಗಿ, ಮೂರನೆಯ ವ್ಯಕ್ತಿ ಮಾರಾಟಗಾರರ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ
                ಗುಣಮಟ್ಟ, ಪ್ರಮಾಣ ಅಥವಾ ದೃಢೀಕರಣಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ ಯಾವುದೇ
                ಖಾತರಿಯನ್ನು ನೀಡುವುದಿಲ್ಲ ಮತ್ತು ಯಾವುದೇ ರೀತಿಯ ಜವಾಬ್ದಾರಿಯನ್ನು
                ಹೊಂದಿರುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಆರ್ಡರ್ ಅಥವಾ ನೋಂದಣಿಯನ್ನು ಇರಿಸಿದ ನಂತರ ಮತ್ತು
                ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಿದ ನಂತರ, ಇಲ್ಲಿ ಸೂಚಿಸಿದಂತೆ ಉಳಿಸಿದ ನಂತರ, ಯಾವುದೇ
                ಮರುಪಾವತಿಗಳು ಅಥವಾ ರದ್ದತಿಗಳನ್ನು ಪ್ರಾರಂಭಿಸಲಾಗುವುದಿಲ್ಲ ಅಥವಾ ಅವಕಾಶ
                ನೀಡಲಾಗುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಈ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳಿಗೆ ಒಳಪಟ್ಟು, ನಿಮ್ಮ ಏಕಮಾತ್ರದ
                ಪ್ರಯೋಜನಕ್ಕಾಗಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಬಳಸಲು
                ಕೊವೆಸ್ಟ್ರೋ ನಿಮಗೆ ವರ್ಗಾವಣೆ ಮಾಡಲಾಗದ, ವಿಶೇಷವಲ್ಲದ, ಉಪ ಲೈಸೆನ್ಸ್
                ಮಾಡಲಾಗದ ಸೀಮಿತ ಹಕ್ಕು ಮತ್ತು ಪರವಾನಗಿ ನೀಡುತ್ತದೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಸೇವೆಗಳ ಬಳಕೆಯ ಮೇಲಿನ ನಿರ್ಬಂಧ</h3>
          <ol>
            <li>
              <p>
                ಈ ಕೆಳಗಿನ ಯಾವುದೇ ಕಾರ್ಯಗಳನ್ನು ಮಾಡಲು ನಿಮಗೆ ಅನುಮತಿಯಿಲ್ಲ ಮತ್ತು
                ಮಾಡಬಾರದು, ಅದರ ಉಲ್ಲಂಘನೆಯು ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಬಳಕೆಯನ್ನು
                ಕೊನೆಗೊಳಿಸಲು ಕೊವೆಸ್ಟ್ರೋ ಗೆ ಹಕ್ಕನ್ನು ನೀಡುತ್ತದೆ, ನಿಮ್ಮ ವಿರುದ್ಧ ನಾವು
                ಹೊಂದಿರುವ ಯಾವುದೇ ಇತರ ಹಕ್ಕುಗಳು ಅಥವಾ ಪರಿಹಾರಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ
                ಯಾವುದೇ ಪೂರ್ವಾಗ್ರಹವಿಲ್ಲದೇ ನಾವು ಇದನ್ನು ನಿರ್ವಹಿಸುತ್ತೇವೆ:
              </p>
              <ol>
                <li>
                  <p>
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಭಾಗವಾಗಿ ನಿಮಗೆ ಒದಗಿಸಲಾದ ಎಲ್ಲ
                    ವಿಷಯಗಳನ್ನು ವಿತರಿಸುವುದು, ಮಾರಾಟ ಮಾಡುವುದು, ಸರಬರಾಜು ಮಾಡುವುದು,
                    ಮಾರ್ಪಡಿಸುವುದು, ಹೊಂದಿಸಿಕೊಳ್ಳುವುದು, ತಿದ್ದುಪಡಿ ಮಾಡುವುದು,
                    ಸಂಯೋಜಿಸುವುದು, ವಿಲೀನಗೊಳಿಸುವುದು ಅಥವಾ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು
                    ಬದಲಾಯಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ ವೆಬ್‌ಸೈಟ್ ಅಥವಾ ಅಪ್ಲಿಕೇಶನ್‌ನ ಭಾಗವಾಗಿ ನಿಮಗೆ
                    ಒದಗಿಸಲಾದ ಯಾವುದೇ ಸೇವೆ ಅಥವಾ ವಿಷಯವನ್ನು ಡಿಕಂಪೈಲ್ ಮಾಡುವುದು,
                    ರಿವರ್ಸ್ ಎಂಜಿನಿಯರ್ ಅಥವಾ ಡಿಸ್ಅಸೆಂಬಲ್ ಮಾಡಲು ಪ್ರಯತ್ನಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ ವೆಬ್‌ಸೈಟ್ ಅಥವಾ ಅಪ್ಲಿಕೇಶನ್‌ನ ಭಾಗವಾಗಿ ನಿಮಗೆ
                    ಒದಗಿಸಲಾದ ಯಾವುದೇ ಸಾಫ್ಟ್‌ವೇರ್ ಅನ್ನು ನಕಲಿಸಲು ಪ್ರಯತ್ನಿಸುವುದು
                    ಮತ್ತು ನಿರ್ದಿಷ್ಟವಾಗಿ ಅದರ ಮೂಲ ಕೋಡ್, ಅಥವಾ ನಿಮಗೆ ಒದಗಿಸಲಾದ ಅಂತಹ
                    ಸಾಫ್ಟ್‌ವೇರ್‌ನ ಯಾವುದೇ ಭಾಗವನ್ನು ಡೀಕ್ರಿಪ್ಟ್ ಮಾಡಲು
                    ಪ್ರಯತ್ನಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಭಾಗವಾಗಿ ಒದಗಿಸಲಾದ ಯಾವುದೇ ವಿಷಯಕ್ಕೆ
                    ಸಂಬಂಧಿಸಿದಂತೆ ಅಥವಾ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಕೆಗೆ ಅನುಕೂಲವಾಗುವಂತೆ ನಾವು
                    ಒದಗಿಸಿದ ಸಾಫ್ಟ್‌ವೇರ್‌ನ ಯಾವುದೇ ಉತ್ಪನ್ನ ಅಥವಾ ಆವೃತ್ತಿಯನ್ನು
                    ರಚಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಕೊವೆಸ್ಟ್ರೋ ದ ಯಾವುದೇ ಡೊಮೇನ್ ಹೆಸರನ್ನು ಗುಪ್ತನಾಮದಿಂದ ರಿಟರ್ನ್
                    ಇಮೇಲ್ ವಿಳಾಸವಾಗಿ ಬಳಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ನೀವು ಯಾವುದೇ ವಿಧಾನದಿಂದಲೂ ಪ್ರವೇಶಿಸಲು ಅಧಿಕಾರ ಹೊಂದಿರದ ಯಾವುದೇ
                    ವಿಷಯವನ್ನು ಪ್ರವೇಶಿಸುವುದು ಅಥವಾ ಪ್ರವೇಶಿಸಲು ಪ್ರಯತ್ನಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಭಾಗವಾಗಿ ಒದಗಿಸಲಾದ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                    ಸೇವೆಗಳಿಂದ ಅಥವಾ ಯಾವುದೇ ವಿಷಯದಿಂದ, ಯಾವುದೇ ಲೋಗೋ, ಹಕ್ಕುಸ್ವಾಮ್ಯ
                    ಅಥವಾ ಸ್ವಾಮ್ಯದ ಸೂಚನೆಗಳು, ದಂತಕಥೆಗಳು, ಚಿಹ್ನೆಗಳು, ಲೇಬಲ್‌ಗಳು,
                    ವಾಟರ್‌ಮಾರ್ಕ್‌ಗಳು, ಸಹಿಗಳು ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಲ್ಲಿ
                    ನಮೂದಿಸಲ್ಪಟ್ಟಿರುವ ಅಥವಾ ಎಂಬೆಡ್ ಮಾಡಲಾದ ಯಾವುದೇ ರೀತಿಯ
                    ಗುರುತುಗಳನ್ನು ತೆಗೆದುಹಾಕುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ತಪ್ಪು ಗುರುತನ್ನು ರಚಿಸಲು, ಯಾವುದೇ ವ್ಯಕ್ತಿ ಅಥವಾ ಸಂಸ್ಥೆಗೆ ಸೋಗು
                    ಹಾಕಿ ಮೋಸ ಮಾಡಲು ಅಥವಾ ಯಾವುದೇ ವಿಷಯದ ಮೂಲವನ್ನು ಮರೆಮಾಚಲು
                    ಪ್ರಯತ್ನಿಸುವುದಕ್ಕೆ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು ಅಥವಾ ಅದರ ಯಾವುದೇ
                    ಭಾಗವನ್ನು ಬಳಸಿಕೊಳ್ಳುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸಿ ಅಥವಾ ಬಳಸಿ
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಕಾರ್ಯನಿರ್ವಹಿಸಲ್ಪಡುವ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಯಾವುದೇ
                    ಸರ್ವರ್‌ಗಳು ಅಥವಾ ಸರ್ವರ್‌ಗಳಿಗೆ ಸಂಪರ್ಕಗೊಂಡಿರುವ ನೆಟ್‌ವರ್ಕ್‌ಗಳಿಗೆ
                    ಹಾನಿ ಮಾಡುವುದು, ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವುದು, ಅಧಿಕ ಹೊರೆಯನ್ನು
                    ನಿರ್ಮಿಸುವುದು ಅಥವಾ ಅದನ್ನು ದುರ್ಬಲಗೊಳಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ನಮ್ಮಿಂದ ಒದಗಿಸಲಾದ ಇಂಟರ್ಫೇಸ್ ಮೂಲಕ ಹೊರತುಪಡಿಸಿ ಯಾವುದೇ ಇತರ
                    ವಿಧಾನಗಳ ಮೂಲಕ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಯಾವುದೇ ಭಾಗವನ್ನು ಬದಲಾಯಿಸುವುದು ಅಥವಾ
                    ಮಾರ್ಪಡಿಸುವುದು; ಮತ್ತು
                  </p>
                </li>
                <li>
                  <p>
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ಸಾಮಗ್ರಿಗಳು, ಸಿಸ್ಟಂ ಸಂಪನ್ಮೂಲಗಳ ಸುರಕ್ಷತೆಗೆ
                    ಅಡ್ಡಿಪಡಿಸುವುದು ಅಥವಾ ಮಧ್ಯಪ್ರವೇಶಿಸುವುದು ಅಥವಾ
                    ಹಾನಿಯನ್ನುಂಟುಮಾಡುವುದು ಅಥವಾ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೂಲಕ ಅಥವಾ ಯಾವುದೇ
                    ಸಂಯೋಜಿತ ಲಿಂಕ್ ಮಾಡಲ್ಪಟ್ಟ ಸೈಟ್‌ಗಳ ಮೂಲಕ ಸಂಪರ್ಕಿಸಲಾದ ಅಥವಾ
                    ಪ್ರವೇಶಿಸಬಹುದಾದ ಸರ್ವರ್‌ಗಳು ಅಥವಾ ನೆಟ್‌ವರ್ಕ್‌ಗಳು, ಬಳಕೆದಾರರ
                    ಖಾತೆಗಳು, ಪಾಸ್‌ವರ್ಡ್‌ಗಳಿಗೆ ಅನಧಿಕೃತ ಪ್ರವೇಶವನ್ನು
                    ಪಡೆದುಕೊಳ್ಳುವುದು.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>ವಿಷಯ</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ವಸ್ತುಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಲು ಅಥವಾ ನಮ್ಮ
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಇತರ ಬಳಕೆದಾರರೊಂದಿಗೆ ಅಥವಾ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿನ
                ಮಾರಾಟಗಾರರನ್ನು ಸಂಪರ್ಕಿಸಲು ನಿಮಗೆ ಅನುಮತಿಸುವ ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                ಸೇವೆಗಳನ್ನು (ಅಥವಾ ಯಾವುದೇ ವೈಶಿಷ್ಟ್ಯ ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ
                ಭಾಗವನ್ನು) ನೀವು ಬಳಸಿದಾಗ, ನೀವು ಅನ್ವಯವಾಗುವ ಡೇಟಾ ಸಂರಕ್ಷಣಾ
                ನಿಯಮಗಳು/ಕಾನೂನುಗಳು ಮತ್ತು ಕೆಳಗೆ ನಿಗದಿಪಡಿಸಿದ ವಿಷಯದ ಮಾನದಂಡಗಳನ್ನು
                ಅನುಸರಿಸಬೇಕು. ವಿಷಯ ಮಾನದಂಡಗಳು ಕೊಡುಗೆಯ ಪ್ರತಿಯೊಂದು ಭಾಗಕ್ಕೆ
                ಪ್ರತ್ಯೇಕವಾಗಿಯೂ ಮತ್ತು ಒಟ್ಟಾರೆಯಾಗಿಯೂ ಅನ್ವಯಿಸುತ್ತವೆ. ಸತ್ಯಗಳನ್ನು
                ಹೇಳುವ ಸಂದರ್ಭಗಳಲ್ಲಿ ನಿಮ್ಮ ಕೊಡುಗೆಯು ನಿಖರವಾಗಿರಬೇಕು,
                ಪ್ರಾಮಾಣಿಕತೆಯನ್ನು ಎತ್ತಿ ಹಿಡಿಯಬೇಕು (ಅಭಿಪ್ರಾಯಗಳನ್ನು ಹೇಳುವ
                ಸಂದರ್ಭಗಳಲ್ಲಿ), ಅದನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿದ ಯಾವುದೇ ದೇಶದಲ್ಲಿ ಅನ್ವಯಿಸುವ
                ಕಾನೂನಿಗೆ ಬದ್ಧವಾಗಿರಬೇಕು; ಮತ್ತು ನಿಮ್ಮ ಪೋಸ್ಟಿಂಗ್ ಅಥವಾ ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳ ಬಳಕೆಯ ಮಾಹಿತಿಗೆ ಸಮಂಜಸವಾಗಿರಬೇಕು.
              </p>
            </li>
            <li>
              <p>
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಬಳಸಿಕೊಂಡು ನಿರ್ವಹಿಸಲ್ಪಡುವ ಹುಡುಕಾಟವು
                ಸೈಟ್‌ಗಳು ಮತ್ತು ವಿಷಯಗಳಿಗೆ ಸಂಬಂಧಪಟ್ಟಂತೆ ನೀವು ಆಕ್ಷೇಪಾರ್ಹ, ಅನುಚಿತ
                ಅಥವಾ ಆಕ್ರಮಣಕಾರಿ ಎಂದು ಭಾವಿಸುವಂತಹ ಫಲಿತಾಂಶಗಳು ಮತ್ತು ಲಿಂಕ್‌ಗಳಿಗೆ
                ಕಾರಣವಾಗಬಹುದು ಮತ್ತು ಅಂತಹ ಯಾವುದೇ ವಿಷಯಕ್ಕೆ ನಾವು ಯಾವುದೇ
                ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ. ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ನಿಮ್ಮ
                ಬಳಕೆಯು ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು, ವ್ಯಾಪಾರಿಗಳ ಮಾಹಿತಿ ಮತ್ತು ನೀವು
                ವಿನಂತಿಸಿದ ಉತ್ಪನ್ನವನ್ನು ಮಾರಾಟ ಮಾಡುವ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳ ವೆಬ್‌ಸೈಟ್‌ಗಳ
                ಲಿಂಕ್‌ಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ಮಾಹಿತಿಯನ್ನು ನಿಮಗೆ ಹಿಂತಿರುಗಿಸುವ
                ಸಾಧ್ಯತೆಯಿರುತ್ತದೆ. ಕೊವೆಸ್ಟ್ರೋ ಈ ಮೂಲಕ ಅದಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ
                ಮತ್ತು ಎಲ್ಲ ಜವಾಬ್ದಾರಿ ಮತ್ತು ಹೊಣೆಗಾರಿಕೆಯನ್ನು ನಿರಾಕರಿಸುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಯಾವುದೇ ಬಾಹ್ಯ ಸೈಟ್‌ಗಳು ಅಥವಾ ಸಂಪನ್ಮೂಲಗಳ ಲಭ್ಯತೆಯ ಪರಿಣಾಮವಾಗಿ ಅಥವಾ
                ಅಂತಹ ವೆಬ್‌ಸೈಟ್‌ಗಳು ಅಥವಾ ಸಂಪನ್ಮೂಲಗಳಿಂದ ಲಭ್ಯವಾಗುವ ಯಾವುದೇ ಜಾಹೀರಾತು,
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು ಅಥವಾ ಇತರ ಅಂಶಗಳ ಸಂಪೂರ್ಣತೆ, ನಿಖರತೆ ಅಥವಾ
                ಅಸ್ತಿತ್ವಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಅವುಗಳ ಮೇಲೆ ನೀವು ಇರಿಸಿದ ನಂಬಿಕೆಯ
                ಪರಿಣಾಮವಾಗಿ ನೀವು ಅನುಭವಿಸುವ ಯಾವುದೇ ರೀತಿಯ ಹಾನಿ ಅಥವಾ ನಷ್ಟಕ್ಕೆ
                ಕೊವೆಸ್ಟ್ರೋ ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ ಎಂಬುದಾಗಿ ನೀವು ಅಂಗೀಕರಿಸುತ್ತೀರಿ
                ಮತ್ತು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ವಿಷಯ, ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು ಮತ್ತು ಇತರ ಡೇಟಾವನ್ನು ಮೇಲ್ವಿಚಾರಣೆ
                ಮಾಡಲು ನೀವು ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ ಮತ್ತು ಸೇವೆಗಳಿಂದ ಅಥವಾ ಅದರೊಳಗೆ
                ವರ್ಗಾಯಿಸಲಾದ ಅಥವಾ ನಿರ್ವಹಿಸಿದ ವಿಷಯವು ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ
                ಹಕ್ಕುಗಳನ್ನು ಉಲ್ಲಂಘಿಸುವುದಿಲ್ಲ ಎನ್ನುವುದನ್ನು ನಮಗೆ ಖಚಿತಪಡಿಸುವ
                ಜವಾಬ್ದಾರಿಯೂ ನಿಮ್ಮದಾಗಿರುತ್ತದೆ. ನಿಮ್ಮ ವಿಷಯವನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು
                ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಬಳಸಲು ಅಗತ್ಯವಿರುವಂತಹ ಅವಶ್ಯಕ
                ಪರವಾನಗಿಗಳನ್ನು ನೀವು ಹೊಂದಿರುವುದಾಗಿ ನೀವು ಖಾತರಿಪಡಿಸುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ವಿಷಯವನ್ನು ಒಳಗೊಂಡಂತೆ, ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ತಾಂತ್ರಿಕ
                ಪ್ರಕ್ರಿಯೆ ಮತ್ತು ಪ್ರಸರಣವನ್ನು ಎನ್‌ಕ್ರಿಪ್ಟ್ ಮಾಡದೆ ವರ್ಗಾಯಿಸಬಹುದು
                ಮತ್ತು ಅದು ಇವುಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ ಎನ್ನುವುದನ್ನು ನೀವು
                ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ: (ಎ) ವಿವಿಧ ನೆಟ್‌ವರ್ಕ್‌ಗಳ ಮೂಲಕ ಪ್ರಸರಣಗಳು;
                ಮತ್ತು (ಬಿ) ಸಂಪರ್ಕಿಸುವ ನೆಟ್‌ವರ್ಕ್‌ಗಳು ಅಥವಾ ಸಾಧನಗಳ ತಾಂತ್ರಿಕ
                ಅವಶ್ಯಕತೆಗಳಿಗೆ ಅನುಗುಣವಾಗಿ ಮತ್ತು ಹೊಂದಿಕೊಳ್ಳುವ ಬದಲಾವಣೆಗಳು.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವುದರ
                ಭಾಗವಾಗಿ, ವಿವಿಧ ಮಾರಾಟಗಾರರು ಚಿತ್ರಗಳು, ವಿವರಣೆಗಳು ಮತ್ತು ಇತರ
                ವಿಷಯವನ್ನು ಪೋಸ್ಟ್ ಮಾಡಬಹುದು ಎಂಬುದನ್ನು ನೀವು ಈ ಮೂಲಕ ಅಂಗೀಕರಿಸುತ್ತೀರಿ
                ಮತ್ತು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತೀರಿ. ಕೊವೆಸ್ಟ್ರೋ ಆಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವ
                ನಾವು ಕೇವಲ ಮಾರುಕಟ್ಟೆ ಸೇವೆಗಳನ್ನು ಒದಗಿಸಲು ಅನುಕೂಲಕರವಾದ
                ವೇದಿಕೆಯಾಗಿದ್ದೇವೆ ಮತ್ತು ಯಾವುದೇ ಮಿತಿಯಿಲ್ಲದೇ, ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ ಅಂತಹ
                ವಿಷಯದ ಸತ್ಯಾಸತ್ಯತೆ ಅಥವಾ ವಿಶ್ವಾಸಾರ್ಹತೆಯ ವಿಷಯದಲ್ಲಿ ಮೂರನೆಯ ವ್ಯಕ್ತಿ
                ಮಾರಾಟಗಾರರು ಪೋಸ್ಟ್ ಮಾಡಿದ ವಿಷಯಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಯಾವುದೇ
                ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಹೊಂದಿರುವುದಿಲ್ಲ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಅಪ್ಲಿಕೇಶನ್‌ಗೆ‌ ಸೀಮಿತ ಪರವಾನಗಿ</h3>
          <ol>
            <li>
              <p>
                ಈ ಒಪ್ಪಂದದ ನಿಯಮಗಳಿಗೆ ಅನುಸಾರವಾಗಿ ವೈಯಕ್ತಿಕ ಮತ್ತು ವಾಣಿಜ್ಯೇತರ
                ಉದ್ದೇಶಗಳಿಗಾಗಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸಲು ಮತ್ತು ಬಳಸಲು ನಾವು
                ನಿಮಗೆ ವೈಯಕ್ತಿಕ, ವಿಶ್ವದಾದ್ಯಂತ ಚಾಲ್ತಿಯಲ್ಲಿರುವ,
                ಹಿಂತೆಗೆದುಕೊಳ್ಳಬಹುದಾದ, ವರ್ಗಾವಣೆ ಮಾಡಲಾಗದ ಮತ್ತು ವಿಶೇಷವಲ್ಲದ
                ಪರವಾನಗಿಯನ್ನು ನೀಡುತ್ತೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಮತ್ತು ಈ ಒಪ್ಪಂದದಲ್ಲಿ ಸ್ಪಷ್ಟವಾಗಿ ನೀಡದಿರುವ ಎಲ್ಲ
                ಹಕ್ಕುಗಳು, ಸ್ವಾಮ್ಯ ಮತ್ತು ಹಿತಾಸಕ್ತಿಯನ್ನು ಕೊವೆಸ್ಟ್ರೋ ಕಾಯ್ದಿರಿಸಿದೆ.
                ನೀವು ಕೊವೆಸ್ಟ್ರೋ ಸಾಫ್ಟ್‌ವೇರ್, ಟೈಟಲ್, ವ್ಯಾಪಾರದ ಹೆಸರು,
                ಟ್ರೇಡ್‌ಮಾರ್ಕ್, ಸೇವಾ ಗುರುತು, ಲೋಗೋ, ಡೊಮೇನ್ ಹೆಸರು ಮತ್ತು/ಅಥವಾ
                ಗಮನಾರ್ಹ ಬ್ರ್ಯಾಂಡ್ ವೈಶಿಷ್ಟ್ಯಗಳೊಂದಿಗೆ ಅಥವಾ ಕೊವೆಸ್ಟ್ರೋ ಒಡೆತನದ ಇತರ
                ವಿಷಯದೊಂದಿಗೆ ಯಾವುದೇ ಇತರ ಗುರುತನ್ನು ಬಳಸಲು ಬಯಸಿದರೆ, ನೀವು ಕೊವೆಸ್ಟ್ರೋ
                ದಿಂದ ಲಿಖಿತ ಅನುಮತಿಯನ್ನು ಪಡೆಯಬೇಕು. ಅನುಮತಿ ವಿನಂತಿಗಳನ್ನು{' '}
                <a href='mailto:foodsecurity.platform@covestro.com'>
                  foodsecurity.platform@covestro.com
                </a>{' '}
                ಗೆ ಕಳುಹಿಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ಯಾವುದೇ ಬಗೆಯ ಸಂದೇಹವನ್ನು ತಪ್ಪಿಸಲು, ದೃಶ್ಯ ಸಂಪರ್ಕಸಾಧನಗಳು, ಸಂವಾದಾತ್ಮಕ
                ವೈಶಿಷ್ಟ್ಯಗಳು, ಗ್ರಾಫಿಕ್ಸ್, ವಿನ್ಯಾಸ, ಬಳಕೆದಾರರ ವಿಷಯದ ಸಂಕಲನ, ಮತ್ತು
                ಒಟ್ಟಾರೆ ಬಳಕೆದಾರರ ವಿಮರ್ಶೆ ರೇಟಿಂಗ್‌ಗಳ ಸಂಕಲನ ಮತ್ತು ಬಳಕೆದಾರರ
                ವಿಷಯವನ್ನು ಹೊರತುಪಡಿಸಿ (ಮೇಲೆ ವಿವರಿಸಿದಂತೆ) ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಎಲ್ಲ ಇತರ
                ಅಂಶಗಳು ಮತ್ತು ಘಟಕಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ಇವುಗಳಿಗೆ ಮಾತ್ರವೇ
                ಸೀಮಿತವಾಗಿರದೇ ಕೊವೆಸ್ಟ್ರೋ ಎಲ್ಲ ಪಠ್ಯ, ಚಿತ್ರಗಳು, ಫೋಟೋಗಳು, ಆಡಿಯೋ,
                ವೀಡಿಯೋ, ಸ್ಥಳ ಡೇಟಾ, ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಸಂಬಂಧಿಸಿದಂತೆ
                ಕೊವೆಸ್ಟ್ರೋ ರಚಿಸುವ ಮತ್ತು ಲಭ್ಯವಿರುವ ಎಲ್ಲ ರೀತಿಯ ಡೇಟಾ ಅಥವಾ ಸಂವಹನಗಳ
                ಸ್ವಾಮ್ಯವನ್ನು ಹೊಂದಿರುತ್ತದೆ. ಇಲ್ಲಿ ಸ್ಪಷ್ಟವಾಗಿ ಮತ್ತು
                ನಿಸ್ಸಂದಿಗ್ಧವಾಗಿ ಒದಗಿಸಿರುವುದನ್ನು ಹೊರತುಪಡಿಸಿ, ನಾವು ನಿಮಗೆ ಯಾವುದೇ
                ಹೇಳಲ್ಪಟ್ಟ ಅಥವಾ ಸೂಚ್ಯ ಹಕ್ಕುಗಳನ್ನು ನೀಡುವುದಿಲ್ಲ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್
                ಮತ್ತು ಕೊವೆಸ್ಟ್ರೋ ಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ವಿಷಯದ ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ನಾವು
                ನಮ್ಮೊಂದಿಗೆ ಉಳಿಸಿಕೊಂಡಿರುತ್ತೇವೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ವಿಷಯದ ಮಾನದಂಡಗಳ ಉಲ್ಲಂಘನೆ</h3>
          <ol>
            <li>
              <p>
                ವಿಷಯದ ಮಾನದಂಡಗಳ ಉಲ್ಲಂಘನೆಯು (ಮೇಲೆ ಹೇಳಿದಂತೆ) ಸಂಭವಿಸಿದೆ ಎಂದು ನಾವು
                ಪರಿಗಣಿಸಿದಾಗ, ನಮ್ಮ ವಿವೇಚನೆಯಿಂದ ನಾವು ಸೂಕ್ತವೆಂದು ಭಾವಿಸುವಂತಹ
                ಕ್ರಮವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ನೀವು ಅನುಸರಿಸಲು ವಿಫಲವಾದರೆ ಅದು ನೀವು
                ಸೇವೆಯನ್ನು ಬಳಸಲು ಅನುಮತಿಸಲಾದ ಬಳಕೆಯ ನಿಯಮಗಳ ಒಪ್ಪಂದದ ಉಲ್ಲಂಘನೆ
                ಎಂಬುದಾಗಿ ಪರಿಗಣಿಸಲ್ಪಡುತ್ತದೆ ಮತ್ತು ಕೆಳಗಿನ ಎಲ್ಲ ಅಥವಾ ಯಾವುದೇ
                ಕ್ರಮಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಕಾನೂನುಬದ್ಧವಾಗಿ ಲಭ್ಯವಿರುವ ಯಾವುದೇ ಕ್ರಮವನ್ನು
                ನಾವು ತೆಗೆದುಕೊಳ್ಳಬಹುದು:
              </p>
              <ol>
                <li>
                  <p>
                    ತಕ್ಷಣವೇ, ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಬಳಸುವ ನಿಮ್ಮ ಹಕ್ಕನ್ನು
                    ತಾತ್ಕಾಲಿಕವಾಗಿ ಅಥವಾ ಶಾಶ್ವತವಾಗಿ ಹಿಂತೆಗೆದುಕೊಳ್ಳುವಿಕೆ ಮತ್ತು ಅದೇ
                    ಸಮಯದಲ್ಲಿಯೇ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಖಾತೆಯನ್ನು
                    ಮುಕ್ತಾಯಗೊಳಿಸುವುದು/ಅಮಾನತುಗೊಳಿಸುವುದು;
                  </p>
                </li>
                <li>
                  <p>
                    ನಿಮ್ಮ ಗಮನಕ್ಕೆ ತಂದ ಉಲ್ಲಂಘನೆಯನ್ನು ಸರಿಪಡಿಸುವುದಕ್ಕೆ ನೀವು ವಿಫಲವಾದ
                    ನಂತರ, ಆ ತಕ್ಷಣವೇ, ಸೇವೆಗಳು ಮತ್ತು ಉತ್ಪನ್ನಗಳನ್ನು ಬಳಸಿಕೊಂಡು
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಈಗಾಗಲೇ ಪೋಸ್ಟ್ ಮಾಡಲಾದ ಯಾವುದೇ ಕೊಡುಗೆಯ
                    ತಾತ್ಕಾಲಿಕ ಅಥವಾ ಶಾಶ್ವತ ತೆಗೆದುಹಾಕುವಿಕೆ;
                  </p>
                </li>
                <li>
                  <p>ನಿಮಗೆ ಎಚ್ಚರಿಕೆಯನ್ನು ನೀಡುವುದು;</p>
                </li>
                <li>
                  <p>ನಿಮ್ಮ ವಿರುದ್ಧ ಹೆಚ್ಚಿನ ಕಾನೂನು ಕ್ರಮ; </p>
                </li>
                <li>
                  <p>
                    ಉಲ್ಲಂಘನೆಯಿಂದ ಉಂಟಾಗುವ ಎಲ್ಲ ವೆಚ್ಚಗಳನ್ನು (ಸಮಂಜಸವಾದ ಆಡಳಿತಾತ್ಮಕ
                    ಮತ್ತು ಕಾನೂನು ವೆಚ್ಚಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ಅದಕ್ಕೆ ಮಾತ್ರ
                    ಸೀಮಿತವಾಗಿರದೇ) ಮರುಪಾವತಿಗಾಗಿ ನಷ್ಟ ಪರಿಹಾರದ ಆಧಾರದ ಮೇಲೆ ನಿಮ್ಮ
                    ವಿರುದ್ಧ ಕಾನೂನು ಕ್ರಮಗಳು; ಮತ್ತು
                  </p>
                </li>
                <li>
                  <p>
                    ನಾವು ಸಮಂಜಸವೆಂದು ಭಾವಿಸುವ ನಮ್ಮ ಅನಿಸಿಕೆಗೆ ಅನುಗುಣವಾಗಿ ಅಥವಾ
                    ಅನ್ವಯವಾಗುವ ಕಾನೂನ ಪ್ರಕಾರ ಅಂತಹ ಮಾಹಿತಿಯನ್ನು ಕಾನೂನು ಜಾರಿಗೊಳಿಸುವ
                    ಅಧಿಕಾರಿಗಳಿಗೆ ಬಹಿರಂಗಪಡಿಸುವುದು.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ಈ ನಿಯಮಗಳ ಉಲ್ಲಂಘನೆಗಳಿಗೆ ಪ್ರತಿಕ್ರಿಯೆಯಾಗಿ ನಾವು ತೆಗೆದುಕೊಳ್ಳಬಹುದಾದ
                ಎಲ್ಲ ಕ್ರಮಗಳಿಗೆ ನಮ್ಮ ಹೊಣೆಗಾರಿಕೆಯನ್ನು ನಾವು ಈ ಮೂಲಕ ನಿರಾಕರಿಸುತ್ತೇವೆ
                ಮತ್ತು ಹೊಣೆಗಾರಿಕೆಯಿಂದ ಹೊರಗಿರುತ್ತೇವೆ. ಮೇಲೆ ವಿವರಿಸಿದ ಕ್ರಮಗಳು
                ಇಷ್ಟಕ್ಕೇ ಸೀಮಿತವಾಗಿಲ್ಲ, ಮತ್ತು ನಾವು ಸಮಂಜಸವಾಗಿ ಸೂಕ್ತವೆಂದು ಪರಿಗಣಿಸುವ
                ಯಾವುದೇ ಕ್ರಮವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ಖಾತೆಯ ಮುಕ್ತಾಯ/ಅಮಾನತುಗೊಳಿಸುವಿಕೆಯ ನಂತರ, ನೀವು ಇನ್ನು ಮುಂದೆ
                ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಅಥವಾ ನೀವು ಸರ್ವರ್‌ಗಳಲ್ಲಿ ಸಂಗ್ರಹಿಸಿದ ಯಾವುದೇ ವಿಷಯ
                ಅಥವಾ ಡೇಟಾವನ್ನು ಪ್ರವೇಶಿಸಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ ಕೊವೆಸ್ಟ್ರೋ ಮೂಲಕ ನಿಮಗೆ ನೀಡಲಾದ ಎಲ್ಲ
                ಪರವಾನಗಿಗಳು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಕೊನೆಗೊಳ್ಳುತ್ತವೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಬಿಲ್ಲಿಂಗ್ ಮತ್ತು ಪಾವತಿ</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಕೆಲವು ಅಂಶಗಳನ್ನು ಶುಲ್ಕ ಅಥವಾ ಇತರ ಚಾರ್ಜ್ ಪಡೆದುಕೊಂಡು
                ಒದಗಿಸಬಹುದು. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಪಾವತಿಸಿದ ಅಂಶಗಳನ್ನು ಬಳಸಲು ನೀವು ಆಯ್ಕೆ
                ಮಾಡಿದರೆ, ಅಂತಹ ಶುಲ್ಕಗಳು ಮತ್ತು ಚಾರ್ಜ್‌ಗಳಿಗೆ ಅನ್ವಯವಾಗುವ ಮಾರಾಟ
                ನಿಯಮಗಳು, ಬೆಲೆ, ಪಾವತಿ ಮತ್ತು ಬಿಲ್ಲಿಂಗ್ ನೀತಿಗಳನ್ನು ನೀವು
                ಒಪ್ಪುತ್ತೀರಿ. ಕೊವೆಸ್ಟ್ರೋ ತನ್ನ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ ಯಾವುದೇ ಸಮಯದಲ್ಲಿ
                ಹೆಚ್ಚುವರಿ ಶುಲ್ಕಗಳು ಮತ್ತು ಚಾರ್ಜ್‌ಗಳಿಗಾಗಿ ಹೊಸ ಸೇವೆಗಳನ್ನು
                ಸೇರಿಸಬಹುದು ಅಥವಾ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಸೇವೆಗಳಿಗೆ ಶುಲ್ಕಗಳು ಮತ್ತು
                ಚಾರ್ಜ್‌ಗಳನ್ನು ತಿದ್ದುಪಡಿ ಮಾಡಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ಸಂಪರ್ಕ ಅಥವಾ ಬಿಲ್ಲಿಂಗ್ ಮಾಹಿತಿಯಲ್ಲಿನ (ಫೋನ್ ಸಂಖ್ಯೆ, ಇಮೇಲ್ ವಿಳಾಸ,
                ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆಗಳು, ಇತ್ಯಾದಿ) ಯಾವುದೇ ಬದಲಾವಣೆಗಳು ಅಥವಾ
                ನವೀಕರಣಗಳನ್ನು ಕೊವೆಸ್ಟ್ರೋ ಗೆ ತ್ವರಿತವಾಗಿ ಒದಗಿಸುವುದು ನಿಮ್ಮ
                ಜವಾಬ್ದಾರಿಯಾಗಿದೆ. ಸುರಕ್ಷಿತ ಪಾವತಿಗಾಗಿ ಗ್ರಾಹಕರ ಪಾವತಿ ಪೂರೈಕೆದಾರರಿಗೆ
                ಅಗತ್ಯವಿರುವ ಎಲ್ಲ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ಕೊವೆಸ್ಟ್ರೋ
                ಮೌಲ್ಯೀಕರಿಸುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಮಾಡಿದ ಖರೀದಿಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ಯಾವುದೇ ರೀತಿಯ
                ಮರುಪಾವತಿ ವಿನಂತಿಗಳನ್ನು ಪರಿಗಣಿಸಲಾಗುವುದಿಲ್ಲ ಮತ್ತು ಅದಕ್ಕಾಗಿ ಯಾವುದೇ
                ಮರುಪಾವತಿಯನ್ನು ಪ್ರಾರಂಭಿಸಲಾಗುವುದಿಲ್ಲ ಎಂದು ನೀವು ಈ ಮೂಲಕ
                ಅಂಗೀಕರಿಸುತ್ತೀರಿ ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನಷ್ಟ ಪರಿಹಾರ ಮತ್ತು ಹೊಣೆಗಾರಿಕೆಯ ಮಿತಿ</h3>
          <ol>
            <li>
              <p>
                ಈ ಕೆಳಗೆ ನಮೂದಿಸಿದ ಯಾವುದೇ ಘಟನೆಗಳಿಂದ ಉಂಟಾಗುವ ಯಾವುದೇ ಮತ್ತು ಎಲ್ಲ
                ಹಕ್ಕುಗಳು, ಹಾನಿಗಳು, ಬಾಧ್ಯತೆಗಳು, ನಷ್ಟಗಳು, ಹೊಣೆಗಾರಿಕೆಗಳು, ವೆಚ್ಚಗಳು
                ಅಥವಾ ಋಣಭಾರಗಳು ಮತ್ತು ಖರ್ಚುಗಳಿಂದ (ವಕೀಲರ ಶುಲ್ಕ ಸೇರಿದಂತೆ, ಆದರೆ
                ಅದಕ್ಕೆ ಮಾತ್ರ ಸೀಮಿತವಾಗಿರದೇ) ಮತ್ತು ಅವುಗಳ ವಿರುದ್ಧ ಕೊವೆಸ್ಟ್ರೋ, ಅದರ
                ಅಧಿಕಾರಿಗಳು, ನಿರ್ದೇಶಕರು, ಉದ್ಯೋಗಿಗಳು ಮತ್ತು ಏಜೆಂಟರನ್ನು ರಕ್ಷಿಸಲು,
                ನಷ್ಟ ಪರಿಹಾರ ನೀಡಲು ಮತ್ತು ಹಾನಿಯುಂಟುಮಾಡದಿರಲು ನೀವು ಒಪ್ಪುತ್ತೀರಿ: (i)
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ನಿಮ್ಮ ಬಳಕೆ ಮತ್ತು
                ಪ್ರವೇಶ; (ii) ಈ ನಿಯಮಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಿಮ್ಮಿಂದ ಯಾವುದೇ ನಿಯಮ
                ಉಲ್ಲಂಘನೆ; (iii) ಯಾವುದೇ ಹಕ್ಕುಸ್ವಾಮ್ಯ, ಆಸ್ತಿ ಅಥವಾ ಗೌಪ್ಯತೆ ಹಕ್ಕು
                ಸೇರಿದಂತೆ ಆದರೆ ಅದಕ್ಕೆ ಮಾತ್ರ ಸೀಮಿತವಾಗಿರದೇ, ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿ
                ಹಕ್ಕು ಉಲ್ಲಂಘನೆ; (iv) ನಿಮ್ಮ ಬಳಕೆದಾರರ ವಿಷಯವು ಮೂರನೆಯ ವ್ಯಕ್ತಿಗೆ
                ಹಾನಿಯನ್ನುಂಟುಮಾಡಿದೆ ಎಂಬ ಯಾವುದೇ ರೀತಿಯ ಹಕ್ಕು ಕೇಳಿಕೆ. ಈ ರಕ್ಷಣೆ ಮತ್ತು
                ನಷ್ಟ ಪರಿಹಾರದ ಹೊಣೆಗಾರಿಕೆಯು ನಿಮ್ಮ ಈ ಒಪ್ಪಂದ ಮತ್ತು ಉತ್ಪನ್ನಗಳು ಮತ್ತು
                ಸೇವೆಗಳು ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಬಳಕೆಯನ್ನು ಪೂರೈಸುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ನಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಅಥವಾ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಪ್ರದರ್ಶಿಸಲಾದ ವಸ್ತುವನ್ನು ಅದರ
                ನಿಖರತೆಯ ಬಗ್ಗೆ ಯಾವುದೇ ಗ್ಯಾರೆಂಟಿಗಳು, ಷರತ್ತುಗಳು ಅಥವಾ ಖಾತರಿಗಳಿಲ್ಲದೆ
                ಒದಗಿಸಲಾಗಿದೆ. ಕಾನೂನಿನಿಂದ ಅನುಮತಿಸಲಾದ ಮಟ್ಟಿಗೆ, ನಾವು, ನಮ್ಮ
                ಅಂಗಸಂಸ್ಥೆಗಳು ಮತ್ತು ಅಧೀನಸಂಸ್ಥೆಗಳು ಮತ್ತು ನಮಗೆ ಸಂಪರ್ಕ ಹೊಂದಿದ ಮೂರನೆಯ
                ವ್ಯಕ್ತಿಗಳು ಈ ಮೂಲಕ ಇವುಗಳನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ಹೊರಗಿಡುತ್ತೇವೆ:
              </p>
              <ol>
                <li>
                  <p>
                    ಶಾಸನ, ಸಾಮಾನ್ಯ ಕಾನೂನು ಅಥವಾ ಸಮಾನತಾ ಕಾನೂನಿನಿಂದ ಸೂಚಿಸಬಹುದಾದ
                    ಷರತ್ತುಗಳು, ವಾರಂಟಿಗಳು ಮತ್ತು ಇತರ ನಿಯಮಗಳು;
                  </p>
                </li>
                <li>
                  <p>
                    ಸೇವೆಗಳು ಮತ್ತು ಉತ್ಪನ್ನಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್
                    ಅಥವಾ ಬಳಕೆಗೆ, ಬಳಕೆಯ ಸಮರ್ಥತೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ಅಥವಾ ಲಭ್ಯವಿರುವ
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಬಳಕೆಯ ಫಲಿತಾಂಶಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಅಥವಾ
                    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ಇದಕ್ಕೆ ಲಿಂಕ್ ಮಾಡಲಾದ ಯಾವುದೇ ವೆಬ್‌ಸೈಟ್ ಮತ್ತು
                    ಇದರ ಮೇಲೆ ಪೋಸ್ಟ್ ಮಾಡಲಾದ ಯಾವುದೇ ಅಂಶಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ
                    ಬಳಕೆದಾರರಿಂದ ಅನುಭವಿಸಲ್ಪಟ್ಟ ಯಾವುದೇ ರೀತಿಯ ಪ್ರತ್ಯಕ್ಷ, ಪರೋಕ್ಷ
                    ಅಥವಾ ಸಾಂದರ್ಭಿಕ ನಷ್ಟ ಅಥವಾ ಹಾನಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಯಾವುದೇ ಭಾದ್ಯತೆ,
                    ಕೆಳಗಿನವುಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ಅವುಗಳಿಗೆ ಮಾತ್ರ ಸೀಮಿತವಾಗಿರದೇ:
                  </p>
                  <ol>
                    <li>
                      <p>ಆದಾಯ ಅಥವಾ ರೆವಿನ್ಯೂ ನಷ್ಟ;</p>
                    </li>
                    <li>
                      <p>ವ್ಯಾಪಾರ ನಷ್ಟ;</p>
                    </li>
                    <li>
                      <p>ಲಾಭ ಅಥವಾ ಒಪ್ಪಂದಗಳ ನಷ್ಟ;</p>
                    </li>
                    <li>
                      <p>ನಿರೀಕ್ಷಿತ ಉಳಿತಾಯದ ನಷ್ಟ;</p>
                    </li>
                    <li>
                      <p>ಡೇಟಾ ನಷ್ಟ;</p>
                    </li>
                    <li>
                      <p>ಗುಡ್‌ವಿಲ್ ನಷ್ಟ;</p>
                    </li>
                    <li>
                      <p>ನಿರ್ವಹಣೆ ಅಥವಾ ಕಚೇರಿ ಸಮಯ ನಷ್ಟ; ಮತ್ತು</p>
                    </li>
                    <li>
                      <p>
                        ಒಪ್ಪಂದದ ಉಲ್ಲಂಘನೆ ಅಥವಾ ಇನ್ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ, ನಿರೀಕ್ಷಿತ
                        ಅಥವಾ ಅದೇ ಸಾಧ್ಯತೆಯ ಬಗ್ಗೆ ಸಲಹೆ ನೀಡಿದ್ದರೂ ಸಹ ಉದ್ಭವಿಸಿದ
                        ಮತ್ತು ವೈಯಕ್ತಿಕ ಅಪರಾಧದಿಂದ (ನಿರ್ಲಕ್ಷ್ಯ ಸೇರಿದಂತೆ) ಉಂಟಾದ
                        ಯಾವುದೇ ರೀತಿಯ ನಷ್ಟ ಅಥವಾ ಯಾವುದೇ ರೀತಿಯ ಹಾನಿ.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>
                ಕೆಳಗೆ ನಮೂದಿಸಲಾದ ಯಾವುದೇ ಅಂಶಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಯಾವುದೇ ಪಕ್ಷವು
                ಒಪ್ಪಂದದಲ್ಲಿ ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ, ವೈಯಕ್ತಿಕ ಅಪರಾಧ
                (ನಿರ್ಲಕ್ಷ್ಯವನ್ನು ಒಳಗೊಂಡಂತೆ, ಯಾವುದೇ ಮಿತಿಯಿಲ್ಲದೇ) ಅಥವಾ ಈ ನಿಯಮಗಳಿಂದ
                ಅಥವಾ ಅದಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಉಂಟಾಗುವ ಯಾವುದೇ ವಿಷಯಕ್ಕೂ
                ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ;
                <br />
                ಎ) ಯಾವುದೇ ಆರ್ಥಿಕ ನಷ್ಟಗಳು (ಯಾವುದೇ ಮಿತಿಯಿಲ್ಲದೆ, ಆದಾಯದ ನಷ್ಟ,
                ಲಾಭಗಳು, ಒಪ್ಪಂದಗಳು, ಡೇಟಾ, ವ್ಯವಹಾರ, ನಿರೀಕ್ಷಿತ ಉಳಿತಾಯ ಅಥವಾ ಬದಲಿ
                ಸೇವೆಗಳ ವೆಚ್ಚ ಸೇರಿದಂತೆ);
                <br /> ಬಿ) ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ ಗುಡ್‌ವಿಲ್ ಅಥವಾ ಖ್ಯಾತಿಯ ನಷ್ಟ;
                <br /> ಸಿ) ಯಾವುದೇ ವಿಶೇಷ, ಪರೋಕ್ಷ ಅಥವಾ ಸಾಂದರ್ಭಿಕ ನಷ್ಟಗಳು; ಅಂತಹ
                ನಷ್ಟಗಳು ಈ ನಿಯಮಗಳ ದಿನಾಂಕದಂದು ಪಕ್ಷಗಳ ಚಿಂತನೆಯಲ್ಲಿ ಇವೆಯೇ ಅಥವಾ ಇಲ್ಲವೇ
                ಎಂಬುದನ್ನು ಹೊರತುಪಡಿಸಿಯೂ ಅನ್ವಯವಾಗುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಗ್ರಾಹಕರ ಡೇಟಾದ ಅನಧಿಕೃತ ಪ್ರವೇಶ ಅಥವಾ ಬದಲಾವಣೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಅಥವಾ
                ನಿಮ್ಮ ಚಟುವಟಿಕೆಯಿಂದ ಡೇಟಾದಲ್ಲಿನ ಬದಲಾವಣೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ
                ಮತ್ತು ಅದರ ಸಂಪೂರ್ಣ ಸ್ವಾಮ್ಯದ ಅಂಗಸಂಸ್ಥೆಗಳು ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ
                ಅಥವಾ ಯಾವುದೇ ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಹೊಂದಿರುವುದಿಲ್ಲ ಎಂದು ನೀವು
                ಒಪ್ಪುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಲ್ಲಿನ ಯಾವುದೇ ದೋಷ, ನ್ಯೂನತೆ, ವೈಫಲ್ಯ ಅಥವಾ
                ವಿಳಂಬಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ ಯಾವುದೇ ಹೊಣೆಗಾರಿಕೆಯನ್ನು
                ಹೊಂದಿರುವುದಿಲ್ಲ. ಮೇಲಿನ ಸಾಮಾನ್ಯ ಸಂಗತಿಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಯಾವುದೇ
                ಪೂರ್ವಾಗ್ರಹವಿಲ್ಲದೆ, ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಲ್ಲಿನ ಯಾವುದೇ ವೈಫಲ್ಯ
                ಅಥವಾ ವಿಳಂಬಕ್ಕೆ ಕೊವೆಸ್ಟ್ರೋ ಯಾವುದೇ ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಹೊಂದಿರುವುದಿಲ್ಲ:
                ಯಾವುದೇ ವಿಶೇಷ, ಪರೋಕ್ಷ ಅಥವಾ ಸಾಂದರ್ಭಿಕ ನಷ್ಟಗಳು; ಅಂತಹ ನಷ್ಟಗಳು ಈ
                ನಿಯಮಗಳ ದಿನಾಂಕದಂದು ಪಕ್ಷಗಳ ಚಿಂತನೆಯಲ್ಲಿದೆಯೇ ಅಥವಾ ಇಲ್ಲವೇ
                ಎನ್ನುವುದನ್ನು ಹೊರತುಪಡಿಸಿ ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ.
                <br />
                ಎ) ಯಾವುದೇ ಸಾರ್ವಜನಿಕ ಇಂಟರ್ನೆಟ್ ವ್ಯವಸ್ಥೆಗಳು, ನೆಟ್‌ವರ್ಕ್‌ಗಳು ಅಥವಾ
                ಸರ್ವರ್‌ಗಳ ನಿಲುಗಡೆಯಿಂದ ಉಂಟಾದ ನಷ್ಟ; ಯಾವುದೇ ವಿಶೇಷ, ಪರೋಕ್ಷ ಅಥವಾ
                ಸಾಂದರ್ಭಿಕ ನಷ್ಟಗಳು; ಅಂತಹ ನಷ್ಟಗಳು ಈ ನಿಯಮಗಳ ದಿನಾಂಕದಂದು ಪಕ್ಷಗಳ
                ಚಿಂತನೆಯಲ್ಲಿದೆಯೇ ಅಥವಾ ಇಲ್ಲವೇ ಎನ್ನುವುದನ್ನು ಹೊರತುಪಡಿಸಿ
                ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ.
                <br />
                ಬಿ) ನಿಮ್ಮ ಉಪಕರಣಗಳು, ವ್ಯವಸ್ಥೆಗಳು ಅಥವಾ ಸ್ಥಳೀಯ ಪ್ರವೇಶ ಸೇವೆಗಳ
                ವೈಫಲ್ಯಗಳಿಂದ ಉಂಟಾದ ನಷ್ಟ ಅಥವಾ
                <br />
                ಸಿ) ಈ ಹಿಂದೆ ನಿಗದಿತ ನಿರ್ವಹಣೆಯ ಕಾರಣದಿಂದಾಗಿ ಉಂಟಾದ ನಷ್ಟ.
              </p>
            </li>
            <li>
              <p>
                ಈ ನಿಯಮಗಳ ಅಡಿಯಲ್ಲಿ ಅಥವಾ ಇವುಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಿಮ್ಮ ಮೇಲೆ
                ಕೊವೆಸ್ಟ್ರೋ ದ (ಮತ್ತು ಅದರ ಸಂಪೂರ್ಣ ಸ್ವಾಮ್ಯದ ಅಂಗಸಂಸ್ಥೆಗಳ) ಒಟ್ಟು
                ಸಂಚಿತ ಹೊಣೆಗಾರಿಕೆ (ಒಪ್ಪಂದದಲ್ಲಿ, ವೈಯಕ್ತಿಕ ಅಪರಾಧದಲ್ಲಿ (ನಿರ್ಲಕ್ಷ್ಯ
                ಸೇರಿದಂತೆ) ಅಥವಾ ಬೇರೆ ರೀತಿಯಲ್ಲಿ) ಯು ನೀವು ಪಾವತಿಸಿದ ಸದಸ್ಯತ್ವ ಶುಲ್ಕದ
                50 ಪ್ರತಿಶತವನ್ನು ಮೀರಬಾರದು.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಅಪರಾಧಗಳು</h3>
          <ol>
            <li>
              <p>
                ದುರುದ್ದೇಶಪೂರಿತ ಅಥವಾ ತಾಂತ್ರಿಕವಾಗಿ ಹಾನಿಕಾರಕವಾಗಿರುವ, ಅಥವಾ ಇತರ
                ಬಳಕೆದಾರರ ಹಕ್ಕುಗಳ ಹಿತಾಸಕ್ತಿಗಳನ್ನು ಮಿತಿಗೊಳಿಸುವ ಅಥವಾ ಯಾವುದೇ
                ಕಂಪ್ಯೂಟರ್ ಸಾಫ್ಟ್‌ವೇರ್, ಹಾರ್ಡ್‌ವೇರ್ ಅಥವಾ ದೂರಸಂಪರ್ಕಗಳ ಕಾರ್ಯವನ್ನು
                ಮಿತಿಗೊಳಿಸುವಂತಹ ವೈರಸ್‌ಗಳು, ಟ್ರೋಜನ್‌ಗಳು, ವರ್ಮ್‌ಗಳು, ಲಾಜಿಕ್
                ಬಾಂಬ್‍ಗಳು, ಟೈಮ್ ಬಾಂಬ್‌ಗಳು, ಕ್ಯಾನ್ಸೆಲ್ ಬೋಟ್‌ಗಳು, ಸ್ಪೈವೇರ್ ಅಥವಾ
                ಇತರ ಸಂಗತಿಗಳು, ಕಂಪ್ಯೂಟರ್ ಪ್ರೋಗ್ರಾಮಿಂಗ್ ರುಟೀನ್‌ಗಳು, ಫೈಲ್‌ಗಳು ಅಥವಾ
                ಅಂತಹ ಇತರ ಪ್ರೋಗ್ರಾಂಗಳನ್ನು ನೀವು ಉದ್ದೇಶಪೂರ್ವಕವಾಗಿ ಪರಿಚಯಿಸುವ ಮೂಲಕ
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ದುರ್ಬಳಕೆ ಮಾಡಬಾರದು. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್
                ಅನ್ನು ಸಂಗ್ರಹಿಸಿರುವ ಸರ್ವರ್ ಅಥವಾ ನಮ್ಮ ಸೈಟ್‌ಗೆ ಸಂಪರ್ಕಗೊಂಡಿರುವ
                ಯಾವುದೇ ಸರ್ವರ್, ಕಂಪ್ಯೂಟರ್ ಅಥವಾ ಡೇಟಾಬೇಸ್‌ಗೆ ಅನಧಿಕೃತ ಪ್ರವೇಶವನ್ನು
                ಪಡೆಯಲು ನೀವು ಪ್ರಯತ್ನಿಸಬಾರದು. ಸೇವೆಯ ನಿರಾಕರಣೆ ದಾಳಿ ಅಥವಾ ವಿತರಣೆ
                ನಿರಾಕರಣೆ ಸೇವೆಯ ದಾಳಿ ಮೂಲಕ ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೇಲೆ ಆಕ್ರಮಣ
                ಮಾಡಬಾರದು.
              </p>
            </li>
            <li>
              <p>
                ಈ ನಿಬಂಧನೆಯನ್ನು ಉಲ್ಲಂಘಿಸುವ ಮೂಲಕ, ನೀವು ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ ಕಾಯಿದೆ,
                2000 (ಮತ್ತು ಯಾವುದೇ ತಿದ್ದುಪಡಿಗಳು) ಅಡಿಯಲ್ಲಿ ಕ್ರಿಮಿನಲ್ ಅಪರಾಧವನ್ನು
                ಎಸಗುತ್ತೀರಿ. ನಾವು ಅಂತಹ ಯಾವುದೇ ಉಲ್ಲಂಘನೆಯನ್ನು ಸಂಬಂಧಿತ ಕಾನೂನು ಜಾರಿ
                ಅಧಿಕಾರಿಗಳಿಗೆ ವರದಿ ಮಾಡುತ್ತೇವೆ ಮತ್ತು ನಿಮ್ಮ ಗುರುತನ್ನು ಅವರಿಗೆ
                ಬಹಿರಂಗಪಡಿಸುವ ಮೂಲಕ ನಾವು ಆ ಅಧಿಕಾರಿಗಳಿಗೆ ಸಹಕರಿಸುತ್ತೇವೆ. ಅಂತಹ
                ಉಲ್ಲಂಘನೆಯ ಸಂದರ್ಭದಲ್ಲಿ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವ ನಿಮ್ಮ ಹಕ್ಕನ್ನು
                ತಕ್ಷಣವೇ ನಿಲ್ಲಿಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ವಿತರಿಸಲಾದ ಸೇವೆಯ ನಿರಾಕರಣೆ ದಾಳಿ, ವೈರಸ್‌ಗಳು ಅಥವಾ ಇತರ ತಾಂತ್ರಿಕವಾಗಿ
                ಹಾನಿಕಾರಕ ವಸ್ತುಗಳಿಂದ ಉಂಟಾಗುವ ಯಾವುದೇ ನಷ್ಟ ಅಥವಾ ಹಾನಿಗೆ ನಾವು
                ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ. ಇವುಗಳು ನಿಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಬಳಕೆಯಿಂದಾಗಿ
                ಅಥವಾ ಅಲ್ಲಿ ಪೋಸ್ಟ್ ಮಾಡಲಾದ ಯಾವುದೇ ವಿಷಯವನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡುವುದಕ್ಕೆ
                ಸಂಬಂಧಿಸಿದಂತೆ ಅಥವಾ ಇದಕ್ಕೆ ಲಿಂಕ್ ಆಗಲ್ಪಟ್ಟ ಯಾವುದೇ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್
                ಬಳಕೆಯ ಕಾರಣದಿಂದಾಗಿ ನಿಮ್ಮ ಕಂಪ್ಯೂಟರ್ ಸಾಧನ, ಕಂಪ್ಯೂಟರ್ ಪ್ರೋಗಾಂಗಳು,
                ಡೇಟಾ ಅಥವಾ ಇತರ ಸ್ವಾಮ್ಯದ ಅಂಶಗಳಿಗೆ ಹಾನಿಯುಂಟುಮಾಡುತ್ತವೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಾಗಿ ಹೆಚ್ಚುವರಿ ನಿಯಮಗಳು</h3>
          <ol>
            <li>
              <p>
                ನೀವು ಅಥವಾ ಇತರ ಬಳಕೆದಾರರಿಗೆ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ಸಾಫ್ಟ್‌ವೇರ್ ಅಥವಾ
                ವೆಬ್‌ಸೈಟ್ ಮೂಲಕ ವೆಬ್‌ಸೈಟ್ ಅಥವಾ ಅಪ್ಲಿಕೇಶನ್‌‌ ಮತ್ತು/ಅಥವಾ ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳನ್ನು ಪ್ರವೇಶಿಸಲು ಅನುವು ಮಾಡಿಕೊಡುವ ಉದ್ದೇಶಕ್ಕಾಗಿ ಮೊಬೈಲ್
                ಅಪ್ಲಿಕೇಶನ್‌, ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌‌, ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು
                ಮತ್ತು ಡೇಟಾವನ್ನು ಪ್ರವೇಶಿಸಲು ನಿಮಗೆ ಸೀಮಿತ, ಉಪ-ಪರವಾನಗಿರಹಿತ ಹಕ್ಕನ್ನು
                ನೀಡಲಾಗಿದೆ. ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌, ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌ನ ಯಾವುದೇ
                ರೀತಿಯ ಬಳಕೆಯು ಈ ಒಪ್ಪಂದದ ನಿಯಮಗಳು ಹಾಗೂ ಕೆಳಗಿನ ನಿರ್ದಿಷ್ಟ ನಿಯಮಗಳಿಗೆ
                ಬದ್ಧವಾಗಿದೆ:
              </p>
              <ol>
                <li>
                  <p>
                    ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ಸಾಫ್ಟ್‌ವೇರ್ ಮೂಲಕ ನೀವು ಒದಗಿಸುವ ಯಾವುದೇ
                    ಸೇವೆಗಳು ಮತ್ತು/ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ ನೀವು ಮಾತ್ರ
                    ಜವಾಬ್ದಾರರಾಗಿರುತ್ತೀರಿ (ಮತ್ತು ನಿಮಗೆ ಅಥವಾ ಯಾವುದೇ ಮೂರನೆಯ
                    ವ್ಯಕ್ತಿಗೆ ನಾವು ಯಾವುದೇ ಜವಾಬ್ದಾರಿ ಅಥವಾ ಹೊಣೆಗಾರಿಕೆಯನ್ನು
                    ಹೊಂದಿಲ್ಲ) ಎಂದು ನೀವು ಒಪ್ಪುತ್ತೀರಿ;
                  </p>
                </li>
                <li>
                  <p>
                    ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗೆ ಅನಪೇಕ್ಷಿತ ಸಂವಹನಗಳನ್ನು (ವಾಣಿಜ್ಯ ಅಥವಾ
                    ಬೇರೆ ಯಾವುದೇ) ಕಳುಹಿಸುವ ಸಾಫ್ಟ್‌ವೇರ್ ರಚಿಸಲು ನೀವು ಮೊಬೈಲ್
                    ಅಪ್ಲಿಕೇಶನ್‌ ಅಥವಾ ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌‌ ಅನ್ನು ಬಳಸುವುದಿಲ್ಲ;
                  </p>
                </li>
                <li>
                  <p>
                    48 (ನಲವತ್ತೆಂಟು) ಗಂಟೆಗಳ ನೋಟೀಸ್ ನೀಡುವ ಮೂಲಕ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌
                    ಮತ್ತು ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ನಿಮ್ಮ ಪ್ರವೇಶವನ್ನು
                    ತಾತ್ಕಾಲಿಕವಾಗಿ ಅಥವಾ ಶಾಶ್ವತವಾಗಿ ಮಾರ್ಪಡಿಸುವ ಅಥವಾ ನಿಲ್ಲಿಸುವ
                    ಹಕ್ಕನ್ನು ನಾವು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ;
                  </p>
                </li>
                <li>
                  <p>
                    ನಿಮ್ಮ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌‌ ಮತ್ತು ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌ನ
                    ಬಳಕೆಯ ಪರಿಣಾಮವಾಗಿ ಉಂಟಾಗುವ ಲಾಭಗಳ ನಷ್ಟ, ಗುಡ್‌ವಿಲ್, ಬಳಕೆ, ಡೇಟಾಗಳ
                    ನಷ್ಟ ಅಥವಾ ಇತರ ಅಮೂರ್ತ ನಷ್ಟ (ಅಂತಹ ಹಾನಿಗಳ ಸಾಧ್ಯತೆಯ ಬಗ್ಗೆ ನಮಗೆ
                    ಸಲಹೆ ನೀಡಿದ್ದರೂ ಸಹ) ಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ಅವುಗಳಿಗೆ ಮಾತ್ರ
                    ಸೀಮಿತವಾಗಿರದೇ, ಇತರ ಯಾವುದೇ ನೇರ, ಪರೋಕ್ಷ, ಪ್ರಾಸಂಗಿಕ, ವಿಶೇಷ,
                    ಸಾಂದರ್ಭಿಕ ಅಥವಾ ಅನುಕರಣೀಯ ಹಾನಿಗಳಿಗೆ ನಾವು ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ
                    ಎನ್ನುವುದನ್ನು ನೀವು ಸ್ಪಷ್ಟವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಮತ್ತು
                    ಒಪ್ಪುತ್ತೀರಿ.
                  </p>
                </li>
                <li>
                  <p>
                    ಯಾವುದೇ ಪೂರ್ವಸೂಚನೆಯೊಂದಿಗೆ ಅಥವಾ ಸೂಚನೆ ಇಲ್ಲದೆಯೇ 48 (ನಲವತ್ತೆಂಟು)
                    ಗಂಟೆಗಳ ನೋಟೀಸ್ ಅನ್ನು ಒದಗಿಸುವ ಮೂಲಕ ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌‌
                    ಮತ್ತು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ ಅನ್ನು ತಾತ್ಕಾಲಿಕವಾಗಿ ಅಥವಾ ಶಾಶ್ವತವಾಗಿ
                    ಮಾರ್ಪಡಿಸುವ ಅಥವಾ ನಿಲ್ಲಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ
                    ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ;
                  </p>
                </li>
                <li>
                  <p>
                    ಡೆಸ್ಕ್‌ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್‌‌ ಮತ್ತು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌‌ ಮೂಲಕ
                    ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ದುರುಪಯೋಗ ಅಥವಾ ಅತಿಯಾದ ಪುನರಾವರ್ತಿತ
                    ವಿನಂತಿಗಳು ನಿಮ್ಮ ಖಾತೆಯ ಪ್ರವೇಶದ ತಾತ್ಕಾಲಿಕ ಅಥವಾ ಶಾಶ್ವತ ಅಮಾನತಿಗೆ
                    ಕಾರಣವಾಗಬಹುದು. ನಾವು, ನಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ, ದುರ್ಬಳಕೆ ಅಥವಾ
                    ಅತಿಯಾದ ಬಳಕೆಯನ್ನು ನಿರ್ಧರಿಸುತ್ತೇವೆ;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಅಧಿಕೃತ ಪ್ರವೇಶ</h3>
          <ol>
            <li>
              <p>
                ನಿಮ್ಮ ಅನುಕೂಲತೆ ಮತ್ತು ಸಮಯದ ಬಗ್ಗೆ ನಾವು ಕಾಳಜಿ ವಹಿಸುತ್ತೇವೆ, ಇದಕ್ಕಾಗಿ
                ನಾವು ನಿರಂತರವಾಗಿ ಪ್ರಯತ್ನಿಸುತ್ತೇವೆ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಯಾವಾಗಲೂ
                ಲಭ್ಯವಿರುತ್ತದೆ ಎಂದು ನಾವು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತೇವೆ. ಆದಾಗ್ಯೂ, ತಾಂತ್ರಿಕ
                ದೋಷಗಳು ಮತ್ತು ಇತರ ಮಾನವೇತರ ದೋಷಗಳು ಸಂಭವಿಸುವುದು ಸಾಮಾನ್ಯವಾಗಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ತಡೆರಹಿತ ಅನುಭವವನ್ನು ಕಾಪಾಡಲು,
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್/ಸರ್ವರ್/ಲಿಂಕ್‌ಗಳು/ವೆಬ್-ಪುಟವನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅಥವಾ
                ಭಾಗಶಃ ಅದರ ಸಾಮಾನ್ಯ ನಿರ್ವಹಣೆಗಾಗಿ ಕೆಲವು ಸಮಯದವರೆಗೆ ಸ್ಥಗಿತಗೊಳಿಸುವ
                ಹಕ್ಕನ್ನು ನಾವು ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ, ಆದರೆ ನಿಮ್ಮ ಸಂತೋಷಕ್ಕಾಗಿ ಡೌನ್-ಟೈಮ್
                ಅನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಪ್ರಯತ್ನಿಸುತ್ತೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಸಂಪೂರ್ಣವಾಗಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ ಅದರ ಯಾವುದೇ ಭಾಗವು, ನಿರ್ವಹಣೆಯ
                ಕಾರಣದಿಂದ ಕೆಲವು ಸಮಯದವರೆಗೆ ಲಭ್ಯವಿಲ್ಲದಿದ್ದರೆ, ಯಾವುದೇ ಕಾರಣಕ್ಕೂ ನಾವು
                ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ ಎಂದು ದೃಢೀಕರಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ನೀವು ಅದನ್ನು
                ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಎಂದು ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನಮ್ಮ ಸೇವೆಗಳ ಬಳಕೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ನಮ್ಮ ಭದ್ರತಾ ಕಾರ್ಯವಿಧಾನದ
                ಭಾಗವಾಗಿ, ನಿಮ್ಮ ಇಮೇಲ್ ಅಥವಾ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸುವ ಮೂಲಕ ಮತ್ತು
                ಸುರಕ್ಷಿತ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಮೂಲಕ ಮತ್ತು/ಅಥವಾ ನಿಮ್ಮ
                ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ಅಥವಾ ಇಮೇಲ್ ವಿಳಾಸಕ್ಕೆ ಕಳುಹಿಸಲಾದ ಒನ್ ಟೈಮ್ ಪಾಸ್‌ವರ್ಡ್
                (ಒಟಿಪಿ) ಅನ್ನು ನಮೂದಿಸುವ ಮೂಲಕ ನಮ್ಮೊಂದಿಗೆ ಖಾತೆಯನ್ನು
                ನೋಂದಾಯಿಸಿಕೊಳ್ಳಬೇಕೆಂದು ನಾವು ಬಯಸುತ್ತೇವೆ. ಬಳಕೆದಾರರ ಪಾಸ್‌ವರ್ಡ್‌ಗಳ
                ವಿಷಯದಲ್ಲಿ, ಇತರರು ನಿರೀಕ್ಷಿಸಲು ಕಷ್ಟಕರವಾದ ಬಲವಾದ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು
                ನೀವು ಆಯ್ಕೆ ಮಾಡುವುದಕ್ಕೆ ಮತ್ತು ಪ್ರತಿ ಸೆಷನ್‌ನ ಕೊನೆಯಲ್ಲಿ ನಿಮ್ಮ
                ಖಾತೆಯಿಂದ ನೀವು ಲಾಗ್ ಔಟ್ ಆಗಬೇಕು ಎಂಬುದಾಗಿ ನಾವು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ.
                ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕಾಗಿ, ನೀವು ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಮರೆತರೆ ಪಾಸ್‌ವರ್ಡ್
                ಮರುಹೊಂದಿಸುವ ವಿಧಾನ ಲಭ್ಯವಿದೆ. ನಿಮ್ಮ ಖಾತೆಯ ಲಾಗ್-ಇನ್ ಮಾಹಿತಿಯನ್ನು
                ನೀವು ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗೆ ಬಹಿರಂಗಪಡಿಸದೆ ಗೌಪ್ಯವಾಗಿ
                ಪರಿಗಣಿಸುತ್ತೀರಿ ಮತ್ತು ಅವುಗಳನ್ನು ವೈಯಕ್ತಿಕವಾಗಿ ಬಳಸುತ್ತೀರಿ ಎಂದು ನೀವು
                ಈ ಮೂಲಕ ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ. ಭದ್ರತೆಯ ಉಲ್ಲಂಘನೆಯಾಗಿರಬಹುದು ಎಂದು ನೀವು
                ಭಾವಿಸಿದರೆ, ತಕ್ಷಣವೇ ನಮಗೆ ತಿಳಿಸುವುದು ಮತ್ತು ಲಾಗ್-ಇನ್ ಅನ್ನು
                ನಿರ್ಬಂಧಿಸದಿದ್ದರೆ, ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ತಕ್ಷಣವೇ ಬದಲಾಯಿಸುವುದು
                ನಿಮ್ಮ ಜವಾಬ್ದಾರಿಯಾಗಿದೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೀವು ಒದಗಿಸುವ ಎಲ್ಲಾ ಮಾಹಿತಿಯು ನಿಖರವಾಗಿರಬೇಕು,
                ಪ್ರಸ್ತುತವಾಗಿರಬೇಕು ಮತ್ತು ಸಂಪೂರ್ಣವಾಗಿರಬೇಕು ಎನ್ನುವುದುನೀವು
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಸುವುದಕ್ಕೆ ಇರುವ ಷರತ್ತು ಆಗಿರುತ್ತದೆ. ನಮ್ಮ
                ಅಭಿಪ್ರಾಯ ಮತ್ತು ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ, ಈ ಸೇವಾ ನಿಯಮಗಳ ಯಾವುದೇ
                ನಿಬಂಧನೆಗಳನ್ನು ಅನುಸರಿಸಲು ನೀವು ವಿಫಲರಾಗಿದ್ದರೆ, ಅಂತಹ ಕಾರಣಕ್ಕಾಗಿ
                ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ ಯಾವುದೇ ಬಳಕೆದಾರ ಖಾತೆಯನ್ನು
                ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಯಾವುದೇ ಮಾನವ ಹಸ್ತಕ್ಷೇಪವಿಲ್ಲದ ಕಾರ್ಯಾಚರಣೆಯಾಗಿದೆ
                ಎನ್ನುವುದನ್ನು ನೀವು ಒಪ್ಪುತ್ತೀರಿ ಮತ್ತು ಆ ಮೂಲಕ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಒದಗಿಸಲಾದ ಯಾವುದೇ ವಿಷಯವು ದೋಷದಿಂದ ಮುಕ್ತವಾಗಿಲ್ಲ
                ಎಂದು ಅಂಗೀಕರಿಸುತ್ತೀರಿ ಮತ್ತು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತೀರಿ ಮತ್ತು ಮಾನವ
                ಹಸ್ತಕ್ಷೇಪದಿಂದ ಉಂಟಾದ ಅನಾನುಕೂಲತೆ ಅಥವಾ ನಷ್ಟವನ್ನು ಹೊರತುಪಡಿಸಿ, ಬೇರೆ
                ಯಾವುದೇ ಅನಾನುಕೂಲತೆ ಅಥವಾ ನಷ್ಟ ಸಂಭವಿಸಿದರೆ ಅದನ್ನು ಸೇವೆಯ ಉಲ್ಲಂಘನೆ
                ಅಥವಾ ಕೊರತೆ ಎಂದು ಪರಿಗಣಿಸಲಾಗುವುದಿಲ್ಲ ಎಂಬುದಾಗಿ ಈ ಮೂಲಕ ಒಪ್ಪುತ್ತೀರಿ.
              </p>
            </li>
          </ol>
        </li>
        <p>
          ತಡೆಗಟ್ಟುವುದಕ್ಕೆ ಸಾಧ್ಯವಾಗದ ಘಟನೆ ಅಥವಾ ದುರಂತ ವೈಫಲ್ಯವನ್ನು ಉಂಟುಮಾಡದ ಎಲ್ಲ
          ತಾಂತ್ರಿಕ ದೋಷಗಳು, ಅಥವಾ ನೈಸರ್ಗಿಕ ವಿಕೋಪ ಅಥವಾ ಬೆಂಕಿಯ ಅಪಘಾತದಿಂದ ಉಂಟಾದ
          ದೋಷಗಳು, ಸರ್ಕಾರಿ ಆದೇಶ, ಗಲಭೆಗಳು ಅಥವಾ ಕೊವೆಸ್ಟ್ರೋದ ನಿಯಂತ್ರಣವನ್ನೂ ಮೀರಿದ ಇತರ
          ಅಂಶಗಳಿಂದ ಉಂಟಾದ ದೋಷ ಅಥವಾ ಹಾನಿಯನ್ನು 90 ರಿಂದ 120 ಗಂಟೆಗಳ ಒಳಗೆ ಸರಿಪಡಿಸುವ
          ಜವಾಬ್ದಾರಿಯನ್ನು ಕೊವೆಸ್ಟ್ರೋ ಕೈಗೆತ್ತಿಕೊಂಡಿರುತ್ತದೆ. ಆದಾಗ್ಯೂ, ಕೆಲವು
          ತಾಂತ್ರಿಕ ದೋಷಗಳು, ನೈಸರ್ಗಿಕ ವಿಪತ್ತು, ಸರ್ಕಾರಿ ಆದೇಶ, ಗಲಭೆಗಳು ಅಥವಾ ಯಾವುದೇ
          ಇತರ ಘಟನೆಯಿಂದಾಗಿ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಪ್ರವೇಶವನ್ನು ಒದಗಿಸದಿರುವುದಕ್ಕೆ ಕೊವೆಸ್ಟ್ರೋ
          ಅನ್ನು ಜವಾಬ್ದಾರಿಯನ್ನಾಗಿ ಮಾಡುವಂತಿಲ್ಲ.
        </p>
        <li>
          <h3>ಗೌಪ್ಯತೆ</h3>
          <ol>
            <li>
              <p>
                ಈ ನಿಯಮಗಳ ಉದ್ದೇಶಗಳಿಗಾಗಿ, &quot;ಗೌಪ್ಯ ಮಾಹಿತಿ&quot; ಯು ಒಂದು ಪಕ್ಷ
                ಅಥವಾ ವ್ಯಕ್ತಿಯು ಮತ್ತೊಂದು ಪಕ್ಷ ಅಥವಾ ವ್ಯಕ್ತಿಗೆ ಲಿಖಿತವಾಗಿ
                ಬಹಿರಂಗಪಡಿಸಲ್ಪಟ್ಟ ಹಾಗೂ &quot;ಗೌಪ್ಯ&quot; ಎಂಬುದಾಗಿ ಸೂಚಿಸಿದ ಅಥವಾ
                ಮೌಖಿಕವಾಗಿ ತಿಳಿಸಿದ ಹಾಗೂ ಐದು ವ್ಯವಹಾರ ದಿನಗಳ ಒಳಗೆ ಲಿಖಿತದಲ್ಲಿ
                ನಮೂದಿಸಲ್ಪಟ್ಟ ಮತ್ತು &quot;ಗೌಪ್ಯ&quot; ಎಂಬುದಾಗಿ ಗುರುತಿಸಲ್ಪಟ್ಟ
                ಯಾವುದೇ ರೀತಿಯ ಸ್ವಾಮ್ಯದ ಅಥವಾ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ಒಳಗೊಳ್ಳುತ್ತದೆ.
                ಆದಾಗ್ಯೂ, ಮಾಹಿತಿಯು ಒಬ್ಬ ವ್ಯಕ್ತಿ ಅಥವಾ ಪಕ್ಷವು ಬಹಿರಂಗಪಡಿಸುವ ಮೊದಲು
                ಸ್ವೀಕರಿಸುವ ಪಕ್ಷದ ಸ್ವಾಧೀನದಲ್ಲಿದ್ದರೆ ಅಥವಾ ಗೌಪ್ಯ ಮಾಹಿತಿಯನ್ನು ಬಳಸದೇ
                ಅಥವಾ ಈ ನಿಯಮಗಳಲ್ಲಿ ವಿವರಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ ಮಾಹಿತಿಯ ಮೂಲಕ ಅನುಮತಿಗೆ
                ಅನುಗುಣವಾಗಿ ಬಳಸಿಕೊಂಡಿದ್ದರೆ, ಸಾರ್ವಜನಿಕವಾಗಿ ತಿಳಿದಿರುವ ಅಥವಾ
                ಸಾರ್ವಜನಿಕರಿಗೆ ತಿಳಿಯಲ್ಪಡುವ ಅಂತಹ ಯಾವುದೇ ಮಾಹಿತಿಯನ್ನು ಗೌಪ್ಯತಾ
                ಮಾಹಿತಿಯು ಒಳಗೊಳ್ಳುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಈ ನಿಯಮಗಳ ಅಡಿಯಲ್ಲಿ ತನ್ನ ಬಾಧ್ಯತೆಗಳನ್ನು ನಿರ್ವಹಿಸುವ ಉದ್ದೇಶಕ್ಕಾಗಿ
                ಅಥವಾ ಕಾನೂನು, ನಿಯಂತ್ರಣ ಅಥವಾ ನ್ಯಾಯಾಲಯದ ಆದೇಶದ ಹೊರತಾಗಿ ಇತರ
                ಸಂದರ್ಭಗಳಲ್ಲಿ ಯಾವುದೇ ಪಕ್ಷವು ಮತ್ತೊಂದು ಪಕ್ಷದ ಪೂರ್ವ ಲಿಖಿತ
                ಒಪ್ಪಿಗೆಯಿಲ್ಲದೇ ಇತರ ಪಕ್ಷದ ಗೌಪ್ಯ ಮಾಹಿತಿಯನ್ನು ಬಳಸುವುದಿಲ್ಲ ಅಥವಾ
                ಬಹಿರಂಗಪಡಿಸುವುದಿಲ್ಲ; ಈ ಸಂದರ್ಭದಲ್ಲಿ, ಗೌಪ್ಯ ಮಾಹಿತಿಯನ್ನು
                ಬಹಿರಂಗಪಡಿಸಲು ಒತ್ತಾಯಿಸಲ್ಪಡುವ ಪಕ್ಷವು ಗೌಪ್ಯ ಮಾಹಿತಿಯನ್ನು
                ಬಹಿರಂಗಪಡಿಸುವ ಮೊದಲು ಸಮಂಜಸವಾಗಿ ಕಾರ್ಯಸಾಧ್ಯವಾಗುವಷ್ಟು ನೋಟೀಸ್ ಅನ್ನು
                ಮತ್ತೊಂದು ಪಕ್ಷಕ್ಕೆ ನೀಡುತ್ತದೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಗೌಪ್ಯತೆ ಮತ್ತು ವೈಯಕ್ತಿಕ ಡೇಟಾದ ರಕ್ಷಣೆ</h3>
          <ol>
            <li>
              <p>
                ಸೈಟ್‌ನಲ್ಲಿ ನೋಂದಾಯಿಸುವ ಮೂಲಕ, ಪ್ರಾಮಾಣಿಕ ಮತ್ತು ನಿಜವಾದ ಮಾಹಿತಿಯನ್ನು
                ನಮಗೆ ಒದಗಿಸಲು ನೀವು ಒಪ್ಪುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಸುಳ್ಳು ಮಾಹಿತಿಯನ್ನು ಸಂವಹನ ಮಾಡುವುದು ಚಾಲ್ತಿಯಲ್ಲಿರುವ ಸಾಮಾನ್ಯ ನಿಯಮಗಳು
                ಮತ್ತು ಷರತ್ತುಗಳಿಗೆ ವಿರುದ್ಧವಾಗಿದೆ ಮತ್ತು ಪರಿಣಾಮವಾಗಿ ಕಾನೂನು ಕ್ರಮಕ್ಕೆ
                ನಿಮ್ಮನ್ನು ಹೊಣೆಗಾರರನ್ನಾಗಿ ಮಾಡಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ಬಗ್ಗೆ ನಾವು ಹೊಂದಿರುವ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು
                ತಿಳಿದುಕೊಳ್ಳುವುದಕ್ಕೆ ನೀವು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಮಗೆ ವಿನಂತಿಯನ್ನು
                ಸಲ್ಲಿಸಬಹುದು. ನೀವು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಮತ್ತು ವಿನಂತಿಯ ಮೂಲಕ ಈ
                ಮಾಹಿತಿಯನ್ನು ಮಾರ್ಪಡಿಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ಹಾರ್ಡ್ ಡ್ರೈವ್‌ನಲ್ಲಿ ಸಂಗ್ರಹವಾಗಿರುವ ಕೆಲವು ಮಾಹಿತಿಯನ್ನು
                ಕುಕೀಗಳು ದಾಖಲಿಸುತ್ತವೆ. ಈ ಮಾಹಿತಿಯು ನಮ್ಮ ಸೈಟ್‌ಗೆ ಭೇಟಿ ನೀಡುವವರ ಡೇಟಾದ
                ಆಧಾರದ ಮೇಲೆ ಅಂಕಿಅಂಶಗಳನ್ನು ರಚಿಸಲು ಮತ್ತು ನೀವು ಈ ಹಿಂದೆ ಸೈಟ್‌ಗೆ ಭೇಟಿ
                ನೀಡಿದಾಗ ನೀವು ಆಯ್ಕೆ ಮಾಡಿದ ಐಟಂಗಳ ಪ್ರಕಾರ ನಿಮಗೆ ಆಸಕ್ತಿಯಿರುವ
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ನಿಮಗೆ ಸೂಚಿಸಲು ನಮಗೆ ಅನುವು
                ಮಾಡಿಕೊಡುತ್ತದೆ. ಕುಕೀಗಳು ನಿಮ್ಮ ಬಗೆಗಿನ ಯಾವುದೇ ಗೌಪ್ಯ ಮಾಹಿತಿಯನ್ನು
                ಹೊಂದಿರುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಕೆಲವು ಸೇವೆಗಳನ್ನು ಪ್ರವೇಶಿಸಲು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ನೀವು ಡೇಟಾವನ್ನು
                ಒದಗಿಸುವುದು ಅಗತ್ಯವಾಗಿರುತ್ತದೆ. ಮಾಹಿತಿಯು ನಿಮ್ಮ ಆರ್ಡ್‌ರ್‌ಗೆ
                ಸಂಬಂಧಿಸಿದ ವಿವರಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ಅದಕ್ಕೆ ಮಾತ್ರ ಸೀಮಿತವಾಗಿರದೇ
                ಅಂತಹ ಇತರ ವಿಷಯಗಳಿಗೆ ಸಂಬಂಧಿಸಿರಬಹುದು. ಅಂತಹ ಮಾಹಿತಿಯ ಗೌಪ್ಯತೆಯು ನಮ್ಮ
                ನಿಯಮಗಳ ಮೂಲಾಧಾರವಾಗಿದೆ, ಆದಾಗ್ಯೂ, ನಮ್ಮ ಗೌಪ್ಯತಾ ನೀತಿಯ ನಿಯಮಗಳಿಗೆ
                ಒಳಪಟ್ಟು (ಒದಗಿಸಲು ಲಿಂಕ್{' '}
                <a href={`${APP_DOMAIN}legal/privacy-statement`}>
                  {APP_DOMAIN}legal/privacy-statement
                </a>{' '}
                ), ನಮ್ಮ ವ್ಯಾಪಾರ, ಸಂಶೋಧನೆ ಮತ್ತು ವಿಶ್ಲೇಷಣೆಗಾಗಿ ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳು
                ಮತ್ತು ಸೇವೆಗಳ ಬಳಕೆಯಲ್ಲಿ ಸಂಗ್ರಹಿಸಿದ ಮಾಹಿತಿ ಅಥವಾ ಡೇಟಾವನ್ನು ನಾವು
                ಉಳಿಸಿಕೊಳ್ಳಬಹುದು ಮತ್ತು ಬಳಸಬಹುದು ಎಂಬುದಾಗಿ ನೀವು ಅಂಗೀಕರಿಸುತ್ತೀರಿ,
                ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ ಮತ್ತು ನಿಮ್ಮ ಸಮ್ಮತಿ ಸೂಚಿಸುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                (i) ಕಾನೂನಿನ ಮೂಲಕ ಅಗತ್ಯವಿದೆ ಎಂದು ತೀರ್ಮಾನಿಸಿದರೆ ಅಥವಾ ನಮ್ಮ, ಹಾಗೆಯೇ
                ಬಳಕೆದಾರರ ಅಥವಾ ಸಾರ್ವಜನಿಕರ ಹಕ್ಕುಗಳು, ಆಸ್ತಿ ಅಥವಾ ಸುರಕ್ಷತೆಯನ್ನು
                ರಕ್ಷಿಸುವುದಕ್ಕೆ ಡೇಟಾದ ಪ್ರವೇಶ, ಸಂರಕ್ಷಣೆ ಅಥವಾ ಬಹಿರಂಗಪಡಿಸುವಿಕೆ
                ಅತ್ಯಾವಶ್ಯಕ , ಎಂಬುದಾಗಿ ಉತ್ತಮ ನಂಬಿಕೆಯನ್ನು ಹೊಂದಿದ್ದರೆ; ಅಥವಾ (ii)
                ಡೇಟಾವನ್ನು ನಾವು ನಿರ್ದೇಶಿಸಿದ ಕಾರ್ಯಗಳ ಹೊರತಾಗಿ ಇತರ ಕಾರ್ಯಗಳಿಗೆ
                ಬಳಸುವುದನ್ನು ಅಥವಾ ಹಂಚಿಕೊಳ್ಳುವುದನ್ನು ತಡೆಯುವಂತಹ ಕೆಲವು ಕಟ್ಟುನಿಟ್ಟಾದ
                ನಿರ್ಬಂಧಗಳ ಜೊತೆಗೆ ನಮ್ಮ ಪರವಾಗಿ ಕಾರ್ಯಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಮೂರನೆಯ
                ವ್ಯಕ್ತಿಗಳಿಗೆ ಕೆಲವು ಸೀಮಿತ ಸಂದರ್ಭಗಳಲ್ಲಿ (ಉದಾ., ಬಿಲ್ಲಿಂಗ್ ಅಥವಾ ಡೇಟಾ
                ಸಂಗ್ರಹಣೆ) ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸುವುದು; ಈ ನಿಯಮಗಳಿಗೆ ಅನುಸಾರವಾಗಿ ಈ
                ಸಂದರ್ಭಗಳನ್ನು ಹೊರತುಪಡಿಸಿ, ಉಳಿದ ಸಂದರ್ಭಗಳಲ್ಲಿ ನಾವು ನಿಮ್ಮ ಡೇಟಾ ಅಥವಾ
                ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ಡೇಟಾವನ್ನು ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳೊಂದಿಗೆ
                ಹಂಚಿಕೊಳ್ಳುವುದಿಲ್ಲ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಬೌದ್ಧಿಕ ಆಸ್ತಿ</h3>
          <ol>
            <li>
              <p>
                ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿರುವ ಎಲ್ಲ ವಿಷಯಗಳು (ವಿವರಣೆಗಳು, ಪಠ್ಯಗಳು,
                ಹೆಸರುಗಳು, ಬ್ರ್ಯಾಂಡ್ ಹೆಸರುಗಳು, ಚಿತ್ರಗಳು ಮತ್ತು ವೀಡಿಯೊಗಳು)
                ಕೊವೆಸ್ಟ್ರೋ ದ ಆಸ್ತಿಯಾಗಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಯಾವುದೇ ವಿಧಾನದಿಂದ ಮತ್ತು ಯಾವುದೇ ಬೆಂಬಲದ ಮೇಲೆ, ಈ ವಿಷಯದ ಯಾವುದೇ ಭಾಗಶಃ
                ಅಥವಾ ಸಂಪೂರ್ಣ ಪುನರುತ್ಪಾದನೆಯು ಕೊವೆಸ್ಟ್ರೋ ದಿಂದ ಮುಂಚಿತವಾಗಿ ಮತ್ತು
                ಸ್ಪಷ್ಟ ಅಭಿವ್ಯಕ್ತ ಅಧಿಕಾರಕ್ಕೆ ಒಳಪಟ್ಟಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಎಲ್ಲ ಮಾಹಿತಿ, ವಿಷಯ ಮತ್ತು ಫೈಲ್‌ಗಳನ್ನು ಬೌದ್ಧಿಕ ಮಾಲೀಕತ್ವ ಮತ್ತು
                ಹಕ್ಕುಸ್ವಾಮ್ಯಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಕಾನೂನಿನ ಸಹಾಯದಿಂದ ರಕ್ಷಿಸಲಾಗಿದೆ.
              </p>
            </li>
            <li>
              <p>
                ಬೌದ್ಧಿಕ ಹಕ್ಕುಗಳನ್ನು ಹೊಂದಿಲ್ಲದ ವಿಷಯವನ್ನು ನೀವು ನಕಲಿಸಲು,
                ಪ್ರದರ್ಶಿಸಲು ಅಥವಾ ವಿತರಿಸಲು ನಾವು ನಿಮಗೆ ಹಕ್ಕನ್ನು ನೀಡುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಈ ವಿಷಯಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ಎಲ್ಲ ವಿಧದ ಮೋಸದ ಬಳಕೆಯು ಮೂರನೆಯ ವ್ಯಕ್ತಿ
                ಹೊಂದಿರುವ ಹಕ್ಕುಗಳನ್ನು ಉಲ್ಲಂಘಿಸುತ್ತದೆ ಹಾಗೂ ಫೋರ್ಜರಿ ಅಪರಾಧವಾಗಿದೆ,
                ಇದನ್ನು ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಕಾನೂನುಗಳ ಅಡಿಯಲ್ಲಿ ಕಠಿಣವಾಗಿ
                ಶಿಕ್ಷಿಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಬಳಕೆದಾರರು ಸೈಟ್‌ನಲ್ಲಿ ತಮ್ಮ ಚಟುವಟಿಕೆಗಳ ಮೂಲಕ ಮೂರನೆಯ ವ್ಯಕ್ತಿ
                ಹೊಂದಿರುವ ಹಕ್ಕುಗಳನ್ನು ಉಲ್ಲಂಘಿಸಿದರೆ, ಅವುಗಳಿಗೆ ನಾವು ಯಾವುದೇ
                ಸಂದರ್ಭಗಳಲ್ಲಿಯೂ ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಹಕ್ಕುಸ್ವಾಮ್ಯ ಕಾನೂನುಗಳಲ್ಲಿ ನಿರ್ದಿಷ್ಟಪಡಿಸಿರುವಂತೆ, ಅಥವಾ ಇತರ
                ಕಾನೂನುಗಳ ಮೂಲಕ ಸ್ಪಷ್ಟವಾಗಿ ಅನುಮತಿಸಿದಂತೆ, ಕಂಪನಿಯ ಒಪ್ಪಿಗೆಯಿಲ್ಲದೆ
                ಅಥವಾ ನಮೂದಿಸಲಾದ ಡೇಟಾ, ಚಿತ್ರಗಳು ಮತ್ತು ವಿಷಯಕ್ಕೆ ಹಕ್ಕುಗಳನ್ನು
                ಹೊಂದಿರುವರ ಸಮ್ಮತಿಯಿಲ್ಲದೇ, ವೈಯಕ್ತಿಕ ಬಳಕೆಯನ್ನು ಹೊರತುಪಡಿಸಿ ಇತರ
                ಯಾವುದೇ ಉದ್ದೇಶಕ್ಕಾಗಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಿಂದ ಪಡೆದ ಡೇಟಾ, ಚಿತ್ರಗಳು ಮತ್ತು
                ಇತರ ಯಾವುದೇ ರೀತಿಯ ವಿಷಯವನ್ನು ಬಳಸುವುದು, ವರ್ಗಾಯಿಸುವುದು, ನಕಲಿಸುವುದು,
                ವಿತರಿಸುವುದು ಅಥವಾ ಮಾರ್ಪಡಿಸುವುದನ್ನು ನಿಷೇಧಿಸಲಾಗಿದೆ.
              </p>
            </li>
            <li>
              <p>
                ಅಂತಿಮ ಬಳಕೆದಾರರು ಅಥವಾ ಯಾವುದೇ ಇತರ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳಿಂದ ಸೈಟ್‌ನಲ್ಲಿ
                ಪೋಸ್ಟ್ ಮಾಡಲಾದ ಅಥವಾ ಸೈಟ್ ಮೂಲಕ ರವಾನಿಸಲಾದ ವಸ್ತುಗಳಿಂದ ಅಥವಾ
                ಸೈಟ್‌ನಲ್ಲಿ ಜಾಹೀರಾತು ಮಾಡಿದ ಐಟಂಗಳಿಂದ ಉಂಟಾಗುವ ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕುಗಳ
                ಯಾವುದೇ ಉಲ್ಲಂಘನೆಗೆ ಕೊವೆಸ್ಟ್ರೋ ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ.
              </p>
              <ol>
                <li>
                  <p>
                    ನೀವು ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕುಗಳ ಮಾಲೀಕರಾಗಿದ್ದರೆ ಅಥವಾ ಬೌದ್ಧಿಕ ಆಸ್ತಿ
                    ಹಕ್ಕುಗಳ ಮಾಲೀಕರ ಪರವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಸಂಪೂರ್ಣವಾಗಿ ಅಧಿಕಾರ
                    ಹೊಂದಿರುವ ಏಜೆಂಟ್ ಆಗಿದ್ದರೆ ಮತ್ತು ಯಾವುದೇ ವಿಷಯ ಅಥವಾ ಇತರ ವಿಷಯವು
                    ನಿಮ್ಮ ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕು ಅಥವಾ ನೀವು ಕಾರ್ಯನಿರ್ವಹಿಸಲು ಅಧಿಕಾರ
                    ಹೊಂದಿರುವವ ಮಾಲೀಕರ ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕನ್ನು ಉಲ್ಲಂಘಿಸುತ್ತದೆ ಎಂದು
                    ನೀವು ಭಾವಿಸಿದರೆ, ನೀವು ಸಂಬಂಧಿತ ವಿಷಯವನ್ನು ಅಳಿಸಲು ಕೊವೆಸ್ಟ್ರೋ ಗೆ
                    ವಿನಂತಿಯೊಂದಿಗೆ ಇಮೇಲ್ ಅನ್ನು ಸಲ್ಲಿಸಬಹುದು:{' '}
                    <a href='mailto:foodsecurity.platform@covestro.com'>
                      foodsecurity.platform@covestro.com
                    </a>{' '}
                    ಗೆ ಇಮೇಲ್ ಮಾಡಿ.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>ವಾರಂಟಿಗಳ ಹಕ್ಕು ನಿರಾಕರಣೆ</h3>
          <ol>
            <li>
              <p>
                ಕೊವೆಸ್ಟ್ರೋ ಮುಂಚಿತ ಸೂಚನೆಯೊಂದಿಗೆ ಅಥವಾ ಸೂಚನೆಯಿಲ್ಲದೇ ಯಾವುದೇ ಸಮಯದಲ್ಲಿ
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು ಅಥವಾ ಸಾಫ್ಟ್‌ವೇರ್‌ನಲ್ಲಿ ಸುಧಾರಣೆಗಳು
                ಮತ್ತು/ಅಥವಾ ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡಬಹುದು. ಕಾರ್ಯಚಟುವಟಿಕೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ
                ಸೇವೆ, ಸಾಫ್ಟ್‌ವೇರ್‌ ಮತ್ತು ವರದಿಗಳನ್ನು &quot;ಅವು ಇರುವಂತೆಯೇ&quot;
                ಒದಗಿಸಲಾಗಿದೆ ಮತ್ತು ಅವುಗಳಲ್ಲಿ ಯಾವುದಕ್ಕೂ ಸಂಬಂಧಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ
                ಯಾವುದೇ ವಾರಂಟಿಗಳನ್ನು ನೀಡುವುದಿಲ್ಲ. ಮೇಲಿನ ಸಾಮಾನ್ಯ ಸಂಗತಿಗಳಿಗೆ ಮಾತ್ರ
                ಸೀಮಿತವಾಗಿರದೇ, ಕೊವೆಸ್ಟ್ರೋ ಈ ಕೆಳಗಿನ ಯಾವುದನ್ನೂ ಪ್ರತಿನಿಧಿಸುವುದಿಲ್ಲ
                ಅಥವಾ ವಾರಂಟಿ ನೀಡುವುದಿಲ್ಲ:
                <br />
                ಎ) ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಬಳಕೆ ಅಥವಾ ಸಾಫ್ಟ್‌ವೇರ್ ನಿಮ್ಮ
                ಅವಶ್ಯಕತೆಗಳನ್ನು ಪೂರೈಸುತ್ತದೆ ಅಥವಾ ತಡೆರಹಿತವಾಗಿರುತ್ತದೆ, ಸುರಕ್ಷಿತ
                ಅಥವಾ ದೋಷ-ಮುಕ್ತವಾಗಿರುತ್ತದೆ;
                <br />
                ಬಿ) ದೋಷಗಳನ್ನು ಸರಿಪಡಿಸಲಾಗುತ್ತದೆ;
                <br />
                ಸಿ) ಸೇವೆ, ಸಾಫ್ಟ್‌ವೇರ್ ಅಥವಾ ಸರ್ವರ್‌ನಲ್ಲಿರುವ ಯಾವುದೇ ಇತರ
                ಸಾಫ್ಟ್‌ವೇರ್‌ಗಳು ವೈರಸ್‌ಗಳು ಅಥವಾ ಇತರ ಹಾನಿಕಾರಕ ಘಟಕಗಳಿಂದ
                ಮುಕ್ತವಾಗಿವೆ;
                <br />
                ಡಿ) ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಬಳಕೆ ಮತ್ತು ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ
                ಮೂಲಕ ಲಭ್ಯವಿರುವ ಯಾವುದೇ ಮಾಹಿತಿಯು (ಕಾರ್ಯಚಟುವಟಿಕೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ
                ವರದಿಗಳನ್ನು ಒಳಗೊಂಡಂತೆ) ಸ್ಪಷ್ಟ, ನಿಖರ, ಸಮಯೋಚಿತ ಅಥವಾ
                ವಿಶ್ವಾಸಾರ್ಹವಾಗಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿ ಮತ್ತು ಬಳಕೆದಾರರ ವಿಷಯವನ್ನು ಆಕಸ್ಮಿಕ ನಷ್ಟದಿಂದ
                ಮತ್ತು ಅನಧಿಕೃತ ಪ್ರವೇಶ, ಬಳಕೆ, ಬದಲಾವಣೆ ಅಥವಾ ಬಹಿರಂಗಪಡಿಸುವಿಕೆಯಿಂದ
                ಸುರಕ್ಷಿತಗೊಳಿಸಲು ನಾವು ವ್ಯಾವಹಾರಿಕವಾಗಿ ಸಮಂಜಸವಾದ ತಾಂತ್ರಿಕ ಮತ್ತು
                ಸಾಂಸ್ಥಿಕ ಕ್ರಮಗಳನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸಿದ್ದೇವೆ ಹಾಗೂ ಜಾರಿಗೆ ತಂದಿದ್ದೇವೆ.
                ಆದಾಗ್ಯೂ, ಅನಧಿಕೃತ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳು ಆ ಕ್ರಮಗಳನ್ನು ದುರ್ಬಲಗೊಳಿಸಲು
                ಅಥವಾ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿ ಮತ್ತು ಬಳಕೆದಾರರ ವಿಷಯವನ್ನು ಅನುಚಿತ
                ಉದ್ದೇಶಗಳಿಗಾಗಿ ಬಳಸಲು ಎಂದಿಗೂ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ ಎಂಬುದಾಗಿ ನಾವು
                ಖಾತರಿಪಡಿಸುವುದಿಲ್ಲ. ನಿಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ
                ಮಾಹಿತಿಯನ್ನು ನೀವು ಒದಗಿಸುತ್ತೀರಿ ಎಂಬುದಾಗಿ ನೀವು ಅಂಗೀಕರಿಸುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಸಂಬಂಧಿತ ಸರಕುಗಳು ಅಥವಾ ಸೇವೆಗಳ ಪಾವತಿ ಮತ್ತು ಅಂತಹ ವ್ಯವಹಾರಗಳಿಗೆ
                ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ಇತರ ನಿಯಮಗಳು, ಷರತ್ತುಗಳು, ವಾರಂಟಿಗಳು ಅಥವಾ
                ಪ್ರಾತಿನಿಧ್ಯಗಳನ್ನು ಒಳಗೊಂಡಂತೆ, ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಮೂಲಕ ಅಥವಾ
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಮಾರಾಟಗಾರರ ಮೂಲಕ ಕಂಡುಬರುವ ಜಾಹೀರಾತುದಾರರೊಂದಿಗೆ
                ನಿಮ್ಮ ಸಂವಹನ ಅಥವಾ ವ್ಯಾಪಾರ ವ್ಯವಹಾರಗಳು ಅಥವಾ ಪ್ರಚಾರಗಳಲ್ಲಿ
                ಭಾಗವಹಿಸುವಿಕೆ ಮುಂತಾದವುಗಳು ನೀವು ಮತ್ತು ಅಂತಹ ಜಾಹೀರಾತುದಾರರು ಅಥವಾ
                ಮಾರಾಟಗಾರರ ನಡುವೆ ಮಾತ್ರ ಇರುತ್ತದೆ. ಇಲ್ಲಿ ಸೂಚಿಸಿರುವಂತೆ, ಅಂತಹ ಯಾವುದೇ
                ವ್ಯವಹಾರಗಳ ಪರಿಣಾಮವಾಗಿ ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು/ಮಾರುಕಟ್ಟೆಗೆ
                ಸಂಬಂಧಿಸಿದಂತೆ ಅಂತಹ ಜಾಹೀರಾತುದಾರರು ಅಥವಾ ಮಾರಾಟಗಾರರ ಉಪಸ್ಥಿತಿಯ
                ಪರಿಣಾಮವಾಗಿ ಉಂಟಾದ ಯಾವುದೇ ರೀತಿಯ ನಷ್ಟ ಅಥವಾ ಹಾನಿಗೆ ಕೊವೆಸ್ಟ್ರೋ
                ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ ಎನ್ನುವುದನ್ನು ನೀವು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು ಅಥವಾ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳು ಇತರ ವೆಬ್
                ಸೈಟ್‌ಗಳು ಅಥವಾ ಸಂಪನ್ಮೂಲಗಳಿಗೆ ಲಿಂಕ್‌ಗಳನ್ನು ಒದಗಿಸಬಹುದು. ಮೊದಲೇ
                ತಿಳಿಸಿರುವಂತೆ ಅಂತಹ ಸೈಟ್‌ಗಳು ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳ ಮೇಲೆ ನಮಗೆ ಯಾವುದೇ
                ನಿಯಂತ್ರಣವಿಲ್ಲದ ಕಾರಣ, ಅಂತಹ ಬಾಹ್ಯ ಸೈಟ್‌ಗಳು ಅಥವಾ ಸಂಪನ್ಮೂಲಗಳ
                ಲಭ್ಯತೆಗೆ ನಾವು ಜವಾಬ್ದಾರರಲ್ಲ ಎನ್ನುವುದನ್ನು ನೀವು ಅಂಗೀಕರಿಸುತ್ತೀರಿ
                ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ ಮತ್ತು ಅಂತಹ ಸೈಟ್‌ಗಳು ಅಥವಾ ಸಂಪನ್ಮೂಲಗಳಿಂದ
                ಲಭ್ಯವಿರುವ ಯಾವುದೇ ವಿಷಯ, ಜಾಹೀರಾತು, ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು
                ಅಥವಇತರ ವಸ್ತುಗಳನ್ನು ನಾವು ಅನುಮೋದಿಸುವುದಿಲ್ಲ ಮತ್ತು ಅವುಗಳಿಗೆ
                ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ. ಯಾವುದೇ ಸೈಟ್ ಅಥವಾ ಸಂಪನ್ಮೂಲದಲ್ಲಿ ಅಥವಾ ಅವುಗಳ
                ಮೂಲಕ ಲಾಭ್ಯವಿರುವ ಯಾವುದೇ ವಿಷಯ, ಸರಕುಗಳು ಅಥವಾ ಸೇವೆಗಳ ಬಳಕೆಯೊಂದಿಗೆ
                ಅಥವಾ ಅವುಗಳ ಮೇಲಿನ ನಂಬಿಕೆಯಿಂದ ಉಂಟಾಗುವ ಯಾವುದೇ ಹಾನಿ ಅಥವಾ ನಷ್ಟಕ್ಕೆ
                ಅಥವಾ ನಷ್ಟವಾಗಿದೆ ಎಂಬ ಆರೋಪಕ್ಕೆ ನಾವು ಪ್ರತ್ಯಕ್ಷವಾಗಿ ಅಥವಾ ಪರೋಕ್ಷವಾಗಿ
                ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ ಮತ್ತು ಬಾಧ್ಯಸ್ಥರಾಗಿರುವುದಿಲ್ಲ ಎಂದು ನೀವು
                ಅಂಗೀಕರಿಸುತ್ತೀರಿ ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮತ್ತು ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ನಿಮ್ಮ ಬಳಕೆಯು ನಿಮ್ಮ
                ವಿವೇಚನೆಗೆ ಮಾತ್ರವೇ ಒಳಪಟ್ಟಿದೆ ಎಂದು ನೀವು ಒಪ್ಪುತ್ತೀರಿ. ಅನ್ವಯವಾಗುವ
                ಕಾನೂನಿನಿಂದ ಗರಿಷ್ಠವಾಗಿ ಅನುಮತಿಸಲಾದ ಮಟ್ಟಿಗೆ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮತ್ತು
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ನಿಮ್ಮ ಬಳಕೆಗಾಗಿ &quot;ಅವು
                ಇರುವಂತೆ&quot; ಮತ್ತು &quot;ಅವು ಲಭ್ಯವಿರುವಂತೆ&quot; ಆಧಾರದ ಮೇಲೆ
                ಯಾವುದೇ ರೀತಿಯ, ಅಭಿವ್ಯಕ್ತ ಅಥವಾ ಸೂಚ್ಯ ಖಾತರಿಗಳಿಲ್ಲದೇ ಒದಗಿಸಲಾಗಿದೆ
                ಎಂದು ನೀವು ಸ್ಪಷ್ಟವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ. ಈ
                ವಾರಂಟಿಗಳು ಯಾವುದೇ ಮಿತಿಯಿಲ್ಲದೇ ವ್ಯಾಪಾರದ ಖಾತರಿಗಳು, ನಿರ್ದಿಷ್ಟ
                ಉದ್ದೇಶಕ್ಕಾಗಿ ಫಿಟ್‌ನೆಸ್, ಟೈಟಲ್, ಉಲ್ಲಂಘನೆಯಾಗದಿರುವುದು ಮತ್ತು
                ವ್ಯಾಪಾರದ ವ್ಯವಹಾರ ಅಥವಾ ಬಳಕೆಯಿಂದ ಉಂಟಾಗುವ ವಾರಂಟಿಗಳನ್ನು
                ಒಳಗೊಳ್ಳುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ನೀವು ಪೋಸ್ಟ್ ಮಾಡಿದ ಅಥವಾ ಅಪ್ಲೋಡ್ ಮಾಡಿದ ಯಾವುದೇ
                ವಿಷಯವು ತಮ್ಮ ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕುಗಳು ಅಥವಾ ತಮ್ಮ ಗೌಪ್ಯತೆಯ ಹಕ್ಕನ್ನು
                ಉಲ್ಲಂಘಿಸುತ್ತದೆ ಎಂದು ಮಾನ್ಯವಾಗಿ ಕ್ಲೈಮ್ ಮಾಡುವ ಮತ್ತು ಪುರಾವೆಗಳನ್ನು
                ಒದಗಿಸುವ ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಗೆ ನಿಮ್ಮ ಗುರುತನ್ನು ಬಹಿರಂಗಪಡಿಸುವ
                ಹಕ್ಕನ್ನು ನಾವು ಹೊಂದಿದ್ದೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ನೀವು ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಯಾವುದೇ ಇತರ ಬಳಕೆದಾರರು ಪೋಸ್ಟ್
                ಮಾಡಿದ ಯಾವುದೇ ವಸ್ತುಗಳ ವಿಷಯ ಅಥವಾ ನಿಖರತೆಗೆ ಅಥವಾ ಯಾವುದೇ ಮೂರನೆಯ
                ವ್ಯಕ್ತಿಗೆ ನಾವು ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಈ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು, ಈ ಒಪ್ಪಂದ ಅಥವಾ ಗೌಪ್ಯತಾ ನೀತಿಯನ್ನು
                ಅನುಸರಿಸದಿದ್ದಲ್ಲಿ, ನಿಮ್ಮ ಪ್ರವೇಶ ಅಥವಾ ಬಳಕೆಯ ಹಕ್ಕುಗಳನ್ನು ತಕ್ಷಣವೇ
                ಅಂತ್ಯಗೊಳಿಸಲು ಮತ್ತು ಅನುಸರಣೆಯಿಲ್ಲದ ಮಾಹಿತಿ ಅಥವಾ ವಸ್ತುಗಳನ್ನು
                ತೆಗೆದುಹಾಕುವ ಹಕ್ಕನ್ನು ನಾವು ಹೊಂದಿದ್ದೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಈ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳ ಉಲ್ಲಂಘನೆಗಳನ್ನು ಕಾನೂನಿನ ಪೂರ್ಣ
                ಪ್ರಮಾಣದಲ್ಲಿ ತನಿಖೆ ಮಾಡಲು ಮತ್ತು ಕಾನೂನು ಕ್ರಮ ಕೈಗೊಳ್ಳಲು ನಾವು
                ಹಕ್ಕನ್ನು ಹೊಂದಿದ್ದೇವೆ ಮತ್ತು ಈ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು
                ಉಲ್ಲಂಘಿಸುವ ಬಳಕೆದಾರರ ವಿರುದ್ಧ ಕಾನೂನು ಕ್ರಮ ಕೈಗೊಳ್ಳುವಲ್ಲಿ ನಾವು
                ಕಾನೂನು ಜಾರಿ ಅಧಿಕಾರಿಗಳೊಂದಿಗೆ ಕಾರ್ಯ ನಿರ್ವಹಿಸಬಹುದು ಮತ್ತು
                ಸಹಕರಿಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆ (ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಯಾವುದೇ
                ವೈಶಿಷ್ಟ್ಯ ಅಥವಾ ಭಾಗ) ಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಿಮ್ಮ ಪ್ರವೇಶ ಅಥವಾ ನಿಮ್ಮ
                ಬಳಕೆಯನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ನಾವು ಯಾವುದೇ ಬಾಧ್ಯತೆಯನ್ನು
                ಹೊಂದಿಲ್ಲವಾದರೂ, ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ನಿರ್ವಹಿಸುವ ಮತ್ತು
                ಉತ್ಪನ್ನಗಳನ್ನು ಒದಗಿಸುವ ಉದ್ದೇಶಕ್ಕಾಗಿ, ಈ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು
                ಅಥವಾ ಅನ್ವಯಿಸುವ ಕಾನೂನು ಅಥವಾ ನ್ಯಾಯಾಲಯ, ಆಡಳಿತಾತ್ಮಕ ಸಂಸ್ಥೆ ಅಥವಾ ಇತರ
                ಸರ್ಕಾರಿ ಸಂಸ್ಥೆಯ ಆದೇಶ ಅಥವಾ ಅಗತ್ಯತೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಿಮ್ಮ
                ಅನುಸರಣೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಾವು ಹಾಗೆ ಮಾಡುವ ಹಕ್ಕನ್ನು
                ಹೊಂದಿದ್ದೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಬಳಕೆದಾರರಿಂದ ದೂರನ್ನು ಸ್ವೀಕರಿಸಿದ ಸಂದರ್ಭದಲ್ಲಿ, ಯಾವುದೇ ಸೇವೆಯಿಂದ
                ಯಾವುದೇ ಅಥವಾ ಎಲ್ಲ ವಿಷಯವನ್ನು ಪೂರ್ವ-ಸ್ಕ್ರೀನ್ ಮಾಡುವ, ಪರಿಶೀಲಿಸುವ,
                ಫ್ಲ್ಯಾಗ್ ಮಾಡುವ, ಫಿಲ್ಟರ್ ಮಾಡುವ, ಮಾರ್ಪಡಿಸುವ, ನಿರಾಕರಿಸುವ ಅಥವಾ
                ತೆಗೆದುಹಾಕುವ ಹಕ್ಕನ್ನು ನಾವು ಹೊಂದಿದ್ದೇವೆ (ಆದರೆ ಯಾವುದೇ ಬಾಧ್ಯತೆ
                ಹೊಂದಿರುವುದಿಲ್ಲ). ಕೆಲವು ಉತ್ಪನ್ನ ಮತ್ತು ಸೇವೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ,
                ಕೊವೆಸ್ಟ್ರೋ ತನ್ನ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ ಸ್ಪಷ್ಟ ಲೈಂಗಿಕ ವಿಷಯಗಳು ಅಥವಾ
                ಸೂಕ್ತವಲ್ಲ ಎಂದು ಭಾವಿಸುವ ಯಾವುದೇ ಇತರ ವಿಷಯಗಳನ್ನು ಫಿಲ್ಟರ್ ಮಾಡುವುದಕ್ಕೆ
                (ಆದರೆ ಅವುಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ,ಯಾವುದೇ ಭಾದ್ಯತೆ ಹೊಂದಿರುವುದಿಲ್ಲ)
                ಟೂಲ್‌ಗಳನ್ನು ಒದಗಿಸಬಹುದು.
              </p>
            </li>
            <li>
              <p>
                ಕೆಳಗೆ ನಮೂದಿಸಿದ ವಿಷಯಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಅಗತ್ಯ ಎಂಬುದಾಗಿ ನಾವು
                ಭಾವಿಸುವಂತಹ ಯಾವುದೇ ಮಾಹಿತಿಯನ್ನು ಪ್ರವೇಶಿಸುವ, ಓದುವ, ಸಂರಕ್ಷಿಸುವ ಮತ್ತು
                ಬಹಿರಂಗಪಡಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ: (ಎ) ಯಾವುದೇ
                ಅನ್ವಯವಾಗುವ ಕಾನೂನು, ನಿಯಂತ್ರಣ, ಕಾನೂನು ಪ್ರಕ್ರಿಯೆ ಅಥವಾ ಸರ್ಕಾರಿ
                ವಿನಂತಿಯನ್ನು ಪೂರೈಸುವುದು; (ಬಿ) ಷರತ್ತು ಮತ್ತು ನಿಬಂಧನೆಗಳ ಸಂಭಾವ್ಯ
                ಉಲ್ಲಂಘನೆಗಳ ತನಿಖೆ ಸೇರಿದಂತೆ ಅವುಗಳನ್ನು ಜಾರಿಗೊಳಿಸುವುದು; (ಸಿ) ವಂಚನೆ,
                ಭದ್ರತೆ ಅಥವಾ ತಾಂತ್ರಿಕ ಸಮಸ್ಯೆಗಳನ್ನು ಪತ್ತೆ ಹಚ್ಚುವುದು, ತಡೆಗಟ್ಟುವುದು
                ಅಥವಾ ಪರಿಹರಿಸುವುದು; (ಡಿ) ಬಳಕೆದಾರರ ಬೆಂಬಲ ವಿನಂತಿಗಳಿಗೆ
                ಪ್ರತಿಕ್ರಿಯಿಸುವುದು; ಅಥವಾ (ಇ) ಕೊವೆಸ್ಟ್ರೋ, ಅದರ ಬಳಕೆದಾರರು ಮತ್ತು
                ಸಾರ್ವಜನಿಕರ ಹಕ್ಕುಗಳು, ಆಸ್ತಿ ಅಥವಾ ಸುರಕ್ಷತೆಯನ್ನು ರಕ್ಷಿಸುವುದು. ಈ
                ವಿಷಯದಲ್ಲಿ ಈ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಯಮಗಳ ಅಡಿಯಲ್ಲಿ ತನ್ನ ಹಕ್ಕುಗಳನ್ನು
                ಚಲಾಯಿಸುವುದು ಅಥವಾ ಚಲಾಯಿಸದಿರುವಿಕೆಗೆ ಕೊವೆಸ್ಟ್ರೋ
                ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ ಮತ್ತು ಬಾಧ್ಯಸ್ಥವಾಗಿರುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                30 (ಮೂವತ್ತು) ದಿನಗಳ ನೋಟೀಸ್ ನೀಡುವ ಮೂಲಕ ಎಲ್ಲ ಸೇವೆಗಳ ಬೆಲೆಗಳನ್ನು
                ಪರಿಚಯಿಸುವ ಅಥವಾ ಬದಲಾಯಿಸುವ ಹಕ್ಕನ್ನು ನಾವು ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ. ಬಳಕೆಯ
                ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳು ಅಥವಾ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ
                ಬದಲಾವಣೆಗಳನ್ನು ಪೋಸ್ಟ್ ಮಾಡುವ ಮೂಲಕ ಅಂತಹ ಸೂಚನೆಯನ್ನು
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿಯೇ ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಒದಗಿಸಬಹುದಾಗಿದೆ.
              </p>
            </li>
            <li>
              <p>
                ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳ ಯಾವುದೇ ಮಾರ್ಪಾಡು, ಬೆಲೆ ಬದಲಾವಣೆ, ಅಮಾನತು
                ಅಥವಾ ಸ್ಥಗಿತಗೊಳಿಸುವಿಕೆಗಾಗಿ ಕೊವೆಸ್ಟ್ರೋ ನಿಮಗೆ ಅಥವಾ ಯಾವುದೇ ಮೂರನೆಯ
                ವ್ಯಕ್ತಿಗೆ ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳ ಮೂಲಕ ಬಳಕೆದಾರರ ಸಲ್ಲಿಕೆ ಮತ್ತು ಬಳಕೆದಾರರ ವಿಷಯ</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವಾಗ, ನೀವು ವಿವಿಧ ಮೂಲಗಳಿಂದ ವಿಷಯವನ್ನು
                ಪಡೆದುಕೊಳ್ಳುತೀರಿ ಮತ್ತು ಅಂತಹ ವಿಷಯಗಳ ನಿಖರತೆ, ಉಪಯುಕ್ತತೆ, ಸುರಕ್ಷತೆ
                ಅಥವಾ ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕುಗಳಿಗೆ ಅಥವಾ ಬೌದ್ಧಿಕ ಆಸ್ತಿ ಹಕ್ಕುಗಳಿಗೆ
                ಕೊವೆಸ್ಟ್ರೋ ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ ಎಂದು ನೀವು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ
                ಮತ್ತು ನಿಮ್ಮ ಬಳಕೆಯ ಎಲ್ಲ ಹೊಣೆಗಾರಿಕೆಗೆ ನೀವು ಒಪ್ಪುತ್ತೀರಿ ಮತ್ತು
                ನಿರ್ವಹಿಸುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ಕೆಲವು ಸಂಗತಿಗಳೆಡೆಗಿನ ಕಳವಳವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಕೊವೆಸ್ಟ್ರೋ ಉತ್ತಮ
                ಪ್ರಯತ್ನಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತಿರುವ ಸಂದರ್ಭದಲ್ಲಿ, ನೀವು ತಪ್ಪಾದ,
                ಆಕ್ರಮಣಕಾರಿ, ಅಸಭ್ಯ, ಅಥವಾ ಆಕ್ಷೇಪಾರ್ಹ, ಮಾನಹಾನಿಕರ ಅಥವಾ ಅಪಮಾನಕರ
                ವಿಷಯವನ್ನು ನೀವು ಎದುರಿಸಬೇಕಾಗಬಹುದು ಎನ್ನುವುದನ್ನು ನೀವು
                ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಮತ್ತು ಅಂಗೀಕರಿಸುತ್ತೀರಿ ಮತ್ತು ಅದಕ್ಕೆ
                ಸಂಬಂಧಿಸಿದಂತೆ ಕೊವೆಸ್ಟ್ರೋ ವಿರುದ್ಧ ನೀವು ಹೊಂದಿರುವ ಅಥವಾ ಮುಂದಕ್ಕೆ
                ಹೊಂದಬಹುದಾದ ಕಾನೂನು ಅಥವಾ ಸಮಾನತಾ ಹಕ್ಕುಗಳು ಅಥವಾ ಪರಿಹಾರಗಳನ್ನು ನೀವು
                ಮನ್ನಾ ಮಾಡಲು ಒಪ್ಪುತ್ತೀರಿ ಮತ್ತು ಈ ಮೂಲಕ ಮನ್ನಾ ಮಾಡುತ್ತೀರಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಮನ್ನಾ ಮತ್ತು ತೀವ್ರತೆ</h3>
          <ol>
            <li>
              <p>
                ಸೇವಾ ನಿಯಮಗಳ ಯಾವುದೇ ಹಕ್ಕನ್ನು ಅಥವಾ ನಿಬಂಧನೆಗಳನ್ನು ಚಲಾಯಿಸಲು ಅಥವಾ
                ಜಾರಿಗೊಳಿಸಲು ಕೊವೆಸ್ಟ್ರೋ ದ ಯಾವುದೇ ರೀತಿಯ ವೈಫಲ್ಯವು ಅಂತಹ ಹಕ್ಕು ಅಥವಾ
                ನಿಬಂಧನೆಯ ಮನ್ನಾಕ್ಕೆ ಕಾರಣವಾಗುವುದಿಲ್ಲ.
              </p>
            </li>
            <li>
              <p>
                ಸೇವಾ ನಿಯಮಗಳು ನಿಮ್ಮ ಮತ್ತು ನಮ್ಮ ನಡುವಿನ ಸಂಪೂರ್ಣ ಒಪ್ಪಂದವನ್ನು ಯಾವುದೇ
                ಪೂರ್ವ ಒಪ್ಪಂದಗಳನ್ನು ರದ್ದುಗೊಳಿಸುವ ಮೂಲಕ (ಸೇವಾ ನಿಯಮಗಳ ಯಾವುದೇ ಹಿಂದಿನ
                ಆವೃತ್ತಿಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆದರೆ ವುಗಳಿಗೆ ಮಾತ್ರವೇ ಸೀಮಿತವಾಗಿರದೇ)
                ರೂಪಿಸುತ್ತವೆ ಮತ್ತು ಸೇವೆಯ ನಿಮ್ಮ ಬಳಕೆಯನ್ನು ನಿಯಂತ್ರಿಸುತ್ತವೆ. ಸಕ್ಷಮ
                ನ್ಯಾಯವ್ಯಾಪ್ತಿಯ ನ್ಯಾಯಾಲಯವು ಈ ಸೇವಾ ನಿಯಮಗಳ ಯಾವುದೇ ನಿಬಂಧನೆಯನ್ನು
                ಯಾವುದೇ ಕಾರಣಕ್ಕಾಗಿ ಅಮಾನ್ಯ, ಕಾನೂನುಬಾಹಿರ ಅಥವಾ ಜಾರಿಗೊಳಿಸಲಾಗದು ಎಂದು
                ತೀರ್ಮಾನಿಸಿದರೆ, ಅಂತಹ ನಿಬಂಧನೆಯನ್ನು ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ ಅಥವಾ ಕನಿಷ್ಠ
                ಮಟ್ಟಿಗೆ ಸೀಮಿತಗೊಳಿಸಲಾಗುತ್ತದೆ. ಅಂದರೆ ಸೇವಾ ನಿಯಮಗಳ ಉಳಿದ ನಿಬಂಧನೆಗಳು
                ಪೂರ್ಣ ಶಕ್ತಿ ಮತ್ತು ಪರಿಣಾಮದಲ್ಲಿ ಹಾಗೆಯೇ ಮುಂದುವರಿಯುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಅಥವಾ ಸೇವೆಯ ಮೂಲಕ ಯಾವುದೇ ಸಂಸ್ಥೆಗಳು ಮತ್ತು/ಅಥವಾ
                ವ್ಯಕ್ತಿಗಳೊಂದಿಗಿನ ನಿಮ್ಮ ಸಂವಹನಗಳಿಗೆ ಕೊವೆಸ್ಟ್ರೋ
                ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ ಎಂದು ನೀವು ಸ್ಪಷ್ಟವಾಗಿ ಅಂಗೀಕರಿಸುತ್ತೀರಿ
                ಮತ್ತು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ. ಇದು ಸರಕು ಮತ್ತು ಸೇವೆಗಳು ಮತ್ತು ಇತರ ಯಾವುದೇ
                ವಸ್ತುಗಳ ಪಾವತಿ ಮತ್ತು ವಿತರಣೆ, ಯಾವುದೇ ಇತರ ಸಂಸ್ಥೆಗಳು ಮತ್ತು/ಅಥವಾ
                ವ್ಯಕ್ತಿಗಳ ಜೊತೆಗೆ ನೀವು ಹೊಂದಿರುವ ಸಂವಹನಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಸ್ಥಿತಿಗಳು,
                ವಾರಂಟಿಗಳು, ಅಥವಾ ಪ್ರಾತಿನಿಧ್ಯಗಳನ್ನು ಒಳಗೊಳ್ಳುತ್ತದೆ ಆದರೆ ಅವುಗಳಿಗೆ
                ಮಾತ್ರ ಸೀಮಿತವಾಗಿಲ್ಲ. ಇವು ನಿಮ್ಮ ಮತ್ತು ಅಂತಹ ಸಂಸ್ಥೆಗಳು ಮತ್ತು/ಅಥವಾ
                ವ್ಯಕ್ತಿಗಳ ಮಾತ್ರ ನಡೆಯುವ ವ್ಯವಹಾರಗಳಾಗಿವೆ.
              </p>
            </li>
            <li>
              <p>
                ಅಂತಹ ಯಾವುದೇ ವ್ಯವಹಾರಗಳು ಅಥವಾ ಸಂವಹನಗಳ ಪರಿಣಾಮವಾಗಿ ಉಂಟಾದ ಯಾವುದೇ
                ರೀತಿಯ ನಷ್ಟ ಅಥವಾ ಹಾನಿಗೆ ಕೊವೆಸ್ಟ್ರೋ ಜವಾಬ್ದಾರಿಯಾಗಿರುವುದಿಲ್ಲ ಅಥವಾ
                ಬಾಧ್ಯಸ್ಥವಾಗಿರುವುದಿಲ್ಲ ಎಂದು ನೀವು ಒಪ್ಪುತ್ತೀರಿ ಮತ್ತು
                ಅಂಗೀಕರಿಸುತ್ತೀರಿ. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಭಾಗವಹಿಸುವವರ ನಡುವೆ ಅಥವಾ
                ಬಳಕೆದಾರರು ಮತ್ತು ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿಯ ನಡುವೆ ವಿವಾದವಿದ್ದರೆ, ಅಂತಹ
                ವಿವಾದದಲ್ಲಿ ಭಾಗಿಯಾಗಲು ಕೊವೆಸ್ಟ್ರೋ ಯಾವುದೇ ಬಾಧ್ಯತೆ ಹೊಂದಿಲ್ಲ ಎಂದು
                ನೀವು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೀರಿ ಮತ್ತು ಒಪ್ಪುತ್ತೀರಿ.
              </p>
            </li>
            <li>
              <p>
                ನೀವು ಒಬ್ಬ ವ್ಯಕ್ತಿ ಅಥವಾ ಹೆಚ್ಚಿನ ಇತರ ಬಳಕೆದಾರರೊಂದಿಗೆ ವಿವಾದವನ್ನು
                ಹೊಂದಿದ್ದರೆ, ಮತ್ತು/ಅಥವಾ ನಮ್ಮ ಸೇವೆಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ
                ಉದ್ಭವಿಸುವ ತಿಳಿದಿರುವ ಅಥವಾ ತಿಳಿದಿಲ್ಲದ, ಅನುಮಾನಾಸ್ಪದ ಮತ್ತು
                ಅನುಮಾನಾಸ್ಪದವಲ್ಲದ, ಬಹಿರಂಗಪಡಿಸಿದ ಮತ್ತು ಬಹಿರಂಗಪಡಿಸದ ಅಂತಹ ವಿವಾದಗಳಿಂದ
                ಸಂಭವಿಸುವ ಯಾವುದೇ ಮತ್ತು ಎಲ್ಲ ರೀತಿಯ ಹಕ್ಕುಗಳು, ಬೇಡಿಕೆಗಳು ಮತ್ತು ಹಾನಿ
                (ವಾಸ್ತವಿಕ ಮತ್ತು ಪರಿಣಾಮವಾಗಿ ಸಂಭವಿಸುವ) ಗಳಿಂದ ನೀವು ಈ ಮೂಲಕ
                ಕೊವೆಸ್ಟ್ರೋ, ಅದರ ಅಧಿಕಾರಿಗಳು, ಉದ್ಯೋಗಿಗಳು, ಏಜೆಂಟ್‌ಗಳು ಮತ್ತು
                ಉತ್ತರಾಧಿಕಾರಿಗಳನ್ನು ಬಿಡುಗಡೆ ಮಾಡುತ್ತೀರಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಮೊಬೈಲ್ ಸಾಧನಗಳ ಬಳಕೆ</h3>
          <ol>
            <li>
              <p>
                ನೀವು ಮೊಬೈಲ್ ಸಾಧನದಲ್ಲಿ ಅಪ್ಲಿಕೇಶನ್‌ ಅನ್ನು ಬಳಸುತ್ತಿದ್ದರೆ ಪಠ್ಯ ಸಂದೇಶ
                ಕಳುಹಿಸುವಿಕೆ ಮತ್ತು ಡೇಟಾ ಶುಲ್ಕಗಳಂತಹ ನಿಮ್ಮ ವಾಹಕದ ಸಾಮಾನ್ಯ ದರಗಳು
                ಮತ್ತು ಶುಲ್ಕಗಳು ಈಗಲೂ ಅನ್ವಯಿಸುತ್ತವೆ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಗಮನಿಸಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನಿಷೇಧಿತ ಕ್ರಮಗಳು</h3>
          <ol>
            <li>
              <p>
                ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಕೆದಾರರು ಈ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳ ಇತರ
                ನಿಬಂಧನೆಗಳಿಂದ ನಿಷೇಧಿಸಲ್ಪಟ್ಟಿರುವಂತಹವುಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಈ ಕೆಳಗಿನ
                ಕ್ರಿಯೆಗಳಲ್ಲಿ ತೊಡಗಿಕೊಳ್ಳುವುದನ್ನು ನಿಷೇಧಿಸಲಾಗಿದೆ:
              </p>
              <ul>
                - ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳು, ಕಂಪನಿ ಅಥವಾ ಅದರ ಪಾಲುದಾರ
                ಕಂಪನಿಗಳ ಇತರ ಬಳಕೆದಾರರ ಹಕ್ಕುಸ್ವಾಮ್ಯಗಳು, ಗೌಪ್ಯತೆ, ಆಸ್ತಿ ಮತ್ತು ಇತರ
                ಹಕ್ಕುಗಳು ಅಥವಾ ಹಿತಾಸಕ್ತಿಗಳನ್ನು ಉಲ್ಲಂಘಿಸುವ ಅಥವಾ ಉಲ್ಲಂಘಿಸಬಹುದಾದ
                ಕ್ರಿಯೆಗಳು.
              </ul>
              <ul>
                - ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಇತರ ಬಳಕೆದಾರರು, ಮೂರನೆಯ ವ್ಯಕ್ತಿಗಳು, ಕಂಪನಿ ಅಥವಾ
                ಅದರ ಪಾಲುದಾರ ಕಂಪನಿಗಳಿಗೆ ಅನನುಕೂಲ, ಹಣಕಾಸಿನ ನಷ್ಟ ಅಥವಾ ಇತರ ಹಾನಿಗಳನ್ನು
                ಉಂಟುಮಾಡುವ ಕ್ರಿಯೆಗಳು.
              </ul>
              <ul>
                - ಸಾರ್ವಜನಿಕ ಸುವ್ಯವಸ್ಥೆ ಮತ್ತು ನೈತಿಕತೆಗೆ ಅಥವಾ ಕಾನೂನುಗಳು,
                ನಿಬಂಧನೆಗಳು ಮತ್ತು ಕಟ್ಟಳೆಗಳನ್ನು ಉಲ್ಲಂಘಿಸುವ ಅಥವಾ ಆಕ್ರಮಣಕಾರಿಯಾದ
                ಕ್ರಮಗಳು.
              </ul>
              <ul>
                - ಸದಸ್ಯತ್ವ ನೋಂದಣಿ ಅಥವಾ ಇತರ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು
                ಬಳಸುವಾಗ ನೀಡಲಾದ ತಪ್ಪು ಮಾಹಿತಿಗಳು.
              </ul>
              <ul>
                - ಇಮೇಲ್ ವಿಳಾಸಗಳು, ಪಾಸ್‌ವರ್ಡ್‌ಗಳು ಅಥವಾ ಇತರ ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯ
                ಕಾನೂನುಬಾಹಿರ ಬಳಕೆ.
              </ul>
              <ul>
                - ವ್ಯಾಪಾರದ ಉದ್ದೇಶಕ್ಕಾಗಿ ಮನವಿ ಸಲ್ಲಿಸಲು ಅಥವಾ ಲಾಭಕ್ಕಾಗಿ ಅಥವಾ ಇತರ
                ವಾಣಿಜ್ಯ ಉದ್ದೇಶಗಳನ್ನು ಸಾಧಿಸಲು ಅಥವಾ ಅದರ ತಯಾರಿಗಾಗಿ ಈ ವೇದಿಕೆಯನ್ನು
                ಬಳಸುವುದು.
              </ul>
              <ul>
                - ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಕಾರ್ಯಾಚರಣೆಯನ್ನು ಅಡ್ಡಿಪಡಿಸುವ ಅಥವಾ ಸೇವೆಗಳ
                ನಿಬಂಧನೆಗೆ ಅಡ್ಡಿಪಡಿಸುವ ಇತರ ಕ್ರಮಗಳು.
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಆಡಳಿತ ಕಾನೂನುಗಳು</h3>
          <ol>
            <li>
              <p>
                ಬಳಕೆಯ ಷರತ್ತುಗಳು ಮತ್ತು ನಿಬಂಧನೆಗಳ ನಿರೂಪಣೆ, ಕಾನೂನು ಬಲ, ಅನ್ವಯಿಕೆ
                ಮತ್ತು ವ್ಯಾಖ್ಯಾನಗಳು ಭಾರತದ ಕಾನೂನುಗಳ ಮೂಲಕ ನಿಯಂತ್ರಿಸಲ್ಪಡುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಭಾರತವನ್ನು ಹೊರತುಪಡಿಸಿ, ಇತರ ಯಾವುದೇ ದೇಶದ ಕಾನೂನುಗಳನ್ನು
                ಅನುಸರಿಸದಿರುವುದಕ್ಕಾಗಿ ಕೊವೆಸ್ಟ್ರೋ ನೇರ ಅಥವಾ ಪರೋಕ್ಷವಾಗಿ ಯಾವುದೇ
                ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ. ಭಾರತವನ್ನು ಹೊರತುಪಡಿಸಿ ಬೇರೆ
                ದೇಶದಲ್ಲಿ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರವೇಶಿಸಬಹುದು ಅಥವಾ ಬಳಸಬಹುದು ಅಥವಾ
                ಯಾವುದೇ ಸೌಲಭ್ಯವನ್ನು ಪಡೆದುಕೊಳ್ಳಬಹುದು ಎನ್ನುವ ಅಂಶವು ನಾವು ಆ ದೇಶದ
                ಕಾನೂನುಗಳನ್ನು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೇವೆ ಎನ್ನುವುದನ್ನು ಸೂಚಿಸುವುದಿಲ್ಲ
                ಎನ್ನುವುದು ವಾಸ್ತವದ ಸಂಗತಿಯಾಗಿದೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನ್ಯಾಯವ್ಯಾಪ್ತಿ</h3>
          <ol>
            <li>
              <p>
                ಈ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಬಳಕೆಯ ಕುರಿತು ಬಳಕೆದಾರರು ಮತ್ತು ಕಂಪನಿ ಅಥವಾ
                ಪಾಲುದಾರ ಕಂಪನಿಯ ನಡುವಿನ ವಿವಾದಗಳನ್ನು ಮಹಾರಾಷ್ಟ್ರದ ಮುಂಬೈ
                ನ್ಯಾಯಾಲಯಗಳಲ್ಲಿ ಪ್ರತ್ಯೇಕವಾಗಿ ವಿಚಾರಣೆ ಮಾಡಲಾಗುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ಮೇಲಿನವುಗಳ ಹೊರತಾಗಿಯೂ, ಯಾವುದೇ ಸಮರ್ಥ ನ್ಯಾಯವ್ಯಾಪ್ತಿಯ ನ್ಯಾಯಾಲಯದಲ್ಲಿ
                ತಡೆಯಾಜ್ಞೆ ಪರಿಹಾರಗಳನ್ನು (ಅಥವಾ ಸಮಾನ ರೀತಿಯ ತುರ್ತು ಕಾನೂನು ಪರಿಹಾರ)
                ಪಡೆಯುವ ಹಕ್ಕನ್ನು ಕೊವೆಸ್ಟ್ರೋ ಹೊಂದಿರುತ್ತದೆ ಎಂಬುದಾಗಿ ನೀವು
                ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನಿಯಮಗಳು ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಮಾರ್ಪಾಡು</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಕಾರ್ಯ ನಿರಂತರ ಪ್ರಗತಿಯಲ್ಲಿರುತ್ತದೆ, ಹಾಗಾಗಿ ಸೇರ್ಪಡೆಗಳು
                ಅಥವಾ ಅಳಿಸುವಿಕೆಗಳು ಅಥವಾ ಪರ್ಯಾಯ ವಿಧಾನಗಳ ಮೂಲಕ ಅಥವಾ ಬೇರೆ ರೀತಿಯಲ್ಲಿ
                ಮಾರ್ಪಾಡುಗಳಿಗೆ ಒಳಪಟ್ಟಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ವೆಬ್‌ಸೈಟ್, ಅಪ್ಲಿಕೇಶನ್‌ ಮತ್ತು ಈ ನಿಯಮಗಳನ್ನು ಕಾಲಕಾಲಕ್ಕೆ, ನಮ್ಮ
                ವಿವೇಚನೆಯಿಂದ ಮತ್ತು ಯಾವುದೇ ಸೂಚನೆ ಇಲ್ಲದೆ ನವೀಕರಿಸುವ ಹಕ್ಕನ್ನು ನಾವು
                ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ.
              </p>
            </li>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅಥವಾ ಸೇವೆಗಳನ್ನು ಪ್ರವೇಶಿಸಿದಾಗ ಪ್ರತಿ ಬಾರಿಯೂ ಸರಿಯಾಗಿ
                ಓದುವ ಮೂಲಕ ಇತ್ತೀಚಿನ ನಿಯಮಗಳನ್ನು ತಿಳಿದುಕೊಳ್ಳುವುದು ಗ್ರಾಹಕರ ಸಂಪೂರ್ಣ
                ಜವಾಬ್ದಾರಿ ಮತ್ತು ಕರ್ತವ್ಯವಾಗಿರುತ್ತದೆ.
              </p>
            </li>
            <li>
              <p>
                ನವೀಕರಿಸಿದ ಸೇವಾ ನಿಯಮಗಳ ಪ್ರಕಟಣೆಯ ನಂತರ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ ಮುಂದುವರಿದ
                ಬಳಕೆಯನ್ನು ಗಮನಿಸಿ, ಬಳಕೆದಾರರು ಮಾರ್ಪಡಿಸಿದ ನಿಯಮಗಳನ್ನು ಓದಿದ್ದಾರೆ
                ಮತ್ತು ಒಪ್ಪಿಕೊಂಡಿದ್ದಾರೆ ಎಂದು ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ನಿಮ್ಮ ಸ್ವಂತ ವಿವೇಚನೆಯಿಂದ ಬಳಸಿ</h3>
          <ol>
            <li>
              <p>
                ಆಹಾರ ಭದ್ರತೆಗೆ ಸಹಾಯ ಮಾಡಲು, ಪ್ರೇರೇಪಿಸಲು ಮತ್ತು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು
                ಒರಿಜಿನಲ್, ಸಂಶೋಧನೆ ಆಧಾರಿತ ಪರಿಹಾರಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸುವ ಮೂಲಕ
                ಮಾರುಕಟ್ಟೆ ಸೇವೆಗಳನ್ನು ಒದಗಿಸುವುದು ನಮ್ಮ ಗುರಿಯಾಗಿದೆ. ಆದಾಗ್ಯೂ,
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಯಾವುದೇ ಮೂರನೆಯ ವ್ಯಕ್ತಿ ಮಾರಾಟಗಾರರ ಗುಣಮಟ್ಟಕ್ಕೆ
                ಗ್ಯಾರೆಂಟಿ ನೀಡುವುದಿಲ್ಲ ಮತ್ತು ಖಾತರಿಪಡಿಸುವುದಿಲ್ಲ. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ
                ನಿಮ್ಮ ಬಳಕೆ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಒದಗಿಸಲಾದ ಯಾವುದೇ ಮಾಹಿತಿ,
                ಮುನ್ನೋಟಗಳು ಅಥವಾ ಸಲಹೆಗಳು ನಿಮ್ಮ ತೃಪ್ತಿಗಾಗಿ ನೀವು ಬಳಸಿಕೊಳ್ಳುವ
                ಮಾರ್ಗಗಳಾಗಿವೆ. ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮೂಲಕ ನಾವು ನಿಮಗೆ ಒದಗಿಸಬಹುದಾದ ಗುಣಮಟ್ಟ,
                ಪ್ರಮಾಣ, ಡೇಟಾ, ಮಾಹಿತಿ, ಅಂದಾಜುಗಳು ಮತ್ತು ಮುನ್ನೋಟಗಳ ನಿಖರತೆಯ ಬಗ್ಗೆ
                ನಾವು ಯಾವುದೇ ರೀತಿಯ ಪ್ರಾತಿನಿಧ್ಯ ಅಥವಾ ಖಾತರಿ ನೀಡುವುದಿಲ್ಲ ಮತ್ತು
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಎಲೆಕ್ಟ್ರಾನಿಕ್ ಮಾರುಕಟ್ಟೆ ಸ್ಥಳವಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುವ
                ಉದ್ದೇಶವನ್ನು ಹೊಂದಿದೆ, ಮತ್ತು ಖರೀದಿದಾರರ ನಷ್ಟದ ಅಪಾಯಕ್ಕೆ
                ಒಳಪಟ್ಟಿರುತ್ತದೆ ಎನ್ನುವುದನ್ನು ನೀವು ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೀರಿ ಮತ್ತು
                ಅರ್ಥೈಸಿಕೊಳ್ಳುತ್ತೀರಿ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ಅಭಿಪ್ರಾಯಗಳು ಮತ್ತು ಪ್ರತಿಕ್ರಿಯೆ</h3>
          <ol>
            <li>
              <p>
                ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಕೊವೆಸ್ಟ್ರೋ ಗೆ ನೀವು ನೀಡಿದ ಯಾವುದೇ ಅಭಿಪ್ರಾಯಗಳು
                ಸ್ಕ್ರೀನಿಂಗ್ ಮತ್ತು ಮಾಡರೇಶನ್‌ಗೆ ಒಳಪಟ್ಟಿರುತ್ತವೆ.
              </p>
            </li>
            <li>
              <p>
                ಅಭಿಪ್ರಾಯಗಳು ಕಾನೂನನ್ನು ಉಲ್ಲಂಘಿಸುತ್ತವೆ ಅಥವಾ ಅನುಚಿತವಾಗಿವೆ ಎಂದು
                ಕಂಡುಬಂದರೆ (ನಿಂದನೀಯ ಪ್ರಚಾರ, ಮಾನನಷ್ಟ, ಅವಮಾನಗಳು, ಅಸಮಂಜಸ
                ವ್ಯಾಖ್ಯಾನಗಳು), ಅದನ್ನು ನಿರಾಕರಿಸುವ ಅಥವಾ ಮಾರ್ಪಡಿಸುವ ಹಕ್ಕನ್ನು ನಾವು
                ಕಾಯ್ದಿರಿಸಿದ್ದೇವೆ ಮತ್ತು ಸೂಕ್ತ ಕ್ರಮವನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತೇವೆ.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TNCKN;
